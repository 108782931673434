import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { FormFormatSelection } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormFormatSelection';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormValues, PolicyTermsFormField, selectDeductibleFormats, selectSortedAlphabeticalChoices } from '../utils/form';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { DeductibleFormatsType, MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { useWatch } from 'react-hook-form';
import { OCCURRENCE_FIELDS, getSublimitShare, trimAndParse, usePolicyTermsContext } from '../utils/coverage-utils';
import { selectTenantFlags } from '../../../selectors/environment';

export const EarthquakeCoverages = () => {
    const { policyTermsGlossary } = usePolicyTermsContext();

    const enableEQPerilForTenant = useAppSelector(state => selectTenantFlags(state).policy_terms_earthquake_cat_model_peril);
    const EARTHQUAKE_DED_PERCENT_AMT = useAppSelector(state => state.global.CONSTANTS.EARTHQUAKE_DED_PERCENT_AMT);
    const DEDUCTIBLE_APPLICABILITY = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'DEDUCTIBLE_APPLICABILITY'));
    const deductibleFormats = useAppSelector(state => selectDeductibleFormats(state));
    const EARTHQUAKE_FIRE_FOLLOWING = useAppSelector(state => state.global.CONSTANTS.EARTHQUAKE_FIRE_FOLLOWING);
    const applyMinimumOccurrenceDeductible = useWatch<PolicyTermsFormValues, 'is_earthquake_minimum_occurrence_applied'>({
        name: 'is_earthquake_minimum_occurrence_applied',
    });
    const applyMaximumOccurrenceDeductible = useWatch<PolicyTermsFormValues, 'is_earthquake_maximum_occurrence_applied'>({
        name: 'is_earthquake_maximum_occurrence_applied',
    });

    const eqGroundUpSublimit = useWatch<PolicyTermsFormValues, 'earthquake_limit'>({ name: 'earthquake_limit' });
    const occurrenceValues = useWatch<PolicyTermsFormValues, typeof OCCURRENCE_FIELDS>({
        name: OCCURRENCE_FIELDS,
    });
    const [
        policy_is_layered,
        occurrence_participation_fmt,
        occurrence_participation,
        occurrence_participation_dollar_amt,
        occurrence_attachment,
        occurrence_limit,
    ] = occurrenceValues;

    const shareEQSublimit = policyTermsGlossary.earthquake_share_of_sublimit_label.active
        ? getSublimitShare(
              {
                  policy_is_layered,
                  occurrence_participation_fmt,
                  occurrence_participation: +occurrence_participation,
                  occurrence_participation_dollar_amt: trimAndParse(occurrence_participation_dollar_amt),
                  occurrence_attachment: trimAndParse(occurrence_attachment),
                  occurrence_limit: trimAndParse(occurrence_limit),
              },
              trimAndParse(eqGroundUpSublimit)
          )
        : '';

    return (
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormInput<PolicyTermsFormValues> name="earthquake_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
            </PolicyTermsFormField>
            {shareEQSublimit && (
                <>
                    <div className="input-label flex">
                        <div className="txt-overflow">{policyTermsGlossary.earthquake_share_of_sublimit_label.label}</div>
                    </div>
                    <div className="note-info-blocks" style={{ marginBottom: 0, width: 'fit-content' }}>
                        <span className="tw-cursor-default">{shareEQSublimit}</span>
                    </div>
                </>
            )}

            {/* complex tw-hidden selector used to hide <hr /> and `Deductibles` if there are no fields rendered under this block*/}
            {enableEQPerilForTenant && (
                <>
                    <hr className="first:tw-hidden [*:has(div[data-meta-label='deductibles']+hr:last-child[data-meta-label='additional-selections'])>&]:tw-hidden tw-col-span-full" />
                    <div
                        className="last:tw-hidden [&:has(+_hr:last-child[data-meta-label='additional-selections'])]:tw-hidden info-rows tw-m-0 tw-col-span-full"
                        data-meta-label="deductibles"
                    >
                        <div className="input-label tw-col-span-full txt-overflow tw-text-grey-primary tw-font-semibold">Deductibles</div>
                    </div>
                </>
            )}

            <PolicyTermsFormField>
                <FormFormatSelection<DeductibleFormatsType, PolicyTermsFormValues>
                    name="earthquake_deductible_fmt"
                    formats={deductibleFormats}
                    render={format =>
                        format === 'PERCENTAGE' ? (
                            <PolicyTermsFormField hideLabel>
                                <FormSelect<PolicyTermsFormValues> name="earthquake_deductible_pct_amt" selections={EARTHQUAKE_DED_PERCENT_AMT} />
                            </PolicyTermsFormField>
                        ) : (
                            <PolicyTermsFormField hideLabel>
                                <FormInput<PolicyTermsFormValues>
                                    name="earthquake_deductible_dollar_amt"
                                    dollarBefore
                                    maskOptions={MASKS['CURRENCY_INTEGER']}
                                />
                            </PolicyTermsFormField>
                        )
                    }
                />
            </PolicyTermsFormField>
            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="earthquake_deductible_basis" selections={DEDUCTIBLE_APPLICABILITY} />
            </PolicyTermsFormField>
            <PolicyTermsFormField>
                <FormButtonGroup<PolicyTermsFormValues>
                    name="is_earthquake_minimum_occurrence_applied"
                    items={[
                        { display: 'Yes', value: true },
                        { display: 'No', value: false },
                    ]}
                />
            </PolicyTermsFormField>

            {applyMinimumOccurrenceDeductible && (
                <PolicyTermsFormField>
                    <FormInput<PolicyTermsFormValues>
                        name="earthquake_min_occurrence_deductible_dollar_amt"
                        dollarBefore
                        maskOptions={MASKS['CURRENCY_INTEGER']}
                    />
                </PolicyTermsFormField>
            )}

            <PolicyTermsFormField>
                <FormButtonGroup<PolicyTermsFormValues>
                    name="is_earthquake_maximum_occurrence_applied"
                    items={[
                        { display: 'Yes', value: true },
                        { display: 'No', value: false },
                    ]}
                />
            </PolicyTermsFormField>

            {applyMaximumOccurrenceDeductible && (
                <PolicyTermsFormField>
                    <FormInput<PolicyTermsFormValues>
                        name="earthquake_max_occurrence_deductible_dollar_amt"
                        dollarBefore
                        maskOptions={MASKS['CURRENCY_INTEGER']}
                    />
                </PolicyTermsFormField>
            )}

            {enableEQPerilForTenant && <hr className="first:tw-hidden last:tw-hidden tw-col-span-full" data-meta-label="additional-selections" />}

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="earthquake_fire_following" selections={EARTHQUAKE_FIRE_FOLLOWING} />
            </PolicyTermsFormField>
            <PolicyTermsFormField>
                <FormButtonGroup<PolicyTermsFormValues>
                    name="is_sprinkler_leakage_included"
                    items={[
                        { display: 'Yes', value: true },
                        { display: 'No', value: false },
                    ]}
                />
            </PolicyTermsFormField>
        </div>
    );
};
