import { createSelector } from '@reduxjs/toolkit';
import { FormError } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormError';
import {
    DATE_FNS_DISPLAY_DATE_FORMAT,
    DISPLAY_DATE_TIME_AM_PM_DATE_FNS,
    DeductibleFormatsType,
} from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { isNullish, isString, isNumber, isChoiceArray, isBoolean } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { formatNumberIntl } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { format, formatISO, parseISO } from 'date-fns';
import React from 'react';
import _ from 'lodash';
import { UseControllerProps, useFormContext } from 'react-hook-form';
import { PEE_FIELDS } from '../../../constants/PolicyCoverageConstants';
import { useAppContext } from '../../../hooks/context';
import { useSubmissionIsEditable } from '../../../hooks/submissions';
import { useValidateQuery } from '../../../services/endpoints/policy-coverage';
import { RootState } from '../../../store';
import { DeductibleFormat, PEEQueryResult, PeeOverridesType, PolicyTermsResult, BuildingTermsResult } from '../../../ts-types/ApiTypes';
import { Types } from '../../../ts-types/viki-types';
import { FORM_INPUTS } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form';
import { Choice } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { TenantGlossaryItemType } from '../../../ts-types/ApiTypes';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';
import { useAppSelector } from '../../../hooks/redux';
import { RAE_SITE_ID } from '../../../constants/SiteConstants';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { usePolicyTermsContext } from './coverage-utils';

// selector function to grab deductible formats in '$', '%' order
// probably overengineered but it's done this way to handle additional formats down the line
export const selectDeductibleFormats = createSelector([(state: RootState) => state.global.CONSTANTS.DEDUCTIBLE_FORMAT], formats => formats.slice().reverse());
export const selectDeductibleFormatsWithDefault = createSelector([(state: RootState) => state.global.CONSTANTS.DEDUCTIBLE_FORMAT_WITH_DEFAULT], formats =>
    formats.slice().reverse()
);

export const selectSortedAlphabeticalChoices = createSelector(
    [(state: RootState) => state.global.CONSTANTS, (_state: RootState, constantType: keyof Types.Constants) => constantType],
    (constants: Types.Constants, constantType: keyof Types.Constants) => {
        const constant = constants[constantType];
        if (!isChoiceArray<string>(constant)) {
            throw new Error(`${constantType} is not a choice array!`);
        }
        // TODO: can we make typescript smarter about picking Value type for Choice?
        return [...constant].sort(({ display: displayA }, { display: displayB }) => {
            return displayA.localeCompare(displayB);
        });
    }
);

export const insertOptionChoices = createSelector(
    [
        (state: RootState) => state.global.CONSTANTS,
        (_state: RootState, constantType: keyof Types.Constants) => constantType,
        (_state: RootState, _constantType: keyof Types.Constants, option: string) => option,
    ],
    (constants: Types.Constants, constantType: keyof Types.Constants, option: string) => {
        const constant = constants[constantType];
        if (!isChoiceArray<string>(constant)) {
            throw new Error(`${constantType} is not a choice array!`);
        }
        const ret = [...constant].sort();
        ret.unshift({ value: null, display: option });
        return ret;
    }
);

export const YES_OR_NO_CHOICES = [
    { display: 'Yes', value: true },
    { display: 'No', value: false },
] as Choice<boolean>[];

type Formatter<DisplayType extends number | string | boolean, SubmitValue extends number | string | boolean = DisplayType> = {
    display: (rawData: unknown) => DisplayType;
    submit: (formData: DisplayType) => SubmitValue;
};

const dateType: Formatter<string> = {
    display: rawData => (isString(rawData) ? format(parseISO(rawData), DATE_FNS_DISPLAY_DATE_FORMAT) : ''),
    submit: formData => (formData ? formatISO(new Date(formData), { representation: 'date' }) : null),
};

const dateTimeType: Formatter<string> = {
    display: rawData => (isString(rawData) ? format(parseISO(rawData), DISPLAY_DATE_TIME_AM_PM_DATE_FNS) : ''),
    submit: formData => (formData ? formatISO(new Date(formData), { representation: 'complete' }) : null),
};

const booleanType = (options?: { inverse?: boolean }): Formatter<boolean, boolean> => ({
    display: rawData => (options?.inverse ? !rawData : !!rawData),
    submit: formData => (options?.inverse ? !formData : !!formData),
});

const numberType = (
    options?: Pick<Intl.NumberFormatOptions, 'style' | 'useGrouping' | 'minimumFractionDigits' | 'maximumFractionDigits' | 'minimumIntegerDigits'>,
    submitAsNumber = false
): Formatter<string | number, string | number> => ({
    display: rawData => {
        if (isNullish(rawData)) {
            return '';
        }
        const { style, ...restOptions } = options ?? {};
        // we don't actually want to format as percent or currency because it inserts a %/$ into the input value
        const value = style === 'percent' ? +rawData * 100 : +rawData;
        return formatNumberIntl(value, { ...restOptions, style: style === 'currency' ? 'currency' : undefined }) || '';
    },
    submit: formData => {
        if (formData === '') {
            return null;
        }
        const { style, maximumFractionDigits, minimumFractionDigits } = options ?? {};
        let submitValue = isString(formData) ? +formData.replace(/[,$]/g, '') : formData;
        if (style === 'percent') {
            submitValue /= 100;
        }
        const result = formatNumberIntl(submitValue, { useGrouping: false, maximumFractionDigits, minimumFractionDigits });
        return submitAsNumber && !isNullish(result) ? +result : result;
    },
});

const stringNullType: Formatter<string> = {
    display: rawData => (isString(rawData) ? rawData : ''),
    submit: formData => (formData !== '' ? formData : null),
};
const _stringType: Formatter<string> = {
    display: rawData => (rawData as string) || '',
    submit: formData => formData,
};
const _percentType = numberType({ style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
const currencyType = numberType({ minimumFractionDigits: 0, maximumFractionDigits: 0 });
const keyType: Formatter<string | number | boolean> = {
    display: rawData => (isString(rawData) || isNumber(rawData) || isBoolean(rawData) ? rawData : null),
    submit: formData => formData,
};
const _deductibleType: Formatter<DeductibleFormatsType> = {
    display: rawData => (rawData === 'PERCENTAGE' || rawData === 'DOLLAR_AMOUNT' ? rawData : null),
    submit: formData => formData,
};
const listType: Formatter<string> = {
    display: rawData => (isString(rawData) ? rawData : ''),
    submit: _formData => null, // TODO: don't think this is supported
};

export const formatForForm = (glossaryItem?: TenantGlossaryItemType, useCurrencyStyle = false) => {
    const fieldFormat = glossaryItem?.field_format;
    const currencyDecimals = fieldFormat?.subtype === 'currency' ? 2 : undefined;
    const decimals = fieldFormat?.decimals ?? currencyDecimals;
    const style = fieldFormat?.subtype === 'percentage' ? 'percent' : fieldFormat?.subtype;
    const useGrouping = fieldFormat?.nocommas ? false : undefined;
    const inverse = glossaryItem?.inverse;
    return {
        text: stringNullType,
        bool: booleanType({ inverse }),
        number: numberType({
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            useGrouping,
            style: !useCurrencyStyle && style === 'currency' ? undefined : style,
        }),
        date: dateType,
        datetime: dateTimeType,
        list: listType,
        choices: keyType,
    } as const;
};

type Formatters = ReturnType<typeof formatForForm>;

export const getFormDefaultsFromGlossary = <
    FormValues,
    DataType extends Record<string, unknown> = Record<string, unknown>,
    GlossaryType extends GenericGlossaryType<DataType> = GenericGlossaryType<DataType>
>(
    data: DataType,
    glossary: GlossaryType,
    useCurrencyStyle = false // TODO: get rid of this gross code and make Overview Page act similar to policy terms with $ formatting
) => {
    const formDefaults = {};
    Object.keys(glossary).forEach(key => {
        if (data?.[key] === undefined) {
            throw new Error(`Data for glossary item '${key}' not found`);
        }
        const glossaryItem = glossary[key];
        // TODO: Handle choices better
        const glossaryType = glossaryItem.choices && glossaryItem.type !== 'bool' ? 'choices' : glossaryItem.type;
        if (!formatForForm()?.[glossaryType]) {
            console.error(`Could not find type '${glossaryType}' for field '${key}' in glossary`);
            return;
        }
        formDefaults[key] = formatForForm(glossaryItem, useCurrencyStyle)[glossaryType as keyof ReturnType<typeof formatForForm>].display(data[key]);
    });
    return formDefaults as FormValues;
};

export type FormValuesFromType<DataType> = {
    [T in keyof DataType]: DataType[T] extends DeductibleFormat
        ? DataType[T]
        : DataType[T] extends string
        ? ReturnType<Formatters['text']['display']>
        : DataType[T] extends number
        ? ReturnType<Formatters['number']['display']>
        : DataType[T] extends boolean
        ? ReturnType<Formatters['bool']['display']>
        : DataType[T];
};

export type PolicyTermsFormValues = FormValuesFromType<PolicyTermsResult>;
export type PolicyTermsFieldName = UseControllerProps<PolicyTermsFormValues>['name'];

export type BuildingTermsFormValues = FormValuesFromType<BuildingTermsResult>;
export type BuildingTermsFieldName = UseControllerProps<BuildingTermsFormValues>['name'];

type GenericGlossaryType<DataType> = {
    [T in keyof DataType]: TenantGlossaryItemType;
};
export const getFormValuesForSubmit = <
    DataType extends Record<string, unknown>,
    FormValues extends FormValuesFromType<DataType> = FormValuesFromType<DataType>,
    GlossaryType extends GenericGlossaryType<DataType> = GenericGlossaryType<DataType>
>(
    data: FormValues,
    glossary: GlossaryType,
    dirtyFields: Partial<{ [T in keyof FormValues]: boolean }>
) => {
    const submitData = {};
    Object.keys(dirtyFields).forEach((key: string) => {
        const glossaryItem = glossary[key];
        const glossaryType = glossaryItem.type;
        if (!formatForForm()?.[glossaryType]) {
            console.error(`Could not find type '${glossaryType}' for field '${String(key)}' in glossary`);
            return;
        }
        const updateData = data[key];
        if (updateData === null) {
            submitData[key] = updateData;
        } else {
            // TODO: Figure out a way to get stricter typing
            submitData[key] = formatForForm(glossaryItem)[glossaryType].submit(updateData);
        }
    });
    return submitData as Partial<DataType>;
};

export const peeDefaults = (peeData: PeeOverridesType, peeCoverage: PEEQueryResult, isStdLimitIncluded: boolean) => {
    const peeDefault = {};
    peeCoverage?.bpp_coverages?.forEach(x => {
        const key = 'pee_override_' + x.prefix;
        if (!(key in peeData)) {
            return;
        }
        const backupValue = isStdLimitIncluded ? x.std_limit : '0';
        peeDefault[key] = !isNullish(peeData[key]) ? peeOverrides[key].display(peeData[key]) : backupValue;
    });
    peeCoverage?.time_elements?.forEach(x => {
        const key = 'pee_override_' + x.prefix;
        if (!(key in peeData)) {
            return;
        }
        const backupValue = isStdLimitIncluded ? x.std_limit : '0';
        peeDefault[key] = !isNullish(peeData[key]) ? peeOverrides[key].display(peeData[key]) : backupValue;
    });

    return peeDefault;
};

type PeeOverrides = Record<`pee_override_${(typeof PEE_FIELDS)[number]}`, typeof currencyType>;
export const peeOverrides = PEE_FIELDS.reduce((prevObj, field) => ({ ...prevObj, [`pee_override_${field}`]: currencyType }), {}) as PeeOverrides;

export const PolicyTermsFormField = ({
    children,
    label,
    hideLabel = false,
    showError = true,
    tooltipMessage = undefined,
    labelClass,
}: {
    children: React.ReactNode;
    label?: string;
    hideLabel?: boolean;
    showError?: boolean;
    tooltipMessage?: string;
    labelClass?: string;
}) => {
    const { currentQuote } = useAppContext();
    const formContext = usePolicyTermsContext();
    const formGlossary = formContext.coverageLevel === 'policy' ? formContext.policyTermsGlossary : formContext.buildingTermsGlossary;
    const { getFieldState, formState } = useFormContext();
    const { data } = useValidateQuery({ id: currentQuote.policy_coverage_id });
    const isEditable = useSubmissionIsEditable();
    const ENV = useAppSelector(state => state.global.ENV);

    // validation of child
    const onlyChild = React.Children.only(children);
    if (!React.isValidElement(onlyChild) || !FORM_INPUTS.some(component => onlyChild?.type === component)) {
        throw new Error(`Child component must be one of ${FORM_INPUTS.map(component => component.name)}`);
    }

    // retrieval of error info and field label
    const { name, disabled: disabledProp, ...restProps } = onlyChild.props;
    const { error } = getFieldState(name, formState);
    if (!formGlossary || !(name in formGlossary) || formGlossary?.[name].active === false) {
        return null;
    }
    if (!hideLabel && !label && !(name in formGlossary)) {
        throw new Error(`Field info for '${name}' could not be found!`);
    }
    const combinedErrors = [error?.message ?? '', ...(data?.field_errors?.[name] ?? [])].join('\n');

    const isRAE = ENV.SITE.id === RAE_SITE_ID;

    const disabled = !isEditable || disabledProp || formGlossary?.[name].editable === false;

    return (
        <>
            {!hideLabel && (
                <span className="input-label flex tw-gap-1">
                    <div className={labelClass}>{label ?? formGlossary[name].label}</div>
                    {formGlossary[name]?.tooltip && (
                        <Tooltip content={formGlossary[name]?.tooltip || undefined} wrapChild>
                            <Icon className="tw-text-blue tw-text-base tw-select-none" icon="info" />
                        </Tooltip>
                    )}
                </span>
            )}

            <div className="tw-flex">
                {React.cloneElement(onlyChild, { disabled, name, ...restProps })}
                {tooltipMessage && isRAE && (
                    <Tooltip
                        content={
                            <div style={{ wordBreak: 'break-word' }}>
                                <p key={tooltipMessage}>{tooltipMessage}</p>
                            </div>
                        }
                        wrapChild
                    >
                        <i className="material-icons">help</i>
                    </Tooltip>
                )}

                {showError && combinedErrors && <FormError errorMessage={combinedErrors} />}
            </div>
        </>
    );
};
