import * as React from 'react';

import { OnOpenModalType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { OnCloneType } from '../../../../ts-types/SubmissionTypes';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    setModalState: ({ isTestServer }: { isTestServer: boolean }) => void;
    ENV: Types.Env;
    modalState: Record<string, any>;
};

class CloneSubmissionBody extends React.Component<bodyPropTypes> {
    constructor(props: bodyPropTypes) {
        super(props);
        this.props.setModalState({
            isTestServer: this.props.ENV.SITE.id === 2, // 2 is TEST_SITE_ID.  Ultimately, this behavior should be controlled by a tenant setting.
        });
    }

    render(): React.ReactNode {
        return (
            <div className="standard-modal-content">
                <div style={{ paddingBottom: '10px' }}>This will create a new submission with the same information as the current one.</div>
                <div style={{ paddingBottom: '10px' }}>
                    It is intended mostly to &lsquo;freeze&rsquo; the state of submissions and submit them for testing/verification.
                </div>
                {!this.props.modalState.isTestServer && <div>Do you want to create the cloned submission as a LIVE or TEST submission?</div>}
            </div>
        );
    }
}

type footerPropTypes = {
    submissionId: number;
    onOpenCenteredModal: OnOpenModalType;
    ENV: Types.Env;
    onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
    modalState: Record<string, any>;
    onClone: OnCloneType;
};

class CloneSubmissionFooter extends React.Component<footerPropTypes> {
    onClone = (toTest?: boolean) => {
        this.props.onClone(this.props.submissionId, this.props.ENV, toTest || false);
    };

    render() {
        let { onClose, modalState } = this.props;

        let buttons = [];

        if (!modalState.isTestServer) {
            buttons.push(
                <button
                    key="live"
                    className="blue"
                    onClick={() => {
                        this.onClone();
                    }}
                >
                    LIVE
                </button>
            );
            buttons.push(<div key="s1" className="spacer wide" />);
            buttons.push(
                <button
                    key="test"
                    className="pink"
                    onClick={() => {
                        this.onClone(true);
                    }}
                >
                    TEST
                </button>
            );
        } else {
            buttons.push(
                <button
                    key="test"
                    className="blue"
                    onClick={() => {
                        this.onClone(true);
                    }}
                >
                    CONTINUE
                </button>
            );
        }

        buttons.push(<div key="s3" className="spacer wide" />);

        buttons.push(
            <button key="cancel" className="grey-dark" onClick={onClose}>
                Cancel
            </button>
        );

        return (
            <div className="button-row" style={{ textAlign: 'center' }}>
                {buttons}
            </div>
        );
    }
}

let modalTypeObj = {
    Body: CloneSubmissionBody,
    Header: DefaultHeaderStatic('Clone Submission?'),
    Footer: CloneSubmissionFooter,
    className: 'clone-submission-modal',
};
registerType(VIRA_MODAL_TYPES.CLONE_SUBMISSION, modalTypeObj);
export default modalTypeObj;
