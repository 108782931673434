import { CLOSED_SUBMISSION_OPTIONS } from '../../../../constants/Submission';
import { ComboBoxInput, NumericInput, TextAreaInput, TextInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useAppContextFns, useAppContext } from '../../../../hooks/context';
import { setSubmissionOutcome } from '../../../../actions/SubmissionActions';
import { closeSubmission } from '../../../../actions/SubmissionLogActions';
import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions'; // openCenteredModal
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

const CloseSubmissionApp = ({ actionHelperFns }: { actionHelperFns: ActionHelperFnsType }) => {
    const dispatch = useAppDispatch();
    const closeSubmissionData = useAppSelector(state => state.closeSubmissionData);
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const appContextFns = useAppContextFns();
    const appContext = useAppContext();

    let submissionOutcome = closeSubmissionData.submissionOutcome;
    let { new_status, declined_reason, ntu_reason } = submissionOutcome;

    const handleChange = (fieldName, value, error) => {
        dispatch(setSubmissionOutcome(fieldName, value, error));
    };

    const onClose = () => {
        dispatch(closeCenteredModal());
    };

    const handleCloseSubmission = () => {
        let submissionOutcome = closeSubmissionData.submissionOutcome;
        dispatch(closeSubmission(appContext.currentSubmissionId, submissionOutcome, appContextFns.onSetCurrentSubmission, actionHelperFns));
    };

    return (
        <>
            <div key="content" className="standard-modal-content flex column">
                <ComboBoxInput
                    name="new_status"
                    label="Outcome"
                    object={submissionOutcome}
                    onChange={handleChange}
                    selectProps={{ choices: CLOSED_SUBMISSION_OPTIONS }}
                />

                <If condition={new_status === 'DECLINED'}>
                    <ComboBoxInput
                        object={submissionOutcome}
                        label="Declined Reason"
                        name="declined_reason"
                        onChange={handleChange}
                        selectProps={{ choices: CONSTANTS.SUBMISSION_OUTCOME_DECLINED_REASONS }}
                    />
                </If>

                <If condition={new_status === 'DECLINED' && declined_reason === 13}>
                    <TextInput onChange={handleChange} object={submissionOutcome} label="Description for Other" name="declined_reason_other" />
                </If>
                <If condition={new_status === 'NOT_ACCEPTED'}>
                    <ComboBoxInput
                        onChange={handleChange}
                        object={submissionOutcome}
                        label="NTU Reason"
                        name="ntu_reason"
                        selectProps={{ choices: CONSTANTS.SUBMISSION_OUTCOME_NTU_REASONS }}
                    />
                </If>
                <If condition={new_status === 'NOT_ACCEPTED' && ntu_reason === 13}>
                    <TextInput onChange={handleChange} object={submissionOutcome} label="Description for Other" name="ntu_reason_other" />
                </If>

                <TextInput name="winning_carrier" label="Winning Carrier" onChange={handleChange} object={submissionOutcome} />
                <NumericInput
                    name="winning_premium"
                    label="Winning Premium"
                    onChange={handleChange}
                    object={submissionOutcome}
                    format={{ subtype: 'currency' }}
                />
                <TextAreaInput name="winning_notes" label="Winning Notes" onChange={handleChange} object={submissionOutcome} />
                <TextAreaInput name="notes" label="Notes" onChange={handleChange} object={submissionOutcome} />
            </div>
            ,
            <div key="buttons" className="button-row">
                <button
                    className="green"
                    onClick={() => {
                        handleCloseSubmission();
                    }}
                >
                    Ok
                </button>
                <div className="spacer wide" />
                <button
                    className="red"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Discard
                </button>
            </div>
            ,
        </>
    );
};

export default CloseSubmissionApp;
