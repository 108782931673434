import * as React from 'react';

import ExposurePanel from './panels/ExposurePanel';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

type propTypes = {
    exposureData?: Record<string, any>;
};

export default class ExposureSummaryApp extends React.Component<propTypes> {
    render() {
        let { exposureData } = this.props;
        if (!exposureData) return null;
        return (
            <>
                <AccountTasksPanel />
                <div className="ventus-content">
                    <legend className="blue-txt margin-bottom">Exposure Summary</legend>

                    <div className="grid-layout exposure-summary">
                        <ExposurePanel
                            groupBy="construction_type"
                            groupByTitle="Construction Type"
                            chartTitle="DISTRIBUTION BY CONSTRUCTION TYPE"
                            data={exposureData}
                        />
                        <ExposurePanel
                            groupBy="occupancy__description"
                            groupByTitle="Occupancy Type"
                            chartTitle="DISTRIBUTION BY OCCUPANCY TYPE"
                            data={exposureData}
                        />
                        <ExposurePanel
                            groupBy="occupancy__ventus_group_number"
                            groupByTitle="Occupancy"
                            chartTitle="DISTRIBUTION BY OCCUPANCY GROUP"
                            data={exposureData}
                        />
                    </div>
                </div>
            </>
        );
    }
}
