import { format, formatISO, isDate, parseISO } from 'date-fns';
import { DATE_FNS_DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { isString } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';

type Formatter<DisplayType extends number | string | boolean, SubmitValue = DisplayType> = {
    display: (rawData: unknown) => DisplayType;
    submit: (formData: DisplayType) => SubmitValue;
};

export const formatDate: Formatter<string, Date> = {
    display: rawData => (isDate(rawData) ? format(rawData as Date, DATE_FNS_DISPLAY_DATE_FORMAT) : ''),
    submit: formData => (formData ? new Date(formData) : null),
};

export const formatDateString: Formatter<string> = {
    display: rawData => (isString(rawData) ? format(parseISO(rawData), DATE_FNS_DISPLAY_DATE_FORMAT) : ''),
    submit: formData => (formData ? formatISO(new Date(formData), { representation: 'date' }) : null),
};
