import { batch } from 'react-redux';
import { openCenteredModal, closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { reloadTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { SUBMISSION_STATUS_MAP } from '../constants/Submission';
import { getAccountSubmissionViewFromServer } from '../actions/SubmissionActions';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { VIRA_MODAL_TYPES } from '../constants/Modal';
import { GoToSubmissionType } from '../ts-types/DataTypes';
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { matchPath } from 'react-router-dom';
import { getUrlFor } from '../utils/navurl-helpers';
import { NAVIGATION_LOCATIONS } from '../constants/Navigation';

const determineRedirectTo = {
    NEW_SUBMISSION: 'clearance',
    RUSH_DATA_ENTRY: 'rush-data-entry',
    DATA_ENTRY: 'data-entry',
    UW_REVIEW: 'underwriting',
    OUTSTANDING_QUOTES: 'outstanding-quotes',
    BOUND: 'bound',
    DECLINED: 'closed',
    CLOSED: 'closed',
} as const;

export type Tab = keyof typeof determineRedirectTo;

export function documentMapper(documentIds: number[] = [], rowData: Record<string, any>): DocumentDataType[] {
    return documentIds.map(docId => {
        let doc = rowData[docId];
        if (!doc) {
            console.error('Document not found', docId);
            return [];
        }
        return {
            ...doc,
            title: doc.description || doc.original_filename,
        };
    });
}

export function notifyMovedSubmission(
    dispatch: DispatchType,
    submissionId: number,
    change_to: Tab,
    actionHelperFns: ActionHelperFnsType,
    goToSubmission: GoToSubmissionType,
    globalState: Record<string, any>
) {
    const isSubmissionLog = !!matchPath(window.location?.pathname ?? '', {
        path: '/submissions',
        exact: true,
    });

    const ENV = globalState?.global?.ENV;
    const siteId = ENV?.SITE?.id;
    if (!isSubmissionLog) {
        dispatch(closeCenteredModal());
        return;
    }
    let params = {
        newStatus: SUBMISSION_STATUS_MAP[change_to],
        confirmLabelTitle: 'Go to submission',
        dismissButtonTitle: 'Dismiss',
        sid: submissionId,
        onOk: () => {
            window.location.href = getUrlFor(NAVIGATION_LOCATIONS.OVERVIEW, { submissionId }, siteId, ENV);
        },
    };

    batch(() => {
        dispatch(getAccountSubmissionViewFromServer(submissionId));
        dispatch(reloadTable(PAGED_TABLES.SUBMISSION_LOG));
        dispatch(
            openCenteredModal(
                {
                    modalData: params,
                },
                VIRA_MODAL_TYPES.MOVED
            )
        );
    });
}
