import * as React from 'react';

import { API } from '@archinsurance-viki/property-jslib';
import { openCenteredModal, openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { downloadDocument } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';
import { DispatchType, OnOpenModalType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { TableConfigType, LiteTableDataType, OnPersistTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import DocumentsApp from './DocumentsApp';
import { Types } from '../../ts-types/viki-types';
import { RootState } from '../../store';
import { VIRA_MODAL_TYPES } from '../../constants/Modal';

import {
    deleteDocument,
    downloadAllSubmissionDocs,
    exportMergedDataEntrySpreadsheet,
    exportSubmission,
    parseDocument,
    saveDocumentData,
    claimsDocuments,
    sendToClaimsVendor,
} from '../../actions/SubmissionLogActions';
import { loadExcel, updateSubmissionData, parseSov } from '../../actions/SubmissionActions';
import { LITE_TABLES } from '../../constants/LiteTableConfigs';
import { AppContextType } from '../../ts-types/AppTypes';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { OpenPanelType } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';

type statePropTypes = {
    CONSTANTS: Types.Constants;
    openPanels: OpenPanelType;
    tableConfig: TableConfigType;
    tableData: LiteTableDataType;
    ENV: Types.Env;
};

type dispatchPropTypes = {
    onExportSubmission: () => Promise<any>;
    onDownloadAllSubmissionDocs: () => void;
    onDeleteDocument: (documentId: number) => Promise<any>;
    onParseDocument: (documentId: number, index?: number) => any;
    onExportMergedDataEntrySpreadsheet: (documentId: number, index?: number) => any;
    onPersistDocumentData: OnPersistTableDataType;
    onUpdateSubmissionData: (submissionId: number, data: Record<string, any>) => any;
    onDownloadDocument: (document: DocumentDataType, callback: () => any) => any;
    onSendToClaimsVendor: (documentRowData: Record<string, any>) => Promise<any>;
    onLoadExcel: (document: Record<string, any>) => any;
    onParseSov: (documentId) => Promise<any>;
    onOpenCenteredModal: OnOpenModalType;
    onDocumentPreviewFail: (documentId) => any;
};

const mapStateToProps = (state: RootState): statePropTypes => {
    return {
        openPanels: state.uiState.openPanels,
        CONSTANTS: state.global.CONSTANTS,
        tableConfig: LITE_TABLES.DOCUMENTS,
        tableData: state.documents,
        ENV: state.global.ENV,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, appContext: AppContextType): dispatchPropTypes => {
    let originalSubmissionId = appContext.originalSubmissionId;
    let currentSubmissionId = appContext.currentSubmissionId;

    return {
        onExportSubmission: () => {
            return dispatch(exportSubmission(currentSubmissionId));
        },
        onLoadExcel: document => {
            return dispatch(loadExcel(document));
        },
        onDownloadAllSubmissionDocs: () => {
            return dispatch(downloadAllSubmissionDocs(originalSubmissionId));
        },
        onPersistDocumentData: (index, id, data) => {
            return dispatch(saveDocumentData(index, id, data));
        },
        onDeleteDocument: documentId => {
            return dispatch(deleteDocument(documentId, originalSubmissionId));
        },
        onParseDocument: (documentId: number) => {
            return dispatch(parseDocument(documentId, currentSubmissionId));
        },
        onParseSov: documentId => {
            return dispatch(parseSov(documentId, currentSubmissionId));
        },
        onExportMergedDataEntrySpreadsheet: documentId => {
            return dispatch(exportMergedDataEntrySpreadsheet(documentId, currentSubmissionId));
        },
        onUpdateSubmissionData: (submissionId, data) => {
            return dispatch(updateSubmissionData(originalSubmissionId, data));
        },
        onDownloadDocument: (document, fn) => {
            return dispatch(downloadDocument(document, fn));
        },
        onSendToClaimsVendor: documentRowData => {
            return dispatch(claimsDocuments(originalSubmissionId)).then(({ data }) => {
                const { default_ids, documents: documentIds, subject, sendTo, emailAddresses } = data;

                // Convert the id lists within dictionary to documentData lists
                const documents = {};
                Object.keys(documentIds).forEach((key: string) => {
                    documents[key] = documentIds[key].map((id: number) => documentRowData[id]);
                });

                const defaultSelectedDocs = default_ids.map((id: number) => documentRowData[id]);

                return dispatch(
                    openMessageModal(
                        {
                            disableModalResizable: true,
                            modalData: {
                                confirmLabelTitle: 'Send Policy Documents',
                                dismissButtonTitle: 'Cancel',
                                onOk: (docsList: number[], subject: string, sendTo: string) =>
                                    dispatch(sendToClaimsVendor(originalSubmissionId, docsList, subject, sendTo)),
                                defaultSelectedDocs,
                                documents,
                                subject,
                                sendTo,
                                emailAddresses,
                            },
                        },
                        VIRA_MODAL_TYPES.SEND_DOCUMENTS
                    )
                );
            });
        },
        onOpenCenteredModal: (modalData, modalType) => {
            dispatch(openCenteredModal(modalData, modalType));
        },
        onDocumentPreviewFail: documentId => {
            return API.doRequest(
                API.endpoints.submission.previewDocuments,
                {
                    data: [documentId],
                    hideDialogForErrors: true,
                },
                dispatch
            );
        },
    };
};

export default function DocumentsPage() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const stateProps = useAppSelector(mapStateToProps);
    const dispatch = useAppDispatch();
    const dispatchProps = mapDispatchToProps(dispatch, appContext);

    React.useEffect(() => {
        appContextFns.onLoadDocumentsIfNeeded();
    });

    return <DocumentsApp appContext={appContext} appContextFns={appContextFns} {...stateProps} {...dispatchProps} />;
}
