import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PanelView from '../PanelView';
import TargetMultipleRow from './TargetMultipleRow';
import { useAppContext } from '../../hooks/context';
import { useGetTargetMultipleRulesQuery, useSetTargetMultipleRuleMutation, useUpdateTargetMultipleRuleMutation } from '../../services/apiSlice';

const getFilterDefaultValue = state => {
    if (state.state) {
        return 'state';
    } else if (state.zipcode) {
        return 'zipcode';
    } else if (state.county_code) {
        return 'county_code';
    } else if (state.zone) {
        return 'zone';
    }
};

const TargetMultiplesPage = props => {
    const appContext = useAppContext();
    const [rows, setRows] = useState([]);
    const [disableAddNewRow, setDisableAddNewRow] = useState(false);
    const [disableChange, setDisableChange] = useState(false);

    const { data: targetMultiples = [], isFetching } = useGetTargetMultipleRulesQuery({});
    const [setTargetMultiple /*{ error, isLoading: isOverriding }*/] = useSetTargetMultipleRuleMutation();
    const [updateTargetRule /*{ error, isLoading: isOverriding }*/] = useUpdateTargetMultipleRuleMutation();

    useEffect(() => {
        setRows(targetMultiples);
        if (!appContext.me.has_autority_to_create_target_multiple) {
            setDisableAddNewRow(true);
            setDisableChange(true);
        }
    }, [targetMultiples, appContext.me.has_autority_to_create_target_multiple]);

    const handleRow = () => {
        setRows([...rows, { is_active: true }]);
        setDisableAddNewRow(true);
    };
    const handleDeleteRow = (id?: number) => {
        let update = rows.filter(r => r.id !== id);
        if (!id) {
            update = rows.filter(r => r.id !== undefined);
            setDisableAddNewRow(false);
        }
        setRows(update);
    };

    const handleAddOrUpdate = (data: Record<string, any>) => {
        data.id
            ? updateTargetRule(data)
            : setTargetMultiple(data).then(() => {
                  setDisableAddNewRow(false);
              });
    };

    rows.map(r => console.info(getFilterDefaultValue(r), r));

    return (
        <PanelView topPanelHeader="Target Multiples" hasLeftPanel={true}>
            <div className="main-content-center">
                <div className="ventus-content">
                    <div className="grid-layout settings target-multiple">
                        <a className="table-header material-icons" title="Select All">
                            <span className="material-icons">done</span>
                        </a>
                        <div className="table-header">Description</div>
                        <div className="table-header grid-2-wide">Filter Type</div>
                        <div className="table-header">Multiple</div>
                        <div className="table-header">Actions</div>

                        <If condition={!isFetching}>
                            {rows.map((r, idx) => (
                                <TargetMultipleRow
                                    filterType={getFilterDefaultValue(r)}
                                    onAddOrUpdate={handleAddOrUpdate}
                                    key={idx}
                                    onDelete={handleDeleteRow}
                                    disableChange={disableChange}
                                    {...r}
                                />
                            ))}
                        </If>
                        <If condition={isFetching}>
                            <a className="action btn blue grid-5-wide spinner-wrapper" title="Loading">
                                <div className="spinner"></div>
                            </a>
                        </If>

                        <a onClick={handleRow} className={classNames('action btn blue grid-6-wide', { disabled: disableAddNewRow })}>
                            Add New Interest
                        </a>
                    </div>
                </div>
            </div>
        </PanelView>
    );
};

export default TargetMultiplesPage;
