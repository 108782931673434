import React from 'react';

import { renderFormInput } from '@archinsurance-viki/property-jslib/src/utils/form-helpers';
import { TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

type propTypes = {
    modalData: Record<string, any>;
    onClose: (x?: boolean) => any;
    onSaveStructure: (structure: any, callback: (sucess: boolean, errorData?: any) => any) => void;
    onDeleteStructure: (structure: any, callback: (sucess: boolean, errorData?: any) => any) => void;
};

export default class AdditionalStructuresModalApp extends React.Component<propTypes, { structure: TableRowDataType }> {
    constructor(props: propTypes) {
        super(props);

        this.state = {
            structure: props.modalData.additionalStructure ? Object.assign({}, props.modalData.additionalStructure) : {},
        };
    }

    onRequestFinished = (success: boolean, errorData?: Record<string, any>) => {
        if (success) {
            this.props.onClose(true);
        } else if (errorData) {
            this.setState(prevState => ({
                ...prevState,
                structure: {
                    ...prevState.structure,
                    _errors: { ...errorData.errors },
                },
            }));
        } else {
            console.warn('TODO: handle non-field error');
        }
    };

    onSave = () => {
        this.props.onSaveStructure(this.state.structure, this.onRequestFinished);
    };

    onDelete = () => {
        this.props.onDeleteStructure(this.state.structure.id, this.onRequestFinished);
    };

    renderRows() {
        let { rows, glossaryData, readOnly } = this.props.modalData;
        let rendered = [];

        for (let row of rows) {
            rendered.push(
                renderFormInput({
                    object: this.state.structure,
                    field: row.key,
                    key: row.key,
                    glossaryData: glossaryData[row.key],
                    saveFn: (key: keyof typeof this.state.structure, value: unknown) => {
                        this.setState(prevState => ({ ...prevState, structure: { ...prevState.structure, [key]: value } }));
                    },
                    creatingObject: !this.state.structure.id,
                    readonly: readOnly,
                    noWrapper: false,
                    selectConstraint: 'window',
                })
            );
        }

        return rendered;
    }

    render() {
        let { readOnly } = this.props.modalData;
        return [
            <div key="content" className="standard-modal-content flex column">
                {this.renderRows()}
            </div>,
            <div key="buttons" className="button-row">
                <If condition={!readOnly}>
                    <button className="green" onClick={this.onSave}>
                        Save
                    </button>
                    <div className="spacer wide" />
                </If>
                <If condition={this.state.structure.id && !readOnly}>
                    <div className="spacer wide" />
                    <button className="red" onClick={this.onDelete}>
                        Delete
                    </button>
                </If>
                <div className="spacer wide" />
                <button
                    className="grey-dark"
                    onClick={() => {
                        this.props.onClose(true);
                    }}
                >
                    Cancel
                </button>
            </div>,
        ];
    }
}
