import React from 'react';
import cx from 'classnames';
import { PANELS, TRANSACTION_PULLDOWN_STATES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import ActionPanel from '@archinsurance-viki/property-jslib/src/components/panels/ActionPanel';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../submissionlog/Helpers';
import PanelView from '../PanelView';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';
import { CenteredModal } from '@archinsurance-viki/property-jslib/src/components/modals';
import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import AccountsAppContainer from '../../containers/AccountsAppContainer';

export const DefaultPageLayout = ({
    children,
    hasActionPanel,
    hideBackgroundTask,
    topPanelHeader,
    transactionPulldown,
}: {
    children: React.ReactNode;
    hasActionPanel?: boolean;
    hideBackgroundTask?: boolean;
    topPanelHeader?: string;
    transactionPulldown?: (typeof TRANSACTION_PULLDOWN_STATES)[keyof typeof TRANSACTION_PULLDOWN_STATES];
}) => {
    const dispatch = useAppDispatch();
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();

    const ENV = useAppSelector(state => state.global.ENV);
    const centeredModalData = useAppSelector(state => state.global.centeredModalData);
    const centeredModalType = useAppSelector(state => state.global.centeredModalType);

    const uiState = useAppSelector(state => state.uiState);
    const slidePanelOpen = uiState.openPanels[PANELS.RIGHT];
    const panelActionsProps = {
        appContext,
        appContextFns,
    };
    return (
        <PanelView topPanelHeader={topPanelHeader} transactionPulldown={transactionPulldown} hasLeftPanel>
            <CenteredModal
                modalProps={{ appContextFns, appContext }}
                modalData={centeredModalData}
                modalType={centeredModalType}
                onClose={() => dispatch(closeCenteredModal())}
                uiState={appContext.uiState}
                ENV={ENV}
            />
            <div className="main-content-center tw-flex-1 tw-flex tw-flex-col tw-min-w-0">
                <div className="tw-flex tw-flex-1 tw-h-100">
                    <div className="tw-flex tw-flex-col tw-flex-1">
                        {!hideBackgroundTask && <AccountTasksPanel />}
                        <div className="tw-flex-1 tw-flex px-2">{children}</div>
                    </div>
                </div>
            </div>
            {hasActionPanel && (
                <div className={cx('main-right-panel main-panel', { closed: !slidePanelOpen })}>
                    <ActionPanel
                        uiState={uiState}
                        panelActions={calculateRowActions(panelActionsProps, ACTION_PANEL_LOCATIONS.COVERAGE_OPTIONS_LIST)}
                        openPanels={uiState.openPanels}
                    />
                </div>
            )}
        </PanelView>
    );
};

export const DefaultAccountLayout = ({
    children,
    topPanelHeader,
    bottomPanel,
    transactionPulldown,
    hasActionPanel = true,
    hideBackgroundTask = false,
}: {
    children: React.ReactNode;
    topPanelHeader?: React.ReactNode;
    bottomPanel?: React.ReactNode;
    hasActionPanel?: boolean;
    hideBackgroundTask?: boolean;
    transactionPulldown?: (typeof TRANSACTION_PULLDOWN_STATES)[keyof typeof TRANSACTION_PULLDOWN_STATES];
}) => (
    <AccountsAppContainer topPanelHeader={topPanelHeader} hasActionPanel={hasActionPanel} transactionPulldown={transactionPulldown}>
        <div className="main-content-center">
            {!hideBackgroundTask && <AccountTasksPanel />}
            <div className="tw-flex-1 tw-flex tw-flex-col overflow-y tw-overflow-x-hidden">{children}</div>
            {bottomPanel ? bottomPanel : null}
        </div>
    </AccountsAppContainer>
);
