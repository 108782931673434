import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import api from '../services/apiSlice';
import { AppDispatch, RootState } from '../store';
import { FinalPremiumType, PolicyCoverageType } from '../ts-types/ApiTypes';

type PricingInitialState = {
    dirty: Record<string, true>;
    skipRevalidation: boolean;
    changedPremium: {
        equipment_breakdown: number;
        terror: number;
    };
};

const pricingSlice = createSlice({
    name: 'pricing',
    initialState: {
        dirty: {},
        skipRevalidation: false,
        changedPremium: {
            equipment_breakdown: 0,
            terror: 0,
        },
    } as PricingInitialState,
    reducers: {
        setQuoteDirty(state, action: PayloadAction<number>) {
            // TODO: We can probably do this better, use actual booleans in payload
            // sorry whoever is changing this, i was lazy
            state.dirty[action.payload] = true;
        },
        toggleSkipRevalidation(state, action: PayloadAction<boolean>) {
            state.skipRevalidation = action.payload;
        },
        setChangedPremium(state, action: PayloadAction<Partial<PricingInitialState['changedPremium']>>) {
            const { equipment_breakdown, terror } = action.payload;
            if (equipment_breakdown) {
                state.changedPremium.equipment_breakdown = equipment_breakdown;
            }
            if (terror) {
                state.changedPremium.terror = terror;
            }
        },
        clearChangedPremium(state, action: PayloadAction<(keyof PricingInitialState['changedPremium'])[]>) {
            if (action.payload.includes('equipment_breakdown')) {
                state.changedPremium.equipment_breakdown = 0;
            }
            if (action.payload.includes('terror')) {
                state.changedPremium.terror = 0;
            }
        },
    },
    extraReducers: builder => {
        builder.addMatcher(api.util.invalidateTags.match, (state, { payload }) => {
            // TODO: Why does RTK think that tags are only a string
            payload
                .filter((tag: any) => tag?.type === 'Quote')
                .forEach((tag: any) => {
                    if (tag?.id in state.dirty) {
                        delete state.dirty[tag.id];
                    }
                });
        });
    },
});

const MAP_TO_PREMIUM_FIELDS = {
    equipment_breakdown: 'equipment_breakdown_premium_final',
    terror: 'terror_premium_final',
} as const;

export const getChangedPremium = (dispatch: AppDispatch, nextFP: FinalPremiumType, prevFP: FinalPremiumType) => {
    ['equipment_breakdown', 'terror'].forEach((field: 'equipment_breakdown' | 'terror') => {
        if (!prevFP || !nextFP || isNullish(nextFP?.charged_premium_before_tf)) {
            return;
        }
        const finalFieldName = MAP_TO_PREMIUM_FIELDS[field];
        if (isNullish(nextFP[finalFieldName]) || isNullish(prevFP[finalFieldName])) {
            return;
        }

        // equipment_breakdown does not account for occurrence participation yet while terror does
        const nextPremiumAfterShare = +nextFP[finalFieldName];
        const prevPremiumAfterShare = +prevFP[finalFieldName];
        const premiumDifference = nextPremiumAfterShare - prevPremiumAfterShare;

        if (premiumDifference === 0) {
            return;
        }

        dispatch(setChangedPremium({ [field]: premiumDifference }));
    });
};

export const selectPolicyCoverageData = (state: RootState, quoteId: number) => {
    const networkData = api.endpoints['quotePolicyCoverage'].select({ quoteId })(state)?.data.policy_coverage;
    return (networkData ?? state.quotes.rowData[quoteId].policy_coverage ?? null) as PolicyCoverageType;
};

export const selectFinalPremium = (state: RootState, quoteId: number) => {
    const cachedQueryData = api.endpoints['quoteFinalPremium'].select({ quoteId })(state)?.data.final_premium;
    return (cachedQueryData ?? state.quotes.rowData[quoteId].final_premium ?? null) as FinalPremiumType;
};

const { actions, reducer } = pricingSlice;

export const { setQuoteDirty, toggleSkipRevalidation, setChangedPremium, clearChangedPremium } = actions;

export default reducer;
