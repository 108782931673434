import { API } from '@archinsurance-viki/property-jslib';
import { AppDispatch } from '../store';

export const getAirPml = (options: Record<string, any>, showProgress = true) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.analytics.pml,
            {
                params: options,
                showProgress,
            },
            dispatch
        )
            .then(({ data }) => {
                return data;
            })
            .catch(({ data }) => {
                return data;
            });
    };
};

export const getPmlTableData = (options: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.analytics.getPml,
            {
                params: options,
            },
            dispatch
        )
            .then(({ data }) => {
                return data;
            })
            .catch(({ data }) => {
                return data;
            });
    };
};

export const calculatePmlData = (options: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.analytics.calculatePml,
            {
                params: options,
                showProgress: true,
            },
            dispatch
        )
            .then(({ data }) => {
                return data;
            })
            .catch(({ data }) => {
                return data;
            });
    };
};
