import api from '../apiSlice';
import { BackgroundTasksResult, BackgroundTaskFilterFields } from '../../ts-types/ApiTypes';
import { Pusher } from '@archinsurance-viki/property-jslib';
import { noop } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';
import { BackgroundTask } from '../../ts-types/DataTypes';

export const tasksApi = api.injectEndpoints({
    endpoints: builder => ({
        cancelTask: builder.mutation<unknown, unknown>({
            query: ({ id }) => ({ url: `v1/tasks/${id}/cancel/`, method: 'PUT' }),
        }),
        tasksSubscription: builder.query<BackgroundTasksResult, { filterParams: BackgroundTaskFilterFields; pusherChannelName: string }>({
            query: ({ filterParams }) => {
                const { status, ...restParams } = filterParams;
                // use URLSearchParams to use the 'status' key multiple times with different values
                const params = new URLSearchParams(restParams as any);
                status?.forEach(value => params.append('status', value));
                return { url: 'v1/tasks/', method: 'GET', params };
            },
            async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, cacheEntryRemoved, getCacheEntry }) {
                const { pusherChannelName } = arg;

                const onNewTaskAvailable = (_newTask: BackgroundTask) => {
                    // TODO: Implement some system where you can pull tasks into view if introduced by other user
                    dispatch(tasksApi.util.invalidateTags([{ type: 'BackgroundTask', id: pusherChannelName }]));
                };

                const onUpdatedTask = (updatedTask: BackgroundTask) => {
                    // this should be rare, but in case a task skips the "QUEUED" status we need to catch it and add it to the tasks panel
                    const taskIndex = getCacheEntry().data?.results?.findIndex(({ id }) => id === updatedTask.id);
                    if (taskIndex === undefined || taskIndex === -1) {
                        onNewTaskAvailable(updatedTask);
                    }
                };

                try {
                    // wait for the initial query to resolve then bind to started event
                    await cacheDataLoaded;
                    Pusher.bind(pusherChannelName, 'started', onNewTaskAvailable);
                    Pusher.bind(pusherChannelName, 'updated', onUpdatedTask);
                } catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                    noop();
                }

                await cacheEntryRemoved; // cache subscription is no longer active

                // clean up
                Pusher.unbind(pusherChannelName, 'started', onNewTaskAvailable);
                Pusher.unbind(pusherChannelName, 'updated', onUpdatedTask);
            },
            providesTags: (_result, _error, { pusherChannelName }) => [{ type: 'BackgroundTask', id: pusherChannelName }],
        }),
    }),
});

export const { useCancelTaskMutation, useTasksSubscriptionQuery } = tasksApi;
