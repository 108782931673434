import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import AccountsAppFnProvider from './AccountsAppFnProvider';
import { applyParamsToPath } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';
import { useAppContext } from '../hooks/context';
import AccountsApp from '../components/submissions-app/AccountsApp';
import { TRANSACTION_PULLDOWN_STATES } from '@archinsurance-viki/property-jslib/src/constants/Constants';

type propTypes = {
    children: React.ReactNode;
    hasLeftPanel?: boolean;
    transactionPulldown?: (typeof TRANSACTION_PULLDOWN_STATES)[keyof typeof TRANSACTION_PULLDOWN_STATES];
    noCurrentSubmission?: boolean;
    topPanelHeader?: React.ReactNode;
    hasActionPanel?: boolean;
};

export default function AccountsAppContainer(props: propTypes) {
    const appContext = useAppContext();
    const match = useRouteMatch();
    const history = useHistory();
    const { submissionId } = useParams<{ submissionId: string }>();

    const onNavigate = (pathData: Record<string, any>, replace = false, subRoute?: string, queryStringParams?: Record<string, any>) => {
        let { params, path } = match;

        params = { ...params, ...pathData };

        path = applyParamsToPath(path, params, subRoute, window.location.search, queryStringParams);
        if (path !== window.location.pathname + window.location.search) {
            // only change the path if it is actually changing
            return history[replace ? 'replace' : 'push'](path);
        }
    };

    const onSetCurrentSubmission = (aId?: number, sId?: number | string, replace?: boolean, subRoute?: string | null, params: Record<string, any> = {}) => {
        const accountId = aId === undefined ? appContext.currentAccountId : aId;
        // Only add `selected=${sid}` to url if the submissionId is missing from url path
        const queryStringParams = !submissionId ? { selected: accountId > 0 ? accountId : null } : undefined;

        onNavigate({ ...params }, replace, subRoute, queryStringParams);
    };

    const onSetCurrentQuote = (quoteId: number, replace?: boolean, subRoute?: string) => {
        onNavigate({ quoteId }, replace, subRoute);
    };

    return (
        <AccountsAppFnProvider onNavigate={onNavigate} onSetCurrentSubmission={onSetCurrentSubmission} onSetCurrentQuote={onSetCurrentQuote}>
            <AccountsApp
                hasLeftPanel={props.hasLeftPanel ?? true}
                transactionPulldown={props.transactionPulldown ?? TRANSACTION_PULLDOWN_STATES.HIDDEN}
                noCurrentSubmission={props.noCurrentSubmission}
                topPanelHeader={props.topPanelHeader}
                hasActionPanel={props.hasActionPanel}
            >
                {props.children}
            </AccountsApp>
        </AccountsAppFnProvider>
    );
}
