import '@archinsurance-viki/property-jslib/ts-typings/conditionals';
require('./styles/index.scss');
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// TODO: upgrade to actual react 18
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { INITIAL_NAVIGATION } from './constants/Navigation';

// Initialize API before loading any of our pages, that way they can use API.BASEURL
import { initializeAPI, initializeConstants, initializePusherEvents } from '@archinsurance-viki/property-jslib';
import * as API_CONFIG from './utils/setup-endpoints';
initializeAPI(API_CONFIG);
import CONSTANTS from './constants/InitAppConstants';
initializeConstants(CONSTANTS);
import PUSHER_EVENTS from './constants/InitAppPusherEvents';
initializePusherEvents(PUSHER_EVENTS);

// Require these manually as the types register themselves.
import './components/common/modals/types';

import TopLevelRoutes from './components/submissions-app/TopLevelRoutes';
import RootContainer from '@archinsurance-viki/property-jslib/src/containers/RootContainer';
import store from './store';
import API from '@archinsurance-viki/property-jslib/src/utils/API';

if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser');
    worker.start({
        onUnhandledRequest: 'bypass',
    });
}

let rootElement = document.getElementById('js-viki-app');

render(
    <Provider store={store}>
        <RootContainer fetchTasks={true} initialNavigation={INITIAL_NAVIGATION(API.BASEURL)}>
            <TopLevelRoutes />
        </RootContainer>
    </Provider>,
    rootElement
);
