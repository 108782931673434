// THIS IS PROVIDED TO JSLIB VIA index.js so that constants here can be used by jslib.
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { LITE_TABLES } from '../constants/LiteTableConfigs';

export default {
    GLOBAL_PUSHER_CHANNEL: 'viki-global-events-channel',
    USER_ACTIVITY_EVENT: 'client-viki-hello',
    DEFAULT_URL: '/submissions',
    APP_NAME: 'Viki',
    TABLE_CONFIGS: { ...PAGED_TABLES, ...LITE_TABLES },
    TASK_TYPES: [
        // NOTE: you need to add the constant here for the push to show up in the task panel.
        'CAT_MODEL',
        'QUOTE_LETTER',
        'QUOTE_PREVIEW',
        'DATA_ENTRY_IMPORT',
        'SUBMIT_TO_TOUCHSTONE',
        'RENEW_SUBMISSION',
        'CLONE_SUBMISSION',
        'BINDER_AGREEMENT',
        'BINDER_PREVIEW',
        'RMS',
        'CREATE_ENDORSEMENT',
        'FETCH_HAZARDHUB',
        'FETCH_PING_DATA',
        'FETCH_MSB',
        'FETCH_INTERMAP',
        'PROCESS_BUILDINGS',
        'CREATE_ACCOUNT_SUMMARY',
        'ENDORSEMENT_PACKAGE',
        'BILLING_STATEMENT',
        'PARSE_SOV',
        'FETCH_BUILDFAX',
        'FETCH_ALL_BUILDFAX',
        'POLICY_ISSUANCE',
        'POLICY_PREVIEW',
        'MISSING_FORMS',
        'FETCH_CAPE_ANALYTICS',
        'FETCH_ALL_CAPE_ANALYTICS',
        'SEND_TO_CLAIMS_VENDOR',
        'CALCULATE_ALL_AIR_PMLS',
        'LOSS_RUN',
        'EXPORT_CAT_DATA',
        'CHANGE_CANCELLATION_EFFECTIVE_DATE',
        'SEARCH_SUBMISSION_POLICYWRITER',
        'UPLOAD_TO_POLICYWRITER',
        'EXPORT_SA_PWCAT_DATA',
    ],
};
