import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tasksApi } from '../services/endpoints/tasks';
import { RootState } from '../store';
import { BackgroundTask } from '../ts-types/DataTypes';

type TaskSliceState = {
    dismissedTasks: number[];
    tasksByAccountId: Record<string, number[]>;
    tasksByTaskType: Record<string, number[]>;
    tasks: {
        byId: Record<string, BackgroundTask>;
        allIds: number[];
    };
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        dismissedTasks: [],
        tasksByAccountId: {},
        tasksByTaskType: {},
        tasks: {
            byId: {},
            allIds: [],
        },
    } as TaskSliceState,
    reducers: {
        dissmissSubscribedTask(state, action: PayloadAction<number>) {
            state.dismissedTasks.push(action.payload);
        },
        updateTask(state, action: PayloadAction<BackgroundTask>) {
            const { payload: task } = action;
            state.tasks.byId[action.payload.id] = task;
            // if task didn't exist, add it to the various tracking fields
            if (!state.tasks.allIds.includes(task.id)) {
                state.tasks.allIds.push(task.id);
                (state.tasksByTaskType[task.task_type] ||= []).unshift(task.id);
                const accountTaskId = task.account_id ?? task.submission_id;
                (state.tasksByAccountId[accountTaskId] ||= []).unshift(task.id);
            }
        },
    },
    extraReducers: builder => {
        builder.addMatcher(tasksApi.endpoints.tasksSubscription.matchFulfilled, (state, { payload, meta }) => {
            // reverse payload results if sorted by last modified so we can preserve order in task panel
            // TODO: This is pretty hacky, we should probably have specific params for ordering and selectedId
            const { filterParams } = meta.arg.originalArgs;
            const results = filterParams?.ordering === '-modified' ? [...payload.results].reverse() : [...payload.results];
            const isSelectedIdRequest = Object.keys(filterParams).length === 1 && Object.keys(filterParams)[0] === 'id';
            results.forEach(task => {
                state.tasks.byId[task.id] = task;
                if (state.tasks.allIds.find(id => id === task.id)) {
                    return;
                }

                state.tasks.allIds.unshift(task.id);

                // TODO: Is there a better way to handle selected tasks
                if (isSelectedIdRequest) {
                    return;
                }

                (state.tasksByTaskType[task.task_type] ||= []).unshift(task.id);

                const accountTaskId = task.account_id ?? task.submission_id;
                (state.tasksByAccountId[accountTaskId] ||= []).unshift(task.id);
            });
        });
    },
});

export const filterVisibleTasks = (state: RootState, tasks: BackgroundTask[]) => tasks.filter(task => !state.tasks.dismissedTasks.includes(task.id));

export const selectTaskById = (state: RootState, taskId: number) => state.tasks.tasks.byId[taskId];
export const selectTasksByIds = (state: RootState, taskIds: number[]) => taskIds?.map(id => selectTaskById(state, id) ?? null) ?? [];

export const selectTasksByAccount = (state: RootState, accountId: number) => selectTasksByIds(state, state.tasks.tasksByAccountId[accountId] ?? []);
export const selectTasksByType = (state: RootState, taskType: string) => selectTasksByIds(state, state.tasks.tasksByTaskType[taskType] ?? []);

export const selectVisibleTasksByAccount = (state: RootState, accountId: number) => filterVisibleTasks(state, selectTasksByAccount(state, accountId));
export const selectVisibleTasksByType = (state: RootState, taskType: string) => filterVisibleTasks(state, selectTasksByType(state, taskType));

const { actions, reducer } = tasksSlice;

export const { dissmissSubscribedTask, updateTask } = actions;

export default reducer;
