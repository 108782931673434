import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormFormatSelection } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormFormatSelection';
import { PolicyTermsFieldName, PolicyTermsFormValues, PolicyTermsFormField, selectDeductibleFormats } from '../utils/form';
import { useAppSelector } from '../../../hooks/redux';
import { DeductibleFormatsType, MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';

type OrdinanceAndLaws = {
    label: string;
    included_name: PolicyTermsFieldName;
    limit?: {
        format: PolicyTermsFieldName;
        pct_amounnt: PolicyTermsFieldName;
        dollar_amount: PolicyTermsFieldName;
        max: PolicyTermsFieldName;
        per_occurrence_limit: PolicyTermsFieldName;
    };
};
const ORDINANCE_AND_LAW_FIELDS: OrdinanceAndLaws[] = [
    {
        label: 'A - Undamaged Portion',
        included_name: 'ord_law_a_limit_uses_building_limit',
    },
    {
        label: 'B - Demolition Costs',
        included_name: 'is_ord_law_b_limit_included',
        limit: {
            format: 'ord_law_b_limit_fmt',
            pct_amounnt: 'ord_law_b_limit_pct_amt',
            dollar_amount: 'ord_law_b_limit_dollar_amt',
            max: 'ord_law_b_limit_max',
            per_occurrence_limit: 'ord_law_b_limit_per_occurrence_limit',
        },
    },
    {
        label: 'C - Increased Cost of Construction',
        included_name: 'is_ord_law_c_limit_included',
        limit: {
            format: 'ord_law_c_limit_fmt',
            pct_amounnt: 'ord_law_c_limit_pct_amt',
            dollar_amount: 'ord_law_c_limit_dollar_amt',
            max: 'ord_law_c_limit_max',
            per_occurrence_limit: 'ord_law_c_limit_per_occurrence_limit',
        },
    },
    {
        label: 'Blanket B & C Coverage',
        included_name: 'is_ord_law_blanket_bc_limit_included',
        limit: {
            format: 'ord_law_blanket_bc_limit_fmt',
            pct_amounnt: 'ord_law_blanket_bc_limit_pct_amt',
            dollar_amount: 'ord_law_blanket_bc_limit_dollar_amt',
            max: 'ord_law_blanket_bc_limit_max',
            per_occurrence_limit: 'ord_law_blanket_bc_limit_per_occurrence_limit',
        },
    },
    {
        label: 'D - Increased Period of Restoration',
        included_name: 'is_increased_period_of_restoration',
    },
];

export const OrdinanceAndLaw = () => {
    const deductibleFormats = useAppSelector(state => selectDeductibleFormats(state));
    return (
        <div className="grid-sub-wrapper align-columns">
            <div className="grid-sub-content grid-sub-5 clmn-headers">
                <div></div>
                <div style={{ width: '130px' }}></div>
                <div>Coverage Amount</div>
                <div>Maximum</div>
                <div>Occurrence Limit</div>
            </div>
            {ORDINANCE_AND_LAW_FIELDS.map(fieldProps => (
                <div key={fieldProps.included_name} className="grid-sub-content grid-sub-5">
                    <div className="input-label flex">{fieldProps.label}</div>
                    <PolicyTermsFormField hideLabel>
                        <FormButtonGroup<PolicyTermsFormValues>
                            name={fieldProps.included_name}
                            items={[
                                { display: 'Include', value: true },
                                { display: 'Exclude', value: false },
                            ]}
                        />
                    </PolicyTermsFormField>
                    {fieldProps.limit ? (
                        <>
                            <PolicyTermsFormField hideLabel>
                                <FormFormatSelection<DeductibleFormatsType, PolicyTermsFormValues>
                                    name={fieldProps.limit.format}
                                    formats={deductibleFormats}
                                    render={format =>
                                        format === 'PERCENTAGE' ? (
                                            <PolicyTermsFormField hideLabel>
                                                <FormInput<PolicyTermsFormValues>
                                                    name={fieldProps.limit.pct_amounnt}
                                                    percentAfter
                                                    maskOptions={MASKS['PERCENTAGE_POSITIVE_INTEGER']}
                                                />
                                            </PolicyTermsFormField>
                                        ) : (
                                            <PolicyTermsFormField hideLabel>
                                                <FormInput<PolicyTermsFormValues>
                                                    name={fieldProps.limit.dollar_amount}
                                                    dollarBefore
                                                    maskOptions={MASKS['CURRENCY_INTEGER']}
                                                />
                                            </PolicyTermsFormField>
                                        )
                                    }
                                />
                            </PolicyTermsFormField>

                            <PolicyTermsFormField hideLabel>
                                <FormInput<PolicyTermsFormValues> name={fieldProps.limit.max} dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                            </PolicyTermsFormField>

                            <PolicyTermsFormField hideLabel>
                                <FormInput<PolicyTermsFormValues> name={fieldProps.limit.per_occurrence_limit} dollarBefore disabled />
                            </PolicyTermsFormField>
                        </>
                    ) : null}
                </div>
            ))}
        </div>
    );
};
