import { TextAreaInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { updateUi } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { changeQuoteStatus_server } from '../../../../actions/QuoteActions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

type propTypes = {
    onClose: (x?: boolean) => void;
    modalData: Record<string, unknown>;
};

// Used by Close Quote and Withdraw Quote
export function AddMessageApp(props: propTypes) {
    const dispatch = useAppDispatch();
    const messageObj = useAppSelector(state => state.uiState.messageObj);

    const onChangeQuoteStatus = (closeWithStatus: string, quoteId: number, message: string, onSuccess: () => void) =>
        dispatch(changeQuoteStatus_server(LITE_TABLES.QUOTES, closeWithStatus, quoteId, message, onSuccess));

    const handleChange = (fieldName: string, value: string) => {
        dispatch(
            updateUi({
                data: { [fieldName]: value },
                dataPath: 'messageObj',
            })
        );
    };

    const handleAddMessage = () => {
        let { quoteId, closeWithStatus, onSuccess } = props.modalData;
        onChangeQuoteStatus(closeWithStatus as string, quoteId as number, messageObj.message, onSuccess as () => void);
        props.onClose();
    };

    if (!props.modalData) return null;

    return (
        <div className="standard-modal-content flex column">
            <TextAreaInput name="message" label="Description" onChange={handleChange} object={messageObj} />
            <div className="button-row">
                <button className="green" onClick={handleAddMessage}>
                    Add
                </button>
                <div className="spacer wide" />
                <button
                    className="red"
                    onClick={() => {
                        props.onClose(true);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default AddMessageApp;
