import * as React from 'react';

import CheckInputClearable from '@archinsurance-viki/property-jslib/src/components/inputs/CheckInputClearable';
import { GetValueType } from '@archinsurance-viki/property-jslib/src/ts-types/InputTypes';
import TextInput from '@archinsurance-viki/property-jslib/src/components/inputs/TextInput';
import { rowComponentPropTypes } from '../QuoteConditionsApp';

type propTypes = {
    inputType?: string;
    inputData?: Record<string, any>;
    onClick: (currentQuote: Record<string, any>, obj: { field: string; value: any; aggregateField: string }) => any;
    currentQuote: Record<string, any>;
    object: Record<string, any>;
    disabled: boolean;
    buildingCount: number;
    documents?: Record<string, any>;
    customQuoteConditionHandler: (currentQuoteId: number, custom_quote_conditions: any[]) => void;
    aggregateField: string;
    checkboxIndex?: number;
    checkboxGroupIndex?: number;
} & rowComponentPropTypes;

const getNumberOfCheckedBuildings = (field: string, buildingQuoteConditionAggregates: Record<string, any>) => {
    return buildingQuoteConditionAggregates[field] || 0;
};

const getQuoteConditionSummary = (numberChecked: number, buildingCount: number) => {
    if (buildingCount > 0 && buildingCount === numberChecked) {
        return 'ALL';
    } else if (numberChecked === 0) {
        return 'NONE';
    } else {
        return 'SELECTED';
    }
};

export class QuoteConditionsRow extends React.Component<propTypes> {
    handleToggleCheck = (field: string, value: any) => {
        const { aggregateField } = this.props;
        this.props.onClick(this.props.currentQuote, { field, value, aggregateField });
    };

    getValue = (_val: any, obj: any) => {
        return obj[this.props.aggregateField] > 0;
    };

    render(): React.ReactNode {
        let { object, inputData, disabled, aggregateField, buildingCount } = this.props;

        if (!object) {
            return null;
        }
        let { label, field } = inputData;
        let numberChecked = getNumberOfCheckedBuildings(aggregateField, object);
        return (
            <div className="flex-table-row row">
                <CheckInputClearable
                    className="width-20"
                    name={field}
                    label={field}
                    labelAfter={true}
                    getValue={this.getValue as GetValueType}
                    object={object}
                    onChange={this.handleToggleCheck}
                    disabled={disabled}
                />
                <div className="width-remaining">{label}</div>
                <div className="width-20 cntr">{getQuoteConditionSummary(numberChecked, buildingCount)}</div>
            </div>
        );
    }
}

export class CustomQuoteConditionsRow extends QuoteConditionsRow {
    handleChange = (field: string, value: any) => {
        let custom_quote_conditions = [...this.props.currentQuote.custom_quote_conditions];
        custom_quote_conditions[this.props.inputData.idx] = value;
        this.props.customQuoteConditionHandler(this.props.currentQuote.id, custom_quote_conditions);
    };

    getValue = (_val: any, obj: any): boolean => {
        return obj[this.props.aggregateField] > 0;
    };

    render(): React.ReactNode {
        let { object, currentQuote, inputData, disabled, aggregateField, buildingCount } = this.props;

        if (!object || !currentQuote || !currentQuote.id) {
            return null;
        }
        let { field } = inputData;
        let numberChecked = getNumberOfCheckedBuildings(aggregateField, object);

        return (
            <div className="flex-table-row row">
                <CheckInputClearable
                    className="width-20"
                    name={field}
                    label={field}
                    labelAfter={true}
                    getValue={this.getValue as GetValueType}
                    object={object}
                    onChange={this.handleToggleCheck}
                    disabled={disabled}
                />
                <div className="width-remaining">
                    <TextInput
                        className="width-remaining"
                        style={{ textAlign: 'left' }}
                        onChange={this.handleChange}
                        object={inputData}
                        name="label"
                        disabled={disabled}
                    />
                </div>
                <div className="width-20 cntr">{getQuoteConditionSummary(numberChecked, buildingCount)}</div>
            </div>
        );
    }
}
