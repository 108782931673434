import { useCallback, useEffect, useRef } from 'react';
import Select from '@archinsurance-viki/property-jslib/src/components/inputs/v2/Select';
import { useAppContext, useAppContextFns } from '../../../hooks/context';
import _ from 'lodash';
import { QUOTE_TRANSITION_STATUSES } from '../../../constants/Quote';
import { openCenteredModal, openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { VIRA_MODAL_TYPES } from '../../../constants/Modal';
import { useFormContext } from 'react-hook-form';
import useQuoteId from '../../../hooks/quotes';
import { usePoilcyTermsBulkUpdateMutation } from '../../../services/endpoints/coverage-terms';
import { useValidateQuery } from '../../../services/endpoints/policy-coverage';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { PolicyTermsFieldName, PolicyTermsFormValues, getFormValuesForSubmit, peeDefaults, peeOverrides } from '../utils/form';
import { useAddCoverageOptionMutation, useCloneCoverageOptionMutation } from '../../../services/endpoints/coverage-option';
import { getAccountSubmissionViewFromServer } from '../../../actions/SubmissionActions';
import { QuoteType } from '../../../ts-types/DataTypes';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { Toast } from 'primereact/toast';
import { useSubmissionIsEditable } from '../../../hooks/submissions';
import { PeeOverridesType, PolicyTermsResult } from '../../../ts-types/ApiTypes';

import { useSubmissionDataQuery } from '../../../services/endpoints/submission';
import { formatQuoteDescription, usePolicyTermsContext } from '../utils/coverage-utils';
import { useAppDispatch } from '../../../hooks/redux';
import { ErrorToast } from '../../../components/common/ToastMessage';
import { useGetPeeDataQuery } from '../../../services/apiSlice';
import { CoverageLevelToggle } from '../components/CoverageLevelToggle';

const CoverageOptionSelect = ({ selectedQuote }: { selectedQuote: QuoteType }) => {
    const { currentSubmissionId } = useAppContext();
    const appContextFns = useAppContextFns();
    const { data } = useSubmissionDataQuery({ id: currentSubmissionId });
    const submissionQuotes = data?.quotes;
    if (!submissionQuotes) {
        return null;
    }
    const quoteDescriptions: [number, string][] = submissionQuotes.map(quote => [quote.id, formatQuoteDescription(quote)]);
    const selectedItem = quoteDescriptions.find(([id, _desc]) => id === selectedQuote.id);
    return (
        <div className="tw-flex-1 tw-min-w-0 tw-flex viki-combo-box txt-standard header-select tw-p-0 tw-border-0 tw-text-sm tw-font-bold tw-bg-white">
            <Select
                className="tw-w-full tw-select-none"
                selectedItemClassname="tw-w-full tw-truncate"
                onChange={([id, _desc]) => appContextFns.onSetCurrentQuote(id)}
                items={quoteDescriptions}
                selectedItem={selectedItem}
            />
        </div>
    );
};

export const PolicyTermsHeader = () => {
    const appContext = useAppContext();
    const dispatch = useAppDispatch();
    const isEditable = useSubmissionIsEditable();

    const { selectedQuoteIds, policyTermsGlossary } = usePolicyTermsContext();

    const toast = useRef<Toast>();

    const { handleSubmit, formState, getValues, reset, setError, setFocus, clearErrors } = useFormContext<PolicyTermsFormValues>();
    const quoteId = useQuoteId();

    const { currentSubmission, currentQuote } = appContext;

    const { data: peeCoverage } = useGetPeeDataQuery({ id: currentQuote.policy_coverage_id });
    const [triggerBulkUpdate, { isLoading: isUpdating }] = usePoilcyTermsBulkUpdateMutation();

    const setFieldErrors = useCallback(
        (errors: Record<string, string[]>) => {
            const errorsList = Object.entries(errors);
            errorsList.forEach(([fieldKey, errorStrings]: [any, string[]]) => {
                setError(fieldKey as any, { type: 'custom', message: errorStrings.join('\n') });
            });
        },
        [setError]
    );

    const { data } = useValidateQuery({ id: currentQuote.policy_coverage_id });

    const { field_errors: fieldErrors, non_field_errors: nonFieldErrors } = data ?? { field_errors: {}, non_field_errors: {} };
    const hasNonFieldErrors = Object.keys(nonFieldErrors).length > 0;
    const hasFieldErrors = Object.keys(fieldErrors).length > 0;
    const hasValidationErrors = hasFieldErrors || hasNonFieldErrors;

    const [triggerCloneCoverageOption, { isLoading: isCloning }] = useCloneCoverageOptionMutation();
    const [triggerAddCoverageOption, { isLoading: isAddingNew }] = useAddCoverageOptionMutation();
    const { dirtyFields, isDirty } = formState;

    useEffect(() => {
        clearErrors();
    }, [isDirty, clearErrors]);

    const onSubmit = async (data: PolicyTermsFormValues) => {
        const submitData = getFormValuesForSubmit<PolicyTermsResult>(data, policyTermsGlossary, dirtyFields);
        if (Object.keys(submitData).length === 0 || !quoteId) {
            return;
        }

        const quoteIds = selectedQuoteIds.length > 0 ? selectedQuoteIds : [quoteId];

        try {
            await triggerBulkUpdate({ quoteIds: quoteIds, data: submitData }).unwrap();
            const peeFormValues = peeDefaults(
                _.pick(submitData, Object.keys(peeOverrides)) as PeeOverridesType,
                peeCoverage,
                (submitData?.is_pee_standard_limits_included as boolean) ?? (getValues().is_pee_standard_limits_included as boolean)
            );
            reset({ ...data, ...peeFormValues }, { keepErrors: true });
        } catch (e: unknown) {
            const errors = (e as any)?.data?.update_errors;
            if (!errors || Object.keys(errors).length === 0) {
                console.error('Unknown error', e);
                return;
            }
            setFieldErrors(errors as Record<string, string[]>);

            // display first 5 errors as toast messages and jump to first in list
            Object.entries(errors as Record<string, string[]>)
                .slice(0, 5)
                .forEach(([field, errors], index) => {
                    if (index === 0) {
                        setFocus(field as PolicyTermsFieldName);
                    }
                    toast.current?.show({
                        severity: 'error',
                        content: <ErrorToast title={`Invalid ${policyTermsGlossary[field].label}`} errorMessage={errors.join('\n')} />,
                    });
                });
        }
    };

    const duplicateAction = () =>
        dispatch(
            openMessageModal(
                {
                    onSubmit: ({ descriptionBody }: { descriptionBody: string }) =>
                        triggerCloneCoverageOption({ quote_id: quoteId, description: descriptionBody }),
                    modalData: { confirmLabelTitle: 'Duplicate', dismissButtonTitle: 'Cancel' },
                },
                VIRA_MODAL_TYPES.ADD_QUOTE_DESCRIPTION
            )
        );

    const addNewAction = () =>
        dispatch(
            openMessageModal(
                {
                    onSubmit: ({ descriptionBody }: { descriptionBody: string }) =>
                        triggerAddCoverageOption({ submission_id: currentSubmission.id, description: descriptionBody }),
                    modalData: { confirmLabelTitle: 'Add New', dismissButtonTitle: 'Cancel' },
                },
                VIRA_MODAL_TYPES.ADD_QUOTE_DESCRIPTION
            )
        );

    const withdrawAction = () => {
        dispatch(
            openCenteredModal(
                {
                    modalData: {
                        quoteId: quoteId,
                        closeWithStatus: QUOTE_TRANSITION_STATUSES.WITHDRAWN.STATUS,
                        onSuccess: () => {
                            dispatch(getAccountSubmissionViewFromServer(currentSubmission.id));
                        },
                    },
                },
                VIRA_MODAL_TYPES.ADD_MESSAGE
            )
        );
    };

    return (
        <div className="viki-tasks">
            <Toast ref={toast} className="tw-absolute tw-top-0 tw-right-0" />
            <div className="flex column w-100 padding-standard">
                <div className="flex fd-r padding-none">
                    <CoverageLevelToggle />
                    <div className="tw-flex tw-w-full">
                        <div className="tw-flex tw-max-w-[750px]">
                            <CoverageOptionSelect selectedQuote={currentQuote} />
                        </div>
                    </div>
                </div>

                <div className="flex fd-r w-100 tw-gap-8">
                    <div className="panelbuttons">
                        <button
                            className="btn green tw-w-16"
                            onClick={e => {
                                // custom valiation errors are persisted and will block handleSubmit unless we clearErrors
                                clearErrors();
                                return handleSubmit(onSubmit)(e);
                            }}
                            disabled={!isEditable || !isDirty || isUpdating}
                        >
                            {isUpdating ? <Spinner color="white" size="base" /> : 'Save'}
                        </button>
                        <button onClick={addNewAction} className="btn blue no-wrap" disabled={!isEditable}>
                            {isAddingNew ? <Spinner color="white" size="base" /> : 'Add New'}
                        </button>
                        <button onClick={duplicateAction} className="btn purple" disabled={!isEditable}>
                            {isCloning ? <Spinner color="white" size="base" /> : 'Duplicate'}
                        </button>
                        {currentQuote.status !== 'WITHDRAWN' && (
                            <button onClick={withdrawAction} className="btn red" disabled={!isEditable}>
                                Withdraw
                            </button>
                        )}
                        <button className="btn grey-dark" onClick={() => reset(undefined, { keepErrors: true })} disabled={!isEditable || !isDirty}>
                            Cancel
                        </button>
                    </div>
                    {hasValidationErrors && (
                        <div className="tw-self-end tw-flex tw-flex-end tw-items-center tw-gap-2 tw-text-orange-dark">
                            <Icon icon="warning_amber" className="tw-text-[24px]" />
                            <span className="tw-text-xl">Validation Errors</span>
                        </div>
                    )}
                    {!isEditable && <p className="tw-self-center tw-text-xl tw-text-red">Warning: This submission is not editable</p>}
                </div>
            </div>
        </div>
    );
};
