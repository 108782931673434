import { PolicyCoverageType } from '../../ts-types/ApiTypes';
import api from '../apiSlice';
import { TenantGlossaryItemType } from '../../ts-types/ApiTypes';
import { cloneDeep } from 'lodash/fp';

const policyCoverageApi = api.injectEndpoints({
    endpoints: builder => ({
        policyCoverage: builder.query<PolicyCoverageType, unknown>({
            query: ({ policyCoverageId }) => ({ url: `v1/policy_coverage/${policyCoverageId}/`, method: 'GET' }),
            providesTags: (_result, _err, { policyCoverageId }) => [{ type: 'PolicyCoverage', id: policyCoverageId }],
        }),
        validate: builder.query<{ field_errors: Record<string, string[]>; non_field_errors: string[] }, { id: number }>({
            query: ({ id }) => ({ url: `v1/policy_coverage/${id}/validate/`, method: 'GET' }),
            providesTags: (_result, _error, { id }) => [{ type: 'PolicyCoverageValidation', id }],
        }),
        deleteQuote: builder.mutation<unknown, { quoteId: number; nextQuoteId?: number }>({
            query: ({ quoteId, nextQuoteId }) => ({
                url: nextQuoteId ? `v1/quotes/${quoteId}/?next_quote_id=${nextQuoteId}` : `v1/quotes/${quoteId}/`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { usePolicyCoverageQuery, useValidateQuery, useDeleteQuoteMutation } = policyCoverageApi;

export const selectActiveFields = <StateType extends Record<string, unknown>>(state: StateType, glossary: Record<string, TenantGlossaryItemType>) => {
    const stateCopy = cloneDeep(state);
    Object.keys(stateCopy).forEach(key => {
        if (glossary?.[key]?.active === false) {
            delete stateCopy[key];
        }
    });
    return stateCopy;
};
