import { BulkEditCoverageOptions } from '../components/BulkEditCoverageOptions';
import { CurrentChanges } from '../components/CurrentChanges';

export const PolicyTermsSidebar = () => {
    return (
        <div className="w-45 tw-min-w-[275px] panelright">
            <div className="info-block subpaneltop resize-on">
                <BulkEditCoverageOptions />
            </div>
            <div className="info-block subpanelbottom resize-on">
                <CurrentChanges />
            </div>
        </div>
    );
};
