import cx from 'classnames';
import _ from 'lodash';
import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { PolicyTermsFieldName, PolicyTermsFormValues, formatForForm } from '../utils/form';
import { useSubmissionIsEditable } from '../../../hooks/submissions';
import { usePolicyTermsFormData } from '../utils/coverage-utils';

export const CurrentChanges = () => {
    const isEditable = useSubmissionIsEditable();
    const { formState, reset, resetField } = useFormContext();
    const [_formData, formGlossary] = usePolicyTermsFormData();
    const { dirtyFields, defaultValues } = formState;
    const formValues = useWatch<PolicyTermsFormValues>();
    return (
        <div className="flex overflow-y-auto h-100 flex-1">
            <div className="grid-layout settings panelgeneral h-100 w-100">
                <div className="flex fd-r table-header">
                    <a
                        className={cx('select-cell material-icons tw-select-none', { 'hover:tw-text-grey-checkbox': !isEditable })}
                        title="Revert All Unsaved Changes"
                        onClick={() => {
                            if (isEditable) {
                                reset(undefined, { keepErrors: true });
                            }
                        }}
                    >
                        undo
                    </a>
                    <div className="data-cell">Current Changes</div>
                </div>
                {Object.keys(dirtyFields).map((key: PolicyTermsFieldName) => {
                    const displayName = formGlossary && key in formGlossary ? formGlossary[key].label : key;
                    const getDisplayValue = (value: unknown) => {
                        let display = value;
                        const glossaryItem = formGlossary[key];
                        const glossaryType = glossaryItem.type;
                        if (glossaryItem?.choices) {
                            display = glossaryItem.choices.find(
                                ({ value: choiceValue }) => choiceValue === formatForForm(glossaryItem)[glossaryType].submit(value)
                            )?.display;
                        }
                        if (isNullish(display) || display === '') {
                            display = '<empty>';
                        }
                        return display;
                    };
                    const fromValue = getDisplayValue(defaultValues[key]);
                    const toValue = getDisplayValue(formValues[key]);
                    return (
                        <div key={key} className="flex fd-r">
                            <a className="select-cell material-icons" title="Revert" onClick={() => resetField(key, { keepError: true })}>
                                undo
                            </a>
                            <div className="data-cell">
                                <span className="changeitem">{displayName}</span>
                                <span> from </span>
                                <span className="changeitem">{fromValue}</span>
                                <span> to </span>
                                <span className="changeitem">{toValue}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
