import { Value } from './PricingGrid';

const RenderMarginalPml = ({ isLoading, numerator, denominator }: { isLoading: boolean; numerator: number; denominator: number }) => {
    return isLoading ? (
        <>
            <div>-</div>
            <div>-</div>
        </>
    ) : (
        <>
            <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={numerator} />
            <Value format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }} value={numerator / +denominator} />
        </>
    );
};

export default RenderMarginalPml;
