import { ColumnType, ExtraDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { NA } from '@archinsurance-viki/property-jslib/src/constants/Constants';

export const calculateBCDisplayVal = (v: number | string, dataObject?: Record<string, any>, _field?: string, extraData?: Record<string, any>) => {
    if (v === null || v === undefined || !extraData || !dataObject || !extraData.ventus_commission_rate) {
        return NA;
    }
    let { pricing } = extraData;

    let value = typeof v === 'string' ? parseFloat(v) : v;

    if (!pricing.gross) {
        let expenseLoadCoefficient = 1.0 - parseFloat(extraData.ventus_commission_rate);
        value = value * expenseLoadCoefficient;
    }

    if (!pricing.dollar) {
        value /= dataObject.tiv || dataObject.latest_tiv || dataObject.latest_tiv__sum;
        value *= 100;
    }
    return value.toFixed(2); // === '0.00' ? NA : value.toFixed(2); I don't know why 0 should become N/A, 0 is a valid thing to print.
};

export const calculateBCFieldFormat = (_column: ColumnType, extraData: ExtraDataType) => {
    if (!extraData) {
        return null;
    }
    let { pricing } = extraData;
    let subtype = pricing.dollar ? 'currency' : 'rate';
    let decimals = pricing.dollar ? 0 : 2;
    return { subtype, decimals };
};

export const getQuoteConditionLabel = (field: string, quoteConditionLabels: Record<string, any>) => {
    if (!quoteConditionLabels || !quoteConditionLabels[field]) {
        return field;
    }
    return `${field} - ${quoteConditionLabels[field].label}`;
};
