import { LITE_TABLE_VIEW_SETTINGS } from './LiteTableViewSettings';
import { COMBINED_TABLE_GLOSSARY } from './CombinedTableGlossary';
import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

const TRANSACTIONS: TableConfigType = {
    apiEndpoint: 'account_transaction_data',
    tableName: 'transactions',
    tableReducerPath: 'transactions',
    rowSubsetPath: 'transactionIds',
    rowDataName: 'Transaction',
    viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.transactions,
    tableGlossary: COMBINED_TABLE_GLOSSARY.transactions,
    showHideRowsFilter: {
        activeLabel: 'Show Reverts',
        inActiveLabel: 'Hide Reverts',
        fnIsActive: (props: any) => props.showingAllRows,
        filters: [
            { name: 'is_active', value: true },
            { name: 'status', value: '!BOUND' },
        ],
    },
};
const DOCUMENTS: TableConfigType = {
    tableName: 'documents',
    tableReducerPath: 'documents',
    rowSubsetPath: 'documentIdList',
    rowDataName: 'Document',
    noCsvDownload: true,
    viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.documents,
    tableGlossary: COMBINED_TABLE_GLOSSARY.documents,
};
const QUOTES: TableConfigType = {
    tableName: 'quotes',
    tableReducerPath: 'quotes',
    rowSubsetPath: 'currentSubmission.quotes',
    rowDataName: 'Quote',
    viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.quotes,
    tableGlossary: COMBINED_TABLE_GLOSSARY.quotes,
};
const TIVGUIDELINES: TableConfigType = {
    tableName: 'tivguidelines',
    tableReducerPath: 'tivGuidelines',
    rowDataName: 'TIV Guidelines',
    viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.tivguidelines,
    tableGlossary: COMBINED_TABLE_GLOSSARY.tivguidelines,
};
const BLANKET_LIMIT_GROUPS: TableConfigType = {
    tableReducerPath: 'blanketLimitGroups',
    tableName: 'blanket_limit_groups',
    apiEndpoint: 'blanket_coverage',
    tableGlossaryName: 'blanketdatasqlview',
    rowDataName: 'Blanket Groups',
    columnsStatic: true,
    dontUseURL: true,
    noTasksDisplay: true,
    viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.blanket_limit_groups,
    tableGlossary: COMBINED_TABLE_GLOSSARY.blanket_limit_groups,
};
const BUILDING_LOCATIONS: TableConfigType = {
    tableName: 'building_locations',
    tableReducerPath: 'buildingLocations',
    rowDataName: 'Building Locations',
    viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.building_locations,
    tableGlossary: COMBINED_TABLE_GLOSSARY.building_locations,
};

export const LITE_TABLES = {
    TRANSACTIONS,
    DOCUMENTS,
    QUOTES,
    TIVGUIDELINES,
    BLANKET_LIMIT_GROUPS,
    BUILDING_LOCATIONS,
};
