import { API } from '@archinsurance-viki/property-jslib';
import { updateSubmissionData } from './SubmissionActions';
import { assert } from '@archinsurance-viki/property-jslib/src/utils/assertions';
import { uRemoveItemFromArrayById, uUpdateItemWithSameId } from '@archinsurance-viki/property-jslib/src/utils/updeep-helpers';
import { AppDispatch } from '../store';

export const deleteAdditionalNamedInsured = (aniID: number, submissionId: number, callback: (x: boolean) => void) => {
    assert(!!aniID, 'Must Have Additional Name Insured Id');
    assert(!!submissionId, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(API.endpoints.additionalNamedInsureds.delete, { params: { aniID }, showProgress: true }, dispatch).then(() => {
            dispatch(
                updateSubmissionData(submissionId, {
                    additional_named_insureds: uRemoveItemFromArrayById(aniID),
                })
            );
            callback(true);
        });
    };
};

export const addOrSaveAdditionalNamedInsured = (ani: Record<string, any>, submissionId: number, callback: (isSucessful: boolean, errorData?: any) => void) => {
    assert(!!ani, 'Must An Additional Name Insured');
    assert(!!submissionId, 'Must Have Submission Id');
    return (dispatch: AppDispatch) => {
        let endpoint = ani.id ? API.endpoints.additionalNamedInsureds.put : API.endpoints.additionalNamedInsureds.post;

        let additionalNamedInsured = ani.id ? ani : Object.assign({}, ani, { submission: submissionId });

        let params = {
            submissionId,
            additionalNamedInsured,
        };

        return API.doRequest(endpoint, { params, showProgress: true, hideDialogForErrors: true }, dispatch)
            .then(({ data }) => {
                dispatch(
                    updateSubmissionData(submissionId, {
                        additional_named_insureds: uUpdateItemWithSameId(data),
                    })
                );
                callback(true);
            })
            .catch(error => {
                if (error.handled) {
                    return;
                }
                callback(false, error.data);
            });
    };
};
