import * as React from 'react';
import _ from 'lodash';
import InfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/InfoBlock';
import { LAYERED_POLICY_INFO_BLOCKS } from '../../../../constants/Quote';
import { PolicyGroupType /*ValidPolicyTypes*/ } from '../../../../ts-types/DataTypes';

type propTypes = {
    onClose: (x?: boolean) => any;
    onSaveAssociatedPolicies: (newPolicies: PolicyGroupType[]) => void;
    associatedPolicies: any[];
    currentSubmissionId: number;
    currentQuoteId: number;
};
type stateTypes = {
    policyGroups: any;
    canSave: boolean;
    validLeadPolicy: boolean;
    rowsDeleted: number; // keep track of the rows that are deleted so we know when to re-render the UI,
};

const LP_GLOSSARY = {
    cn: { name: 'Company Name', field_name: 'company_name' },
    pn: { name: 'Policy Number', field_name: 'policy_number' },
    loi: { name: 'Limit of Insurance', field_name: 'limit_of_insurance' },
    lp: { name: 'Lead Policy', field_name: 'is_lead_policy', type: 'bool' },
};
const LP_COLUMNS = [
    { key: 'cn', className: 'width-33' },
    { key: 'pn', className: 'width-22' },
    { key: 'loi', className: 'width-22' },
    { key: 'lp', className: 'width-22 w-header' },
];

function areEqualShallow(a, b) {
    for (let key in a) {
        if (!(key in b) || a[key] !== b[key]) {
            return false;
        }
    }
    for (let key in b) {
        if (!(key in a) || a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}

function canSaveRow(row) {
    for (let col of Object.values(LP_GLOSSARY)) {
        if (!row[col.field_name] && col.field_name !== 'is_lead_policy') {
            return false;
        }
    }
    return true;
}

function rowHasData(row) {
    if (row) {
        for (let col of Object.values(LP_GLOSSARY)) {
            if (row[col.field_name]) {
                return true;
            }
        }
    }
    return false;
}

function canSavePolicyGroups(policyGroups) {
    for (let block of LAYERED_POLICY_INFO_BLOCKS) {
        let rows = policyGroups[block.key];
        if (rows) {
            for (let row of rows) {
                if (!canSaveRow(row)) {
                    return false;
                }
            }
        }
    }
    return true;
}

function leadPolicySelectionIsValid(policyGroups) {
    let lpcount = 0;
    for (let block of LAYERED_POLICY_INFO_BLOCKS) {
        let rows = policyGroups[block.key];
        if (rows) {
            for (let row of rows) {
                if (row['is_lead_policy']) {
                    lpcount += 1;
                }
            }
        }
    }
    if (lpcount > 1) {
        return false;
    }
    return true;
}

const DEBOUNCE = 1000;

export default class AdditionalNamedInsuredsApp extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);

        const policyGroups = _.groupBy(_.cloneDeep(props.associatedPolicies), 'policy_type');

        this.state = {
            policyGroups,
            canSave: false,
            validLeadPolicy: false,
            rowsDeleted: 0,
        };
    }

    getSaveObject() {
        const saveRows = [];
        for (let block of LAYERED_POLICY_INFO_BLOCKS) {
            let rows = this.state.policyGroups[block.key];
            if (!rows) {
                continue;
            }
            for (let row of rows) {
                if (rowHasData(row)) {
                    saveRows.push(row);
                }
            }
        }
        return saveRows;
    }

    getPolicyGroupCopy(policyType: string): PolicyGroupType[] {
        return this.state.policyGroups[policyType] ? _.cloneDeep(this.state.policyGroups[policyType]) : [];
    }

    updatePolicyGroup(policyType: string, rows: PolicyGroupType[]) {
        const policyGroups = { ...this.state.policyGroups, [policyType]: rows };
        this.setState({
            policyGroups,
            canSave: canSavePolicyGroups(policyGroups),
            validLeadPolicy: leadPolicySelectionIsValid(policyGroups),
        });
    }

    onSave = () => {
        const saveData = this.getSaveObject();
        this.props.onSaveAssociatedPolicies(saveData);
        this.props.onClose();
    };

    deleteRow = ({ index, policy_type }) => {
        const rows = this.getPolicyGroupCopy(policy_type);
        rows.splice(index, 1);
        this.updatePolicyGroup(policy_type, rows);
        this.setState({ rowsDeleted: this.state.rowsDeleted + 1 });
    };

    rowDeleteIsEnabledFn = ({ index, policy_type }) => {
        const rows = this.getPolicyGroupCopy(policy_type);
        return rowHasData(rows[index]);
    };

    onEveryChange = _.debounce((field, value, { policy_type, index }) => {
        console.log(field, value, policy_type, index);

        const rows = this.getPolicyGroupCopy(policy_type);
        if (!rows[index]) {
            rows[index] = {
                quote_id: this.props.currentQuoteId,
                submission_id: this.props.currentSubmissionId,
                policy_type,
            };
        }
        rows[index][field] = value;
        if (!rowHasData(rows[index])) {
            this.deleteRow({ policy_type, index });
        } else {
            this.updatePolicyGroup(policy_type, rows);
        }
    }, DEBOUNCE);

    handleSave = _.debounce((field: string, value: any, { policy_type, index }: Record<string, any>): void => {
        const rows = this.getPolicyGroupCopy(policy_type);
        rows[index][field] = value;
        this.updatePolicyGroup(policy_type, rows);
    }, DEBOUNCE);

    renderBlocks(): React.ReactNode[] {
        return LAYERED_POLICY_INFO_BLOCKS.map((block: any) => {
            const rows = this.state.policyGroups[block.key] || [];
            return (
                <InfoBlock
                    {...block}
                    showColHeaders={true}
                    glossary={LP_GLOSSARY}
                    columns={LP_COLUMNS}
                    helperFunctions={{
                        onEveryChange: this.onEveryChange,
                        handleSave: this.handleSave,
                        deleteRow: this.deleteRow,
                        rowDeleteIsEnabled: this.rowDeleteIsEnabledFn,
                    }}
                    extraData={{ policy_type: block.key }}
                    parentProps={[...rows, {}]}
                />
            );
        });
    }

    render(): React.ReactNode {
        const { rowsDeleted } = this.state;
        let enableSave = this.state.canSave;
        let validLeadPolicy = this.state.validLeadPolicy;
        if (enableSave) {
            const startData = _.sortBy(this.props.associatedPolicies, 'id');
            const saveData = _.sortBy(this.getSaveObject(), 'id');
            if (startData.length === saveData.length) {
                let same = true;
                for (let i = 0; i < startData.length; i++) {
                    if (!areEqualShallow(startData[i], saveData[i])) {
                        same = false;
                        break;
                    }
                }
                if (same) {
                    enableSave = false;
                }
            }
        }

        return (
            <>
                <div key={rowsDeleted} className="standard-modal-content flex column">
                    {this.renderBlocks()}
                </div>
                <If condition={enableSave && !validLeadPolicy}>
                    <div className="center red-txt">ONLY ONE POLICY MAY BE SELECTED AS LEAD POLICY</div>
                </If>
                <div key="buttons" className="button-row">
                    <button className="green" disabled={!enableSave || !validLeadPolicy} onClick={this.onSave}>
                        Save
                    </button>
                    <div className="spacer wide" />
                    <button
                        className="grey-dark"
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        {enableSave && validLeadPolicy ? 'Cancel' : 'Done'}
                    </button>
                </div>
            </>
        );
    }
}
