import moment from 'moment';
import * as React from 'react';
import { PmlDataKeyType, PmlDataType } from '../../ts-types/DataTypes';
import { abbreviateNumber } from './utils';
export const CALC_ON_DATE_FORMAT = 'M/D h:mm A';

type propTypes = {
    data?: PmlDataType[];
    headers: string[];
    selectedDate: Date;
};

const formatRowKey = ({ y, cat_view_type, includes_quoted }: PmlDataKeyType) => {
    const view_type = cat_view_type === 'B' ? 'Broker' : 'Pricing';
    const quoted_status = includes_quoted ? 'Q+B' : 'Bound';
    const title = y.includes('_RATIO') ? `${y.slice(0, 3)} / Premium` : y;
    return `${title}\n${view_type} / ${quoted_status}`;
};

const formRowLabel = (key: string, row: PmlDataType) => (
    <div key={key}>
        <span style={{ display: 'inline-block' }}>
            {key}
            <br />
            <sub style={{ fontWeight: 'normal' }}>as of {moment(row['calculated_on']).format(CALC_ON_DATE_FORMAT)}</sub>
        </span>
    </div>
);

export default class AnalyticsTable extends React.Component<propTypes> {
    render(): React.ReactNode {
        const { data, headers, selectedDate } = this.props;

        // map needs to be ready to create the AnalyticsTable, if not ready just don't render it
        if (typeof data.map !== 'function') return null;

        const headerDivs = [selectedDate.toLocaleDateString(), ...headers].map(h => <div key={h}>{h}</div>);
        const className = `grid-layout gl-${headerDivs.length} pml-tables`;
        const rows = data.map(row => {
            const rowKey = formatRowKey(row.key);
            const label = formRowLabel(rowKey, row);
            const vals = headers.map(h => {
                const val = h === 'COUNT' ? row[h] : abbreviateNumber(row[h], 2);
                return <div key={row[h]}>{val}</div>;
            });
            return (
                <div className={className} key={rowKey}>
                    {[label, ...vals]}
                </div>
            );
        });
        return (
            <>
                <fieldset className="panel-inline">
                    <div className={`${className} pml-table-headers`}>{headerDivs}</div>
                    {rows}
                </fieldset>
            </>
        );
    }
}
