import { DRFPagedResponse } from '@archinsurance-viki/property-jslib/src/ts-types/ApiTypes';
import { BuildingDataType, ExposureType } from '../../ts-types/DataTypes';
import api from '../apiSlice';

export const buildingApi = api.injectEndpoints({
    endpoints: builder => ({
        submissionBuildingData: builder.query<DRFPagedResponse<BuildingDataType>['results'], { submissionId: number }>({
            query: ({ submissionId }) => ({
                url: `v1/building_data/`,
                method: 'GET',
                params: {
                    submission_id: submissionId,
                    fields: 'geo,id,street,city,state,zipcode,county',
                },
            }),
            transformResponse: (result: DRFPagedResponse<BuildingDataType>) => ({
                ...result.results,
            }),
            // TODO: provides Building tag
        }),
        statisticsSummary: builder.query<{ data: ExposureType[] }, unknown>({
            query: ({ submissionId }) => ({ url: `v1/dashboard/statistics`, params: { submission_id: submissionId }, method: 'GET' }),
        }),
    }),
});

export const { useSubmissionBuildingDataQuery } = buildingApi;
