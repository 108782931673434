import { ViewSettingsConfig } from '@archinsurance-viki/property-jslib/src/utils/tables/view-settings';

export const RAE_DOCUMENTS_COLUMNS_WITH_BOUND_ACTIONS = [
    'dt', // document_type
    'd', // description
    'of', // original_filename
    'c', // created
    'rae_bound_actions',
];

export const ALL_COLUMNS_LIST = {
    tivguidelines: [
        'ln', // location_number
        'a', // address
        'og', // occupancy_group
        'aoi', // arch_occupancy_type
        'aci', // arch_construction
        'ctd', // construction_type_display
        'ppc', // ppc
        'is', // is_sprinklered
        'ajt', // adjusted_tiv_after_participation
        'tiv', // tiv
        'g', // guidance
        's', // status
    ],
    transactions: [
        'ssid', // sorted submission id (aka transaction_ordering_key), see VIKI-3576
        'tn', // transaction_number
        'seqn', // sequence_number
        'tt', // transaction_type
        's', // status
        'ed', // endorsement_description
        'efd', // effective_date
        'pd', // date_bound
        'dk', // date_booked
        'nob', // number_of_buildings
        'tiv', // tiv
        'cpbtd', // charged_premium_before_tf_delta
        'atbpt', // air_technical_premium_before_tf
        'rtbpt', // rms_technical_premium_before_tf
        'cpbt', // charged_premium_before_tf
        'ofac', // ofac_status
        'ofaca', // ofac_approver
    ],
    quotes: [
        'i', // id
        'ip', // is_primary
        'sd', // status_display
        'd', // description
        'nob', // number_of_buildings
        'c', // created,
        'ladr', // last_air_date_received
        'lrdr', // last_rms_date_received
        'cpbt', // charged_premium_before_tf
        'apot', // air_pct_of_technical
        'rpot', // rms_pct_of_technical
        'tapf', // total_aop_premium_final
        'atcpf', // air_total_cat_premium_final
        'rtcpf', // rms_total_cat_premium_final
        'atp', // air_total_premium
        'rtp', // rms_total_premium
    ],
    documents: [
        'dt', // document_type
        'd', // description
        'of', // original_filename
        'c', // created
        'v', // av status
        'a', //actions
    ],
    building_locations: ['id', 'pa', 'a'],
};

const tivguidelines: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 100,
        sortable: true,
        resizable: true,
    },
    tableProps: {
        columnList: ALL_COLUMNS_LIST.tivguidelines,
    },
    allColumnsList: ALL_COLUMNS_LIST.tivguidelines,
};
const blanket_limit_groups: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 155,
        sortable: false,
        resizable: false,
    },
    tableProps: {
        sortColumn: 'd',
        sortDirection: 'ASC',
        columnList: ['sbi.l.i', 'sb.l.i', 's.l.i', 'b.l.i', 'i.l.i'],
        fixedColumnList: ['d', 'nob'],
    },
};
const transactions: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 100,
        sortable: false,
        resizable: true,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'ssid',
        sortDirection: 'ASC',
        columnList: ALL_COLUMNS_LIST.transactions,
    },
};
const quotes: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 120,
        sortable: true,
        resizable: true,
    },
    tableProps: {
        columnList: ALL_COLUMNS_LIST.quotes,
    },
};
const documents: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        sortable: true,
        resizable: true,
    },
    tableProps: {
        sortColumn: 'dt',
        sortDirection: 'ASC',
        columnList: ALL_COLUMNS_LIST.documents,
    },
    allColumnsList: ALL_COLUMNS_LIST.documents,
};
const raeBoundDocuments: ViewSettingsConfig = {
    ...documents,
    tableProps: {
        ...documents.tableProps,
        columnList: RAE_DOCUMENTS_COLUMNS_WITH_BOUND_ACTIONS,
    },
    allColumnsList: RAE_DOCUMENTS_COLUMNS_WITH_BOUND_ACTIONS,
};
const building_locations: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 100,
        sortable: false,
        resizable: true,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'ssid',
        sortDirection: 'ASC',
        columnList: ALL_COLUMNS_LIST.building_locations,
    },
    allColumnsList: ALL_COLUMNS_LIST.building_locations,
};

// each item in tabsList here is extended with the main properties and becomes
export const LITE_TABLE_VIEW_SETTINGS = {
    tivguidelines,
    blanket_limit_groups,
    transactions,
    quotes,
    documents,
    raeBoundDocuments,
    building_locations,
};
