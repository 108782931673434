import { batch } from 'react-redux';

import { API } from '@archinsurance-viki/property-jslib';
import { updateTaskProgress } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { closeCenteredModal, openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { reloadTable, updateDataByPath } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';

import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { SubmissionDataType, GoToSubmissionType } from '../ts-types/DataTypes';
import { Types } from '../ts-types/viki-types';
import { hasOutstandingQuotes } from '../utils/quote-helpers';
import { notifyMovedSubmission } from '../utils/ui-helpers';
import {
    clearSubmissionOutcome,
    deleteDocumentFromSubmission,
    getAccountSubmissionViewFromServer,
    setSubmissionOutcomeError,
    updateDocumentData,
    updateSubmissionData,
} from './SubmissionActions';
import { AppDispatch } from '../store';

const SUBLOG_TABLE_CONFIG = PAGED_TABLES.SUBMISSION_LOG;

export const updateSubmissionSuccess = (dispatch: AppDispatch, submissionId: number, message?: string) => {
    dispatch(getAccountSubmissionViewFromServer(submissionId));
    dispatch(
        openMessageModal(
            {
                title: message,
            },
            CENTERED_MODAL_TYPES.CONFIRM
        )
    );
};

export const closeSubmission = (
    submissionId: number,
    outcome: Record<string, any>,
    goToSubmission: GoToSubmissionType,
    actionHelperFns: ActionHelperFnsType
) => {
    return (dispatch: AppDispatch, getState: () => Record<string, any>) => {
        return API.doRequest(
            API.endpoints.submission.close,
            {
                id: submissionId,
                params: outcome,
                showProgress: true,
                hideDialogForErrors: true,
            },
            dispatch
        )
            .then(data => {
                batch(() => {
                    dispatch(clearSubmissionOutcome());
                });
                notifyMovedSubmission(dispatch, submissionId, 'CLOSED', actionHelperFns, goToSubmission, getState());
                return data;
            })
            .catch(xhr => {
                dispatch(setSubmissionOutcomeError(xhr.data.errors));
                throw xhr;
            });
    };
};

export const cloneSubmission = (submissionId: number | null, env: Types.Env, toTest: boolean) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.clone,
            {
                id: submissionId,
                to_site_id: toTest ? 2 : env.SITE.id, // 2 is TEST_SITE_ID.  Ultimately, this behavior should be controlled by a tenant setting.
                showProgress: true,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(updateTaskProgress({ data, taskPath: getTaskPath(data.task_type, data.key_id) }));
                dispatch(closeCenteredModal());
            },
            () => {
                dispatch(closeCenteredModal());
            }
        );
    };
};

export const renewSubmission = (submissionId: number, message?: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.renewal,
            {
                id: submissionId,
                showProgress: true,
                params: { message },
            },
            dispatch
        ).then(({ data }) => {
            batch(() => {
                dispatch(updateTaskProgress({ data, taskPath: getTaskPath(data.task_type, data.key_id) }));
                dispatch(
                    updateSubmissionData(submissionId, {
                        _actions: { renewable: false },
                    })
                );
            });
        });
    };
};

export const changeInsuredFlag = (submissionId: number, status: boolean, message?: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.insuredFlag,
            {
                id: submissionId,
                showProgress: true,
                params: { status, message },
            },
            dispatch
        ).then(data => {
            dispatch(
                updateDataByPath(PAGED_TABLES.SUBMISSION_LOG, `rowData.${submissionId}`, {
                    is_insured_flag: data.data.is_insured_flag,
                    insured_flag_reason: data.data.insured_flag_reason,
                })
            );
        });
    };
};

export const unlockSubmission = (submissionId: number, message: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.unlockSubmission,
            {
                id: submissionId,
                message: message,
            },
            dispatch
        ).then(() => {
            dispatch(reloadTable(SUBLOG_TABLE_CONFIG));
            return updateSubmissionSuccess(dispatch, submissionId, 'Successfully Unlocked');
        });
    };
};

export const relockSubmission = (submissionId: number, message: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.relockSubmission,
            {
                id: submissionId,
                message: message,
            },
            dispatch
        ).then(({ data }) => {
            dispatch(updateTaskProgress({ data, taskPath: getTaskPath(data.task_type, data.key_id) }));
            dispatch(reloadTable(SUBLOG_TABLE_CONFIG));
            return updateSubmissionSuccess(dispatch, submissionId, 'Successfully Relocked - Regenerating Binder');
        });
    };
};

export const reOpenSubmission = (submissionId: number, goToSubmission: GoToSubmissionType, actionHelperFns: ActionHelperFnsType) => {
    return (dispatch: AppDispatch, getState: () => Record<string, any>) => {
        return API.doRequest(
            API.endpoints.submission.reOpen,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        ).then(({ data }) => {
            let changeTo = data.change_to;
            if (changeTo === 'ENTERING_DATA') {
                changeTo = data?.is_rush ? 'RUSH_DATA_ENTRY' : 'DATA_ENTRY';
            }
            notifyMovedSubmission(dispatch, submissionId, changeTo, actionHelperFns, goToSubmission, getState());
        });
    };
};

export const sendToDataEntry = (submissionId: number, isRush: boolean, goToSubmission: GoToSubmissionType, actionHelperFns: ActionHelperFnsType) => {
    return (dispatch: AppDispatch, getState: () => Record<string, any>) => {
        return API.doRequest(
            API.endpoints.submission.sendToDataEntry,
            {
                id: submissionId,
                showProgress: true,
                params: {
                    is_rush: isRush,
                },
            },
            dispatch
        ).then(() => {
            notifyMovedSubmission(dispatch, submissionId, isRush ? 'RUSH_DATA_ENTRY' : 'DATA_ENTRY', actionHelperFns, goToSubmission, getState());
        });
    };
};

export const referEBPrice = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.referEBPrice,
            {
                id: submissionId,
                showProgress: true,
                params: {},
            },
            dispatch
        ).then(() => {
            return updateSubmissionSuccess(dispatch, submissionId, 'Referred to Boiler RE for EB Pricing');
        });
    };
};

export const sendToUwReview = (submission: SubmissionDataType, goToSubmission: GoToSubmissionType, actionHelperFns: ActionHelperFnsType) => {
    return (dispatch: AppDispatch, getState: () => Record<string, any>) => {
        console.log('Sending to UW review', submission);
        return API.doRequest(
            API.endpoints.submission.changeStatus,
            {
                id: submission.id,
                showProgress: true,
                newStatus: 'UW_REVIEW',
            },
            dispatch
        ).then(() => {
            const tab = hasOutstandingQuotes(submission, getState().quotes.rowData) ? 'OUTSTANDING_QUOTES' : 'UW_REVIEW';
            notifyMovedSubmission(dispatch, submission.id, tab, actionHelperFns, goToSubmission, getState());
        });
    };
};

export const proceedToUwReview = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.proceedToUWReview,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        );
    };
};

export const saveDocumentData = (index: number, documentId: number, data: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        // Update here so UI change sticks
        let updatePath = `rowData.${documentId}`;
        dispatch(updateDataByPath(LITE_TABLES.DOCUMENTS, updatePath, data));
        return API.doRequest(
            API.endpoints.submission.saveDocumentData,
            {
                id: documentId,
                params: data,
                showProgress: true,
            },
            dispatch
        ).then(response => {
            // Update it again in case server data changed anything.
            dispatch(updateDataByPath(LITE_TABLES.DOCUMENTS, updatePath, response.data));
        });
    };
};

export const deleteDocument = (documentId: number, submissionId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(deleteDocumentFromSubmission(submissionId, documentId));
        return API.doRequest(
            API.endpoints.submission.deleteDocument,
            {
                id: documentId,
                showProgress: true,
            },
            dispatch
        );
    };
};
export const previewDocuments = (documentIds: number[]) => {
    return (dispatch: AppDispatch) => {
        // Mark GENERATING FOR DOCS WE ARE UPDATING - this makes sure we don't do this again as this is triggered by a render of docs app
        console.log('Getting previews and marking docs as generating manually', documentIds);

        let generating = { preview: { state: 'GENERATING', REQUESTED: true } };

        let updates = {};
        for (let docId of documentIds) {
            updates[docId] = generating;
        }

        dispatch(updateDataByPath(LITE_TABLES.DOCUMENTS, 'rowData', updates));

        return API.doRequest(
            API.endpoints.submission.previewDocuments,
            {
                data: documentIds,
                hideDialogForErrors: true,
            },
            dispatch
        ).then(response => {
            let documents = response.data;

            dispatch(updateDocumentData(documents, { preview: { REQUESTED: true } }));
        });
    };
};

export const parseDocument = (documentId: number, submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.parseDocument,
            {
                id: documentId,
                showProgress: true,
                params: {
                    submission: submissionId,
                },
            },
            dispatch
        );
    };
};

export const exportMergedDataEntrySpreadsheet = (documentId: number, submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.exportMergedDataEntrySpreadsheet,
            {
                id: documentId,
                showProgress: true,
                params: {
                    submission: submissionId,
                },
            },
            dispatch
        ).then(({ data }) => {
            window.location.href = data.url;
        });
    };
};

export const downloadAllSubmissionDocs = (submissionId: number) => {
    return (dispatch: AppDispatch): void => {
        API.doRequest(
            API.endpoints.submission.downloadDocuments,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        ).then(({ data }) => {
            if (data.is_partial_download) {
                dispatch(
                    openMessageModal(
                        {
                            title: 'Warning: some files are missing from this download',
                            modalData: {
                                confirmLabelTitle: 'Download',
                                onOk: () => {
                                    window.location.href = data.url;
                                },
                            },
                        },
                        CENTERED_MODAL_TYPES.CONFIRM
                    )
                );
            } else {
                window.location.href = data.url;
            }
        });
    };
};

export const exportSubmission = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.exportXLS,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        ).then(({ data }) => {
            window.location = data.url;
        });
    };
};

export const claimsDocuments = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.claimsDocuments,
            {
                id: submissionId,
            },
            dispatch
        );
    };
};

export const sendToClaimsVendor = (submissionId: number, docsList: number[], subject: string, sendTo: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.sendToClaimsVendor,
            {
                id: submissionId,
                params: {
                    docsList,
                    subject,
                    sendTo,
                },
            },
            dispatch
        );
    };
};

export const exportCatData = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.exportCatData,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        );
    };
};

export const exportPolicyWriter = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.exportPolicyWriter,
            {
                id: submissionId,
            },
            dispatch
        ).then(({ data }) => {
            window.location.href = data.url;
        });
    };
};

export const uploadPolicyWriter = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.uploadPolicyWriter,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        );
    };
};

export const uploadStrategicAnalytics = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.uploadStrategicAnalytics,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        );
    };
};
