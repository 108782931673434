import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

import { addCustomQuoteCondition_server } from '../../../../actions/QuoteActions';

import AddQuoteConditionApp from '../apps/AddQuoteConditionApp';
import { RootState } from '../../../../store';

type ownPropTypes = {
    onClose: (x?: boolean) => any;
    modalData: Record<string, any>;
    quoteId: number;
    customQuoteConditions: string[];
};

type propTypes = {
    onAddCustomQuoteCondition: (quoteId: number, data: Record<string, any>, callback: (success: boolean, errorData?: any) => any) => any;
    uiState: Record<string, any>;
} & ownPropTypes;

const mapStateToProps = (state: RootState, _ownProps: ownPropTypes) => {
    return {
        uiState: state.uiState,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, _ownProps: ownPropTypes) => {
    return {
        onAddCustomQuoteCondition: (quoteId, data, callback) => {
            return dispatch(addCustomQuoteCondition_server(quoteId, data, callback));
        },
    };
};

class AddQuoteCondition extends React.Component<propTypes> {
    render() {
        return <AddQuoteConditionApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(AddQuoteCondition);
export default connected;
