import React, { useState } from 'react';
import Button from '@archinsurance-viki/property-jslib/src/components/buttons/Button';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';

const DOCUMENT_TYPE_LABELS = {
    ENDORSEMENTS: 'Endorsements',
    POLICY_ISSUANCE: 'Policy Issuance',
    SCHEDULE_OF_LOCATIONS: 'Schedule of Locations',
    SOV: 'SOV',
    OTHER: 'Other Documents',
};

type DocumentRowProps = {
    checked: boolean;
    doc: DocumentDataType;
    onAdd: (doc: DocumentDataType) => void;
    onRemove: (doc: DocumentDataType) => void;
};

const DocumentRow = ({ doc, checked, onAdd, onRemove }: DocumentRowProps) => {
    const { description, original_filename, created } = doc;
    const handleChange = () => {
        checked ? onRemove(doc) : onAdd(doc);
    };
    return (
        <tr>
            <td>
                <input type="checkbox" checked={checked} onChange={handleChange} />
            </td>
            <td>{description}</td>
            <td>{original_filename}</td>
            <td>{new Date(created).toLocaleDateString()}</td>
        </tr>
    );
};

const Header = () => (
    <thead className="bkg-blue">
        <tr>
            <th></th>
            <th>Description</th>
            <th>Filename</th>
            <th>Date Created</th>
        </tr>
    </thead>
);

type DocumentSelectionProps = {
    documents: DocumentDataType[];
    selectedDocs: DocumentDataType[];
    onAddSelection: (doc: DocumentDataType) => void;
    onRemoveSelection: (doc: DocumentDataType) => void;
};

const DocumentSelection = ({ documents, selectedDocs, onAddSelection, onRemoveSelection }: DocumentSelectionProps) => {
    const [documentTableKey, setDocumentTableKey] = useState('');

    // select document type or toggle table visibility if selecting currently active key
    const handleClick = (newKey: string) => {
        setDocumentTableKey(newKey !== documentTableKey ? newKey : '');
    };

    return (
        <div className="document-selection">
            <div className="flex document-selection-tabs">
                {['POLICY_ISSUANCE', 'SOV', 'SCHEDULE_OF_LOCATIONS', 'ENDORSEMENTS', 'OTHER'].map(key => (
                    <Button key={key} className={`default ${key === documentTableKey ? 'active' : ''}`} onClick={() => handleClick(key)}>
                        {DOCUMENT_TYPE_LABELS[key]}
                    </Button>
                ))}
            </div>
            <div className="document-selection-container">
                {documentTableKey && (
                    <table className="document-selection-table">
                        <Header />
                        <tbody>
                            {documents[documentTableKey].map((doc: DocumentDataType) => (
                                <DocumentRow
                                    key={doc.id}
                                    doc={doc}
                                    checked={!!selectedDocs.find(searchDoc => searchDoc.id === doc.id)}
                                    onRemove={onRemoveSelection}
                                    onAdd={onAddSelection}
                                />
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default DocumentSelection;
