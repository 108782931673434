// bulk-cat-modeling/schedule
import { BulkCatModelJob } from '../../ts-types/DataTypes';
import api from '../apiSlice';

export const bulkCatModelApi = api.injectEndpoints({
    endpoints: builder => ({
        bulkCatModelingSchedule: builder.query<{ unavailable_dates: { TS: Record<string, string[]> } }, Record<string, never>>({
            query: () => ({ url: `v1/bulk-cat-modeling/schedule`, method: 'GET' }),
        }),
        createBulkCatModelingJob: builder.mutation<unknown, { document: File; scheduled_date: string; modeling_type: 'TS'; modeling_version: string }>({
            query: ({ document, scheduled_date, modeling_type, modeling_version }) => {
                const formData = new FormData();
                formData.append('document', document);
                formData.append('scheduled_date', scheduled_date);
                formData.append('modeling_type', modeling_type);
                formData.append('modeling_version', modeling_version);
                return { url: `v1/bulk-cat-modeling/upload`, method: 'POST', data: formData, hideAllModalErrors: true };
            },
        }),
        deleteBulkCatModelingJob: builder.mutation<unknown, unknown>({
            query: ({ id }) => ({ url: `v1/bulk-cat-modeling/${id}/`, method: 'DELETE' }),
            invalidatesTags: [{ type: 'BulkCatModelJob', id: 'LIST' }],
        }),
        bulkCatModelingJobs: builder.query<BulkCatModelJob[], { filters: Record<string, string[]> }>({
            query: ({ filters }) => {
                const params = new URLSearchParams();
                Object.entries(filters).forEach(([filterKey, values]) => {
                    params.append(`${filterKey}__in`, values.join(','));
                });
                return { url: 'v1/bulk-cat-modeling/', method: 'GET', params };
            },
            providesTags: [{ type: 'BulkCatModelJob', id: 'LIST' }],
        }),
    }),
});

export const { useBulkCatModelingScheduleQuery, useCreateBulkCatModelingJobMutation, useDeleteBulkCatModelingJobMutation, useBulkCatModelingJobsQuery } =
    bulkCatModelApi;
