import React, { useEffect } from 'react';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import TransactionsApp from './TransactionsApp';
import { LITE_TABLES } from '../../constants/LiteTableConfigs';

import { NO_ACCOUNT } from '../../reducers/accounts';
import { getCarriers_server, getCarriersByTransaction_server } from '../../actions/CarrierActions';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

const mapDispatchToProps = (dispatch: DispatchType) => {
    return {
        onGetCarriers: currentQuoteId => dispatch(getCarriers_server(currentQuoteId)),
        onGetCarriersByTransaction: currentTransactionId => dispatch(getCarriersByTransaction_server(currentTransactionId)),
    };
};

export default function TransactionsPage() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const carriers = useAppSelector(state => state.carriers);
    const dispatchProps = mapDispatchToProps(useAppDispatch());

    const { currentAccount } = appContext;
    const transactionIds = currentAccount?.id !== NO_ACCOUNT ? currentAccount.account_transaction_ids ?? [] : [];

    useEffect(() => {
        // This depends on originalSubmissionId so can't do in initialize. Ok to call over and over.
        appContextFns.onLoadDocumentsIfNeeded();
    });

    return (
        <TransactionsApp
            appContext={appContext}
            tableConfig={LITE_TABLES.TRANSACTIONS}
            transactionIds={transactionIds}
            appContextFns={appContextFns}
            onGetCarriers={dispatchProps.onGetCarriers}
            onGetCarriersByTransaction={dispatchProps.onGetCarriersByTransaction}
            carriers={carriers}
        />
    );
}
