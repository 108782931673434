import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { convertToMillionsBase, formatNumberIntl, VentusNumberFormatOptions } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { QUOTE_TRANSITION_STATUSES } from '../../../constants/Quote';
import { QuoteType } from '../../../ts-types/DataTypes';
import { isString } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import {
    BuildingGroupTermsGlossaryResult,
    BuildingGroupTermsResult,
    BuildingTermsGlossaryResult,
    BuildingTermsResult,
    PolicyCoverageType,
    PolicyTermsGlossaryResult,
    PolicyTermsResult,
} from '../../../ts-types/ApiTypes';
import { createContextAndHelpers } from '@archinsurance-viki/property-jslib/src/hooks/context';

export const POLICY_TERMS_TOGGLES = {
    POLICY_LEVEL: 'policy',
    BUILDING_LEVEL: 'building',
    BUILDING_GROUP: 'building-group',
} as const;

export const POLICY_TERMS_FLAG_MAP = {
    [POLICY_TERMS_TOGGLES.BUILDING_LEVEL]: 'building_terms',
    [POLICY_TERMS_TOGGLES.BUILDING_GROUP]: 'building_group_terms',
} as const;

export const POLICY_TERMS_TOGGLE_LABELS = {
    [POLICY_TERMS_TOGGLES.POLICY_LEVEL]: 'Policy Level',
    [POLICY_TERMS_TOGGLES.BUILDING_LEVEL]: 'Building Level',
    [POLICY_TERMS_TOGGLES.BUILDING_GROUP]: 'Group Level',
} as const;

export type CoverageTermsLevel = (typeof POLICY_TERMS_TOGGLES)[keyof typeof POLICY_TERMS_TOGGLES];

export type PolicyTermsContextType = {
    coverageLevel: CoverageTermsLevel;
    handleCoverageLevelSwitch: (level: CoverageTermsLevel) => void;
    policyTermsData: PolicyTermsResult;
    policyTermsGlossary: PolicyTermsGlossaryResult;
    buildingTermsData: BuildingTermsResult;
    buildingTermsGlossary: BuildingTermsGlossaryResult;
    buildingGroupTermsData: BuildingGroupTermsResult;
    buildingGroupTermsGlossary: BuildingGroupTermsGlossaryResult;
    buildingCoverageId: number;
    buildingGroupCoverageId: number;
    handleBuildingCoverageChange: (bcId: number) => void;
    handleSelectedBuildingCoverageChange: (bId: number) => void;
    handleSelectedQuoteChange: (id: number) => void;
    handleBuildingGroupCoverageChange: (bcgId: number) => void;
    selectedQuoteIds: number[];
    selectedBuildingCoverageIds: number[];
    setSelectedQuoteIds: (ids: number[]) => void;
    setSelectedBuildingCoverageIds: (bcIds: number[]) => void;
};
export const [PolicyTermsContext, PolicyTermsContextProvider, usePolicyTermsContext] = createContextAndHelpers<PolicyTermsContextType>();

export const usePolicyTermsFormData = () => {
    const { coverageLevel, ...contextData } = usePolicyTermsContext();
    if (coverageLevel === 'policy') {
        return [contextData.policyTermsData, contextData.policyTermsGlossary];
    } else if (coverageLevel === 'building') {
        return [contextData.buildingTermsData, contextData.buildingTermsGlossary];
    } else if (coverageLevel === 'building-group') {
        return [contextData.buildingGroupTermsData, contextData.buildingGroupTermsGlossary];
    }
};

export const trimAndParse = (data: string | number) => (isString(data) ? +data.replace(/,/g, '') : data);

export const formatQuoteDescription = (quote: QuoteType) =>
    [quote.status === QUOTE_TRANSITION_STATUSES.WITHDRAWN.STATUS ? `(${quote.status_display})` : '', `Coverage Option (${quote.id}):`, quote.description]
        .join(' ')
        .trimStart();

export const occurrenceParticipationAmountAsPercent = (participation_amount: number, total_amount: number) => {
    return (participation_amount / total_amount) * 100;
};

export const occurrenceDescription = (layered: boolean, limit: number, occurrenceParticipation: number, occurrenceAttachment: number) => {
    if (isNullish(limit) || isNullish(occurrenceParticipation)) {
        return '';
    }

    const occurrenceParticipationDecimal = occurrenceParticipation / 100;
    const currencyFormatOptions: VentusNumberFormatOptions = {
        style: 'currency',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    } as const;

    const occurrenceAmountFormatted = `${formatNumberIntl(convertToMillionsBase(occurrenceParticipationDecimal * limit), currencyFormatOptions)}m`;

    let occurrenceParticipationFormatted = `(${formatNumberIntl(occurrenceParticipationDecimal, { style: 'percent', maximumFractionDigits: 0 })})`;
    if (occurrenceParticipation !== 100) {
        const formattedLimit = `${formatNumberIntl(convertToMillionsBase(limit), currencyFormatOptions)}m`;
        occurrenceParticipationFormatted = `${occurrenceParticipationFormatted} part of ${formattedLimit}`;
    }

    let layerDescription = 'Ground Up';
    if (layered && occurrenceAttachment > 0) {
        layerDescription = `xs of ${formatNumberIntl(convertToMillionsBase(occurrenceAttachment), currencyFormatOptions)}m`;
    } else if (layered) {
        layerDescription = 'Primary';
    }
    return !layered
        ? `${occurrenceAmountFormatted} ${layerDescription}`
        : `${occurrenceAmountFormatted} ${occurrenceParticipationFormatted} ${layerDescription}`;
};

export const OCCURRENCE_FIELDS = [
    'policy_is_layered',
    'occurrence_participation_fmt',
    'occurrence_participation',
    'occurrence_participation_dollar_amt',
    'occurrence_attachment',
    'occurrence_limit',
] as const;

type OccurrenceDescriptionValues = Pick<PolicyCoverageType, (typeof OCCURRENCE_FIELDS)[number]>;
export const getSharedAndLayeredDescription = (occurrence: OccurrenceDescriptionValues, tiv: number) => {
    const {
        policy_is_layered,
        occurrence_participation_fmt,
        occurrence_participation,
        occurrence_participation_dollar_amt,
        occurrence_attachment,
        occurrence_limit,
    } = occurrence;
    // generate occurrence description based on form state
    const isPercentageFormat = occurrence_participation_fmt === 'PERCENTAGE';
    const limit = policy_is_layered ? occurrence_limit : +tiv;
    const participation = isPercentageFormat ? +occurrence_participation : occurrenceParticipationAmountAsPercent(occurrence_participation_dollar_amt, limit);
    let description = '';
    if (limit && participation) {
        description = occurrenceDescription(policy_is_layered, limit, participation, occurrence_attachment);
    }

    return description;
};

export const sublimitShareDesc = (occurrence_limit: number, occurrenceParticipation: number, occurrenceAttachment: number, sublimit: number) => {
    if (isNullish(occurrence_limit) || isNullish(occurrenceParticipation)) {
        return '';
    }
    const occurrenceParticipationDecimal = occurrenceParticipation / 100;
    const currencyFormatOptions: VentusNumberFormatOptions = {
        style: 'currency',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    } as const;

    const limit = Math.min(occurrence_limit, Math.max(0, sublimit - occurrenceAttachment));
    const occurrenceAmountFormatted = `${formatNumberIntl(convertToMillionsBase(occurrenceParticipationDecimal * limit), currencyFormatOptions)}m`;

    let occurrenceParticipationFormatted = '';
    let excess = '';

    if (occurrenceParticipation !== 100) {
        const formattedLimit = `${formatNumberIntl(convertToMillionsBase(limit), currencyFormatOptions)}m`;
        occurrenceParticipationFormatted = `(${formatNumberIntl(occurrenceParticipationDecimal, { style: 'percent', maximumFractionDigits: 2 })})`;
        occurrenceParticipationFormatted = ` ${occurrenceParticipationFormatted} part of ${formattedLimit}`;
    }

    if (occurrenceAttachment > 0) {
        excess = ` xs of ${formatNumberIntl(convertToMillionsBase(occurrenceAttachment), currencyFormatOptions)}m`;
    }

    return `${occurrenceAmountFormatted}${occurrenceParticipationFormatted}${excess}`;
};

export const getSublimitShare = (occurrence: OccurrenceDescriptionValues, sublimit: number) => {
    const {
        policy_is_layered,
        occurrence_participation_fmt,
        occurrence_participation,
        occurrence_participation_dollar_amt,
        occurrence_attachment,
        occurrence_limit,
    } = occurrence;

    if (!policy_is_layered || isNullish(sublimit) || sublimit === 0) {
        return '';
    }

    // generate occurrence description based on form state
    const isPercentageFormat = occurrence_participation_fmt === 'PERCENTAGE';
    const participation = isPercentageFormat
        ? +occurrence_participation
        : occurrenceParticipationAmountAsPercent(occurrence_participation_dollar_amt, occurrence_limit);

    let description = '';
    if (participation) {
        description = sublimitShareDesc(occurrence_limit, participation, occurrence_attachment, sublimit);
    }

    return description;
};
