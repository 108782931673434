import * as React from 'react';

import { formatCurrency, formatPercentage } from '@archinsurance-viki/property-jslib/src/utils/converters';

type propTypes = {
    bldgs: number;
    bldgsPercent: number;
    tiv: number;
    tivPercent: number;
    type: string;
};

export default class ExposureSummaryRow extends React.Component<propTypes> {
    render() {
        let { bldgs, bldgsPercent, tiv, tivPercent, type } = this.props;
        const tivPercentFormatted = formatPercentage(tivPercent);

        return (
            <div className="flex-table-row panel-table-row row">
                <div className="width-30 p-t-cell">
                    <label>{type}</label>
                </div>
                <div className="width-10 p-t-cell numeric">
                    <label>{bldgs}</label>
                </div>
                <div className="width-10 p-t-cell numeric">
                    <label>{formatPercentage(bldgsPercent)}</label>
                </div>
                <div className="width-15 p-t-cell numeric">
                    <label>{formatCurrency(tiv)}</label>
                </div>
                <div className="width-10 p-t-cell numeric">
                    <label>{tivPercentFormatted}</label>
                </div>
                <div className="width-25 p-t-cell">
                    <div className="progress-bar">
                        <div className="bar" style={{ width: tivPercentFormatted }} />
                    </div>
                </div>
            </div>
        );
    }
}
