import * as React from 'react';
import { connect } from 'react-redux';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { updateTransactionData } from '../../../../actions/EndorsementActions';
import EndorsementPricingApp from '../apps/EndorsementPricingApp';
import { RootState } from '../../../../store';
import { TransactionDataType } from '../../../../ts-types/DataTypes';

type ownPropTypes = {
    onClose: (x?: boolean) => any;
    onSaveEndorsementPricing: (
        transaction: any,
        pricing_type: any,
        pricing_override: any,
        equipment_breakdown_external_premium_delta_override: any,
        inspection_fee: any,
        modeling_fee: any
    ) => void;
    transaction: TransactionDataType;
};

type propTypes = {
    prevTransaction: TransactionDataType;
    featureFlags: Record<string, boolean>;
} & ownPropTypes;

const mapStateToProps = (state: RootState, ownProps: ownPropTypes) => {
    let { transaction } = ownProps;
    return {
        prevTransaction: state.transactions.rowData[transaction.previous_transaction_id],
        featureFlags: state.global.featureFlags,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, _ownProps: ownPropTypes) => {
    return {
        onSaveEndorsementPricing: (
            transaction,
            endorsement_pricing_type,
            endorsement_price_delta_override,
            equipment_breakdown_external_premium_delta_override,
            inspection_fee,
            modeling_fee
        ) => {
            dispatch(
                updateTransactionData(transaction.id, {
                    endorsement_pricing_type,
                    endorsement_price_delta_override,
                    equipment_breakdown_external_premium_delta_override,
                    inspection_fee,
                    modeling_fee,
                })
            );
        },
    };
};

class EndorsementPricing extends React.Component<propTypes> {
    render() {
        return <EndorsementPricingApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(EndorsementPricing);
export default connected;
