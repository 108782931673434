import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFieldName, PolicyTermsFormValues, PolicyTermsFormField, selectSortedAlphabeticalChoices } from '../utils/form';

type ClaimsAndCoinsuranceType = {
    label: string;
    claim_valuation: PolicyTermsFieldName;
    coinsurance: PolicyTermsFieldName;
    is_stock?: boolean;
};
const CLAIMS_AND_COINSURANCE_FIELDS: ClaimsAndCoinsuranceType[] = [
    {
        label: 'Building',
        claim_valuation: 'building_limit_claim_valuation',
        coinsurance: 'building_limit_coinsurance',
    },
    {
        label: 'Additional Property',
        claim_valuation: 'appurtenant_structures_claim_valuation',
        coinsurance: 'appurtenant_structures_coinsurance',
    },
    {
        label: 'Tenant Improvements',
        claim_valuation: 'tenant_improvements_and_betterments_claim_valuation',
        coinsurance: 'tenant_improvements_and_betterments_coinsurance',
    },
    {
        label: 'Contents',
        claim_valuation: 'contents_limit_claim_valuation',
        coinsurance: 'contents_limit_coinsurance',
    },
    {
        label: 'Stock',
        claim_valuation: 'stock_limit_claim_valuation',
        coinsurance: 'stock_limit_coinsurance',
        is_stock: true,
    },
    {
        label: 'Property of Others',
        claim_valuation: 'property_of_others_claim_valuation',
        coinsurance: 'property_of_others_coinsurance',
    },
    {
        label: 'Electronic Data Processing',
        claim_valuation: 'electronic_data_processing_claim_valuation',
        coinsurance: 'electronic_data_processing_coinsurance',
    },
];

export const ClaimsAndCoinsurance = () => {
    const VALUATION_TYPE = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'VALUATION_TYPE'));
    const STOCK_VALUATION_TYPE = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'STOCK_VALUATION_TYPE'));
    const COINSURANCE_TYPES = useAppSelector(state => state.global.CONSTANTS.COINSURANCE_TYPES);
    return (
        <div className="grid-sub-wrapper align-columns cac">
            <div className="grid-sub-content clmn-headers">
                <div></div>
                <div>Claims Valuation</div>
                <div>Coinsurance</div>
            </div>
            {CLAIMS_AND_COINSURANCE_FIELDS.map(fieldProps => (
                <div key={fieldProps.label} className="grid-sub-content">
                    <div className="input-label">{fieldProps.label}</div>

                    <PolicyTermsFormField hideLabel>
                        <FormSelect<PolicyTermsFormValues>
                            name={fieldProps.claim_valuation}
                            selections={fieldProps.is_stock ? STOCK_VALUATION_TYPE : VALUATION_TYPE}
                        />
                    </PolicyTermsFormField>

                    <PolicyTermsFormField hideLabel>
                        <FormSelect<PolicyTermsFormValues> name={fieldProps.coinsurance} selections={COINSURANCE_TYPES} />
                    </PolicyTermsFormField>
                </div>
            ))}
        </div>
    );
};
