import * as React from 'react';

import { SubmissionDataType } from '../../ts-types/DataTypes';
import BindingRequirementsRow from './rows/BindingRequirementsRow';
import { INPUT_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { submissionIsEditable } from '../submissionlog/Helpers';

type propTypes = {
    currentSubmission: SubmissionDataType | null;
    currentTransaction: Record<string, any> | null;
    currentQuote: Record<string, any> | null;
    bindingRequirementsData: Record<string, any>;
    onUpdateBindingRequirements: (quoteId: number, data: Record<string, any>) => void;
};

export default class BindingRequirementsApp extends React.Component<propTypes> {
    renderTextboxList(textboxList: Record<string, any>[] = []) {
        let { currentQuote } = this.props;
        let rows = [];
        for (let textbox of textboxList) {
            rows.push(
                <BindingRequirementsRow
                    key={textbox.field}
                    inputType={INPUT_TYPES.TEXTBOX}
                    inputData={textbox}
                    disabled
                    currentQuote={currentQuote}
                    object={this.props.currentQuote.letter_params}
                    onClick={this.props.onUpdateBindingRequirements}
                />
            );
        }
        return rows;
    }

    renderCheckboxList(checkboxList: Record<string, any>[] = []) {
        let { currentQuote, currentSubmission } = this.props;
        let rows = [];
        let disabled = !submissionIsEditable(currentSubmission);
        for (let i = 0; i < checkboxList.length; i++) {
            let checkbox = checkboxList[i];
            rows.push(
                <BindingRequirementsRow
                    key={checkbox.field}
                    inputType={INPUT_TYPES.CHECKBOX}
                    inputData={checkbox}
                    checkboxIndex={i}
                    disabled={checkbox.disabled || disabled}
                    currentQuote={currentQuote}
                    object={this.props.currentQuote.letter_params}
                    onClick={this.props.onUpdateBindingRequirements}
                />
            );
        }
        return rows;
    }

    render() {
        let { currentSubmission, currentQuote, bindingRequirementsData } = this.props;
        if (!currentSubmission || !currentQuote || !currentQuote.id || !bindingRequirementsData) {
            return null;
        }

        return (
            <React.Fragment key="bindingRequirements">
                <div className="section-header">BINDING REQUIREMENTS</div>
                <fieldset className="section-content">
                    {this.renderCheckboxList(bindingRequirementsData.checkboxes)}
                    {this.renderTextboxList(bindingRequirementsData.textboxes)}
                </fieldset>
            </React.Fragment>
        );
    }
}
