import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import _ from 'lodash';
import { Prompt } from 'react-router';
import { ThinBottomPanelWrapper } from '@archinsurance-viki/property-jslib/src/containers/panels/ThinBottomPanelWrapper';
import { BuildingGroupHeader } from './BuildingGroupHeader';
import { BuildingGroupSidebar } from './BuildingGroupSidebar';
import { getFormDefaultsFromGlossary, BuildingTermsFormValues, BuildingTermsFieldName } from '../utils/form';
import { useValidateQuery } from '../../../services/endpoints/policy-coverage';
import { useAppContext } from '../../../hooks/context';
import { useChangeEffect, usePrevUpdate } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { useBeforeUnload } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { getLocationId } from '../../../utils/navurl-helpers';
import { Location } from 'history';
import { ValidationsTable } from '../../ValidationsPanel';
import { usePolicyTermsContext } from '../utils/coverage-utils';
import { BuildingGroupGrid } from './BuildingGroupGrid';
import { BuildingGroupDetails } from './BuildingGroupDetails';
import { BuildingGroupTermsSection } from './BuildingGroupSection';

export const BuildingGroupTerms = () => {
    const { currentQuote } = useAppContext();
    const [isValidationsOpen, setIsValidationsOpen] = useState(false);

    const { buildingGroupTermsData, buildingGroupTermsGlossary } = usePolicyTermsContext();

    const formDefaultValues = getFormDefaultsFromGlossary(buildingGroupTermsData || {}, buildingGroupTermsGlossary || {});
    const formMethods = useForm<BuildingTermsFormValues>({
        defaultValues: formDefaultValues,
        reValidateMode: 'onSubmit',
    });

    useEffect(() => {
        const locationId = getLocationId();
        if (locationId) {
            const el = window.document.getElementById(locationId);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, []);

    const { data: validationData } = useValidateQuery({ id: currentQuote.policy_coverage_id });

    const allValidationErrors = [
        ...Object.values(validationData?.non_field_errors ?? {}).map(message => ({ message })),
        ...Object.entries(validationData?.field_errors ?? {}).map(([field, errors]) => ({
            message: `${buildingGroupTermsGlossary?.[field].label ?? 'Error'}: ${errors.join('\n')}`,
            buttonTitle: 'Jump to Error',
            onClick: () => formMethods.setFocus(field as BuildingTermsFieldName),
        })),
    ];
    const hasValidationErrors = allValidationErrors.length > 0;
    usePrevUpdate(hasValidationErrors, prevHasValidationErrors => {
        if (hasValidationErrors && !prevHasValidationErrors) {
            setIsValidationsOpen(true);
        } else if (!hasValidationErrors && prevHasValidationErrors) {
            setIsValidationsOpen(false);
        }
    });

    useBeforeUnload(!_.isEmpty(formMethods.formState.dirtyFields));

    // Reset override default values when changed via input form or via external event
    // Ensure that we only reset fields that have changed to avoid wiping out user input
    // Note: we have extra isDirty checks in here because the final premium can change our default value for inspection fees
    useChangeEffect(formDefaultValues, (nextDefaults, prevDefaults) => {
        Object.keys(nextDefaults).forEach((key: keyof typeof formDefaultValues) => {
            if (!prevDefaults || prevDefaults[key] !== nextDefaults[key]) {
                formMethods.resetField(key, { defaultValue: nextDefaults[key] });
            }
        });
    });

    return (
        <FormProvider {...formMethods}>
            <div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-hidden">
                <BuildingGroupHeader />
                <Prompt
                    message={(location: Location, _action: string) => {
                        return !location.pathname.includes('coverage_options') && !_.isEmpty(formMethods.formState.dirtyFields)
                            ? `You are leaving this page with active changes, do you want to discard them?`
                            : true;
                    }}
                />
                <div className="ventus-content content-hidden tw-h-full">
                    <div className="fd-r overflow-y-auto h-100">
                        <BuildingGroupGrid />
                    </div>
                </div>
                {false && (
                    <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-4 tw-p-4">
                        <BuildingGroupDetails />
                        <div className="ventus-content tw-p-0 fd-r content-hidden tw-flex-1 tw-h-full">
                            <div className="w-55 flex column overflow-y tw-pr-2 tw-h-full [&_.info-rows:empty]:tw-m-0 [&_.info-rows:empty]:tw-border-0">
                                <BuildingGroupTermsSection groupKey="WIND"></BuildingGroupTermsSection>
                                <BuildingGroupTermsSection groupKey="EARTHQUAKE"></BuildingGroupTermsSection>
                            </div>
                            <BuildingGroupSidebar />
                        </div>
                    </div>
                )}
                <ThinBottomPanelWrapper
                    tabs={[`${allValidationErrors.length} Error${allValidationErrors.length !== 1 ? 's' : ''}`]}
                    activeTabIndex={isValidationsOpen ? 0 : null}
                    onTabSelect={() => setIsValidationsOpen(prev => !prev)}
                    render={() => (
                        <div className="tw-flex tw-flex-col tw-gap-2 tw-h-[300px] tw-pb-8">
                            <h2>Validation Errors</h2>
                            <ValidationsTable validations={allValidationErrors} />
                        </div>
                    )}
                />
            </div>
        </FormProvider>
    );
};
