import cx from 'classnames';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';
import { formatNumberIntl } from '@archinsurance-viki/property-jslib/src/utils/converters';

export const ErrorTooltip = ({ error }: { error?: string }) => (
    <Tooltip content={<span className="tw-break-normal">{error}</span>} wrapChild>
        <Icon className={cx('tw-text-red tw-text-[18px] tw-select-none', { 'tw-invisible': !error })} icon="error" />
    </Tooltip>
);

const defaultModifiedMessage = modelValue =>
    `Modified from the default value of ${formatNumberIntl(modelValue, {
        style: 'currency',
        maximumFractionDigits: 0,
    })}. You can revert to the default value by entering a blank value.`;

export const OverridenTooltip = ({ modelValue, overrideValue, message }: { modelValue: number; overrideValue: number; message?: string }) => (
    <div className={cx('tw-w-4', { 'tw-invisible': !overrideValue })}>
        <Tooltip content={<span className="tw-break-normal">{message ?? defaultModifiedMessage(modelValue)}</span>} wrapChild>
            <Icon className="self-center blue-txt text-sm-no-lh" icon="create" />
        </Tooltip>
    </div>
);
