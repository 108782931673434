import * as React from 'react';

import InfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/InfoBlock';
import LargeTextBlock from '@archinsurance-viki/property-jslib/src/components/blocks/LargeTextBlock';
import { TRANSACTION_STATUS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { RowSelectedFnType, LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { blockPropTypes } from '../../../../ts-types/DataTypes';
import {
    BASIC_DETAILS,
    DATES,
    CAT_PRICING_DETAILS,
    PRICING_DETAILS,
    TRANSACTION_NAMES,
    PRINT_ENDORSEMENT_DESCRIPTION,
    CARRIER_PARTICIPATION,
} from '../../../../constants/TransactionConstants';
import { processColumnsForInfoBlocks } from '@archinsurance-viki/property-jslib/src/utils/tables/columns';
import { AppContextType } from '../../../../ts-types/AppTypes';
import { Types } from '../../../../ts-types/viki-types';
import CarrierParticipationTable from '../../../underwriting/CarrierParticipation';

type propTypes = {
    CONSTANTS: Types.Constants;
    appContext: AppContextType;
    onRowSelected: RowSelectedFnType;
    onSaveFn: (field: string, value: any) => void;
    selectedRowIndex: number;
    tableData: LiteTableDataType;
    tableGlossary: Record<string, any>;
    prevTransaction: Record<string, any>;
    carrierPools: Record<string, any>[];
};

export default class TransactionsBottomPanelApp extends React.Component<propTypes> {
    renderBlocks = () => {
        const { tableGlossary, appContext, onSaveFn, CONSTANTS, prevTransaction, carrierPools } = this.props;
        const { currentTransaction } = appContext;
        const { print_endorsement_description } = currentTransaction;
        if (!currentTransaction) {
            return null;
        }
        let { status } = currentTransaction;

        let infoBlocks: blockPropTypes[];

        let defs = processColumnsForInfoBlocks([], tableGlossary, CONSTANTS);

        if (status === TRANSACTION_STATUS.BOUND || status === TRANSACTION_STATUS.IN_REVIEW) {
            infoBlocks = [BASIC_DETAILS, DATES, CAT_PRICING_DETAILS, PRICING_DETAILS, PRINT_ENDORSEMENT_DESCRIPTION, CARRIER_PARTICIPATION];
        } else {
            infoBlocks = [BASIC_DETAILS, DATES, PRINT_ENDORSEMENT_DESCRIPTION, CARRIER_PARTICIPATION];
        }

        return infoBlocks.map((block: blockPropTypes) => {
            if (block.type === 'TEXT') {
                return (
                    <LargeTextBlock
                        parentProps={{ ...appContext }}
                        {...block}
                        text={print_endorsement_description}
                        onSaveFn={onSaveFn}
                        prevObject={prevTransaction}
                    />
                );
            } else if (block.type === 'TABLE') {
                return <CarrierParticipationTable carrierPools={carrierPools} {...block} parentProps={{ ...appContext }} />;
            } else {
                return (
                    <InfoBlock
                        rowObjectGetter="currentTransaction"
                        parentProps={{ ...appContext }}
                        {...block}
                        glossary={defs}
                        onSaveFn={onSaveFn}
                        prevObject={prevTransaction}
                    />
                );
            }
        });
    };

    render() {
        let { appContext } = this.props;
        let { currentTransactionId, currentTransaction } = appContext;
        if (!currentTransaction) {
            return null;
        }
        let { transaction_type } = currentTransaction;
        const header = `${TRANSACTION_NAMES[transaction_type]} ${currentTransactionId}`;

        return (
            <React.Fragment>
                <div className="abs top-left flex handles">
                    <div className="panel-label handle">
                        <div className="text">{header}</div>
                    </div>
                </div>
                <div className="panel-area">
                    <div className="grid-layout transactions-panel ">{this.renderBlocks()}</div>
                </div>
            </React.Fragment>
        );
    }
}
