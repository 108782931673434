import u from 'updeep';
import * as types from '../constants/ActionTypes';

import { processPipelineGroups } from '../utils/render-helpers/quotes/quote-conditions-helpers';

const initialState: Record<string, any> = {
    binding_requirements: {},
    quote_conditions: {},
    policy_forms: {},
};

// TODO: Replace with pure RTK Query calls
function quotesReducer(state = initialState, action: any): typeof initialState {
    switch (action.type) {
        case types.QUOTE_CHECKBOX_ACTIONS.UPDATE_CHECKBOX_DATA: {
            let pipelineData = processPipelineGroups(action.data);
            const update = {};
            for (let [k, v] of Object.entries(pipelineData)) {
                update[k] = u.constant(v);
            }

            return u(update, state);
        }

        case types.QUOTE_CHECKBOX_ACTIONS.UPDATE_CHECKBOX_DATA_MERGE: {
            return u(action.data, state);
        }

        default:
            return state;
    }
}

export default quotesReducer;
