import { editableEvenIfNotPremiumBearingReadonly } from './CombinedTableGlossary';
import { calculateExposureGroup } from '../utils/exposure-helpers';
import { NOTE_TYPE_ADDITIONAL_DETAILS, NOTE_TYPE_GENERAL_NOTE, NOTE_TYPE_OCCUPANCY_NOTE, NOTE_TYPE_UNDERWRITER_RATIONALE } from './NoteConstants';
import { blockPropTypes } from '../ts-types/DataTypes';
import { RAE_SITE_ID, REAL_SITE_ID } from '../constants/SiteConstants';

export const INFO_PANEL_TYPES = {
    OVERVIEW: 'O',
    SUBMISSION: 'S',
};

const APPEND_NJ_SLA_NUMBER_IF_HOMESTATE_IS_NJ = (homeState: string) => {
    let baseList = ['pbcn', 'bcn', 'bol', 'abi', 'aba', 'lbi', 'lba', 'abe', 'abpn', 'sci', 'sce', 'scpn', 'bof'];
    if (homeState === 'NJ') {
        baseList.push('sn');
    }
    return baseList;
};

const submissionInfoBlocks = {
    general: {
        className: 'grid-2-high',
        rowObjectGetter: 'currentSubmission',
        header: 'General',
        rows: {
            [INFO_PANEL_TYPES.SUBMISSION]: ['s', 'is_renewal', 'ui', 'dre', 'pqds', 'ted', 'texd', 'tqd'],
            [INFO_PANEL_TYPES.OVERVIEW]: ['s', 'is_renewal', 'ui', 'dre', 'pqds', 'ted', 'texd', 'tqd', 'tp'],
        },
    },
    status: {
        className: 'grid-2-high',
        rowObjectGetter: 'currentSubmission',
        header: null,
        // rows: ['s', 'ui', 'dre', 'pqds', 'ted', 'texd', 'tqd', 'pisd', 'pn'],
        rowFn: (ENV, props) => {
            return ENV?.SITE?.id === RAE_SITE_ID
                ? ['s', 'is_renewal', 'ui', 'ai', 'DIVIDER', 'dre', 'pqds', 'ted', 'texd', 'tqd', 'tp']
                : ['s', 'is_renewal', 'ui', 'DIVIDER', 'dre', 'pqds', 'ted', 'texd', 'tqd', 'tp'];
        },
        // TODO:  Once VIKI is ready to include the Analyst field the following line can replace the above rownFn.
        // rows: ['s', 'is_renewal', 'ui', 'ai', 'DIVIDER', 'dre', 'pqds', 'ted', 'texd', 'tqd', 'tp'],
    },
    bound_status: {
        className: 'grid-2-high',
        rowObjectGetter: 'currentSubmission',
        header: null,
        rowFn: (ENV, props) => {
            return ENV?.SITE?.id === RAE_SITE_ID
                ? ['s', 'is_renewal', 'ui', 'ai', 'DIVIDER', 'dre', 'pqds', 'tqd', 'tp', 'pid', 'ped', 'db']
                : ['s', 'is_renewal', 'ui', 'DIVIDER', 'dre', 'pqds', 'tqd', 'tp', 'pid', 'ped', 'db'];
        },
        // TODO:  Once VIKI is ready to include the Analyst field the following line can replace the above rownFn.
        // rows: ['s', 'is_renewal', 'ui', 'ai', 'DIVIDER', 'dre', 'pqds', 'tqd', 'tp', 'pid', 'ped', 'db'],
    },
    loss_history: {
        header: 'Loss History',
        rowObjectGetter: 'currentSubmission',
        rows: ['ilry', 'ilh', 'iiofb', 'iobt'],
    },
    business_info: {
        header: 'Strategy',
        rowObjectGetter: 'currentSubmission',
        rows: {
            [INFO_PANEL_TYPES.SUBMISSION]: ['bstr', 'cpd'],
            [INFO_PANEL_TYPES.OVERVIEW]: ['bs', 'bstr', 'cpd'],
        },
        readonly: true,
    },
    account_info: {
        header: null,
        rowObjectGetter: 'currentSubmission',
        rows: ['is_renewal'],
        readonly: true,
    },
    endorsements: {
        header: 'Endorsements',
        rows: [],
        readonly: true,
    },
    history: {
        header: 'Submission/Quote History',
        rowObjectGetter: 'currentSubmission',
        rows: ['dre', 'pqds', 'pqtapao', 'pqtcpao', 'pqtpao'],
        readonly: true,
    },
    policy: {
        header: 'Policy Information',
        rowObjectGetter: 'currentSubmission',
        rows: ['roi', 'wopn', 'pn', 'pisd', 'iep', 'iec'],
    },
    premium: {
        header: 'Premium (AIR/RMS)',
        rows: ['af', 'cf', 'aact', 'chr_pr', 'potec', 'r', 'ctp'],
        readonly: true,
    },
    property: {
        header: 'Property Info (Primary Quote)',
        rowObjectGetter: 'currentSubmission',
        rows: {
            [INFO_PANEL_TYPES.SUBMISSION]: ['pqtbt', 'pqmbt', 'pqnob'],
            [INFO_PANEL_TYPES.OVERVIEW]: ['pqtbt', 'pqmbt', 'pqnob', 'hs'],
        },
        readonly: true,
    },
    limit: {
        header: 'Limits/Deductibles',
        rows: ['limit', 'ebrk', 'primary_quote_total_bi_limit', 'bip', 'wdr', 'catd', 'aowd', 'aopd', 'wdd', 'max_flood'],
        readonly: true,
    },
    broker: {
        header: 'Broker Information',
        rowObjectGetter: 'currentSubmission',
        rowFn: (ENV, props) => {
            return ENV?.SITE?.id === REAL_SITE_ID
                ? {
                      [INFO_PANEL_TYPES.SUBMISSION]: ['pbcn', 'bcn', 'bol', 'abi'],
                      [INFO_PANEL_TYPES.OVERVIEW]: APPEND_NJ_SLA_NUMBER_IF_HOMESTATE_IS_NJ(props?.home_state),
                  }
                : {
                      [INFO_PANEL_TYPES.SUBMISSION]: ['pbcn', 'bcn', 'bol', 'abi'],
                      [INFO_PANEL_TYPES.OVERVIEW]: ['pbcn', 'bcn', 'bol', 'abi', 'aba', 'pcai', 'lbi', 'lbai', 'abe', 'abpn', 'sci', 'sce', 'scpn', 'bof'],
                  };
        },
    },
    carrier_information: {
        className: 'dynamic-width',
        header: 'Carrier Information',
        readonly: true,
        rowObjectGetter: 'currentSubmission.carrierData',
        overrideMessageFn: props => {
            return [props?.parentProps?.appContext?.currentTransaction?.transaction_type].includes('CFL')
                ? 'Flat cancellations do not have carrier information'
                : null;
        },
        columns: [
            { key: 'carrier_name', className: 'width-50' },
            { key: 'pool_name' },
            { key: 'final_net_participation_pct', className: 'numeric-input' },
            { key: 'actual_allocated_premium' },
        ],
    },
    insured_info: {
        header: 'Insured Information',
        rowObjectGetter: 'currentSubmission',
        rows: ['in', 'istr', 'ic', 'ista', 'iz', 'ibf', 'idopo', 'ofac'],
    },
    insured_info_cont: {
        header: 'Insured Contact Information',
        rowObjectGetter: 'currentSubmission',
        rows: ['icn', 'ice', 'icp'],
    },
    quotes: {
        header: 'Quotes',
        rowObjectGetter: 'quotes',
        columns: [
            { key: 'quote_description', className: 'width-50' },
            { key: 'is_primary', className: 'width-25' },
            { key: 'quote_premium', className: 'width-25', type: 'currency' },
        ],
        readonly: true,
    },
    general_notes: {
        header: 'Notes',
        rowTooltip: 'message',
        alwaysShowAddRowIcon: true,
        type: 'NOTE',
        readonly: true,
        noteType: NOTE_TYPE_GENERAL_NOTE,
        rowObjectGetter: 'currentNotes.GN',
        columns: [
            { key: 'edited_at', className: 'width-25' },
            { key: 'change_by_full_name', className: 'width-25' },
            { key: 'subject', className: 'width-50' },
        ],
    },
    general_renewal_notes: {
        header: 'Renewal Notes',
        rowTooltip: 'message',
        alwaysShowAddRowIcon: false,
        type: 'RENEWAL_NOTE',
        readonly: true,
        noteType: NOTE_TYPE_GENERAL_NOTE,
        rowObjectGetter: 'currentRenewalNotes.GN',
        columns: [
            { key: 'edited_at', className: 'width-25' },
            { key: 'change_by_full_name', className: 'width-25' },
            { key: 'subject', className: 'width-50' },
        ],
    },
    occupancy_notes: {
        type: 'NOTE',
        maxRowCount: 1,
        readonly: () => false,
        header: 'Occupancy Notes',
        alwaysShowAddRowIcon: true,
        noteType: NOTE_TYPE_OCCUPANCY_NOTE,
        rowObjectGetter: 'currentNotes.OC',
        columns: [{ key: 'message', className: 'width-25' }],
    },
    underwriter_notes: {
        type: 'NOTE',
        maxRowCount: 1,
        readonly: () => false,
        alwaysShowAddRowIcon: true,
        header: 'Underwriting Rationale',
        rowObjectGetter: 'currentNotes.UR',
        noteType: NOTE_TYPE_UNDERWRITER_RATIONALE,
        columns: [{ key: 'message', className: 'width-25' }],
    },
    additional_details: {
        type: 'NOTE',
        maxRowCount: 1,
        readonly: () => false,
        alwaysShowAddRowIcon: true,
        header: 'Additional Details',
        rowObjectGetter: 'currentNotes.AD',
        noteType: NOTE_TYPE_ADDITIONAL_DETAILS,
        columns: [{ key: 'message', className: 'width-25' }],
    },
    dist_by_construction: {
        header: 'Distribution by Const Type',
        readonly: true,
        rowObjectGetter: (props: Record<string, any>) => {
            let { currentSubmission } = props;
            if (!currentSubmission || !currentSubmission.statisticsSummary) {
                return [];
            }
            return calculateExposureGroup(currentSubmission.statisticsSummary, 'construction_type');
        },
        columns: [
            { key: 'type', className: 'width-50' },
            { key: 'bldgs', className: 'width-20' },
            { key: 'tiv', className: 'width-30' },
        ],
    },
    dist_by_occupancy: {
        header: 'Distribution by Occupancy',
        readonly: true,
        rowObjectGetter: (props: Record<string, any>) => {
            let { currentSubmission } = props;
            if (!currentSubmission || !currentSubmission.statisticsSummary) {
                return [];
            }
            return calculateExposureGroup(currentSubmission.statisticsSummary, 'occupancy__description');
        },
        columns: [
            { key: 'type', className: 'width-50' },
            { key: 'bldgs', className: 'width-20' },
            { key: 'tiv', className: 'width-30' },
        ],
    },

    additional_name_insureds: {
        type: 'ADDITIONAL_NAME_INSUREDS',
        header: 'Additional Named Insureds',
        showAddRowIcon: true,
        showColHeaders: false,
        readonly: editableEvenIfNotPremiumBearingReadonly,
        filterDisplayedColumns: (c: Record<string, any>) => {
            return c.key === 'name';
        },
        rowObjectGetter: 'currentSubmission.additional_named_insureds',
        columns: [
            { key: 'name', className: 'width-20' },
            { key: 'street', className: 'width-20' },
            { key: 'city', className: 'width-20' },
            { key: 'state', className: 'width-20' },
            { key: 'zipcode', className: 'width-20' },
        ],
    },

    assigned_claims_adjuster: {
        header: 'Assigned Claims Adjuster',
        rowObjectGetter: 'currentSubmission',
        rows: ['uaca', 'acan', 'acat', 'acacn', 'acae', 'acap'],
    },
};

const keyedBlocks = {};
for (let [k, v] of Object.entries(submissionInfoBlocks)) {
    keyedBlocks[k] = { ...v, key: k };
}

export const SUBMISSION_INFOBLOCKS: Record<string, blockPropTypes> = keyedBlocks;
