/* accounts */
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT';
export const RECEIVE_NO_ACCOUNT = 'RECEIVE_NO_ACCOUNT';
export const DISCARD_ENDORSEMENT = 'DISCARD_ENDORSEMENT';

/* user */
export const RECEIVE_USER = 'RECEIVE_USER';
export const RECEIVE_LOGIN_ERROR = 'RECEIVE_LOGIN_ERROR';

/* carriers */
export const SET_CURRENT_CARRIERS = 'SET_CURRENT_CARRIERS';
export const UPDATE_CARRIER = 'UPDATE_CARRIER';
export const RESET_CARRIERS = 'RESET_CARRIERS';

/* submissions */
export const SET_SUBMISSION_OUTCOME = 'SET_SUBMISSION_OUTCOME';
export const SET_SUBMISSION_OUTCOME_ERROR = 'SET_SUBMISSION_OUTCOME_ERROR';
export const CLEAR_SUBMISSION_OUTCOME = 'CLEAR_SUBMISSION_OUTCOME';

/* insured info */
export const UPDATE_INSURED_INFO = 'UPDATE_INSURED_INFO';

export const QUOTE_CHECKBOX_ACTIONS = {
    UPDATE_CHECKBOX_DATA: 'UPDATE_CHECKBOX_DATA',
    UPDATE_CHECKBOX_DATA_MERGE: 'UPDATE_CHECKBOX_DATA_MERGE',
};
/* table */
export const UPDATE_ROW_DATA = 'UPDATE_ROW_DATA';
export const LOAD_ROWS = 'LOAD_ROWS';
export const UPDATE_DATA_BY_PATH = 'UPDATE_DATA_BY_PATH';
export const ADD_OR_DUPLICATE_ROW = 'ADD_OR_DUPLICATE_ROW';

/* User Settings */
export const UPDATE_VISIBLE_COLUMNS = 'UPDATE_VISIBLE_COLUMNS';
export const APPLY_FILTER = 'APPLY_FILTER';

/* DOCUMNETS */
export const UPDATE_SUBMISSION_DOCUMENTS = 'UPDATE_SUBMISSION_DOCUMENTS';
export const LOADING_SUBMISSION_DOCUMENTS = 'LOADING_SUBMISSION_DOCUMENTS';
export const RECEIVE_SUBMISSION_DOCUMENT_IDS = 'RECEIVE_SUBMISSION_DOCUMENT_IDS';
export const DELETE_SUBMISSION_DOCUMENT = 'DELETE_SUBMISSION_DOCUMENT';
export const ADD_SUBMISSION_DOCUMENT = 'ADD_SUBMISSION_DOCUMENT';

/* Policy Forms */
export const TOGGLE_GLOBAL_VIEW = 'TOGGLE_GLOBAL_VIEW';

/* Referral History */
export const SET_REFERRAL_HISTORY = 'SET_REFERRAL_HISTORY';
export const CLEAR_REFERRAL_HISTORY = 'CLEAR_REFERRAL_HISTORY';
