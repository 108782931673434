import { SubmissionDataType } from '../ts-types/DataTypes';

export const filterCoverageOptions = (currentSubmission: any = {}, currentQuote: Record<string, any>): Record<string, any> => {
    // This method should return all errors that pertain to a specific coverage option.  If the error is not tied to any
    // quote, that definitely counts -- it just filters out ones that are relevant only to other quotes.
    const { _validations = {} } = currentSubmission;
    const { WARN, ERROR, detailObjs } = _validations;
    const filterDetailObjs = [];
    const filteredErrorKeys = [];

    const _ERROR: Record<string, any> = {};
    Object.keys(ERROR).map(x => {
        _ERROR[x] = [];
    });
    const _WARN: Record<string, any> = {};
    Object.keys(WARN).map(x => {
        _WARN[x] = [];
    });

    if (detailObjs && WARN && ERROR && currentQuote) {
        const errorKeys = Object.keys(detailObjs);
        for (let i = 0; i < errorKeys.length; i++) {
            let error = detailObjs[errorKeys[i]];
            if (!error.coverage_option_id || error.coverage_option_id === currentQuote.id) {
                filterDetailObjs.push(error);
                filteredErrorKeys.push(errorKeys[i]);
            }
        }

        for (let k in ERROR) {
            for (let j in ERROR[k]) {
                if (filteredErrorKeys.includes(ERROR[k][j])) {
                    _ERROR[k].push(ERROR[k][j]);
                }
            }
        }

        for (let k in WARN) {
            for (let j in WARN[k]) {
                if (filteredErrorKeys.includes(WARN[k][j])) {
                    _WARN[k].push(WARN[k][j]);
                }
            }
        }

        return { detailObjs: filterDetailObjs, WARN: _WARN, ERROR: _ERROR };
    }
};

export const hasOutstandingQuotes = (submission: SubmissionDataType, quotesMap: Record<number, any>): boolean => {
    for (let quoteId of submission.quotes || []) {
        let quote = quotesMap[quoteId] || {};
        if (quote.status === 'SENT_TO_AGENT' || (quote.status === 'INDICATED' && !quote.is_deleted)) {
            return true;
        }
    }

    return false;
};

export const getBuildingQuoteConditionUpdate = (
    { field, value, aggregateField }: Record<string, any>,
    prevAggregates: Record<string, any>,
    buildingCount: number
): Record<string, any> => {
    const aggregates = { ...prevAggregates, [aggregateField]: value ? buildingCount : 0 };
    const serverChange = {
        quote_condition_field: field,
        building_ids_to_set: value ? 'all' : null,
        building_ids_to_unset: value ? null : 'all',
    };

    return {
        serverChange,
        aggregates,
    };
};
