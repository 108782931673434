import * as React from 'react';

import AddMessage from '../containers/AddMessage.container';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    modalData: Record<string, any>;
    onChangeQuoteStatus: (closeWithStatus: string, quoteId: number, message: string, onSuccess: () => void) => any;
    onClose: (x?: boolean) => any;
    uiState: Record<string, any>;
};

class AddMessageBody extends React.Component<bodyPropTypes> {
    render() {
        return <AddMessage {...this.props} />;
    }
}

let modalTypeObj = {
    Body: AddMessageBody,
    Header: DefaultHeaderStatic('Add Message'),
    className: 'add-message-modal',
};
registerType(VIRA_MODAL_TYPES.ADD_MESSAGE, modalTypeObj);
export default modalTypeObj;
