import _ from 'lodash';
import { applyParamsToPath } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';
import { SUBMISSION_SHORTCUT } from '../reducers/accounts';
import { TEST_SITE_ID } from '../constants/SiteConstants';
import API from '@archinsurance-viki/property-jslib/src/utils/API';

export const getDownloadUrlForDocument = (document_id: string | number): string => {
    return `${API.BASEURL}/api/v1/file?document_id=${document_id}`;
};

export const getArchLinkBaseUrl = (ENV: string) => {
    let url;
    switch (ENV) {
        case 'dev':
            url = 'http://www.int.archinsurance.biz/';
            break;
        case 'staging':
            url = 'http://www.stg.archinsurance.biz/';
            break;
        case 'trn':
            url = 'http://www.trn.archinsurance.biz/';
            break;
        case 'prod':
            url = 'http://www.archinsurance.biz/';
            break;
        case 'feature':
            url = 'http://www.trn.archinsurance.biz/';
            break;
        default:
            url = 'http://www.trn.archinsurance.biz/';
    }

    return url;
};

export const getArchLinkSubmissionUrl = (ENV: string, archlinkSubmissionId: string) => {
    if (!archlinkSubmissionId) {
        return;
    }
    let baseUrl = getArchLinkBaseUrl(ENV);
    let submissionId = archlinkSubmissionId.startsWith('S') ? archlinkSubmissionId.slice(1) : archlinkSubmissionId;

    return baseUrl + `BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${submissionId}`;
};

export const getTestSiteBaseUrl = (env: string): string => {
    let _env = '';
    if (env !== 'prod') {
        _env = `-${env}`;
    }
    return `${window.location.protocol}//viki${_env}-test.ventusrisk.com`;
};

export const getUrlFor = (location: Record<string, any>, urlData: Record<string, any>, urlSiteId?: number | string, env?: Record<string, any>): string => {
    const params = location.FIELDS_TO_REPLACE.reduce(function (map, field) {
        map[field] = map[field] || urlData[field] || urlData[_.snakeCase(field)];
        if (!map[field]) {
            // special case for when we don't have an Account Id
            if (field === 'accountId') {
                map[field] = urlData.submissionId || urlData.submission_id;
                map.submissionId = SUBMISSION_SHORTCUT.CURRENT;
            } else {
                console.error(`We do not have data for ${field} to create url.`);
                return null;
            }
        }
        return map;
    }, {});
    const urlWithParams = applyParamsToPath(location.PATH, params);
    if (urlSiteId && env && urlSiteId !== env.SITE.id && urlSiteId === TEST_SITE_ID) {
        let baseUrl = getTestSiteBaseUrl(env.ENV);
        return `${baseUrl}${urlWithParams}`;
    } else {
        return urlWithParams;
    }
};

export const getLocationId = () => {
    const location = window.location;
    if (location && location.hash && location.hash.length > 1) {
        return /^#(.*)/.exec(location.hash)[1] || null;
    }
    return null;
};

export const getArchPWBaseUrl = (ENV: string) => {
    let url;
    switch (ENV) {
        case 'local':
            url = 'http://aigi-srvpwpr01.corp.archcapservices.com:8080/'; // STG
            break;
        case 'dev':
            url = 'http://aigi-irvpwpr01.corp.archcapservices.com:8080/'; // INT
            break;
        case 'staging':
            url = 'http://aigi-srvpwpr01.corp.archcapservices.com:8080/'; // STG
            break;
        case 'feature':
            url = 'http://aigi-urvpwpr01.corp.archcapservices.com:8080/'; // REP
            break;
        case 'prod':
            url = 'http://adkpropapp.corp.archcapservices.com:8080/'; // PROD load balancer
            break;
        case 'uat':
            url = 'http://aigi-urvpwpr01.corp.archcapservices.com:8080/'; // REP
            break;
        default:
            url = 'http://aigi-urvpwpr01.corp.archcapservices.com:8080/'; // REP
    }

    return url;
};

export const getArchSubmissionId = (arch_submission_id?: string) => {
    let archSubmissionId = '';
    if (arch_submission_id !== null) {
        archSubmissionId = arch_submission_id.startsWith('S') ? arch_submission_id.slice(1) : arch_submission_id;
    }
    return archSubmissionId;
};

export const getArchPWSubmissionUrl = (ENV: string, pwMcId: number, userName: string, pwCtId?: number, pwVersionNo?: number) => {
    const user = userName.split('@')[0];
    let baseUrl = getArchPWBaseUrl(ENV);

    const searchParams = new URLSearchParams();
    searchParams.append('pageCode', 'POLICY_DETAILS');
    searchParams.append('MC_ID', (pwMcId || '').toString());
    searchParams.append('CT_ID', (pwCtId || 1).toString());
    searchParams.append('VERSION_NO', (pwVersionNo || 1).toString());
    searchParams.append('sessionInit', user);

    return baseUrl + 'AMPolicyWriter/AMEntryPoint?' + searchParams.toString();
};
