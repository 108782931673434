import { eachDayOfInterval } from 'date-fns';
import { PmlApiResponseType } from '../../ts-types/DataTypes';

export const MAX_SERIES_DISPLAY = 6;
export const CURRENT_YEAR = new Date().getFullYear();
export const yearStart = (year: number) => new Date(year, 0, 1);
export const yearEnd = (year: number) => new Date(year + 1, 0, 0);

const yearRange = (year: number) => eachDayOfInterval({ start: yearStart(year), end: yearEnd(year) });

export const CAT_VIEW = {
    B: 'Broker',
    P: 'Pricing',
};

const Y_AXES = {
    MONEY_SCALE: {
        title: {
            text: '$',
        },
        opposite: false,
    },
    PML_RATIO: {
        title: {
            text: 'PML Ratio',
        },
        opposite: true,
    },
    COUNT: {
        title: {
            text: 'Count',
        },
        opposite: true,
    },
};

type LabelY = 'AEP' | 'OEP' | 'AEP_RATIO' | 'OEP_RATIO' | 'AAL' | 'COUNT' | 'PREMIUM';

const mapToYAxis = (label: LabelY) => {
    const mapper = {
        AEP: 'MONEY_SCALE',
        OEP: 'MONEY_SCALE',
        OEP_RATIO: 'PML_RATIO',
        AEP_RATIO: 'PML_RATIO',
        AAL: 'MONEY_SCALE',
        COUNT: 'COUNT',
        PREMIUM: 'MONEY_SCALE',
    };
    return Y_AXES[mapper[label]];
};

const getData = (data: PmlApiResponseType, selections: Selections): { name: string; data: [Date, number][]; y_label: LabelY } => {
    const x = yearRange(selections.year);
    const y = data.y_values;
    const name = calcualteLabel(data.filter, selections);
    return {
        name,
        data: x.map((date: Date, i: number) => [date, y[i]] as [Date, number]),
        y_label: data.filter.y,
    };
};

export type Series = { name: string; data: [Date, number][] };
type AxisY = {
    title: {
        text: string;
    };
    opposite: boolean;
};
export type Selections = {
    year: number;
    includes_quoteds: string[];
    pmls: string[];
    metrics: string[];
    cat_view_types: string[];
    return_periods: string[];
};
export const formChartData = (data: PmlApiResponseType[], selections: Selections): [Series[], AxisY[]] => {
    const series: Series[] = [];
    const yAxes: AxisY[] = [];
    const addedYAxes = {};
    data.slice(0, MAX_SERIES_DISPLAY).forEach(d => {
        const { name, data, y_label } = getData(d, selections);
        series.push({ name, data });
        const yAxis = mapToYAxis(y_label);
        yAxes.push({
            show: addedYAxes[yAxis.title.text] ? false : true,
            showAlways: true, // ensure y-axis doesn't disapper on series toggle
            seriesName: addedYAxes[yAxis.title.text] || name,
            labels: {
                formatter: (val: string) => {
                    return abbreviateNumber(parseInt(val), 1);
                },
            },
            ...yAxis,
        });
        if (!addedYAxes[yAxis.title.text]) {
            addedYAxes[yAxis.title.text] = name;
        }
    });
    return [series, yAxes];
};

export const calcualteLabel = (filter: Record<string, any>, selections: Selections) => {
    const formIncludesQuoted = (includes_quoted: boolean) => (includes_quoted ? 'Q+B' : 'B');
    const vals = [
        filter.y,
        selections.return_periods.length > 1 ? filter.return_period : '',
        selections.cat_view_types.length > 1 ? CAT_VIEW[filter.cat_view_type] : '',
        selections.includes_quoteds.length > 1 ? formIncludesQuoted(filter.includes_quoted) : '',
    ];
    return vals.filter(str => !!str).join(' / ');
};

export const abbreviateNumber = (number: number, fractionDigits: number) => {
    const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    const tier = (Math.log10(number) / 3) | 0;
    if (tier === 0) return number.toFixed(fractionDigits);
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = number / scale;
    return scaled.toFixed(fractionDigits) + suffix;
};
