import _ from 'lodash';

import { COMBINED_TABLE_GLOSSARY } from '../../../constants/CombinedTableGlossary';

export function getQuoteConditionsHashMap(pipelineData: any) {
    if (!pipelineData) {
        return {};
    }

    let { checkboxGroups, textboxes } = pipelineData.quote_conditions;
    let definedQuoteConditions = {};
    checkboxGroups.map(g => {
        g.checkboxes.map(cb => {
            definedQuoteConditions[cb.field] = cb;
        });
    });

    let customQuoteConditions = {};
    textboxes.map(tb => {
        customQuoteConditions[tb.field] = {
            ...tb,
            short_description: tb.label,
        };
    });

    return Object.assign({}, definedQuoteConditions, customQuoteConditions);
}

export function getQuoteConditionAggregates() {
    const aggregates = [];
    for (let v of Object.values(COMBINED_TABLE_GLOSSARY.building_quote_conditions)) {
        const val: any = v;
        if (val.aggregate) {
            aggregates.push(val.field_name + val.aggregate);
        }
    }

    return aggregates;
}

export function processPipelineGroups(data: Record<string, any>) {
    let returnObj = {};
    for (let [k, v] of Object.entries(data)) {
        let { checkboxes, groups, group_order, ...rest } = v;
        if (checkboxes && groups) {
            let groupMap = {};
            for (let c of checkboxes) {
                let group: Record<string, any> = groupMap[c.group_id];
                if (!group) {
                    group = {};
                    group.id = c.group_id;
                    group.title = groups[c.group_id];
                    group.checkboxes = [];
                    groupMap[c.group_id] = group;
                }
                group.checkboxes.push(c);
            }
            let group: Record<string, any>;
            for (group of Object.values(groupMap)) {
                group.checkboxes = _.sortBy(group.checkboxes, g => (g.display_number !== undefined ? g.display_number : Number(g.field.substring(1))));
            }
            let grouped = group_order ? _.map(group_order, gId => groupMap[gId]) : _.sortBy(Object.values(groupMap), 'title');
            grouped = grouped.filter(g => g !== undefined);
            rest.checkboxGroups = grouped;
            returnObj[k] = rest;
        } else {
            returnObj[k] = v;
        }
    }
    return returnObj;
}
