export const WARNING_DESCRIPTIONS = {
    accountCorrectionExists: 'You are generating a binder on an account that has an existing account correction',
};

export const VIRA_MODAL_TYPES = {
    ADD_MESSAGE: 'ADD_MESSAGE',
    ADD_QUOTE_DESCRIPTION: 'ADD_QUOTE_DESCRIPTION',
    MOVED: 'MOVED',
    CLOSED: 'CLOSED',
    CLONE_SUBMISSION: 'CLONE_SUBMISSION',
    ENDORSEMENT_PRICING: 'ENDORSEMENT_PRICING',
    INITIATE_ENDORSEMENT: 'INITIATE_ENDORSEMENT',
    ADDITIONAL_STRUCTURES: 'ADDITIONAL_STRUCTURES',
    ADDITIONAL_NAMED_INSURED: 'ADDITIONAL_NAMED_INSURED',
    RENEWAL: 'RENEWAL',
    PPE: 'PPE',
    ADD_QUOTE_CONDTION: 'ADD_QUOTE_CONDTION',
    VALUATION_OVERRIDE: 'VALUATION_OVERRIDE',
    CUO_APPROVAL: 'CUO_APPROVAL',
    LAYERED_POLICIES: 'LAYERED_POLICIES',
    OVERRIDE_MODAL: 'OVERRIDE_MODAL',
    WARNINGS: 'WARNINGS',
    SEND_DOCUMENTS: 'SEND_DOCUMENTS',
    SELF_APPROVAL_WARNING: 'SELF_APPROVAL_WARNING',
    OVERRIDE_OFAC: 'OVERRIDE_OFAC',
    SEND_POLICY_DOCS: 'SEND_POLICY_DOCS',
    UPDATE_CANCELLATION_EFFECTIVE_DATE: 'UPDATE_CANCELLATION_EFFECTIVE_DATE',
    CAT_MODELS: 'CAT_MODELS',
} as const;

export const BPP_COVERAGES_ROWS = [
    { prefix: 'accounts_receivable', description: 'Accounts Receivable', col: '1', baseRow: false },
    { prefix: 'asbestos_removal', description: 'Asbestos Removal', col: '2', baseRow: false },
    { prefix: 'builders_risks', description: "Builder's Risks", col: '3', baseRow: false },
    { prefix: null, description: 'Debris Removal', col: '4', baseRow: true },
    {
        prefix: 'debris_removal_percent_loss',
        description: '% of Loss',
        col: '',
        baseRow: false,
        field: 'percentage',
    },
    { prefix: 'debris_removal_maximum', description: 'Maximum', col: '', baseRow: false },
    { prefix: 'debris_removal_additional_limit', description: 'Additional Limit', col: '', baseRow: false },
    { prefix: 'electronic_data_and_media', description: 'Electronic Data and Media', col: '5', baseRow: false },
    { prefix: 'emergency_removal_expense', description: 'Emergency Removal Expense', col: '6', baseRow: false },
    { prefix: 'errors_or_omissions', description: 'Errors or Omissions', col: '7', baseRow: false },
    { prefix: 'fine_arts', description: 'Fine Arts', col: '8', baseRow: false },
    { prefix: 'fire_brigade_charges', description: 'Fire Department Service Charges', col: '9', baseRow: false },
    { prefix: 'fraud_and_deceit', description: 'Fraud and Deceit', col: '10', baseRow: false },
    { prefix: '', description: 'Fungus, Wet Rot, Dry Rot and Bacteria', col: '11', baseRow: true },
    { prefix: 'fungus_per_occurrence', description: 'Per Occurrence', col: '', baseRow: false },
    { prefix: 'fungus_aggregate', description: 'Aggregate', col: '', baseRow: false },
    { prefix: 'green_upgrades', description: 'Green Upgrades', col: '12', baseRow: false },
    { prefix: '', description: 'Leased or Rented Equipment', col: '13', baseRow: true },
    { prefix: 'leased_equipment_per_item_limit', description: 'Per Item Limit', col: '', baseRow: false },
    { prefix: 'leased_equipment_aggregate_limit', description: 'Aggregate Limit', col: '', baseRow: false },
    { prefix: 'leasehold_interest', description: 'Leasehold Interest', col: '14', baseRow: false },
    {
        prefix: 'limited_pollution_coverage',
        description: 'Limited Pollution Coverage (Annual Aggregate) ',
        col: '15',
        baseRow: false,
    },
    { prefix: 'lock_replacement', description: 'Lock Replacement', col: '16', baseRow: false },
    {
        prefix: 'misc_unnamed_locations',
        description: 'Miscellaneous Unnamed Locations',
        col: '17',
        baseRow: false,
    },
    { prefix: '', description: 'Newly Acquired Property', col: '18', baseRow: true },
    {
        error: 'Newly Acquired Property Days',
        prefix: 'newly_acquired_property_days',
        description: 'Days',
        col: '',
        baseRow: false,
        field: 'text',
    },
    {
        error: 'Newly Acquired Property Limit',
        prefix: 'newly_acquired_property_maximum',
        description: 'Maximum',
        col: '',
        baseRow: false,
    },
    { prefix: '', description: 'Outdoor Property', col: '19', baseRow: true },
    {
        prefix: 'plants_per_item_limit',
        description: 'Per Item Limit for Plants, Trees and Shrubs',
        col: '',
        baseRow: false,
    },
    { prefix: 'plants_aggregate_limit', description: 'Aggregate Limit', col: '', baseRow: false },
    { prefix: 'personal_property_of_others', description: 'Personal Property of Others', col: '20', baseRow: false },
    {
        prefix: 'preservation_of_property_days',
        description: 'Preservation of Property (Days)',
        col: '21',
        baseRow: false,
        field: 'text',
    },
    {
        prefix: 'professional_fees',
        description: 'Professional Fees for Claim Preparation (Annual Aggregate)',
        col: '22',
        baseRow: false,
    },
    {
        prefix: 'property_in_course_of_construction',
        description: 'Property in the Course of Construction (non-structural)',
        col: '23',
        baseRow: false,
    },
    {
        prefix: 'recharging_fire_equipment',
        description: 'Recharging of Fire Extinguishing Equipment',
        col: '24',
        baseRow: false,
    },
    {
        prefix: 'reclaiming_land_improvements',
        description: 'Reclaiming, restoring, or repairing land improvements',
        col: '25',
        baseRow: false,
    },
    { prefix: 'reward_reimbursement', description: 'Reward Reimbursement', col: '26', baseRow: false },
    { prefix: '', description: 'Service Interruption Direct Damage', col: '27', baseRow: true },
    { prefix: 'service_interruption_direct_damage', description: 'Direct Damage', col: '', baseRow: false },
    {
        prefix: 'service_interruption_combined',
        description: 'Combined Direct Damage and Time Element Limit',
        col: '',
        baseRow: false,
    },
    { prefix: 'sewer', description: 'Sewer, Drain or Sump Backup or Overflow', col: '28', baseRow: false },
    { prefix: 'transit', description: 'Transit', col: '29', baseRow: false },
    { prefix: 'valuable_papers', description: 'Valuable Papers & Records', col: '30', baseRow: false },
    { prefix: 'wind_driven_precipitation', description: 'Wind-Driven Precipitation', col: '31', baseRow: false },
];

export const TIME_ELEMENT_ROWS = [
    {
        prefix: 'builders_risks_soft_costs',
        description: "Builder's Risks Soft Costs",
        col: '32',
        baseRow: false,
    },
    { prefix: '', description: 'Interruption by Civil or Military Authority', col: '33', baseRow: true },
    {
        error: 'Interruption by Civil or Military Authority Distance Limitation',
        prefix: 'interruption_by_authority_distance',
        description: 'Distance Limitation (miles)',
        col: '',
        baseRow: false,
        field: 'text',
    },
    {
        error: 'Interruption by Civil or Military Authority Weeks',
        prefix: 'interruption_by_authority_weeks',
        description: 'Weeks',
        col: '',
        baseRow: false,
        field: 'text',
    },
    {
        error: 'Interruption by Civil or Military Authority Maxium',
        prefix: 'interruption_by_authority_maximum',
        description: 'Maximum',
        col: '',
        baseRow: false,
    },
    { prefix: 'contingent_time_element', description: 'Contingent Time Element', col: '34', baseRow: false },
    {
        prefix: 'extended_period_of_bi_indemnity',
        description: 'Extended Period of BI Indemnity ',
        col: '35',
        baseRow: false,
        field: 'text',
    },
    { prefix: 'extra_expense', description: 'Extra Expense/Expediting Expense', col: '36', baseRow: false },
    { prefix: '', description: 'Ingress/Egress', col: '37', baseRow: true },
    {
        error: 'Ingress/Egress Distance Limitation',
        prefix: 'ingress_distance',
        description: 'Distance Limitation (miles)',
        col: '',
        baseRow: false,
        field: 'text',
    },
    {
        error: 'Ingress/Egress Weeks',
        prefix: 'ingress_weeks',
        description: 'Weeks',
        col: '',
        baseRow: false,
        field: 'text',
    },
    {
        error: 'Ingress/Egress Limit',
        prefix: 'ingress_maximum',
        description: 'Maximum',
        col: '',
        baseRow: false,
    },
    {
        prefix: 'ordinary_payroll',
        description: 'Ordinary Payroll (days)',
        col: '38',
        baseRow: false,
        field: 'text',
    },
    { prefix: 'royalties', description: 'Royalties', col: '39', baseRow: false },
    { prefix: 'service_interruption_bi', description: 'Service Interruption Time Element', col: '40', baseRow: false },
];
