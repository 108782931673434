import { useHistory } from 'react-router-dom';

import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { reloadTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { getBackgroundTask_server } from '@archinsurance-viki/property-jslib/src/actions/TaskActions';
import BackgroundTaskApp from '../../components/tasks/BackgroundTasksApp';
import { useAppSelector } from '../../hooks/redux';
import { SelectedTaskPanel } from '../../features/AccountTasksPanel';
import { useJslibDispatch } from '@archinsurance-viki/property-jslib/src/hooks/redux';
import AccountsAppContainer from '../AccountsAppContainer';

const FETCHED_TASKS = {};

export default function BackgroundTaskPage() {
    const history = useHistory();
    const tableData = useAppSelector(state => state.backgroundTasks);
    const ENV = useAppSelector(state => state.global.ENV);
    const dispatch = useJslibDispatch();

    const onReloadTable = () => dispatch(reloadTable(PAGED_TABLES.TASKS));

    const onLoadTaskIfNeeded = (taskId?: number, forceLoad?: boolean) => {
        if (taskId && (forceLoad === true || !FETCHED_TASKS[taskId])) {
            console.log('task id fetch:', taskId);
            FETCHED_TASKS[taskId] = true;
            dispatch(getBackgroundTask_server(taskId, PAGED_TABLES.TASKS));
        }
    };

    const currentRowId = tableData.selectedRowId as number;
    const currentTask = tableData.rowData[currentRowId];
    return (
        <AccountsAppContainer topPanelHeader="Tasks">
            <BackgroundTaskApp
                tableData={tableData}
                selectedBackgroundTaskId={currentRowId}
                selectedBackgroundTask={currentRowId ? currentTask : null}
                history={history}
                onReloadTable={onReloadTable}
                onLoadTaskIfNeeded={onLoadTaskIfNeeded}
                ENV={ENV}
                tasksPanel={currentRowId && <SelectedTaskPanel taskId={currentRowId} />}
            />
        </AccountsAppContainer>
    );
}
