import * as React from 'react';
import classNames from 'classnames';
import { batch } from 'react-redux';

import OverviewApp from './OverviewApp';

import { updateNote, getNotes_server, popNoteModal } from '@archinsurance-viki/property-jslib/src/actions/NoteActions';
import { persistRowData_server } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { updateSubmissionData } from '../../actions/SubmissionActions';

import BottomPanelContainer from '@archinsurance-viki/property-jslib/src/containers/panels/BottomPanelContainer';

import { getValidationsPanel } from '../../utils/render-helpers/quotes/quote-validations-helper';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../../components/submissionlog/Helpers';
import { VIRA_MODAL_TYPES } from '../../constants/Modal';
import { AppContextType } from '../../ts-types/AppTypes';
import { groupNotesByNoteType, isInReviewReinstatementOrReversion } from '../../utils/overview-helpers';
import ActionPanel from '@archinsurance-viki/property-jslib/src/components/panels/ActionPanel';

import { AppDispatch, RootState } from '../../store';

import { NoteDataType } from '@archinsurance-viki/property-jslib/src/ts-types/NoteTypes';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';
import { getRenewalNotes_server } from '@archinsurance-viki/property-jslib/src/actions/RenewalNoteActions';
import { useRepriceMutation } from '../../services/apiSlice';
import {
    submissionApi,
    useOldSubmissionOverviewGlossaryQuery,
    useSubmissionOverviewDataQuery,
    useSubmissionOverviewDetailGlossaryQuery,
} from '../../services/endpoints/submission';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { LOADING } from '@archinsurance-viki/property-jslib/src/constants/Constants';

const OVERVIEW_ACTIONS_PANEL = 'OVERVIEW_ACTIONS';

const mapStateToProps = (state: RootState, appContext: AppContextType) => {
    let { notesById } = state.note;
    let { renewalNotesById } = state.renewalNote;

    let currentNotes;
    let notesLoading = false;
    let notesLoaded = false;

    let currentRenewalNotes;
    let renewalNotesLoading = false;
    let renewalNotesLoaded = false;

    if (appContext.currentAccountId) {
        const notes = notesById[appContext.currentAccountId];

        if (notes === LOADING) {
            notesLoading = true;
        } else if (notes !== undefined) {
            currentNotes = groupNotesByNoteType(notes);
            notesLoaded = true;
        }
    }

    if (appContext.currentSubmission) {
        if (appContext.currentSubmission.renewal_of_id) {
            const renewalNotes = renewalNotesById[appContext.currentSubmission.renewal_of_id];
            if (renewalNotes === LOADING) {
                renewalNotesLoading = true;
            } else if (renewalNotes !== undefined) {
                currentRenewalNotes = groupNotesByNoteType(renewalNotes);
                renewalNotesLoaded = true;
            }
        } else {
            renewalNotesLoaded = true;
        }
    }

    return {
        notesLoading,
        notesLoaded,
        currentNotes,
        renewalNotesLoading,
        renewalNotesLoaded,
        currentRenewalNotes,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, appContext: AppContextType) => {
    let { currentAccountId, currentSubmissionId } = appContext;
    return {
        onPopNoteModal: data => {
            dispatch(popNoteModal(data));
        },
        onGetNotes: params => {
            dispatch(getNotes_server(params));
        },
        onGetRenewalNotes: params => {
            dispatch(getRenewalNotes_server(params));
        },
        onAddNote: (note: NoteDataType) => {
            dispatch(updateNote(note, currentAccountId));
        },
        onSaveSubmissionData: persistData => {
            batch(() => {
                dispatch(persistRowData_server(PAGED_TABLES.SUBMISSION_LOG, persistData));
                dispatch(updateSubmissionData(currentSubmissionId, persistData.request.data));
            });
        },
        onAddOrEditAdditionalNamedInsured: (ani: Record<string, any>, data: Record<string, any>) => {
            return dispatch(
                openCenteredModal(
                    {
                        modalData: {
                            additionalNamedInsured: ani,
                            glossaryData: data.glossary,
                            rows: data.columns,
                            submissionId: currentSubmissionId,
                            readOnly: data.readonly,
                        },
                        noDismiss: true,
                    },
                    VIRA_MODAL_TYPES.ADDITIONAL_NAMED_INSURED
                )
            );
        },
    };
};

export default function OverviewPage() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const uiState = useAppSelector(state => state.uiState);
    const stateProps = useAppSelector(state => mapStateToProps(state, appContext));
    const dispatch = useAppDispatch();
    const dispatchProps = mapDispatchToProps(dispatch, appContext);

    React.useEffect(() => {
        loadNotesIfNeeded();
    });

    const loadNotesIfNeeded = () => {
        let { currentAccountId, currentSubmissionId, currentSubmission } = appContext;
        let { notesLoading, notesLoaded, renewalNotesLoaded, renewalNotesLoading } = stateProps;
        if (!currentAccountId || !currentSubmissionId || !currentSubmission || notesLoading || renewalNotesLoading) {
            return;
        }

        if (!notesLoaded) {
            dispatchProps.onGetNotes({ accountId: currentAccountId, submissionId: currentSubmissionId });
        }

        if (!renewalNotesLoaded && currentSubmission.renewal_of_id) {
            dispatchProps.onGetRenewalNotes({
                accountId: currentSubmission.renewal_of_id,
                submissionId: currentSubmission.renewal_of_id,
            });
        }
    };

    const { ENV, currentQuoteId, currentSubmission, currentSubmissionId, currentTransaction, oldWebsite } = appContext;

    const showArchIndexes = ENV.TENANT_SETTINGS.show_arch_indexes;
    const [triggerReprice] = useRepriceMutation({ fixedCacheKey: `${currentQuoteId}` });
    const { data: glossaryData, isError } = useSubmissionOverviewDetailGlossaryQuery({ id: currentSubmissionId }, { skip: !currentSubmissionId });
    const { data: oldGlossaryData, isError: oldIsError } = useOldSubmissionOverviewGlossaryQuery({});
    const { data: overviewData, isError: isDataError } = useSubmissionOverviewDataQuery({ submissionId: currentSubmissionId }, { skip: !currentSubmissionId });

    // PRUW-5777: we reprice only if the data quality indexes are displayed
    React.useEffect(() => {
        if (showArchIndexes && currentQuoteId) {
            dispatch(submissionApi.util.invalidateTags([{ type: 'SubmissionValidation', id: currentSubmissionId }]));
            triggerReprice({ id: currentQuoteId });
        }
    }, [showArchIndexes, currentSubmissionId, currentQuoteId, triggerReprice, dispatch]);

    // VIKI-7198: correct submission for in-review reinstatement/reversion txns is the last_interval_submission
    const [submissionForApp, submissionIdForApp] =
        currentTransaction && isInReviewReinstatementOrReversion(currentTransaction)
            ? [currentTransaction.last_interval_submission_obj, currentTransaction.last_interval_submission_id]
            : [currentSubmission, currentSubmissionId];
    let bottomPanels = [getValidationsPanel(currentSubmission, oldWebsite)];

    if (isError || oldIsError || isDataError) {
        return (
            <>
                <div className="main-content-center">
                    <div className="content-hidden">
                        <AccountTasksPanel />
                        <div className="tw-flex tw-items-center tw-justify-center tw-pt-32 tw-gap-1">
                            <Icon icon="error" className="tw-text-red" />
                            <span>An error occurred. Please try again.</span>
                        </div>
                        <BottomPanelContainer key="bottom-panel" panelName="overview-bottom-panel" panels={bottomPanels} />
                    </div>
                </div>
                <div
                    className={classNames('main-right-panel main-panel', {
                        closed: !uiState.openPanels[OVERVIEW_ACTIONS_PANEL],
                    })}
                >
                    <ActionPanel
                        uiState={uiState}
                        panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.OVERVIEW)}
                        panelName={OVERVIEW_ACTIONS_PANEL}
                        openPanels={uiState.openPanels}
                        tableConfig={PAGED_TABLES.SUBMISSION_LOG}
                    />
                </div>
            </>
        );
    }

    if (!glossaryData || !oldGlossaryData || !overviewData) {
        return (
            <>
                <div className="main-content-center">
                    <div className="content-hidden">
                        <AccountTasksPanel />
                        <BottomPanelContainer key="bottom-panel" panelName="overview-bottom-panel" panels={bottomPanels} />
                    </div>
                </div>
                <div
                    className={classNames('main-right-panel main-panel', {
                        closed: !uiState.openPanels[OVERVIEW_ACTIONS_PANEL],
                    })}
                >
                    <ActionPanel
                        uiState={uiState}
                        panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.OVERVIEW)}
                        panelName={OVERVIEW_ACTIONS_PANEL}
                        openPanels={uiState.openPanels}
                        tableConfig={PAGED_TABLES.SUBMISSION_LOG}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="main-content-center">
                <div className="content-hidden">
                    <AccountTasksPanel />
                    <OverviewApp
                        submissionGlossary={glossaryData}
                        oldGlossaryData={oldGlossaryData}
                        currentNotes={stateProps.currentNotes}
                        currentRenewalNotes={stateProps.currentRenewalNotes}
                        onPopNoteModal={dispatchProps.onPopNoteModal}
                        onAddNote={dispatchProps.onAddNote}
                        onSaveSubmissionData={dispatchProps.onSaveSubmissionData}
                        onAddOrEditAdditionalNamedInsured={dispatchProps.onAddOrEditAdditionalNamedInsured}
                        currentSubmission={submissionForApp}
                        currentSubmissionId={submissionIdForApp}
                    />
                    <BottomPanelContainer key="bottom-panel" panelName="overview-bottom-panel" panels={bottomPanels} />
                </div>
            </div>
            <div
                className={classNames('main-right-panel main-panel', {
                    closed: !uiState.openPanels[OVERVIEW_ACTIONS_PANEL],
                })}
            >
                <ActionPanel
                    uiState={uiState}
                    panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.OVERVIEW)}
                    panelName={OVERVIEW_ACTIONS_PANEL}
                    openPanels={uiState.openPanels}
                    tableConfig={PAGED_TABLES.SUBMISSION_LOG}
                />
            </div>
        </>
    );
}
