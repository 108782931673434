import { API } from '@archinsurance-viki/property-jslib';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { updateDataByPath } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { assert } from '@archinsurance-viki/property-jslib/src/utils/assertions';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';
import { uRemoveItemFromArrayById, uUpdateItemWithSameId } from '@archinsurance-viki/property-jslib/src/utils/updeep-helpers';
import { VIRA_MODAL_TYPES } from '../constants/Modal';
import { AppDispatch } from '../store';
import { updateTaskProgress } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';

export const popValuationOverrideModal = (submissionId: number, data?: Record<string, any>) => {
    let defaultTypes = {
        submissionId: submissionId,
    };
    return (dispatch: AppDispatch) => {
        dispatch(
            openCenteredModal(
                {
                    modalData: Object.assign({}, defaultTypes, data),
                },
                VIRA_MODAL_TYPES.VALUATION_OVERRIDE
            )
        );
    };
};

export const fetchAdditionalStructures = (tableConfig: TableConfigType, building_id: number, submission_id: number) => {
    assert(!!building_id, 'Must Have Building Id');
    assert(!!submission_id, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        // // Update here so UI change sticks
        let updatePath = `rowData.${building_id}`;

        return API.doRequest(API.endpoints.additionalStructures.get, { params: { building: building_id, submission_id } }, dispatch).then(({ data }) => {
            dispatch(updateDataByPath(tableConfig, updatePath, { additional_structures: data }));
        });
    };
};

export const deleteStructure = (
    tableConfig: TableConfigType,
    submissionId: number,
    structureId: number,
    buildingId: number,
    callback: (x: boolean) => void
) => {
    assert(!!submissionId, 'Must Have Submission Id');
    assert(!!structureId, 'Must Have Structure Id');
    assert(!!buildingId, 'Must Have Building Id');

    return (dispatch: AppDispatch) => {
        // // Update here so UI change sticks
        let updatePath = `rowData.${buildingId}`;

        return API.doRequest(API.endpoints.additionalStructures.delete, { params: { structureId }, showProgress: true }, dispatch).then(() => {
            dispatch(
                updateDataByPath(tableConfig, updatePath, {
                    additional_structures: uRemoveItemFromArrayById(structureId),
                })
            );
            callback(true);
        });
    };
};

export const addOrSaveStructure = (
    tableConfig: TableConfigType,
    s: Record<string, any>,
    buildingId: number,
    submissionId: number,
    callback: (wasSuccessful: boolean, errorData?: any) => void
) => {
    assert(!!s, 'Must Have Structure');
    assert(!!buildingId, 'Must Have Building Id');

    return (dispatch: AppDispatch) => {
        // // Update here so UI change sticks
        let updatePath = `rowData.${buildingId}`;

        let endpoint = s.id ? API.endpoints.additionalStructures.put : API.endpoints.additionalStructures.post;

        let structure = s.id ? s : Object.assign({}, s, { building: buildingId, submission: submissionId });

        let params = {
            buildingId,
            structure,
        };

        return API.doRequest(endpoint, { params, showProgress: true, hideDialogForErrors: true }, dispatch)
            .then(({ data }) => {
                dispatch(
                    updateDataByPath(tableConfig, updatePath, {
                        additional_structures: uUpdateItemWithSameId(data),
                    })
                );
                callback(true);
            })
            .catch(error => {
                if (error.handled) {
                    return;
                }
                callback(false, error.data);
            });
    };
};

export const fetchMSBData = (buildingId: number) => {
    assert(!!buildingId, 'Must Have Building Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.MSBData,
            {
                params: {
                    buildingId: buildingId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchAllMSBData = (submissionId: number) => {
    assert(!!submissionId, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.allMSBData,
            {
                params: {
                    submissionId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchAllHazardHubData = (submissionId: number) => {
    assert(!!submissionId, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.allHazardHubData,
            {
                params: {
                    submissionId: submissionId,
                },
                showProgress: true,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(updateTaskProgress({ data, taskPath: getTaskPath(data.task_type, data.key_id) }));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const fetchBuildfax = (buildingId: number) => {
    assert(!!buildingId, 'Must have Building Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.buildfax,
            {
                params: {
                    buildingId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchAllBuildfax = (submissionId: number) => {
    assert(!!submissionId, 'Must have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.allBuildfax,
            {
                params: {
                    submissionId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchCapeAnalytics = (buildingId: number) => {
    assert(!!buildingId, 'Must Have Building Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.capeAnalytics,
            {
                params: {
                    buildingId: buildingId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchAllCapeAnalytics = (submissionId: number) => {
    assert(!!submissionId, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.allCapeAnalytics,
            {
                params: {
                    submissionId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchIntermapData = (buildingId: number) => {
    assert(!!buildingId, 'Must Have Building Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.intermapData,
            {
                params: {
                    buildingId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchAllIntermapData = (submissionId: number) => {
    assert(!!submissionId, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.allIntermapData,
            {
                params: {
                    submissionId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const geocodeWithPreferredSource = (submissionId: number) => {
    assert(!!submissionId, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.geocodePreferredSource,
            {
                params: {
                    submissionId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchPingData = (buildingId: number) => {
    assert(!!buildingId, 'Must Have Building Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.pingData,
            {
                params: {
                    buildingId: buildingId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};

export const fetchAllPingData = (submissionId: number) => {
    assert(!!submissionId, 'Must Have Submission Id');

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.building.allPingData,
            {
                params: {
                    submissionId,
                },
                showProgress: true,
            },
            dispatch
        );
    };
};
