import { CurrentChanges } from '../components/CurrentChanges';
import { BulkEditCoverageOptions } from '../components/BulkEditCoverageOptions';
import { BulkEditBuildingCoverageOptions } from '../components/BulkEditBuildingCoverageOptions';

export const BuildingLevelSidebar = () => {
    return (
        <div className="w-45 tw-min-w-[275px] panelright tw-flex tw-flex-col">
            <div className="info-block subpaneltop tw-max-h-[200px]">
                <BulkEditCoverageOptions />
            </div>
            <BulkEditBuildingCoverageOptions />
            <div className="info-block subpanelbottom">
                <CurrentChanges />
            </div>
        </div>
    );
};
