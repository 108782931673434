import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { PolicyTermsFormField } from '../utils/form';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';

export const BuildingAndCoverageLimits = () => {
    return (
        <div className="tw-grid tw-grid-cols-2 tw-gap-8">
            <div className="info-rows">
                <PolicyTermsFormField>
                    <FormInput name="building_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="sov_value" disabled dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="external_valuation_amount" disabled dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="structure_value" disabled dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="bi_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
            </div>

            <div className="info-rows [&>span:not(:first-child)]:tw-pl-4">
                <PolicyTermsFormField>
                    <FormInput name="business_personal_property" disabled dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>

                <PolicyTermsFormField>
                    <FormInput name="contents_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="stock_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="property_of_others_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="electronic_data_processing_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
                <PolicyTermsFormField>
                    <FormInput name="tenant_improvements_and_betterments_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
            </div>
        </div>
    );
};
