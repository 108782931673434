import * as React from 'react';

type propTypes = {
    groupedByTitle: string;
};

export default class ExposureSummaryHeaderRow extends React.Component<propTypes> {
    render() {
        let { groupedByTitle } = this.props;

        return (
            <div className="flex-table-row row clmn-headers">
                <div className="width-30">
                    <label>{groupedByTitle}</label>
                </div>

                <div className="width-10 numeric">
                    <label>Bldgs</label>
                </div>

                <div className="width-10 numeric">
                    <label>%</label>
                </div>

                <div className="width-15 numeric">
                    <label>TIV</label>
                </div>

                <div className="width-10 numeric">
                    <label>%</label>
                </div>

                <div className="width-25" />
            </div>
        );
    }
}
