import { useState } from 'react';
import { BodyCell, HeaderCell, HeaderRow, Table, TableRow } from '@archinsurance-viki/property-jslib/src/components/table/Table';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultFooter, DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { useAppContext } from '../../../../hooks/context';
import { useAppSelector } from '../../../../hooks/redux';
import { QuoteType } from '../../../../ts-types/DataTypes';
import { useFakeSubmitCatModelsMutation, useSubmitCatModelsMutation } from '../../../../services/apiSlice';

type CatModelsBodyProps = {
    fake?: boolean;
    /** Passed in by CenteredModal, presumably closes the modal */
    onClose: (confirmed: boolean) => void;
};

const CatModelsBody = ({ onClose, fake = false }: CatModelsBodyProps) => {
    const { currentSubmissionId, currentSubmission } = useAppContext();
    const { quotes: quoteIds } = currentSubmission;
    const availableQuotes = useAppSelector(state => quoteIds.map(id => state.quotes.rowData[id] as QuoteType));

    const [submitCatModels] = useSubmitCatModelsMutation();
    const [fakeSubmitCatModels] = useFakeSubmitCatModelsMutation();

    const [selectedQuotes, setSelectedQuotes] = useState<number[]>([]);

    const toggleChecked = (quoteId: number) => {
        if (selectedQuotes.includes(quoteId)) {
            setSelectedQuotes(selectedQuotes.filter(id => id !== quoteId));
        } else {
            setSelectedQuotes([...selectedQuotes, quoteId]);
        }
    };

    const onOk = () => {
        const quotesToRun = selectedQuotes.length ? selectedQuotes : quoteIds;
        if (fake) {
            fakeSubmitCatModels({ submissionId: currentSubmissionId, quoteIds: quotesToRun });
        } else {
            submitCatModels({ submissionId: currentSubmissionId, quoteIds: quotesToRun });
        }
    };

    return (
        <div className="tw-w-[1200px] tw-py-4 tw-px-8">
            <div className="tw-h-80 tw-overflow-y-scroll">
                <Table className="tw-grid-cols-[60px_60px_100px_1fr_5fr]">
                    <HeaderRow>
                        <HeaderCell />
                        <HeaderCell>ID</HeaderCell>
                        <HeaderCell>Primary</HeaderCell>
                        <HeaderCell>Status</HeaderCell>
                        <HeaderCell>Description</HeaderCell>
                    </HeaderRow>
                    {availableQuotes.map(quote => (
                        <TableRow key={quote.id}>
                            <BodyCell>
                                <input type="checkbox" checked={selectedQuotes.includes(quote.id)} onChange={() => toggleChecked(quote.id)} />
                            </BodyCell>
                            <BodyCell>{quote.id}</BodyCell>
                            <BodyCell>{quote.id === currentSubmission.primary_quote_id ? 'Yes' : 'No'}</BodyCell>
                            <BodyCell>{quote.status_display}</BodyCell>
                            <BodyCell>{quote.description}</BodyCell>
                        </TableRow>
                    ))}
                </Table>
            </div>
            <DefaultFooter
                onClose={onClose}
                isDestructive={false}
                modalData={{
                    confirmLabelTitle: `${fake ? 'Fake' : 'Run'} CAT Models (${selectedQuotes.length ? selectedQuotes.length : 'All'} Quotes)`,
                    // this is the default text but it has to be set in order to display the button
                    dismissButtonTitle: 'Cancel',
                    onOk,
                }}
            />
        </div>
    );
};

let modalTypeObj = {
    Body: CatModelsBody,
    Header: DefaultHeaderStatic('Select Coverage Options for Running CAT Models'),
};
registerType(VIRA_MODAL_TYPES.CAT_MODELS, modalTypeObj);
export default modalTypeObj;
