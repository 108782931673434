import * as React from 'react';

import InitiateEndorsement from '../containers/InitiateEndorsement.container';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { AppContextType } from '../../../../ts-types/AppTypes';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    onSetCurrentSubmission: (aId?: number, sId?: string | number, replace?: boolean, subRoute?: string | null, params?: Record<string, any>) => void;
    onClose: (x?: boolean) => any;
    appContext: AppContextType;
};

class InitiateEndorsementBody extends React.Component<bodyPropTypes> {
    render() {
        return <InitiateEndorsement {...this.props} />;
    }
}

let modalTypeObj = {
    Body: InitiateEndorsementBody,
    Header: DefaultHeaderStatic('INITIATE ENDORSEMENT'),
    className: 'init-endorsement-modal',
};
registerType(VIRA_MODAL_TYPES.INITIATE_ENDORSEMENT, modalTypeObj);
export default modalTypeObj;
