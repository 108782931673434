import { useGetMeQuery } from '@archinsurance-viki/property-jslib/src/services/endpoints/user';
import { UserType } from '../ts-types/AppTypes';

export const AUTHORITY_GROUP_LEVELS = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
} as const;

export const useAuthorityGroupLevel = () => {
    const { data } = useGetMeQuery({});
    return (data as unknown as UserType)?.authority_group_level;
};
