import { API } from '@archinsurance-viki/property-jslib';
import * as types from '../constants/ActionTypes';
import { AppDispatch } from '../store';

export const setCurrentCarriers = (carriers: Record<string, any>, quoteId: number) => ({
    type: types.SET_CURRENT_CARRIERS,
    carriers: carriers,
    quoteId: quoteId,
});

export const setCurrentCarriersByTransaction = (carriers: Record<string, any>, transactionId: number) => ({
    type: types.SET_CURRENT_CARRIERS,
    carriers: carriers,
    transactionId: transactionId,
});

export const updateCarrier = (carrier: Record<string, any>, quoteId: number) => ({
    type: types.UPDATE_CARRIER,
    carrier: carrier,
    quoteId: quoteId,
});

export const resetCarriers = () => ({
    type: types.RESET_CARRIERS,
});

export const getCarriers_server = (quoteId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.carrier.get,
            {
                id: quoteId,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(setCurrentCarriers(data, quoteId));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const getCarriersByTransaction_server = (transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.carrier.getByTransaction,
            {
                id: transactionId,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(setCurrentCarriersByTransaction(data, transactionId));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const updateCarriers_server = (quoteId: number, payload: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        dispatch(updateCarrier(payload.carrier_participation, quoteId));
        return API.doRequest(
            API.endpoints.carrier.put,
            {
                id: quoteId,
                params: payload,
            },
            dispatch
        ).then(
            ({ data }) => {
                console.log('RECEIVED DATA:', data);
                dispatch(setCurrentCarriers(data, quoteId));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const resetCarriers_server = (quoteId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(resetCarriers());
        return API.doRequest(
            API.endpoints.carrier.put,
            {
                id: quoteId,
                params: { carrier_participation: null },
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(setCurrentCarriers(data, quoteId));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};
