export const QUOTE_TRANSITION_STATUSES = {
    WITHDRAWN: { STATUS: 'WITHDRAWN', STATUS_DISPLAY: 'Withdrawn' },
    SENT_TO_AGENT: { STATUS: 'SENT_TO_AGENT', STATUS_DISPLAY: 'Sent to Agent' },
    NOT_TAKEN_UP: { STATUS: 'NOT_TAKEN_UP', STATUS_DISPLAY: 'Not Taken Up' },
    BOUND: { STATUS: 'BOUND', STATUS_DISPLAY: 'Bound' },
    IN_PROGRESS: { STATUS: 'IN_PROGRESS', STATUS_DISPLAY: 'In Progress' },
    UNKNOWN: { STATUS: 'UNKNOWN', STATUS_DISPLAY: 'Uknown' },
};

export const QUOTE_STATUSES = {
    UNKNOWN: 'UNKNOWN',
    IN_PROGRESS: 'IN_PROGRESS',
    INDICATED: 'INDICATED',
    SENT_TO_AGENT: 'SENT_TO_AGENT',
    BOUND: 'BOUND',
    WITHDRAWN: 'WITHDRAWN',
    NOT_TAKEN_UP: 'NOT_TAKEN_UP',
    ENDORSEMENT_REVIEW: 'ENDORSEMENT_REVIEW',
};

export const MANUSCRIPT_TYPES = {
    endorsement: 'MANU_END',
    form: 'MANU_FORM',
};

export const LAYERED_POLICY_INFO_BLOCKS = [
    {
        key: 'P',
        header: 'Primary Policies',
    },
    {
        key: 'U',
        header: 'Underlying Policies',
    },
    {
        key: 'O',
        header: 'Other Associated Policies',
    },
    {
        key: 'E',
        header: 'Participating Excess Policies',
    },
];
