import * as React from 'react';

import { formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { OnInputChangeType } from '@archinsurance-viki/property-jslib/src/ts-types/InputTypes';

type propTypes = {
    object: Record<string, any>;
    onChange?: OnInputChangeType;
    carrier: Record<string, any>;
    onEveryChange?: (...params: any[]) => any;
    disabled: boolean;
    parentProps: Record<string, any>;
};

export default class CarrierDetailRow extends React.Component<propTypes> {
    render(): React.ReactNode {
        let { object } = this.props;
        let { currentTransaction } = this.props.parentProps;
        let isPremiumBearing = currentTransaction ? currentTransaction.is_premium_bearing : null;
        return (
            <tr>
                <th>
                    <label className="frm-lbl unbold" style={{ whiteSpace: 'nowrap' }}>
                        {this.props.carrier.name}
                    </label>
                </th>
                <th className="size-4 unbold num">{formatNumber(object.default_participation_pct, undefined, '%', 1)}</th>
                <th className="size-4 unbold num">{object.pool_id === 3 ? 'n/a' : formatNumber(object.override_participation_pct, undefined, '%', 1)}</th>
                <th className="size-4 unbold num">{formatNumber(object.final_participation_pct, undefined, '%', 1)}</th>
                <th className="size-4 unbold num">{isPremiumBearing ? formatNumber(object.actual_allocated_premium_delta, '$', undefined, 0) : '$0'}</th>
                <th className="size-4 unbold num">{isPremiumBearing ? formatNumber(object.commission_amount_delta, '$', undefined, 0) : '$0'} </th>
            </tr>
        );
    }
}
