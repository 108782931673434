import * as React from 'react';

import AddQuoteCondition from '../containers/AddQuoteCondition.container';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    uiState: Record<string, any>;
    onClose: (x?: boolean) => any;
    modalData: Record<string, any>;
    quoteId: number;
    customQuoteConditions: string[];
};

class AddQuoteConditionBody extends React.Component<bodyPropTypes> {
    render() {
        return <AddQuoteCondition {...this.props} />;
    }
}

let modalTypeObj = {
    Body: AddQuoteConditionBody,
    Header: DefaultHeaderStatic('Add Quote Condition'),
    className: 'add-quote-condition-modal',
};
registerType(VIRA_MODAL_TYPES.ADD_QUOTE_CONDTION, modalTypeObj);
export default modalTypeObj;
