import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { FormError } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormError';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormTextArea } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormTextArea';
import ConfirmPromptButton from '@archinsurance-viki/property-jslib/src/components/popups/ConfirmPrompt';
import { BodyCell, HeaderCell, HeaderRow, Table, TableRow } from '@archinsurance-viki/property-jslib/src/components/table/Table';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useQuoteId from '../hooks/quotes';
import { useSubmissionIsEditable } from '../hooks/submissions';
import { useAddBindingRequirementMutation, useChangeBindingRequirementMutation } from '../services/endpoints/coverage-option';
import { useDeleteBindingRequirementMutation } from '../services/endpoints/policy-forms';
import { QuoteBindingRequirement } from '../ts-types/DataTypes';
import { useAppContext } from '../hooks/context';

type ErrorsObject = Record<'add' | number, ErrorData>;
type ErrorData = Record<string, string[]>;

export const useSubmissionCanEditBindingRequirements = (): boolean => {
    const { currentTransaction } = useAppContext();
    const submissionIsEditable = useSubmissionIsEditable();
    const canEditBR = !currentTransaction || ['NB', 'RB', 'NBR', 'RBR', 'NBV', 'RBV'].includes(currentTransaction.transaction_type);
    return submissionIsEditable && canEditBR;
};

const AddCondition = ({ disabled, onAddCondition }: { onAddCondition: () => void; disabled?: boolean }) => (
    <BasicButton className="tw-flex tw-items-center tw-p-1 tw-gap-0.5 tw-text-[16px]" disabled={disabled} onClick={() => onAddCondition()}>
        <Icon icon="add" className="tw-text-[16px]" />
        <span>Add Condition</span>
    </BasicButton>
);

type FormValues = { label: string; description: string };

export const EditingRow = ({
    bindingRequirement,
    onSave,
    onCancel,
    errors,
}: {
    bindingRequirement?: QuoteBindingRequirement;
    onSave: (data: FormValues) => void;
    onCancel: () => void;
    errors?: Record<string, string[]>;
}) => {
    const { control, handleSubmit } = useForm<FormValues>({
        defaultValues: { label: bindingRequirement?.label ?? '', description: bindingRequirement?.description ?? '' },
    });
    const submit = (data: FormValues) => {
        onSave(data);
    };
    return (
        <TableRow>
            <BodyCell className="tw-p-0 tw-h-full tw-w-full tw-flex tw-justify-center">
                <div />
            </BodyCell>
            <BodyCell>
                <div>
                    <FormInput name="label" control={control} />
                    {errors?.label && <FormError errorMessage={errors.label[0]} />}
                </div>
            </BodyCell>
            <BodyCell>
                <div className="tw-flex tw-gap-2">
                    <div className="tw-flex-1">
                        <FormTextArea name="description" rows={6} cols={100} control={control} />
                        {errors?.description && <FormError errorMessage={errors.description[0]} />}
                    </div>
                    <BasicButton className="tw-self-baseline tw-p-1 tw-text-[16px]" onClick={handleSubmit(submit)}>
                        Save
                    </BasicButton>
                    <BasicButton className="tw-self-baseline tw-p-1 tw-text-[16px]" onClick={() => onCancel()}>
                        Cancel
                    </BasicButton>
                </div>
            </BodyCell>
        </TableRow>
    );
};

export const BindingRequirementTable = ({ bindingRequirements, groupId }: { bindingRequirements: QuoteBindingRequirement[]; groupId: string }) => {
    const isEditable = useSubmissionCanEditBindingRequirements();
    const quoteId = useQuoteId();
    const [editingId, setEditingId] = useState<'add' | number>(null);
    const [errors, setErrors] = useState<ErrorsObject>({} as ErrorsObject);

    const [triggerChangeBR] = useChangeBindingRequirementMutation();
    const [triggerAddBR] = useAddBindingRequirementMutation();
    const [triggerDeleteBR] = useDeleteBindingRequirementMutation();

    const addError = (id: 'add' | number, data: ErrorData) => setErrors(prev => ({ ...prev, [id]: data }));
    const clearErrors = () => setErrors({} as ErrorsObject);
    const onStopEditing = () => {
        clearErrors();
        setEditingId(null);
    };
    const onStartEditing = (id: 'add' | number) => {
        clearErrors();
        setEditingId(id);
    };

    return (
        <>
            <Table className="tw-grid-cols-[50px_1fr_3fr] items-center tw-w-full tw-max-h-[500px] tw-overflow-auto">
                <HeaderRow>
                    <HeaderCell />
                    <HeaderCell>Condition</HeaderCell>
                    <HeaderCell>Description</HeaderCell>
                </HeaderRow>
                {bindingRequirements?.map(bindingRequirement => {
                    if (editingId === bindingRequirement.id) {
                        return (
                            <EditingRow
                                key={bindingRequirement.id}
                                bindingRequirement={bindingRequirement}
                                onSave={async submitData => {
                                    clearErrors();
                                    try {
                                        await triggerChangeBR({
                                            quoteId,
                                            brId: bindingRequirement.id,
                                            group_id: groupId,
                                            ...submitData,
                                        }).unwrap();
                                        onStopEditing();
                                    } catch (e) {
                                        if (e?.data) {
                                            addError(bindingRequirement.id, e.data);
                                        }
                                    }
                                }}
                                onCancel={() => onStopEditing()}
                                errors={errors?.[bindingRequirement.id]}
                            />
                        );
                    }
                    return (
                        <TableRow key={bindingRequirement.id}>
                            <BodyCell className="tw-self-stretch w-full h-full">
                                <input
                                    className="tw-px-2 tw-py-1"
                                    type="checkbox"
                                    checked={bindingRequirement.is_active}
                                    disabled={!isEditable}
                                    onChange={e =>
                                        triggerChangeBR({
                                            quoteId,
                                            brId: bindingRequirement.id,
                                            active: e.target.checked,
                                            label: bindingRequirement.label,
                                            description: bindingRequirement.description,
                                            group_id: groupId,
                                        })
                                    }
                                />
                            </BodyCell>
                            <BodyCell>{bindingRequirement.label}</BodyCell>
                            <BodyCell>
                                <div className="tw-flex tw-gap-2">
                                    <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-1">
                                        {bindingRequirement.description.split('\n').map((line, i) => (
                                            <span key={i}>{line}</span>
                                        ))}
                                    </div>
                                    {bindingRequirement.is_custom && (
                                        <>
                                            <BasicButton
                                                className="tw-p-1 tw-self-baseline"
                                                disabled={!isEditable}
                                                onClick={() => onStartEditing(bindingRequirement.id)}
                                            >
                                                <Icon icon="edit" className="tw-text-[20px]" />
                                            </BasicButton>
                                            <ConfirmPromptButton
                                                disabled={!isEditable}
                                                confirmPromptProps={{
                                                    onConfirmed: () => triggerDeleteBR({ id: bindingRequirement.id }),
                                                    title: 'Are you sure you want to delete this binding requirement?',
                                                }}
                                                className="no-viki-style tw-text-white tw-p-1 tw-self-baseline tw-bg-red-warn"
                                            >
                                                <Icon icon="delete" className="tw-text-[20px]" />
                                            </ConfirmPromptButton>
                                        </>
                                    )}
                                </div>
                            </BodyCell>
                        </TableRow>
                    );
                })}
            </Table>
            {editingId !== 'add' && <AddCondition disabled={!isEditable} onAddCondition={() => onStartEditing('add')} />}
            {editingId === 'add' && (
                <Table className="tw-grid-cols-[50px_1fr_3fr] items-center tw-w-full tw-h-max-[500px] tw-overflow-auto">
                    <EditingRow
                        onSave={async submitData => {
                            clearErrors();
                            try {
                                await triggerAddBR({ quoteId, group_id: groupId, ...submitData }).unwrap();
                                onStopEditing();
                            } catch (e) {
                                if (e?.data) {
                                    addError('add', e.data);
                                }
                            }
                        }}
                        onCancel={() => onStopEditing()}
                        errors={errors?.add}
                    />
                </Table>
            )}
        </>
    );
};
