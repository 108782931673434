import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormValues, PolicyTermsFormField, selectSortedAlphabeticalChoices } from '../utils/form';

export const BusinessInterruption = () => {
    const BI_TYPES = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'BI_TYPES'));
    const BI_MONTHLY_LIMITATIONS = useAppSelector(state => state.global.CONSTANTS.BI_MONTHLY_LIMITATIONS);
    const AOP_DEDUCTIBLE_HOURS_OPTION = useAppSelector(state => state.global.CONSTANTS.AOP_DEDUCTIBLE_HOURS_OPTION);

    return (
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="bi_type" selections={BI_TYPES} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormButtonGroup<PolicyTermsFormValues>
                    name="business_interruption_includes_extra_expense"
                    items={[
                        { display: 'Yes', value: true },
                        { display: 'No', value: false },
                    ]}
                />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormButtonGroup<PolicyTermsFormValues>
                    name="business_interruption_includes_rental_income"
                    items={[
                        { display: 'Yes', value: true },
                        { display: 'No', value: false },
                    ]}
                />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="bi_monthly_limitation" selections={BI_MONTHLY_LIMITATIONS} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="bus_interruption_deduct" selections={AOP_DEDUCTIBLE_HOURS_OPTION} />
            </PolicyTermsFormField>
        </div>
    );
};
