import * as React from 'react';

import { useAppContext } from '../../hooks/context';
import RegionalCATSummaryApp from './RegionalCATSummaryApp';
import { useAppSelector } from '../../hooks/redux';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { PAGED_TABLE_VIEW_SETTINGS } from '../../constants/PagedTableViewSettings';
import { useGetRegionalCATSummaryChoicesQuery } from '../../services/apiSlice';

export default function RegionalCATSummaryContainer() {
    const appContext = useAppContext();

    const currentSubmissionId = appContext.currentSubmissionId;
    const apiParams = {
        submission_id: currentSubmissionId,
    };
    const apiParamsKey = getApiParamsKey(apiParams);
    const tableData = useAppSelector(state => state.regionalCATSummary);
    const viewSettingsConfig = PAGED_TABLE_VIEW_SETTINGS.regional_cat_summary;

    const { data } = useGetRegionalCATSummaryChoicesQuery({ submissionId: currentSubmissionId }, { skip: !currentSubmissionId });

    if (!currentSubmissionId || !data) {
        return null;
    }

    PAGED_TABLES.REGIONAL_CAT_SUMMARY.tableGlossary['r']['choices'] = data.regions;
    PAGED_TABLES.REGIONAL_CAT_SUMMARY.tableGlossary['p']['choices'] = data.perils;
    PAGED_TABLES.REGIONAL_CAT_SUMMARY.tableGlossary['q']['choices'] = data.quotes;

    const tableConfig = { ...PAGED_TABLES.REGIONAL_CAT_SUMMARY, apiParams, apiParamsKey, viewSettingsConfig };

    return <RegionalCATSummaryApp tableConfig={tableConfig} tableData={tableData} appContext={appContext} />;
}
