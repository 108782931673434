import cx from 'classnames';
import { add, format, parse, parseISO } from 'date-fns';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultAccountLayout } from '../../components/common/DefaultLayout';
import { AUTHORITY_GROUP_LEVELS, useAuthorityGroupLevel } from '../../hooks/authority-group-level';
import Dropzone from 'react-dropzone';
import { FormDateInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormDateInput';
import { useForm, useWatch } from 'react-hook-form';
import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import { TabPanelHeaderTemplateOptions, TabView, TabPanel } from 'primereact/tabview';

import { BodyCell, HeaderCell, HeaderRow, Table, TableRow } from '@archinsurance-viki/property-jslib/src/components/table/Table';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';

import {
    useBulkCatModelingJobsQuery,
    useBulkCatModelingScheduleQuery,
    useCreateBulkCatModelingJobMutation,
    useDeleteBulkCatModelingJobMutation,
} from '../../services/endpoints/bulk_cat_model';
import { useToastContext } from '../../hooks/toast';
import { ErrorToast, extractErrorMessage } from '../../components/common/ToastMessage';
import { TaskTypePanel } from '../AccountTasksPanel';
import { useAppContext } from '../../hooks/context';
import ConfirmPromptButton from '@archinsurance-viki/property-jslib/src/components/popups/ConfirmPrompt';
import { BulkCatModelJob } from '../../ts-types/DataTypes';
import { DATE_FNS_DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';
import api from '../../services/apiSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

export const DragNDrop = ({
    children,
    onDrop,
    accept,
    noClick,
}: {
    children: React.ReactNode;
    onDrop: (files: File[]) => void;
    accept: Record<string, string[]>;
    noClick?: boolean;
}) => {
    return (
        <Dropzone noClick={noClick} accept={accept} onDrop={(acceptedFiles: File[]) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="tw-flex tw-flex-col tw-cursor-pointer">
                    <input {...getInputProps()} />
                    {children}
                </div>
            )}
        </Dropzone>
    );
};

export const BulkCatModelPage = () => {
    const dispatch = useAppDispatch();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const currentSiteId = useAppSelector(state => state.global.ENV.SITE?.id);
    const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
        return (
            <div onClick={options.onClick} className={cx(options.className, 'table-pref', { active: options.selected })}>
                <i className="pi pi-prime p-mr-2" />
                {options.titleElement}
            </div>
        );
    };
    usePusherEvent('tasks-SCAN_AND_PARSE_BULK_FILE', 'done', (message: Record<string, unknown>) => {
        if (message?.site_id === currentSiteId) {
            dispatch(api.util.invalidateTags([{ type: 'BulkCatModelJob', id: 'LIST' }]));
        }
    });
    return (
        <DefaultAccountLayout hasActionPanel={false}>
            <div className="tw-items-center tw-p-2 tw-max-w-[1200px] [&_.p-tabview-nav-container]:tw-max-w-[600px]">
                <TaskTypePanel taskType="SCAN_AND_PARSE_BULK_FILE" />
                <TabView panelContainerClassName="tw-mt-4" activeIndex={activeTabIndex} onTabChange={e => setActiveTabIndex(e.index)}>
                    <TabPanel header="Schedule New Job" headerTemplate={tabTemplate}>
                        <BulkCatModelScheduling />
                    </TabPanel>
                    <TabPanel header="Bulk Cat Model Jobs" headerTemplate={tabTemplate}>
                        <BulkCatModelJobs />
                    </TabPanel>
                </TabView>
            </div>
        </DefaultAccountLayout>
    );
};

const DEFAULT_FILTERS = {
    status: {
        SCHEDULED: true,
        IN_PROGRESS: true,
        SUCCESS: true,
        FAILURE: false,
    },
};

const hasTooltip = (job: BulkCatModelJob) => ['SCHEDULED', 'SUCCESS', 'FAILURE'].includes(job.status);
const isDeleteDisabled = (job: BulkCatModelJob) => job.status !== 'SCHEDULED';
const formatTimestampAsDate = (timestamp: string) => (timestamp ? format(parseISO(timestamp), DATE_FNS_DISPLAY_DATE_FORMAT) : '');

const JOB_STATUS_SORT_ORDER = ['SCHEDULED', 'IN_PROGRESS', 'SUCCESS', 'FAILURE'];

export const BulkCatModelJobs = () => {
    const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
    const { sortedJobs } = useBulkCatModelingJobsQuery(
        {
            filters: {
                status: Object.entries(filters.status)
                    .filter(([_key, value]) => !!value)
                    .map(([key, _value]) => key),
            },
        },
        {
            selectFromResult: result => {
                const { data } = result;
                if (!data) {
                    return { ...result, sortedJobs: [] };
                }
                let sortedJobs = [] as BulkCatModelJob[];
                JOB_STATUS_SORT_ORDER.forEach(status => {
                    sortedJobs = [...sortedJobs, ...data.filter(job => job.status === status)];
                });
                return { ...result, sortedJobs };
            },
        }
    );
    const [triggerDeleteBulkCatModelJob] = useDeleteBulkCatModelingJobMutation();

    return (
        <div className="tw-flex tw-flex-col tw-gap-4">
            <h2 className="tw-text-lg">Bulk Cat Model Jobs</h2>
            <div className="tw-flex tw-flex-col tw-gap-2">
                <h3>Filters</h3>
                <div className="tw-flex tw-gap-6 tw-text-base">
                    <div className="tw-flex tw-gap-2">
                        <label>Scheduled</label>
                        <input
                            type="checkbox"
                            checked={filters.status.SCHEDULED}
                            onChange={() => setFilters(prev => ({ ...prev, status: { ...prev.status, SCHEDULED: !prev.status.SCHEDULED } }))}
                        />
                    </div>
                    <div className="tw-flex tw-gap-2">
                        <label>In Progress</label>
                        <input
                            type="checkbox"
                            checked={filters.status.IN_PROGRESS}
                            onChange={() => setFilters(prev => ({ ...prev, status: { ...prev.status, IN_PROGRESS: !prev.status.IN_PROGRESS } }))}
                        />
                    </div>
                    <div className="tw-flex tw-gap-2">
                        <label>Success</label>
                        <input
                            type="checkbox"
                            checked={filters.status.SUCCESS}
                            onChange={() => setFilters(prev => ({ ...prev, status: { ...prev.status, SUCCESS: !prev.status.SUCCESS } }))}
                        />
                    </div>
                    <div className="tw-flex tw-gap-2">
                        <label>Failure</label>
                        <input
                            type="checkbox"
                            checked={filters.status.FAILURE}
                            onChange={() => setFilters(prev => ({ ...prev, status: { ...prev.status, FAILURE: !prev.status.FAILURE } }))}
                        />
                    </div>
                </div>
            </div>
            <Table className="tw-grid-cols-[150px_150px_1fr_2fr_100px] items-center tw-w-full tw-max-h-[800px] tw-overflow-auto">
                <HeaderRow>
                    <HeaderCell>Status</HeaderCell>
                    <HeaderCell>Date Submitted</HeaderCell>
                    <HeaderCell>User</HeaderCell>
                    <HeaderCell>File Name</HeaderCell>
                    <HeaderCell />
                </HeaderRow>
                {sortedJobs?.map(bulkCatModelJob => {
                    let tooltip: React.ReactNode = '';
                    if (bulkCatModelJob.status === 'SCHEDULED') {
                        tooltip = <span>Scheduled Date: {formatTimestampAsDate(bulkCatModelJob.scheduled_start_date)}</span>;
                    } else if (bulkCatModelJob.status === 'SUCCESS' || bulkCatModelJob.status === 'FAILURE') {
                        tooltip = (
                            <div className="tw-flex tw-flex-col tw-gap-2">
                                <div>Date Completed: {formatTimestampAsDate(bulkCatModelJob.completed_datetime)}</div>
                                <div>Successful Remodels: {bulkCatModelJob.success_child_job_count}</div>
                                <div>Failed Remodels: {bulkCatModelJob.failed_child_job_count}</div>
                            </div>
                        );
                    }
                    return (
                        <TableRow key={bulkCatModelJob.id}>
                            <BodyCell>
                                <div>
                                    <Tooltip content={tooltip}>
                                        <div
                                            className={cx('tw-w-fit tw-cursor-default', {
                                                'tw-border-b tw-border-blue-300': hasTooltip(bulkCatModelJob),
                                            })}
                                        >
                                            {bulkCatModelJob.status_display}
                                        </div>
                                    </Tooltip>
                                </div>
                            </BodyCell>
                            <BodyCell>{formatTimestampAsDate(bulkCatModelJob.created)}</BodyCell>
                            <BodyCell>{bulkCatModelJob.submitter}</BodyCell>
                            <BodyCell>{bulkCatModelJob.original_file_name}</BodyCell>
                            <BodyCell>
                                <ConfirmPromptButton
                                    disabled={isDeleteDisabled(bulkCatModelJob)}
                                    confirmPromptProps={{
                                        onConfirmed: () => triggerDeleteBulkCatModelJob({ id: bulkCatModelJob.id }),
                                        title: `Are you sure you want to delete the following job: ${bulkCatModelJob.original_file_name}`,
                                    }}
                                    className={cx('no-viki-style tw-text-white tw-py-1 tw-px-2 tw-self-baseline', {
                                        'tw-bg-red-warn': !isDeleteDisabled(bulkCatModelJob),
                                    })}
                                >
                                    Cancel
                                </ConfirmPromptButton>
                            </BodyCell>
                        </TableRow>
                    );
                })}
            </Table>
        </div>
    );
};

type FormValues = { document: File; scheduled_date: string };

export const BulkCatModelScheduling = () => {
    const history = useHistory();

    const { toast } = useToastContext();

    const {
        CONSTANTS: { BULK_CAT_MODELING_VENDOR_TO_VERSION },
    } = useAppContext();

    const { data } = useBulkCatModelingScheduleQuery({});
    const [triggerCreateBulkCatModelingJob] = useCreateBulkCatModelingJobMutation();

    const { control, handleSubmit, setValue } = useForm<FormValues>({
        defaultValues: { document: null },
    });
    const filename = useWatch({ control })?.document?.name;
    const scheduledDate = useWatch({ control })?.scheduled_date;

    const authorityGroupLevel = useAuthorityGroupLevel() as number;
    if (authorityGroupLevel !== AUTHORITY_GROUP_LEVELS[4]) {
        history.replace('/submissions');
        return;
    }

    const onSubmit = async (submitData: FormValues) => {
        try {
            await triggerCreateBulkCatModelingJob({ ...submitData, modeling_type: 'TS', modeling_version: BULK_CAT_MODELING_VENDOR_TO_VERSION.TS }).unwrap();
        } catch (e: unknown) {
            toast.show({
                severity: 'error',
                content: <ErrorToast title="An error occurred while submitting bulk cat modeling job" errorMessage={extractErrorMessage(e)} />,
            });
        }
    };

    const submitDisabled = !scheduledDate || !filename;

    return (
        <div className="tw-flex tw-flex-col tw-gap-4 tw-max-w-[800px]">
            <h2>Bulk Cat Model Scheduling</h2>
            <DragNDrop onDrop={files => setValue('document', files?.[0])} accept={{ 'text/csv': ['.csv'] }}>
                <div className="tw-w-[800px] tw-h-[350px] tw-border-4 tw-border-dashed tw-flex tw-items-center tw-justify-center">
                    {!filename ? (
                        'Drag and Drop a .csv file or click to select a .csv file for upload'
                    ) : (
                        <span className="tw-flex tw-gap-2">
                            Selected file:<span className="tw-font-semibold">{filename}</span>
                        </span>
                    )}
                </div>
            </DragNDrop>
            <div>
                <div className="tw-flex tw-gap-4">
                    <label>Scheduled Date</label>
                    <div className="tw-flex-1">
                        <FormDateInput
                            name="scheduled_date"
                            minBookingDate={new Date()}
                            maxBookingDate={add(new Date(), { days: 100 })}
                            unavailableDates={data?.unavailable_dates?.TS?.['9.0']?.map(dateString => parse(dateString, 'yyyy-MM-dd', new Date()))}
                            control={control}
                        />
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-gap-4">
                <BasicButton type="submit" className={cx('tw-px-4 tw-py-2')} disabled={submitDisabled} onClick={handleSubmit(onSubmit)}>
                    Schedule Bulk Cat Model
                </BasicButton>
            </div>
        </div>
    );
};
