import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { setSubmissionOutcome } from '../../../../actions/SubmissionActions';
import { closeSubmission } from '../../../../actions/SubmissionLogActions';
import UpdateCancellationEffectiveDateApp from '../apps/UpdateCancellationEffectiveDateApp';
import { AppContextFnsType, AppContextType } from '../../../../ts-types/AppTypes';
import { RootState } from '../../../../store';
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

type ownPropTypes = {
    onClose: (x?: boolean) => any;
    appContextFns: AppContextFnsType;
    appContext: AppContextType;
    actionHelperFns: ActionHelperFnsType;
};

type propTypes = {
    onCloseSubmission: (submissionId: number, outcome: Record<string, any>) => any;
    closeSubmissionData: Record<string, any>;
    onSetSubmissionOutcome: (fieldName: string, value: any) => void;
} & ownPropTypes;

const mapStateToProps = (state: RootState, _ownProps: ownPropTypes) => {
    return {
        closeSubmissionData: state.closeSubmissionData,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    const onSetCurrentSubmission = ownProps.appContextFns.onSetCurrentSubmission;
    return {
        onSetSubmissionOutcome: (fieldName, value, error) => {
            dispatch(setSubmissionOutcome(fieldName, value, error));
        },

        onCloseSubmission: (submissionId, outcome) => {
            dispatch(closeSubmission(submissionId, outcome, onSetCurrentSubmission, ownProps.actionHelperFns));
        },
    };
};

class UpdateCancellationEffectiveDate extends React.Component<propTypes> {
    render() {
        return <UpdateCancellationEffectiveDateApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(UpdateCancellationEffectiveDate);
export default connected;
