import * as React from 'react';
import classNames from 'classnames';

import ActionPanel from '@archinsurance-viki/property-jslib/src/components/panels/ActionPanel';

import ExposureSummaryApp from './ExposureSummaryApp';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../../components/submissionlog/Helpers';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { useAppSelector } from '../../hooks/redux';
import { useAppContext, useAppContextFns } from '../../hooks/context';

const OVERVIEW_ACTIONS_PANEL = 'OVERVIEW_ACTIONS';

export default function ExposureSummaryPage() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const uiState = useAppSelector(state => state.uiState);

    if (!appContext.currentSubmission) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="main-content-center">
                <ExposureSummaryApp exposureData={appContext.currentSubmission.statisticsSummary || null} />
            </div>
            <div
                className={classNames('main-right-panel main-panel', {
                    closed: !uiState.openPanels[OVERVIEW_ACTIONS_PANEL],
                })}
            >
                <ActionPanel
                    uiState={uiState}
                    panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.OVERVIEW)}
                    panelName={OVERVIEW_ACTIONS_PANEL}
                    openPanels={uiState.openPanels}
                    tableConfig={PAGED_TABLES.SUBMISSION_LOG}
                />
            </div>
        </React.Fragment>
    );
}
