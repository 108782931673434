import * as React from 'react';

import { LiteTableDataType, RowSelectedFnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { Types } from '../../../../ts-types/viki-types';
import { AccountTasksPanel } from '../../../../features/AccountTasksPanel';

type propTypes = {
    tableData: LiteTableDataType;
    CONSTANTS: Types.Constants;
    onPersistTableRows?: (persistData: Record<string, any>) => void;
    selectedRowIndex: number;
    onSaveFn: (...params: any) => any;
    onRowSelected: RowSelectedFnType;
    onOpenDetailsPanel: () => void;
    openPanels: Record<string, any>;
    editingDisabled: boolean;
};

export default class BlanketLimitsBottomPanelApp extends React.Component<propTypes, Record<string, any>> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            forceBottomPanelOpen: false,
        };
    }

    componentDidUpdate(_prevProps: propTypes): void {
        let { rows } = this.props.tableData;
        if (rows && rows.length > 0 && !this.state.forceBottomPanelOpen) {
            this.props.onOpenDetailsPanel();
            this.setState({ forceBottomPanelOpen: true });
        }
    }

    render(): React.ReactNode {
        return (
            <LiteTableContainer
                hasActionPanel={false}
                editingDisabled={this.props.editingDisabled}
                tableConfig={LITE_TABLES.BLANKET_LIMIT_GROUPS}
                tableData={this.props.tableData}
                containerClass="bottom-panel-table"
                tasksPanel={<AccountTasksPanel />}
            />
        );
    }
}
