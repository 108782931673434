// const IS_PROD = process.env.NODE_ENV === 'production';

import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

import api from '../services/apiSlice';
import listenerMiddleware from './listeners';
import { referenceDataApi } from '@archinsurance-viki/property-jslib/src/services/referenceDataApi';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            // TODO: Enable these
            serializableCheck: false,
            immutableCheck: false,
        })
            .prepend(listenerMiddleware.middleware)
            .concat([api.middleware, referenceDataApi.middleware]),
});

// TODO: hot reloading
/*
if (!IS_PROD && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
}
*/

export type RootState = ReturnType<typeof store.getState>;
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;
export type AppDispatch = typeof store.dispatch;

export default store;
