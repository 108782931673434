import { isObject } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';

export const extractErrorMessage = (e: unknown) => {
    let errorMessage = null;
    if (isObject(e) && 'data' in e && isObject(e.data)) {
        errorMessage = e.data?.error || e.data?.exception;
    }
    return errorMessage;
};

export const ErrorToast = ({ title, errorMessage }: { title: string; errorMessage?: string }) => (
    <div className="tw-z-20 tw-rounded-lg tw-w-[350px] tw-border-red tw-border-l-4 tw-bg-red tw-text-black tw-p-2 tw-flex tw-flex-col tw-gap-2">
        <h3 className="tw-whitespace-normal">{title}</h3>
        {errorMessage?.split('\n')?.map((line, i) => (
            <p key={`${line}${i}`} className="tw-whitespace-normal">
                {line}
            </p>
        ))}
    </div>
);

export const ToastMessage = ({ title, message }: { title: string; message?: string }) => (
    <div className="tw-z-20 tw-rounded-lg tw-w-[350px] tw-border-l-4 tw-bg-grey-super-light tw-text-black tw-p-2 tw-flex tw-flex-col tw-gap-2">
        <h3 className="tw-whitespace-normal">{title}</h3>
        {message?.split('\n')?.map((line, i) => (
            <p key={`${line}${i}`} className="tw-whitespace-normal">
                {line}
            </p>
        ))}
    </div>
);
