import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { TransactionDataType, AccountTransactionResultDataType } from '../../../../ts-types/DataTypes';
import { NumericInput, RadioInputGroup } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { formatCurrency, formatNumber, formatPercentage } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { TRANSACTION_TYPES } from '../../../../constants/TransactionConstants';
import { OnEveryChangeType } from '@archinsurance-viki/property-jslib/src/ts-types/InputTypes';
import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';

type propTypes = {
    onClose: (x?: boolean) => null;
    onSaveEndorsementPricing: (
        transaction: any,
        pricing_type: any,
        pricing_override: any,
        equipment_breakdown_external_premium_delta_override: any,
        inspection_fee: any,
        modeling_fee: any
    ) => void;
    transaction: TransactionDataType;
    prevTransaction: TransactionDataType;
    featureFlags: Record<string, boolean>;
};

const TIMES = '×';
const MINUS = '−';
const PLUS = '+';
const EQUALS = '=';

const formulaDisplay = (value: any, label: any) => {
    return (
        <div className="component">
            <div className="top">{value}</div>
            <div className="formula-label">{label}</div>
        </div>
    );
};

const pricingDisplay = (val: number, className?: string) => {
    return <div className={classNames('pricing-txt', className, { negative: val < 0, positive: val > 0 })}>{formatCurrency(val)}</div>;
};

const getPricingDisplay = (val: number, otherContent: any) => {
    return (
        <React.Fragment>
            {otherContent}
            <div className="formula-display">
                <div className="operator">{EQUALS}</div>
            </div>
            {pricingDisplay(val, 'result-col')}
        </React.Fragment>
    );
};

const formatLabel = (line1: string, line2?: string) => {
    if (!line2) {
        return line1;
    }

    return (
        <React.Fragment>
            <div>{line1}</div>
            <div>{line2}</div>
        </React.Fragment>
    );
};

const INPUT_GROUP_LIST = [
    {
        inputProps: {
            label: 'Original Account Rate',
            value: 'AR',
        },
        chargedPremiumFunc: ({ account_transaction_results_obj }, _prevTransaction) =>
            account_transaction_results_obj && account_transaction_results_obj.ep_same_acct_rate,
        additionalElementFunc: (changeInChargedPremium, { account_transaction_results_obj }, prevTransaction, _) => {
            let account_rate = null,
                meanTiv = null,
                term = null,
                previousMeanTiv = null,
                previousTerm = null;

            if (account_transaction_results_obj) {
                account_rate = account_transaction_results_obj.account_rate;
                meanTiv = account_transaction_results_obj.mean_tiv;
                term = account_transaction_results_obj.term;
            }

            if (prevTransaction && prevTransaction.account_transaction_results_obj) {
                previousMeanTiv = prevTransaction.account_transaction_results_obj.mean_tiv;
                previousTerm = prevTransaction.account_transaction_results_obj.term;
            }

            return getPricingDisplay(
                changeInChargedPremium,
                <div className="formula-display flex-grow">
                    {formulaDisplay(formatNumber(account_rate, '$', undefined, 4), formatLabel('rate per', '$100 of limit'))}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay('1/(365*100)', formatLabel('Unit Adj'))}
                    <div className="operator">{TIMES}</div>
                    <div className="bracket">(</div>
                    {formulaDisplay(formatCurrency(meanTiv), 'endorsement mean tiv')}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay(formatNumber(term * 365, undefined, undefined, 0), formatLabel('new term', 'in days'))}
                    <div className="operator">{MINUS}</div>
                    {formulaDisplay(formatCurrency(previousMeanTiv), 'previous mean tiv')}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay(formatNumber(previousTerm * 365, undefined, undefined, 0), formatLabel('previous term', 'in days'))}
                    <div className="bracket">)</div>
                </div>
            );
        },
    },
    {
        inputProps: {
            label: 'Latest Account Rate',
            value: 'LAR',
        },
        chargedPremiumFunc: ({ account_transaction_results_obj }, _prevTransaction) =>
            account_transaction_results_obj && account_transaction_results_obj.ep_latest_acct_rate,
        additionalElementFunc: (changeInChargedPremium, { account_transaction_results_obj }, prevTransaction, _) => {
            let account_rate = null,
                meanTiv = null,
                term = null,
                previousMeanTiv = null,
                previousTerm = null;

            if (account_transaction_results_obj) {
                account_rate = account_transaction_results_obj.latest_account_rate;
                meanTiv = account_transaction_results_obj.mean_tiv;
                term = account_transaction_results_obj.term;
            }

            if (prevTransaction && prevTransaction.account_transaction_results_obj) {
                previousMeanTiv = prevTransaction.account_transaction_results_obj.mean_tiv;
                previousTerm = prevTransaction.account_transaction_results_obj.term;
            }

            return getPricingDisplay(
                changeInChargedPremium,
                <div className="formula-display flex-grow">
                    {formulaDisplay(formatNumber(account_rate, '$', undefined, 4), formatLabel('rate per', '$100 of limit'))}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay('1/(365*100)', formatLabel('Unit Adj'))}
                    <div className="operator">{TIMES}</div>
                    <div className="bracket">(</div>
                    {formulaDisplay(formatCurrency(meanTiv), 'endorsement mean tiv')}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay(formatNumber(term * 365, undefined, undefined, 0), formatLabel('new term', 'in days'))}
                    <div className="operator">{MINUS}</div>
                    {formulaDisplay(formatCurrency(previousMeanTiv), 'previous mean tiv')}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay(formatNumber(previousTerm * 365, undefined, undefined, 0), formatLabel('previous term', 'in days'))}
                    <div className="bracket">)</div>
                </div>
            );
        },
    },
    {
        inputProps: {
            label: 'Original % of Technical',
            value: 'BT',
        },
        chargedPremiumFunc: ({ account_transaction_results_obj }, _prevTransaction) =>
            account_transaction_results_obj && account_transaction_results_obj.ep_bound_technical_ratio,
        additionalElementFunc: (changeInChargedPremium, { account_transaction_results_obj }, _, _featureFlag) => {
            let bound_technical_ratio = null,
                ep_technical_premium_before_tf = null,
                previous_charged_premium_before_tf = null;
            if (account_transaction_results_obj) {
                bound_technical_ratio = account_transaction_results_obj.bound_technical_ratio;
                ep_technical_premium_before_tf = account_transaction_results_obj.ep_technical_premium_before_tf;
                previous_charged_premium_before_tf = account_transaction_results_obj.previous_charged_premium_before_tf;
            }

            return getPricingDisplay(
                changeInChargedPremium,
                <div className="formula-display  flex-grow">
                    {formulaDisplay(formatPercentage(bound_technical_ratio * 100), formatLabel('original %', 'of tech'))}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay(formatCurrency(ep_technical_premium_before_tf), formatLabel('new', 'technical'))}
                    <div className="operator">{MINUS}</div>
                    {formulaDisplay(formatCurrency(previous_charged_premium_before_tf), formatLabel('previous', 'premium'))}
                </div>
            );
        },
    },
    {
        inputProps: {
            label: 'Latest % of Technical',
            value: 'LT',
        },
        chargedPremiumFunc: ({ account_transaction_results_obj }, _prevTransaction) =>
            account_transaction_results_obj && account_transaction_results_obj.ep_latest_technical_ratio,
        additionalElementFunc: (changeInChargedPremium, { account_transaction_results_obj }, _, _featureFlag) => {
            let latest_technical_ratio = null,
                ep_technical_premium_before_tf = null,
                previous_charged_premium_before_tf = null;
            if (account_transaction_results_obj) {
                latest_technical_ratio = account_transaction_results_obj.latest_technical_ratio;
                ep_technical_premium_before_tf = account_transaction_results_obj.ep_technical_premium_before_tf;
                previous_charged_premium_before_tf = account_transaction_results_obj.previous_charged_premium_before_tf;
            }

            return getPricingDisplay(
                changeInChargedPremium,
                <div className="formula-display  flex-grow">
                    {formulaDisplay(formatPercentage(latest_technical_ratio * 100), formatLabel('original %', 'of tech'))}
                    <div className="operator">{TIMES}</div>
                    {formulaDisplay(formatCurrency(ep_technical_premium_before_tf), formatLabel('new', 'technical'))}
                    <div className="operator">{MINUS}</div>
                    {formulaDisplay(formatCurrency(previous_charged_premium_before_tf), formatLabel('previous', 'premium'))}
                </div>
            );
        },
    },
    {
        inputProps: {
            label: 'Amount Over Technical',
            value: 'PT',
        },
        chargedPremiumFunc: ({ account_transaction_results_obj }, _prevTransaction) =>
            account_transaction_results_obj && account_transaction_results_obj.ep_amt_over_technical_premium,
        additionalElementFunc: (changeInChargedPremium, { account_transaction_results_obj }, _, featureFlags) => {
            let amt_over_technical_premium = null,
                ep_technical_premium_before_tf = null,
                previous_technical_premium_before_tf = null,
                previous_charged_premium_before_tf = null;
            if (account_transaction_results_obj) {
                amt_over_technical_premium = account_transaction_results_obj.amt_over_technical_premium;
                ep_technical_premium_before_tf = account_transaction_results_obj.ep_technical_premium_before_tf;
                previous_charged_premium_before_tf = account_transaction_results_obj.previous_charged_premium_before_tf;
                previous_technical_premium_before_tf = account_transaction_results_obj.previous_technical_premium_before_tf;
            }
            return getPricingDisplay(
                changeInChargedPremium,
                featureFlags.display_change_in_technical_premium && previous_technical_premium_before_tf !== null ? (
                    <div className="formula-display  flex-grow">
                        <div className="bracket">(</div>
                        {formulaDisplay(formatCurrency(amt_over_technical_premium), formatLabel('original $', 'over tech'))}
                        <div className="operator">{PLUS}</div>
                        {formulaDisplay(formatCurrency(ep_technical_premium_before_tf), formatLabel('new', 'technical'))}
                        <div className="bracket">)</div>
                        <div className="operator">{MINUS}</div>
                        <div className="bracket">(</div>
                        {formulaDisplay(formatCurrency(amt_over_technical_premium), formatLabel('original $', 'over tech'))}
                        <div className="operator">{PLUS}</div>
                        {formulaDisplay(formatCurrency(previous_technical_premium_before_tf), formatLabel('previous', 'tech'))}
                        <div className="bracket">)</div>
                    </div>
                ) : (
                    <div className="formula-display  flex-grow">
                        {formulaDisplay(formatCurrency(amt_over_technical_premium), formatLabel('original $', 'over tech'))}
                        <div className="operator">{PLUS}</div>
                        {formulaDisplay(formatCurrency(ep_technical_premium_before_tf), formatLabel('new', 'technical'))}
                        <div className="operator">{MINUS}</div>
                        {formulaDisplay(formatCurrency(previous_charged_premium_before_tf), formatLabel('previous', 'premium'))}
                    </div>
                )
            );
        },
    },
    {
        inputProps: {
            label: 'Premium Return',
            value: 'PR',
        },
        chargedPremiumFunc: ({ account_transaction_results_obj }, _prevTransaction) =>
            account_transaction_results_obj && account_transaction_results_obj.ep_premium_return,
        additionalElementFunc: (changeInChargedPremium, { account_transaction_results_obj }, _, _featureFlag) => {
            let ep_premium_return_params = null,
                unearned_previous_charged_premium_before_tf = null,
                previous_charged_premium_before_tf = null;
            if (account_transaction_results_obj) {
                ep_premium_return_params = account_transaction_results_obj.ep_premium_return_params;
                unearned_previous_charged_premium_before_tf = account_transaction_results_obj.unearned_previous_charged_premium_before_tf;
                previous_charged_premium_before_tf = account_transaction_results_obj.previous_charged_premium_before_tf;
            }
            return getPricingDisplay(
                changeInChargedPremium,
                <div className="formula-display  flex-grow">
                    <If condition={ep_premium_return_params.use_new_MEP_formula}>
                        {formulaDisplay(formatCurrency(unearned_previous_charged_premium_before_tf), formatLabel('Unearned Premium'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatNumber(ep_premium_return_params.pct_of_tiv_removed, undefined, undefined, 4), formatLabel('TIV Factor'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatNumber(ep_premium_return_params.mep_factor, undefined, undefined, 2), formatLabel('MEP Factor'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatNumber(ep_premium_return_params.wind_season_factor, undefined, undefined, 2), formatLabel('Wind Season Factor'))}
                    </If>
                    <If condition={!ep_premium_return_params.use_new_MEP_formula}>
                        <div className="bracket">min(</div>
                        {formulaDisplay(formatPercentage(ep_premium_return_params.premium_haircut), formatLabel('Haircut'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatCurrency(previous_charged_premium_before_tf), formatLabel('Total Written', 'Premium'))}
                        <div className="bracket">,</div>
                        {formulaDisplay(formatCurrency(unearned_previous_charged_premium_before_tf), formatLabel('Unearned Premium'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatNumber(ep_premium_return_params.pct_of_tiv_removed, undefined, undefined, 4), formatLabel('TIV Factor'))}
                        <div className="bracket">)</div>
                    </If>
                </div>
            );
        },
    },
    {
        inputProps: {
            label: 'Premium Return w/ Proof of Sale',
            value: 'PRWP',
        },
        chargedPremiumFunc: ({ account_transaction_results_obj }, _prevTransaction) =>
            account_transaction_results_obj && account_transaction_results_obj.ep_premium_return_with_proof_of_sale,
        additionalElementFunc: (changeInChargedPremium, { account_transaction_results_obj }, _, _featureFlag) => {
            let ep_premium_return_params = null,
                unearned_previous_charged_premium_before_tf = null;
            if (account_transaction_results_obj) {
                ep_premium_return_params = account_transaction_results_obj.ep_premium_return_params;
                unearned_previous_charged_premium_before_tf = account_transaction_results_obj.unearned_previous_charged_premium_before_tf;
            }
            return getPricingDisplay(
                changeInChargedPremium,
                <div className="formula-display  flex-grow">
                    <If condition={ep_premium_return_params.use_new_MEP_formula}>
                        {formulaDisplay(formatCurrency(unearned_previous_charged_premium_before_tf), formatLabel('Unearned Premium'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatNumber(ep_premium_return_params.pct_of_tiv_removed, undefined, undefined, 4), formatLabel('TIV Factor'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatNumber(ep_premium_return_params.mep_factor, undefined, undefined, 2), formatLabel('MEP Factor'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(
                            formatNumber(ep_premium_return_params.wind_season_factor_with_proof, undefined, undefined, 2),
                            formatLabel('Wind Season Factor')
                        )}
                    </If>
                    <If condition={!ep_premium_return_params.use_new_MEP_formula && ep_premium_return_params.use_VT01291016}>
                        {formulaDisplay(formatCurrency(unearned_previous_charged_premium_before_tf), formatLabel('Unearned Premium'))}
                        <div className="operator">{TIMES}</div>
                        {formulaDisplay(formatNumber(ep_premium_return_params.pct_of_tiv_removed, undefined, undefined, 4), formatLabel('TIV Factor'))}
                    </If>
                </div>
            );
        },
    },
    {
        inputProps: {
            label: 'Manually Enter',
            value: 'MA',
        },
    },
];

const INPUT_FEE_GROUP_LIST = [
    {
        label: 'Modeling Fee',
        name: 'modeling_fee',
        prev_atr_total_field: 'total_modeling_fees',
    },
    {
        label: 'Inspection Fee',
        name: 'inspection_fee',
        prev_atr_total_field: 'total_inspection_fees',
    },
];

const OVERRIDE_LIST = [
    {
        visibleFunc: account_transaction_results_obj => {
            return account_transaction_results_obj.is_equipment_breakdown_referral_required;
        },
        label: 'Equipment Breakdown Referral Price',
        overrideFieldName: 'equipment_breakdown_external_premium_delta_override',
        technicalFieldFunc: (account_transaction_results_obj, prevTransaction) => {
            let eb_account_rate = null,
                meanTiv = null,
                term = null,
                previousMeanTiv = null,
                previousTerm = null,
                ep_same_eb_acct_rate_delta = null;

            if (account_transaction_results_obj) {
                eb_account_rate = account_transaction_results_obj.eb_account_rate;
                meanTiv = account_transaction_results_obj.mean_tiv;
                term = account_transaction_results_obj.term;
                ep_same_eb_acct_rate_delta = account_transaction_results_obj.ep_same_eb_acct_rate_delta;
            }

            if (prevTransaction && prevTransaction.account_transaction_results_obj) {
                previousMeanTiv = prevTransaction.account_transaction_results_obj.mean_tiv;
                previousTerm = prevTransaction.account_transaction_results_obj.term;
            }

            return {
                ebRatePerOneHundred: eb_account_rate,
                meanTiv,
                term,
                previousMeanTiv,
                previousTerm,
                ebReferral: ep_same_eb_acct_rate_delta,
            };
        },
    },
    // {
    //     label: 'AIR Cat AAL Override',
    //     override_field_name: 'air_cat_aal_override',
    //     technical_field_name: 'air_cat_aal',
    //     name: '',
    // },
];

export default class EndorsementPricingApp extends React.Component<propTypes, Record<string, any>> {
    baseline: number;

    constructor(props: propTypes) {
        super(props);

        let { transaction } = props;

        let atr: AccountTransactionResultDataType | Record<string, unknown> = transaction.account_transaction_results_obj;
        if (!atr) {
            atr = {};
            console.warn('ATR MISSING!');
        }
        this.baseline = atr ? Number(atr.previous_charged_premium_before_tf) : 0;
        this.state = {
            inspection_fee: transaction.inspection_fee,
            modeling_fee: transaction.modeling_fee,
            pricing_type: transaction.endorsement_pricing_type,
            pricing_override: transaction.endorsement_price_delta_override ? Number(transaction.endorsement_price_delta_override) : null,
            equipment_breakdown_external_premium_delta_override: transaction.equipment_breakdown_external_premium_delta_override,
            eb_referral_selection_type: 'DEFAULT',
        };
    }

    onSetPricingType = (type: string) => {
        this.setState({
            pricing_type: type,
        });
    };

    overrideUpdated = (val: number) => {
        this.setState({
            pricing_override: val,
            pricing_type: 'MA',
        });
    };

    updateFees = (field: string, val: number) => {
        this.setState({
            [field]: val,
        });
    };

    updateOverride = (field: string, val: number) => {
        this.setState({
            [field]: val,
        });
    };

    handleSelectEbReferral = (type: string) => {
        this.setState({ eb_referral_selection_type: type });
    };

    savePricing = (): void => {
        this.props.onSaveEndorsementPricing(
            this.props.transaction,
            this.state.pricing_type,
            this.state.pricing_override,
            this.state.equipment_breakdown_external_premium_delta_override,
            this.state.inspection_fee,
            this.state.modeling_fee
        );
        this.props.onClose(true);
    };

    onEveryChange = (name, val) => {
        this.overrideUpdated(val);
    };

    onFeesChange = (name, val) => {
        this.updateFees(name, val);
    };

    render(): React.ReactNode[] {
        let { transaction, prevTransaction, featureFlags } = this.props;
        const atr = transaction.account_transaction_results_obj;
        if (!atr) {
            return null;
        }
        let newChargedPremium = null;
        let changeInPremiumAnnualized = 0;
        let newRenewalPremium = 0;
        let tmcp = atr.target_minimum_charged_premium;

        let isBound = transaction.status === 'BOUND';
        // This assertion blocking certain tx types from repricing should be mirrored in backend in update_policy_info_after_change_in_endorsement_pricing.
        let disabled =
            isBound ||
            ([TRANSACTION_TYPES.FLAT_CANCELLATION, TRANSACTION_TYPES.REINSTATEMENT, TRANSACTION_TYPES.REVERSION] as string[]).includes(
                transaction.transaction_type
            );

        let inputs: ((typeof INPUT_GROUP_LIST)[number] & { additionalElement?: React.ReactNode })[] = _.cloneDeep(INPUT_GROUP_LIST);

        if (!atr.ep_premium_return) {
            inputs = _.filter(inputs, i => i.inputProps.value !== 'PR');
        }
        if (!atr.ep_premium_return_with_proof_of_sale) {
            inputs = _.filter(inputs, i => i.inputProps.value !== 'PRWP');
        }
        if (isNullish(atr.latest_account_rate) || !featureFlags?.show_latest_transaction_pricing_methods) {
            inputs = _.filter(inputs, i => i.inputProps.value !== 'LAR');
        }
        if (isNullish(atr.latest_technical_ratio) || !featureFlags?.show_latest_transaction_pricing_methods) {
            inputs = _.filter(inputs, i => i.inputProps.value !== 'LT');
        }

        for (let input of inputs) {
            if (input.chargedPremiumFunc) {
                let chargedPremium = input.chargedPremiumFunc(transaction, prevTransaction);
                let changeInPremium = chargedPremium - this.baseline;

                if (this.state.pricing_type === input.inputProps.value) {
                    newChargedPremium = chargedPremium;
                }
                if (input.inputProps.value === 'AR') {
                    changeInPremiumAnnualized = changeInPremium / atr.remaining_term;
                    newRenewalPremium = changeInPremiumAnnualized + this.baseline;
                }
                input.additionalElement = input.additionalElementFunc(changeInPremium, transaction, prevTransaction, featureFlags);
            } else {
                input.additionalElement = (
                    <React.Fragment key={input.inputProps.value}>
                        <div className="flex-grow" />
                        <NumericInput
                            object={this.state}
                            name="pricing_override"
                            format={{ subtype: 'currency' }}
                            disabled={disabled}
                            onEveryChange={this.onEveryChange as OnEveryChangeType}
                        />
                    </React.Fragment>
                );

                if (this.state.pricing_type === input.inputProps.value) {
                    newChargedPremium = this.state.pricing_override;
                }
            }
        }

        const feeInputs = INPUT_FEE_GROUP_LIST.map(input => (
            <div className="input_fee_wrapper" key={input.label}>
                <div className="input_current_value">
                    {formatNumber(prevTransaction.account_transaction_results_obj[input.prev_atr_total_field], '$', undefined, 2)}{' '}
                </div>
                <NumericInput
                    {...input}
                    disabled={disabled}
                    object={this.state}
                    format={{ subtype: 'currency' }}
                    onEveryChange={this.onFeesChange as OnEveryChangeType}
                />
            </div>
        ));

        let overrides = OVERRIDE_LIST.map((input, i) => {
            if (!input.visibleFunc(atr)) return null;

            let technicalField: Record<string, any> = input.technicalFieldFunc(atr, prevTransaction) || {};

            return (
                <div>
                    <div className="padding-l-r">
                        <div className="row pd-tp">
                            <h5>Technical Price Overrides</h5>
                            <h4 className="result-col">Change In Technical Price</h4>
                        </div>

                        <div className="row">
                            <div className="viki-radio-group">
                                <div className="radio-element-wrapper">
                                    <div className="input-control radio-input-control">
                                        <label className="input-row n-pd-btm">
                                            <div className="input-label flex">
                                                <b>{input.label}</b>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="formula-display flex-grow">
                                        {formulaDisplay(
                                            formatNumber(technicalField.ebRatePerOneHundred, '$', undefined, 4),
                                            formatLabel('EB rate per', '$100 of limit')
                                        )}
                                        <div className="operator">{TIMES}</div>
                                        {formulaDisplay('1/(365*100)', formatLabel('Unit Adj'))}
                                        <div className="operator">{TIMES}</div>
                                        <div className="bracket">(</div>
                                        {formulaDisplay(formatCurrency(technicalField.meanTiv), 'endorsement mean tiv')}
                                        <div className="operator">{TIMES}</div>
                                        {formulaDisplay(formatNumber(technicalField.term * 365, undefined, undefined, 0), formatLabel('new term', 'in days'))}
                                        <div className="operator">{MINUS}</div>
                                        {formulaDisplay(formatCurrency(technicalField.previousMeanTiv), 'previous mean tiv')}
                                        <div className="operator">{TIMES}</div>
                                        {formulaDisplay(
                                            formatNumber(technicalField.previousTerm * 365, undefined, undefined, 0),
                                            formatLabel('prev term', 'in days')
                                        )}
                                        <div className="bracket">)</div>
                                    </div>

                                    <div className="formula-display">
                                        <div className="operator">{EQUALS}</div>
                                    </div>

                                    <div className="pricing-txt result-col">{formatCurrency(technicalField.ebReferral)}</div>
                                </div>

                                <div className="radio-element-wrapper">
                                    <div className="input-control radio-input-control">
                                        <label className="input-row n-pd-btm">
                                            <div className="input-label flex">
                                                <b>Manually Enter</b>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="flex-grow"></div>
                                    <NumericInput
                                        name={input.overrideFieldName}
                                        disabled={disabled}
                                        key={i}
                                        object={this.state}
                                        format={{ subtype: 'currency' }}
                                        onEveryChange={
                                            _.debounce((name: string, val) => {
                                                this.updateOverride(name, val);
                                            }, 750) as OnEveryChangeType
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }).filter(x => x); // remove nulls

        return [
            <div key="content" className="standard-modal-content no-padding">
                <If condition={overrides}>
                    {overrides}
                    <div className="divider-line dl-margin-default"></div>
                </If>

                <div className="padding-l-r">
                    <div className="row">
                        <h5>Pricing Method</h5>
                        <h4 className="result-col">Change in Charged Premium</h4>
                    </div>
                    <div className="row">
                        <RadioInputGroup object={this.state} name="pricing_type" inputGroupList={inputs} onChange={this.onSetPricingType} disabled={disabled} />
                    </div>
                </div>

                <div className="divider-line dl-margin-default"></div>

                <div className="padding-l-r">
                    <div className="row price-summary-row">
                        <div className="price-summary-txt">
                            <h6>New Price</h6>
                            <div className="pricing-txt">{formatCurrency(newChargedPremium)}</div>
                        </div>
                        <div className="price-summary-txt">
                            <h6>Target Premium</h6>
                            <div className="pricing-txt">{formatCurrency(tmcp)}</div>
                        </div>
                        <If condition={newRenewalPremium}>
                            <div className="price-summary-txt">
                                <h6>
                                    <span className="big-text">~</span> Renewal Premium
                                    <br />
                                    By Account Rate
                                </h6>
                                <div className="pricing-txt">{formatCurrency(newRenewalPremium)}</div>
                            </div>
                            <div className="price-summary-txt">
                                <h6>
                                    <span className="big-text">~</span> Annual Change
                                    <br />
                                    in Premium
                                </h6>
                                {pricingDisplay(changeInPremiumAnnualized)}
                            </div>
                        </If>
                    </div>
                    <div className="row inputs-centered">{feeInputs}</div>
                </div>
            </div>,

            <div key="buttons" className="button-row padding-l-r">
                <If condition={!disabled}>
                    <button className="blue" onClick={this.savePricing}>
                        Save
                    </button>
                    <div className="spacer wide" />
                    <button
                        className="grey-dark"
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        Cancel
                    </button>
                </If>
                <If condition={disabled}>
                    <button
                        className="grey-dark"
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        Close
                    </button>
                </If>
            </div>,
        ];
    }
}
