import React, { ReactNode, useEffect } from 'react';

import { BuildingsPanel, ExpiringTermsAndPricingPanel, PortfolioComparablesPanel, CarrierSharesPanel } from '../pricing-page';
import { usePrefetch } from '../../services/apiSlice';
import { useAccountTransactionDataQuery } from '../../services/endpoints/account';
import { SubmissionDataType } from '../../ts-types/DataTypes';
import RMSAIRMetricsPanel from '../../features/pricing/RMSAIRMetricsPanel';

export enum PricingTab {
    METRICS = 'METRICS',
    EXPIRING = 'EXPIRING',
    COMPARABLES = 'COMPARABLES',
    BUILDINGS = 'BUILDINGS',
    CARRIERS = 'CARRIERS',
}

type TabProps = { children: ReactNode; tabName: PricingTab };
const Tab = ({ children }: TabProps) => {
    return (
        <div className="flex overflow-y-auto h-100">
            <div className="flex-1 pricing-tab-panel-content">{children}</div>
        </div>
    );
};

type TabSelectionProps = { children: ReactNode; currentTab: PricingTab; onSelect: (tab: PricingTab) => void };
const TabSelection = ({ children, currentTab, onSelect }: TabSelectionProps) => {
    const tabs = React.Children.toArray(children);
    return (
        <>
            <div className="flex handles pricing">
                {tabs.map(child => {
                    if (!React.isValidElement<TabProps>(child) || child.type !== Tab) {
                        return child;
                    }
                    const tabName = child.props.tabName;
                    return (
                        <a
                            key={tabName}
                            className={`flex-1 flex jc_c margin-0 pad-025 select-none handle ${tabName === currentTab ? 'show-active' : 'show-inactive'}`}
                            onClick={() => onSelect(tabName)}
                        >
                            {tabName}

                            {/* <div className="icon w-spinner">
                                <div className="progress-indicator extra-small" />
                            </div> */}
                        </a>
                    );
                })}
            </div>
            {tabs.find(child => {
                if (React.isValidElement<TabProps>(child) && child.type === Tab) {
                    return child.props.tabName === currentTab;
                }
            })}
        </>
    );
};

type TabsPanelProps = {
    currentSubmission: SubmissionDataType;
    currentTab: PricingTab;
    onChangeTab: (tab: PricingTab) => void;
    showPricingTabTodo: boolean;
    showLayeringAALsPMLs: boolean;
    showTechnicalPremiumByPeril: boolean;
};

export const PricingTabsPanel = ({
    currentSubmission,
    currentTab,
    onChangeTab,
    showPricingTabTodo,
    showLayeringAALsPMLs,
    showTechnicalPremiumByPeril,
}: TabsPanelProps) => {
    const renewalId = currentSubmission?.renewal_of_id;
    const { data: expiringSubmissionData } = useAccountTransactionDataQuery({ submissionId: +renewalId }, { skip: !renewalId });
    const prefetchExpiringQuote = usePrefetch('quoteFinalPremium');

    useEffect(() => {
        if (expiringSubmissionData?.primary_quote_id) {
            prefetchExpiringQuote({ quoteId: expiringSubmissionData.primary_quote_id });
        }
    }, [prefetchExpiringQuote, expiringSubmissionData]);

    return (
        <>
            <TabSelection currentTab={currentTab} onSelect={onChangeTab}>
                <Tab tabName={PricingTab.METRICS}>
                    <RMSAIRMetricsPanel
                        showPricingTabTodo={showPricingTabTodo}
                        currentSubmission={currentSubmission}
                        showLayeringAALsPMLs={showLayeringAALsPMLs}
                        showTechnicalPremiumByPeril={showTechnicalPremiumByPeril}
                    />
                </Tab>

                <Tab tabName={PricingTab.EXPIRING}>
                    <ExpiringTermsAndPricingPanel currentSubmission={currentSubmission} renewalQuoteId={expiringSubmissionData?.primary_quote_id} />
                </Tab>
                <Tab tabName={PricingTab.COMPARABLES}>
                    <PortfolioComparablesPanel />
                </Tab>
                {showPricingTabTodo && (
                    <Tab tabName={PricingTab.BUILDINGS}>
                        <BuildingsPanel />
                    </Tab>
                )}
                <Tab tabName={PricingTab.CARRIERS}>
                    <CarrierSharesPanel />
                </Tab>
            </TabSelection>
        </>
    );
};
