import * as React from 'react';
import moment from 'moment';

import { DateInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { AppContextType, AppContextFnsType } from '../../../../ts-types/AppTypes';
import { DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';

type propTypes = {
    onClose: (x?: boolean) => any;
    appContext: AppContextType;
    appContextFns: AppContextFnsType;
};

export default class UpdateCancellationEffectiveDateApp extends React.Component<propTypes, any> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            effective_date: null,
        };
    }

    handleChangeEffectiveDate = () => {
        const currentTransactionId = this.props.appContext.currentTransaction.id;
        this.props.appContextFns.onUpdateCancellationEffectiveDate(currentTransactionId, this.state.effective_date).then(() => {
            this.props.onClose(true);
        });
    };

    render() {
        let format = { dateDisplayFormat: DISPLAY_DATE_FORMAT };
        let { appContext } = this.props;
        let { currentTransaction } = appContext;
        return [
            <div key="content" className="standard-modal-content flex column">
                <DateInput
                    format={format}
                    key="effdate"
                    name="effective_date"
                    label="New Effective Date"
                    onChange={(k, v) => this.setState({ [k]: v })}
                    minDate={moment(currentTransaction.effective_date_min, 'YYYY-MM-DD').toDate()}
                    maxDate={moment(currentTransaction.effective_date_max, 'YYYY-MM-DD').toDate()}
                    object={this.state}
                />
            </div>,
            <div key="buttons" className="button-row">
                <button disabled={!this.state.effective_date} className="green" onClick={this.handleChangeEffectiveDate}>
                    Ok
                </button>
                <div className="spacer wide" />
                <button
                    className="red"
                    onClick={() => {
                        this.props.onClose(true);
                    }}
                >
                    Cancel
                </button>
            </div>,
        ];
    }
}
