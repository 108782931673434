import { formatNumberIntl, VentusNumberFormatOptions } from '@archinsurance-viki/property-jslib/src/utils/converters';

/* Thin wrapper component to use standard number formatter */
export const FormattedValue = ({ value, className, options }: { value: number; className?: string; options?: VentusNumberFormatOptions }) => {
    return <div className={className}>{formatNumberIntl(value, options ?? {})}</div>;
};

export const CurrencyValue = ({
    value,
    className,
    showDollarSign = false, // hide dollar sign by default
    options,
}: {
    value: number;
    showDollarSign?: boolean;
    className?: string;
    options?: Omit<VentusNumberFormatOptions, 'style'>;
}) => <FormattedValue className={className} value={value} options={{ ...options, style: showDollarSign ? 'currency' : 'decimal' }} />;

/* Default decimal places is 2 */
export const RateValue = ({ value, className, options }: { value: number; className?: string; options?: VentusNumberFormatOptions }) => (
    <FormattedValue className={className} value={value} options={{ maximumFractionDigits: 2, minimumFractionDigits: 2, ...options }} />
);

/* Default decimal places is 1 */
export const PercentageValue = ({
    value,
    className,
    shouldScalePercentage = false, // when true, Intl.NumberFormat will multiply value by 100 to convert to percent display
    options,
}: {
    value: number;
    shouldScalePercentage?: boolean;
    className?: string;
    options?: Omit<VentusNumberFormatOptions, 'style'>;
}) => (
    <FormattedValue
        className={className}
        value={shouldScalePercentage ? value : value / 100.0}
        options={{ maximumFractionDigits: 1, minimumFractionDigits: 1, ...options, style: 'percent' }}
    />
);
