import Pusher from '@archinsurance-viki/property-jslib/src/components/Pusher';
import { AUTH0_LOCKR_KEY } from '@archinsurance-viki/property-jslib/src/reducers/auth0';
import { assertIn } from '@archinsurance-viki/property-jslib/src/utils/api-helpers';
import { Environment, getCurrentEnvironment } from '@archinsurance-viki/property-jslib/src/utils/environment-helpers';
import axios from 'axios/index';
import Lockr from 'lockr';

function initBaseUrl() {
    let baseUrl = '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    let clearanceUrl = '';

    const domainRegExp = /:\/\/([^/]+).ventusrisk/.exec(window.location.href);
    const subdomain = domainRegExp && domainRegExp[1] ? domainRegExp[1] : null; // for unit tests

    if (subdomain === 'viki-local' || subdomain === 'rae-local') {
        clearanceUrl = '//vmac-local.ventusrisk.com:8001';
    } else if (subdomain.startsWith('viki')) {
        clearanceUrl = `//${window.location.host.replace('viki', 'vmac')}`;
    } else if (subdomain.startsWith('rae')) {
        clearanceUrl = `//${window.location.host.replace('rae', 'vmac')}`;
    } else if (subdomain.startsWith('arch-programs')) {
        clearanceUrl = null;
    } else {
        throw new Error('Failure calculating VMAC equivalent domain.');
    }

    return [baseUrl, clearanceUrl];
}

const isTest = process.env.NODE_ENV === 'test';
export const [BASEURL, CLEARANCE_URL] = isTest ? ['localhost', 'localhost'] : initBaseUrl();
const environment = isTest ? Environment.Local : getCurrentEnvironment();

const __API = axios.create({
    withCredentials: true,
    headers: { Accept: 'application/json' },
    xsrfCookieName: `csrftoken-${environment}`,
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: `${BASEURL}/api/v1/`,
});

__API.interceptors.request.use(config => {
    const authToken = Lockr.get(AUTH0_LOCKR_KEY);
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
});

const __API2 = axios.create({
    withCredentials: true,
    headers: { Accept: 'application/json' },
    xsrfCookieName: `csrftoken-${environment}`,
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: `${BASEURL}/api/v2/`,
});

__API2.interceptors.request.use(config => {
    const authToken = Lockr.get(AUTH0_LOCKR_KEY);
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
});

export { __API, __API2 };

export const __VMACAPI = CLEARANCE_URL
    ? axios.create({
          withCredentials: true,
          headers: { Accept: 'application/json' },
          baseURL: `${CLEARANCE_URL}/api/v1/`,
      })
    : null;

export const endpoints = {
    initialize: () => {
        axios.defaults.params = {};
        axios.defaults.params['pusher_socket_id'] = Pusher.socket_id();
    },

    accountSubmissionView: {
        // TODO: Don't call this for every page
        get: (options: Record<string, any>) => {
            endpoints.initialize();

            let submission = __API.get(`account_transaction_data/${options.id}/`).then(({ data }) => data);

            const buildings = __API.get(`building_data/?submission_id=${options.id}&fields=geo,id`);
            const statisticsSummary = __API.get(`/dashboard/statistics?submission_id=${options.id}`);

            const carrierData = __API
                .get(
                    `account_transaction_carrier_data/?account_view_id=${options.id}&actual_allocated_pct__gt=0` +
                        `&fields=pool_name,carrier_name,actual_allocated_premium,final_net_participation_pct&ordering=-actual_allocated_premium`
                )
                .then(({ data }: { data: any }) => data.results);

            const otherSubmissionData = __API2
                .get(
                    [
                        `submission/${options.id}/`,
                        'quotes.*',
                        'quotes.associated_policies.*',
                        'quotes.final_carrier_data.*',
                        'quotes.final_premium.*',
                        'quotes.policy_coverage.*',
                        'insured.possible_duplicate_sids',
                        'additional_named_insureds.*',
                        'archlink_communication_statuses.*',
                        'is_live_production_submission',
                        'is_pw_uploadable',
                        'is_sa_uploadable',
                        'is_insured_flag',
                        'insured_flag_reason',
                        'renewal_of_id',
                        '_actions.*',
                    ]
                        .join('&include[]=')
                        .replace(/&/, '?') + '&show_possible_duplicate_sids=1&filter{quotes|is_deleted}=0&exclude[]=*',
                    {
                        params: options.params,
                    }
                )
                .then(({ data }: { data: any }) => {
                    return data.submission;
                });

            return axios.all([
                submission,
                buildings,
                statisticsSummary,
                // exposureSummary,
                otherSubmissionData,
                carrierData,
            ]);
        },
    },
    additionalStructures: {
        get: function (options: Record<string, any>) {
            return __API.get('additional_property_exposures/', options);
        },
        post: function (options: Record<string, any>) {
            return __API.post(`additional_property_exposures/`, options.params.structure);
        },
        put: function (options: Record<string, any>) {
            return __API.put(`additional_property_exposures/${options.params.structure.id}/`, options.params.structure);
        },
        delete: function (options: Record<string, any>) {
            return __API.delete(`additional_property_exposures/${options.params.structureId}/`);
        },
    },

    additionalNamedInsureds: {
        post: function (options: Record<string, any>) {
            return __API.post(`additional_named_insured/`, options.params.additionalNamedInsured);
        },
        put: function (options: Record<string, any>) {
            return __API.put(`additional_named_insured/${options.params.additionalNamedInsured.id}/`, options.params.additionalNamedInsured);
        },
        delete: function (options: Record<string, any>) {
            return __API.delete(`additional_named_insured/${options.params.aniID}/`);
        },
    },

    blanketCoverage: {
        create: function (options: Record<string, any>) {
            return __API.post(`blanket_coverage/?quote_id=${options.id}`, options.params);
        },
        getBlanketGroups: (options: Record<string, any>) => {
            return __API.get(`/blanket_coverage/?quote_id=${options.quoteId}`, options);
        },
    },

    building: {
        geocode: function (options: Record<string, any>) {
            return __API.get(`building/geocode?building_id=${options.id}`);
        },
        reverseGeocode: function (options: Record<string, any>) {
            return __API.get(`building/reverse_geocode?building_id=${options.id}`);
        },
        hazardHubData: function (options: Record<string, any>) {
            return __API.post(`fetch_hazardhub_data`, options.params);
        },
        allHazardHubData: function (options: Record<string, any>) {
            return __API.post(`fetch_all_hazardhub_data`, options.params);
        },
        MSBData: function (options: Record<string, any>) {
            return __API.post('fetch_msb_valuation', options.params);
        },
        allMSBData: function (options: Record<string, any>) {
            return __API.post('fetch_all_msb_valuation', options.params);
        },
        buildfax: function (options: Record<string, any>) {
            return __API.post('fetch_buildfax', options.params);
        },
        allBuildfax: function (options: Record<string, any>) {
            return __API.post('fetch_all_buildfax', options.params);
        },
        capeAnalytics: function (options: Record<string, any>) {
            return __API.post('fetch_cape_analytics', options.params);
        },
        allCapeAnalytics: function (options: Record<string, any>) {
            return __API.post('fetch_all_cape_analytics', options.params);
        },
        intermapData: function (options: Record<string, any>) {
            return __API.post('fetch_intermap_data', options.params);
        },
        allIntermapData: function (options: Record<string, any>) {
            return __API.post('fetch_all_intermap_data', options.params);
        },
        geocodePreferredSource: function (options: Record<string, any>) {
            return __API.post('geocode_with_preferred_source', options.params);
        },
        pingData: function (options: Record<string, any>) {
            return __API.post(`fetch_ping_data`, options.params);
        },
        allPingData: function (options: Record<string, any>) {
            return __API.post('fetch_all_ping_data', options.params);
        },
    },

    clearance: {
        changeStatus: function (options: Record<string, any>) {
            return axios.all([__API.put(`clearance/emails/${options.params.emailId}/change_status/`, options.params)]);
        },
        search: function (options: Record<string, any>) {
            let { globalSearchTerm, columnFilter, filterText } = options.params;
            let url = 'clearance/';
            if (globalSearchTerm) {
                url += `&filter=${globalSearchTerm}`;
            } else if (columnFilter && filterText) {
                url += `&${columnFilter}=${filterText}`;
            }
            return __API.get(url, options.params);
        },
    },
    carrier: {
        get: function (options: Record<string, any>) {
            return __API.get(`/quotes/${options.id}/carrier_pools/`);
        },
        getByTransaction: function (options: Record<string, any>) {
            return __API2.get(`/account_transaction/${options.id}/carrier_pools/`, options.params);
        },
        put: function (options: Record<string, any>) {
            return __API.put(`/quotes/${options.id}/carrier_pools/`, options.params);
        },
    },
    endorsement: {
        get: function (options: Record<string, any>) {
            return __API2.get(
                [
                    `/account_transaction/${options.transactionId}/`,
                    'account_transaction_results_obj.*',
                    'submission.quotes.*',
                    'last_interval_submission_obj',
                    'transaction_underwriter.*',
                ]
                    .join('&include[]=')
                    .replace(/&/, '?')
            );
        },
        create: function (options: Record<string, any>) {
            return __API2.put(`/account/${options.accountId}/create_transaction/`, options.params);
        },
        update: function (options: Record<string, any>) {
            return __API2.patch(`/account_transaction/${options.transactionId}/`, options.params);
        },
        changeStatus: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.transactionId}/change_status/`, options.params);
        },
        repriceTransaction: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.transactionId}/reprice_transaction/`, options.params);
        },
        delete: function (options: Record<string, any>) {
            return __API2.delete(`/account_transaction/${options.transactionId}/`);
        },
        unbind: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.transactionId}/unbind_transaction/`, options.params);
        },
        scheduleOfLocationsExport: function (options: Record<string, any>) {
            return __API2.get(`/account_transaction/${options.transactionId}/export_schedule_of_locations/`);
        },
        bookTransaction: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.transactionId}/book_transaction/`);
        },
        unbookTransaction: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.transactionId}/unbook_transaction/`);
        },
        updateEndorsementForms: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.transactionId}/update_endorsement_forms/`, options.params);
        },
        updateCancellationEffectiveDate: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.transactionId}/change_effective_date/`, options.params);
        },
    },
    note: {
        get: function (options: Record<string, any>) {
            let url = options.noteId ? `/note/${options.noteId}/` : `/note/`;
            if (options.params.accountId) {
                url = `${url}?include[]=change_by_id.*&account=${options.params.accountId}`;
            }
            return __API2.get(url, options.params);
        },
        create: function (options: Record<string, any>) {
            return __API2.post(`/note/`, options.params);
        },
        update: function (options: Record<string, any>) {
            return __API2.put(`/note/${options.noteId}/`, options.params);
        },
    },
    report: {
        generateQuoteLetter: function (options: Record<string, any>) {
            assertIn('quote_id', options.params, 'quote id flag missing');
            return __API.post(`/reports/generate_quote_letter`, options.params);
        },
        generateBindLetter: function (options: Record<string, any>) {
            assertIn('quote_id', options.params, 'quote id flag missing');
            return __API.post(`/reports/generate_binding_letter`, options.params);
        },
        generateEndorsementPackage: function (options: Record<string, any>) {
            return __API.post('/reports/generate_endorsement_package', options.params);
        },
        unbindSubmission: function (options: Record<string, any>) {
            assertIn('submission_id', options.params, 'submission id missing');
            return __API.post(`/reports/unbind_quote`, options.params);
        },
        updateBindingRequirements: function (options: Record<string, any>) {
            return __API.post(`/reports/update/params`, options.params);
        },
        quoteCondBindReqs: (options: Record<string, any>) => {
            assertIn('quoteId', options, 'final premium id');
            return __API2.get(`/quote_form_data?quote_id=${options.quoteId}&include_custom_quote_conditions=True`);
        },
        policyForms: (options: Record<string, any>) => {
            assertIn('quoteId', options, 'final premium id');
            return __API2.get(`/policy_form_data?quote_id=${options.quoteId}`);
        },
        generateAccountSummary: function (options: Record<string, any>) {
            return __API.post('/reports/generate_account_summary', options.params);
        },
    },
    accounts: {
        // This gets the submission for non-bound submissions and the account for bound ones.
        get: (options: Record<string, any>) => {
            return __API2.get(
                [
                    `account/${options.id}/`,
                    // 'account_id.*',
                    'account_transaction_ids.*',
                    'account_transaction_ids.account_transaction_results_obj.*',
                    // 'account_transaction_ids.submission.quotes.*',
                    'account_transaction_ids.last_interval_submission_obj',
                    'account_transaction_ids.transaction_underwriter.*',
                    'account_transaction_ids.review_by.*',
                    'account_transaction_ids.bound_by.*',
                    'account_transaction_ids.booked_by.*',
                ]
                    .join('&include[]=')
                    .replace(/&/, '?'),
                {
                    params: options.params,
                }
            );
        },
    },
    analytics: {
        pml: (options: Record<string, any>) => {
            const returnPeriods = [...new Set(options.params.return_periods)].join(',');
            const ys = [...new Set(options.params.ys)].join(',');
            const catViewTypes = [...new Set(options.params.cat_view_types)].join(',');
            const includesQuoted = [...new Set(options.params.includes_quoteds)].join(',');
            return __API2.get(
                `/air_pml_analytics?carrier=${options.params.carrier}&as_of_type=` +
                    `${options.params.as_of_type}&year=${options.params.year}` +
                    `&return_periods=${returnPeriods}&ys=${ys}&cat_view_types=${catViewTypes}` +
                    `&includes_quoteds=${includesQuoted}&in_force=${options.params.in_force}`
            );
        },
        getPml: (options: Record<string, any>) => {
            const params = Object.keys(options.params).map((key: string) => {
                if (Array.isArray(options.params[key])) {
                    return `${key}=${options.params[key].join(',')}`;
                } else {
                    return `${key}=${options.params[key]}`;
                }
            });
            return __API2.get(`/air_pml_daily_analytics/?${params.join('&')}`);
        },
        calculatePml: (options: Record<string, any>) => {
            const params = Object.keys(options.params).map((key: string) => {
                if (Array.isArray(options.params[key])) {
                    return `${key}=${options.params[key].join(',')}`;
                } else {
                    return `${key}=${options.params[key]}`;
                }
            });
            return __API2.get(`/calculate_all_air_pmls?${params.join('&')}`);
        },
    },
    submission: {
        validate: (options: Record<string, any>) => {
            if (options.coverageOptionId)
                return __API.get(
                    `submission/${options.submissionId}/validate/?coverage_option_id=${options.coverageOptionId}` +
                        '&validations=CAN_BE_BOUND,CAN_BE_QUOTED,CAN_PRICE_CAT,CAN_PRICE_AOP,CAN_BE_UW_REVIEW,CAN_PREVIEW_QUOTE,CAN_ISSUE_POLICY'
                );
            else
                return __API.get(
                    `submission/${options.submissionId}/validate/?validations=CAN_BE_BOUND,CAN_BE_QUOTED,CAN_PRICE_CAT,CAN_PRICE_AOP,CAN_BE_UW_REVIEW` +
                        ',CAN_PREVIEW_QUOTE,CAN_ISSUE_POLICY'
                );
        },

        clone: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/clone/`, {
                to_site_id: options.to_site_id,
            });
        },
        relockSubmission: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/relock_submission/`, {
                message: options.message,
            });
        },
        unlockSubmission: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/unlock_submission/`, {
                message: options.message,
            });
        },
        bookTransaction: (options: Record<string, any>) => {
            return __API2.put(`/account_transaction/${options.id}/book_transaction/`, {
                message: options.message,
            });
        },
        unbookTransaction: (options: Record<string, any>) => {
            return __API2.put(`/account_transaction/${options.id}/unbook_transaction/`, {
                message: options.message,
            });
        },
        renewal: (options: Record<string, any>) => {
            return __API.post(`submission/${options.id}/create_renewal/`, options.params);
        },
        insuredFlag: (options: Record<string, any>) => {
            return __API.post(`submission/${options.id}/change_insured_flag/`, options.params);
        },
        importXLS: (_options: Record<string, any>) => {
            console.error('Not implemented');
            // return __API.post(`submission/${options.id}/create_renewal/`, {
            //     params: options.params,
            // });
        },
        statisticsSummary: (options: Record<string, any>) => {
            return __API.get(`/dashboard/statistics?submission_id=${options.id}`);
        },
        exportXLS: (options: Record<string, any>) => {
            return __API.get(`submission/${options.id}/download_xlsx/`, {
                params: options.params,
            });
        },
        claimsDocuments: (options: Record<string, any>) => {
            return __API.get(`submission/${options.id}/claims_documents/`, {});
        },
        sendToClaimsVendor: (options: Record<string, any>) => {
            return __API.post(`submission/${options.id}/send_to_claims_vendor/`, {
                vendor: 'Sedgwick',
                ...options.params,
            });
        },
        exportCatData: (options: Record<string, any>) => {
            return __API.get(`submission/${options.id}/export_cat_data/`, {});
        },
        applyValuationOverride: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/building_valuation_bulk_override/`, options.params);
        },
        close: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/close_submission/`, options.params);
        },
        reOpen: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/reopen_submission/`);
        },
        sendToDataEntry: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/send_to_data_entry/`, options.params);
        },
        referEBPrice: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/refer_eb_price/`, options.params);
        },
        changeStatus: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/change_status/`, {
                change_to: options.newStatus,
            });
        },
        downloadDocuments: (options: Record<string, any>) => {
            return __API.get(`submission/${options.id}/download_documents/`);
        },
        saveDocumentData: (options: Record<string, any>) => {
            return __API.patch(`documents/${options.id}/`, options.params);
        },
        deleteDocument: (options: Record<string, any>) => {
            return __API.delete(`documents/${options.id}/`);
        },
        previewDocuments: (options: Record<string, any>) => {
            return __API.put(`documents/generate_previews/`, { document_ids: options.data }); //, force: true });
        },
        parseDocument: (options: Record<string, any>) => {
            return __API.put(`documents/${options.id}/parse_data_xlsx/`, options.params);
        },
        exportMergedDataEntrySpreadsheet: (options: Record<string, any>) => {
            return __API.get(`documents/${options.id}/export_merged_data_entry_spreadsheet/`, options.params);
        },
        uploadFiles: (options: Record<string, any>) => {
            return __API.post('document/upload', options);
        },
        getDocuments: (options: Record<string, any>) => {
            return __API2
                .get(`submission/${options.id}/?include[]=documents.*&filter{documents|is_visible}=1&exclude[]=*`)
                .then(({ data }) => (data as any).submission.documents);
        },
        dismissGeocodeWarnings: (options: Record<string, any>) => {
            return __API.put(`submission/${options.id}/dismiss_geocode_warnings/`, options.params);
        },
        parseSov: (options: Record<string, any>) => {
            return __API.put(`submission/${options.submission_id}/parse_sov/`, options);
        },
        policyIssuance: (options: Record<string, any>) => {
            return __API.put(`submission/${options.submission_id}/generate_policy_issuance/`, options);
        },
        overrideOfac: function (options: Record<string, any>) {
            return __API2.put(`/account_transaction/${options.submissionId}/override_ofac/`, options.params);
        },
        exportPolicyWriter: (options: Record<string, any>) => {
            return __API.get(`submission/${options.id}/export_policywriter_spreadsheet/`, options.params);
        },
        uploadPolicyWriter: (options: Record<string, any>) => {
            return __API.post(`submission/${options.id}/upload_to_policywriter_api/`, options.params);
        },
        uploadStrategicAnalytics: (options: Record<string, any>) => {
            return __API.get(`submission/${options.id}/upload_to_strategicanalytics_networkshare/`, options.params);
        },
        proceedToUWReview: (options: Record<string, any>) => {
            return __API.post(`submission/${options.id}/proceed_to_uw_review/`, options.params);
        },
    },

    producerInfo: {
        updateProducerInfo: (options: Record<string, any>) => {
            return __API.put(`/producer_info/${options.params.submissionId}/`, options.params);
        },
    },

    quote: {
        get: (options: Record<string, any>) => {
            const url = [`/quote/${options.id}/`, 'final_premium.*', 'policy_coverage.*', 'associated_policies.*'].join('&include[]=').replace(/&/, '?');
            return __API2.get(url).then(({ data }) => (data as any).quote);
        },
        markAsWithdrawn: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/withdrawn/`, options.params);
        },
        markAsNotTakenUp: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/not_taken_up/`, options.params);
        },
        markAsSentToAgent: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/sent_to_agent/`, options.params);
        },
        markAsPrimary: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/mark_as_primary/`, options.params);
        },
        duplicateQuote: (options: Record<string, any>) => {
            return __API.post(`/quotes/clone/`, options.params);
        },
        reprice: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/reprice/`, options.params);
        },
        markAsApproved: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/mark_as_approved/`, options.params);
        },
        requestCUOApproval: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/request_cuo_approval/`, options.params);
        },
        setOverrides: (options: Record<string, any>): Promise<any> => {
            assertIn('finalPremiumId', options, 'final premium id');
            return __API.put(`/final_premium/${options.finalPremiumId}/set_overrides/`, options.params);
        },
        getTivGuidelines: (options: Record<string, any>) => {
            return __API.get(`/dashboard/limitguidelines?quote_id=${options.id}`);
        },
        repriceQuote: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.id}/reprice/`);
        },
        getQuoteConditions: (options: Record<string, any>) => {
            return __API.get(`/building_premium/?coverage_option_id=${options.quoteId}`, options);
        },
        addCustomQuoteCondition: function (options: Record<string, any>) {
            return __API.post(`/quotes/${options.id}/custom_quote_conditions/`, options.params);
        },
        applyQuoteConditionsToBuilding: (options: Record<string, any>) => {
            return __API.put(`/quotes/${options.quoteId}/apply_quote_condition_to_buildings/`, options.params);
        },
        put: function (options: Record<string, any>) {
            return __API.put(`/quotes/${options.id}/`, options.params);
        },
        toggleRAECatModel: function (options: Record<string, any>) {
            return __API.put(`/quotes/${options.id}/toggle_rae_cat_model/`, options.params);
        },
        updateAssociatedPolicies: function (options: Record<string, any>) {
            // I'm using this one.
            return __API2.put(`/associated_policy/replace_all/?quote_id=${options.quoteId}`, { data: options.newPolicies });
        },
    },

    userAccessLog: {
        viewingAccount: (options: Record<string, any>) => {
            return __API2.post(`/user_access_log`, { module: 'ACCOUNT', id: options.id, route: options.route });
        },
    },

    viki: {
        getBIGlossary: () => {
            return __API.get('/bi-glossary?format=json').then(({ data }: { data: any }) => {
                const sections = [];
                let section;
                for (let item of data) {
                    if (item.type === 'section') {
                        section = { ...item, tables: [] };
                        sections.push(section);
                    } else {
                        if (section) {
                            section.tables.push({ ...item.table, fields: item.fields, description: item.table.description.trim() });
                        } else {
                            console.warn('Processing table with no section');
                        }
                    }
                }
                return sections;
            });
        },
    },

    vmac: {
        connectionIsActive: () => {
            return !!__VMACAPI;
        },
        getBIGlossary: () => {
            if (!__VMACAPI) return null;
            return __VMACAPI.get('/bi-glossary?format=json').then(({ data }: { data: any }) => {
                const sections = [];
                let section;
                for (let item of data) {
                    if (item.type === 'section') {
                        section = { ...item, tables: [] };
                        sections.push(section);
                    } else {
                        if (section) {
                            section.tables.push({ ...item.table, fields: item.fields, description: item.table.description.trim() });
                        } else {
                            console.warn('Processing table with no section');
                        }
                    }
                }
                return sections;
            });
        },
    },
};

export const staticMethods = {
    get: function (url: string) {
        return __API.request({ url: url, baseURL: BASEURL });
    },
};
