import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import isEqual from 'react-fast-compare';

import { RowSelectedFnType, PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import SubLogBottomPanelApp from '../apps/SubLogBottomPanelApp';
import { AppContextType } from '../../../../ts-types/AppTypes';
import { Types } from '../../../../ts-types/viki-types';

type ownPropTypes = {
    appContext: AppContextType;
    tableGlossary: Record<string, any>;
};

type propTypes = {
    CONSTANTS: Types.Constants;
    onRowSelected: RowSelectedFnType;
    onSaveFn: (...params: any) => any;
    selectedRowIndex: number;
    tableData: PagedTableDataType;
} & ownPropTypes;

const mapStateToProps = (_state: Record<string, any>, _ownProps: ownPropTypes) => {
    return {};
};

const mapDispatchToProps = (_dispatch: DispatchType, _ownProps: ownPropTypes) => {
    return {};
};

class SubLogBottomPanelContainer extends React.Component<propTypes> {
    shouldComponentUpdate(nextProps: propTypes) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        return <SubLogBottomPanelApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(SubLogBottomPanelContainer);
export default connected;
