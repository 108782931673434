import u from 'updeep';

import { API } from '@archinsurance-viki/property-jslib';
import { closeCenteredModal, openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { updateDataByPath } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { CENTERED_MODAL_TYPES, TRANSACTION_STATUS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';

import * as types from '../constants/ActionTypes';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { validateSubmission_server } from './SubmissionActions';
import { VIRA_MODAL_TYPES } from '../constants/Modal';
import { getAccountSubmissionViewFromServer } from './SubmissionActions';
import { AppDispatch } from '../store';
import { updateTaskProgress, updateUi } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';

export const updateTransaction = (transactionId: number, data: Record<string, any>): any => ({
    type: types.UPDATE_DATA_BY_PATH,
    tableConfig: LITE_TABLES.TRANSACTIONS,
    path: `rowData.${transactionId}`,
    update: u.constant(data),
});

export const discardEndorsement = (accountId: number, transactionId: number): any => ({
    type: types.DISCARD_ENDORSEMENT,
    accountId,
    transactionId,
});

type GoToSubmissionType = (accountId: number, x: null, y: boolean, z: null, obj: { transactionId: number | null }) => void;

export const discardEndorsement_server = (accountId: number, transactionId: number, goToSubmission: GoToSubmissionType) => {
    return (dispatch: AppDispatch) => {
        return dispatch(
            openCenteredModal(
                {
                    title: 'Are you sure you want to dismiss this endorsement?',
                    modalData: {
                        confirmLabelTitle: 'Discard',
                        dismissButtonTitle: 'Cancel',
                        onOk: () => {
                            dispatch(discardEndorsement(accountId, transactionId));
                            return API.doRequest(
                                API.endpoints.endorsement.changeStatus,
                                {
                                    transactionId,
                                    params: {
                                        change_to: TRANSACTION_STATUS.CANCELLED,
                                    },
                                    showProgress: {
                                        isBlocking: true,
                                        message: `Discarding Transaction: ${transactionId}`,
                                    },
                                },
                                dispatch
                            )
                                .then(() => {
                                    dispatch(getAccountSubmissionViewFromServer(accountId));
                                })
                                .then(() => {
                                    goToSubmission(accountId, null, true, null, { transactionId: null });
                                });
                        },
                    },
                },
                CENTERED_MODAL_TYPES.CONFIRM
            )
        );
    };
};

export const unbindEndorsement_server = (currentSubmissionId: number, transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.unbind,
            {
                transactionId,
                showProgress: {
                    isBlocking: true,
                    message: `Unbinding Transaction: ${transactionId}`,
                },
            },
            dispatch
        ).then(
            () => {
                dispatch(getAccountSubmissionViewFromServer(currentSubmissionId));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const exportScheduleOfLocations_server = (transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.scheduleOfLocationsExport,
            {
                transactionId,
                showProgress: {
                    isBlocking: true,
                    message: `Exporting Schedule Of Buildings Spreadsheet: ${transactionId}`,
                },
            },
            dispatch
        ).then(({ data }) => {
            window.location.href = data.url;
        });
    };
};

export const changeTransactionStatus = (currentSubmissionId: number, transactionId: number, newStatus: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.changeStatus,
            {
                transactionId,
                params: {
                    change_to: newStatus,
                },
                showProgress: {
                    isBlocking: true,
                    message: `Changing status of Transaction: ${transactionId}`,
                },
            },
            dispatch
        ).then(
            data => {
                dispatch(getAccountSubmissionViewFromServer(currentSubmissionId));
                if (data.data.warnings.length > 0) {
                    dispatch(
                        openCenteredModal(
                            {
                                modalData: {
                                    warnings: data.data.warnings,
                                },
                            },
                            VIRA_MODAL_TYPES.WARNINGS
                        )
                    );
                }
            },
            _xhr => {
                // dispatch(setErrors(xhr.errors));
            }
        );
    };
};

export const bookTransaction_server = (transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.bookTransaction,
            {
                transactionId,
                showProgress: {
                    isBlocking: true,
                    message: `Booking Transaction: ${transactionId}`,
                },
            },
            dispatch
        ).then(() => {
            dispatch(getAccountSubmissionViewFromServer(transactionId));
        });
    };
};

export const unbookTransaction_server = (transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.unbookTransaction,
            {
                transactionId,
                showProgress: {
                    isBlocking: true,
                    message: `Unbooking Transaction: ${transactionId}`,
                },
            },
            dispatch
        ).then(() => {
            dispatch(getAccountSubmissionViewFromServer(transactionId));
        });
    };
};

export const refreshTransaction = (transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(API.endpoints.endorsement.get, { transactionId }, dispatch).then(({ data }) => {
            dispatch(updateTransaction(transactionId, data.account_transaction));
        });
    };
};

export const repriceTransaction = (transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.repriceTransaction,
            {
                transactionId,
                params: {},
                showProgress: true,
            },
            dispatch
        );
    };
};

export const createTransaction = (accountId: number, kwargs: any) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.create,
            {
                accountId: accountId,
                params: kwargs,
                showProgress: 'modal-in-progress',
                hideDialogForErrors: true,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(updateTaskProgress({ data, taskPath: getTaskPath(data.task_type, data.key_id) }));
                dispatch(closeCenteredModal());
            },
            errors => {
                dispatch(
                    updateUi({
                        data: {
                            endorsementObj: { _errors: errors.data },
                        },
                    })
                );
                throw errors;
            }
        );
    };
};

export const initiateEndorsement = (
    accountId: number,
    currentSubmissionId: number,
    onSetCurrentSubmission: (aId?: number, sId?: string | number, replace?: boolean, subRoute?: string | null, params?: Record<string, any>) => void
) => {
    return (dispatch: AppDispatch): void => {
        dispatch(
            openCenteredModal(
                {
                    accountId,
                    currentSubmissionId,
                    onSetCurrentSubmission,
                    confirmLabelTitle: 'Create',
                    onClose: () => {
                        dispatch(
                            updateUi({
                                data: {
                                    endorsementObj: {},
                                },
                                shouldReplace: true,
                            })
                        );
                    },
                },
                VIRA_MODAL_TYPES.INITIATE_ENDORSEMENT
            )
        );
    };
};

export const priceEndorsement = (transaction: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        return dispatch(
            openCenteredModal(
                {
                    modalData: {
                        transaction: transaction,
                        confirmLabelTitle: 'Create',
                    },
                },
                VIRA_MODAL_TYPES.ENDORSEMENT_PRICING
            )
        );
    };
};

export const updateTransactionData = (transactionId: number, data: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        // Update here so UI change sticks
        let updatePath = `rowData.${transactionId}`;

        dispatch(updateDataByPath(LITE_TABLES.TRANSACTIONS, updatePath, data));
        return API.doRequest(
            API.endpoints.endorsement.update,
            {
                transactionId,
                params: data,
                showProgress: true,
            },
            dispatch
        ).then(() => {
            dispatch(validateSubmission_server(transactionId, null, true));
        });
    };
};

export const generateEndorsementPackage_server = (transactionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(API.endpoints.report.generateEndorsementPackage, { params: { transaction_id: transactionId } }, dispatch);
    };
};

export const openUpdateCancellationEffectiveDateModal = transactionId => {
    return (dispatch: AppDispatch) => {
        return dispatch(
            openCenteredModal(
                {
                    modalData: {
                        transaction: transactionId,
                    },
                },
                VIRA_MODAL_TYPES.UPDATE_CANCELLATION_EFFECTIVE_DATE
            )
        );
    };
};

export const updateCancellationEffectiveDate = (transactionId: number, effective_date) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.endorsement.updateCancellationEffectiveDate,
            {
                transactionId,
                params: { effective_date },
            },
            dispatch
        );
    };
};
