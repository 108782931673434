import u from 'updeep';
import _ from 'lodash';
import * as types from '../constants/ActionTypes';

const initialState = {
    carrier_pools: {},
    carrier_pools_by_transaction: {},
};

// TODO: Replace with pure RTK Query calls
function carrierReducer(state: any = initialState, action: any) {
    switch (action.type) {
        case types.SET_CURRENT_CARRIERS: {
            let new_carrier_pools = action.carriers.carrier_pools;
            if (action.transactionId) {
                return Object.assign({}, state, u({ carrier_pools_by_transaction: { [action.transactionId]: new_carrier_pools } }, state));
            }
            return Object.assign({}, state, u({ carrier_pools: { [action.quoteId]: new_carrier_pools } }, state));
        }

        case types.UPDATE_CARRIER: {
            // need to update the the carrier participation percent inside of an individual carrier pool
            let pool = _.find(state.carrier_pools[action.quoteId], { pool_id: action.carrier.pool_id });
            let carriers = pool.carriers.map(carrier => {
                return action.carrier.carrier_id === carrier.carrier_id
                    ? u(
                          {
                              override_participation_pct: action.carrier.override_participation_pct,
                          },
                          carrier
                      )
                    : carrier;
            });

            return Object.assign({}, state, u({ carrier_pools: { [action.quoteId]: { carriers: carriers } } }, state));
        }

        case types.RESET_CARRIERS: {
            let reset = carrier => {
                return u({ carrier: { override_participation_pct: null } }, carrier);
            };
            return Object.assign({}, state, u({ carrier_pools: { [action.quoteId]: u.map(reset) } }, state));
        }

        default:
            return state;
    }
}

export default carrierReducer;
