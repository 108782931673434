import { useContext } from 'react';
import classNames from 'classnames';

import LeftPanelOption from './LeftPanelOption';

import { PANELS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { Auth0LogoutButton } from '@archinsurance-viki/property-jslib/src/components/buttons/LogoutWithAuth0';

import { useAppContext } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';
import { useGetGlobalStatusQuery } from '../../services/apiSlice';
import GlobalStatusPanel from '@archinsurance-viki/property-jslib/src/components/panels/GlobalStatusPanel';
import { RAE_SITE_ID } from '../../constants/SiteConstants';
import { Types } from '../../ts-types/viki-types';

export default function LeftPanel() {
    const { data: globalStatusData = {}, isLoading } = useGetGlobalStatusQuery({});
    const sharedContext = useContext(AppContext);
    const appContext = useAppContext();
    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const tenantFlags = useAppSelector(state => state.global.ENV.TENANT_SETTINGS.features) as Types.TenantFlags;
    const me = useAppSelector(state => state.global.me);
    const leftPanelState = useAppSelector(state => state.global.leftPanelState);
    const leftPanelOpened = useAppSelector(state => state.uiState.openPanels[PANELS.LEFT]);
    const ENV = useAppSelector(state => state.global.ENV);

    const appName = ENV.SITE.id === RAE_SITE_ID ? 'RAE' : 'VIKI';

    const panelOptions = leftPanelState
        .filter(x => {
            if (x.visible === undefined) {
                return true;
            }
            return typeof x.visible === 'function' ? x.visible({ featureFlags, tenantFlags, appContext, me }) : x.visible;
        })
        .map(navItem => <LeftPanelOption key={navItem.url} navItem={navItem} level={0} />);

    const handleSwitchApp = () => {
        const prevUrl = new URL(window.location.href);
        // Remove submission id if exists
        if (prevUrl.searchParams.has('selected')) {
            prevUrl.searchParams.delete('selected');
        }
        const url = prevUrl.toString();

        if (appName === 'VIKI') {
            window.location.assign(url.replace('viki', 'rae'));
        } else {
            window.location.assign(url.replace('rae', 'viki'));
        }
    };

    return (
        <div className={classNames('main-left-panel main-panel', { closed: !leftPanelOpened })}>
            <div className="main-left-content">
                <div className="left-top-content">{panelOptions}</div>
                <If condition={featureFlags?.global_status}>
                    <GlobalStatusPanel isLoading={isLoading} status={globalStatusData?.status} description={globalStatusData?.description} />
                </If>
                <div className="left-bottom-content">
                    <div className="username">{me.username}</div>
                    <Auth0LogoutButton onLogout={sharedContext.onLogout} />
                    <div className="switch-off">
                        <button className="blue" onClick={handleSwitchApp} title="Switch">
                            Switch to {appName === 'VIKI' ? 'RAE' : 'VIKI'}
                        </button>
                        <div className="icon">
                            <i className="material-icons md-18">swap_calls</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
