export const BACKGROUND_TASK_TYPES = {
    QUOTE_LETTER: 'QUOTE_LETTER',
    QUOTE_PREVIEW: 'QUOTE_PREVIEW',
    BINDER_AGREEMENT: 'BINDER_AGREEMENT',
    BINDER_PREVIEW: 'BINDER_PREVIEW',
    ENDORSEMENT_PACKAGE: 'ENDORSEMENT_PACKAGE',
    DATA_ENTRY_IMPORT: 'DATA_ENTRY_IMPORT',
    SUBMIT_TO_TOUCHSTONE: 'SUBMIT_TO_TOUCHSTONE',
    RMS: 'RMS',
    RENEW_SUBMISSION: 'RENEW_SUBMISSION',
    CLONE_SUBMISSION: 'CLONE_SUBMISSION',
    CREATE_ENDORSEMENT: 'CREATE_ENDORSEMENT',
    FETCH_HAZARDHUB: 'FETCH_HAZARDHUB',
    BILLING_STATEMENT: 'BILLING_STATEMENT',
    CREATE_ACCOUNT_SUMMARY: 'CREATE_ACCOUNT_SUMMARY',
    PARSE_SOV: 'PARSE_SOV',
    FETCH_BUILDFAX: 'FETCH_BUILDFAX',
    FETCH_ALL_BUILDFAX: 'FETCH_ALL_BUILDFAX',
    FETCH_CAPE_ANALYTICS: 'FETCH_CAPE_ANALYTICS',
    FETCH_ALL_CAPE_ANALYTICS: 'FETCH_ALL_CAPE_ANALYTICS',
    POLICY_ISSUANCE: 'POLICY_ISSUANCE',
    POLICY_PREVIEW: 'POLICY_PREVIEW',
    MISSING_FORMS: 'MISSING_FORMS',
    CALCULATE_ALL_AIR_PMLS: 'CALCULATE_ALL_AIR_PMLS',
    LOSS_RUN: 'LOSS_RUN',
    EXPORT_CAT_DATA: 'EXPORT_CAT_DATA',
    FETCH_INTERMAP: 'FETCH_INTERMAP',
    CHANGE_CANCELLATION_EFFECTIVE_DATE: 'CHANGE_CANCELLATION_EFFECTIVE_DATE',
    SEARCH_SUBMISSION_POLICYWRITER: 'SEARCH_SUBMISSION_POLICYWRITER',
    UPLOAD_TO_POLICYWRITER: 'UPLOAD_TO_POLICYWRITER',
    EXPORT_SA_PWCAT_DATA: 'EXPORT_SA_PWCAT_DATA',
    FETCH_PING_DATA: 'FETCH_PING_DATA',
};
