import { isObject } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import _ from 'lodash';
import { CarrierPool } from '../ts-types/ApiTypes';
import { ValidationError } from '../ts-types/ApiTypes';
import { Carrier } from '../ts-types/DataTypes';

export const buildIncludesExcludesParams = ({
    params = {},
    includes = [],
    excludes = [],
    filter = '',
}: {
    params?: Record<string, string>;
    includes?: string[];
    excludes?: string[];
    filter?: string;
}) => {
    const builtParams = new URLSearchParams(params);
    includes.forEach(include => builtParams.append('include[]', include));
    excludes.forEach(exclude => builtParams.append('exclude[]', exclude));
    if (filter) {
        builtParams.append('filter', `{${filter}}`);
    }
    return builtParams;
};

export type VentusCarriersOverrideType = Pick<Carrier, 'override_participation_pct' | 'carrier_id' | 'pool_id'>;
export type VentusCarriersOverridesType = Record<string, VentusCarriersOverrideType>;
export type UpdateVentusCarriersOverridesType = VentusCarriersOverrideType[];

const createEmptyCarrierPools = (prevPools: CarrierPool[]) => {
    return prevPools.map(pool => {
        return { ...pool, carriers: createEmptyCarriers(pool.carriers) };
    });
};

const createEmptyCarriers = (prevCarriers: Carrier[]) => {
    return prevCarriers.map(carrier => {
        return { ...carrier, override_participation_pct: null };
    });
};

export const updateCarrierOverridesObject = (
    prevParticipations: CarrierPool[],
    updateData: Pick<Carrier, 'pool_id' | 'carrier_id' | 'override_participation_pct'>[]
) => {
    if (updateData.length === 0) {
        return createEmptyCarrierPools(prevParticipations);
    }

    const updatedPoolIds = _.uniq(updateData.map(({ pool_id }) => pool_id));
    const mergedPools = prevParticipations.map(pool => {
        if (!updatedPoolIds.includes(pool.pool_id)) {
            return pool;
        }
        const prevCarrierOverrides = _.keyBy(prevParticipations.find(({ pool_id }) => pool_id === pool.pool_id).carriers, 'carrier_id');
        const carrierOverrides: Record<number, (typeof updateData)[number]> = {};
        updateData
            .filter(({ pool_id }) => pool_id === pool.pool_id)
            .forEach(({ carrier_id, override_participation_pct, pool_id }) => {
                const newPct = override_participation_pct !== null ? (+override_participation_pct).toFixed(1) : '0.0';
                carrierOverrides[carrier_id] = {
                    ...prevCarrierOverrides[carrier_id],
                    carrier_id,
                    pool_id,
                    override_participation_pct: newPct,
                };
            });

        const otherOverrides = _.omit(prevCarrierOverrides, Object.keys(carrierOverrides));

        const areAllOthersEmpty =
            Object.keys(otherOverrides).length > 0 &&
            Object.values(otherOverrides).every(({ override_participation_pct }) => override_participation_pct === null);
        const areAllOthersZero =
            !areAllOthersEmpty && Object.values(otherOverrides).every(({ override_participation_pct }) => override_participation_pct === '0.0');

        // Edge cases
        // 1. myOverrides are going from non-null to null and otherOverrides are '0.0': Everything should be null
        // 2. otherOverrides are set to null and one of myOverrides is getting set to non-null: all other overrides should be "0.0"
        let mergedCarrierOverides = {};
        if (areAllOthersZero && updateData.every(({ override_participation_pct }) => override_participation_pct === null)) {
            mergedCarrierOverides = createEmptyCarriers(pool.carriers);
        } else if (areAllOthersEmpty) {
            const zerodOverrides = _.mapValues(otherOverrides, override => ({ ...override, override_participation_pct: '0.0' }));
            mergedCarrierOverides = {
                ...zerodOverrides,
                ...carrierOverrides,
            };
        } else {
            mergedCarrierOverides = {
                ...otherOverrides,
                ...carrierOverrides,
            };
        }
        return { ...pool, carriers: Object.values<Carrier>(mergedCarrierOverides) };
    });
    console.log({ mergedPools });
    return mergedPools;
};

export const isValidationError = (e: unknown): e is ValidationError => {
    if (!isObject(e) || !('error_code' in e) || !('error_detail' in e) || !('error_detail_list' in e)) {
        return false;
    }
    return isObject(e.error_detail) && !!e.error_detail?.validation_type;
};

export const wasNotAbleToPrice = (e: unknown) => {
    return isValidationError(e) && e.error_detail_list?.[0]?.startsWith('Unable to price');
};

export const wasInvalidPricing = (e: unknown) => {
    return isValidationError(e) && e.error_detail_list?.[0]?.startsWith('Pricing error:');
};
