import cx from 'classnames';
import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import { useState } from 'react';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { ReferralDataType } from '../../ts-types/DataTypes';
import { VIRA_MODAL_TYPES } from '../../constants/Modal';
import Button from '@archinsurance-viki/property-jslib/src/components/buttons/Button';
import { ApprovedRow, RequiresApprovalRow } from './Utils';
import { useReferralHistoryQuery } from '../../services/endpoints/coverage-option';
import { DefaultAccountLayout } from '../common/DefaultLayout';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { TRANSACTION_PULLDOWN_STATES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { useRouteMatch } from 'react-router-dom';

const ReferralHistoryContainer = () => {
    const transactionsMatch = useRouteMatch('/submissions/:accountId/transactions/:transactionId/referral_history');
    return (
        <DefaultAccountLayout hasActionPanel={false} transactionPulldown={transactionsMatch ? TRANSACTION_PULLDOWN_STATES.HIDDEN : undefined}>
            <ReferralHistoryApp />
        </DefaultAccountLayout>
    );
};

const ReferralHistoryApp = () => {
    const {
        currentQuoteId,
        currentSubmissionId,
        me: { has_cuo_approval_authority },
    } = useAppContext();
    const appContextFns = useAppContextFns();
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const { data: referralHistoryData, isLoading, isUninitialized } = useReferralHistoryQuery({ quoteId: currentQuoteId }, { skip: !currentQuoteId });

    if (isLoading || isUninitialized) {
        return (
            <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center">
                <Spinner size="xl" />
            </div>
        );
    }

    const handleCUOApproval = () => {
        const { approval_hash, is_unrequested } = referralHistoryData;
        if (is_unrequested) {
            appContextFns.onOpenCenteredModal(
                {
                    quoteId: currentQuoteId,
                    submissionId: currentSubmissionId,
                    approval_hash: approval_hash,
                    onProceed: appContextFns.onMarkQuoteApproved,
                },
                VIRA_MODAL_TYPES.SELF_APPROVAL_WARNING
            );
        } else {
            appContextFns.onMarkQuoteApproved(currentQuoteId, currentSubmissionId, approval_hash);
        }
    };

    const renderActiveRequests = () => {
        return (
            <fieldset className="panel-inline no-border">
                <div className="grid-layout referral-tables">
                    <div className="table-header">Approval Type</div>
                    <div className="table-header">Description</div>
                    <div className="table-header">Requested</div>
                    {(referralHistoryData?.requires_approval || []).map(ar => {
                        return <RequiresApprovalRow key={ar.approval_limit_name} {...ar} />;
                    })}
                </div>
            </fieldset>
        );
    };

    const canApproveRequests = has_cuo_approval_authority && referralHistoryData?.requires_approval && referralHistoryData?.requires_approval.length > 0;
    const activeRequests = (
        <>
            {renderActiveRequests()}
            {canApproveRequests && (
                <Button onClick={handleCUOApproval} className="margin-left-1 margin-top-1 blue" throttleTime={2000}>
                    Approve Requests
                </Button>
            )}
        </>
    );

    const renderHistory = () => {
        return (
            <fieldset className="panel-inline no-border">
                <div className="grid-layout referral-tables history">
                    <div className="table-header">Approval Type</div>
                    <div className="table-header">Description</div>
                    <div className="table-header">Requested</div>
                    <div className="table-header">Approved</div>
                    {(referralHistoryData?.approved || []).map((h: ReferralDataType) => {
                        return <ApprovedRow key={h.approval_limit_name} {...h} />;
                    })}
                </div>
            </fieldset>
        );
    };

    const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
        return (
            <div onClick={options.onClick} className={cx(options.className, 'table-pref', { active: options.selected })}>
                <i className="pi pi-prime p-mr-2" />
                {options.titleElement}
            </div>
        );
    };

    return (
        <div className="tw-p-2">
            <TabView activeIndex={activeTabIndex} onTabChange={e => setActiveTabIndex(e.index)}>
                <TabPanel header="Requires Approval" headerTemplate={tabTemplate}>
                    {activeRequests}
                </TabPanel>
                <TabPanel header="Approved" headerTemplate={tabTemplate}>
                    {renderHistory()}
                </TabPanel>
            </TabView>
        </div>
    );
};

export default ReferralHistoryContainer;
