import * as React from 'react';

import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';

import { SubmissionDataType } from '../../ts-types/DataTypes';

import { TableConfigType, PagedTableDataType, ColumnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../submissionlog/Helpers';
import { AppContextFnsType, AppContextType } from '../../ts-types/AppTypes';
import { getTaskSubtitles } from '../../utils/task-helpers';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

type propTypes = {
    currentSubmission: SubmissionDataType | null;
    currentQuoteId: number | null;
    tableConfig: TableConfigType;
    extraCellRenderData: Record<string, any>;
    onPersistBuildingPremiumData: (persistData: { request: any }, column: ColumnType) => void;
    onApplyColumnSetter: ({ column, value }: { column: any; value: any }) => void;
    appContextFns: AppContextFnsType;
    appContext: AppContextType;
    getQuoteConditions: (quoteId: number) => Promise<any>;
    tableData: PagedTableDataType;
};

export default class BuildingQuoteConditionsApp extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);

        let { currentQuoteId } = props;
        if (currentQuoteId) {
            props.getQuoteConditions(currentQuoteId);
        }
    }

    render() {
        let { currentSubmission, currentQuoteId, tableData, appContext, appContextFns } = this.props;

        if (!currentSubmission || !currentQuoteId || !tableData) {
            return null;
        }

        return (
            <PagedTableContainer
                hasActionPanel={false}
                tableConfig={this.props.tableConfig}
                extraCellRenderData={this.props.extraCellRenderData}
                onPersistTableRows={this.props.onPersistBuildingPremiumData}
                onApplyColumnSetter={this.props.onApplyColumnSetter}
                getTaskSubtitles={getTaskSubtitles}
                panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.BUILDING_QUOTE_CONDITIONS)}
                tasksPanel={<AccountTasksPanel />}
            />
        );
    }
}
