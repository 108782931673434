import * as React from 'react';

import { SubmissionDataType } from '../../ts-types/DataTypes';
import { CustomQuoteConditionsRow, QuoteConditionsRow } from './rows/QuotePipelineRows';
import { INPUT_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { submissionIsEditable } from '../submissionlog/Helpers';
import { VIRA_MODAL_TYPES } from '../../constants/Modal';
import { OnOpenModalType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

export type quoteConditionsAppPropTypes = {
    currentSubmission: SubmissionDataType | null;
    currentQuote: Record<string, any> | null;
    quoteConditionsData: Record<string, any>;
    buildingQuoteConditionAggregates: Record<string, any>;
    buildingCount: number;
    onUpdateQuoteConditions: (quote: any, data: any) => void;
    onUpdateCustomQuoteConditions: (quoteId: number, data: Record<string, any>) => Promise<any>;
    onOpenCenteredModal: OnOpenModalType;
};

export type rowComponentPropTypes = {
    inputType?: string;
    buildingCount: number;
    checkboxIndex?: number;
    aggregateField: string;
    disabled: boolean | any;
    checkboxGroupIndex?: number;
    object: Record<string, any>;
    inputData?: Record<string, any>;
    currentQuote: Record<string, any> | null;
    onClick: (quote: any, data: any) => void;
    customQuoteConditionHandler: (quoteId: number, data: Record<string, any>) => Promise<any>;
};

export class RowComponent extends React.Component<rowComponentPropTypes> {
    render(): any {
        const { group_id } = this.props.inputData;
        const isCustomQC = group_id === 'CUSTOM';
        return isCustomQC ? <CustomQuoteConditionsRow {...this.props} /> : <QuoteConditionsRow {...this.props} />;
    }
}

export default class QuoteConditionsApp extends React.Component<quoteConditionsAppPropTypes> {
    renderCheckboxList(checkboxList: Record<string, any>[] = [], checkboxGroupIndex?: number): React.ReactNode[] {
        let { currentQuote, currentSubmission } = this.props;
        let rows = [];
        let disabled = !submissionIsEditable(currentSubmission);
        for (let i = 0; i < checkboxList.length; i++) {
            let checkbox = checkboxList[i];
            rows.push(
                <RowComponent
                    key={checkbox.field}
                    inputType={INPUT_TYPES.CHECKBOX}
                    inputData={checkbox}
                    checkboxIndex={i}
                    checkboxGroupIndex={checkboxGroupIndex}
                    disabled={checkbox.disabled || disabled}
                    currentQuote={currentQuote}
                    object={this.props.buildingQuoteConditionAggregates}
                    onClick={this.props.onUpdateQuoteConditions}
                    aggregateField={`quote_condition_${checkbox.field.toLowerCase()}__sum`}
                    buildingCount={this.props.buildingCount}
                    customQuoteConditionHandler={this.props.onUpdateCustomQuoteConditions}
                />
            );
        }
        return rows;
    }

    renderCheckboxGroups(checkboxGroups: Record<string, any>[] = []): React.ReactNode[] {
        let renderedGroups = [];

        for (let i = 0; i < checkboxGroups.length; i++) {
            let checkboxGroup = checkboxGroups[i];
            renderedGroups.push(
                <div className="subsection" key={checkboxGroup.id}>
                    <div className="headers">
                        <div className="width-remaining bolder dark-text">{checkboxGroup.title}</div>
                        <If condition={i === 0}>
                            <div className="width-20 cntr">Applicable Buildings</div>
                        </If>
                    </div>
                    {this.renderCheckboxList(checkboxGroup.checkboxes, i)}
                </div>
            );
        }

        return renderedGroups;
    }

    addCustomQuoteCondition = () => {
        let { currentSubmission } = this.props;
        if (!currentSubmission || !submissionIsEditable(currentSubmission)) {
            return null;
        }

        return this.props.onOpenCenteredModal(
            {
                quoteId: this.props.currentQuote.id,
                customQuoteConditions: this.props.currentQuote.custom_quote_conditions,
            },
            VIRA_MODAL_TYPES.ADD_QUOTE_CONDTION
        );
    };

    render(): React.ReactNode {
        let { currentSubmission, currentQuote, buildingQuoteConditionAggregates } = this.props;
        if (!currentSubmission || !currentQuote || !currentQuote.id || !buildingQuoteConditionAggregates) {
            return null;
        }

        let data = this.props.quoteConditionsData;
        if (!data) {
            return null;
        }

        const headerButton = (
            <button className="small blue margin-left-1" onClick={this.addCustomQuoteCondition}>
                Add Quote Condition
            </button>
        );

        return (
            <React.Fragment key="quoteConditions">
                <div className="section-header">QUOTE CONDITIONS {headerButton}</div>
                <fieldset className="section-content">{this.renderCheckboxGroups(data.checkboxGroups)}</fieldset>
            </React.Fragment>
        );
    }
}
