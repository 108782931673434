import _ from 'lodash';
import { formatNumberIntl } from '@archinsurance-viki/property-jslib/src/utils/converters';

import {
    calculateCatPool,
    calculateRates,
    getPctOfTechnicals,
    getPolicyCoverageItem,
    isLayeredRatio,
    makeRawSideBySide,
    maxFloodLimit,
} from '../utils/overview-helpers';

import { convertDecimalToPercent, formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';

import { DEDUCTIBLE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { NO_ACCOUNT } from '../reducers/accounts';

export const TBD = 'TBD';
export const NA = 'N/A';

export const LABELS = {
    EDIT: 'Edit',
    DUPLICATE: 'Duplicate',
    DELETE: 'Delete',
};

export const URLS = {
    BASE_URL: '/submissions/new/',
    MODELING_URL: '/dashboard/',
};

export const DEFAULTS = {
    ButtonTitle: 'Button Title',
    ExpandAccordions: true,
    USE_DATA_TABLES: true,
    UNLOCK_ACCOUNTS: false,
};

export const SubmissionTabMap = {
    documents: 1,
    insured: 2,
    producer: 3,
    buildings: 4,
    coverage_options: 5,
    review: 6,
};

export const PolicyTransactionTypes = {
    NEW_BUSINESS: 'NB',
    ENDORSEMENT: 'EN',
    FLAT_CANCELLATION: 'CFL',
    RENEWAL_BUSINESS: 'RB',
    REINSTATEMENT: 'RET',
    REVERSION: 'REV',
    SHORT_RATE_CANCELLATION: 'CSR',
    NON_FLAT_RATE_CANCELLATION: 'CPR',
};

export const UserTypes = {
    UNDERWRITER: 'UNDERWRITER',
    DATA_ENTRY: 'DATA_ENTRY',
};

export const SUBMISSION_STATUS_MAP = {
    NEW_SUBMISSION: 'Clearance',
    ENTERING_DATA: 'Data Entry',
    DATA_ENTRY: 'Data Entry',
    RUSH_DATA_ENTRY: 'Data Entry as Rush',
    UW_REVIEW: 'UW Review',
    AWAITING_AGENT: 'Awaiting Agent',
    BOUND: 'Bound/Booked',
    DECLINED: 'Declined',
    NOT_ACCEPTED: 'Not Taken Up',
    QUOTES_WITHDRAWN: 'Quotes Withdrawn',
    ENDORSEMENT_REVIEW: 'Endorsement Review',
    OUTSTANDING_QUOTES: 'Outstanding Quotes',
    CLOSED: 'Closed',
} as const;

export const SUBMISSION_STATUS = {
    NEW_SUBMISSION: 'NEW_SUBMISSION',
    ENTERING_DATA: 'ENTERING_DATA',
    UW_REVIEW: 'UW_REVIEW',
    AWAITING_AGENT: 'AWAITING_AGENT',
    BOUND: 'BOUND',
    DECLINED: 'DECLINED',
    NOT_ACCEPTED: 'NOT_ACCEPTED',
    QUOTES_WITHDRAWN: 'QUOTES_WITHDRAWN',
    ENDORSEMENT_REVIEW: 'ENDORSEMENT_REVIEW',
};

export const MODAL_ERRORS = {
    quote_letter: {
        title: 'Cannot Generate Quote Letter',
        errors: null,
    },
    binder: {
        title: 'Cannot Bind Account',
        errors: null,
    },
    binder_preview: {
        title: 'Cannot Preview Binder',
        errors: null,
    },
    pricing_dashboard: {
        title: 'Cannot View Pricing',
        errors: null,
    },
};

export const CLOSED_SUBMISSION_OPTIONS = [
    ['DECLINED', 'Declined'],
    ['NOT_ACCEPTED', 'Not Taken Up'],
    ['QUOTES_WITHDRAWN', 'Quotes Withdrawn'],
];

export const STATES_LONG_FORM = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};

// TODO: put these into COMBINED_TABLE_GLOSSARY
export const SUPPLEMENTAL_DEFINITIONS = {
    name: {
        name: 'Name',
        readonly: true,
    },
    state: {
        name: 'State',
        readonly: true,
    },
    type: {
        name: 'Created',
        readonly: true,
    },
    bldgs: {
        name: 'Created',
        readonly: true,
    },
    tiv: {
        name: 'Created',
        readonly: true,
        field_format: { subtype: 'currency' },
    },
    assitant: {
        name: 'Assistant',
        readonly: true,
    },
    'assitant.phone': {
        name: 'Assistant email',
        readonly: true,
    },
    bil: {
        name: 'Bi Limit',
        readonly: true,
    },
    bip: {
        name: 'BI Percentage',
        readonly: true,
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote?.final_premium) {
                return undefined;
            }
            let final_premium = props.appContext.currentQuote.final_premium;
            let bi_limit = final_premium ? final_premium.total_bi_limit : null;
            let tiv = final_premium ? final_premium.tiv : null;

            if (!bi_limit || !tiv) {
                return null;
            }
            let pct = convertDecimalToPercent(bi_limit / tiv);

            return formatNumber(pct, undefined, '%', 0);
        },
    },
    wdr: {
        name: 'WDR Limit',
        readonly: true,
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote?.policy_coverage) {
                return undefined;
            }
            return formatNumber(props.appContext.currentQuote.policy_coverage.pee_override_wind_driven_precipitation, '$');
        },
    },
    primary_quote_catd: {
        name: 'Cat Ded (Primary Quote)',
        readonly: true,
        cellDataGetter: (_value, _props) => TBD,
    },
    catd: {
        name: 'Cat Deductible',
        readonly: true,
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote) {
                return undefined;
            }
            let { policy_coverage } = props.appContext.currentQuote;
            if (!policy_coverage) {
                return null;
            }
            let { cw_deductible_fmt, cw_deductible_dollar_amt, cw_deductible_pct_amt } = policy_coverage;

            if (cw_deductible_fmt === DEDUCTIBLE_FORMAT.PERCENT) {
                let pct = convertDecimalToPercent(cw_deductible_pct_amt);
                return formatNumber(pct, undefined, '%');
            } else if (cw_deductible_fmt === DEDUCTIBLE_FORMAT.DOLLAR) {
                return formatNumber(cw_deductible_dollar_amt, '$');
            } else {
                return 'Unknown';
            }
        },
    },
    aowd: {
        name: 'AOW Deductible',
        readonly: true,
        type: 'currency',
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote) {
                return undefined;
            }
            return props.appContext.currentQuote.policy_coverage.cw_secondary_deductible_dollar_amt;
        },
    },
    aopd: {
        name: 'AOP Deductible',
        readonly: true,
        type: 'currency',
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote?.policy_coverage) {
                return undefined;
            }
            return props.appContext.currentQuote.policy_coverage.aop_deductible;
        },
    },
    wdd: {
        name: 'Water Damage Deductible',
        readonly: true,
        type: 'currency',
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote) {
                return undefined;
            }
            return props.appContext.currentQuote.policy_coverage.water_damage_deductible;
        },
    },
    pp: {
        name: 'Real Property Value',
        readonly: true,
        cellDataGetter: (_value, _props) => TBD,
    },
    ebrk: {
        name: 'Equip Breakdown',
        readonly: true,
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote) {
                return undefined;
            }
            return getPolicyCoverageItem(props.appContext.currentQuote, 'equipment_breakdown') ? 'Yes' : 'No';
        },
    },
    max_flood: {
        name: 'Flood Limit (max zone)',
        readonly: true,
        type: 'currency',
        cellDataGetter: (value, props) => {
            return maxFloodLimit(props?.appContext?.currentQuote);
        },
    },

    max_tiv_campus: {
        name: 'Max Campus TIV',
        readonly: true,
        cellDataGetter: (_value, _props) => TBD,
    },

    limit: {
        name: 'Limit',
        readonly: true,
        showTooltip: true,
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote?.policy_coverage || !props?.appContext?.currentQuote?.final_premium) {
                return undefined;
            }

            return isLayeredRatio(props.appContext.currentQuote.policy_coverage, props.appContext.currentQuote.final_premium.tiv);
        },
    },
    ihs: {
        name: 'Home State',
        readonly: true,
        cellDataGetter: (value, props) => {
            return _.get(props.appContext, 'currentSubmission.home_state');
        },
    },
    r: {
        name: 'Account Rate (incl Terror)',
        readonly: true,
        cellDataGetter: (value, { currentQuote, currentSubmission }) => {
            if (currentSubmission?.account_rate) {
                return currentSubmission.account_rate;
            } else {
                let n1 = calculateRates(currentQuote, 'air_total_premium_original');
                let n2 = calculateRates(currentQuote, 'rms_total_premium_original');
                return `${n1} / ${n2}`;
            }
        },
    },
    potec: {
        name: '% of Tech',
        readonly: true,
        cellDataGetter: getPctOfTechnicals,
    },
    af: {
        name: 'AOP Technical',
        readonly: true,
        cellDataGetter: (value, { currentSubmission }) => {
            // SPEC VIKI-4087, AOP is Total - CAT, "Technical (AOP)"
            if (!currentSubmission) return undefined;

            let charged_terror_premium = currentSubmission.charged_terror_premium;

            let total1 = currentSubmission.air_technical_premium_before_tf - charged_terror_premium;
            let total2 = currentSubmission.rms_technical_premium_before_tf - charged_terror_premium;

            let v1 = total1 - currentSubmission.air_technical_cat_premium;
            let v2 = total2 - currentSubmission.rms_technical_cat_premium;

            if (value) {
                return makeRawSideBySide(v1, v2);
            } else {
                return formatNumber(v1, '$');
            }
        },
    },
    cf: {
        name: 'CAT Technical',
        readonly: true,
        cellDataGetter: (value, { currentSubmission }) => {
            if (!currentSubmission) return undefined;

            let v1 = currentSubmission.air_technical_cat_premium;
            let v2 = currentSubmission.rms_technical_cat_premium;

            if (value) {
                return makeRawSideBySide(v1, v2);
            } else {
                return formatNumber(v1, '$');
            }
        },
    },
    aact: {
        name: 'Technical (excl Terror)',
        readonly: true,
        cellDataGetter: (value, { currentSubmission }) => {
            if (!currentSubmission) return undefined;

            let charged_terror_premium = currentSubmission.charged_terror_premium;

            let air_technical_premium_before_ttf = currentSubmission.air_technical_premium_before_tf - charged_terror_premium;
            let rms_technical_premium_before_ttf = currentSubmission.rms_technical_premium_before_tf - charged_terror_premium;

            if (value) {
                return makeRawSideBySide(air_technical_premium_before_ttf, rms_technical_premium_before_ttf);
            } else {
                return formatNumber(air_technical_premium_before_ttf, '$');
            }
        },
    },
    chr_pr: {
        name: 'Charged (excl Terror)',
        readonly: true,
        type: 'currency',
        cellDataGetter: (value, { currentSubmission }) => {
            return currentSubmission && currentSubmission.charged_premium_before_ttf;
        },
    },
    cat_pool: {
        name: 'Cat Pool',
        readonly: true,
        cellDataGetter: (value, props) => {
            return calculateCatPool(props.currentSubmission);
        },
    },
    is_renewal: {
        name: 'New/Renewal',
        readonly: true,
        cellDataGetter: value => (value ? 'Renewal' : 'New'),
    },
    primary_quote_total_bi_limit: {
        name: 'Total BI Limit',
        readonly: true,
        type: 'currency',
        cellDataGetter: (value, props) => {
            if (!props?.appContext?.currentQuote?.final_premium) {
                return undefined;
            }
            return props.appContext.currentQuote.final_premium.total_bi_limit;
        },
    },
    'insured.business_description': {
        name: 'Business Form Desc.',
        readonly: true,
    },
    'insured.loss_history': {
        name: 'Loss Ratio',
        readonly: true,
    },
    'insured.loss_ratio_years': {
        name: 'Number of years',
        readonly: true,
    },
    // account_agent_id: {
    //     name: 'Account Broker',
    //     readonly: false,
    //     constants_choices_map: 'PRODUCING_AGENTS_BY_PRODUCER',
    //     choices_key: 'producer_id',
    // },
    // office_id: {
    //     name: 'Office',
    //     readonly: false,
    //     constants_choices: 'OFFICES',
    // },
    // 'producer.city': {
    //     name: 'Agency Office',
    //     readonly: true,
    // },
    message: {
        name: 'Message',
        readonly: true,
    },
    change_by_id: {
        name: 'Changed By',
        readonly: true,
    },
    change_by_full_name: {
        name: 'Changed By',
        readonly: true,
    },
    subject: {
        name: 'Subject',
        readonly: true,
    },
    carrier_name: {
        name: 'Carrier Name',
        readonly: true,
    },
    pool_name: {
        name: 'Pool Name',
        readonly: true,
    },
    final_net_participation_pct: {
        name: 'Carrier Participation Percent',
        readonly: true,
        cellDataGetter: (value, props) => formatNumberIntl(value / 100, { maximumFractionDigits: 1, minimumFractionDigits: 1, style: 'percent' }),
    },
    actual_allocated_premium: {
        name: 'Carrier Allocated Premium',
        type: 'currency',
        readonly: true,
    },
    edited_at: {
        name: 'Created',
        readonly: true,
        field_format: { subtype: 'date' },
    },
    is_primary: {
        name: 'Is Primary',
        readonly: true,
        cellDataGetter: (value, quote, field, parentProps) => {
            return quote.id === parentProps.currentSubmission.primary_quote_id ? 'Primary' : '';
        },
    },
    quote_premium: {
        name: 'Charged Premium',
        field_format: { subtype: 'currency' },
        readonly: true,
        cellDataGetter: (value, quote) => {
            if (!quote.final_premium) {
                return null;
            }
            let { total_premium_override, rms_total_premium, air_total_premium } = quote.final_premium;
            return total_premium_override || Math.min(rms_total_premium, air_total_premium);
        },
    },
    quote_description: {
        name: 'Quotes',
        readonly: true,
        field_name: 'description',
    },
    has_endorsement: {
        name: 'Has Endorsement',
        type: 'bool',
        readonly: true,
        cellDataGetter: (value, props) => {
            return props.currentAccount === undefined ? undefined : props.currentAccount.id !== NO_ACCOUNT;
        },
    },
    ctp: {
        cellDataGetter: (value, { currentSubmission }) => {
            return currentSubmission && currentSubmission.charged_terror_premium;
        },
    },
    pqisi: {
        cellDataGetter: (value, props) => {
            return value || value === 0 ? convertDecimalToPercent(value) : 'n/a';
        },
    },
    pqdci: {
        cellDataGetter: (value, props) => {
            return value || value === 0 ? convertDecimalToPercent(value) : 'n/a';
        },
    },
    pqiaqi: {
        cellDataGetter: (value, props) => {
            return value || value === 0 ? convertDecimalToPercent(value) : 'n/a';
        },
    },
};

export const ADDITIONAL_NAMED_INSUREDS_DEFS = {
    name: {
        name: 'Name',
    },
    street: {
        name: 'Street',
    },
    city: {
        name: 'City',
    },
    state: {
        name: 'State',
        field_name: 'state',
        choices: _.map(STATES_LONG_FORM, (s, f) => [f, s]),
    },
    zipcode: {
        name: 'Zipcode',
    },
};

export const BUSINESS_SEGMENT_TO_SUBDIVISION_MAPPING = {
    SMMS: '6312',
    VENTUS_HO: '5011',
    ES_LINES: '5010',
    RETAIL_PROPERTY: '5020',
};
