import { DRFPagedResponse } from '@archinsurance-viki/property-jslib/src/ts-types/ApiTypes';
import { AccountTransactionCarrierResult } from '../../ts-types/ApiTypes';
import api from '../apiSlice';

export const carrierApi = api.injectEndpoints({
    endpoints: builder => ({
        accountTransactionCarrierData: builder.query<DRFPagedResponse<AccountTransactionCarrierResult>['results'], { submissionId: number }>({
            query: ({ submissionId }) => ({
                url: `v1/account_transaction_carrier_data/`,
                params: {
                    account_view_id: submissionId,
                    actual_allocated_pct__gt: 0,
                    fields: ['pool_name', 'carrier_name', 'actual_allocated_premium', 'final_net_participation_pct'],
                    ordering: '-actual_allocated_premium',
                },
                method: 'GET',
            }),
            transformResponse: (result: DRFPagedResponse<AccountTransactionCarrierResult>) => [...result.results],
        }),
    }),
});

export const { useAccountTransactionCarrierDataQuery } = carrierApi;
