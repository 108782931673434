import React from 'react';
import cx from 'classnames';

import PanelView from '../../components/PanelView';
import { CenteredModal } from '@archinsurance-viki/property-jslib/src/components/modals';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';
import ActionPanel from '@archinsurance-viki/property-jslib/src/components/panels/ActionPanel';
import { PANELS, TRANSACTION_PULLDOWN_STATES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../submissionlog/Helpers';

type PropTypes = {
    children: React.ReactNode;
    hasLeftPanel?: boolean;
    transactionPulldown?: (typeof TRANSACTION_PULLDOWN_STATES)[keyof typeof TRANSACTION_PULLDOWN_STATES];
    noCurrentSubmission?: boolean;
    topPanelHeader?: React.ReactNode;
    hasActionPanel?: boolean;
};

export default function AccountsApp({ children, hasLeftPanel, transactionPulldown, noCurrentSubmission, topPanelHeader, hasActionPanel }: PropTypes) {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const ENV = useAppSelector(state => state.global.ENV);
    const centeredModalData = useAppSelector(state => state.global.centeredModalData);
    const centeredModalType = useAppSelector(state => state.global.centeredModalType);

    const uiState = useAppSelector(state => state.uiState);
    const slidePanelOpen = uiState.openPanels[PANELS.RIGHT];
    const panelActionsProps = {
        appContext,
        appContextFns,
    };

    return (
        <React.Fragment>
            <CenteredModal
                modalProps={{ appContextFns, appContext }}
                modalData={centeredModalData}
                modalType={centeredModalType}
                onClose={appContextFns.onCloseCenteredModal}
                uiState={appContext.uiState}
                ENV={ENV}
            />
            <PanelView
                topPanelHeader={topPanelHeader}
                noCurrentSubmission={noCurrentSubmission}
                transactionPulldown={transactionPulldown}
                hasLeftPanel={hasLeftPanel}
            >
                {children}
                {hasActionPanel && (
                    <div className={cx('main-right-panel main-panel', { closed: !slidePanelOpen })}>
                        <ActionPanel
                            uiState={uiState}
                            panelActions={calculateRowActions(panelActionsProps, ACTION_PANEL_LOCATIONS.COVERAGE_OPTIONS_LIST)}
                            openPanels={uiState.openPanels}
                        />
                    </div>
                )}
            </PanelView>
        </React.Fragment>
    );
}
