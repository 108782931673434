import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { PolicyTermsFormValues, PolicyTermsFormField } from '../utils/form';

export const Margin = () => (
    <div className="info-rows">
        <PolicyTermsFormField>
            <FormInput<PolicyTermsFormValues> name="margin_percentage" percentAfter maskOptions={MASKS['PERCENTAGE_POSITIVE']} />
        </PolicyTermsFormField>
    </div>
);
