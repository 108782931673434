import { useState, ReactNode, useEffect } from 'react';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultFooter, DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';
import { Badge } from '@archinsurance-viki/property-jslib/src/components/widgets/Badge';
import { ComboBoxInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { Types } from '../../../../ts-types/viki-types';
import DocumentSelectionApp from './DocumentSelectionApp';

const ATTACHMENTS_SIZE_LIMIT = 10 * 1024 * 1024;

const getFileSizeMB = (size: number) => (size / 1024 / 1024).toFixed(2);

const DocumentBadge = ({ doc, title, onRemove }: { doc: DocumentDataType; title: string; onRemove: () => void }) => {
    const tooltipContent = () => (
        <div className="flex column send-documents-attachments-tooltip">
            <span>{doc.original_filename}</span>
            <span>
                <strong>Document Type: </strong>
                {doc?.document_type_display || 'N/A'}
            </span>
            <span>
                <strong>Date created: </strong>
                {new Date(doc.created).toLocaleDateString()}
            </span>
            <span>
                <strong>File size: </strong>
                {`${getFileSizeMB(doc.file_length)} MB`}
            </span>
        </div>
    );
    return (
        <Tooltip wrapChild={false} content={tooltipContent}>
            <Badge title={title} onRemove={onRemove} />
        </Tooltip>
    );
};

type InputProps = {
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
};

const Input = ({ value, onChange, disabled }: InputProps) => {
    return (
        <div className="modal-input-container">
            <input className="modal-input" value={value} onChange={e => onChange(e.target.value)} disabled={disabled} />
        </div>
    );
};

type SelectProps = {
    choices: { value: string; display: string }[];
    disabled: boolean;
    onSelect: (newValue: string) => void;
    selectedValue: string;
};
const Select = ({ choices, disabled, onSelect, selectedValue }: SelectProps) => {
    return (
        <div className="width-50">
            <ComboBoxInput
                disabled={disabled}
                name=""
                label=""
                object={{}}
                defaultValue={selectedValue}
                onChange={(_, selectedValue) => onSelect(selectedValue)}
                selectProps={{ choices, noSort: true }}
            />
        </div>
    );
};

type ModalActionProps = {
    callbackFn: () => void;
    title: string;
    isHidden?: boolean;
};

const ModalAction = ({ callbackFn, title, isHidden }: ModalActionProps) => (
    <>
        {!isHidden && (
            <button type="reset" className="text-button blue modal-section-action" onClick={callbackFn}>
                {title}
            </button>
        )}
    </>
);

type ModalSectionProps = {
    children: ReactNode;
    title: string;
    action?: ReactNode;
};

const ModalSection = ({ children, title, action }: ModalSectionProps) => (
    <div className="modal-section">
        <div className="flex modal-section-header">
            <h3>{title}</h3>
            {action}
        </div>
        {children}
    </div>
);

type footerProps = {
    onClose: (close: boolean) => void;
    isDestructive: boolean;
    modalData: any;
};

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    modalData: Record<string, any>;
} & footerProps;

const SendDocumentsBody = ({ modalData, ...props }: bodyPropTypes) => {
    const { defaultSelectedDocs, documents, emailAddresses, onOk, sendTo: defaultSendTo, subject: defaultSubject, ...footerData } = modalData;
    const [sendTo, setSendTo] = useState(defaultSendTo as string);
    const [selectedDocuments, setSelectedDocuments] = useState(defaultSelectedDocs);
    const [attachmentsTotalSize, setAttachmentsTotalSize] = useState(0);
    const [subject, setSubject] = useState(defaultSubject);

    useEffect(() => {
        setAttachmentsTotalSize(selectedDocuments.reduce((sum: number, { file_length }) => sum + file_length, 0));
    }, [selectedDocuments]);

    const handleConfirm = () => {
        const selectedIds = selectedDocuments.map((doc: DocumentDataType) => doc.id);
        onOk(selectedIds, subject, sendTo);
    };

    const onAddSelection = (doc: DocumentDataType) => {
        setSelectedDocuments((prev: DocumentDataType[]) => {
            return [...prev, doc];
        });
    };

    const onRemoveSelection = (doc: DocumentDataType) => {
        setSelectedDocuments((prev: DocumentDataType[]) => {
            return prev.filter(({ id }) => id !== doc.id);
        });
    };

    const resetSendTo = <ModalAction title="Reset" isHidden={sendTo === defaultSendTo} callbackFn={() => setSendTo(defaultSendTo || '')} />;
    const resetSubject = <ModalAction title="Reset" isHidden={subject === defaultSubject} callbackFn={() => setSubject(defaultSubject || '')} />;
    const clearAttachments = <ModalAction title="Clear" isHidden={selectedDocuments.length === 0} callbackFn={() => setSelectedDocuments([])} />;

    return (
        <div className="send-documents-modal">
            <ModalSection title="Send To" action={resetSendTo}>
                <Select
                    disabled={emailAddresses.length <= 1}
                    choices={emailAddresses.map((email: string) => ({ value: email, display: email }))}
                    onSelect={(selectedValue: string) => setSendTo(selectedValue)}
                    selectedValue={sendTo}
                />
            </ModalSection>
            <ModalSection title="Subject" action={resetSubject}>
                <Input value={subject} onChange={value => setSubject(value)} />
            </ModalSection>
            <ModalSection title="Attachments" action={clearAttachments}>
                <div className="attachments-container">
                    <div className="flex wrap attachments-list">
                        {selectedDocuments.map((doc: DocumentDataType) => (
                            <DocumentBadge key={doc.id} title={doc.original_filename} doc={doc} onRemove={() => onRemoveSelection(doc)} />
                        ))}
                    </div>
                    {attachmentsTotalSize >= ATTACHMENTS_SIZE_LIMIT && (
                        <div className="error-message attachments-error">
                            {`Error: Email attachments cannot exceed ${getFileSizeMB(ATTACHMENTS_SIZE_LIMIT)} MB in total size.` +
                                `Current size is ${getFileSizeMB(attachmentsTotalSize)} MB`}
                        </div>
                    )}
                </div>
            </ModalSection>
            <ModalSection title="Add Attachments">
                <DocumentSelectionApp
                    documents={documents}
                    selectedDocs={selectedDocuments}
                    onAddSelection={onAddSelection}
                    onRemoveSelection={onRemoveSelection}
                />
            </ModalSection>
            <DefaultFooter
                modalData={{ onOk: handleConfirm, ...footerData }}
                disableConfirm={selectedDocuments.length === 0 || attachmentsTotalSize >= ATTACHMENTS_SIZE_LIMIT}
                {...props}
            />
        </div>
    );
};

let modalTypeObj = {
    Body: SendDocumentsBody,
    Header: DefaultHeaderStatic('Select Documents for External Communication'),
};
registerType(VIRA_MODAL_TYPES.SEND_DOCUMENTS, modalTypeObj);
export default modalTypeObj;
