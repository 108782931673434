import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import API from '@archinsurance-viki/property-jslib/src/utils/API';
import BIGlossary, { GlossaryDataType } from './BIGlossary';
import PanelView from '../PanelView';
import { updateNav } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { useAppDispatch } from '../../hooks/redux';

type propTypes = {
    hasLeftPanel?: boolean;
};

const mergeSections = (g1: GlossaryDataType, g2: GlossaryDataType): GlossaryDataType => {
    // Blend multiple glossary api return objects by keying on section_name and type.
    if (!g1) return g2;
    if (!g2) return g1;

    const ret = [...g1];

    g2.forEach(s => {
        const matchedSection = ret.find(el => el.section_name === s.section_name && el.type === s.type);
        if (matchedSection) {
            matchedSection.tables = [...matchedSection.tables, ...s.tables];
        } else ret.push(s);
    });

    return ret;
};

export default function BIGlossaryContainer(props: propTypes) {
    // TODO: we probably want to load these with RTK query instead of doing it right here in the component
    const [VMAC, setVMAC] = useState(null);
    const [VIKI, setVIKI] = useState(null);
    const location = useLocation();
    const dispatch = useAppDispatch();

    const onUpdateNav = update => dispatch(updateNav('Glossary', update));

    useEffect(() => {
        const loadVikiGlossary = async () => {
            try {
                setVIKI(await API.doRequest(API.endpoints.viki.getBIGlossary, {}, dispatch));
            } catch (err) {
                console.log('Failed to reach VIKI.', err);
            }
        };
        const loadVmacGlossary = async () => {
            if (API.endpoints.vmac.connectionIsActive()) {
                try {
                    setVMAC(
                        await API.doRequest(
                            API.endpoints.vmac.getBIGlossary,
                            {
                                skipAuthenticationErrors: true,
                                hideDialogForErrors: true,
                            },
                            dispatch
                        )
                    );
                } catch (err) {
                    console.log('Failed to reach VMAC.', err);
                }
            }
        };

        loadVikiGlossary();
        loadVmacGlossary();
    }, [dispatch]);

    const glossaryData = useMemo(() => mergeSections(VIKI, VMAC), [VIKI, VMAC]);

    return (
        <PanelView topPanelHeader="BI Glossary" hasLeftPanel={props.hasLeftPanel}>
            <BIGlossary glossaryData={glossaryData} location={location} updateNav={onUpdateNav} />
        </PanelView>
    );
}
