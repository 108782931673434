import * as React from 'react';
import _ from 'lodash';

import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';

import { SubmissionDataType } from '../../ts-types/DataTypes';

import { TableConfigType, PagedTableDataType, LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { AppContextType } from '../../ts-types/AppTypes';
import { captureMessageThen } from '@archinsurance-viki/property-jslib/src/utils//ui-helpers';
import BlanketLimitsBottomPanelContainer from '../common/panels/containers/BlanketLimitsBottomPanelContainer';
import { submissionIsEditable, transactionIsPremiumBearing } from '../submissionlog/Helpers';
import { getValidationsPanel } from '../../utils/render-helpers/quotes/quote-validations-helper';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

type propTypes = {
    currentTransaction: Record<string, any> | null;
    currentSubmission: SubmissionDataType | null;
    currentQuoteId: number | null;
    oldWebsite: string;
    tableData: PagedTableDataType;
    tableConfig: TableConfigType;
    extraCellRenderData: Record<string, any>;
    appContext: AppContextType;
    blanketLimitGroups: LiteTableDataType;
    createBlanketLimitGroup: (groupName: any) => any;
    onPersistedData: ({ request }: { request: any }) => any;
};

export default class BlanketLimitsApp extends React.Component<propTypes> {
    render() {
        let { blanketLimitGroups, currentTransaction, currentSubmission, currentQuoteId, tableData, oldWebsite } = this.props;
        if (!currentQuoteId || !currentSubmission) {
            return null;
        }
        let extraSections = [];
        let actions = [];
        let activeBlanketGroups = blanketLimitGroups.rows.filter(rowId => {
            return blanketLimitGroups.rowData[rowId].number_of_buildings > 0;
        });
        let disabledReasons = null;
        if (activeBlanketGroups.length >= 5) {
            disabledReasons = 'Limited to 5 blanket groups per coverage option';
        } else if (_.isEmpty(tableData.checkedRows)) {
            disabledReasons = 'Select row(s) to enable button';
        }

        actions.push({
            label: 'Create Blanket Limit Group',
            action: captureMessageThen({
                title: `Creating blanket limit group with ${Object.keys(tableData.checkedRows).length} buildings`,
                prompt: 'Group Name',
                onSave: result => this.props.createBlanketLimitGroup(result.message),
                saveButtonCaption: 'Create',
                required: true,
            }),
            disabledReasons: disabledReasons,
            disabled: !!disabledReasons,
            id: 'create',
        });

        extraSections.push({
            title: 'Blanket Limit Actions',
            actions: actions,
        });

        let isPremiumBearing = transactionIsPremiumBearing(currentTransaction);
        let isSubmissionEditable = submissionIsEditable(currentSubmission);
        let isReadonly = !isSubmissionEditable || !isPremiumBearing;
        let editable = !isReadonly;
        return (
            <PagedTableContainer
                {...this.props}
                hasSearchPanel={true}
                editingDisabled={!editable}
                panelActions={{ extraSections }}
                bottomPanel={{
                    ContentElement: BlanketLimitsBottomPanelContainer,
                    label: 'Blanket Group Limits',
                    tableData: blanketLimitGroups,
                    editingDisabled: !editable,
                    currentQuoteId,
                }}
                bottomPanels={[getValidationsPanel(currentSubmission, oldWebsite)]}
                tasksPanel={<AccountTasksPanel />}
            />
        );
    }
}
