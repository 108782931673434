import _ from 'lodash';
import { Badge } from '@archinsurance-viki/property-jslib/src/components/widgets/Badge';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';
import { formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { isCancel } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { useQuoteId, useQuoteFinalPremium, useQuotePolicyCoverage } from '../../../hooks/quotes';
import { useSetFinalPremiumOverridesMutation } from '../../../services/apiSlice';
import { wasInvalidPricing, wasNotAbleToPrice } from '../../../services/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { clearChangedPremium, setChangedPremium } from '../../../reducers/pricing';

const MAP_PRETTY_NAME = {
    equipment_breakdown: 'Equipment Breakdown',
    terror: 'Terror',
};

export const ChargedPremiumDifferenceBadge = ({ premiumField }: { premiumField: 'terror' | 'equipment_breakdown' }) => {
    const dispatch = useAppDispatch();
    const quoteId = useQuoteId();
    const { finalPremium } = useQuoteFinalPremium();
    const { policyCoverage } = useQuotePolicyCoverage();
    const [setOverrides] = useSetFinalPremiumOverridesMutation({ fixedCacheKey: `${quoteId}` });

    const dismissButton = () => {
        dispatch(clearChangedPremium([premiumField]));
    };

    const premiumDifference = useAppSelector(state => state.pricing.changedPremium[premiumField]);

    if (!premiumDifference) {
        return null;
    }

    const newChargedPremium = +policyCoverage.charged_premium_before_tf + premiumDifference;
    if (newChargedPremium <= 0) {
        return null;
    }

    const formattedPremiumDifference = formatNumber(premiumDifference < 0 ? -premiumDifference : premiumDifference, '$', '', 0, ',');
    const wrappedPremiumDifference = premiumDifference < 0 ? `(${formattedPremiumDifference})` : formattedPremiumDifference;
    const tooltipMessage = (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-break-normal">
            <span>{`Apply the ${MAP_PRETTY_NAME[premiumField]} premium difference of ${wrappedPremiumDifference} to the charged premium?`}</span>
            <span>{`Resulting Charged Premium: ${formatNumber(newChargedPremium, '$', '', 0, ',')}`}</span>
        </div>
    );

    return (
        <div className="tw-p-0">
            <Tooltip wrapChild={false} content={tooltipMessage}>
                <div>
                    <Badge
                        title={`${premiumDifference < 0 ? '-' : '+'}Charged Prem`}
                        className="tw-bg-blue-primary tw-m-0"
                        onClick={async () => {
                            const savedPremiumDifference = premiumDifference;
                            try {
                                const resultPromise = setOverrides({
                                    id: finalPremium.id,
                                    quoteId,
                                    data: { charged_premium_before_tf: newChargedPremium.toFixed(0) },
                                    usedChargedPremiumButton: true,
                                }).unwrap();
                                dismissButton();
                                await resultPromise;
                            } catch (e) {
                                if (isCancel(e) || wasNotAbleToPrice(e?.data) || wasInvalidPricing(e?.data)) {
                                    // if policy coverage was updated, no need to do anything else
                                    return;
                                }
                                // policy coverage was not updated and we need to reset state
                                dispatch(setChangedPremium(savedPremiumDifference));
                            }
                        }}
                        onRemove={() => dismissButton()}
                    />
                </div>
            </Tooltip>
        </div>
    );
};
