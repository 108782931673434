import { differenceInDays } from 'date-fns';
import * as React from 'react';
import Chart from 'react-apexcharts';
import { PmlApiResponseType } from '../../ts-types/DataTypes';
import { abbreviateNumber, MAX_SERIES_DISPLAY, formChartData, yearStart, yearEnd, Series, Selections } from './utils';

const MINIMUM_DAILY_VIEW = 2;
const CHART_TITLE = 'PML Analysis';
const DAY_IN_MS = 24 * 60 * 60 * 1000;

type propTypes = {
    data: PmlApiResponseType[];
    enableAnimations?: boolean;
    onSelectDate?: (date: Date) => void;
    selections: Selections;
};

type stateTypes = {
    series: Series[];
    options: any;
};

export default class MultiAxisLineGraph extends React.Component<propTypes, stateTypes> {
    yearStart = yearStart(this.props.selections.year);
    yearEnd = yearEnd(this.props.selections.year);

    constructor(props: propTypes) {
        super(props);
        const [series, yAxes] = formChartData(this.props.data, this.props.selections);
        this.state = {
            series,
            options: {
                noData: {
                    text: 'No Data Was Found. Please alter selection and try again',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '20px',
                        fontFamily: 'Helvetica,sans-serif,bold',
                    },
                },
                chart: {
                    stacked: false,
                    height: 500,
                    zoom: {
                        type: 'x',
                        enabled: true,
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            reset: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                        },
                    },
                    animations: this.props.enableAnimations ? this.getAnimations() : { enabled: false },
                    events: {
                        click: (_event, ctx, { seriesIndex, dataPointIndex }) => {
                            // Ignore the click event if the user is using the drag-selection feature
                            // For some reason, startX and startY are always set to zero while using
                            // drag-selection zoom. If you click on a single point, the startX
                            // and startY values will be set
                            const isZoomDrag = !ctx.zoomPanSelection.startX && !ctx.zoomPanSelection.startY;
                            if (!isZoomDrag && this.props.onSelectDate && seriesIndex >= 0 && dataPointIndex >= 0) {
                                this.props.onSelectDate(series[seriesIndex].data[dataPointIndex][0]);
                            }
                        },
                        beforeResetZoom: (_chartContext, opts) => {
                            this.updateView(this.props.data, this.yearStart, this.yearEnd);
                            return { opts };
                        },
                        beforeZoom: (chartContext, { xaxis }) => {
                            const minDate = xaxis.min < this.yearStart ? this.yearStart : new Date(xaxis.min);
                            const maxDate = xaxis.max > this.yearEnd ? this.yearEnd : new Date(xaxis.max);
                            const diff = differenceInDays(maxDate, minDate);
                            // do not proceed with the zoom if it would be too granular
                            if (diff <= MINIMUM_DAILY_VIEW) {
                                return {
                                    xaxis: chartContext.ctx.w.config.xaxis,
                                };
                            }
                            this.updateView(this.props.data, minDate, maxDate);
                            // apexcharts doesn't seem to properly calculate the xaxis min and max
                            // the minimum date displayed can be 0.5-1.0 days behind the start and end dates
                            return {
                                xaxis: {
                                    min: minDate === this.yearStart ? minDate.getTime() : minDate.getTime() - DAY_IN_MS / 2.0,
                                    max: maxDate === this.yearEnd ? maxDate.getTime() : maxDate.getTime() - DAY_IN_MS / 2.0,
                                },
                            };
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                },
                title: {
                    text: this.formatTitle(this.yearStart, this.yearEnd),
                    align: 'left',
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0.25,
                        stops: [0, 90, 100],
                    },
                },
                yaxis: yAxes,
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: false,
                    intersect: false,
                    y: {
                        formatter: function (val: string) {
                            return abbreviateNumber(parseFloat(val), 2);
                        },
                    },
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'right',
                    itemMargin: {
                        horizontal: 5,
                        vertical: 3,
                    },
                },
            },
        };
    }

    formatTitle(startDate: Date, endDate: Date) {
        let str = [CHART_TITLE, `(${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()})`].join(' ');
        if (this.props.data.length > MAX_SERIES_DISPLAY) {
            const warning = `Data Being Suppressed (Showing ${MAX_SERIES_DISPLAY} / ${this.props.data.length} permutations)`;
            str = `${str} -- ${warning}`;
        }
        return str;
    }

    updateView = (data: PmlApiResponseType[], startDate: Date, endDate: Date) => {
        const [series] = formChartData(data, this.props.selections);
        const { title: oldTitle, ...otherOptions } = this.state.options;
        const options = {
            title: {
                text: this.formatTitle(startDate, endDate),
                align: oldTitle.align,
            },
            ...otherOptions,
        };
        this.setState({ series, options });
    };

    getAnimations() {
        return {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: false,
                delay: 150,
            },
            dynamicAnimation: {
                enabled: false,
                speed: 350,
            },
        };
    }

    render() {
        return (
            <div className="multgrid-wrapper">
                <Chart options={this.state.options} series={this.state.series as any} type="area" height={500} />
            </div>
        );
    }
}
