import * as React from 'react';
import _ from 'lodash';
import cx from 'classnames';

import { TRANSACTION_STATUS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import PolicyFormCheckInput from './widgets/PolicyFormCheckInput';
import EndorsementFormCheckInput from './widgets/EndorsementFormCheckInput';
import { TRANSACTION_TYPES } from '../../constants/TransactionConstants';
import {
    selectPolicyFormMutationsByEndpoint,
    useClearPolicyFormsOverridesMutation,
    useGetPolicyFormsQuery,
    useUpdateEndorsementFormMutation,
    useUpdatePolicyFormMutation,
} from '../../services/endpoints/policy-forms';
import useQuoteId from '../../hooks/quotes';
import { useAppContext } from '../../hooks/context';
import { PolicyFormCheckbox, PolicyFormGroups } from '../../ts-types/ApiTypes';
import { useSubmissionIsEditable } from '../../hooks/submissions';
import Button from '@archinsurance-viki/property-jslib/src/components/buttons/Button';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { useAppSelector } from '../../hooks/redux';
import { selectPendingRequests } from '../../services/apiSlice';
import { Types } from '../../ts-types/viki-types';

const getNewCheckboxData = (field: string, value: boolean, { suggested, _actions: oldActions }) => {
    const manually_applied = value;
    const applied = value === null ? suggested : value;

    return {
        field,
        applied,
        manually_applied,
        _actions: {
            follow_suggested: {
                enabled: oldActions.follow_suggested.enabled,
                visible: manually_applied === null && suggested !== null && applied !== suggested,
            },
            clear_override: {
                enabled: oldActions.clear_override.enabled,
                visible: manually_applied !== null,
            },
            set_override: {
                enabled: oldActions.set_override.enabled,
                visible: true,
            },
        },
    };
};

type PolicyFormGroupProps = { children: React.ReactNode; title: PolicyFormGroups[keyof PolicyFormGroups]; isEndorsementPackage: boolean };
const PolicyFormGroup = ({ children, title, isEndorsementPackage }: PolicyFormGroupProps) => (
    <div className="subsection tw-py-2">
        <div className="headers">
            <div className="width-remaining bolder dark-text">{title}</div>
        </div>
        {isEndorsementPackage && (
            <div className="headers tw-flex">
                <div className="tw-basis-16 bolder dark-text">Applied</div>
                <div className="bolder dark-text">Displayed (Endorsement Package)</div>
            </div>
        )}
        {children}
    </div>
);

const PolicyFormsRow = ({ checkbox, isEndorsementPackage, groupKey }: { checkbox: PolicyFormCheckbox; isEndorsementPackage: boolean; groupKey: string }) => {
    const quoteId = useQuoteId();
    const appContext = useAppContext();
    const isEditable = useSubmissionIsEditable();
    const tenantFlags = useAppSelector(state => state.global.ENV.TENANT_SETTINGS.features) as Types.TenantFlags;

    const { currentQuote, currentTransaction } = appContext;

    const [triggerPolicyUpdate] = useUpdatePolicyFormMutation();
    const [triggerEndorsementUpdate] = useUpdateEndorsementFormMutation();

    const onChange = (field: string, value: boolean) => {
        const updateData = getNewCheckboxData(field, value, checkbox);
        triggerPolicyUpdate({ quoteId, policyCoverageId: currentQuote.policy_coverage_id, data: updateData });
    };
    const onChangeEndorsement = (field: string, value: boolean) => {
        triggerEndorsementUpdate({ quoteId, transactionId: currentTransaction.id, data: { field, applied: value } });
    };

    const firstLabel = isEndorsementPackage ? null : checkbox.field;
    const secondLabel = isEndorsementPackage ? checkbox.field : null;
    const isActive = groupKey !== 'INACTIVE_FORMS' ? true : false;
    return (
        <div
            className={cx(
                'tw-flex tw-items-center tw-p-0.5 tw-gap-2',
                'even:tw-bg-grey-extra-light odd:last:tw-border-solid odd:last:tw-border-grey-extra-light odd:last:tw-border'
            )}
        >
            <div className="tw-basis-16">
                <PolicyFormCheckInput
                    name={checkbox.field}
                    label={firstLabel}
                    labelAfter={true}
                    onChange={onChange}
                    object={checkbox}
                    _actions={checkbox._actions}
                    suggested={checkbox.suggested}
                    disabled={checkbox.disabled || !isEditable}
                />
            </div>
            {isEndorsementPackage && (
                <EndorsementFormCheckInput
                    name={checkbox.field}
                    label={secondLabel}
                    labelAfter={true}
                    object={checkbox}
                    onChange={onChangeEndorsement}
                    disabled={!checkbox.endorsement_package_enabled}
                />
            )}
            <div className="tw-flex-1 check-input-control">{checkbox.label}</div>
            <div className="tw-flex-initial">{checkbox.active_description}</div>
            {isActive && tenantFlags.policy_forms_view_template && (
                <Button
                    className={cx(
                        'no-viki-style tw-px-2 tw-py-1',
                        !checkbox.is_existing_latex_form ? 'tw-bg-grey-light tw-text-black/30' : 'tw-text-white tw-bg-blue-primary'
                    )}
                    onClick={() => {
                        fetch(`/api/v2/policyform/render_placeholder_template/?formNumber=${checkbox.field}`)
                            .then(res => res.blob())
                            .then(data => {
                                const fileURL = URL.createObjectURL(data);
                                window.open(fileURL, '_blank');
                            });
                    }}
                    disabled={!checkbox.is_existing_latex_form}
                    hoverTip={!checkbox.is_existing_latex_form ? 'Not Available' : ''}
                >
                    View Template
                </Button>
            )}
        </div>
    );
};

const PolicyForms = () => {
    const quoteId = useQuoteId();
    const isEditable = useSubmissionIsEditable();
    const { data, isLoading } = useGetPolicyFormsQuery({ quoteId }, { skip: !quoteId });
    const [triggerClearOverrides, { isLoading: isClearing }] = useClearPolicyFormsOverridesMutation();
    const pendingPF = useAppSelector(state => selectPendingRequests(Object.values(selectPolicyFormMutationsByEndpoint(state, 'updatePolicyForm'))));
    const pendingEnd = useAppSelector(state => selectPendingRequests(Object.values(selectPolicyFormMutationsByEndpoint(state, 'updateEndorsementForm'))));
    const isUpdating = isClearing || pendingPF.length > 0 || pendingEnd.length > 0;
    const appContext = useAppContext();
    const { currentTransaction, currentQuote } = appContext;
    const isEndorsementPackage =
        currentTransaction &&
        currentTransaction.status === TRANSACTION_STATUS.IN_REVIEW &&
        currentTransaction.transaction_type === TRANSACTION_TYPES.ENDORSEMENT;

    if (isLoading) {
        return (
            <div className="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center">
                <Spinner size="xl" />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const { policy_forms: policyFormData } = data;
    return (
        <>
            <div className="section-header tw-px-0.5 tw-py-2 tw-top-0 tw-sticky tw-z-10 tw-bg-white tw-flex tw-gap-4">
                POLICY FORMS
                <Button
                    className={cx(
                        'no-viki-style tw-px-2 tw-py-1',
                        !isEditable || !policyFormData.has_manual_overrides ? 'tw-bg-grey-light tw-text-black/30' : 'tw-text-white tw-bg-blue-primary'
                    )}
                    onClick={() => triggerClearOverrides({ policyCoverageId: currentQuote.policy_coverage_id, quoteId })}
                    disabled={!isEditable || !policyFormData.has_manual_overrides}
                >
                    Clear All Overrides
                </Button>
                {isUpdating && (
                    <span className="tw-flex tw-gap-2 tw-tracking-[1px] tw-font-medium">
                        Updating <Spinner />
                    </span>
                )}
            </div>
            <fieldset className="section-content">
                {Object.entries(policyFormData?.checkboxes_by_group).map(([groupKey, checkboxList]) => (
                    <PolicyFormGroup key={groupKey} title={policyFormData.groups[groupKey]} isEndorsementPackage={isEndorsementPackage}>
                        {checkboxList.map(fieldId => (
                            <PolicyFormsRow
                                key={fieldId}
                                checkbox={data.policy_forms.checkboxes_by_field_id[fieldId]}
                                isEndorsementPackage={isEndorsementPackage}
                                groupKey={groupKey}
                            />
                        ))}
                    </PolicyFormGroup>
                ))}
            </fieldset>
        </>
    );
};

export default PolicyForms;
