import { Redirect, Route, Switch } from 'react-router-dom';
import AccountsAppRoutes from './AccountsAppRoutes';
import BackgroundTaskPage from '../../containers/tasks/BackgroundTasksPage.container';
import BIGlossaryContainer from '../../components/glossary/BIGlossaryContainer';
import AnalyticsContainer from '../../components/analytics/Analytics.container';
import TargetMultiplesPage from '../../components/actuarial/TargetMultiplePage';
import TargetMultipleHistoryPage from '../../components/actuarial/TargetMultipleHistoryPage';
import { CattoolsPage } from '../../components/cattools/CattoolsApp';

import Viki404Page from '../pages/Viki404Page';
import { AppConstants } from '@archinsurance-viki/property-jslib';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { VikiSearch } from '../../features/VikiSearch';
import { BulkCatModelPage } from '../../features/bulk-cat-model/BulkCatModelPage';

export default function TopLevelRoutes() {
    return (
        <Switch>
            <Route path="/analytics">
                <AnalyticsContainer hasLeftPanel={true} />
            </Route>
            <Route path="/cattools">
                <CattoolsPage />
            </Route>
            <Route path="/bulk-cat-model">
                <BulkCatModelPage />
            </Route>
            <Route path="/glossary">
                <BIGlossaryContainer hasLeftPanel={true} />
            </Route>
            <Route path="/grid/:submissionId">
                <AccountsAppRoutes />
            </Route>
            <CompatRoute path="/search" component={VikiSearch} />
            <Route path="/tasks/:view?">
                <BackgroundTaskPage />
            </Route>
            <Route path="/submissions/:accountId?/transactions/:transactionId?">
                <AccountsAppRoutes />
            </Route>
            <Route path="/submissions/:accountId?/:submissionId?">
                <AccountsAppRoutes />
            </Route>
            <Route exact path="/actuarial">
                <Redirect to="/actuarial/target-multiples" />
            </Route>
            <Route exact path="/actuarial/target-multiples">
                <TargetMultiplesPage />
            </Route>
            <Route exact path="/actuarial/target-multiple-audit-history">
                <TargetMultipleHistoryPage />
            </Route>
            <Route exact path="/">
                <Redirect to={AppConstants.DEFAULT_URL} />
            </Route>
            <Route>
                <Viki404Page />
            </Route>
        </Switch>
    );
}
