import * as React from 'react';
import * as _ from 'lodash';

import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import DefaultModal from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    modalData: Record<string, any>;
};

class WarningsBody extends React.Component<bodyPropTypes> {
    render() {
        let { warnings } = this.props.modalData;

        return (
            <div style={{ padding: '30px 30px 60px' }}>
                <div style={{ padding: '0 0 15px 0' }}>Warnings:</div>
                {_.map(warnings, warning => {
                    return <div key={warning}>{warning}</div>;
                })}
            </div>
        );
    }
}

let modalTypeObj = {
    Body: WarningsBody,
    Header: DefaultHeaderStatic('THERE ARE WARNINGS ASSOCIATED WITH YOUR REQUEST'),
    Footer: DefaultModal.Footer,
};

registerType(VIRA_MODAL_TYPES.WARNINGS, modalTypeObj);
export default modalTypeObj;
