import _ from 'lodash';
// import { formatCurrency } from '@archinsurance-viki/property-jslib/src/utils/converters';

export const calculateExposureGroup = (data: any, groupByKey: string) => {
    const totalBuildingTivs = _.sumBy(data, 'building_value');
    const totalBuildings = data.length;
    const r = _.groupBy(data, groupByKey);
    let results = [];

    for (let key in r) {
        const groupedData = r[key];
        const tiv = _.sumBy(groupedData, 'building_value');
        const bldgs = groupedData.length;
        results.push({
            bldgs: bldgs,
            bldgsPercent: (100 * bldgs) / totalBuildings,
            tiv: tiv,
            tivPercent: (100 * tiv) / totalBuildingTivs,
            type: key,
        });
    }

    return _.orderBy(results, 'tiv', 'desc');
};

// export const calculateDistributionGroup = (data: any, groupByKey: string) => {
//     const r = _.groupBy(data, groupByKey);
//     let results = Object.keys(r).map((key) => {
//         const groupedData = r[key];
//         const tiv = _.sumBy(groupedData, 'total_tiv');
//         const bldgs = _.sumBy(groupedData, 'count');
//         return {
//             tiv: formatCurrency(tiv),
//             tiv_unformatted: tiv,
//             bldgs: bldgs,
//             type: key,
//         };
//     });

//     return _.orderBy(results, 'tiv_unformatted', 'desc');
// };
