import React from 'react';
import { BindingRequirementTable } from '../../features/BindingRequirementsTable';
import { BottomValidationsPanel } from '../../features/ValidationsPanel';
import useQuoteId from '../../hooks/quotes';
import { useAppSelector } from '../../hooks/redux';
import { useQuoteBindingRequirementsQuery } from '../../services/endpoints/coverage-option';
import { QuoteBindingRequirement } from '../../ts-types/DataTypes';
import { DefaultAccountLayout } from '../common/DefaultLayout';

const BindingRequirementSection = ({
    data,
    groupId,
    actions,
}: {
    data: Record<string, QuoteBindingRequirement[]>;
    groupId: string;
    actions?: React.ReactNode[];
}) => {
    const bindingRequirementGroup = useAppSelector(state => state.global.CONSTANTS.BINDING_REQUIREMENT_GROUPS.find(choice => choice.value === groupId));
    if (!bindingRequirementGroup) {
        throw new Error(`Invalid group id provided: ${groupId}`);
    }
    return (
        <div className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-flex tw-gap-2 tw-items-center">
                <h2 className="tw-text-[18px]">{bindingRequirementGroup.display}</h2>
                <div>
                    {actions?.map((action, i) => (
                        <React.Fragment key={`${groupId}_action${i}`}>{action}</React.Fragment>
                    ))}
                </div>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2 tw-items-start">
                <BindingRequirementTable bindingRequirements={data?.[groupId] ?? []} groupId={groupId} />
            </div>
        </div>
    );
};

export const BindingRequirements = () => {
    const quoteId = useQuoteId();
    const { groupedData } = useQuoteBindingRequirementsQuery(
        { quoteId },
        {
            skip: !quoteId,
            selectFromResult: result => {
                const groupedData = {} as Record<string, QuoteBindingRequirement[]>;
                if (!result?.data) {
                    return { ...result, groupedData };
                }
                result.data.forEach(br => (groupedData[br.group_id] ||= []).push(br));
                return { ...result, groupedData: groupedData };
            },
        }
    );

    return (
        <DefaultAccountLayout bottomPanel={<BottomValidationsPanel />}>
            <div className="tw-flex tw-flex-col tw-gap-4 tw-px-4 tw-py-2 tw-max-w-[1200px]">
                <BindingRequirementSection groupId="REQUIRED_DOCUMENTATION" data={groupedData} />
                <BindingRequirementSection groupId="REPRESENTATIONS_AND_WARRANTIES" data={groupedData} />
            </div>
        </DefaultAccountLayout>
    );
};
