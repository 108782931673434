import u from 'updeep';
import _ from 'lodash';

import { batch } from 'react-redux';

import { API } from '@archinsurance-viki/property-jslib';
import * as types from '../constants/ActionTypes';

import { QUOTE_TRANSITION_STATUSES } from '../constants/Quote';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { ActionHelperFnsType, TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { getAccountSubmissionViewFromServer, validateSubmission_server, getAccountDataFromServer } from './SubmissionActions';
import {
    updateDataByPath,
    loadLiteTableRows,
    refreshData,
    getTableAggregates_server,
    updateAggregates,
    reloadTable,
} from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';
import { VIRA_MODAL_TYPES } from '../constants/Modal';
import { hasOutstandingQuotes } from '../utils/quote-helpers';
import { getQuoteConditionAggregates } from '../utils/render-helpers/quotes/quote-conditions-helpers';
import { GoToSubmissionType, QuoteType, SubmissionDataType } from '../ts-types/DataTypes';
import { notifyMovedSubmission } from '../utils/ui-helpers';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { AppDispatch } from '../store';
import { updateTaskProgress, updateUi } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { coverageOptionApi } from '../services/endpoints/coverage-option';

export const updateQuoteCheckboxData = (quoteId: number, data: Record<string, any>) => ({
    type: types.QUOTE_CHECKBOX_ACTIONS.UPDATE_CHECKBOX_DATA,
    data,
    idKey: quoteId,
});

export const updateQuoteCheckboxDataMerge = (quoteId: number, data: Record<string, any>) => ({
    type: types.QUOTE_CHECKBOX_ACTIONS.UPDATE_CHECKBOX_DATA_MERGE,
    data,
    idKey: quoteId,
});

export const setQuoteAttribute = (tableConfig: TableConfigType, data: Record<string, any>) => ({
    type: types.UPDATE_ROW_DATA,
    data: data,
    tableConfig: tableConfig,
});

export const getQuoteFromServer = (quoteId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.get,
            {
                id: quoteId,
            },
            dispatch
        ).then(quote => {
            dispatch(loadLiteTableRows(LITE_TABLES.QUOTES, [quote]));
        });
    };
};

export const popCloseQuoteMessageModal = (
    quoteId: number,
    closeWithStatus: string,
    currentSubmission?: SubmissionDataType,
    goToSubmission?: GoToSubmissionType,
    actionHelperFns?: ActionHelperFnsType
) => {
    return (dispatch: AppDispatch, getState: () => { quotes: Record<string, any> }) => {
        dispatch(
            openCenteredModal(
                {
                    modalData: {
                        quoteId: quoteId,
                        closeWithStatus: closeWithStatus,
                        onSuccess: () => {
                            if (currentSubmission && goToSubmission && actionHelperFns) {
                                const tab = hasOutstandingQuotes(currentSubmission, getState().quotes.rowData) ? 'OUTSTANDING_QUOTES' : 'UW_REVIEW';
                                notifyMovedSubmission(dispatch, currentSubmission.id, tab, actionHelperFns, goToSubmission, getState());
                            }
                        },
                    },
                },
                VIRA_MODAL_TYPES.ADD_MESSAGE
            )
        );
    };
};

export const popBindConfirmationModal = (quoteId: number, submissionId: number, quote: QuoteType, onSuccess: () => any) => {
    return (dispatch: AppDispatch) => {
        dispatch(
            openCenteredModal(
                {
                    title: `Bind Confirmation for SID: ${submissionId}`,
                    content: (
                        <div>
                            <p>
                                You are about to bind SID {submissionId}, Quote ID {quoteId}:
                            </p>
                            <ul className="tw-mx-10 tw-list-disc tw-list-inside tw-text-sm">
                                <li>Description: {quote.description}</li>
                                <li>
                                    Charged Premium before Taxes and Fees: $
                                    {quote.final_premium.charged_premium_before_tf.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2 })}
                                </li>
                                <li>TRIA: {quote.policy_coverage.terror ? 'Included' : 'Excluded'}</li>
                            </ul>
                            <p>Are you sure?</p>
                        </div>
                    ),
                    modalData: {
                        confirmLabelTitle: 'Bind',
                        dismissButtonTitle: 'Cancel',
                        onOk: () => {
                            onSuccess();
                        },
                    },
                },
                CENTERED_MODAL_TYPES.CONFIRM
            )
        );
    };
};

export const popPPEModal = (peeInterimData: Record<string, any>, title: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(
            openCenteredModal(
                {
                    modalData: {
                        peeInterimData: peeInterimData,
                        title: title,
                    },
                },
                VIRA_MODAL_TYPES.PPE
            )
        );
    };
};

export const updateQuote = (quoteId: number, data: Record<string, any>, isPatch?: boolean) => {
    let update = {};

    if (isPatch) {
        update = data;
    } else {
        for (let [k, v] of Object.entries(data)) {
            update[k] = u.constant(v);
        }
    }
    return {
        type: types.UPDATE_DATA_BY_PATH,
        tableConfig: LITE_TABLES.QUOTES,
        path: `rowData.${quoteId}`,
        update: update,
    };
};

export const duplicateQuote_server = (tableConfig: TableConfigType, submissionId: number, quoteId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.duplicateQuote,
            {
                params: { quote_id: quoteId },
            },
            dispatch
        ).then(
            () => {
                return dispatch(getAccountSubmissionViewFromServer(submissionId));
            },
            _xhr => {
                console.error('markAsPrimary_server: markAsPrimary_server');
                //dispatch(setErrors(xhr.errors));
            }
        );
    };
};

export const markAsPrimary_server = (tableConfig: TableConfigType, submissionId: number, quoteId: number, params: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.markAsPrimary,
            {
                id: quoteId,
                params: params,
            },
            dispatch
        ).then(
            () => {
                return dispatch(getAccountSubmissionViewFromServer(submissionId));
            },
            _xhr => {
                console.error('markAsPrimary_server: markAsPrimary_server');
                //dispatch(setErrors(xhr.errors));
            }
        );
    };
};

export const changeQuoteStatus_server = (tableConfig: TableConfigType, newStatus: string, quoteId: number, message: string, onSuccess: () => void) => {
    let endpoint;
    switch (newStatus) {
        case QUOTE_TRANSITION_STATUSES.WITHDRAWN.STATUS:
            endpoint = API.endpoints.quote.markAsWithdrawn;
            break;
        case QUOTE_TRANSITION_STATUSES.NOT_TAKEN_UP.STATUS:
            endpoint = API.endpoints.quote.markAsNotTakenUp;
            break;
        case QUOTE_TRANSITION_STATUSES.SENT_TO_AGENT.STATUS:
            endpoint = API.endpoints.quote.markAsSentToAgent;
            break;
        default:
            throw new Error(`${newStatus} is not allowed.`);
    }

    return (dispatch: AppDispatch) => {
        return API.doRequest(
            endpoint,
            {
                id: quoteId,
                params: { message: message },
            },
            dispatch
        ).then(
            () => {
                let { STATUS, STATUS_DISPLAY } = QUOTE_TRANSITION_STATUSES[newStatus];
                dispatch(setQuoteAttribute(tableConfig, { id: quoteId, status: STATUS, status_display: STATUS_DISPLAY }));
                dispatch(updateUi({ data: { message: null }, dataPath: 'messageObj' }));
                onSuccess();
            },
            _xhr => {
                console.error(`FAILED: need to implement ${newStatus}`);
            }
        );
    };
};

export const setOverrides_server = (finalPremiumId: number, quoteId: number, params: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.setOverrides,
            {
                finalPremiumId: finalPremiumId,
                params: params,
                showProgress: true,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(updateQuote(quoteId, data));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const getTivGuidelines_server = (quoteId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.getTivGuidelines,
            {
                id: quoteId,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(loadLiteTableRows(LITE_TABLES.TIVGUIDELINES, data.building_guidelines));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const getQuoteCheckboxes_server = (quoteId: number, checkboxType) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.report[checkboxType],
            {
                showProgress: true,
                quoteId: quoteId,
            },
            dispatch
        ).then(
            ({ data }: { data: Record<string, any> }) => {
                dispatch(updateQuoteCheckboxData(quoteId, data));
            },
            _xhr => {
                console.error(_xhr);
            }
        );
    };
};

export const repriceQuotes_server = (tableConfig: TableConfigType, quoteIds: number[]) => {
    return (dispatch: AppDispatch) => {
        for (let quoteId of quoteIds) {
            API.doRequest(
                API.endpoints.quote.repriceQuote,
                {
                    id: quoteId,
                },
                dispatch
            ).then(
                ({ data }) => {
                    dispatch(updateDataByPath(tableConfig, `rowData.${quoteId}`, data));
                },
                _xhr => {
                    console.error(_xhr);
                }
            );
        }
    };
};

export const updateBindingRequirements_server = (quoteId: number, params: Record<string, any>) => {
    console.log('update binding reqs', quoteId, params);
    return (dispatch: AppDispatch) => {
        dispatch(updateQuote(quoteId, { letter_params: params }));
        return API.doRequest(
            API.endpoints.report.updateBindingRequirements,
            {
                params: _.extend({ quote_id: quoteId }, {}, params), // TODO: build new API this sucks
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(updateQuote(quoteId, data));
            },
            xhr => {
                console.error('Error saving updateBindingRequirements_server. TODO: handle error.', xhr);
            }
        );
    };
};

export const getBlanketLimits_server = (quoteId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.blanketCoverage.getBlanketGroups,
            {
                showProgress: true,
                quoteId: quoteId,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(loadLiteTableRows(LITE_TABLES.BLANKET_LIMIT_GROUPS, data.results));
            },
            xhr => {
                console.error('Error saving getQuoteConditions_server. TODO: handle error.', xhr);
            }
        );
    };
};

export const generateQuoteLetter_server = (quoteId: number, isPreview: boolean, isApproved: boolean, submissionId?: number) => {
    return (dispatch: AppDispatch) => {
        // Refresh the table after marking quote as sent to ensure the submission is removed from Underwriting tab without hard refresh
        const shouldRefreshTable = !isPreview && !!submissionId;
        return API.doRequest(
            API.endpoints.report.generateQuoteLetter,
            {
                showProgress: {
                    isBlocking: shouldRefreshTable,
                    message: `Generating Quote Letter for submission: ${submissionId}`,
                },
                params: {
                    quote_id: quoteId,
                    is_preview: isPreview,
                    approved: isApproved,
                },
            },
            dispatch
        ).then(
            ({ data }) => {
                if (!data.success) {
                    dispatch(updateTaskProgress({ data, taskPath: getTaskPath(data.task_type, data.key_id) }));
                } else if (data.success && shouldRefreshTable) {
                    dispatch(getAccountDataFromServer(submissionId));
                    dispatch(getAccountSubmissionViewFromServer(submissionId));
                    dispatch(reloadTable(PAGED_TABLES.SUBMISSION_LOG));
                }
            },
            xhr => {
                console.error('Error saving generateQuoteLetter_server. TODO: handle error.', xhr);
            }
        );
    };
};

export const updateQuote_server = (quoteId: number, params: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.put,
            {
                id: quoteId,
                params: params,
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(updateQuote(quoteId, data));
            },
            xhr => {
                console.error('Error saving updateQuote_server. TODO: handle error.', xhr);
            }
        );
    };
};

type callbackType = (isSucessful: boolean, errorData?: any) => any;
export const addCustomQuoteCondition_server = (quoteId: number, params: Record<string, any>, callback: callbackType) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.addCustomQuoteCondition,
            {
                id: quoteId,
                params: params,
                hideDialogForErrors: true,
            },
            dispatch
        )
            .then(({ data }) => {
                batch(() => {
                    dispatch(updateQuote(quoteId, { custom_quote_conditions: data.custom_quote_conditions }, true));
                    dispatch(updateQuoteCheckboxData(quoteId, data.quote_letter_form_data));
                });
                callback(true);
            })
            .catch(error => {
                callback(false, error.data);
            });
    };
};

export const requestCUOApproval = (quoteId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.requestCUOApproval,
            {
                id: quoteId,
            },
            dispatch
        ).then(
            () => {
                dispatch(
                    openCenteredModal(
                        {
                            title: 'Request CUO Approval',
                            description: 'The CUO has been notified of your request. Check back soon to see the status of the request.',
                            modalData: {
                                confirmLabelTitle: 'Ok',
                                onOk: () => {
                                    let updateData = {};
                                    dispatch(updateQuote(quoteId, updateData));
                                },
                            },
                        },
                        CENTERED_MODAL_TYPES.CONFIRM
                    )
                );
            },
            xhr => {
                console.error('Error requestCUOApproval. TODO: handle error.', xhr);
            }
        );
    };
};

export const markAsApproved = (quoteId: number, submissionId: number, approvalHash: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.markAsApproved,
            {
                id: quoteId,
                params: {
                    hash: approvalHash,
                },
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(validateSubmission_server(submissionId, quoteId, true));
                dispatch(coverageOptionApi.endpoints.referralHistory.initiate({ quoteId }, { forceRefetch: true }));
                dispatch(
                    openCenteredModal(
                        {
                            modalData: {
                                approval_descriptions: data.approval_descriptions,
                                message: data.message,
                                onOk: () => {
                                    let updateData = {
                                        is_allowed_to_quote: { is_allowed: true },
                                    };
                                    dispatch(updateQuote(quoteId, updateData));
                                },
                            },
                        },
                        VIRA_MODAL_TYPES.CUO_APPROVAL
                    )
                );
            },
            xhr => {
                console.error('Error requestCUOApproval. TODO: handle error.', xhr);
            }
        );
    };
};

export const updateBuildingQuoteConditions_server = (tableConfig: TableConfigType, quoteId: number, data: Record<string, any>) => {
    return (dispatch: AppDispatch): void => {
        if (data.aggregates) {
            dispatch(updateAggregates(tableConfig, data.aggregates));
        }

        API.doRequest(
            API.endpoints.quote.applyQuoteConditionsToBuilding,
            {
                quoteId,
                params: data.serverChange,
                showProgress: true,
            },
            dispatch
        ).then(() => {
            // always need to get new data to properly show "override" tag
            dispatch(refreshData(tableConfig));
            if (!data.aggregates) {
                // only need to fetch this again if we didn't optimistically update above
                dispatch(getTableAggregates_server(tableConfig, getQuoteConditionAggregates()));
            }
        });
    };
};

export const saveAssociatedPolicies_server = (quoteId, newPolicies: any) => {
    return (dispatch: AppDispatch) => {
        dispatch(updateQuote(quoteId, { associated_policies: newPolicies }));
        return API.doRequest(
            API.endpoints.quote.updateAssociatedPolicies,
            {
                newPolicies,
                quoteId,
            },
            dispatch
        ).then(data => {
            console.log('updated', data);
        });
    };
};

export const toggleRAECatModel = (quoteId, rae_cat_model_indicator: boolean) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.quote.toggleRAECatModel,
            {
                id: quoteId,
                params: { rae_cat_model_indicator },
                showProgress: true,
            },
            dispatch
        ).then(({ data }) => {
            dispatch(updateQuote(quoteId, data));
        });
    };
};
