import { useQuoteFinalPremium } from '../../hooks/quotes';
import Grid, { Label, OverrideButton, Value } from './PricingGrid';

const TargetPremiumPanel = ({ showModal }: { showModal: () => void }) => {
    const { finalPremium } = useQuoteFinalPremium();
    if (!finalPremium) {
        return null;
    }
    const {
        target_premium_before_wdbd,
        charged_premium_before_tf,
        wdbd_premium,
        wdbd_premium_override,
        wdbd_premium_adj_for_participation_final,
        rate_tiv_before_wdbd,
        rate_amount_subject_before_wdbd,
        target_premium_after_wdbd,
        rate_tiv_after_wdbd,
        rate_amount_subject_after_wdbd,
    } = finalPremium;

    const wdbdPremiumDisplayValue = charged_premium_before_tf
        ? wdbd_premium_adj_for_participation_final
        : wdbd_premium_override
        ? wdbd_premium_override
        : wdbd_premium;

    return (
        <>
            <div className="info-block grid-2-high w-260">
                <div className="header">Target Premium</div>
                <Grid columns={2} className="gap-0125 padding-light">
                    <Label>
                        <b>Target Premium - Before WDBD</b>
                    </Label>
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={target_premium_before_wdbd} />

                    <Label>Rate (TIV)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={rate_tiv_before_wdbd} />

                    <Label>Rate (Amount Subject)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={rate_amount_subject_before_wdbd} />

                    <div className="grid-2-wide grid-spacer"></div>

                    <Label>
                        <b>Wind Deductible Buydown Premium</b>
                    </Label>
                    <OverrideButton onClick={() => showModal()}>
                        <Value format={{ type: 'number', delimiter: ',' }} value={wdbdPremiumDisplayValue} />
                    </OverrideButton>

                    <div className="grid-2-wide grid-spacer"></div>

                    {/* TODO: Need to change below fields */}
                    <Label>
                        <b>Target Premium - After WDBD</b>
                    </Label>
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={target_premium_after_wdbd} />
                    <Label>Rate (TIV)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={rate_tiv_after_wdbd} />

                    <Label>Rate (Amount Subject)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={rate_amount_subject_after_wdbd} />
                </Grid>
            </div>
        </>
    );
};

export default TargetPremiumPanel;
