import * as React from 'react';
import { ReactReduxContext } from 'react-redux';

import _ from 'lodash';

import { finishProgress, startProgress } from '@archinsurance-viki/property-jslib/src/utils/Progress';

import Dropzone from 'react-dropzone';

import { EndpointType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

type propTypes = {
    className: string;
    uploadParams: Record<string, any>;
    uploadEndpoint: EndpointType;
    uploadAlias: string;
    onDocumentUploaded?: (document?: any) => void;
    onStatusUpdate: (Object) => void;
    children: any;
};

type stateTypes = {
    errors: string[];
};

export default class DocumentUpload extends React.Component<propTypes, stateTypes> {
    static contextType = ReactReduxContext;

    fileData: Record<string, any>;

    constructor(props: propTypes) {
        super(props);

        this.state = {
            errors: [],
        };

        this.fileData = {
            uploading: false,
            totalFiles: 0,
            filesLeft: [],
            failures: false,
            currentFileProgress: 0,
        };
    }

    startNextUpload() {
        if (this.fileData.filesLeft.length === 0) {
            this.fileData.uploading = false;

            if (this.fileData.failures) {
                this.props.onStatusUpdate({
                    done: true,
                    message: 'Some files failed to upload',
                    errors: this.state.errors,
                });
                return;
            } else {
                this.props.onStatusUpdate({
                    done: true,
                    message: 'Upload successful',
                    errors: this.state.errors,
                });
                return;
            }
        }

        let file = this.fileData.filesLeft[0];
        this.fileData.filesLeft = this.fileData.filesLeft.slice(1);
        this.fileData.uploading = true;
        this.fileData.currentFileProgress = 0;

        this.uploadFile(file);
        this.updateProgress();
        startProgress(this.context.store.dispatch, 'doc-upload');
    }

    updateProgress() {
        let totalFiles = this.fileData.totalFiles;
        let currentFile = totalFiles - this.fileData.filesLeft.length; // 1 indexed

        let progress = (currentFile - 1) / totalFiles;
        if (this.fileData.currentFileProgress) {
            progress += this.fileData.currentFileProgress * (1 / totalFiles);
        }

        let message = 'Uploading...';
        if (progress > 0) {
            message += ' ' + Math.round(progress * 1000) / 10 + '% complete.';
        }

        this.props.onStatusUpdate({
            done: false,
            message: message,
            errors: this.state.errors,
        });
    }

    uploadFile = (file: File) => {
        let formData = new FormData();
        formData.append(this.props.uploadAlias, file);

        let additionalData = Object.assign({ document_type: 'UNKNOWN' }, this.props.uploadParams);
        for (let [field, data] of Object.entries(additionalData)) {
            formData.append(field, data);
        }

        let config = {
            onUploadProgress: o => {
                if (o.loaded) {
                    this.fileData.currentFileProgress = o.loaded / o.total;
                }
                this.updateProgress();
            },
        };

        this.props
            .uploadEndpoint(formData, config)
            .then(o => {
                if (typeof this.props.onDocumentUploaded === 'function') {
                    this.props.onDocumentUploaded(o.document);
                }
                console.log('Uploaded doc');
                finishProgress(this.context.store.dispatch, 'doc-upload');
                this.startNextUpload();
            })
            .catch(errorData => {
                console.error('Error uploading file', errorData);

                let errors = errorData.errors || errorData.detail || errorData;

                let newErrors = _.isArray(errors) ? this.state.errors.concat(errors) : this.state.errors.concat([errors]);

                this.setState({ errors: newErrors });
                this.fileData.failures = true;
                finishProgress(this.context.store.dispatch, 'doc-upload');
                this.startNextUpload();
            });
    };

    onDrop = (acceptedFiles: File[]) => {
        this.fileData.filesLeft = this.fileData.filesLeft.concat(acceptedFiles);
        this.fileData.totalFiles = this.fileData.totalFiles + acceptedFiles.length;

        if (!this.fileData.uploading) {
            this.props.onStatusUpdate({
                done: false,
                message: 'Uploading...',
                errors: this.state.errors,
            });

            this.startNextUpload();
        }
    };

    applyPropsToChildren(getRootProps: () => any): any {
        const { children } = this.props;

        return (
            <div {...getRootProps()} className="drop-container">
                {children}
            </div>
        );
    }

    render() {
        return (
            <Dropzone noClick={true} onDrop={this.onDrop}>
                {({ getRootProps }) => this.applyPropsToChildren(getRootProps)}
            </Dropzone>
        );
    }
}
