import * as React from 'react';
import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import LayeredPoliciesModalApp from '../apps/LayeredPoliciesModalApp';
import { AppContextType } from '../../../../ts-types/AppTypes';
import { saveAssociatedPolicies_server } from '../../../../actions/QuoteActions';
import { PolicyGroupType } from '../../../../ts-types/DataTypes';

type ownPropTypes = {
    onClose: (x?: boolean) => any;
    appContext: AppContextType;
};

type propTypes = {
    onSaveAssociatedPolicies: (newPolicies: PolicyGroupType[]) => void;
    associatedPolicies: PolicyGroupType[];
    currentSubmissionId: number;
    currentQuoteId: number;
} & ownPropTypes;

const mapStateToProps = (state: Record<string, any>, ownProps: ownPropTypes) => {
    const { currentQuote, currentQuoteId, currentSubmissionId } = ownProps.appContext;
    const { associated_policies = [] } = currentQuote;
    return {
        associatedPolicies: associated_policies,
        currentQuoteId,
        currentSubmissionId,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    return {
        onSaveAssociatedPolicies(newPolicies: PolicyGroupType[]) {
            dispatch(saveAssociatedPolicies_server(ownProps.appContext.currentQuoteId, newPolicies));
        },
    };
};

class AdditionalNamedInsuredsContainer extends React.Component<propTypes> {
    render() {
        return <LayeredPoliciesModalApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(AdditionalNamedInsuredsContainer);
export default connected;
