import React, { useEffect, useMemo } from 'react';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { ColumnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { updateDataByPath } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { getTableAggregates_server } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { getQuoteConditionAggregates } from '../../utils/render-helpers/quotes/quote-conditions-helpers';

import { updateBuildingQuoteConditions_server, getQuoteCheckboxes_server } from '../../actions/QuoteActions';

import BuildingQuoteConditionsApp from './BuildingQuoteConditionsApp';
import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { AppContextType } from '../../ts-types/AppTypes';
import { getQuoteConditionsHashMap } from '../../utils/render-helpers/quotes/quote-conditions-helpers';
import { RootState } from '../../store';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import { getBuildingQuoteConditionUpdate } from '../../utils/quote-helpers';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

const mapStateToProps = (state: RootState, appContext: AppContextType) => {
    let { currentQuoteId } = appContext;

    const apiParams = {
        quote_id: currentQuoteId,
    };
    const apiParamsKey = getApiParamsKey(apiParams);
    const tableConfig = { ...PAGED_TABLES.BUILDING_QUOTE_CONDITIONS, apiParams, apiParamsKey };
    const tableData = state.buildingQuoteConditions[apiParamsKey];

    let extraCellRenderData = {
        quoteConditionsLabelsByField: getQuoteConditionsHashMap(state.quoteCheckboxes[currentQuoteId]),
        aggregates: tableData ? tableData.aggregates : {},
        rowCount: tableData ? tableData.rows.length : undefined,
    };

    return {
        tableConfig,
        tableData,
        extraCellRenderData,
    };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
    return {
        getQuoteConditions: (quoteId: number) => {
            return dispatch(getQuoteCheckboxes_server(quoteId, 'quoteCondBindReqs'));
        },
        updateBuildingQuoteCondition: (
            tableConfig: TableConfigType,
            quoteId: number,
            buildingIds: unknown,
            bcIds: unknown[],
            data: Record<string, any>,
            column: ColumnType
        ) => {
            let updateField = Object.keys(data)[0];
            let updateValue = data[updateField];

            let building_ids_to_set = null;
            let building_ids_to_unset = null;
            if (updateValue === 0 || updateValue === false) {
                building_ids_to_unset = buildingIds;
            } else {
                building_ids_to_set = buildingIds;
            }

            let payload = {
                quote_condition_field: column.id,
                building_ids_to_set: building_ids_to_set,
                building_ids_to_unset: building_ids_to_unset,
                include_building_coverage_validation: false,
            };

            if (bcIds.length === 1 && typeof bcIds[0] === 'number') {
                // update in reducer right away for responsiveness
                dispatch(updateDataByPath(tableConfig, `rowData.${bcIds[0]}`, data));
            }

            dispatch(updateBuildingQuoteConditions_server(tableConfig, quoteId, { serverChange: payload }));
        },
        updateBuildingQuoteConditions: (tableConfig: TableConfigType, quoteId: number, data) => {
            dispatch(updateBuildingQuoteConditions_server(tableConfig, quoteId, data));
        },
    };
};

export default function QuoteConditionsPage() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const stateProps = useAppSelector(state => mapStateToProps(state, appContext));
    const dispatchProps = mapDispatchToProps(useAppDispatch());
    const dispatch = useAppDispatch();

    const { currentQuoteId } = appContext;
    const bqcTableConfig = useMemo(() => {
        const apiParams = { quote_id: currentQuoteId };
        const apiParamsKey = getApiParamsKey(apiParams);
        return { ...PAGED_TABLES.BUILDING_QUOTE_CONDITIONS, apiParams, apiParamsKey };
    }, [currentQuoteId]);

    const updateBuildingQuoteCondition = (persistData: { request: any }, column: ColumnType) => {
        let { tableConfig, tableData } = stateProps;
        const { currentSubmission, currentQuoteId } = appContext;
        if (currentSubmission && currentSubmission._actions && !currentSubmission._actions.editable) {
            return;
        }
        let { request } = persistData;
        let buildingIds = request.pks.map(bcId => {
            return tableData.rowData[bcId].building_id;
        });

        dispatchProps.updateBuildingQuoteCondition(tableConfig, currentQuoteId, buildingIds, request.pks, request.data, column);
    };

    const updateQuoteConditionForAllBuildings = ({ column, value }) => {
        const data = {
            aggregateField: column.key + column.aggregate,
            field: column.id,
            value,
        };
        const { aggregates, rowCount } = stateProps.extraCellRenderData;
        const update = getBuildingQuoteConditionUpdate(data, aggregates, rowCount);
        dispatchProps.updateBuildingQuoteConditions(stateProps.tableConfig, appContext.currentQuoteId, update);
    };

    useEffect(() => {
        if (currentQuoteId) {
            dispatch(getQuoteCheckboxes_server(currentQuoteId, 'quoteCondBindReqs'));
            dispatch(getTableAggregates_server(bqcTableConfig, getQuoteConditionAggregates()));
        }
    }, [currentQuoteId, bqcTableConfig, dispatch]);

    return (
        <BuildingQuoteConditionsApp
            currentSubmission={appContext.currentSubmission}
            currentQuoteId={appContext.currentQuoteId}
            tableConfig={stateProps.tableConfig}
            extraCellRenderData={stateProps.extraCellRenderData}
            onApplyColumnSetter={updateQuoteConditionForAllBuildings}
            onPersistBuildingPremiumData={updateBuildingQuoteCondition}
            appContextFns={appContextFns}
            appContext={appContext}
            getQuoteConditions={dispatchProps.getQuoteConditions}
            tableData={stateProps.tableData}
        />
    );
}
