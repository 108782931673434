import * as React from 'react';

import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { Types } from '../../../../ts-types/viki-types';
import { AppContextFnsType } from '../../../../ts-types/AppTypes';

type bodyPropTypes = {
    setModalState: ({ isTestServer }: { isTestServer: boolean }) => void;
    ENV: Types.Env;
    modalState: Record<string, any>;
};

class OverrideOfacBody extends React.Component<bodyPropTypes> {
    render(): React.ReactNode {
        return <div className="standard-modal-content">Would you like to approve or block the policy based on the OFAC results?</div>;
    }
}

type footerPropTypes = {
    modalData: any;
    onClose: any;
    appContextFns: AppContextFnsType;
};

class OverrideOfacFooter extends React.Component<footerPropTypes> {
    onApprove = () => {
        this.props.appContextFns.onOverrideOfac(this.props.modalData.submissionId, true);
        this.props.onClose(true);
    };

    onBlock = () => {
        this.props.appContextFns.onOverrideOfac(this.props.modalData.submissionId, false);
        this.props.onClose(true);
    };

    render() {
        let { onClose } = this.props;

        return (
            <div className="button-row" style={{ textAlign: 'center' }}>
                <button key="approve" className="blue" onClick={this.onApprove}>
                    Approve
                </button>
                <div key="s1" className="spacer wide" />
                <button key="block" className="red" onClick={this.onBlock}>
                    Block
                </button>
                <div key="s2" className="spacer wide" />
                <button key="close" className="grey" onClick={onClose}>
                    Back
                </button>
            </div>
        );
    }
}

let modalTypeObj = {
    Body: OverrideOfacBody,
    Header: DefaultHeaderStatic('Override OFAC'),
    Footer: OverrideOfacFooter,
    className: 'override-ofac-modal',
};
registerType(VIRA_MODAL_TYPES.OVERRIDE_OFAC, modalTypeObj);
export default modalTypeObj;
