import { NavLink } from 'react-router-dom';

import { NAVIGATION_LOCATIONS } from '../constants/Navigation';
import { BACKGROUND_TASK_TYPES } from '../constants/TaskConstants';
import { getDownloadUrlForDocument, getUrlFor } from './navurl-helpers';
import { Types } from '../ts-types/viki-types';
import { BackgroundTaskStatus } from '../ts-types/DataTypes';
import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import store from '../store';
import { openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { VIRA_MODAL_TYPES } from '../constants/Modal';
import { SEND_POLICY_DOCS_TASK_TYPES } from '../constants/PolicyServicesConstants';

export const getTaskSubtitles = (currentTaskInfo: Record<string, any>, env: Types.Env) => {
    let onGetUrlFor = (location: Record<string, any>, params: Record<string, any>, siteId: number) => {
        return getUrlFor(location, params, siteId, env);
    };

    let { submission_id, submission__site_id, quote_id, new_submission_id, new_submission__site_id, account_id, task_type, document_id } = currentTaskInfo;

    const status = currentTaskInfo.status as BackgroundTaskStatus;

    if (status === 'FAIL' || status === 'CANCEL') {
        return [];
    }

    let subtitles = [];
    if (submission_id) {
        let params = {
            submissionId: submission_id,
            accountId: account_id,
        };
        subtitles.push(
            <NavLink
                className="badge badge-primary"
                key="submission-link"
                to={onGetUrlFor(NAVIGATION_LOCATIONS.OVERVIEW, params, submission__site_id)}
            >{`SID ${submission_id}`}</NavLink>
        );
    }
    if (quote_id) {
        let params = {
            submissionId: submission_id,
            accountId: account_id,
            quoteId: quote_id,
        };
        subtitles.push(
            <NavLink
                className="badge badge-primary"
                key="coverage-option-link"
                to={onGetUrlFor(NAVIGATION_LOCATIONS.COVERAGE_OPTION, params, submission__site_id)}
            >{`COID ${quote_id}`}</NavLink>
        );
    }
    if (new_submission_id) {
        let params: any = {};
        params.submissionId = new_submission_id;
        let label = `New SID ${new_submission_id}`;
        if (task_type === BACKGROUND_TASK_TYPES.RENEW_SUBMISSION) {
            label = `Renewed SID ${new_submission_id}`;
        } else if (task_type === BACKGROUND_TASK_TYPES.CLONE_SUBMISSION) {
            label = `Cloned SID ${new_submission_id}`;
        } else if (task_type === BACKGROUND_TASK_TYPES.CREATE_ENDORSEMENT) {
            label = `Endorsement SID ${new_submission_id}`;
            params.accountId = account_id;
        }

        if (task_type === BACKGROUND_TASK_TYPES.CLONE_SUBMISSION && new_submission__site_id !== env.SITE.id) {
            subtitles.push(
                <a className="badge badge-primary" key="new-submission-link" href={onGetUrlFor(NAVIGATION_LOCATIONS.OVERVIEW, params, new_submission__site_id)}>
                    {label}
                </a>
            );
        } else {
            subtitles.push(
                <NavLink
                    className="badge badge-primary"
                    key="new-submission-link"
                    to={onGetUrlFor(NAVIGATION_LOCATIONS.OVERVIEW, params, new_submission__site_id)}
                >
                    {label}
                </NavLink>
            );
        }
    }
    if (document_id) {
        subtitles.push(
            <a className="badge badge-primary" key="document-link" href={getDownloadUrlForDocument(document_id)} download={true}>
                Download Document
            </a>
        );
    }
    if (SEND_POLICY_DOCS_TASK_TYPES.includes(task_type)) {
        subtitles.push(
            <BasicButton
                key="policy-docs-send"
                onClick={() =>
                    store.dispatch(
                        openMessageModal(
                            { submissionId: submission_id, documentId: document_id, taskType: task_type, disableModalResizable: true },
                            VIRA_MODAL_TYPES.SEND_POLICY_DOCS
                        )
                    )
                }
                className="badge badge-primary tw-text-xs tw-w-full tw-rounded-[3px]"
            >
                Email Policy Document to Broker
            </BasicButton>
        );
    }
    return subtitles;
};
