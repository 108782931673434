import * as React from 'react';
import _ from 'lodash';

import ExposureSummaryRow from '../rows/ExposureSummaryRow';
import ExposureSummaryHeaderRow from '../rows/ExposureSummaryHeaderRow';
import { calculateExposureGroup } from '../../../utils/exposure-helpers';

type propTypes = {
    chartTitle: string;
    data: Record<string, any>;
    groupBy: string;
    groupByTitle: string;
};

export default class ExposurePanel extends React.Component<propTypes> {
    renderRows = () => {
        let { data, groupBy } = this.props;
        const rowData = calculateExposureGroup(data, groupBy);
        return _.map(rowData, (props, idx) => {
            return <ExposureSummaryRow {...props} key={idx} />;
        });
    };

    render() {
        let { chartTitle, groupByTitle } = this.props;
        return (
            <fieldset className="panel-inline exposure-panel">
                <h5 className="panel-label-header">{chartTitle}</h5>
                <div className="panel-area panel-table">
                    <ExposureSummaryHeaderRow groupedByTitle={groupByTitle} {...this.props} />
                    {this.renderRows()}
                </div>
            </fieldset>
        );
    }
}
