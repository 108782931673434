import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { selectTenantFlags } from '../../../selectors/environment';
import { CoverageTermsLevel, POLICY_TERMS_TOGGLES, usePolicyTermsContext, POLICY_TERMS_TOGGLE_LABELS, POLICY_TERMS_FLAG_MAP } from '../utils/coverage-utils';

export const CoverageLevelToggle = () => {
    const { coverageLevel, handleCoverageLevelSwitch } = usePolicyTermsContext();

    const tenantFlags = useAppSelector(state => selectTenantFlags(state));
    const showBuildingTerms = tenantFlags?.building_terms ?? false;
    const showBuildingGroupTerms = tenantFlags?.building_group_terms ?? false;

    useEffect(() => {
        if (!showBuildingTerms && coverageLevel === 'building') {
            handleCoverageLevelSwitch('policy');
        } else if (!showBuildingGroupTerms && coverageLevel === 'building-group') {
            handleCoverageLevelSwitch('policy');
        }
    }, [coverageLevel, handleCoverageLevelSwitch, showBuildingTerms, showBuildingGroupTerms]);

    return (
        <div className="filter-panel tw-max-w-[450px] tw-w-[30%]">
            {Object.values(POLICY_TERMS_TOGGLES).map((level: CoverageTermsLevel) => {
                const flagName = POLICY_TERMS_FLAG_MAP?.[level];
                if (level !== 'policy' && !tenantFlags?.[flagName]) {
                    return null;
                }
                return (
                    <div key={level} onClick={() => handleCoverageLevelSwitch(level)} className={`table-pref ${coverageLevel === level ? 'active' : ''}`}>
                        {POLICY_TERMS_TOGGLE_LABELS[level]}
                    </div>
                );
            })}
        </div>
    );
};
