import useQuoteId, { useQuotePolicyCoverage } from '../../hooks/quotes';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { useAppContext } from '../../hooks/context';
import {
    usePolicyTermsDataQuery,
    usePolicyTermsDetailGlossaryQuery,
    useBuildingTermsDataQuery,
    useBuildingTermsDetailGlossaryQuery,
    useBuildingGroupTermsDataQuery,
    useBuildingGroupTermsDetailGlossaryQuery,
} from '../../services/endpoints/coverage-terms';
import { useGetPeeDataQuery } from '../../services/apiSlice';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';
import { PolicyLevelTerms } from './policy/PolicyLevelTerms';
import { BuildingLevelTerms } from './building/BuildingLevelTerms';
import { PolicyTermsContextProvider, CoverageTermsLevel } from './utils/coverage-utils';
import { NumberParam, decodeString, encodeString, useQueryParam } from 'use-query-params';
import { useEffect, useState } from 'react';
import { withDefault } from 'use-query-params';
import { usePrevUpdate } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { BuildingGroupTerms } from './building-group/BuildingGroupLevelTerms';

export const PolicyTermsContainer = () => {
    const { currentQuote } = useAppContext();
    const { policyCoverage, isError } = useQuotePolicyCoverage();

    const { data: peeData } = useGetPeeDataQuery({ id: currentQuote?.policy_coverage_id }, { skip: !currentQuote?.policy_coverage_id });

    if (isError) {
        return <span>There was an error while loading the page</span>;
    }

    if (!policyCoverage || !peeData) {
        return (
            <div className="tw-flex-1 tw-h-full tw-flex tw-justify-center tw-items-center">
                <div>
                    <Spinner size="xl" />
                </div>
            </div>
        );
    }

    return (
        <div className="main-content-center data-entry-wrapper tw-flex tw-flex-col tw-max-h-full">
            <AccountTasksPanel />
            <PolicyTermsApp />
        </div>
    );
};

const PolicyLevelParam = withDefault(
    {
        encode: (str: CoverageTermsLevel) => encodeString(str),
        decode: (str: string | string[] | null | undefined) => decodeString(str) as CoverageTermsLevel,
    },
    'policy'
);

const PolicyTermsApp = () => {
    const { currentQuote } = useAppContext();

    const [coverageLevel, setCoverageLevel] = useQueryParam('coverage-level', PolicyLevelParam);
    const [buildingCoverageId, setBuildingCoverageId] = useQueryParam('building_coverage_id', NumberParam);
    const [buildingGroupCoverageId, setBuildingGroupCoverageId] = useQueryParam('building_group_coverage_id', NumberParam);

    const ptDataQuery = usePolicyTermsDataQuery(
        { policyCoverageId: currentQuote?.policy_coverage_id },
        { skip: coverageLevel !== 'policy' || !currentQuote?.policy_coverage_id }
    );
    const ptGlossaryQuery = usePolicyTermsDetailGlossaryQuery(
        { id: currentQuote?.policy_coverage_id },
        { skip: coverageLevel !== 'policy' || !currentQuote?.policy_coverage_id }
    );

    const btDataQuery = useBuildingTermsDataQuery({ buildingCoverageId }, { skip: !buildingCoverageId || coverageLevel !== 'building' });
    const btGlossaryQuery = useBuildingTermsDetailGlossaryQuery({ buildingCoverageId }, { skip: !buildingCoverageId || coverageLevel !== 'building' });

    const bgDataQuery = useBuildingGroupTermsDataQuery(
        { buildingGroupCoverageId: buildingGroupCoverageId },
        { skip: !buildingGroupCoverageId || coverageLevel !== 'building-group' }
    );
    const bgGlossaryQuery = useBuildingGroupTermsDetailGlossaryQuery(
        { buildingGroupCoverageId: buildingGroupCoverageId },
        { skip: !buildingGroupCoverageId || coverageLevel !== 'building-group' }
    );

    // Coverage Option Selection state put in context to easily share across components
    const quoteId = useQuoteId();
    const [selectedQuoteIds, setSelectedQuoteIds] = useState([quoteId]);
    usePrevUpdate(quoteId, () => {
        setSelectedQuoteIds([quoteId]);
    });

    const [selectedBuildingCoverageIds, setSelectedBuildingCoverageIds] = useState([buildingCoverageId]);
    usePrevUpdate(buildingCoverageId, () => {
        setSelectedBuildingCoverageIds([buildingCoverageId]);
    });

    const handleCoverageLevelSwitch = (level: CoverageTermsLevel) => {
        setCoverageLevel(level);
        setBuildingCoverageId(undefined);
    };

    const handleBuildingCoverageChange = (bcId: number) => {
        setBuildingCoverageId(bcId);
    };

    const handleBuildingGroupCoverageChange = (bgcId: number) => {
        setBuildingGroupCoverageId(bgcId);
    };

    const handleSelectedBuildingCoverageChange = (bId: number) => {
        setSelectedBuildingCoverageIds(prevIds => {
            let ids = [...prevIds];
            const index = ids.indexOf(bId);
            if (index < 0) {
                ids.push(bId);
            } else {
                ids.splice(index, 1);
            }
            return ids;
        });
    };

    const handleSelectedQuoteChange = (id: number) => {
        setSelectedQuoteIds(prevIds => {
            let ids = [...prevIds];
            const index = ids.indexOf(id);
            if (index < 0) {
                ids.push(id);
            } else {
                ids.splice(index, 1);
            }
            return ids;
        });
    };

    useEffect(() => {
        if (coverageLevel === 'building') {
            if (!buildingCoverageId) {
                if (!currentQuote.policy_coverage.building_coverages.length) {
                    throw new Error('There are no building coverages to load');
                }
                setBuildingCoverageId(currentQuote.policy_coverage.building_coverages[0]['id']);
            }
        } else if (coverageLevel === 'building-group') {
            if (!buildingGroupCoverageId) {
                if (currentQuote.building_group_coverage_ids.length) {
                    console.warn('There are no building group coverages to load');
                }
                setBuildingGroupCoverageId(currentQuote.building_group_coverage_ids[0]);
            }
        } else if (coverageLevel === 'policy') {
            // clear id if we are on policy level tab
            setBuildingCoverageId(undefined);
        }
    }, [
        coverageLevel,
        currentQuote.policy_coverage.building_coverages,
        currentQuote.building_group_coverage_ids,
        buildingCoverageId,
        buildingGroupCoverageId,
        setBuildingCoverageId,
        setBuildingGroupCoverageId,
    ]);

    const isLoadingPolicyTerms = coverageLevel === 'policy' && [ptDataQuery, ptGlossaryQuery].some(({ data }) => !data);
    const isLoadingBuildingTerms = coverageLevel === 'building' && [btDataQuery, btGlossaryQuery].some(({ data }) => !data);
    const isLoadingBuildingGroupTerms =
        coverageLevel === 'building-group' && (buildingGroupCoverageId ? [bgDataQuery, bgGlossaryQuery].some(({ data }) => !data) : false);
    if (isLoadingPolicyTerms || isLoadingBuildingTerms || isLoadingBuildingGroupTerms) {
        return (
            <div className="tw-flex-1 tw-h-full tw-flex tw-justify-center tw-items-center">
                <div>
                    <Spinner size="xl" />
                </div>
            </div>
        );
    }

    const { data: policyTermsData } = ptDataQuery;
    const { data: policyTermsGlossary } = ptGlossaryQuery;
    const { data: buildingTermsData } = btDataQuery;
    const { data: buildingTermsGlossary } = btGlossaryQuery;

    const renderCoverageLevel = () => {
        if (coverageLevel === 'policy') {
            return <PolicyLevelTerms />;
        } else if (coverageLevel === 'building') {
            return <BuildingLevelTerms />;
        } else if (coverageLevel === 'building-group') {
            return <BuildingGroupTerms />;
        }
    };

    return (
        <PolicyTermsContextProvider
            value={{
                coverageLevel,
                handleCoverageLevelSwitch,
                policyTermsData,
                policyTermsGlossary,
                buildingTermsData,
                buildingTermsGlossary,
                buildingCoverageId,
                buildingGroupCoverageId,
                selectedQuoteIds,
                selectedBuildingCoverageIds,
                handleSelectedQuoteChange,
                handleBuildingCoverageChange,
                handleSelectedBuildingCoverageChange,
                handleBuildingGroupCoverageChange,
                setSelectedQuoteIds,
                setSelectedBuildingCoverageIds,
                buildingGroupTermsData: bgDataQuery.data,
                buildingGroupTermsGlossary: bgGlossaryQuery.data,
            }}
        >
            {renderCoverageLevel()}
        </PolicyTermsContextProvider>
    );
};
