import { useEffect } from 'react';
import _ from 'lodash';

import { LITE_TABLES } from '../../constants/LiteTableConfigs';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useRepriceMutation } from '../../services/apiSlice';
import { getQuoteErrorPanel, VALIDATING } from '../../utils/render-helpers/quotes/quote-validations-helper';
import { ACTION_PANEL_LOCATIONS, calculateRowActions, submissionIsValidatable } from '../submissionlog/Helpers';
import { getTaskSubtitles } from '../../utils/task-helpers';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

// Empty wrapper component for quote repricing on mount
// Ensures reprice is run at least once per quote in table
// TODO: Move this logic to a useEffect once we remove lite tables
const RepriceWrapper = ({ quoteId }: { quoteId: number }) => {
    const dispatch = useAppDispatch();
    const [triggerReprice] = useRepriceMutation({ fixedCacheKey: `${quoteId}` });

    useEffect(() => {
        triggerReprice({ id: quoteId });
    }, [dispatch, quoteId, triggerReprice]);

    return null;
};

export default function CoverageOptionsPage() {
    const dispatch = useAppDispatch();
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const tableData = useAppSelector(state => state.quotes);
    const { currentQuote, currentQuoteId, currentSubmission, currentSubmissionId, oldWebsite, featureFlags } = appContext;
    const [triggerReprice, { isLoading }] = useRepriceMutation({ fixedCacheKey: currentQuoteId ? `${currentQuoteId}` : undefined });
    const enableRms = featureFlags?.enable_rms || false;

    useEffect(() => {
        if (currentQuoteId) {
            triggerReprice({ id: currentQuoteId });
        }
    }, [currentQuoteId, dispatch, triggerReprice]);

    const onRowSelected = (quoteId, replaceInUrl) => {
        appContextFns.onNavigate({ quoteId }, replaceInUrl);
    };

    if (!currentSubmission || !currentQuote || !currentSubmission.quotes) {
        return (
            <LiteTableContainer
                tableData={tableData}
                tableConfig={LITE_TABLES.QUOTES}
                allowsDragging={false}
                hasActionPanel={true}
                selectedRowId={appContext.urlQuoteId}
                onRowSelected={onRowSelected}
                editingDisabled={true}
                tableRows={appContext.currentSubmission?.quotes ?? []}
                panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.COVERAGE_OPTIONS_LIST)}
                getTaskSubtitles={getTaskSubtitles}
                tasksPanel={<AccountTasksPanel />}
            />
        );
    }

    let { primary_quote_id } = currentSubmission;
    const quoteValidationPanel = !isLoading ? getQuoteErrorPanel([currentQuote], currentSubmissionId, oldWebsite) : VALIDATING;
    const panels = [];
    if (quoteValidationPanel && submissionIsValidatable(currentSubmission)) {
        panels.push(quoteValidationPanel);
    }

    return (
        <>
            {appContext.currentSubmission?.quotes?.map(quoteId => (
                <RepriceWrapper key={quoteId} quoteId={quoteId} />
            ))}
            <LiteTableContainer
                tableData={tableData}
                tableConfig={LITE_TABLES.QUOTES}
                allowsDragging={false}
                hasActionPanel={true}
                selectedRowId={appContext.urlQuoteId}
                onRowSelected={onRowSelected}
                bottomPanels={panels}
                editingDisabled={true}
                tableRows={appContext.currentSubmission.quotes}
                panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.COVERAGE_OPTIONS_LIST)}
                getTaskSubtitles={getTaskSubtitles}
                extraCellRenderData={{ primary_quote_id, enableRms }}
                tasksPanel={<AccountTasksPanel />}
            />
        </>
    );
}
