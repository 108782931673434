import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { PolicyTermsFormField } from '../utils/form';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';

export const BuildingLevelOverview = () => {
    return (
        <div className="info-rows tw-grid-cols-[250px_1fr]">
            <PolicyTermsFormField>
                <FormButtonGroup
                    name="is_included_in_quote"
                    items={[
                        { display: 'Include', value: true },
                        { display: 'Exclude', value: false },
                    ]}
                />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormInput name="location_tiv" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} disabled />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormInput name="pricing_tiv" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} disabled />
            </PolicyTermsFormField>
        </div>
    );
};
