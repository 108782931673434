import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { useWindowSize } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { useAppContext } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';
import { ClaimDataType, PolicyClaimDataType } from '../../ts-types/DataTypes';
import { Select } from '@archinsurance-viki/property-jslib/src/components/inputs/v2';
import BasicInput from '@archinsurance-viki/property-jslib/src/components/inputs/v2/BasicInput';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { formatDateString, formatNumberIntl, formatUSAPhoneNumber, VentusNumberFormatOptions } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { noop } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { DefaultAccountLayout } from '../../components/common/DefaultLayout';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { isNullish } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { useSedgwickClaimQuery } from '../../services/endpoints/submission';

const formatCurrency = (value: number, style: VentusNumberFormatOptions['style'] = 'currency') =>
    formatNumberIntl(value, { style, minimumFractionDigits: 0, maximumFractionDigits: 0 });

// TODO: Before moving to jslib, allow for arbitrary choices
const RadioButtonInput = ({ value, name }: { value: 'Y' | 'N'; name: string }) => (
    <form className="tw-flex tw-gap-4">
        <div className="tw-flex tw-gap-1">
            <input type="radio" id={`${name}_yes`} value="Yes" checked={value === 'Y'} name={name} disabled />
            <label htmlFor={`${name}_yes`}>Yes</label>
        </div>

        <div className="tw-flex tw-gap-1">
            <input type="radio" id={`${name}_no`} value="No" checked={value !== 'Y'} name={name} disabled />
            <label htmlFor={`${name}_no`}>No</label>
        </div>
    </form>
);

const ClaimsRadioButtonField = ({ value, name, label }: { value: 'Y' | 'N'; name: string; label: string }) => {
    return (
        <>
            <label>{label}</label>
            <RadioButtonInput value={value} name={name} />
        </>
    );
};

const Section = ({
    children,
    header,
    canCollapse,
    defaultIsCollapsed = false,
}: {
    children?: React.ReactNode;
    header: React.ReactNode;
    subheader?: React.ReactNode;
    canCollapse?: boolean;
    defaultIsCollapsed?: boolean;
}) => {
    const contentRef = useRef<HTMLDivElement>();
    const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);

    const handleHeaderClick = () => {
        if (!canCollapse) {
            return;
        }
        setIsCollapsed(prev => !prev);
    };

    const collapsableHeader = isCollapsed ? <Icon icon="arrow_right" /> : <Icon icon="arrow_drop_down" />;

    const infoRowsElement = contentRef.current?.querySelector('.info-rows');
    const isEmptySection = !contentRef.current?.hasChildNodes() || (infoRowsElement && !infoRowsElement.hasChildNodes());

    return (
        <div className="info-block row-editable tw-p-0 tw-font-sans tw-font-normal">
            <div
                onClick={() => handleHeaderClick()}
                className={cx('tw-px-2 tw-py-1 tw-transition tw-delay-75 header tw-text-base tw-normal-case tw-font-normal tw-justify-normal', {
                    'tw-border-transparent': isCollapsed || isEmptySection,
                    'tw-cursor-pointer': canCollapse,
                })}
            >
                {canCollapse ? collapsableHeader : null}
                <div className="tw-flex tw-gap-4 tw-items-center tw-w-full">
                    <div className="tw-text-base header-text tw-font-semibold tw-w-full tw-font-sans">{header}</div>
                </div>
            </div>
            <div
                ref={contentRef}
                className={cx('tw-text-base tw-transition-[all] tw-duration-300', {
                    'tw-overflow-hidden tw-max-h-0': isCollapsed,
                    'tw-max-h-[2000px]': !isCollapsed,
                })}
            >
                <div className="[&>*:not(#header)]:tw-p-2">{children}</div>
            </div>
        </div>
    );
};

const PolicyPeriodSection = ({ children, data }: { children?: React.ReactNode; data: PolicyClaimDataType }) => (
    <Section
        header={
            <div className="tw-grid tw-grid-cols-[280px_160px_325px_1fr] tw-text-base tw-font-sans tw-font-normal">
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Policy Number:</span>
                    <span>{data.policy_number}</span>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Claims:</span>
                    <span>{data.claims.length || 'No Claims'}</span>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Policy Period:</span>
                    <span>{formatDateString(data.inception_date) + ' - ' + formatDateString(data.expiration_date)}</span>
                </div>
                <div className="tw-flex tw-gap-2">
                    <span className="tw-font-semibold">Incurred:</span>
                    <span>{formatCurrency(data.incurred_per_policy, 'decimal')}</span>
                </div>
            </div>
        }
        canCollapse
    >
        <div className="tw-w-full tw-p-2 tw-mb-2 tw-flex tw-flex-col tw-gap-4">
            <div className="tw-grid tw-grid-cols-[125px_1fr_125px_1fr] tw-items-center tw-gap-x-8 tw-gap-y-2">
                <ClaimsField label="Total Claims" value={data.claims.length || 'No Claims'} />
                <ClaimsField label="Policy Period" value={formatDateString(data.inception_date) + ' - ' + formatDateString(data.expiration_date)} />
                <ClaimsField label="Loss Paid" value={formatCurrency(data.loss_paid_per_policy)} isCurrency />
                <ClaimsField label="Loss Reserve" value={formatCurrency(data.loss_reserve_per_policy)} isCurrency />
                <ClaimsField label="Expense Paid" value={formatCurrency(data.expense_paid_per_policy)} isCurrency />
                <ClaimsField label="Expense Reserve" value={formatCurrency(data.expense_reserve_per_policy)} isCurrency />
                <ClaimsField label="Subrogation" value={formatCurrency(data.subro_recovery_per_policy)} isCurrency />
                <ClaimsField label="Total Incurred" value={formatCurrency(data.incurred_per_policy)} isCurrency />
            </div>
            {children}
        </div>
    </Section>
);

function isOverflowing(element: HTMLTextAreaElement) {
    return element?.scrollHeight > element?.clientHeight ?? false;
}

const ClaimSection = ({ data }: { children?: React.ReactNode; data: ClaimDataType }) => {
    const CLAIM_STATUSES = useAppSelector(state => state.global.CONSTANTS.CLAIM_STATUSES);

    const textareaRef = useRef<HTMLTextAreaElement>();
    const [textareaRows, setTextareaRows] = useState(1); // default 1 row
    const windowSize = useWindowSize();

    useEffect(() => {
        if (!textareaRef.current) {
            return;
        }

        // Modify size of textarea if browser resize makes the text overflow - should always increase size
        // Note: I don't know of a good mechanism of ensuring the textarea size fits the content size, so we never decrease size
        if (isOverflowing(textareaRef.current)) {
            setTextareaRows(prevNumRows => {
                const rowHeight = textareaRef.current.clientHeight / prevNumRows;
                return Math.trunc(textareaRef.current.scrollHeight / rowHeight) + 1;
            });
        }
    }, [windowSize]);

    return (
        <Section
            header={
                <div className="tw-grid tw-grid-cols-[200px_120px_150px_1fr] tw-gap-2 tw-text-base  tw-font-sans tw-font-normal">
                    <div className="tw-flex tw-gap-1">
                        <span className="tw-font-semibold">Claim Number:</span>
                        <span>{data.claim_number}</span>
                    </div>
                    <div className="tw-flex tw-gap-1">
                        <span className="tw-font-semibold">Status:</span>
                        <span>{CLAIM_STATUSES[data.claim_status]}</span>
                    </div>
                    <div className="tw-flex tw-gap-1">
                        <span className="tw-font-semibold">Incurred:</span>
                        <span>{formatCurrency(data.incurred, 'decimal')}</span>
                    </div>
                    <div className="tw-flex tw-gap-1">
                        <span className="tw-font-semibold">Peril:</span>
                        <span className="tw-capitalize">{data.peril.toLowerCase()}</span>
                    </div>
                </div>
            }
            canCollapse
            defaultIsCollapsed
        >
            <div className="tw-grid tw-grid-cols-[125px_1fr_125px_1fr] tw-items-center tw-gap-x-8 tw-gap-y-2">
                <label>Loss Description</label>
                <textarea
                    ref={textareaRef}
                    disabled
                    className="input-div flex tw-items-center tw-px-2 tw-py-1 text-input masked-text-input tw-text-grey-dark tw-bg-grey-extra-light tw-whitespace-normal tw-col-span-3"
                    value={data.loss_description}
                    rows={textareaRows}
                />
                <ClaimsField label="Date of Loss" value={data.claim_loss_date ? formatDateString(data.claim_loss_date) : ''} />
                <ClaimsField label="Date Reported" value={data.claim_open_date ? formatDateString(data.claim_open_date) : ''} />
                <ClaimsField label="Date Closed" value={data.claim_close_date ? formatDateString(data.claim_close_date) : ''} />
                <ClaimsField label="Date Reopened" value={data.reopen_date ? formatDateString(data.reopen_date) : ''} />

                <label>Loss Type</label>
                <Select selectedItem={[data.claim_loss_type, data.claim_loss_type]} items={[]} disabled className="tw-pl-2" onChange={noop} />
                <ClaimsField label="Cause of Loss" value={data.claim_cause_of_loss} />
                <ClaimsField label="Cat Event Name" value={data.claim_cat_event_name} />
                <ClaimsField label="Deductible" value={data.claim_deductible} />
                <ClaimsField label="Loss Paid" value={formatCurrency(data.loss_paid)} isCurrency />
                <ClaimsField label="Loss Reserve" value={formatCurrency(data.loss_reserve)} isCurrency />
                <ClaimsField label="Expense Paid" value={formatCurrency(data.expense_paid)} isCurrency />
                <ClaimsField label="Expense Reserve" value={formatCurrency(data.expense_reserve)} isCurrency />
                <ClaimsField label="Subrogation" value={formatCurrency(data.subro_recovery)} isCurrency />
                <ClaimsField label="Total Incurred" value={formatCurrency(data.incurred)} isCurrency />
                <ClaimsRadioButtonField label="Litigation" value={data.litigation_indicator} name="litigation" />
                <ClaimsRadioButtonField label="Coverage Issues" value={data.coverage_issue_indicator} name="coverage_issues" />
                <ClaimsRadioButtonField label="Claim Denied" value={data.claim_denied} name="claim_denied" />
                <ClaimsRadioButtonField label="CRN" value={data.crn} name="crn" />
                <ClaimsRadioButtonField label="Public Adjuster" value="Y" name="public_adjuster" />
                <ClaimsRadioButtonField label="AOB" value="N" name="aob" />
                <ClaimsField label="Adjuster Name" value={data.adjuster_name} />
                <ClaimsField label="Adjuster Email" value={data.adjuster_email} />
                <ClaimsField label="Adjuster Phone" value={data.adjuster_phone ? formatUSAPhoneNumber(data.adjuster_phone) : ''} />
            </div>
        </Section>
    );
};

const ClaimsField = ({
    label,
    value,
    isCurrency,
    inputClassName,
    disabled = true,
}: {
    label?: string;
    value: unknown;
    inputClassName?: string;
    isCurrency?: boolean;
    disabled?: boolean;
}) => (
    <>
        <label>{label}</label>
        <BasicInput
            value={isNullish(value) && isCurrency ? '-' : (value as string)}
            disabled={disabled}
            className={cx('tw-px-2', inputClassName)}
            dollarBefore={false}
            maskOptions={isCurrency ? { ...MASKS['CURRENCY_INTEGER'], numeralPositiveOnly: false } : undefined}
            rightAlignText={isCurrency}
        />
    </>
);

export const ClaimsPage = () => {
    const { currentSubmissionId } = useAppContext();
    const { data: sedgwickClaim, isError } = useSedgwickClaimQuery({ submissionId: currentSubmissionId }, { skip: !currentSubmissionId });

    if (isError) {
        return (
            <DefaultAccountLayout>
                <div className="tw-flex-1 tw-flex tw-px-8 tw-py-4">
                    <span className="tw-flex tw-gap-1 tw-items-center tw-h-fit">
                        <Icon className="tw-text-red" icon="error" />
                        {`Could not load claims for submission ${currentSubmissionId}`}
                    </span>
                </div>
            </DefaultAccountLayout>
        );
    }

    if (!sedgwickClaim) {
        return (
            <DefaultAccountLayout>
                <div className="tw-flex-1 tw-flex tw-items-center tw-justify-center">
                    <Spinner size="xl" />
                </div>
            </DefaultAccountLayout>
        );
    }

    const {
        total_claims,
        total_loss_paid,
        total_loss_reserve,
        total_expense_paid,
        total_expense_reserve,
        total_subro_recovery,
        total_incurred,
        inception_date,
        expiration_date,
        policy_claims,
    } = sedgwickClaim;

    let policy_period = '';
    if (inception_date) {
        policy_period = formatDateString(inception_date) + ' - ' + formatDateString(expiration_date);
    }

    return (
        <DefaultAccountLayout>
            <div className="[&_*]:tw-text-left [&>*:not(#header)]:tw-max-w-[1400px]">
                <div id="header" className="tw-w-full tw-text-black tw-bg-gray-100 tw-p-4">
                    <h2 className="tw-text-[24px]">Claims</h2>
                </div>

                <div className="tw-sticky tw-top-0 tw-z-10 tw-pb-2 tw-bg-white">
                    <div className="tw-py-2 tw-px-4">
                        <Section header="Total Claims for All Years">
                            <div className="tw-grid tw-grid-cols-[125px_1fr_125px_1fr] tw-items-center tw-gap-x-8 tw-gap-y-2 [&>*:nth-child(even)]:tw-ml-[-20px]">
                                <ClaimsField label="Total Claims" value={total_claims > 0 ? total_claims : 'No Claims'} />
                                <ClaimsField label="Policy Period" value={policy_period} />
                                <ClaimsField label="Loss Paid" value={formatCurrency(total_loss_paid)} isCurrency />
                                <ClaimsField label="Loss Reserve" value={formatCurrency(total_loss_reserve)} isCurrency />
                                <ClaimsField label="Expense Paid" value={formatCurrency(total_expense_paid)} isCurrency />
                                <ClaimsField label="Expense Reserve" value={formatCurrency(total_expense_reserve)} isCurrency />
                                <ClaimsField label="Subrogation" value={formatCurrency(total_subro_recovery)} isCurrency />
                                <ClaimsField label="Total Incurred" value={formatCurrency(total_incurred)} isCurrency />
                            </div>
                        </Section>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col tw-w-full tw-px-4 tw-py-2 tw-overflow-auto tw-gap-4">
                    {policy_claims.map((policyClaim: PolicyClaimDataType) => (
                        <PolicyPeriodSection key={policyClaim.policy_number} data={policyClaim}>
                            {policyClaim.claims?.map((claim: ClaimDataType) => (
                                <ClaimSection key={claim.claim_number} data={claim} />
                            ))}
                        </PolicyPeriodSection>
                    ))}
                </div>
            </div>
        </DefaultAccountLayout>
    );
};
