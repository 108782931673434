import * as React from 'react';

import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    setModalState: ({ isTestServer }: { isTestServer: boolean }) => void;
    ENV: Types.Env;
    modalState: Record<string, any>;
};

class SelfApprovalWarningBody extends React.Component<bodyPropTypes> {
    render(): React.ReactNode {
        return (
            <div className="standard-modal-content">
                <div style={{ paddingBottom: '10px' }}>There are unrequested approvals on this submission.</div>
                <div style={{ paddingBottom: '10px' }}>If you approve, you will display as both the approver and the requester.</div>
                <div>Are you sure you want to proceed?</div>
            </div>
        );
    }
}

type footerPropTypes = {
    quoteId: number;
    submissionId: number;
    onProceed: any;
    onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
    approval_hash: string;
};

class SelfApprovalWarningFooter extends React.Component<footerPropTypes> {
    onProceed = () => {
        this.props.onProceed(this.props.quoteId, this.props.submissionId, this.props.approval_hash);
    };
    render() {
        let { onClose } = this.props;

        return (
            <div className="button-row" style={{ textAlign: 'center' }}>
                <button key="proceed" className="green" onClick={this.onProceed}>
                    Proceed
                </button>
                <div key="s1" className="spacer wide" />
                <button key="cancel" className="red" onClick={onClose}>
                    Cancel
                </button>
            </div>
        );
    }
}

let modalTypeObj = {
    Body: SelfApprovalWarningBody,
    Header: DefaultHeaderStatic('Self-approval Warning'),
    Footer: SelfApprovalWarningFooter,
    className: 'self-approval-warning-model',
};

registerType(VIRA_MODAL_TYPES.SELF_APPROVAL_WARNING, modalTypeObj);
export default modalTypeObj;
