import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import PanelView from '../PanelView';
import { useGetTargetMultipleAuditHistoryQuery } from '../../services/apiSlice';

const HistoryRow = (props: any) => {
    return (
        <>
            <tr>
                <td>{props.rule_name}</td>
                <td>{moment(props.created).format('MM/DD/YYYY @ HH:mm:ss')}</td>
                <td>{props.description}</td>
            </tr>
        </>
    );
};

const TargetMultipleHistoryPage = () => {
    const { data: targetMultipeHistory = [] } = useGetTargetMultipleAuditHistoryQuery({});
    return (
        <PanelView topPanelHeader="Target Multiples" hasLeftPanel={true}>
            <div className="main-content-center">
                <div className="ventus-content">
                    <table>
                        <tr>
                            <th>Rule</th>
                            <th>Date Modified</th>
                            <th>Notes</th>
                        </tr>
                        {targetMultipeHistory.map((h, idx) => {
                            return <HistoryRow key={idx} {...h} />;
                        })}
                    </table>
                </div>
            </div>
        </PanelView>
    );
};

export default TargetMultipleHistoryPage;
