// THIS IS PROVIDED TO JSLIB VIA index.js so that we can call pusher event actions from jslib.
import {
    handleAccountRefresh,
    handleAccountRevalidation,
    handleDocumentPreview,
    handleTransactionRefresh,
    handleRowDirty,
    handleGridRefresh,
    handleRowCreatePush,
    handleDocumentDeletePush,
    handleDocumentCreatePush,
} from '../actions/PusherEventActions';

export default {
    'transaction-refresh': handleTransactionRefresh,
    'account-refresh': handleAccountRefresh,
    'account-revalidation': handleAccountRevalidation,
    'document-preview': handleDocumentPreview,
    'row-dirty': handleRowDirty,
    'grid-refresh': handleGridRefresh,
    'row-create': handleRowCreatePush,
    'document-delete': handleDocumentDeletePush,
    'document-create': handleDocumentCreatePush,
};
