import { FormFormatSelection } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormFormatSelection';
import { useAppSelector } from '../../../hooks/redux';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { BuildingTermsFormValues, PolicyTermsFormField, selectDeductibleFormatsWithDefault, selectSortedAlphabeticalChoices } from '../utils/form';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { DeductibleFormatsWithDefaultType, MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { useWatch } from 'react-hook-form';

export const EarthquakeCoverages = () => {
    const EARTHQUAKE_DED_PERCENT_AMT = useAppSelector(state => state.global.CONSTANTS.EARTHQUAKE_DED_PERCENT_AMT);
    const DEDUCTIBLE_APPLICABILITY = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'DEDUCTIBLE_APPLICABILITY'));
    const deductibleFormats = useAppSelector(state => selectDeductibleFormatsWithDefault(state));
    const policyEarthquakeDeductibleFmt = useWatch<BuildingTermsFormValues, 'policy_earthquake_deductible_fmt'>({ name: 'policy_earthquake_deductible_fmt' });
    return (
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormInput name="earthquake_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
            </PolicyTermsFormField>
            <PolicyTermsFormField>
                <FormSelect<BuildingTermsFormValues> name="earthquake_deductible_basis" selections={DEDUCTIBLE_APPLICABILITY} disabled />
            </PolicyTermsFormField>
            <PolicyTermsFormField>
                <FormFormatSelection<DeductibleFormatsWithDefaultType, BuildingTermsFormValues>
                    name="earthquake_deductible_fmt"
                    formats={deductibleFormats}
                    render={format =>
                        format === null ? (
                            policyEarthquakeDeductibleFmt === 'PERCENTAGE' ? (
                                <PolicyTermsFormField hideLabel>
                                    <FormSelect<BuildingTermsFormValues> name="earthquake_deductible_pct_amt" selections={EARTHQUAKE_DED_PERCENT_AMT} />
                                </PolicyTermsFormField>
                            ) : (
                                <FormInput<BuildingTermsFormValues>
                                    name="earthquake_deductible_dollar_amt"
                                    dollarBefore
                                    maskOptions={MASKS['CURRENCY_INTEGER']}
                                />
                            )
                        ) : format === 'PERCENTAGE' ? (
                            <PolicyTermsFormField hideLabel>
                                <FormSelect<BuildingTermsFormValues> name="earthquake_deductible_pct_amt" selections={EARTHQUAKE_DED_PERCENT_AMT} />
                            </PolicyTermsFormField>
                        ) : (
                            <PolicyTermsFormField hideLabel>
                                <FormInput<BuildingTermsFormValues>
                                    name="earthquake_deductible_dollar_amt"
                                    dollarBefore
                                    maskOptions={MASKS['CURRENCY_INTEGER']}
                                />
                            </PolicyTermsFormField>
                        )
                    }
                    className="data-entry-btn-wrapper dollar-percent-labels"
                />
            </PolicyTermsFormField>
        </div>
    );
};
