import moment from 'moment';

import { SUBMISSION_STATUS } from './Submission';
import { DEFAULT_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { sortSubmissionsForSwimlanes } from '../utils/swimlanes-helpers';
import SwimTileContainer from '../components/submissionlog/SubmissionSwimTileContainer';
import { GridSwimlaneType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

const SWIMLANE_CONFIGS: Record<string, GridSwimlaneType> = {
    SUBMISSION_LOG: {
        useGlobalSearch: true,
        actions: {
            onClick: (props: any) => {
                return props['onSendSubmissionToScrub'](props.id);
            },
            onDblClick: (props: any) => {
                window.location.href = `/submissions/${props.id}/current/documents`;
            },
        },
        initialState: (props: any) => {
            return {
                fields: props.swimlanesOpts.filterFields,
                ignoreStatuses: props.swimlanesOpts.statuses,
                data: [],
                underwriterId: props.me.id,
                effectiveDate: moment(new Date()).format(DEFAULT_DATE_FORMAT),
                underwriterIdOverride: props.me.id,
                searchTerm: '',
                filteredData: [],
                boundSubmissions: [],
                uwReviewSubmissions: [],
                enteringDataSubmissions: [],
                enteringDataRushSubmissions: [],
                uwReviewActiveSubmissions: [],
                UNDERWRITERS: props.CONSTANTS.UNDERWRITERS.filter((u: any) => !u[2]),
            };
        },
        toggleButtonOpts: {
            fnIsActive: (props: any) => {
                return !(props.uiState.tableStates[props.tableConfig.tableName] || {}).useSwimlanes;
            },
            activeLabel: 'Grid',
            inActiveLabel: 'Swimlanes',
        },
        onHandleChange: (key: string, value: any) => {
            let update: any = { [key]: value };
            if (key === 'underwriterIdOverride') {
                update['underwriterId'] = value || null;
            }
            return update;
        },
        getQueryParameter: (options: any) => {
            return {
                underwriter_id: options.underwriterId ? `${options.underwriterId}` : '',
                policy_inception_date__gte: options.effectiveDate ? `${options.effectiveDate}` : '',
                status_in: options.ignoreStatuses.join(','),
                fields: options.fields.join(','),
                limit: 10000,
                is_latest: true,
            };
        },
        swimTile: SwimTileContainer,
        filterData: (params: Record<string, any>, dataFromApi: Record<string, any>[], state: Record<string, any>) =>
            sortSubmissionsForSwimlanes(params, dataFromApi, state),
        lanes: [
            {
                title: 'Cleared Submissions',
                stateName: 'boundSubmissions',
                filters: { status: SUBMISSION_STATUS.ENTERING_DATA, is_rush: false, has_active_quotes: null },
            },
            {
                title: 'Sent To Scrub',
                stateName: 'uwReviewSubmissions',
                filters: { status: SUBMISSION_STATUS.ENTERING_DATA, is_rush: true, has_active_quotes: null },
            },
            {
                title: 'UW Review',
                stateName: 'uwReviewActiveSubmissions',
                filters: { status: SUBMISSION_STATUS.UW_REVIEW, is_rush: null, has_active_quotes: false },
            },
            {
                title: 'Outstanding Quotes',
                stateName: 'enteringDataSubmissions',
                filters: { status: SUBMISSION_STATUS.UW_REVIEW, is_rush: null, has_active_quotes: true },
            },
            {
                title: 'Bound',
                stateName: 'enteringDataRushSubmissions',
                filters: { status: SUBMISSION_STATUS.BOUND, is_rush: null, has_active_quotes: null },
            },
        ],
        statuses: [
            SUBMISSION_STATUS.ENTERING_DATA,
            SUBMISSION_STATUS.BOUND,
            SUBMISSION_STATUS.ENDORSEMENT_REVIEW,
            SUBMISSION_STATUS.AWAITING_AGENT,
            SUBMISSION_STATUS.UW_REVIEW,
            SUBMISSION_STATUS.NEW_SUBMISSION,
        ],
        filterFields: [
            'id',
            'account_id',
            'status',
            'is_rush',
            'is_bound',
            'insured_name',
            'effective_date',
            'underwriter_id',
            'status_display',
            'policy_inception_date',
            'account_broker_name',
            'has_active_quotes',
            'underwriter_full_name',
        ],
    },
};

export default SWIMLANE_CONFIGS;
