import { useState, ReactNode, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import BasicInput from '@archinsurance-viki/property-jslib/src/components/inputs/v2/BasicInput';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultFooter, DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Badge } from '@archinsurance-viki/property-jslib/src/components/widgets/Badge';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { Types } from '../../../../ts-types/viki-types';
import { useDefaultPolicyDocEmailInfoQuery, useSendPolicyDocumentToBrokerMutation } from '../../../../services/endpoints/policy-issuance';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { SEND_POLICY_DOCS_TASK_TYPES } from '../../../../constants/PolicyServicesConstants';

type EmailInputProps = { clearErrorMessage: () => void; errorMessage: string; onAddEmail: (emailAddress: string) => void };
const EmailInput = ({ clearErrorMessage, errorMessage, onAddEmail }: EmailInputProps) => {
    const [emailAddress, setEmailAddress] = useState('');
    const buttonRef = useRef<HTMLInputElement>();
    return (
        <>
            <div className="tw-flex tw-gap-1">
                <BasicInput
                    className="tw-flex-1 tw-px-1"
                    value={emailAddress}
                    onBlur={() => clearErrorMessage()}
                    onChange={e => {
                        if (errorMessage) {
                            clearErrorMessage();
                        }
                        setEmailAddress(e.target.value);
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            buttonRef.current?.click();
                        }
                    }}
                />
                <BasicButton
                    className="tw-px-2 tw-py-1"
                    disabled={!emailAddress}
                    ref={buttonRef}
                    onClick={e => {
                        e.preventDefault();
                        onAddEmail(emailAddress);
                        setEmailAddress('');
                    }}
                >
                    Add Email
                </BasicButton>
            </div>
            {errorMessage && <span className="tw-text-red-warn tw-text-sm">{errorMessage}</span>}
        </>
    );
};

type ModalSectionProps = {
    children: ReactNode;
    onReset?: () => void;
    showReset?: boolean;
    title: string;
};

const ModalSection = ({ children, onReset, showReset, title }: ModalSectionProps) => (
    <div>
        <div className="tw-mb-0.5 tw-flex tw-gap-2">
            <h3>{title}</h3>
            {showReset && (
                <span className="tw-cursor-pointer tw-text-md tw-text-blue-primary" onClick={onReset}>
                    Reset
                </span>
            )}
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>
    </div>
);

const LoadingOrErrorContent = ({ error, onClose }: { error?: string; onClose: footerProps['onClose'] }) => (
    <>
        <div className="tw-flex-1 tw-flex tw-justify-center tw-items-center">{error ? error : <Spinner size="xl" />}</div>
        <DefaultFooter onClose={onClose} modalData={{}} className="tw-p-0" />
    </>
);

type SendPolicyDocsProps = {
    onSubmit: (formValues: FormValues) => void;
    defaultSendTo: string[];
    defaultSubject: string;
    defaultMessageBody: string;
} & footerProps;

type FormValues = {
    sendTo: string[];
    subject: string;
    messageBody: string;
};

const SendPolicyDocs = ({ defaultSendTo, defaultMessageBody, defaultSubject, onSubmit, ...modalProps }: SendPolicyDocsProps) => {
    const [emailError, setEmailError] = useState('');

    const { control, formState, handleSubmit, register, resetField, setValue, watch } = useForm<FormValues>({
        defaultValues: {
            sendTo: defaultSendTo,
            subject: defaultSubject,
            messageBody: defaultMessageBody,
        },
    });

    const { dirtyFields } = formState;
    const emailAddresses = watch('sendTo');
    const messageBody = watch('messageBody');
    const addEmailAddress = (emailAddress: string) => {
        if (emailAddresses.includes(emailAddress)) {
            setEmailError('Error: Unable to add duplicate email address');
            return;
        }
        setValue('sendTo', [...emailAddresses, emailAddress], { shouldDirty: true });
    };
    const removeEmailAddress = (emailAddress: string) =>
        setValue(
            'sendTo',
            emailAddresses.filter(value => value !== emailAddress),
            { shouldDirty: true }
        );

    return (
        <form className="tw-flex tw-flex-col tw-gap-4">
            <ModalSection title="Send To" showReset={!!dirtyFields.sendTo} onReset={() => setValue('sendTo', defaultSendTo, { shouldDirty: true })}>
                <EmailInput
                    onAddEmail={newEmailAddress => addEmailAddress(newEmailAddress)}
                    errorMessage={emailError}
                    clearErrorMessage={() => setEmailError('')}
                />
                <div className="tw-flex tw-flex-wrap tw-gap-1 tw-max-h-[50px] tw-overflow-auto">
                    {emailAddresses.length > 0 &&
                        emailAddresses.map(emailAddress => (
                            <Badge key={emailAddress} className="tw-m-0 tw-w-max" title={emailAddress} onRemove={() => removeEmailAddress(emailAddress)} />
                        ))}
                </div>
            </ModalSection>
            <ModalSection title="Subject" showReset={!!dirtyFields.subject} onReset={() => resetField('subject')}>
                <Controller control={control} name="subject" render={({ field }) => <BasicInput className="tw-px-1" {...field} />} />
            </ModalSection>
            <ModalSection title="Body" showReset={!!dirtyFields.messageBody} onReset={() => resetField('messageBody')}>
                <textarea rows={10} className="tw-p-1 tw-w-full tw-border tw-border-grey-border" {...register('messageBody')} />
            </ModalSection>
            <DefaultFooter
                className="tw-p-0"
                disableConfirm={!messageBody || emailAddresses.length === 0}
                modalData={{
                    onOk: handleSubmit(onSubmit),
                    dismissButtonTitle: 'Close',
                    confirmLabelTitle: 'Send',
                }}
                {...modalProps}
            />
        </form>
    );
};

type footerProps = {
    onClose: (close: boolean) => void;
    isDestructive?: boolean;
    modalData?: unknown;
};

type ModalProps = {
    ENV: Types.Env;
    modalState: Record<string, unknown>;
    modalData?: Record<string, unknown>;
} & footerProps;

export const PolicyDocsWrapper = ({
    submissionId,
    documentId,
    taskType,
    onClose,
    ...modalProps
}: ModalProps & { submissionId: number; documentId: number; taskType: (typeof SEND_POLICY_DOCS_TASK_TYPES)[number] }) => {
    const { data, error } = useDefaultPolicyDocEmailInfoQuery({ submissionId, documentId }, { skip: !submissionId });
    const [triggerSendDocument] = useSendPolicyDocumentToBrokerMutation();

    const onSubmit = ({ sendTo, subject, messageBody }: FormValues) => {
        triggerSendDocument({ submissionId, documentId, sendTo, subject, messageBody });
    };

    if (!SEND_POLICY_DOCS_TASK_TYPES.includes(taskType)) {
        throw new Error('Invalid task type for sending policy documents.');
    }

    if (!data) {
        return (
            <div className="tw-flex tw-flex-col tw-p-4 tw-w-[650px] tw-h-[480px]">
                <LoadingOrErrorContent error={error ? 'An error occurred.' : null} onClose={onClose} />
            </div>
        );
    }

    return (
        <div className="tw-flex tw-flex-col tw-p-4 tw-w-[650px] tw-h-[480px]">
            <SendPolicyDocs
                onSubmit={onSubmit}
                defaultSendTo={data.send_to}
                defaultSubject={data.subject}
                defaultMessageBody={data.message_body}
                onClose={onClose}
                {...modalProps}
            />
        </div>
    );
};

const modalTypeObj = {
    Body: PolicyDocsWrapper,
    Header: DefaultHeaderStatic('Send Policy Documents'),
};
registerType(VIRA_MODAL_TYPES.SEND_POLICY_DOCS, modalTypeObj);
export default modalTypeObj;
