import * as React from 'react';

const SelectedDate = (props: { date: Date }) => (
    <div className="header-stats">
        <label>Year : </label>
        <span>{props.date.getFullYear()}</span>

        <label className="adjustments">Date: </label>
        <span>{`${props.date.toLocaleString('default', { month: 'short', day: 'numeric' })}`}</span>
    </div>
);

interface PanelAreaProps {
    selectedDate: Date;
    children: React.ReactNode;
}

export default function PanelArea({ selectedDate, children }: PanelAreaProps) {
    const filterAreas = React.Children.map(children, (child, index) => (
        <div className="pml-filter-area" key={index}>
            {child}
        </div>
    ));
    const glNum = filterAreas.length >= 2 && filterAreas.length <= 9 ? `gl-${filterAreas.length}` : 'gl-1-fill';
    return (
        <div className="grid-layout gl-pml">
            <fieldset className="panel-inline">
                <h5 className="panel-label-header w-100 no-border-right">
                    Data Filters
                    {selectedDate ? <SelectedDate date={selectedDate} /> : null}
                </h5>
                <div className={`panel-area grid-layout ${glNum}`}>{filterAreas}</div>
            </fieldset>
        </div>
    );
}
