import { CattoolsExportParams } from '../../ts-types/ApiTypes';
import api from '../apiSlice';

export const cattoolsApi = api.injectEndpoints({
    endpoints: builder => ({
        exportCatData: builder.query<unknown, CattoolsExportParams>({
            query: params => ({ url: `v2/cattools_export`, params, method: 'GET' }),
        }),
    }),
});

export const { useLazyExportCatDataQuery } = cattoolsApi;
