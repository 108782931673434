import * as React from 'react';

interface PanelAreaProps {
    children: React.ReactNode;
    actions?: React.ReactNode[];
}

export default function CattoolsPanelArea({ children, actions }: PanelAreaProps) {
    const filterAreas = React.Children.map(
        children,
        (child, index) =>
            child && (
                <div className="pml-filter-area" key={index}>
                    {child}
                </div>
            )
    );
    return (
        <div className="grid-layout gl-pml fit-content">
            <fieldset className="panel-inline">
                <h5 className="panel-label-header w-100 no-border-right tw-flex">
                    <span className="tw-flex-1">Data Filters</span>
                    {actions}
                </h5>
                <div className="panel-area grid-layout tw-gap-0">{filterAreas}</div>
            </fieldset>
        </div>
    );
}
