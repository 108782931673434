import * as React from 'react';
import * as _ from 'lodash';

import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import DefaultModal from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    modalData: Record<string, any>;
};

class MovedSubmissionBody extends React.Component<bodyPropTypes> {
    renderText(sid, newStatus, warnings = []) {
        if (warnings.length > 0) {
            return (
                <div style={{ padding: '30px 30px 60px' }}>
                    <div style={{ textAlign: 'center', padding: '0 0 30px 0' }}>
                        <b>Submission {sid}</b> has been successfully moved to <b>{newStatus}</b>
                    </div>
                    <div style={{ padding: '0 0 15px 0' }}>Warnings:</div>
                    {_.map(warnings, warning => {
                        return <div>{warning}</div>;
                    })}
                </div>
            );
        } else {
            return (
                <div style={{ padding: '30px 30px 60px', textAlign: 'center' }}>
                    <b>Submission {sid}</b> has been successfully moved to <b>{newStatus}</b>
                </div>
            );
        }
    }

    render() {
        let { sid, newStatus, warnings } = this.props.modalData;

        return this.renderText(sid, newStatus, warnings);
    }
}

let modalTypeObj = {
    Body: MovedSubmissionBody,
    Header: DefaultHeaderStatic('YOUR SUBMISSION HAS BEEN MOVED'),
    Footer: DefaultModal.Footer,
};
registerType(VIRA_MODAL_TYPES.MOVED, modalTypeObj);
export default modalTypeObj;
