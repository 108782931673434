import classNames from 'classnames';

import ActionPanel from '@archinsurance-viki/property-jslib/src/components/panels/ActionPanel';
import BottomPanelContainer from '@archinsurance-viki/property-jslib/src/containers/panels/BottomPanelContainer';
import { UiStateType } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { useAppContext } from '../../hooks/context';
import { useQuoteId } from '../../hooks/quotes';
import { ACTION_PANEL_LOCATIONS, calculateRowActions, submissionIsValidatable } from '../../components/submissionlog/Helpers';
import { getQuoteErrorPanel } from '../../utils/render-helpers/quotes/quote-validations-helper';
import { useRepriceMutation } from '../../services/apiSlice';
import { useValidateSubmissionQuery } from '../../services/endpoints/submission';
import { AppContextFnsType, AppContextType } from '../../ts-types/AppTypes';
import { QuoteResult } from '../../ts-types/ApiTypes';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';
import { QuoteType } from '../../ts-types/DataTypes';
import React from 'react';

type PricingPageWrapperProps = {
    children: React.ReactNode;
    hideHeader?: boolean;
    panelActionsProps: {
        appContext: AppContextType;
        appContextFns: AppContextFnsType;
    };
    quoteData?: QuoteResult | null;
    slidePanelOpen: boolean;
    uiState: UiStateType;
    header?: React.ReactNode;
};

const ValidationsBottomTab = () => {
    const quoteId = useQuoteId();
    const { currentQuote, currentSubmission, oldWebsite } = useAppContext();
    const [_triggerReprice, { isLoading: isRepricing, isUninitialized: needsReprice }] = useRepriceMutation({ fixedCacheKey: `${quoteId}` });
    const { data: validationData, isFetching } = useValidateSubmissionQuery(
        { submissionId: currentSubmission?.id, quoteId },
        { skip: !currentSubmission?.id || !quoteId || isRepricing || needsReprice }
    );
    const quoteValidationData =
        validationData && !isFetching ? { reprice: { validation_messages: validationData }, description: currentQuote?.description ?? '', id: quoteId } : {};

    let panels = [];
    let errorPanel = getQuoteErrorPanel([{ ...quoteValidationData, id: quoteId } as QuoteType], currentSubmission?.id ?? -1, oldWebsite);
    if (errorPanel && submissionIsValidatable(currentSubmission)) {
        panels.push(errorPanel);
    }

    return panels.length > 0 ? <BottomPanelContainer key="bottom-panel-container" panelName="BOTTOM_PRICINGV2" hasPlaceholder panels={panels} /> : null;
};

export const PricingPageWrapper = ({ children, hideHeader, panelActionsProps, slidePanelOpen, uiState, header = null }: PricingPageWrapperProps) => (
    <>
        <div className="main-content-center pricing-wrapper" key="pricing-page">
            <div className="flex h-100">
                <div className="flex column flex-1">
                    <AccountTasksPanel />
                    <div className="viki-tasks">{hideHeader ? null : header}</div>
                    <div className="info-block-wrapper flex-1">{children}</div>
                    <ValidationsBottomTab />
                </div>
            </div>
        </div>
        <div key="panels" className={classNames('main-right-panel main-panel', { closed: !slidePanelOpen })}>
            <ActionPanel
                uiState={uiState}
                panelActions={calculateRowActions(panelActionsProps, ACTION_PANEL_LOCATIONS.COVERAGE_OPTIONS_LIST)}
                openPanels={uiState.openPanels}
            />
        </div>
    </>
);
