import _ from 'lodash';
import { isObject, isArray } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { UpdateVentusCarriersOverridesType, VentusCarriersOverrideType } from '../services/utils';
import { CarrierPool } from './DataTypes';

export const isVentusCarriersOverride = (data: unknown): data is VentusCarriersOverrideType => {
    if (!isObject(data)) {
        return false;
    }
    return 'pool_id' in data && 'carrier_id' in data && 'override_participation_pct' in data;
};

export const isUpdateVentusCarriersOverrides = (data: unknown): data is UpdateVentusCarriersOverridesType => {
    if (!isArray(data)) {
        return false;
    }
    return data.every(val => isVentusCarriersOverride(val));
};

export const isVentusCarrierPool = (data: unknown): data is CarrierPool<'VENTUS'> => {
    return isObject(data) && 'pool_name' in data && data.pool_name === 'VENTUS';
};
