import cx from 'classnames';
import React from 'react';

export const BuildingGroupGrid = () => {
    const dummyData = [
        {
            id: 1,
            peril_type: 'Wind',
            group_name: 'Villas',
            grouped_by: 'Country, State',
            count: '15',
            tiv: '$25,000,000',
            description: 'This building group comprises located with a residential area.',
        },
        {
            id: 2,
            peril_type: 'Earthquake',
            group_name: 'mall',
            grouped_by: 'Country, Construction',
            count: '15',
            tiv: '$25,000,000',
            description: 'This building group comprises located with a residential area.',
        },
        {
            id: 3,
            peril_type: 'Earthquake',
            group_name: 'Apartments',
            grouped_by: 'Country, State',
            count: '15',
            tiv: '$25,000,000',
            description: 'This building group comprises located with a residential area.',
        },
        {
            id: 4,
            peril_type: 'Wind',
            group_name: 'Office park',
            grouped_by: 'State, Occupancy',
            count: '15',
            tiv: '$25,000,000',
            description: 'This building group comprises located with a residential area.',
        },
        {
            id: 5,
            peril_type: 'Earthquake',
            group_name: 'Villas',
            grouped_by: 'Country, Occupancy, State',
            count: '15',
            tiv: '$25,000,000',
            description: 'This building group comprises located with a residential area.',
        },
    ];

    const renderBuildingData = buildingData => {
        return (
            <>
                <a
                    className={cx('select-cell material-icons tw-select-none', {
                        active: true,
                        'hover:tw-text-grey-checkbox': false,
                    })}
                    title="Select"
                    onClick={() => {
                        console.log('clicked');
                    }}
                >
                    done
                </a>
                <div className="data-cell tw-w-1/12">{buildingData.peril_type}</div>
                <div className="data-cell tw-w-2/12">{buildingData.group_name}</div>
                <div className="data-cell tw-w-3/12">{buildingData.grouped_by}</div>
                <div className="data-cell tw-w-1/12">{buildingData.count}</div>
                <div className="data-cell tw-w-1/12">{buildingData.tiv}</div>
                <div className="data-cell tw-w-auto">{buildingData.description}</div>
            </>
        );
    };

    return (
        <>
            <div className="tw-flex-1 tw-mb-2 tw-font-semibold">Building Groups</div>
            <div className="grid-layout settings panelgeneral w-100">
                <div className="table-header">
                    <a
                        className={cx('select-cell material-icons tw-select-none', {
                            active: true,
                            'hover:tw-text-grey-checkbox': false,
                        })}
                        title="Select All"
                        onClick={() => {
                            console.log('clicked');
                        }}
                    >
                        done
                    </a>
                    <div className="data-cell tw-w-1/12">Peril Type</div>
                    <div className="data-cell tw-w-2/12">Group Name</div>
                    <div className="data-cell tw-w-3/12">Grouped By</div>
                    <div className="data-cell tw-w-1/12">Building Count</div>
                    <div className="data-cell tw-w-1/12">Pricing TIV</div>
                    <div className="data-cell tw-w-auto">Group Description</div>
                </div>

                {dummyData.map(data => (
                    <div key={data.id} className="flex fd-r">
                        {renderBuildingData(data)}
                    </div>
                ))}
            </div>
        </>
    );
};
