import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { getTableAggregates_server } from '@archinsurance-viki/property-jslib/src/actions/TableActions';

import {
    getQuoteCheckboxes_server,
    updateBindingRequirements_server,
    updateBuildingQuoteConditions_server,
    updateQuote_server,
} from '../../actions/QuoteActions';

import BindingRequirementsApp from './BindingRequirementsApp';
import QuoteConditionsApp from './QuoteConditionsApp';
import ActionPanel from '@archinsurance-viki/property-jslib/src/components/panels/ActionPanel';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../../components/submissionlog/Helpers';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { getQuoteConditionAggregates } from '../../utils/render-helpers/quotes/quote-conditions-helpers';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import { getBuildingQuoteConditionUpdate } from '../../utils/quote-helpers';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

const OVERVIEW_ACTIONS_PANEL = 'OVERVIEW_ACTIONS';

export default function QuotePipelinePage() {
    // TODO: create separate containers for the sections of this page and move getAggregates into the correct container and initialize in its child
    // as is done with BuildingQuoteConditions.
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector(state => state.uiState);
    const showBindingRequirementsV1 = useAppSelector(state => !state.global.featureFlags?.binding_requirements_v2);

    const { currentQuoteId } = appContext;
    const bqcTableConfig = useMemo(() => {
        const apiParams = { quote_id: currentQuoteId };
        const apiParamsKey = getApiParamsKey(apiParams);
        return { ...PAGED_TABLES.BUILDING_QUOTE_CONDITIONS, apiParams, apiParamsKey };
    }, [currentQuoteId]);

    const buildingQuoteConditions = useAppSelector(state => state.buildingQuoteConditions[bqcTableConfig.apiParamsKey]);
    const { buildingCount, buildingQuoteConditionAggregates } = useMemo(
        () => ({
            buildingCount: buildingQuoteConditions?.rows.length ?? null,
            buildingQuoteConditionAggregates: buildingQuoteConditions?.aggregates ?? null,
        }),
        [buildingQuoteConditions]
    );

    const quoteCheckboxes = useAppSelector(state => state.quoteCheckboxes[currentQuoteId]);
    const { bindingRequirementsData, quoteConditionsData } = useMemo(
        () => ({
            bindingRequirementsData: quoteCheckboxes?.binding_requirements ?? {},
            quoteConditionsData: quoteCheckboxes?.quote_conditions ?? {},
        }),
        [quoteCheckboxes]
    );

    useEffect(() => {
        if (currentQuoteId) {
            dispatch(getQuoteCheckboxes_server(currentQuoteId, 'quoteCondBindReqs'));
            dispatch(getTableAggregates_server(bqcTableConfig, getQuoteConditionAggregates()));
        }
    }, [currentQuoteId, bqcTableConfig, dispatch]);

    useEffect(() => {
        // This depends on originalSubmissionId so can't do in initialize. Ok to call over and over.
        appContextFns.onLoadDocumentsIfNeeded();
    });

    const onUpdateCustomQuoteConditions = (quoteId: number, data: Record<string, any>) => {
        return dispatch(updateQuote_server(quoteId, { custom_quote_conditions: data }));
    };
    const onUpdateBindingRequirements = (quoteId: number, data: Record<string, any>) => {
        dispatch(updateBindingRequirements_server(quoteId, data));
    };
    const updateBuildingQuoteConditions = (quote, data) => {
        const update = getBuildingQuoteConditionUpdate(data, buildingQuoteConditionAggregates, buildingCount);
        dispatch(updateBuildingQuoteConditions_server(bqcTableConfig, quote.id, update));
    };
    const onOpenCenteredModal = (modalData: any, modalType: string) => {
        dispatch(openCenteredModal(modalData, modalType));
    };

    return (
        <>
            <div className="main-content-center">
                <AccountTasksPanel />
                <div className="ventus-content flex-table even-odd quote-pipeline">
                    <QuoteConditionsApp
                        currentSubmission={appContext.currentSubmission}
                        currentQuote={appContext.currentQuote}
                        quoteConditionsData={quoteConditionsData}
                        buildingQuoteConditionAggregates={buildingQuoteConditionAggregates}
                        buildingCount={buildingCount}
                        onUpdateQuoteConditions={updateBuildingQuoteConditions}
                        onUpdateCustomQuoteConditions={onUpdateCustomQuoteConditions}
                        onOpenCenteredModal={onOpenCenteredModal}
                    />
                    {showBindingRequirementsV1 && (
                        <BindingRequirementsApp
                            currentSubmission={appContext.currentSubmission}
                            currentTransaction={appContext.currentTransaction}
                            currentQuote={appContext.currentQuote}
                            bindingRequirementsData={bindingRequirementsData}
                            onUpdateBindingRequirements={onUpdateBindingRequirements}
                        />
                    )}
                </div>
            </div>
            <div
                className={classNames('main-right-panel main-panel', {
                    closed: !uiState.openPanels[OVERVIEW_ACTIONS_PANEL],
                })}
            >
                <ActionPanel
                    uiState={uiState}
                    panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.OVERVIEW)}
                    panelName={OVERVIEW_ACTIONS_PANEL}
                    openPanels={uiState.openPanels}
                    tableConfig={PAGED_TABLES.SUBMISSION_LOG}
                />
            </div>
        </>
    );
}
