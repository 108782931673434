import * as React from 'react';
import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

import { LiteTableDataType, RowSelectedFnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import BlanketLimitsBottomPanelApp from '../apps/BlanketLimitsBottomPanelApp';
import { Types } from '../../../../ts-types/viki-types';
import { RootState } from '../../../../store';
import { getBlanketLimits_server } from '../../../../actions/QuoteActions';
import { OpenPanelType } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { updateUiPanels } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';

type ownPropTypes = {
    CONSTANTS: Types.Constants;
    readonly: boolean;
    selectedRowIndex: number;
    onSaveFn: (...params: any) => any;
    onRowSelected: RowSelectedFnType;
    onOpenDetailsPanel: () => void;
    tableData: LiteTableDataType;
    openPanels: OpenPanelType;
    editingDisabled: boolean;
    currentQuoteId: number;
};

type propTypes = { onPersistBlanketLimitsData: () => Promise<any> } & ownPropTypes;

const mapStateToProps = (state: RootState, _ownProps: ownPropTypes) => {
    return {
        openPanels: state.uiState.openPanels,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    return {
        onOpenDetailsPanel: () => {
            dispatch(updateUiPanels({ BOTTOM_blanket_limits: { open: 'half' } }));
        },
        onPersistBlanketLimitsData: () => {
            return dispatch(getBlanketLimits_server(ownProps.currentQuoteId));
        },
    };
};

class BlanketLimitsBottomPanelContainer extends React.Component<propTypes> {
    isUpdating: boolean;

    constructor(props) {
        super(props);
        this.isUpdating = false;
    }

    componentDidUpdate() {
        if (!this.isUpdating) {
            this.isUpdating = true;
            this.props.onPersistBlanketLimitsData().then(() => {
                this.isUpdating = false;
            });
        }
    }

    render() {
        return <BlanketLimitsBottomPanelApp {...this.props} tableData={this.props.tableData} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BlanketLimitsBottomPanelContainer) as any;
export default connected;
