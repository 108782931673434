import api from '../apiSlice';
import { DefaultPolicyDocEmailInfoResult, GenerateLossRunReportResult } from '../../ts-types/ApiTypes';

const policyIssuanceApi = api.injectEndpoints({
    endpoints: builder => ({
        defaultPolicyDocEmailInfo: builder.query<DefaultPolicyDocEmailInfoResult, { submissionId: number; documentId: number }>({
            query: ({ submissionId, documentId }) => ({
                url: `v1/submission/${submissionId}/default_policy_document_email_info/`,
                method: 'GET',
                params: { document_id: documentId },
            }),
        }),
        sendPolicyDocumentToBroker: builder.mutation<
            unknown,
            { submissionId: number; documentId: number; sendTo: string[]; subject: string; messageBody: string }
        >({
            query: ({ submissionId, documentId, sendTo, subject, messageBody }) => ({
                url: `v1/submission/${submissionId}/send_policy_document_to_broker/`,
                method: 'POST',
                data: { document_id: documentId, send_to: sendTo, subject, message_body: messageBody },
            }),
        }),
        generateLossRunReport: builder.mutation<GenerateLossRunReportResult, { submissionId: number; sendTo?: string[]; messageBody?: string }>({
            query: ({ submissionId, sendTo, messageBody }) => ({
                url: `v1/submission/${submissionId}/generate_loss_run_report/`,
                method: 'PUT',
                data: { submissionId, send_to: sendTo, message_body: messageBody },
            }),
        }),
    }),
});

export default policyIssuanceApi;

export const { useDefaultPolicyDocEmailInfoQuery, useGenerateLossRunReportMutation, useSendPolicyDocumentToBrokerMutation } = policyIssuanceApi;
