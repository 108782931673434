import { useCallback, useEffect, useRef } from 'react';
import Select from '@archinsurance-viki/property-jslib/src/components/inputs/v2/Select';
import { useAppContext, useAppContextFns } from '../../../hooks/context';
import { useFormContext } from 'react-hook-form';
import useQuoteId from '../../../hooks/quotes';
import { useValidateQuery } from '../../../services/endpoints/policy-coverage';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { PolicyTermsFieldName, getFormValuesForSubmit } from '../utils/form';
import { QuoteType } from '../../../ts-types/DataTypes';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { Toast } from 'primereact/toast';
import { useSubmissionIsEditable } from '../../../hooks/submissions';
import { BuildingTermsResult } from '../../../ts-types/ApiTypes';

import { useSubmissionDataQuery } from '../../../services/endpoints/submission';
import { formatQuoteDescription, usePolicyTermsContext } from '../utils/coverage-utils';
import { ErrorToast } from '../../../components/common/ToastMessage';
import { useBuildingTermsBulkUpdateMutation } from '../../../services/endpoints/coverage-terms';
import { CoverageLevelToggle } from '../components/CoverageLevelToggle';

const CoverageOptionSelect = ({ selectedQuote }: { selectedQuote: QuoteType }) => {
    const { currentSubmissionId } = useAppContext();
    const appContextFns = useAppContextFns();
    const { data } = useSubmissionDataQuery({ id: currentSubmissionId });
    const submissionQuotes = data?.quotes;
    if (!submissionQuotes) {
        return null;
    }
    const quoteDescriptions: [number, string][] = submissionQuotes.map(quote => [quote.id, formatQuoteDescription(quote)]);
    const selectedItem = quoteDescriptions.find(([id, _desc]) => id === selectedQuote.id);
    return (
        <div className="tw-flex-1 tw-min-w-0 tw-flex viki-combo-box txt-standard header-select tw-p-0 tw-border-0 tw-text-sm tw-font-bold tw-bg-white">
            <Select
                className="tw-w-full tw-select-none"
                selectedItemClassname="tw-w-full tw-truncate"
                onChange={([id, _desc]) => appContextFns.onSetCurrentQuote(id)}
                items={quoteDescriptions}
                selectedItem={selectedItem}
            />
        </div>
    );
};
export const BuildingLevelHeader = () => {
    const appContext = useAppContext();
    const isEditable = useSubmissionIsEditable();

    const { buildingTermsGlossary, selectedQuoteIds, selectedBuildingCoverageIds } = usePolicyTermsContext();

    const toast = useRef<Toast>();

    const { handleSubmit, formState, reset, setError, setFocus, clearErrors } = useFormContext();
    const quoteId = useQuoteId();

    const { currentQuote } = appContext;

    const [triggerBulkUpdate, { isLoading: isUpdating }] = useBuildingTermsBulkUpdateMutation();

    const setFieldErrors = useCallback(
        (errors: Record<string, string[]>) => {
            const errorsList = Object.entries(errors);
            errorsList.forEach(([fieldKey, errorStrings]: [any, string[]]) => {
                setError(fieldKey as any, { type: 'custom', message: errorStrings.join('\n') });
            });
        },
        [setError]
    );

    // TODO: Validate building coverage
    const { data } = useValidateQuery({ id: currentQuote.policy_coverage_id }, { skip: true });

    const { field_errors: fieldErrors, non_field_errors: nonFieldErrors } = data ?? { field_errors: {}, non_field_errors: {} };
    const hasNonFieldErrors = Object.keys(nonFieldErrors).length > 0;
    const hasFieldErrors = Object.keys(fieldErrors).length > 0;
    const hasValidationErrors = hasFieldErrors || hasNonFieldErrors;

    const { dirtyFields, isDirty } = formState;

    useEffect(() => {
        clearErrors();
    }, [isDirty, clearErrors]);

    const onSubmit = async data => {
        const submitData = getFormValuesForSubmit<BuildingTermsResult>(data, buildingTermsGlossary, dirtyFields);
        if (Object.keys(submitData).length === 0 || !quoteId) {
            return;
        }

        const quoteIds = selectedQuoteIds.length > 0 ? selectedQuoteIds : [quoteId];

        try {
            triggerBulkUpdate({ buildingCoverageIds: selectedBuildingCoverageIds, quoteIds: quoteIds, data: submitData }).unwrap();
        } catch (e: unknown) {
            const errors = (e as any)?.data?.update_errors;
            if (!errors || Object.keys(errors).length === 0) {
                console.error('Unknown error', e);
                return;
            }
            setFieldErrors(errors as Record<string, string[]>);

            // display first 5 errors as toast messages and jump to first in list
            Object.entries(errors as Record<string, string[]>)
                .slice(0, 5)
                .forEach(([field, errors], index) => {
                    if (index === 0) {
                        setFocus(field as PolicyTermsFieldName);
                    }
                    toast.current?.show({
                        severity: 'error',
                        content: <ErrorToast title={`Invalid ${buildingTermsGlossary[field].label}`} errorMessage={errors.join('\n')} />,
                    });
                });
        }
    };

    return (
        <div className="viki-tasks">
            <Toast ref={toast} className="tw-absolute tw-top-0 tw-right-0" />
            <div className="flex column w-100 padding-standard">
                <div className="flex fd-r padding-none">
                    <CoverageLevelToggle />

                    <div className="tw-flex tw-w-full">
                        <div className="tw-flex tw-max-w-[750px]">
                            <CoverageOptionSelect selectedQuote={currentQuote} />
                            <div className="tw-flex-1 tw-min-w-0 input-div viki-combo-box txt-standard header-select tw-p-0 tw-border-0 tw-text-sm tw-font-bold">
                                <Select
                                    className="tw-w-full tw-select-none"
                                    selectedItemClassname="tw-w-full tw-truncate"
                                    items={[[0, 'Building 1 - 1234 Avenue Suite 200 City, State, 111111']]}
                                    onChange={() => console.log('TODO: Building onchange')}
                                    selectedItem={[0, 'Building 1 - 1234 Avenue Suite 200 City, State, 111111']}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex fd-r w-100 tw-gap-8">
                    <div className="panelbuttons">
                        <button
                            className="btn green tw-w-16"
                            onClick={e => {
                                // custom valiation errors are persisted and will block handleSubmit unless we clearErrors
                                clearErrors();
                                return handleSubmit(onSubmit)(e);
                            }}
                            disabled={!isEditable || !isDirty || isUpdating}
                        >
                            {isUpdating ? <Spinner color="white" size="base" /> : 'Save'}
                        </button>
                        <button className="btn grey-dark" onClick={() => reset(undefined, { keepErrors: true })} disabled={!isEditable || !isDirty}>
                            Cancel
                        </button>
                    </div>
                    {hasValidationErrors && (
                        <div className="tw-self-end tw-flex tw-flex-end tw-items-center tw-gap-2 tw-text-orange-dark">
                            <Icon icon="warning_amber" className="tw-text-[24px]" />
                            <span className="tw-text-xl">Validation Errors</span>
                        </div>
                    )}
                    {!isEditable && <p className="tw-self-center tw-text-xl tw-text-red">Warning: This submission is not editable</p>}
                </div>
            </div>
        </div>
    );
};
