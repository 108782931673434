import { applyValuationOverride_server } from '../../../../actions/SubmissionActions';
import { NumericInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { UiStateType, updateUi } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { Paths, TypeFromPath } from '@archinsurance-viki/property-jslib/src/ts-types/util';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

type propTypes = {
    onClose: (x?: boolean) => void;
    modalData: Record<string, unknown>;
};

type ValuationOverrideType = UiStateType['valuationOverride'];

const ValuationOverrideApp = ({ modalData, onClose }: propTypes) => {
    const dispatch = useAppDispatch();
    const valuationOverride = useAppSelector(state => state.uiState.valuationOverride);

    // TODO: override_valuation should just be local state instead of Redux state
    useEffect(() => {
        dispatch(updateUi({ data: { valuationOverride: { override_valuation: 100 } } }));
    }, [dispatch]);

    const handleChange = <Path extends Paths<ValuationOverrideType>>(fieldName: Path, value: TypeFromPath<ValuationOverrideType, Path>) => {
        dispatch(
            updateUi({
                data: {
                    valuationOverride: { [fieldName]: value },
                },
            })
        );
    };

    const clearOverride = () => {
        dispatch(
            updateUi({
                data: {
                    valuationOverride: { override_valuation: null },
                },
            })
        );
    };

    const handleApplyOverrides = () => {
        let { submissionId } = modalData;
        if (valuationOverride) {
            dispatch(applyValuationOverride_server(submissionId as number, valuationOverride.override_valuation));
            clearOverride();
            onClose(true);
        }
    };

    const handleCancel = () => {
        clearOverride();
        onClose(true);
    };
    if (!modalData) {
        return null;
    }

    return (
        <div className="standard-modal-content flex column">
            <div className="js-notes-class" style={{ marginBottom: '5px' }}>
                <NumericInput
                    maxValue={1000}
                    format={{ subtype: 'percentage' }}
                    name="override_valuation"
                    className="label-inline"
                    label="Please enter the override percentage of the external valuation:"
                    onChange={handleChange}
                    object={valuationOverride}
                />
            </div>
            <div className="button-row">
                <button className="green" onClick={handleApplyOverrides}>
                    Apply Override
                </button>
                <div className="spacer wide" />
                <button
                    className="red"
                    onClick={() => {
                        handleCancel();
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default ValuationOverrideApp;
