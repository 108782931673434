import { createSelector } from '@reduxjs/toolkit';
import { Choice } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';

import { RootState } from '../store';
import { Types } from '../ts-types/viki-types';

// TODO: Ensure we only get constants that have choices
export const selectConstant = (state: RootState, constant: keyof Types.Constants) => state.global.CONSTANTS[constant] as Choice<string | number>[];

export const filterInvalidChoicesFromConstant = createSelector(
    [state => state, (_state, constant) => constant, (_state, _constant, invalidChoices) => invalidChoices],
    (state: RootState, constant: keyof Types.Constants, invalidChoices: unknown[]) =>
        selectConstant(state, constant).filter(({ value }) => !invalidChoices.includes(value))
);
