import * as React from 'react';
import lockr from 'lockr';
import { useHistory } from 'react-router-dom';

import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import SubmissionLogApp from './SubmissionLogApp';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

import { selectTableRow } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { SUBMISSION_SHORTCUT } from '../../reducers/accounts';
import { AppContextType } from '../../ts-types/AppTypes';
import { RECENT_TAB_ACCOUNT_ID_CACHE } from '../../constants/CacheConstants';

import { ACCOUNT_NOT_LOADED } from '../../utils/state-helpers';
import { RootState } from '../../store';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

const mapStateToProps = (state: RootState, appContext: AppContextType) => {
    const { submissionLog } = state;
    const { currentSubmissionId, currentAccountId, currentAccount } = appContext;

    let selectedRowId = currentSubmissionId;

    if (!selectedRowId && currentAccount === ACCOUNT_NOT_LOADED && Number.isInteger(currentAccountId)) {
        const rowData: Record<number, any> = state.submissionLog.rowData;
        // We have an accountId but have not loaded account data yet but to make row selection work quickly see if we have table data
        const selectedRow = Object.values(rowData).find(r => r.account_id === currentAccountId);
        if (selectedRow) {
            selectedRowId = selectedRow.id;
        } else {
            selectedRowId = -1;
        }
    }

    return {
        selectedRowId,
        submissionRows: submissionLog.rows,
    };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
    return {
        onSelectTableRow: (tableConfig, rowId) => {
            dispatch(selectTableRow(tableConfig, rowId));
        },
    };
};

export default function SubmissionLog() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const { location } = useHistory();
    const { selectedRowId, submissionRows } = useAppSelector(state => mapStateToProps(state, appContext));
    const { onSelectTableRow } = mapDispatchToProps(useAppDispatch());
    const tableConfig = PAGED_TABLES.SUBMISSION_LOG;
    const ENV = useAppSelector(state => state.global.ENV);
    const me = useAppSelector(state => state.global.me);

    const onRowSelected = (id, replaceInUrl = false) => {
        const loadingInProgress = Object.keys(appContext.submissionMap).length === 1; // submissionMap will always contain the loading row from its initial state

        const rowData = appContext.submissionMap[id];
        if (!rowData) {
            if (!loadingInProgress) {
                appContextFns.onSetCurrentSubmission(id, SUBMISSION_SHORTCUT.CURRENT, replaceInUrl); // new row case
            }
            return;
        }

        onSelectTableRow(tableConfig, id);

        const lastVisistedAccountId = lockr.get<number>(RECENT_TAB_ACCOUNT_ID_CACHE);
        const accountId = rowData.account_id || id;
        const submissionId = accountId === id ? SUBMISSION_SHORTCUT.CURRENT : id;

        // VIKI-6973
        if (location && location.search && location.search.includes('=recent') && lastVisistedAccountId) {
            appContextFns.onSetCurrentSubmission(lastVisistedAccountId, lastVisistedAccountId, replaceInUrl);
            lockr.set(RECENT_TAB_ACCOUNT_ID_CACHE, null);
        } else {
            appContextFns.onSetCurrentSubmission(accountId, submissionId, replaceInUrl);
        }
    };
    return (
        <SubmissionLogApp
            tableConfig={tableConfig}
            appContext={appContext}
            appContextFns={appContextFns}
            submissionRows={submissionRows}
            onRowSelected={onRowSelected}
            selectedRowId={selectedRowId}
            siteId={ENV.SITE.id}
            env={ENV.ENV}
            me={me}
        />
    );
}
