import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { PolicyTermsFormValues, PolicyTermsFormField } from '../utils/form';

export const ProRataMEP = () => (
    <div className="info-rows">
        <PolicyTermsFormField>
            <FormButtonGroup<PolicyTermsFormValues>
                name="is_allow_prorata_wind_mep"
                items={[
                    { display: 'Yes', value: true },
                    { display: 'No', value: false },
                ]}
            />
        </PolicyTermsFormField>
    </div>
);
