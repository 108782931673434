import { useParams } from 'react-router-dom';
import { useAppContext } from './context';
import { useAppSelector } from './redux';
import { getCurrentSubmissionId } from '../utils/state-helpers';
import { TRANSACTION_TYPES } from '../constants/TransactionConstants';

export const useSubmissionId = (): number | undefined => {
    const params = useParams();
    return useAppSelector(state => getCurrentSubmissionId(state, params));
};

export const useSubmissionIsEditable = (): boolean | undefined => {
    const { currentSubmission } = useAppContext();
    // Note: I made the check for === false explicit because in the undefined case
    // I don't want the UI to accidentally lock out the user from modifying pricing.
    // I'd rather have a false positive and have the backend say "Hey, you can't do this!"
    // instead of the frontend guessing that you aren't allowed to edit based on missing info
    // OverrideModal.tsx has a (almost) copy paste of this line because it doesn't have access to useAppContext data
    return currentSubmission?.is_editable !== false;
};

export const useTransactionIsPremiumBearing = (): boolean | undefined => {
    const { currentTransaction } = useAppContext();
    return currentTransaction?.transaction_type === TRANSACTION_TYPES.ENDORSEMENT && currentTransaction?.is_premium_bearing === true;
};
