import _ from 'lodash';
import u from 'updeep';

import { batch } from 'react-redux';

import API from '@archinsurance-viki/property-jslib/src/utils/API';
import { RecursivePartial } from '@archinsurance-viki/property-jslib/src/ts-types/util';
import { assert } from '@archinsurance-viki/property-jslib/src/utils/assertions';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import * as types from '../constants/ActionTypes';
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { addToUndoStack, refreshData, updateDataByPath, reloadTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';
import { notifyMovedSubmission } from '../utils/ui-helpers';
import { ExposureType, GoToSubmissionType, SubmissionDataType } from '../ts-types/DataTypes';
import { VIRA_MODAL_TYPES } from '../constants/Modal';
import { AppDispatch } from '../store';
import { accountApi } from '../services/endpoints/account';
import { buildingApi } from '../services/endpoints/building';
import { carrierApi } from '../services/endpoints/carrier';
import { submissionApi } from '../services/endpoints/submission';
import { updateTaskProgress } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';

export const processAccountData = (dispatch: AppDispatch, { account, account_transactions }: Record<string, any>): void => {
    let policyTransactionMap = _.keyBy(account_transactions, 'id');
    let transactionMap = {};
    let submissionIdToAccount = {};
    for (let id of account.account_transaction_ids) {
        let transaction = policyTransactionMap[id];
        transactionMap[transaction.id] = transaction;
        submissionIdToAccount[transaction.submission_id] = Number(account.id);
    }
    batch(() => {
        dispatch({
            type: types.RECEIVE_ACCOUNT,
            account: {
                ...account,
                most_recent_committed_transaction: policyTransactionMap[account.most_recent_committed_transaction_id],
                most_recent_unreverted_transaction: policyTransactionMap[account.most_recent_unreverted_transaction_id],
            },
            submissionIdToAccount,
        });
        dispatch({
            type: types.LOAD_ROWS,
            tableConfig: LITE_TABLES.TRANSACTIONS,
            rowDataMap: transactionMap,
        });
    });
};

export const processSubmissionData = (
    dispatch: AppDispatch,
    accountViewData: Record<string, any>,
    buildingData: Record<string, any>,
    statisticsSummary: Record<string, any>,
    otherSubmissionData: Record<string, any>,
    carrierData: Record<string, any>
): Record<string, SubmissionDataType> => {
    let producingAgentMap = {}; //_.keyBy(producing_agents, 'id');
    let producersMap = {}; //_.keyBy(producers, 'id');
    let quoteList = otherSubmissionData.quotes;

    let submissionData = _.clone(accountViewData);

    submissionData._actions = otherSubmissionData._actions;
    submissionData.additional_named_insureds = otherSubmissionData.additional_named_insureds;
    submissionData.carrierData = carrierData || [];

    submissionData.possible_duplicate_sids = otherSubmissionData.insured ? otherSubmissionData.insured.possible_duplicate_sids : null;

    submissionData.account_agent = producingAgentMap[submissionData.account_agent_id];
    submissionData.licensed_broker = producersMap[submissionData.licensed_broker_id];
    submissionData.office = producersMap[submissionData.office_id];

    submissionData.buildings = buildingData.results;
    submissionData.quotes = quoteList.map(q => q.id);

    // if actual value is not retrieved, we must specify the value as undefined
    // we can't use 'falsy' checks later or production code could mistakenly think we are in dev/staging
    submissionData.is_live_production_submission = otherSubmissionData?.is_live_production_submission;

    // overwrite documents with a list of ids as the document data is stored in the table reducer
    submissionData.statisticsSummary = statisticsSummary || {};
    // submissionData.exposureSummary = exposureSummary || {};
    submissionData.SUPPLEMENTED = true;
    submissionData._is_dirty = undefined;

    submissionData.archlink_communication_statuses = otherSubmissionData.archlink_communication_statuses;
    submissionData.is_pw_uploadable = otherSubmissionData.is_pw_uploadable;
    submissionData.is_sa_uploadable = otherSubmissionData.is_sa_uploadable;
    submissionData.is_insured_flag = otherSubmissionData.is_insured_flag;
    submissionData.insured_flag_reason = otherSubmissionData.insured_flag_reason;
    submissionData.arch_policywriter_mc_id = otherSubmissionData.arch_policywriter_mc_id;

    delete submissionData.account;

    batch(() => {
        dispatch({
            type: types.UPDATE_ROW_DATA,
            tableConfig: PAGED_TABLES.SUBMISSION_LOG,
            data: submissionData,
        });
        dispatch({
            type: types.LOAD_ROWS,
            tableConfig: LITE_TABLES.QUOTES,
            rowArray: quoteList,
        });
    });

    return submissionData;
};

export const insuredInfoUpdated = (
    field: string,
    value: string,
    errorObj: Record<string, any>
): { type: string; updateField: string; updateValue: string; errorObj: Record<string, any> } => ({
    type: types.UPDATE_INSURED_INFO,
    updateField: field,
    updateValue: value,
    errorObj: errorObj,
});

export const setSubmissionOutcome = (field: string, value: any, error?: string): { type: string; field: string; value: any; error: string } => ({
    type: types.SET_SUBMISSION_OUTCOME,
    field: field,
    value: value,
    error: error,
});

export const updateSubmissionData = (submissionId: number, updatedData: RecursivePartial<SubmissionDataType>) => ({
    type: types.UPDATE_ROW_DATA,
    tableConfig: PAGED_TABLES.SUBMISSION_LOG,
    data: { ...updatedData, id: submissionId },
});

export const clearSubmissionOutcome = (): { type: string } => ({
    type: types.CLEAR_SUBMISSION_OUTCOME,
});

export const setSubmissionOutcomeError = (errors: Record<string, any>) => ({
    type: types.SET_SUBMISSION_OUTCOME_ERROR,
    errors: errors,
});

export const receiveNoAccount = (id: number) => ({
    type: types.RECEIVE_NO_ACCOUNT,
    id,
});

export const loadingSubmissionDocuments = (id: number) => ({
    type: types.LOADING_SUBMISSION_DOCUMENTS,
    id,
});

export const updateDocumentData = (documentUpdates: Record<string, any>[], extraData?: Record<string, any>) => {
    return (dispatch: AppDispatch) => {
        let updates: Record<string, any> = {};
        for (let d of documentUpdates) {
            let documentData: Record<string, any> = extraData ? u(extraData, d) : d;
            updates[documentData.id] = u.constant(documentData);
        }

        dispatch(updateDataByPath(LITE_TABLES.DOCUMENTS, 'rowData', updates));
    };
};

// originalSubmission is where all documents are stored and may or may not be the same as the current submission
export const loadDocuments = (originalSubmissionId: number, reloading: boolean) => {
    return (dispatch: AppDispatch) => {
        if (!reloading) {
            dispatch(loadingSubmissionDocuments(originalSubmissionId));
        }

        return API.doRequest(API.endpoints.submission.getDocuments, { id: originalSubmissionId }, dispatch).then(documents => {
            let documentIds = documents.map(document => document.id);
            batch(() => {
                dispatch({
                    type: types.RECEIVE_SUBMISSION_DOCUMENT_IDS,
                    data: documentIds,
                    id: originalSubmissionId,
                });

                dispatch({
                    type: types.LOAD_ROWS,
                    tableConfig: LITE_TABLES.DOCUMENTS,
                    rowArray: documents,
                });
            });
        });
    };
};

export const getAccountDataFromServer = (fetchId: number): any => {
    return (dispatch: AppDispatch) => {
        let request = {
            id: fetchId,
            showProgress: true,
            hideDialogForErrors: true,
        };

        return API.doRequest(API.endpoints.accounts.get, request, dispatch).then(
            ({ data }) => {
                processAccountData(dispatch, data);
            },
            ({ status }) => {
                if (status === 404) {
                    // no account yet for that submission
                    dispatch(receiveNoAccount(fetchId));
                }
            }
        );
    };
};

export const validateSubmission_server = (submissionId: number, coverageOptionId: number, showProgress: boolean): any => {
    let request = {
        submissionId,
        coverageOptionId,
        showProgress,
    };
    return (dispatch: AppDispatch) => {
        return API.doRequest(API.endpoints.submission.validate, request, dispatch).then(({ data }) => {
            return dispatch(updateSubmissionData(submissionId, { _validations: { ...data, _currentQuoteId: coverageOptionId } }));
        });
    };
};

export const getAccountSubmissionViewFromServer = (submissionId: number, forceRefetch = false) => {
    return async (dispatch: AppDispatch) => {
        const subscriptions = [
            dispatch(accountApi.endpoints.accountTransactionData.initiate({ submissionId }, { forceRefetch })),
            dispatch(buildingApi.endpoints.submissionBuildingData.initiate({ submissionId }, { forceRefetch })),
            dispatch(buildingApi.endpoints.statisticsSummary.initiate({ submissionId }, { forceRefetch })),
            dispatch(submissionApi.endpoints.submissionData.initiate({ id: submissionId }, { forceRefetch })),
            dispatch(carrierApi.endpoints.accountTransactionCarrierData.initiate({ submissionId }, { forceRefetch })),
        ];

        const [accountViewData, buildingData, statisticsSummary, otherSubmissionData, carrierData] = await Promise.all(Object.values(subscriptions));

        return {
            submissionData: processSubmissionData(
                dispatch,
                accountViewData.data,
                buildingData.data,
                (statisticsSummary.data as { data: ExposureType[] }).data,
                otherSubmissionData.data,
                carrierData.data
            ),
            subscriptions,
        };
    };
};

export const onExcelLoaded = (documentId: number, xlsData: Record<string, any> | string) => {
    return (dispatch: AppDispatch): void => {
        dispatch(
            updateDataByPath(LITE_TABLES.DOCUMENTS, 'rowData', {
                [documentId]: { preview: { xls_data: xlsData } },
            })
        );
    };
};

const loadingDocs = {};
export const loadExcel = (document: Record<string, any>) => {
    return (dispatch: AppDispatch): void => {
        let { preview } = document;

        let url = preview.xls_json_url;

        if (loadingDocs[url]) {
            // we are already fetching this.
            return;
        }

        loadingDocs[url] = true;
        API.staticMethods.get(url).then(
            ({ data }) => {
                dispatch(onExcelLoaded(document.id, data));
            },
            () => {
                dispatch(onExcelLoaded(document.id, 'EXCEL_LOAD_FAILURE'));
            }
        );
    };
};

/****************** TAKEN FROM /api/reports ***************** */

export const unbindSubmission_server = (submissionId: number, goToSubmission?: GoToSubmissionType, actionHelperFns?: ActionHelperFnsType) => {
    return (dispatch: AppDispatch, getState: () => Record<string, any>) => {
        return API.doRequest(
            API.endpoints.report.unbindSubmission,
            {
                params: { submission_id: submissionId },
            },
            dispatch
        ).then(
            () => {
                if (goToSubmission && actionHelperFns) {
                    notifyMovedSubmission(dispatch, submissionId, 'OUTSTANDING_QUOTES', actionHelperFns, goToSubmission, getState());
                }
            },
            () => {
                console.error('unbindSubmission_server: unbindSubmission_server');
                //dispatch(setErrors(xhr.errors));
            }
        );
    };
};

export const bindSubmission_server = (quoteId: number, submissionId: number, isPreview?: boolean) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.report.generateBindLetter,
            {
                showProgress: isPreview
                    ? true
                    : {
                          isBlocking: true,
                          message: `Binding Submission: ${submissionId}`,
                      },
                params: { quote_id: quoteId, is_preview: isPreview },
            },
            dispatch
        ).then(
            ({ data }) => {
                if (!data.success) dispatch(updateTaskProgress({ data, taskPath: getTaskPath(data.task_type, data.key_id) }));
                if (!isPreview) {
                    dispatch(getAccountDataFromServer(submissionId));
                    dispatch(getAccountSubmissionViewFromServer(submissionId));
                    dispatch(reloadTable(PAGED_TABLES.SUBMISSION_LOG));
                }
                if (data.warnings.length > 0) {
                    dispatch(
                        openCenteredModal(
                            {
                                modalData: {
                                    warnings: data.warnings,
                                },
                            },
                            VIRA_MODAL_TYPES.WARNINGS
                        )
                    );
                }
            },
            () => {
                console.error('bindSubmission_server: bindSubmission_server');
                //dispatch(setErrors(xhr.errors));
            }
        );
    };
};

export const applyValuationOverride_server = (submission_id: number, percentage: number) => {
    assert(!!submission_id, 'Must Have Submission Id');
    assert(!!percentage, 'Must Have percentage');

    return (dispatch: AppDispatch) => {
        // // Update here so UI change sticks
        // let updatePath = `rowData.${building_id}`;

        return API.doRequest(
            API.endpoints.submission.applyValuationOverride,
            {
                id: submission_id,
                params: { percentage: percentage },
            },
            dispatch
        ).then(() => {
            dispatch(refreshData(PAGED_TABLES.BUILDING_GRID));
        });
    };
};

export const deleteDocumentFromSubmission = (submissionId: number, documentId: number): { type: string; documentId: number; id: number } => ({
    type: types.DELETE_SUBMISSION_DOCUMENT,
    documentId: documentId,
    id: submissionId,
});

export const addDocumentToSubmission = (submissionId: number, documentId: number): { type: string; documentId: number; id: number } => ({
    type: types.ADD_SUBMISSION_DOCUMENT,
    documentId: documentId,
    id: submissionId,
});

export const dismissGeocodeWarnings_server = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.dismissGeocodeWarnings,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        ).then(({ data }) => {
            let undoData = {
                type: 'REQUEST_DATA' as const,
                request: {
                    data: { user_confirmed_address: null },
                    pks: data,
                },
            };
            addToUndoStack(PAGED_TABLES.BUILDING_GRID, dispatch, undoData);
            dispatch(getAccountSubmissionViewFromServer(submissionId));
        });
    };
};

export const parseSov = (documentId: number, submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.parseSov,
            {
                submission_id: submissionId,
                document_id: documentId,
            },
            dispatch
        );
    };
};

export const generatePolicyIssuance_server = (submissionId: number, isDraft: boolean) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.policyIssuance,
            {
                submission_id: submissionId,
                is_draft: isDraft,
            },
            dispatch
        ).then(data => {
            if (!isDraft) {
                dispatch(
                    updateDataByPath(PAGED_TABLES.SUBMISSION_LOG, `rowData.${submissionId}`, {
                        is_issued: !isDraft,
                        policy_issuance_date: data.data.policy_issuance_date,
                    })
                );
            }
        });
    };
};

export const overrideOfac = (submissionId: number, approved: boolean) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.overrideOfac,
            {
                submissionId: submissionId,
                params: {
                    approved: approved,
                },
            },
            dispatch
        ).then(({ data }) => {
            dispatch(updateDataByPath(LITE_TABLES.TRANSACTIONS, `rowData.${submissionId}`, data));
            dispatch(updateDataByPath(PAGED_TABLES.SUBMISSION_LOG, `rowData.${submissionId}`, data));
        });
    };
};

export const openOverrideOfacModal = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(
            openCenteredModal(
                {
                    modalData: {
                        submissionId: submissionId,
                    },
                },
                VIRA_MODAL_TYPES.OVERRIDE_OFAC
            )
        );
    };
};

export const generateAccountSummary_server = (submissionId: number, transactionId: number, onDemandStatus?: string) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.report.generateAccountSummary,
            {
                params: {
                    submission_id: submissionId,
                    transaction_id: transactionId,
                    on_demand_status: onDemandStatus,
                },
            },
            dispatch
        );
    };
};
