import { ComboBoxInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { PmlApiResponseType, PmlDataType } from '../../ts-types/DataTypes';
import AnalyticsTable from './AnalyticsTable';
import MultiAxisLineGraph from './MultiAxisLineGraph';
import PanelArea from './PanelArea';
import SelectBox from './SelectBox';
import Button from '@archinsurance-viki/property-jslib/src/components/buttons/Button';
import { CURRENT_YEAR } from './utils';
import { BACKGROUND_TASK_TYPES } from '../../constants/TaskConstants';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';
import { Types } from '../../ts-types/viki-types';
import { TaskTypePanel } from '../../features/AccountTasksPanel';

const KICKS_IN_DATE_FORMAT = 'YYYY-MM-DD';

type propTypes = {
    CONSTANTS: Types.Constants;
    featureFlags: Types.FeatureFlags;
    onGetAirPml: (options: Record<string, any>) => Promise<Record<string, any>[]>;
    onGetPmlTableData: (options: Record<string, any>) => Promise<Record<string, any>[]>;
    onCalculatePmlData: (options: Record<string, any>) => Promise<Record<string, any>[]>;
    params: UrlParams;
    onChangeUrlQueryParams: (arg: Partial<UrlParams>) => void;
};

type UrlParams = {
    carrier_id: number;
    year: number;
    in_force: string;
    return_periods: string[];
    pmls: string[];
    cat_view_types: string[];
    includes_quoteds: string[];
    metrics: string[];
};

type stateTypes = {
    as_of_type: string;
    metrics: string[];
    enrollment_period: string[];
    carrier_id: number | string;
    data: PmlApiResponseType[];
    pmls: string[];
    return_periods: string[];
    cat_view_types: string[];
    includes_quoteds: string[];
    year: number;
    in_force: string;
    carriers: { value: number; display: string }[];
    pmlDateData?: PmlDataType[];
    selectedDate: Date;
    bg_task_id: number;
    isCalculating: boolean;
};

export default class AnalyticsApp extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        const getFromUrl = (field: keyof UrlParams, default_value: any) => {
            return typeof props.params[field] === 'undefined' ? default_value : props.params[field];
        };
        const metrics = getFromUrl('metrics', []);
        const defaultReturnPeriods = metrics.length > 0 ? [] : ['200'];
        const defaultPmls = metrics.length > 0 ? [] : ['AEP'];

        this.state = {
            as_of_type: 'E',
            return_periods: getFromUrl('return_periods', defaultReturnPeriods),
            pmls: getFromUrl('pmls', defaultPmls),
            carrier_id: getFromUrl('carrier_id', ''),
            enrollment_period: [],
            data: [],
            metrics: metrics,
            cat_view_types: getFromUrl('cat_view_types', ['P']),
            includes_quoteds: getFromUrl('includes_quoteds', ['false']),
            year: getFromUrl('year', CURRENT_YEAR),
            carriers: [],
            pmlDateData: null,
            selectedDate: null,
            in_force: getFromUrl('in_force', 'true'),
            bg_task_id: -1,
            isCalculating: false,
        };

        // once params are guaranteed initialized, setup the URL
        this.props.onChangeUrlQueryParams({ ...this.getSelections() });
    }

    getSelections = (): UrlParams => {
        const { year, in_force, includes_quoteds, pmls, return_periods, metrics, cat_view_types } = this.state;
        return { year, in_force, includes_quoteds, pmls, return_periods, metrics, cat_view_types } as UrlParams;
    };

    getQueryParams() {
        const { year, in_force, includes_quoteds, pmls, return_periods, carrier_id, metrics, as_of_type, cat_view_types } = this.state;
        const ys = [...pmls, ...metrics];
        return {
            ys,
            carrier: carrier_id,
            return_periods,
            as_of_type,
            cat_view_types,
            year,
            includes_quoteds,
            in_force,
        };
    }

    getActiveCarriers() {
        const year = this.state.year;
        return this.props.CONSTANTS.CARRIERS.filter((c: Types.Carrier) => {
            const { activated_on, deactivated_on } = c;
            const start = new Date(year, 0, 1);
            const end = new Date(year + 1, 0, 0);
            // TODO: Is deactivated_on inclusive?
            // Filter for carriers that were active in the desired time range
            // Special cases
            //   `deactivated_on` being empty means it was never deactivated
            //   `activated_on` being empty means it was activated since beginning of time
            return (!deactivated_on || start <= new Date(deactivated_on)) && (!activated_on || end >= new Date(activated_on));
        }).map((c: Types.Carrier) => {
            const { id, name, short_name } = c;
            return { value: id, display: `${short_name}: ${name}` };
        });
    }

    componentDidMount() {
        this.props.onGetAirPml(this.getQueryParams()).then((data: any[]) => {
            this.setState({ data, carriers: this.getActiveCarriers() });
        });
    }

    componentDidUpdate(prevProps: propTypes, prevState: stateTypes) {
        const s1 = _.omit(this.state, ['data', 'carriers', 'pmlDateData']);
        const s2 = _.omit(prevState, ['data', 'carriers', 'pmlDateData']);

        if (!_.isEqual(s1, s2)) {
            if (s1.year !== s2.year) {
                const carriers = this.getActiveCarriers();
                const newCarrierIsActive = carriers.find(c => {
                    return s2.carrier_id === c.value;
                });
                this.setState({ carriers, carrier_id: newCarrierIsActive ? s2.carrier_id : '' });
            }
            this.props.onGetAirPml(this.getQueryParams()).then((data: any[]) => {
                this.setState({ data });
            });
        }
    }

    handleChange = (key: string, value: any) => {
        const newState: any = { [key]: value, pmlDateData: null };
        this.props.onChangeUrlQueryParams({ [key]: value } as UrlParams);
        this.setState(newState);
    };

    handleChangeOptions = (key: string, value: any, added: boolean) => {
        const newVal = added ? { [key]: [value, ...this.state[key]] } : { [key]: this.state[key].filter((v: string) => v !== value) };
        this.props.onChangeUrlQueryParams(newVal as UrlParams);
        this.setState({ ...newVal, pmlDateData: null, selectedDate: null });
    };

    handleSelectDate = (date: Date) => {
        const kicks_in_on = moment(date).format(KICKS_IN_DATE_FORMAT);
        this.setState({ selectedDate: date });
        this.props.onGetPmlTableData({ kicks_in_on, ...this.getQueryParams() }).then((data: PmlDataType[]) => {
            this.setState({ pmlDateData: data });
        });
    };

    getHeaders = () => {
        const { return_periods, metrics } = this.state;
        return_periods.sort((a: string, b: string) => parseInt(a) - parseInt(b));
        metrics.sort();
        return [...return_periods, ...metrics];
    };

    calculatePMLAnalytics = () => {
        return this.props.onCalculatePmlData(this.getQueryParams()).then(data => {
            this.setState({ bg_task_id: data['id'] });
        });
    };

    renderGraph(): React.ReactNode {
        const { data } = this.state;
        return <MultiAxisLineGraph key={JSON.stringify(this.state.data)} onSelectDate={this.handleSelectDate} selections={this.getSelections()} data={data} />;
    }

    render(): React.ReactNode {
        const { pmlDateData, selectedDate } = this.state;
        return (
            <>
                <div className="tw-px-2 tw-max-w-[1150px] tw-overflow-auto tw-flex tw-flex-col tw-relative">
                    <TaskTypePanel taskType="CALCULATE_ALL_AIR_PMLS" />
                    <PanelArea selectedDate={selectedDate}>
                        <div>
                            <h4>Carrier:</h4>
                            <ComboBoxInput
                                name="carrier_id"
                                label={null}
                                object={this.state}
                                onChange={this.handleChange}
                                selectProps={{
                                    choices: [{ value: '', display: 'ALL' }, ...this.state.carriers],
                                }}
                            />

                            <h4 style={{ marginTop: '15px' }}>Year:</h4>
                            <ComboBoxInput
                                name="year"
                                label={null}
                                object={this.state}
                                onChange={this.handleChange}
                                selectProps={{
                                    choices: [
                                        { value: CURRENT_YEAR + 1, display: CURRENT_YEAR + 1 },
                                        { value: CURRENT_YEAR, display: CURRENT_YEAR },
                                        { value: CURRENT_YEAR - 1, display: CURRENT_YEAR - 1 },
                                    ],
                                }}
                            />

                            <h4 style={{ marginTop: '15px' }}>Time Period:</h4>
                            <ComboBoxInput
                                name="in_force"
                                label={null}
                                object={this.state}
                                onChange={this.handleChange}
                                selectProps={{
                                    choices: [
                                        { value: 'true', display: 'In Force' },
                                        { value: 'false', display: 'Year to Date' },
                                    ],
                                }}
                            />
                        </div>

                        <div className="return-period-grid">
                            <SelectBox
                                onSelect={this.handleChangeOptions}
                                object={this.state}
                                name="return_periods"
                                boxChoices={[
                                    { optionsLabel: 'Return Period' },
                                    { name: 'return_periods', value: '20', label: '20' },
                                    { name: 'return_periods', value: '50', label: '50' },
                                    { name: 'return_periods', value: '100', label: '100' },
                                    { name: 'return_periods', value: '200', label: '200' },
                                    { name: 'return_periods', value: '250', label: '250' },
                                    { name: 'return_periods', value: '500', label: '500' },
                                    { name: 'return_periods', value: '1000', label: '1000' },
                                ]}
                            />
                            <SelectBox
                                onSelect={this.handleChangeOptions}
                                object={this.state}
                                name="pmls"
                                boxChoices={[
                                    { optionsLabel: 'PML' },
                                    { name: 'pmls', value: 'AEP', label: 'AEP' },
                                    { name: 'pmls', value: 'OEP', label: 'OEP' },
                                    { optionsLabel: 'PML Ratio', className: 'margin-top' },
                                    { name: 'pmls', value: 'AEP_RATIO', label: 'AEP' },
                                    { name: 'pmls', value: 'OEP_RATIO', label: 'OEP' },
                                ]}
                            />
                        </div>

                        <SelectBox
                            onSelect={this.handleChangeOptions}
                            object={this.state}
                            name="cat_view_types"
                            boxChoices={[
                                { optionsLabel: 'Cat View' },
                                { name: 'cat_view_types', value: 'P', label: 'Pricing' },
                                { name: 'cat_view_types', value: 'B', label: 'Broker' },
                            ]}
                        />

                        <SelectBox
                            onSelect={this.handleChangeOptions}
                            object={this.state}
                            name="includes_quoteds"
                            boxChoices={[
                                { optionsLabel: 'Status' },
                                { name: 'includes_quoteds', value: 'false', label: 'Bound' },
                                { name: 'includes_quoteds', value: 'true', label: 'Quoted + Bound' },
                            ]}
                        />

                        <SelectBox
                            onSelect={this.handleChangeOptions}
                            object={this.state}
                            name="metrics"
                            boxChoices={[
                                { optionsLabel: 'Metrics' },
                                { name: 'metrics', value: 'AAL', label: 'AAL' },
                                { name: 'metrics', value: 'COUNT', label: 'Count' },
                                { name: 'metrics', value: 'PREMIUM', label: 'Premium' },
                            ]}
                        />
                    </PanelArea>
                    {this.renderGraph()}
                    {this.props?.featureFlags?.manual_pml_calculate && (
                        <Button
                            disabled={this.state.isCalculating}
                            className={`default blue fit-content-centered`}
                            onClick={() => this.calculatePMLAnalytics()}
                            progressInfoPath={getTaskPath(BACKGROUND_TASK_TYPES.CALCULATE_ALL_AIR_PMLS, this.state.bg_task_id)}
                        >
                            Calculate All AIR PMLs
                        </Button>
                    )}
                    {!_.isNull(pmlDateData) && <AnalyticsTable data={pmlDateData} headers={this.getHeaders()} selectedDate={this.state.selectedDate} />}
                </div>
            </>
        );
    }
}
