import * as React from 'react';
import moment from 'moment';
import { ReferralDataType } from '../../ts-types/DataTypes';
import { useSubmissionId } from '../../hooks/submissions';

const DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm A';

export function ApprovedRow(props: ReferralDataType) {
    return (
        <React.Fragment>
            <div className="grid-layout sub-grid">
                <div>{props.verbose_name}</div>
            </div>
            <div className="grid-layout sub-grid">
                <div style={{ wordBreak: 'normal', whiteSpace: 'pre-line' }}>
                    {props.verbose_name === 'Geographic Limits' && !!props.description ? (
                        <GeographicLimitsDescription {...props.description} />
                    ) : (
                        <p> {props.description} </p>
                    )}
                </div>
            </div>
            <div className="grid-layout sub-grid">
                <div>
                    {props.requested_by_name} <br /> {moment(props.date_requested).format(DATE_TIME_FORMAT)}
                </div>
            </div>
            <div className="grid-layout sub-grid">
                <div>
                    {props.approved_by_name} <br /> {moment(props.date_approved).format(DATE_TIME_FORMAT)}
                </div>
            </div>
        </React.Fragment>
    );
}

export function RequiresApprovalRow(props: ReferralDataType) {
    return (
        <React.Fragment>
            <div className="grid-layout sub-grid">
                <div>{props.verbose_name}</div>
            </div>
            <div className="grid-layout sub-grid">
                <div style={{ wordBreak: 'normal', whiteSpace: 'pre-line' }}>
                    {props.verbose_name === 'Geographic Limits' ? <GeographicLimitsDescription {...props.description} /> : <p> {props.description} </p>}
                </div>
            </div>
            <div className="grid-layout sub-grid">
                <div>
                    <If condition={props.requested_by_name && props.date_requested}>
                        {props.requested_by_name} at {moment(props.date_requested).format(DATE_TIME_FORMAT)}
                    </If>
                    <If condition={!(props.requested_by_name && props.date_requested)}>Unrequested</If>
                </div>
            </div>
        </React.Fragment>
    );
}

const createBuildingLink = (submissionId: number, building: object) => {
    return (
        <>
            <b>
                <a style={{ textDecoration: 'underline' }} href={`/submissions/${submissionId}/current/editor/${building[0]}?tab=data-entry`}>
                    {building[1]}
                </a>
            </b>
            <br></br>
        </>
    );
};

export function GeographicLimitsDescription(data: object[]) {
    const submissionId = useSubmissionId();
    data = Object.values(data);

    return (
        <table>
            <thead>
                <tr>
                    <th>Geographic Limit Referral</th>
                    <th>Percentage of TIV</th>
                    <th>Location/Building contributing to the Geographic Limit Referral</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td>
                            <b>{row[0]}</b>
                        </td>
                        <td>{row[1]}</td>
                        <td>{row[2].map((building, _) => createBuildingLink(submissionId, building))}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
