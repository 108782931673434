import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { RowSelectedFnType, LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import TransactionsBottomPanelApp from '../apps/TransactionsBottomPanelApp';
import { AppContextType } from '../../../../ts-types/AppTypes';

import isEqual from 'react-fast-compare';
import { Types } from '../../../../ts-types/viki-types';
import { RootState } from '../../../../store';

type ownPropTypes = {
    CONSTANTS: Types.Constants;
    appContext: AppContextType;
    onRowSelected: RowSelectedFnType;
    onSaveFn: (...params: any) => any;
    selectedRowIndex: number;
    tableData: LiteTableDataType;
    tableGlossary: Record<string, any>;
    prevTransaction: Record<string, any>;
    carrierPools: Record<string, any>[];
};

const mapStateToProps = (state: RootState, ownProps: ownPropTypes) => {
    let { appContext } = ownProps;
    let { currentTransaction } = appContext;
    return {
        prevTransaction: state.transactions.rowData[currentTransaction.previous_transaction_id],
    };
};

const mapDispatchToProps = (_dispatch: DispatchType, _ownProps: ownPropTypes) => {
    return {};
};

class TransactionsBottomPanelContainer extends React.Component<ownPropTypes> {
    shouldComponentUpdate(nextProps: ownPropTypes) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        return <TransactionsBottomPanelApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(TransactionsBottomPanelContainer) as any;
export default connected;
