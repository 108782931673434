import * as React from 'react';
import PanelView from '../PanelView';

const Viki404Page = () => {
    return (
        <PanelView topPanelHeader="Error: Page Not Found">
            <div className="viki-404-page flex column centered jc_c w-100">
                <h2>404</h2>
                <p>Page not found</p>
            </div>
        </PanelView>
    );
};

export default Viki404Page;
