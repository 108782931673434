import React from 'react';

import { TableConfigType, PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import { AppContextType } from '../../ts-types/AppTypes';
import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';

type propTypes = {
    tableData: PagedTableDataType;
    tableConfig?: TableConfigType;
    appContext: AppContextType;
};

const RegionalCATSummaryApp: React.FC<propTypes> = props => {
    const panelRowActions: any = {};

    const tableConfig = props.tableConfig || { ...PAGED_TABLES.REGIONAL_CAT_SUMMARY };

    return (
        <PagedTableContainer
            {...props}
            hasSearchPanel={false}
            panelActions={{ panelRowActions }}
            editingDisabled={true}
            allowsDragging={false}
            tableConfig={tableConfig}
            aboveGridContent={
                <div className="tw-flex">
                    <div className="main-label tw-flex-1 tw-mb-2">Regional CAT Summary</div>
                </div>
            }
        />
    );
};

export default RegionalCATSummaryApp;
