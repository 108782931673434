import * as React from 'react';
import { connect } from 'react-redux';

import BuildingCoverageBottomPanelApp from './BuildingCoverageBottomPanelApp';

type ownPropTypes = {
    children?: React.ReactNode;
};
type propTypes = ownPropTypes;

const mapStateToProps = (state: Record<string, any>, ownProps: ownPropTypes) => {
    return {};
};

const mapDispatchToProps = (dispatch: any, ownProps: ownPropTypes) => {
    return {};
};

class BuildingCoverageBottomPanelContainer extends React.Component<propTypes> {
    render() {
        return <BuildingCoverageBottomPanelApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BuildingCoverageBottomPanelContainer);
export default connected;
