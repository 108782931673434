import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormValues, PolicyTermsFormField } from '../utils/form';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';

export const EquipmentBreakdown = () => {
    const EQUIPMENT_BREAKDOWN_SUBLIMITS = useAppSelector(state => state.global.CONSTANTS.EQUIPMENT_BREAKDOWN_SUBLIMITS);
    return (
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="eb_sublimit_spoilage" selections={EQUIPMENT_BREAKDOWN_SUBLIMITS} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="eb_sublimit_expediting_expenses" selections={EQUIPMENT_BREAKDOWN_SUBLIMITS} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormInput<PolicyTermsFormValues> name="eb_sublimit_drying_out" disabled dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="eb_sublimit_hazardous_substances" selections={EQUIPMENT_BREAKDOWN_SUBLIMITS} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="eb_sublimit_data_restoration" selections={EQUIPMENT_BREAKDOWN_SUBLIMITS} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="eb_sublimit_dicc" selections={EQUIPMENT_BREAKDOWN_SUBLIMITS} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="eb_sublimit_off_premises_coverage" selections={EQUIPMENT_BREAKDOWN_SUBLIMITS} disabled />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="eb_sublimit_electronic_vandalism" selections={EQUIPMENT_BREAKDOWN_SUBLIMITS} disabled />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormButtonGroup<PolicyTermsFormValues>
                    name="is_equipment_breakdown_referral_flagged_by_user"
                    items={[
                        { display: 'Yes', value: true },
                        { display: 'No', value: false },
                    ]}
                />
            </PolicyTermsFormField>
        </div>
    );
};
