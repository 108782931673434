import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import ValidationBadge from '@archinsurance-viki/property-jslib/src/components/widgets/ValidationBadge';
import { QuoteType, SubmissionDataType } from '../../../ts-types/DataTypes';
import { submissionIsValidatable } from '../../../components/submissionlog/Helpers';
import { SUPER_VERBOSE_WARNING } from '../../../constants/LoggingConstants';
import { BottomPanelType } from '@archinsurance-viki/property-jslib/src/components/panels/BottomPanel';
import { RowValidationType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

export const VALIDATION_TYPES = [
    { type: 'CAN_PRICE_AOP', caption: 'AOP Pricing', isBlocked: false, details: null, isWarning: false },
    { type: 'CAN_PRICE_CAT', caption: 'Touchstone Pricing', isBlocked: false, details: null, isWarning: false },
    { type: 'CAN_BE_UW_REVIEW', caption: 'UW Review', isBlocked: false, details: null, isWarning: false },
    { type: 'CAN_BE_BOUND', caption: 'Binding', isBlocked: false, details: null, isWarning: false },
    { type: 'CAN_BE_QUOTED', caption: 'Quoting', isBlocked: false, details: null, isWarning: false },
    { type: 'CAN_ISSUE_POLICY', caption: 'Issuing', isBlocked: false, details: null, isWarning: false },
];

const PROGRESS_INDICATOR_INLINE = <div className="progress-indicator inline number" />;
const HANDLE_DISABLED_NUMBER = <div className="number">—</div>;

function getValidationsHandle(errors, warnings) {
    return (
        <>
            <div className="text">Errors</div>
            {errors}
            <div className="text">Warnings</div>
            {warnings}
        </>
    );
}

export const VALIDATING = {
    handleContent: getValidationsHandle(PROGRESS_INDICATOR_INLINE, PROGRESS_INDICATOR_INLINE),
    singleHeight: true,
    content: (
        <div className="viki-loader validating">
            <div className="txt">Validating</div>
            <div className="progress-indicator below large" />
        </div>
    ),
};

const INIT = {
    handleContent: getValidationsHandle(HANDLE_DISABLED_NUMBER, HANDLE_DISABLED_NUMBER),
    singleHeight: true,
    content: <div className="note-general">Please select a submission for validation.</div>,
};

function hydrateValidationsObjects(validationData) {
    let detailObjs = validationData ? validationData.detailObjs || {} : {};

    let validations = {
        ERROR: [],
        WARNING: [],
        detailObjArray: _.map(detailObjs, (detailObj, key) => {
            return { ...detailObj, key };
        }),
    };

    if (validationData) {
        for (let validationType of VALIDATION_TYPES) {
            if (validationData.ERROR && validationData.ERROR[validationType.type] && validationData.ERROR[validationType.type].length) {
                let hydrated = _.clone(validationType);
                hydrated.details = validationData.ERROR[validationType.type];
                hydrated.isBlocked = true;
                validations.ERROR.push(hydrated);
            }

            if (validationData.WARN && validationData.WARN[validationType.type] && validationData.WARN[validationType.type].length) {
                let hydrated = _.clone(validationType);
                hydrated.details = validationData.WARN[validationType.type];
                hydrated.isWarning = true;
                validations.WARNING.push(hydrated);
            }
        }
    }

    return validations;
}

function getErrorDetails(error, baseUrl) {
    const details: Record<string, any> = {
        description: error.coverage_option_scoped_description || null,
        urlData: null,
        key: error.key,
    };

    const commonUrlV1 = `${baseUrl}/submissions/new/${error.submission_id || error.account_id}`;
    const commonUrlV2 = `${baseUrl}/submissions/${error.submission_id || error.account_id}/current`;
    switch (error.affects) {
        case 'submission':
            details.urlData = {
                url: `${commonUrlV2}/overview`,
                linkText: 'Go to Overview',
            };
            break;
        case 'building':
            details.urlData = {
                url: `/submissions/${error.submission_id}/current/editor/${error.building_id}`,
                linkText: `Go to Building`,
            };
            break;
        case 'policy_coverage':
            details.urlData = {
                url: getCoverageOptionLink(error.coverage_option_id, error.submission_id),
                linkText: `Go to Policy Coverage`,
            };
            break;
        case 'building_coverage':
            details.urlData = {
                url: `${commonUrlV1}/coverage_options/${error.coverage_option_id}/building/${error.building_id}`,
                linkText: `Go to Building Coverage`,
            };
            break;
        case 'tiv_guidelines':
            details.urlData = {
                url: `/submissions/${error.submission_id}/current/underwriting/${error.coverage_option_id}/guidelines`,
                linkText: `Go to TIV Guidelines`,
            };
            break;
        case 'producer':
            details.urlData = {
                url: `${commonUrlV1}/producer`,
                linkText: `Go to Producer`,
            };
            break;
        case 'insured':
            details.urlData = {
                url: `${commonUrlV1}/insured`,
                linkText: `Go to Insured`,
            };
            break;
        case 'pricing':
            details.urlData = {
                url: `/submissions/${error.submission_id ?? error.original_submission_id}/current/underwriting/${
                    error.coverage_option_id ?? error.account_transaction_id
                }/pricing`,
                linkText: `Go to pricing`,
            };
            break;
        case 'carrier_participation':
            details.urlData = {
                url: `/submissions/${error.submission_id}/current/underwriting/${error.coverage_option_id}/pricing`,
                linkText: `Go to pricing`,
            };
            break;
        default:
            if (error.affects && SUPER_VERBOSE_WARNING) {
                console.warn('Unhandled error affects link:', error.affects, error);
            }
            break;
    }

    return details;
}

function getCoverageOptionLink(quoteId: number, submissionId: number, website = '') {
    return `${website}/submissions/${submissionId}/current/underwriting/${quoteId}/policy_terms`;
}

function renderValidationRows(
    groupedValidationsByMessage: Record<string, any>,
    validationList: { caption: string; type: string; details: any }[],
    type: string,
    oldWebsite: string
) {
    let validationRows = [];

    for (let [message, validationObjects] of Object.entries(groupedValidationsByMessage)) {
        let messageBadgeMap = {};

        for (let validationObj of validationObjects) {
            for (let validationData of validationList) {
                if (_.includes(validationData.details, validationObj.key)) {
                    messageBadgeMap[validationData.type] = <ValidationBadge key={validationData.type} {...validationData} />;
                }
            }
        }

        let messageBadges: any[] = Object.values(messageBadgeMap);
        if (!messageBadges.length) {
            continue;
        }

        let affectedRows = [];
        validationObjects = _.sortBy(validationObjects, o => o.coverage_option_scoped_description);

        for (let validationObj of validationObjects) {
            let errorDetails = getErrorDetails(validationObj, oldWebsite);
            if (!errorDetails || !(errorDetails.description || errorDetails.urlData)) {
                continue;
            }

            let { key, urlData, description } = errorDetails;
            affectedRows.push(
                <div key={key} className="affects-error-row details">
                    {urlData && (
                        <a className="btn btn-default" href={urlData.url} target="_blank" rel="noopener noreferrer">
                            {urlData.linkText}
                        </a>
                    )}
                    {description}
                </div>
            );
        }

        validationRows.push(
            <tr key={validationRows.length}>
                <td>{validationRows.length + 1}</td>
                <td>
                    <div className="message">{message}</div>
                    {affectedRows}
                </td>
                <td>{messageBadges}</td>
            </tr>
        );
    }

    return validationRows as React.ReactNode[];
}

function renderValidations(type: string, validations: Record<string, any>, oldWebsite: string) {
    let { detailObjArray } = validations;

    let validationList = validations[type];

    if (!validationList || !detailObjArray) {
        return [];
    }

    let groupedValidationsByMessage = _.groupBy(detailObjArray, 'message');
    return renderValidationRows(groupedValidationsByMessage, validationList, type, oldWebsite);
}

export function renderValidationsTable(rows: React.ReactNode[], header: string, key: string, type: string): React.ReactNode {
    return (
        <table key={key} className={classNames('errors-warnings-table', type)}>
            <thead>
                <tr>
                    <th colSpan={4}>
                        <legend className="small">{header}</legend>
                    </th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
}

export function renderQuotesValidations(type: string, quotesList: QuoteType[], submissionId: number, oldWebsite: string): Record<string, any> {
    const quoteValidations = [];

    for (let quote of quotesList) {
        if (!quote.reprice || !quote.reprice.validation_messages) {
            continue;
        }

        let renderedValidations = renderValidations(type, hydrateValidationsObjects(quote.reprice.validation_messages), oldWebsite);

        if (!renderedValidations.length) {
            continue;
        }

        let coverageOptionLink = getCoverageOptionLink(quote.id, submissionId, oldWebsite);

        quoteValidations.push({
            renderedValidations,
            quote: quote,
            header: (
                <a className="coverage-option-link" href={coverageOptionLink} target="_blank" rel="noopener noreferrer">
                    {`CO ${quote.id} - ${quote.description}`}
                </a>
            ),
        });
    }

    const returnObj = {
        rendered: [],
        total: 0,
    };

    for (let validation of quoteValidations) {
        returnObj.total += validation.renderedValidations.length;
        returnObj.rendered.push(renderValidationsTable(validation.renderedValidations, validation.header, validation.quote.id, type));
    }

    return returnObj;
}

function getErrorPanel(errors: Record<string, any>, warnings: Record<string, any>): BottomPanelType {
    return {
        handleContent: getValidationsHandle(<div className="number red-txt">{errors.total}</div>, <div className="number orange-txt">{warnings.total}</div>),
        singleHeight: true,
        className: 'quote-error-panel',
        content: (
            <div className={'grid-layout gl-2 errors-wrapper panel-area'}>
                <div className="errors-warnings-content">
                    <h1>
                        <span>{errors.total}</span> Errors
                    </h1>
                    {errors.rendered}
                </div>
                <div className="errors-warnings-content warnings">
                    <h1>
                        <span>{warnings.total}</span> Warnings
                    </h1>
                    {warnings.rendered}
                </div>
            </div>
        ),
    };
}

const BOUND = {
    handleContent: getValidationsHandle(HANDLE_DISABLED_NUMBER, HANDLE_DISABLED_NUMBER),
    singleHeight: true,
    content: <div className="note-general">Validations are disabled as submission is not editable</div>,
};

export function getQuoteErrorPanel(quotesList: QuoteType[], submissionId: number, oldWebsite: string): Record<string, any> {
    for (let quote of quotesList) {
        if (!quote.reprice) {
            return VALIDATING;
        }
    }
    const quoteErrors = renderQuotesValidations('ERROR', quotesList, submissionId, oldWebsite);
    const quoteWarnings = renderQuotesValidations('WARNING', quotesList, submissionId, oldWebsite);

    return getErrorPanel(quoteErrors, quoteWarnings);
}

export const getValidationsFromData = (data: RowValidationType, oldWebsite: string) => {
    let hydratedValidations = hydrateValidationsObjects(data);

    const errors = renderValidations('ERROR', hydratedValidations, oldWebsite);
    const warnings = renderValidations('WARNING', hydratedValidations, oldWebsite);

    return { errors, warnings };
};

export function getValidationsPanel(submission: SubmissionDataType, oldWebsite: string): BottomPanelType {
    if (!submission || !submission._actions) {
        return INIT;
    }

    if (!submissionIsValidatable(submission)) {
        // Do this in between as we want to show BOUND even though we have no validations
        return BOUND;
    }

    if (!submission._validations || !submission._validations.ERROR) {
        return VALIDATING;
    }

    let hydratedValidations = hydrateValidationsObjects(submission._validations);

    let errors = renderValidations('ERROR', hydratedValidations, oldWebsite);
    let warnings = renderValidations('WARNING', hydratedValidations, oldWebsite);

    let errorsObj = {
        rendered: renderValidationsTable(errors, null, 'ERROR', 'ERROR'),
        total: errors.length,
    };

    let warningsObj = {
        rendered: renderValidationsTable(warnings, null, 'WARNING', 'WARNING'),
        total: warnings.length,
    };

    return getErrorPanel(errorsObj, warningsObj);
}
