import classNames from 'classnames';

import LeftPanel from './navigation/LeftPanel';
import TopPanel from './navigation/TopPanel';
import { PANELS, TRANSACTION_PULLDOWN_STATES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { useAppSelector } from '../hooks/redux';

import { useAccountId } from '../hooks/account';
import { useAccountDataQuery } from '../services/endpoints/account';
import { useSubmissionDataQuery } from '../services/endpoints/submission';
import { useSubmissionId } from '../hooks/submissions';
import { ToastProvider } from '../hooks/toast';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';

type propTypes = {
    noCurrentSubmission?: boolean;
    transactionPulldown?: (typeof TRANSACTION_PULLDOWN_STATES)[keyof typeof TRANSACTION_PULLDOWN_STATES];
    hasLeftPanel?: boolean;
    topPanelHeader?: React.ReactNode | string;
    // any children you pass in
    children: React.ReactNode;
};

export default function PanelView(props: propTypes) {
    const { hasLeftPanel = true, noCurrentSubmission, transactionPulldown, topPanelHeader } = props;
    const openPanels = useAppSelector(state => state.uiState.openPanels);
    const leftPanelOpen = hasLeftPanel && openPanels[PANELS.LEFT];

    const accountId = useAccountId();
    const submissionId = useSubmissionId();

    useAccountDataQuery({ id: accountId }, { skip: !accountId });
    useSubmissionDataQuery({ id: submissionId }, { skip: !submissionId });

    const toast = useRef<Toast>();

    return (
        <>
            <ToastProvider value={{ toast: toast.current }}>
                <div className={classNames('main-content', { 'menu-hidden': !leftPanelOpen, 'tw-top-[65px]': typeof topPanelHeader === 'string' })}>
                    <Toast ref={toast} className="tw-absolute tw-top-0 tw-right-0" />
                    <If condition={hasLeftPanel}>
                        <LeftPanel />
                    </If>
                    {props.children}
                </div>
            </ToastProvider>
            <TopPanel noCurrentSubmission={noCurrentSubmission} transactionPulldown={transactionPulldown} topPanelHeader={topPanelHeader} />
        </>
    );
}
