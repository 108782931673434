import { Popup } from '@archinsurance-viki/property-jslib';
import { geocodeBuilding_server, persistRowData_server } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { ActionPanelActionsType, ActionPanelActionType } from '@archinsurance-viki/property-jslib/src/ts-types/tables/action-panel';
import { PersistDataType } from '@archinsurance-viki/property-jslib/src/ts-types/tables/component-props';
import { PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/tables/data';
import { formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { getLatestSOV } from '@archinsurance-viki/property-jslib/src/utils/documents-helpers';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import React from 'react';
import {
    fetchAllBuildfax,
    fetchAllCapeAnalytics,
    fetchAllHazardHubData,
    fetchAllIntermapData,
    fetchAllMSBData,
    fetchAllPingData,
    fetchBuildfax,
    fetchCapeAnalytics,
    fetchIntermapData,
    fetchMSBData,
    fetchPingData,
    geocodeWithPreferredSource,
    popValuationOverrideModal,
} from '../../actions/BuildingActions';
import { dismissGeocodeWarnings_server } from '../../actions/SubmissionActions';
import { BACKGROUND_TASK_TYPES } from '../../constants/TaskConstants';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useFetchHazardHubDataMutation } from '../../services/apiSlice';
import { BuildingDataType } from '../../ts-types/DataTypes';
import { isBoundReinstatementOrReversion, isInReviewReinstatementOrReversion } from '../../utils/overview-helpers';
import { getTaskSubtitles } from '../../utils/task-helpers';
import BuildingsBottomPanelContainer from '../common/panels/containers/BuildingsBottomPanelContainer';
import { submissionIsBound, submissionIsEditable, transactionIsPremiumBearing } from '../submissionlog/Helpers';
import { RAE_SITE_ID } from '../../constants/SiteConstants';
import { Types } from '../../ts-types/viki-types';

export default function GridEditorApp() {
    const {
        buildingDataConfig,
        currentBuildingId,
        currentQuote,
        currentSubmission,
        currentSubmissionId,
        currentTransaction,
        documentsLoaded,
        documentsLoading,
        oldWebsite,
        originalSubmission,
        originalSubmissionId,
    } = useAppContext();
    const appContextFns = useAppContextFns();
    const dispatch = useAppDispatch();

    const apiParamsKey = getApiParamsKey({ submission_id: currentSubmissionId });
    const tableData = useAppSelector(state => state.buildings[apiParamsKey] ?? ({ rows: [], rowData: {} } as PagedTableDataType));

    const tableConfig = buildingDataConfig;
    const featureFlags = useAppSelector(state => state.global.featureFlags as Types.FeatureFlags);
    const ENV = useAppSelector(state => state.global.ENV);
    const documents = useAppSelector(state =>
        documentsLoaded
            ? ((state.submissionDocumentsMap.map[currentSubmissionId] ?? []) as number[]) // TODO: handle this type properly
                  .map(dId => state.documents.rowData[dId] as DocumentDataType)
                  .filter(doc => doc !== null)
            : null
    );

    const [fetchHazardHubData, { isLoading: isHazardHubLoading }] = useFetchHazardHubDataMutation();
    const onGeocodeBuilding = () => dispatch(geocodeBuilding_server(buildingDataConfig, currentBuildingId));
    const onReverseGeocodeBuilding = () => dispatch(geocodeBuilding_server(buildingDataConfig, currentBuildingId, true));
    const onHideGeocodingWarning = (data: PersistDataType) => dispatch(persistRowData_server(buildingDataConfig, data));
    const onHideAllGeocodingWarnings = () => dispatch(dismissGeocodeWarnings_server(currentSubmissionId));
    const onGeocodeWithPreferredSource = () => dispatch(geocodeWithPreferredSource(currentSubmissionId));
    const onSelectValuationOverride = () => dispatch(popValuationOverrideModal(currentSubmissionId));
    const onFetchAllHazardHubData = () => dispatch(fetchAllHazardHubData(currentSubmissionId));
    const onFetchPingData = () => dispatch(fetchPingData(currentBuildingId));
    const onFetchAllPingData = () => dispatch(fetchAllPingData(currentSubmissionId));
    const onFetchMSBData = () => dispatch(fetchMSBData(currentBuildingId));
    const onFetchAllMSBData = () => dispatch(fetchAllMSBData(currentSubmissionId));
    const onFetchBuildfax = () => dispatch(fetchBuildfax(currentBuildingId));
    const onFetchAllBuildfax = () => dispatch(fetchAllBuildfax(currentSubmissionId));
    const onFetchCapeAnalytics = () => dispatch(fetchCapeAnalytics(currentBuildingId));
    const onFetchAllCapeAnalytics = () => dispatch(fetchAllCapeAnalytics(currentSubmissionId));
    const onFetchIntermapData = () => dispatch(fetchIntermapData(currentBuildingId));
    const onFetchAllIntermapData = () => dispatch(fetchAllIntermapData(currentSubmissionId));

    const onFetchHazardHubData = async () => {
        if (!isHazardHubLoading) {
            try {
                await fetchHazardHubData({ buildingId: currentBuildingId }).unwrap();
            } catch (error) {
                if (!error.handled) {
                    console.error('HazardHub request failed', error); // TODO: this will only happen if something unexpected happens in the base query, what do we do in that case?
                }
            }
        }
    };

    const onRowSelected = (buildingId?: number | 'loading', replaceInUrl?: boolean) => {
        appContextFns.onNavigate({ buildingId }, replaceInUrl);
    };

    const usesBuildFax = () => {
        const integration = ENV.TENANT_SETTINGS.integrations.find(i => i.api === 'BUILDFAX');
        return integration?.is_enabled;
    };

    const onGeocode = (data: any, target: HTMLElement) => {
        Popup.plugins().popover(
            {
                title: `Geocode using`,
                buttons: {
                    left: [
                        {
                            text: 'Address',
                            className: 'green',
                            action: () => {
                                onGeocodeBuilding();
                                Popup.close();
                            },
                        },
                    ],
                    right: [
                        {
                            text: 'Lat / Long',
                            className: 'green',
                            action: () => {
                                onReverseGeocodeBuilding();
                                Popup.close();
                            },
                        },
                    ],
                },
            },
            target
        );
    };

    const latestSOV = React.useRef(null as { secure_url: string });
    React.useEffect(() => {
        if (!originalSubmission) {
            appContextFns.onLoadAccountSubmissionViewIfNeeded(originalSubmissionId, originalSubmission);
            return;
        }

        if (!originalSubmissionId || documentsLoading) {
            return;
        }

        if (!documentsLoaded) {
            appContextFns.onLoadDocumentsForSubmission(originalSubmissionId);
        }

        if (!latestSOV.current && documents) {
            latestSOV.current = getLatestSOV(documents);
        }
    });

    if (!currentSubmissionId) {
        return null;
    }

    const isRae = currentSubmission?.site_id === RAE_SITE_ID;
    const isRaeAndBoundCurrentSubmission = isRae && submissionIsBound(currentSubmission);

    if (isInReviewReinstatementOrReversion(currentTransaction) || isBoundReinstatementOrReversion(currentTransaction)) {
        tableConfig.apiParams.submission_id = currentTransaction.last_interval_submission_id; // TODO: mutating = NO
    }

    const currentBuilding = (tableData.rowData[currentBuildingId] || {}) as BuildingDataType;
    const { street, city, state, zipcode } = currentBuilding;
    const isPremiumBearing = transactionIsPremiumBearing(currentTransaction);
    const isSubmissionEditable = submissionIsEditable(currentSubmission) && !isRaeAndBoundCurrentSubmission;
    const isReadonly = !isSubmissionEditable || !isPremiumBearing;
    const editable = !isReadonly;
    const CAPE_ANALYTICS_MIN_BUILDING_VALUATION = ENV?.TENANT_SETTINGS?.cape_analytics_settings?.min_building_valuation;
    const CAPE_ANALYTICS_MIN_BUILDING_SQR_FT = ENV?.TENANT_SETTINGS?.cape_analytics_settings?.min_building_sqr_ft;
    const CAPE_ANALYTICS_ENABLED = ENV?.TENANT_SETTINGS?.integrations.find(i => i.api === 'CAPEANALYTICS')?.is_enabled;
    const INTERMAP_ENABLED = ENV?.TENANT_SETTINGS?.integrations.find(i => i.api === 'INTERMAP')?.is_enabled;
    const building_value = currentBuilding.ventus_override_value || currentBuilding.sov_value;

    const disableCapeAnalytics = () => {
        let disabled =
            !street ||
            !city ||
            !state ||
            !zipcode ||
            !CAPE_ANALYTICS_ENABLED ||
            (building_value > 0 && building_value < CAPE_ANALYTICS_MIN_BUILDING_VALUATION) ||
            (currentBuilding.sqr_footage > 0 && currentBuilding.sqr_footage < CAPE_ANALYTICS_MIN_BUILDING_SQR_FT);

        return disabled;
    };

    const panelRowActions: ActionPanelActionsType = {
        addAction: editable && !isRaeAndBoundCurrentSubmission,
        deleteAction: editable && !isRaeAndBoundCurrentSubmission,
        duplicateAction: editable && !isRaeAndBoundCurrentSubmission,
        objectName: 'Building',
        additionalActions: [],
    };

    const getCapeError = () => {
        if (!CAPE_ANALYTICS_ENABLED) {
            return 'Cape Analytics is currently disabled.';
        }

        let error =
            `To use Cape Analytics, address, city, state, and zipcode need to be set and the building value must be greater` +
            ` > ${formatNumber(CAPE_ANALYTICS_MIN_BUILDING_VALUATION, '$', undefined, 0)} w/ a sqrt footage > ${formatNumber(
                CAPE_ANALYTICS_MIN_BUILDING_SQR_FT,
                undefined,
                undefined,
                0
            )}`;

        return error;
    };

    if (currentBuilding && currentBuilding.building_may_need_geocoding) {
        let { id, pretty_address, geo } = currentBuilding;

        let hideGeocodeWarnings = () => {
            let data = {
                request: {
                    data: { user_confirmed_address: pretty_address },

                    pks: [id],
                },
                undo: {
                    type: 'REQUEST_DATA' as const,
                    request: {
                        data: { user_confirmed_address: null },
                        pks: [id],
                    },
                },
            };
            onHideGeocodingWarning(data);
        };

        if (!isRaeAndBoundCurrentSubmission) {
            panelRowActions.additionalActions.push({
                label: 'Geocode Building?',
                action: geo ? onGeocode : onGeocodeBuilding,
                color: 'orange',
                id: 'geocode-building',
                order: 4,
                secondary: {
                    action: hideGeocodeWarnings,
                    icon: 'close',
                },
            });
        }
    }

    const allBuildingActions: ActionPanelActionType[] = [];

    if (editable && tableData.rows.length) {
        allBuildingActions.push({
            label: 'Valuation Override',
            action: () => onSelectValuationOverride(),
            color: 'blue',
            id: 'validation-override',
            order: 1,
            visible: !isRaeAndBoundCurrentSubmission,
        });

        if (currentBuilding && !currentBuilding.building_may_need_geocoding) {
            if (featureFlags.enable_ping_data && !isRaeAndBoundCurrentSubmission) {
                panelRowActions.additionalActions.push({
                    label: 'Fetch HazardHub Data (current Building)',
                    action: () => onFetchPingData(),
                    color: 'blue',
                    id: 'fetch-ping',
                    order: 5,
                });
            } else if (!isRaeAndBoundCurrentSubmission) {
                panelRowActions.additionalActions.push({
                    label: 'Fetch HazardHub Data (current Building)',
                    action: () => onFetchHazardHubData(),
                    color: 'blue',
                    id: 'fetch-hazardhub',
                    order: 5,
                });
            }

            if (!isRaeAndBoundCurrentSubmission) {
                panelRowActions.additionalActions.push({
                    label: 'Fetch MSB Data (current Building)',
                    action: () => onFetchMSBData(),
                    color: 'blue',
                    id: 'fetch-msbdata',
                    order: 5,
                });
            }

            if (!isRaeAndBoundCurrentSubmission) {
                panelRowActions.additionalActions.push({
                    label: 'Fetch BuildFax (Current Building)',
                    action: () => onFetchBuildfax(),
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_BUILDFAX, currentSubmissionId || -1),
                    color: 'blue',
                    id: 'fetch-buildfax',
                    order: 5,
                    disabled: !street || !city || !state || !zipcode || !usesBuildFax(),
                    disabledReasons: 'Need to provide address, city, state, and zipcode to call BuildFax.',
                });
            }

            if (!isRaeAndBoundCurrentSubmission) {
                panelRowActions.additionalActions.push({
                    label: 'Fetch Cape Analytics (Current Building)',
                    action: () => onFetchCapeAnalytics(),
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_CAPE_ANALYTICS, currentSubmissionId || -1),
                    color: 'blue',
                    id: 'fetch-cape-analytics',
                    order: 5,
                    disabled: disableCapeAnalytics(),
                    disabledReasons: getCapeError(),
                });
            }

            if (INTERMAP_ENABLED && !isRaeAndBoundCurrentSubmission) {
                panelRowActions.additionalActions.push({
                    label: 'Fetch Intermap Data (current Building)',
                    action: () => onFetchIntermapData(),
                    color: 'blue',
                    id: 'fetch-intermap',
                    order: 5,
                });
            }

            if (featureFlags.enable_ping_data) {
                allBuildingActions.push({
                    label: 'Fetch HazardHub Data (All Buildings)',
                    action: () => onFetchAllPingData(),
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_PING_DATA, currentSubmissionId || -1),
                    color: 'blue',
                    id: 'fetch-ping-all',
                    order: 5,
                    visible: !isRaeAndBoundCurrentSubmission,
                });
            } else {
                allBuildingActions.push({
                    label: 'Fetch HazardHub Data (All Buildings)',
                    action: () => onFetchAllHazardHubData(),
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_HAZARDHUB, currentSubmissionId || -1),
                    color: 'blue',
                    id: 'fetch-hazardhub-all',
                    order: 5,
                    visible: !isRaeAndBoundCurrentSubmission,
                });
            }

            allBuildingActions.push({
                label: 'Fetch MSB Data (All Buildings)',
                action: () => onFetchAllMSBData(),
                progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_HAZARDHUB, currentSubmissionId || -1),
                color: 'blue',
                id: 'fetch-msbdata-all',
                order: 5,
                visible: !isRaeAndBoundCurrentSubmission,
            });

            allBuildingActions.push({
                label: 'Fetch BuildFax (All Buildings)',
                action: () => onFetchAllBuildfax(),
                disabled: !usesBuildFax(),
                progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_ALL_BUILDFAX, currentSubmissionId || -1),
                color: 'blue',
                id: 'fetch-buildfax-all',
                order: 5,
                visible: !isRaeAndBoundCurrentSubmission,
            });

            allBuildingActions.push({
                label: 'Fetch Cape Analytics (All Buildings)',
                action: () => onFetchAllCapeAnalytics(),
                progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_ALL_CAPE_ANALYTICS, currentSubmissionId || -1),
                disabled: disableCapeAnalytics(),
                color: 'blue',
                id: 'fetch-cape-analytics-all',
                order: 5,
                visible: !isRaeAndBoundCurrentSubmission,
            });

            if (INTERMAP_ENABLED) {
                allBuildingActions.push({
                    label: 'Fetch Intermap Data (All Buildings)',
                    action: () => onFetchAllIntermapData(),
                    progressInfoPath: getTaskPath(BACKGROUND_TASK_TYPES.FETCH_INTERMAP, currentSubmissionId || -1),
                    color: 'blue',
                    id: 'fetch-intermap-all',
                    order: 5,
                    visible: !isRaeAndBoundCurrentSubmission,
                });
            }
        }

        if (Object.values(tableData.rowData).some(b => b.building_has_preferred_geo_source === false)) {
            allBuildingActions.push({
                label: 'Geocode with Preferred Source',
                action: () => onGeocodeWithPreferredSource(),
                color: 'orange',
                id: 'geocode-with-preferred-source',
                order: 2,
                visible: !isRaeAndBoundCurrentSubmission,
            });
        }

        if (Object.values(tableData.rowData).some(b => b.building_may_need_geocoding === true)) {
            allBuildingActions.push({
                label: 'Clear Geo Warnings',
                action: () => onHideAllGeocodingWarnings(),
                color: 'orange',
                id: 'dismiss-all-geocode-warnings',
                order: 2,
                visible: !isRaeAndBoundCurrentSubmission,
            });
        }
    }

    if (latestSOV.current) {
        allBuildingActions.push({
            label: 'Download Latest SOV',
            action: () => {
                window.location.href = `${oldWebsite}${latestSOV.current.secure_url}`;
            },
            color: 'blue',
            id: 'download-latest-sov',
            order: 1,
        });
    }

    const extraSections = allBuildingActions.length ? [{ title: 'All Buildings', actions: allBuildingActions }] : [];

    return (
        <PagedTableContainer
            tableData={tableData}
            onRowSelected={onRowSelected}
            selectedRowId={currentBuildingId}
            allowsDragging={editable}
            hasSearchPanel={true}
            editingDisabled={!editable}
            panelActions={{ panelRowActions, extraSections }}
            tableConfig={tableConfig}
            getTaskSubtitles={getTaskSubtitles}
            extraCellRenderData={{ isSubmissionEditable: isSubmissionEditable, featureFlags, ENV }}
            tasksPanel={<AccountTasksPanel />}
            bottomPanel={{
                ContentElement: BuildingsBottomPanelContainer,
                label: 'Details',
                currentSubmission,
                currentSubmissionId,
                currentBuilding,
                currentQuote,
                readonly: !editable,
                ENV,
            }}
        />
    );
}
