import _ from 'lodash';

import { NO_ACCOUNT, SUBMISSION_SHORTCUT } from '../reducers/accounts';
import { AppContextType } from '../ts-types/AppTypes';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { RootState } from '../store';
import { AccountDataType, BuildingDataType, QuoteType, SubmissionDataType, TransactionDataType } from '../ts-types/DataTypes';
import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { LOADING } from '@archinsurance-viki/property-jslib/src/constants/Constants';

export const ACCOUNT_NOT_LOADED = {};

export const getSelectedIdFromQuerystring = () => {
    const querystring = new URLSearchParams(window.location.search);
    return querystring.has('selected') ? parseInt(querystring.get('selected')) : null;
};

export type AppContextParams = {
    accountId?: string;
    submissionId?: string;
    transactionId?: string;
    quoteId?: string;
    buildingId?: string;
};

/**
 * The "app context" is core information that is used pretty much everywhere in VIKI.
 *
 * All of the logic used to assemble this object used to be crammed into this function.
 * It now exists in separate smaller functions.
 * Those smaller functions should become hooks that eventually replace `useAppContext()`
 * so that we're not recomputing all of these values on every render.
 */
export const getCurrentAppContext = (state: RootState, params: AppContextParams): AppContextType => {
    return {
        currentAccount: getCurrentAccount(state, params),
        currentAccountId: getCurrentAccountId(params),
        currentBuilding: getCurrentBuilding(state, params),
        currentBuildingId: getCurrentBuildingId(params),
        buildingDataConfig: applySubmissionParamsToTableConfig(state, params, PAGED_TABLES.BUILDING_GRID),
        buildingConverageGridConfig: applySubmissionParamsToTableConfig(state, params, PAGED_TABLES.BUILDING_COVERAGE_GRID),
        currentQuote: getCurrentQuote(state, params),
        currentQuoteId: getCurrentQuoteId(state, params),
        ...getSubmissionDocuments(state, params),
        urlQuoteId: params.quoteId ? +params.quoteId : null,
        currentSubmission: getCurrentSubmission(state, params),
        currentSubmissionId: getCurrentSubmissionId(state, params),
        currentSubmissionShortcut: getCurrentSubmissionShortcut(state, params),
        currentTransaction: getCurrentTransaction(state, params),
        currentTransactionId: getCurrentTransactionId(state, params),
        initialQuote: getInitialQuote(state, params),
        urlTransactionId: params.transactionId ? +params.transactionId : null,
        originalSubmissionId: getOriginalSubmissionId(state, params),
        originalSubmission: getOriginalSubmission(state, params),
        transactions: getAccountTransactions(state, params),
        submissionMap: state.submissionLog.rowData,
        quotesMap: state.quotes.rowData,
        oldWebsite: state.global.oldWebsite,
        featureFlags: state.global.featureFlags,
        tenantFlags: state.global.ENV?.TENANT_SETTINGS?.features ?? {},
        me: state.global.me,
        CONSTANTS: state.global.CONSTANTS,
        ENV: state.global.ENV,
        uiState: state.uiState,
    };
};

/**
 * Get the current account ID from the URL (either from the path `/submissions/:accountId` or from the query key `selected`).
 * The account ID only refers to an actual "account" if the underlying submission is bound, otherwise it just refers to a submission.
 */
export const getCurrentAccountId = (urlParams: AppContextParams) => {
    return urlParams.accountId ? +urlParams.accountId : getSelectedIdFromQuerystring();
};

/**
 * Get the currently selected account.
 * Note that if `account.id === NO_ACCOUNT`, then this is just a placeholder account, and `currentAccountId` will be referring to a submission.
 */
export const getCurrentAccount = (state: RootState, urlParams: AppContextParams): AccountDataType => {
    const currentAccountId = getCurrentAccountId(urlParams);
    return state.accountsState.accounts[currentAccountId] ?? ACCOUNT_NOT_LOADED;
};

/**
 * Getting the current submission ID is a complicated operation that is dependent on both the URL and on resources referred to by the IDs in the URL.
 *
 * - If there is an :accountId in the URL, the submission ID will be either:
 *     - the :accountId (if that ID doesn't yet refer to an account)
 *     - the :submissionId in the URL, which may be a shortcut to one of the submission IDs on the account
 *     - the submission ID of the transaction referred to by the :transactionId in the URL
 *     - if all else fails, the latest submission ID of the account
 * - If there is no :accountId in the URL but there is a :submissionId, it means we're on the building grid (/grid/:submissionId)
 *   and we should just use that :submissionId
 */
export const getCurrentSubmissionId = (state: RootState, urlParams: AppContextParams): number => {
    const urlAccountId = getCurrentAccountId(urlParams);
    const currentAccount = getCurrentAccount(state, urlParams);
    const transactionId = urlParams.transactionId ? +urlParams.transactionId : null;
    const currentTransaction = state.transactions.rowData[transactionId] as TransactionDataType;

    if (urlAccountId) {
        if (currentAccount.id === NO_ACCOUNT) {
            // :accountId *is* the submission ID
            return urlAccountId;
        } else {
            // There is an actual account
            if (urlParams.submissionId) {
                if (urlParams.submissionId === SUBMISSION_SHORTCUT.ORIGINAL) {
                    return currentAccount.original_submission_id;
                } else if (urlParams.submissionId === SUBMISSION_SHORTCUT.CURRENT) {
                    return currentAccount.latest_submission_id;
                } else if (urlParams.submissionId === SUBMISSION_SHORTCUT.INITIAL) {
                    return currentAccount.latest_initial_submission_id;
                } else if (isFinite(+urlParams.submissionId)) {
                    return +urlParams.submissionId;
                } else {
                    console.error('Invalid submission ID in URL:', urlParams.submissionId);
                }
            }

            if (currentTransaction) {
                // There is a valid transaction, use its submission ID
                return currentTransaction.submission_id;
            }

            // TODO: just use current for now, but maybe that isn't correct
            return currentAccount.latest_submission_id;
        }
    }

    if (isFinite(+urlParams.submissionId)) {
        // If we got here, we're probably on the building grid
        return +urlParams.submissionId;
    }

    // we're probably on a page where the current submission ID doesn't apply.
    return null;
};

/** Get the submission with ID = `getCurrentSubmissionId()` */
export const getCurrentSubmission = (state: RootState, urlParams: AppContextParams) => {
    const currentSubmissionId = getCurrentSubmissionId(state, urlParams);
    return (state.submissionLog.rowData[currentSubmissionId] as SubmissionDataType) ?? null;
};

/**
 * Get a string to be used as the :submissionId in links in the nav panel
 */
export const getCurrentSubmissionShortcut = (state: RootState, urlParams: AppContextParams) => {
    const currentAccount = getCurrentAccount(state, urlParams);
    const currentTransaction = getCurrentTransaction(state, urlParams);

    if (urlParams.submissionId && currentAccount.id !== NO_ACCOUNT) {
        // if current account is real and there is a submission ID in the url, just return that submission ID
        return urlParams.submissionId;
    } else if (currentTransaction) {
        // if there is a current valid transaction, return that transaction's submission ID
        return `${currentTransaction.submission_id}`;
    }

    // otherwise, just return `current` and let `getCurrentSubmissionId()` figure out the ID
    return 'current';
};

/**
 * Get the ID of the "original" submission:
 * - if the current account is real, get the original submission ID of the account
 * - otherwise, the current submission *is* the original submission
 */
export const getOriginalSubmissionId = (state: RootState, urlParams: AppContextParams) => {
    const currentAccount = getCurrentAccount(state, urlParams);
    const currentSubmissionId = getCurrentSubmissionId(state, urlParams);
    return currentAccount.id !== NO_ACCOUNT ? currentAccount.original_submission_id : currentSubmissionId;
};

/** Get the submission with ID = `getOriginalSubmissionId()` */
export const getOriginalSubmission = (state: RootState, urlParams: AppContextParams) => {
    const originalSubmissionId = getOriginalSubmissionId(state, urlParams);
    return (state.submissionLog.rowData[originalSubmissionId] as SubmissionDataType) ?? null;
};

/**
 * Get the ID of the current transaction:
 * - If there is a :transactionId in the URL, just use that
 * - Otherwise, find the transaction from redux (using the list of transaction IDs from the current account)
 *   whose submission ID matches the current submission
 */
export const getCurrentTransactionId = (state: RootState, urlParams: AppContextParams) => {
    if (urlParams.transactionId) {
        return +urlParams.transactionId;
    }

    const currentAccount = getCurrentAccount(state, urlParams);
    const currentSubmissionId = getCurrentSubmissionId(state, urlParams);
    const transactions = state.transactions.rowData as Record<number, TransactionDataType>;

    const transactionId = currentAccount.account_transaction_ids?.find(
        id => transactions[id]?.submission_id && transactions[id]?.submission_id === currentSubmissionId
    );
    return transactionId ?? null;
};

/** Get the transaction with ID = `getCurrentTransactionId()` */
export const getCurrentTransaction = (state: RootState, urlParams: AppContextParams) => {
    const currentTransactionId = getCurrentTransactionId(state, urlParams);
    return (state.transactions.rowData[currentTransactionId] as TransactionDataType) ?? null;
};

/** Get the primary quote ID of the current submission */
export const getCurrentQuoteId = (state: RootState, urlParams: AppContextParams) => {
    if (urlParams.quoteId) {
        return +urlParams.quoteId;
    }

    const currentSubmission = getCurrentSubmission(state, urlParams);
    return currentSubmission?.primary_quote_id ?? null;
};

/** Get the quote with ID = `getCurrentQuoteId()` */
export const getCurrentQuote = (state: RootState, urlParams: AppContextParams) => {
    const currentQuoteId = getCurrentQuoteId(state, urlParams);
    return (state.quotes.rowData[currentQuoteId] as QuoteType) ?? null;
};

/**
 * Get the initial quote:
 * - If there is a current transaction, get the quote with the initial quote ID from that transaction
 * - Otherwise, just return the current quote
 */
export const getInitialQuote = (state: RootState, urlParams: AppContextParams) => {
    const currentTransaction = getCurrentTransaction(state, urlParams);
    const currentQuote = getCurrentQuote(state, urlParams);
    const initialQuote = (state.quotes.rowData[currentTransaction?.initial_quote_id] as QuoteType) ?? null;
    return currentTransaction ? initialQuote : currentQuote;
};

/** Get the :buildingId from the URL */
export const getCurrentBuildingId = (urlParams: AppContextParams) => {
    return urlParams.buildingId ? +urlParams.buildingId : null;
};

/** Get the data for the current building within the current submission */
export const getCurrentBuilding = (state: RootState, urlParams: AppContextParams) => {
    const currentBuildingId = getCurrentBuildingId(urlParams);
    const currentSubmissionId = getCurrentSubmissionId(state, urlParams);
    const apiParamsKey = getApiParamsKey({ submission_id: currentSubmissionId });
    return (state.buildings[apiParamsKey]?.rowData[currentBuildingId] as BuildingDataType) ?? null;
};

/** Get the list of transactions for the current account */
export const getAccountTransactions = (state: RootState, urlParams: AppContextParams) => {
    const currentAccount = getCurrentAccount(state, urlParams);
    return currentAccount.account_transaction_ids?.map(id => state.transactions.rowData[id] as TransactionDataType) ?? [];
};

export const getSubmissionDocuments = (state: RootState, urlParams: AppContextParams) => {
    const originalSubmissionId = getOriginalSubmissionId(state, urlParams);
    let documentIdList = state.submissionDocumentsMap.map[originalSubmissionId];
    let documentsLoading = false;
    let documentsLoaded = false;

    if (documentIdList === LOADING) {
        documentsLoading = true;
        documentIdList = [];
    } else if (documentIdList === undefined) {
        documentIdList = [];
    } else {
        documentsLoaded = true;
    }

    return {
        documentIdList,
        documentsLoading,
        documentsLoaded,
    };
};

/**
 * Applies an `apiParams` and `apiParamsKey` for the current submission to the provided tableConfig.
 */
export const applySubmissionParamsToTableConfig = (state: RootState, urlParams: AppContextParams, tableConfig: TableConfigType) => {
    const currentSubmissionId = getCurrentSubmissionId(state, urlParams);
    const apiParams = { submission_id: currentSubmissionId };
    const apiParamsKey = getApiParamsKey(apiParams);
    return { ...tableConfig, apiParams, apiParamsKey };
};
