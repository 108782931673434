import moment from 'moment';
import { DEFAULT_DATE_FORMAT, COMMON_COLUMNS_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';

import OverrideBadge from '@archinsurance-viki/property-jslib/src/tables/widgets/OverrideBadge';
import { ViewSettingsConfig } from '@archinsurance-viki/property-jslib/src/utils/tables/view-settings';
import { SummaryConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { RAE_SITE_ID, REAL_SITE_ID } from './SiteConstants';

export const CLOSED_COLUMNS_FIELDS = ['wp', 'wc', 'wn', 'nr', 'nro', 'dr', 'dro'];

export const ALL_COLUMNS_LIST = {
    buildingdatasqlview: [
        'i', //'id',
        'd', //'description',
        's', //'street',
        'c', //'city',
        'st', //'state', *
        'cnty', // county
        'brka', //'broker_address',
        'fips', // fips code
        'flhuc', // inland flood huc zone
        'z', //zipcode',
        'g', // geo - Point of combined lat/lon - nt
        'es', //electrical_service',
        'im', //is_metropolitan',
        'nos', //number_of_structures',
        'dats', //date_added_to_schedule',
        'ct', //construction_type',
        'ogi', //occupancy_group_id',
        'ogvc', // occupancy_group_ventus_code
        'ogg', // occupancy_group_group
        'ogd', // occupancy_group_description
        'act', //air_construction_type',
        'aogc', //arch_occupancy_group_code
        'aoi', //arch_occupancy_type_id
        'aomc', //arch_occupancy_msb_code
        'aci', //arch_construction_id
        'acad', //arch_construction_air_display
        'acmc', //arch_construction_msb_code
        'acic', //arch_construction_iso_code
        'po', //pct_occupied',
        'sf', //sqr_footage',
        'vsf', //ventus_sqr_footage',
        'p', //ppc',
        'apc', //arch_ppc',
        'sr', //sinkhole_rating',
        'ps', //pct_sprinklered',
        'sp', //sprinkler', *
        'yb', //year_built',
        'ygr', //year_gut_rennovated',
        'gra', //gut_reno_approved',
        'grh', //gut_reno_haircut',
        'nosto', //number_of_stories', *
        'ffz', //fema_flood_zone',
        'afz', //arch_flood_zone',
        'pc', //partially_constructed',
        'efd', //existing_fire_damage',
        'sd', //scheduled_demolition',
        'o', //obsolete',
        'ur', //undergoing_renovation',
        'htrc', //high_theft_risk_contents',
        'abie', //annual_bi_exposure',
        'sv', //sov_value',
        'svcpsf', //sov_value_cost_per_sqft',
        'evs', //external_valuation_source',
        'eva', //external_valuation_amount', AGG
        'ava', //appraisal_valuation_amount',
        'evacpsf', //external_valuation_amount_cost_per_sqft',
        'vov', //ventus_override_value',
        'vovcpsf', //ventus_override_value_cost_per_sqft',
        'rg', //roof_geometry',
        'rp', //roof_pitch',
        'rc', //roof_covering',
        'rca', //roof_covering_attch',
        'rd', //roof_deck',
        'rda', //roof_deck_attch',
        'ra', //roof_anchorage',
        'ryb', //roof_year_built',
        'bry', //buildfax_roof_year
        'byb', //buildfax_year_built
        'wt', //wall_type',
        'ws', //wall_siding',
        'gp', //glass_percentage',
        'gt', //glass_type',
        'wp', //window_protection',
        'bc', //building_condition',
        'soa', //seal_of_approval',
        'sep', //service_eqp_production',
        'fc', //foundation_connection',
        'ffh', //first_floor_height',
        'ft', // foundation_type
        'ctr', //county_tier
        'cv', // crime_value
        'ctxt', //crime_text,
        'cs', //crime_score
        'dtcv', //distance_to_coast_value,
        'dtcu', //distance_to_coast_units
        'dtcwv', //distance_to_coast_wind_value,
        'casc', // cape_analytics_structure_count
        'cafv', // cape_analytics_structure_footprint_value
        'cafu', // cape_analytics_structure_footprint_units
        'cad', // cape_analytics_cml_roof_condition_date
        'cacs', //cape_analytics_cml_roof_condition_condfidence_score
        'cawc', // cape_analytics_cml_roof_condition_worst_condition
        'cawa', // cape_analytics_cml_roof_condition_weighted_average
        'cari', // cape_analytics_cml_roof_condition_image_url
        'calp', // cape_analytics_last_pulled
        'caae', // cape_analytics_api_error
        'cavsc', // cape_analytics_valid_count
        'bl', // building_limit
        'bil', // bi_limit
        'cl', // contents_limit
        'apl', // additional_properties_limit
        'pool', // property_of_others_limit
        'sl', // stock_limit
        'edpl', // electronic_data_processing_limit
        'tiabl', // tenant_improvements_and_betterments_limit
        'tiv', // tiv
        'ptiv', // pricing_tiv
        'iiiq', // is_included_in_quote
        'gcs', // geocode_source
        'eqz', // arch_eq_zone
        'tcz', // arch_tc_zone

        'imbfe', // imap_derived_bfe
        'imdiw', // imap_distance_inland_water
        'imelv', // imap_elevation
        'imehp', // imap_elevation_high_point
        'imelp', // imap_elevation_low_point
        'imffz', // imap_fema_flood_zone
        'imfsd', // imap_flood_scope_depth
        'imfsr', // imap_flood_scope_rtp
        'imgcq', // imap_geocoding_quality
        'imaiw', // imap_height_above_inland_water
        'immsc', // imap_min_storm_cat
        'imod', // imap_ocean_distance
        'imrca', // imap_risk_score_average
        'imrhp', // imap_risk_score_high_point
        'imrlp', // imap_risk_score_low_point
        'imsfh', // imap_surge_flood_height
        'imurl', // imap_visualization_url
        'imwfd', // imap_world_flood_depth
        'imwfr', // imap_world_flood_rtp
        'imst', // imap_status

        'air-cat-aal',
        'tc-cat-aal',
        'eq-cat-aal',
    ],
    accounttransactiondatasqlview: [
        'aid', // account_id
        'repns', //rae_expiring_policy_numbers
        'csi', // clearance_submission_id
        'asaid', // arch_submission_id
        'archss', // arch_submission_status
        'arcsd', // arch_sub_division
        'acaid', // arch_clearance_api_account_id
        'arcbd', // arch_business_division
        'archpf', // arch_product_family
        'apmi', //arch_policywriter_mc_id
        'archdoa', // arch_direct_or_assumed
        'archsc', // arch_section_code
        'arcp', // arch_program
        'arcr', // arch_region

        'nor', // new_or_renewal
        'roi', // renewal_of_id / prev sid
        'rroi', // rae_renewal_of_id / prev submission
        'roppn', // renewal_of_policy_policy_number
        'rbpn', // renewed_by_policy_number

        'woi', // rewrite_of_id
        'wopn', // rewrite_of_policy_number
        'wbi', // rewritten_by_id
        'wbpn', // rewritten_by_policy_number

        'dd', // renewal_of_prev_policy
        'dc', // date_closed
        's', // status
        'db', // date_bound
        'dk', // date_booked
        'pdk', // provisional_date_booked
        'odk', // original_date_booked,
        'opdk', // original_provisional_date_booked
        'bs', // business_segment
        'bstr', // business_strategy
        'dre', // date_received
        'dstde', // date_sent_to_data_entry / Date Sent
        'dedd', // data_entry_due_date / Due Back
        'dstu', // date_sent_to_uw / Completed
        'pid', // policy_inception_date
        'ped', // policy_expiration_date
        'dq', // date_quoted
        'pisd', // policy_issue_date
        'pn', // policy_number
        'sn', //sla_number
        'in', // insured_name
        'boi', // broker_office_id
        'bcn', // brokerage_company_name
        'pbcn', // parent_brokerage_company_name
        'bol', // brokerage_office_location
        'bos', // brokerage_office_state
        'lbi', // licensed_broker_id
        'lbs', // licensed_broker_state
        'abi', // account_broker_id
        'ai', // analyst_id
        'tqd', // target_quote_date
        'ted', // target_effective_date
        'texd', // target_expiration_date
        'ilry', // insured_loss_ratio_years
        'ilh', // insured_loss_history
        'tp', // target_premium
        'iec', // insured_expiring_carrier
        'iep', // insured_expiring_premium
        'ui', // underwriter_full_name
        'mufn', // merged_underwriter_full_name
        'pqi', // primary_quote_id
        'pqs', // primary_quote_status
        'pqds', // primary_quote_date_sent
        'pqtbt', // primary_quote_total_building_tiv
        'pqisi', // primary_quote_in_scope_index
        'pqdci', //primary_quote_data_completeness_index
        'pqiaqi', //primary_quote_itv_account_quality_index
        'pqtapao', // primary_quote_total_aop_premium_after_overrides
        'pqtcpao', // primary_quote_total_cat_premium_after_overrides
        'pqtpao', // charged_premium_before_tf (was primary_quote_total_premium_after_overrides)
        'pqnob', // primary_quote_number_of_buildings
        'pqmbt', // primary_quote_max_building_tiv
        'pqapomp', // AIR ??
        'pqrpomp', // RMS ??
        'pqtbl', // primary_quote_total_bi_limit
        'pqcr', // account_rate (was primary_quote_calculated_rate)
        'pqd', // primary_quote_description
        'wp', // winning_premium
        'wc', // winning_carrier
        'wn', // winning_notes
        'nr', // ntu_reason
        'nro', // ntu_reason_other
        'dr', // declined_reason
        'dro', // declined_reason_other
        'istr', // insured_street
        'ic', // insured_city
        'ista', // insured_state
        'iz', // insured_zipcode
        'if', // insured_phone
        'icn', // insured_contact_name
        'ice', // insured_contact_email
        'icp', // insured_contact_phone
        'mfl', // max_flood_limit
        'pqbp', // primary_quote_bi_pct
        'cpd', // cat_pools_display
        'bof', //brokerage_office_fein
        'oad', // original_accounting_date
        'rv', // rates_version,
        'bp', // bind_propensity
        'ir', // is_rush
        'pbs', // pending_bor_status
        'pbsid', // pending_bor_submission_id
        'ofac', // ofac_status
        'ofaca', // ofac_approver
        'lbai', // licensed_broker_arch_id
        'pcai', // producing_company_arch_id
        'iifs', // insured_flag_status,
        'pqiff', // primary_quote_inspection_fees_final
    ],
    accounttransactionbuildingdatasqlview: [
        'des',
        'pa',
        'da',
        'dr',
        'loc',
        'cdis',
        'odis',
        'lt',
        'cpbt', // charged_premium_before_tf
        'ccp', // charged_cat_premium
        'cmcp', // charged_modeled_cat_premium,
        'cifp', // charged_inland_flood_premium,
        'cap', // charged_aop_premium,
        'ctp', // charged_terror_premium,
        'cep', // charged_eb_premium,
        'cpp', // charged_pee_premium,
    ],
    backgroundtask: [
        'i', // task id
        'c', // created
        'sub', // submission_id
        // 'a', // account_id
        'quote', // quote_id
        'sin', // submission__insured__name
        'tt', // task_type
        's', // status
        'pc', // percentage complete
        'td', // total duration
        'lu', // last updated
        'sfn', // submitter__full_name
        'fm', // failed message
    ],
    backgroundtask_sub_submission: [
        'i', // id
        'c', // created
        'quote', // quote_id
        'tt', // task_type
        's', // status
        'pc', // percentage complete
        'td', // total duration
        'lu', // last updated
        'sfn', // submitter__full_name
        'fm', // failed message
    ],
    blanket_limits: [
        COMMON_COLUMNS_TYPES.ROW_SELECTION,
        'bcli',
        'bi',
        'loc',
        'street',
        'city',
        'state',
        'zip',
        'county',
        'des',
        'cdis',
        'odis',
        'tiv',
        'bl',
        'tiabl',
        'cl',
        'stl',
        'pool',
        'edpl',
        'bil',
        'apl',
    ],
    buildingcoveragegridsqlview: [
        'bid',
        'loc',
        'pa',
        'bl',
        'blcv',
        'blc',
        'cl',
        'clcv',
        'clc',
        'sl',
        'slcv',
        'slc',
        'tiab',
        'tiabcv',
        'tiabc',
        'edp',
        'edpcv',
        'edpc',
        'poo',
        'poocv',
        'pooc',
        'cwd',
        'cwdf',
        'cwdda',
        'cwdpa',
        'cwad',
        'olalubl',
        'iolbli',
        'olblf',
        'olblpa',
        'olblda',
        'iolcli',
        'olclf',
        'olclpa',
        'olclda',
        'iolbbli',
        'olbblf',
        'olbblpa',
        'olbblda',
        'iipor',
        'eb',
        'te',
        'tome',
        't',
        's',
        'msd',
        'ivpi',
        'ev',
        'esl',
        'vpsd',
        'vped',
        'ivci',
        'vcmpr',
        've',
        'ad',
        'bi_limit',
        'bt',
        'biiri',
        'biiee',
        'bml',
        'bus_interruption_deduct',
        'slda',
        'sdda',
        'sdop',
        'scv',
        'sot',
        'sboc',
        'spo',
        'srmsa',
        'tmcp',
        'tmcpm',
    ],
    building_quote_conditions: [
        'bi',
        'pa',
        'des',

        'R6',
        'R7',
        'R8',
        'R9',
        'R10',
        'R11',
        'R12',
        'R13',
        'R14',
        'R15',
        'R16',

        'B5',

        'C1',

        'E1',

        'P5',
        'P6',

        'R1',
        'R2',

        'R4',

        'P3',
        'P4',

        'P1',
        'P2',

        'R5',

        'V1',
        'V2',
        'V3',
        // 'V4',

        'R3',
        'X1',
        'X2',
        'X3',
        'X4',
        'X5',
        'X6',
        'X7',
        'X8',
        'X9',
        'X10',
        'X11',
        'X12',
        'X13',
        'X14',
        'X15',
    ],
};

const BUILDING_PREMIUMS_COLUMNS = ['pa', 'loc', 'cdis', 'odis', 'tiv'];

const BUILDING_COVERAGE_GRID_FIXED_COLUMNS = ['bid', 'loc', 'pa'];

const BUILDING_COVERAGE_GRID = [
    'bl',
    'blcv',
    'blc',
    'cl',
    'clcv',
    'clc',
    'sl',
    'slcv',
    'slc',
    'tiab',
    'tiabcv',
    'tiabc',
    'edp',
    'edpcv',
    'edpc',
    'poo',
    'poocv',
    'pooc',
];

const BUILDING_PREMIUMS_CHARGED_COLS = [
    'aapao', // air_charged_aop_premium
    'acpao', // air_charged_cat_premium
    'actpt', // air_charged_total
    'rapao', // rms_charged_aop_premium
    'rcpao', // rms_charged_cat_premium
    'rctpt', // rms_charged_total
];
const BUILDING_PREMIUMS_TECH_COLS = [
    'atapao', // air_technical_aop_premium
    'atcpao', // air_technical_cat_premium
    'attpt', // air_technical_total
    'rtapao', // rms_technical_aop_premium
    'rtcpao', // rms_technical_cat_premium
    'rttpt', // rms_technical_total
];

const buildingcoveragegridsqlview: ViewSettingsConfig = {
    type: 'tabs',
    columnDefaults: {
        w: 120,
        sortable: true,
        readonly: true,
        resizable: true,
    },
    tablePropsDefaults: {
        sortColumn: 'des',
        sortDirection: 'ASC',
    },
    allColumnsList: ALL_COLUMNS_LIST.buildingcoveragegridsqlview,

    tabsList: [
        {
            name: 'Coverage Limits, Claim Valuation Basis and Coinsurance',
            id: 'cl-cvb-ci',
            tableProps: {
                fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
                columnList: BUILDING_COVERAGE_GRID,
            },
        },
        {
            name: 'Wind Deductibles',
            id: 'wind-deductibles',
            tableProps: {
                fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
                columnList: ['cwd', 'cwdf', 'cwdda', 'cwdpa', 'cwad'],
            },
        },
        {
            name: 'Ordinance or Law',
            id: 'ord-and-law',
            tableProps: {
                fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
                columnList: [
                    'olalubl',
                    'iolbli',
                    'olblf',
                    'olblpa',
                    'olblda',
                    'iolcli',
                    'olclf',
                    'olclpa',
                    'olclda',
                    'iolbbli',
                    'olbblf',
                    'olbblpa',
                    'olbblda',
                    'iipor',
                ],
            },
        },
        {
            name: 'Equipment Breakdown',
            id: 'equipment-breakdown',
            tableProps: {
                fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
                columnList: ['eb'],
            },
        },
        {
            name: 'Additional Coverages',
            id: 'additional-coverages',
            tableProps: {
                fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
                columnList: ['te', 'tome', 't', 's', 'msd', 'ivpi', 'ev', 'esl', 'vpsd', 'vped', 'ivci', 'vcmpr', 've'],
            },
        },
        {
            name: 'Business Interruption',
            id: 'business-interruption',
            tableProps: {
                fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
                columnList: ['ad', 'bi_limit', 'bt', 'biiri', 'biiee', 'bml', 'bus_interruption_deduct'],
            },
        },

        {
            name: 'Spoilage Limit',
            id: 'spoilage-limit',
            tableProps: {
                fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
                columnList: ['slda', 'sdda', 'sdop', 'scv', 'sot', 'sboc', 'spo', 'srmsa'],
            },
        },
        // {
        //     name: 'Property Enhancement Endorsement',
        //     id: 'ppe',
        //     tableProps: {
        //         fixedColumnList: BUILDING_COVERAGE_GRID_FIXED_COLUMNS,
        //         columnList: [],
        //     },
        // },
    ],
};

export const SUMMARY_CONFIGS: Record<string, SummaryConfigType> = {
    numBldgs: {
        label: '# Bldgs',
        key: 'id__count',
        icon: 'icon-list2',
    },
    total: {
        key: 'charged_premium_before_tf__sum',
        label: 'Total Premium',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    submissionsPercentOfModelAIR: {
        key: 'spom_air',
        keys: ['charged_premium_before_tf__sum', 'air_technical_premium_before_tf__sum'],
        label: 'AIR % of Model',
        calculate: (chargedPremium: number, technicalPremium: number) => {
            return technicalPremium === 0 ? 0 : ((100 * chargedPremium) / technicalPremium).toFixed(1) + '%';
        },
        icon: 'icon-stats-bars',
    },
    submissionsPercentOfModelRMS: {
        key: 'spom_rms',
        keys: ['charged_premium_before_tf__sum', 'rms_technical_premium_before_tf__sum'],
        label: 'RMS % of Model',
        calculate: (chargedPremium: number, technicalPremium: number) => {
            return technicalPremium === 0 ? 0 : ((100 * chargedPremium) / technicalPremium).toFixed(1) + '%';
        },
        icon: 'icon-stats-bars',
    },
    buildingExtValAmt: {
        key: 'external_valuation_amount__sum',
        label: 'Ext Val Amt',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    buildingSovVal: {
        key: 'sov_value__sum',
        label: 'SOV Val',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    tivSum: {
        key: 'tiv__sum',
        label: 'TIV',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    ltSum: {
        key: 'latest_tiv__sum',
        label: 'TIV',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },

    ccpSum: {
        key: 'charged_cat_premium__sum',
        label: 'Charged Cat Premium ',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    capSum: {
        key: 'charged_aop_premium__sum',
        label: 'Charged AOP Premium ',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    aapaoSum: {
        key: 'air_charged_aop_premium__sum',
        label: 'AIR AOP Charged Premium ',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    acpaoSum: {
        key: 'air_charged_cat_premium__sum',
        label: 'AIR Charged Cat Premium ',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    actSum: {
        key: 'air_charged_total_premium__sum',
        label: 'AIR Charged Total',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    rapaoSum: {
        key: 'rms_charged_aop_premium__sum',
        label: 'RMS Charged AOP Premium',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    rcpaoSum: {
        key: 'rms_charged_cat_premium__sum',
        label: 'RMS Charged Cat Premium',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    rctpaoSum: {
        key: 'rms_charged_total_premium__sum',
        label: 'RMS Charged Total',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    atapaoSum: {
        key: 'air_technical_aop_premium__sum',
        label: 'AIR Technical AOP Premium',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    atcpaoSum: {
        key: 'air_technical_cat_premium__sum',
        label: 'AIR Technical Cat Premium',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    attSum: {
        key: 'air_technical_total_premium__sum',
        label: 'AIR Technical Total',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    rtapaoSum: {
        key: 'rms_technical_aop_premium__sum',
        label: 'RMS Technical AOP Premium ',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    rtcpaoSum: {
        key: 'rms_technical_cat_premium__sum',
        label: 'RMS Technical Cat Premium',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
    rttSum: {
        key: 'rms_technical_total_premium__sum',
        label: 'RMS Technical Total',
        icon: 'icon-briefcase',
        numberPrefix: '$',
    },
};

const accounttransactiondatasqlview: ViewSettingsConfig = {
    type: 'tabs',
    columnDefaults: {
        w: 160,
        sortable: true,
        resizable: true,
    },
    allColumnsList: ALL_COLUMNS_LIST.accounttransactiondatasqlview,
    tablePropsDefaults: {
        summaryCount: true,
        summaryData: ['total'],
    },
    tabsList: [
        {
            name: 'Recently Viewed',
            id: 'recents',
            featureFlag: 'recently-viewed',
            staticIndex: 0,
            forceRefresh: true,
            tableProps: {
                // staticApiParams: {
                //     only_most_recent: 10,
                // },
                apiParams: {
                    only_most_recent: 25,
                },
                filters: {},
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                fixedColumnList: ['aid'],
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                columnList: ['in', 'pn', 's', 'pid', 'ped', 'pqtbt', 'pqtpao'],
            },
        },
        {
            name: 'Clearance',
            id: 'clearance',
            tableProps: {
                filters: {
                    s: 'NEW_SUBMISSION',
                },
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                sortColumn: 'ted',
                summaryData: [],
                fixedColumnList: ['aid'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: ['nor', 'roi', 'rroi', 'roppn', 'dre', 'dedd', 'in', 'boi', 'lbi', 'lbs', 'abi', 'tqd', 'ted', 'tp', 'iec', 'iep', 'ui', 'mufn'],
            },
        },
        {
            name: 'Rush Data Entry',
            id: 'rush-data-entry',
            tableProps: {
                filters: {
                    s: 'ENTERING_DATA',
                    ir: true,
                },
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                sortColumn: 'ted',
                summaryData: [],
                fixedColumnList: ['aid'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: ['nor', 'roi', 'rroi', 'roppn', 'dre', 'dstde', 'dedd', 'in', 'boi', 'lbi', 'abi', 'tqd', 'ted', 'iec', 'iep', 'ui', 'mufn'],
            },
        },
        {
            name: 'Data Entry',
            id: 'data-entry',
            tableProps: {
                filters: {
                    s: 'ENTERING_DATA',
                    ir: false,
                },
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                sortColumn: 'ted',
                summaryData: [],
                fixedColumnList: ['aid'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: ['nor', 'roi', 'rroi', 'roppn', 'dre', 'dstde', 'dedd', 'in', 'boi', 'lbi', 'abi', 'tqd', 'ted', 'tp', 'iec', 'iep', 'ui', 'mufn'],
            },
        },
        {
            name: 'Pending BOR',
            id: 'pending-bor',
            tableProps: {
                filters: {
                    pbs: 'P',
                },
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                sortColumn: 'ted',
                summaryData: [],
                fixedColumnList: ['aid', 'pbsid', 'pbs'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: ['nor', 'roi', 'rroi', 'roppn', 'dre', 'dedd', 'in', 'boi', 'lbi', 'lbs', 'abi', 'tqd', 'ted', 'tp', 'iec', 'iep', 'ui', 'mufn'],
            },
        },
        {
            name: 'In Force',
            id: 'in-force',
            tableProps: {
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                filters: {
                    pid: '<=' + moment().format(DEFAULT_DATE_FORMAT),
                    ped: '>=' + moment().format(DEFAULT_DATE_FORMAT),
                    s: 'BOUND',
                },
                sortColumn: 'ted',
                fixedColumnList: ['aid'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: [
                    'nor',
                    'roi',
                    'rroi',
                    'roppn',
                    's',
                    'dre',
                    'dstde',
                    'dedd',
                    'pid',
                    'ped',
                    'dq',
                    'in',
                    'pn',
                    'lbi',
                    'abi',
                    'tqd',
                    'ted',
                    'tp',
                    'iec',
                    'iep',
                    'ui',
                    'mufn',
                    'pqi',
                    'pqs',
                    'pqds',
                    'pqtbt',
                    'pqtapao',
                    'pqtcpao',
                    'pqtpao',
                    'pqapomp', // AIR ??
                    'pqrpomp', // RMS ??
                    'pqcr',
                ],
            },
        },
        {
            name: 'Underwriting',
            id: 'underwriting',
            tableProps: {
                filters: {
                    s: 'UW_REVIEW',
                    haq: false,
                },
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                sortColumn: 'ted',
                fixedColumnList: ['aid'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: [
                    'nor',
                    'roi',
                    'roppn',
                    'dre',
                    'dstde',
                    'dedd',
                    'dstu',
                    'boi',
                    'in',
                    'lbi',
                    'abi',
                    'tqd',
                    'ted',
                    'pqtbt',
                    'tp',
                    'iec',
                    'iep',
                    'ui',
                    'mufn',
                ],
            },
        },
        {
            name: 'Outstanding Quotes',
            id: 'outstanding-quotes',
            tableProps: {
                filters: {
                    s: 'UW_REVIEW',
                    haq: true,
                },
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                sortColumn: 'pid',
                fixedColumnList: ['aid'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: [
                    'nor',
                    'roi',
                    'roppn',
                    'in',
                    'boi',
                    'lbi',
                    'abi',
                    'pid',
                    'tp',
                    'iec',
                    'iep',
                    'ui',
                    'mufn',
                    'pqi',
                    'pqs',
                    'pqds',
                    'pqtbt',
                    'pqtapao',
                    'pqtcpao',
                    'pqtpao',
                    'pqapomp', // AIR ??
                    'pqrpomp', // RMS ??
                    'pqcr',
                    'pqd',
                ],
            },
        },
        {
            name: 'Closed',
            id: 'closed',
            tableProps: {
                filters: {
                    s: 'DECLINED__QUOTES_WITHDRAWN__NOT_ACCEPTED',
                },
                customFilterOptions: {
                    s: [
                        ['DECLINED', 'Declined'],
                        ['NOT_ACCEPTED', 'Not Taken Up'],
                        ['QUOTES_WITHDRAWN', 'Quotes Withdrawn'],
                    ],
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                sortColumn: 'dc',
                sortDirection: 'DESC',
                fixedColumnList: ['aid'],
                columnList: ['nor', 'roi', 'roppn', 'dc', 'in', 'boi', 'lbi', 'abi', 'iec', 'iep', 'tp', 'wp', 'wc', 'wn', 'dr', 'dro'],
            },
        },
        {
            name: 'Bound',
            id: 'bound',
            tableProps: {
                filters: {
                    s: 'BOUND',
                },
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                sortColumn: 'db',
                sortDirection: 'DESC',
                summaryData: ['submissionsPercentOfModelAIR', 'submissionsPercentOfModelRMS', 'total'],
                fixedColumnList: ['aid'],
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: [
                    'nor',
                    'roi',
                    'roppn',
                    'db',
                    'dk', // date_booked
                    'pn',
                    'sn',
                    'in',
                    'boi',
                    'lbi',
                    'abi',
                    'pisd',
                    'pid',
                    'pqtbt',
                    'pqtapao',
                    'pqtcpao',
                    'pqtpao',
                    'tp',
                    'pqapomp', // AIR ??
                    'pqrpomp', // RMS ??
                    'pqcr',
                    'iifs',
                ],
            },
        },
        {
            name: 'All',
            id: 'all',
            staticIndex: 1,
            tableProps: {
                sortColumn: 'aid',
                sortDirection: 'DESC',
                fixedColumnList: ['aid'],
                hiddenColumns: CLOSED_COLUMNS_FIELDS,
                customFilterOptions: {
                    nor: [
                        [false, 'New'],
                        [true, 'Renewal'],
                    ],
                },
                columnList: [
                    'nor',
                    'roi',
                    'roppn',
                    's',
                    'dre',
                    'dstde',
                    'dedd',
                    'pid',
                    'dq',
                    'in',
                    'pn',
                    'lbi',
                    'abi',
                    'tqd',
                    'ted',
                    'tp',
                    'iec',
                    'iep',
                    'ui',
                    'mufn',
                    'pqi',
                    'pqs',
                    'pqds',
                    'pqtbt',
                    'pqtapao',
                    'pqtcpao',
                    'pqtpao',
                    'pqapomp', // AIR ??
                    'pqrpomp', // RMS ??
                    'pqcr',
                    'pqiff',
                    'boi',
                    'iifs',
                ],
            },
        },
    ],
};

const backgroundtask: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 120,
        sortable: true,
        readonly: true,
        resizable: true,
    },
    allColumnsList: ALL_COLUMNS_LIST.backgroundtask,
    tableProps: {
        sortColumn: 'id',
        sortDirection: 'DESC',
        fixedColumnList: ALL_COLUMNS_LIST.backgroundtask.slice(0, 2),
        columnList: ALL_COLUMNS_LIST.backgroundtask.slice(2),
    },
};

const backgroundtask_sub_submission: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 120,
        sortable: true,
        readonly: true,
        resizable: true,
    },
    allColumnsList: ALL_COLUMNS_LIST.backgroundtask_sub_submission,
    tableProps: {
        sortColumn: 'id',
        sortDirection: 'DESC',
        fixedColumnList: ALL_COLUMNS_LIST.backgroundtask_sub_submission.slice(0, 2),
        columnList: ALL_COLUMNS_LIST.backgroundtask_sub_submission.slice(2),
    },
};

const blanket_limits: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        sortable: true,
    },
    allColumnsList: ALL_COLUMNS_LIST.blanket_limits,
    tableProps: {
        sortColumn: 'id',
        sortDirection: 'DESC',
        summaryData: ['tivSum'],
        summaryCount: true,
        fixedColumnList: ALL_COLUMNS_LIST.blanket_limits.slice(0, 2),
        columnList: ALL_COLUMNS_LIST.blanket_limits.slice(2),
    },
};

const buildingpremiumsqlview: ViewSettingsConfig = {
    type: 'tabs',
    columnDefaults: {
        w: 120,
        sortable: true,
        readonly: true,
        resizable: true,
    },
    tablePropsDefaults: {
        sortColumn: 'des',
        sortDirection: 'ASC',
    },
    allColumnsList: ['des', ...BUILDING_PREMIUMS_COLUMNS, ...BUILDING_PREMIUMS_CHARGED_COLS, ...BUILDING_PREMIUMS_TECH_COLS, 'tmcpm', 'tmcp'],
    tabsList: [
        {
            name: 'Charged Premium',
            id: 'charged',
            tableProps: {
                fixedColumnList: ['des'],
                columnList: [...BUILDING_PREMIUMS_COLUMNS, ...BUILDING_PREMIUMS_CHARGED_COLS, 'tmcpm', 'tmcp'],
            },
        },
        {
            name: 'Technical Premium',
            id: 'technical',
            tableProps: {
                fixedColumnList: ['des'],
                columnList: [...BUILDING_PREMIUMS_COLUMNS, ...BUILDING_PREMIUMS_TECH_COLS, 'tmcpm', 'tmcp'],
            },
        },
    ],
};

const accounttransactionbuildingdatasqlview: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 120,
        sortable: true,
        readonly: true,
        resizable: true,
    },
    allColumnsList: ALL_COLUMNS_LIST.accounttransactionbuildingdatasqlview,
    tableProps: {
        fixedColumnList: ALL_COLUMNS_LIST.accounttransactionbuildingdatasqlview.slice(0, 2),
        columnList: ALL_COLUMNS_LIST.accounttransactionbuildingdatasqlview.slice(2),
        sortColumn: 'des',
        sortDirection: 'ASC',
    },
};

const buildingdatasqlview: ViewSettingsConfig = {
    type: 'tabs',
    columnDefaults: {
        w: 160,
        sortable: true,
        resizable: true,
    },
    tablePropsDefaults: {
        summaryCount: true,
        summaryData: ['buildingSovVal', 'buildingExtValAmt'],
    },
    allColumnsList: ALL_COLUMNS_LIST.buildingdatasqlview,
    tabsList: [
        {
            name: 'Underwriter',
            id: 'underwriter',
            siteIds: [REAL_SITE_ID],
            tableProps: {
                customFilterOptions: { g: null },
                fixedColumnList: ['i', 'd', 's', 'c', 'st'],
                columnList: [
                    'z',
                    'ct',
                    'ogi',
                    'act',
                    'aoi',
                    'aci',
                    'po',
                    'sf',
                    'ps',
                    'sp',
                    'yb',
                    'ygr',
                    'gra',
                    'grh',
                    'ryb',
                    'nos',
                    'abie',
                    'sv',
                    'evs',
                    'eva',
                    'ava',
                    'vov',
                ],
            },
            // sort
            // filters
        },
        {
            name: 'Underwriter',
            id: 'underwriter-rae',
            siteIds: [RAE_SITE_ID],
            tableProps: {
                customFilterOptions: { g: null },
                fixedColumnList: ['i', 'd', 's', 'c', 'st'],
                columnList: [
                    'z',
                    'cnty',
                    'aci',
                    'acic',
                    'bl',
                    'bil',
                    'cl',
                    'sl',
                    'tiv',
                    'ptiv',
                    'aogc',
                    'aoi',
                    'sp',
                    'dtcwv',
                    'tcz',
                    'eqz',
                    'g',
                    'aomc',
                    'acmc',
                    'sf',
                    'p',
                    'yb',
                    'nosto',
                    'ffz',
                    'svcpsf',
                    'evs',
                    'eva',
                    'evacpsf',
                    'ryb',
                ],
            },
        },
        {
            name: 'Data entry',
            id: 'data-entry',
            tableProps: {
                customFilterOptions: { g: null },
                fixedColumnList: ALL_COLUMNS_LIST.buildingdatasqlview.slice(0, 5),
                columnList: ALL_COLUMNS_LIST.buildingdatasqlview.slice(5),
            },
        },
    ],
};

const building_quote_conditions: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 300,
        sortable: false,
        resizable: true,
        readonly: true,
        extraContent: OverrideBadge,
    },
    tableProps: {
        sortColumn: 'bi',
        sortDirection: 'ASC',
        columnList: ALL_COLUMNS_LIST.building_quote_conditions.slice(3),
        fixedColumnList: ALL_COLUMNS_LIST.building_quote_conditions.slice(0, 3),
    },
    allColumnsList: ALL_COLUMNS_LIST.building_quote_conditions,
};

const REGIONAL_CAT_SUMMARY_COLS = ['q', 'ld', 'r', 'p', 'ga', 'rp2gp', 'rp1gp'];

const regional_cat_summary: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 120,
        sortable: true,
        readonly: true,
        resizable: true,
    },
    allColumnsList: REGIONAL_CAT_SUMMARY_COLS,
    tableProps: {
        sortColumn: 'q',
        sortDirection: 'ASC',
        columnList: REGIONAL_CAT_SUMMARY_COLS,
        fixedColumnList: [],
    },
};

// each item in tabsList here is extended with the main properties and becomes
export const PAGED_TABLE_VIEW_SETTINGS = {
    accounttransactiondatasqlview,
    backgroundtask,
    backgroundtask_sub_submission,
    blanket_limits,
    buildingpremiumsqlview,
    accounttransactionbuildingdatasqlview,
    buildingdatasqlview,
    building_quote_conditions,
    buildingcoveragegridsqlview,
    regional_cat_summary,
};
