import React from 'react';

import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';
import { TableConfigType, PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import { NAVIGATION_LOCATIONS } from '../../constants/Navigation';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { BACKGROUND_TASK_TYPES } from '../../constants/TaskConstants';
import { getDownloadUrlForDocument, getUrlFor } from '../../utils/navurl-helpers';
import { History } from 'history';
import { Types } from '../../ts-types/viki-types';
import { getTaskSubtitles } from '../../utils/task-helpers';

type propTypes = {
    tableData: PagedTableDataType;
    tableConfig?: TableConfigType;
    selectedBackgroundTaskId?: number;
    selectedBackgroundTask: Record<string, any>;
    history: History;
    onReloadTable: () => void;
    onLoadTaskIfNeeded: (taskId?: number, forceLoad?: boolean) => any;
    ENV: Types.Env;
    tasksPanel: React.ReactNode;
};

export default class BackgroundTaskApp extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);
        this.props.onLoadTaskIfNeeded(props.selectedBackgroundTaskId);
    }

    componentDidUpdate(): void {
        this.props.onLoadTaskIfNeeded(this.props.selectedBackgroundTaskId);
    }

    onGetUrlFor = (location: Record<string, any>, params: Record<string, any>, siteId: number): string => {
        let { ENV } = this.props;
        return getUrlFor(location, params, siteId, ENV);
    };

    render(): React.ReactNode {
        let { selectedBackgroundTaskId, selectedBackgroundTask, ENV, history, tasksPanel } = this.props;
        let panelRowActions: any = {};
        panelRowActions.objectName = 'Task';

        if (selectedBackgroundTaskId) {
            let { submission_id, submission__site_id, account_id, document_id, new_submission_id, new_submission__site_id, quote_id, task_type } =
                selectedBackgroundTask;
            panelRowActions.additionalActions = [];

            if (submission_id) {
                let params = {
                    submissionId: submission_id,
                    accountId: account_id,
                };
                panelRowActions.additionalActions.push({
                    label: 'Go to Submission',
                    action: () => {
                        history.push(this.onGetUrlFor(NAVIGATION_LOCATIONS.OVERVIEW, params, submission__site_id));
                    },
                    id: 'nav-to-submission',
                });
            }

            if (quote_id) {
                let params = {
                    submissionId: submission_id,
                    accountId: account_id,
                    quoteId: quote_id,
                };
                panelRowActions.additionalActions.push({
                    label: 'Go to Coverage Option',
                    action: () => {
                        history.push(this.onGetUrlFor(NAVIGATION_LOCATIONS.COVERAGE_OPTION, params, submission__site_id));
                    },
                    id: 'nav-to-coverage-option',
                });
            }

            if (new_submission_id) {
                let params: any = {};
                params.submissionId = new_submission_id;
                let label = 'Go to New Submission';
                if (task_type === BACKGROUND_TASK_TYPES.RENEW_SUBMISSION) {
                    label = `Go to Renewed SID ${new_submission_id}`;
                } else if (task_type === BACKGROUND_TASK_TYPES.CLONE_SUBMISSION) {
                    label = `Go to Cloned SID ${new_submission_id}`;
                } else if (task_type === BACKGROUND_TASK_TYPES.CREATE_ENDORSEMENT) {
                    label = `Go to Endorsement SID ${new_submission_id}`;
                    params.accountId = account_id;
                }
                panelRowActions.additionalActions.push({
                    label: label,
                    action: () => {
                        if (task_type === BACKGROUND_TASK_TYPES.CLONE_SUBMISSION && new_submission__site_id !== ENV.SITE.id) {
                            window.location.href = this.onGetUrlFor(NAVIGATION_LOCATIONS.OVERVIEW, params, new_submission__site_id);
                        } else {
                            history.push(this.onGetUrlFor(NAVIGATION_LOCATIONS.OVERVIEW, params, new_submission__site_id));
                        }
                    },
                    id: 'nav-to-new-submission',
                });
            }

            if (document_id) {
                panelRowActions.additionalActions.push({
                    label: 'Download Document',
                    action: () => {
                        window.location.href = getDownloadUrlForDocument(document_id);
                    },
                    id: 'download-document',
                });
            }
        }

        const tableConfig = this.props.tableConfig || { ...PAGED_TABLES.TASKS };
        return (
            <PagedTableContainer
                {...this.props}
                getTaskSubtitles={getTaskSubtitles}
                hasSearchPanel={false}
                panelActions={{ panelRowActions }}
                editingDisabled={true}
                allowsDragging={false}
                tableConfig={tableConfig}
                tasksPanel={tasksPanel}
            />
        );
    }
}
