import { BuildingDataType } from '../ts-types/DataTypes';
import { RAE_SITE_ID } from '../constants/SiteConstants';

export const BUILDING_DETAILS = {
    header: 'Building Valuation',
    key: 'building-details',
    rows: ['sv', 'svcpsf', 'eva', 'ava', 'evacpsf', 'evs', 'vov', 'vovcpsf'],
};
export const BUILDING_LIMITS = {
    header: 'Building Limits',
    key: 'insured-values',
    rows: ['building', 'bpp', 'bi_limit', 'total', 'percent_of_tiv'],
    disabled: true,
};
export const ROOF_ATTRIBUTES = {
    header: 'Roof Attributes',
    key: 'roof-attributes',
    rows: ['rg', 'rp', 'rc', 'rca', 'rd', 'rda', 'ra', 'ryb', 'bry', 'cawa', 'cawc'],
};
export const WALL_ATTRIBUTES = {
    header: 'Wall Attributes',
    key: 'wall-attributes',
    rows: ['wt', 'ws', 'gp', 'gt', 'wp'],
};
export const EXTERNAL_DATA = {
    header: 'External Data',
    key: 'other',
    rows: ['p', 'sr', 'ffz', 'ctr', 'cv', 'ctxt', 'cs', 'distance_to_coast', 'imdiw', 'imffz', 'imod', 'imrca', 'apc'],
};
export const OTHER_EXPOSURE_DATA = {
    header: 'Other Exposure Data',
    key: 'other-exposure',
    rows: ['ogvc', 'ogg', 'ogd', 'sp', 'ps', 'po', 'es', 'im', 'abie'],
};
export const BUILDING_DOCS = {
    header: 'Building Documents',
    key: 'building-documents',
    rows: ['bur', 'inspection', 'wmitform'],
};
export const CONSTRUCTION_DETAILS = {
    header: 'Construction Details',
    key: 'cope',
    rowFn: ENV => {
        return ENV?.SITE?.id === RAE_SITE_ID
            ? ['aci', 'acad', 'yb', 'byb', 'ygr', 'gra', 'grh', 'nos', 'nosto', 'sf']
            : ['ct', 'act', 'yb', 'byb', 'ygr', 'gra', 'grh', 'nos', 'nosto', 'sf', 'vsf'];
    },
};
export const ADDITIONAL_STRUCTURES = {
    header: 'Additional Structures',
    key: 'additional_structures',
    showAddRowIcon: true,
    showColHeaders: true,
    className: 'dynamic-width',
    rowObjectGetter: 'currentBuilding.additional_structures',
    columns: [
        { key: 'description', className: 'flex-2' },
        { key: 'exposure_type', className: 'flex-2' },
        { key: 'total_value', className: 'flex-1' },
    ],
    disabled: true,
};
export const ADD_ADDITIONAL_STRUCTURES_COLUMNS = [
    { key: 'description' },
    { key: 'exposure_type' },
    { key: 'value' },
    { key: 'contents_value' },
    { key: 'total_value' },
];
export const ADDRESS_INFO = {
    header: 'Address Info',
    key: 'address-info',
    rows: ['d', 's', 'c', 'st', 'z', 'cnty'],
};
export const UNDERWRITER_QUESTIONS = {
    header: 'Underwriter Questions',
    key: 'underwriter-questions',
    rows: ['pc', 'efd', 'o', 'ur', 'htrc'],
};

const buildingValue = ({ building_limit, additional_properties_limit, tenant_improvements_and_betterments_limit }: BuildingDataType) =>
    building_limit + additional_properties_limit + tenant_improvements_and_betterments_limit;

const bppValue = ({ contents_limit, electronic_data_processing_limit, stock_limit, property_of_others_limit }: BuildingDataType) =>
    contents_limit + electronic_data_processing_limit + stock_limit + property_of_others_limit;

export const BUILDING_COVERAGE_TABLE_DEFS = {
    building: {
        name: 'Building',
        readonly: true,
        field_format: { subtype: 'currency', decimals: 0 },
        cellDataGetter: (value, building: BuildingDataType) => {
            return buildingValue(building);
        },
    },
    bpp: {
        name: 'BPP',
        readonly: true,
        field_format: { subtype: 'currency', decimals: 0 },
        cellDataGetter: (value, building: BuildingDataType) => {
            return bppValue(building);
        },
    },
    bi_limit: {
        name: 'BI',
        readonly: true,
        field_format: { subtype: 'currency', decimals: 0 },
    },
    total: {
        name: 'Total',
        readonly: true,
        field_format: { subtype: 'currency', decimals: 0 },
        cellDataGetter: (value, building: BuildingDataType) => {
            return buildingValue(building) + bppValue(building) + building.bi_limit;
        },
    },
    percent_of_tiv: {
        name: 'Percent of TIV',
        readonly: true,
        cellDataGetter: (_value, building: BuildingDataType, _extraData, parentProps) => {
            const tiv = building.is_included_in_quote ? parentProps?.currentQuote?.final_premium?.tiv : null;
            if (!tiv) {
                return 'N/A';
            }
            const total_limits = buildingValue(building) + bppValue(building) + building.bi_limit;
            return `${Math.round((total_limits / tiv) * 100)}%`;
        },
    },
};

export const ADDITIONAL_BUILDING_TABLE_DEFS = {
    description: {
        name: 'Description',
    },
    exposure_type: { name: 'Exposure Type', constants_choices: 'ADDITIONAL_EXPOSURE_TYPES' },
    value: {
        name: 'Structure Value',
        field_format: { subtype: 'currency' },
    },
    contents_value: {
        name: 'Contents Value',
        field_format: { subtype: 'currency' },
    },
    total_value: {
        name: 'Total Value',
        readonly: true,
        field_format: { subtype: 'currency', integer_limit: 13 },
        cellDataGetter: (value, structure) => {
            return structure.value + structure.contents_value;
        },
    },
    rpv: {
        name: 'Real Property Value',
        readonly: true,
    },
    pp: {
        name: 'Personal Property',
        readonly: true,
    },
    bivalue: {
        name: 'BI Value',
        readonly: true,
    },
    othervalue: {
        name: 'Other Values',
        readonly: true,
    },
    totalvalue: {
        name: 'Total',
        readonly: true,
    },
    pct_tiv: {
        name: 'Percent of TIV',
        readonly: true,
    },
    bur: {
        name: 'BUR',
        readonly: true,
    },
    inspection: {
        name: 'Inspection',
        readonly: true,
    },
    wmitform: {
        name: 'Wind Mit Form',
        readonly: true,
    },
    cawa: {
        name: 'Average CA Score',
        field_format: { subtype: 'number', decimals: 2 },
    },
    cawc: {
        name: 'Lowest CA Score',
        field_format: { subtype: 'number', decimals: 2 },
    },
    distance_to_coast: {
        name: 'Distance to Tidal Water',
        readonly: true,
        cellDataGetter: (_value, { distance_to_coast_value, distance_to_coast_units }: BuildingDataType) => {
            if (distance_to_coast_value === null || distance_to_coast_value === undefined) {
                return '';
            }
            let unit = distance_to_coast_units ? ` ${distance_to_coast_units}` : '';
            if (distance_to_coast_units === 'miles') {
                unit = ' mi';
            } else if (distance_to_coast_units === 'feet') {
                unit = ' ft';
            }
            return `${distance_to_coast_value}${unit}`;
        },
    },
};
