import * as React from 'react';

import ValuationOverride from '../containers/ValuationOverride.container';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, unknown>;
    modalData: Record<string, unknown>;
    onClose: (x?: boolean) => void;
};

const ValuationOverrideBody = ({ onClose, modalData }: bodyPropTypes) => {
    return <ValuationOverride onClose={onClose} modalData={modalData} />;
};

let modalTypeObj = {
    Body: ValuationOverrideBody,
    Header: DefaultHeaderStatic('Valuation Override'),
    className: 'add-note-modal',
};
registerType(VIRA_MODAL_TYPES.VALUATION_OVERRIDE, modalTypeObj);
export default modalTypeObj;
