export const PEE_FIELDS = [
    'accounts_receivable',
    'asbestos_removal',
    'builders_risks',
    'builders_risks_soft_costs',
    'contingent_time_element',
    'debris_removal_additional_limit',
    'debris_removal_maximum',
    'debris_removal_percent_loss',
    'electronic_data_and_media',
    'emergency_removal_expense',
    'errors_or_omissions',
    'extended_period_of_bi_indemnity',
    'extra_expense',
    'fine_arts',
    'fire_brigade_charges',
    'fraud_and_deceit',
    'fungus_aggregate',
    'fungus_per_occurrence',
    'green_upgrades',
    'ingress_distance',
    'ingress_maximum',
    'ingress_weeks',
    'interruption_by_authority_distance',
    'interruption_by_authority_maximum',
    'interruption_by_authority_weeks',
    'leased_equipment_aggregate_limit',
    'leased_equipment_per_item_limit',
    'leasehold_interest',
    'limited_pollution_coverage',
    'lock_replacement',
    'misc_unnamed_locations',
    'newly_acquired_property_days',
    'newly_acquired_property_maximum',
    'ordinary_payroll',
    'personal_property_of_others',
    'plants_aggregate_limit',
    'plants_per_item_limit',
    'preservation_of_property_days',
    'professional_fees',
    'property_in_course_of_construction',
    'recharging_fire_equipment',
    'reclaiming_land_improvements',
    'reward_reimbursement',
    'royalties',
    'service_interruption_bi',
    'service_interruption_combined',
    'service_interruption_direct_damage',
    'sewer',
    'transit',
    'valuable_papers',
    'wind_driven_precipitation',
] as const;

export const PEE_RATE_FIELDS = [
    'accounts_receivable',
    'asbestos_removal',
    'builders_risks',
    'electronic_data_and_media',
    'emergency_removal_expense',
    'errors_or_omissions',
    'fine_arts',
    'fire_brigade_charges',
    'fraud_and_deceit',
    'fungus_aggregate',
    'leased_equipment_aggregate_limit',
    'leasehold_interest',
    'limited_pollution_coverage',
    'lock_replacement',
    'plants_aggregate_limit',
    'preservation_of_property_days',
    'professional_fees',
    'recharging_fire_equipment',
    'reclaiming_land_improvements',
    'reward_reimbursement',
    'sewer',
    'transit',
    'valuable_papers',
    'builders_risks_soft_costs',
    'ingress_maximum',
    'ordinary_payroll',
    'royalties',
] as const;
