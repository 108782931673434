import { ACCOUNT_PURPOSES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { transactionIsPremiumBearingAndNotRollback } from '../components/submissionlog/Helpers';
import { TransactionDataType } from '../ts-types/DataTypes';

export const showSubmissionPricing = (accountPurpose: (typeof ACCOUNT_PURPOSES)[keyof typeof ACCOUNT_PURPOSES], transaction: TransactionDataType) => {
    let isPremiumBearing = transactionIsPremiumBearingAndNotRollback(transaction);
    return accountPurpose !== ACCOUNT_PURPOSES.MIDPOLICY_ENDORSEMENT && isPremiumBearing;
};

export const showSubmissionPolicyTerms = (transaction: TransactionDataType) => transactionIsPremiumBearingAndNotRollback(transaction);
