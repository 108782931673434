import * as React from 'react';

import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';

import SubLogBottomPanelContainer from '../common/panels/containers/SubLogBottomPanel.container';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from './Helpers';
import { TableConfigType, RowSelectedFnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import isEqual from 'react-fast-compare';
import { getValidationsPanel } from '../../utils/render-helpers/quotes/quote-validations-helper';
import { getTaskSubtitles } from '../../utils/task-helpers';
import { AppContextFnsType, AppContextType } from '../../ts-types/AppTypes';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

type propTypes = {
    tableConfig: TableConfigType;
    appContext: AppContextType;
    appContextFns: AppContextFnsType;
    submissionRows: (number | 'loading')[];
    onRowSelected: RowSelectedFnType;
    selectedRowId?: number;
    env?: string;
    siteId?: number;
    me?: Record<string, any>;
};

class SubmissionLogApp extends React.Component<propTypes> {
    shouldComponentUpdate(nextProps: propTypes): boolean {
        return !isEqual(this.props, nextProps);
    }
    render(): React.ReactNode {
        const { appContext, selectedRowId, env, siteId, me } = this.props;
        const { currentSubmission, oldWebsite } = appContext;
        const bottomPanels = [getValidationsPanel(currentSubmission, oldWebsite)];

        return (
            <PagedTableContainer
                hasSearchPanel={true}
                waitForLoadingRows={true}
                allowsDragging={false}
                selectedRowId={selectedRowId}
                panelActions={calculateRowActions(this.props, ACTION_PANEL_LOCATIONS.SUBMISSION_LOG)}
                bottomPanel={{
                    ContentElement: SubLogBottomPanelContainer,
                    appContext,
                    label: 'Details',
                }}
                bottomPanels={bottomPanels}
                getTaskSubtitles={getTaskSubtitles}
                tasksPanel={<AccountTasksPanel />}
                extraCellRenderData={{ env, siteId, me }}
                {...this.props}
            />
        );
    }
}

export default SubmissionLogApp;
