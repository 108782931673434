import * as React from 'react';

import CloseSubmissionApp from '../apps/CloseSubmissionApp';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { AppContextFnsType, AppContextType } from '../../../../ts-types/AppTypes';
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

type bodyPropTypes = {
    onClose: (x?: boolean) => any;
    modalProps: Record<string, any>;
    appContextFns: AppContextFnsType;
    appContext: AppContextType;
    actionHelperFns: ActionHelperFnsType;
};

class CloseSubmissionBody extends React.Component<bodyPropTypes> {
    constructor(props: bodyPropTypes) {
        super(props);
    }

    render() {
        return <CloseSubmissionApp actionHelperFns={this.props.actionHelperFns} />;
    }
}

let modalTypeObj = {
    Body: CloseSubmissionBody,
    Header: DefaultHeaderStatic('Close Submission?'),
    className: 'close-submission-modal',
};
registerType(VIRA_MODAL_TYPES.CLOSED, modalTypeObj);
export default modalTypeObj;
