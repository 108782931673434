import * as React from 'react';

import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import LayeredPoliciesModalContainer from '../containers/LayeredPoliciesModalContainer';
import { DefaultHeader } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { AppContextType } from '../../../../ts-types/AppTypes';

type headerPropTypes = {
    modalData: Record<string, any>;
};

const title = 'SCHEDULE OF PRIMARY, UNDERLYING AND PARTICIPATING EXCESS INSURANCE';

export function LayeredPoliciesModalHeader(props: headerPropTypes) {
    return <DefaultHeader title={title} />;
}

type bodyPropTypes = {
    modalData: Record<string, any>;
    onClose: (x?: boolean) => any;
    appContext: AppContextType;
};

class LayeredPoliciesModalBody extends React.Component<bodyPropTypes> {
    constructor(props: bodyPropTypes) {
        super(props);
    }

    render() {
        return <LayeredPoliciesModalContainer {...this.props} />;
    }
}

let modalTypeObj = {
    Body: LayeredPoliciesModalBody,
    Header: LayeredPoliciesModalHeader,
    className: 'layered-policies-modal',
    noDismiss: true,
};
registerType(VIRA_MODAL_TYPES.LAYERED_POLICIES, modalTypeObj);
export default modalTypeObj;
