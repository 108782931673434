import * as React from 'react';
import isEqual from 'react-fast-compare';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { SUBMISSION_STATUS } from '../../constants/Submission';

type stateTypes = {
    isPinned: boolean;
};

type propTypes = Record<string, any>;

export default class SubmissionSwimTileApp extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            isPinned: props.pinned,
        };
    }

    shouldComponentUpdate(nextProps: propTypes, nextState: stateTypes) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    getUserAbbreviation() {
        const split = this.props.underwriter_full_name.split(' ');
        let abbr;
        switch (split.length) {
            case 1:
                abbr = `${split[0][0]}`;
                break;
            case 2:
                abbr = `${split[0][0]} ${split[1][0]}`;
                break;
            case 3:
                abbr = `${split[0][0]} ${split[2][0]}`;
                break;
            default:
                abbr = 'N/A';
                break;
        }

        return abbr;
    }

    getDaysTillInception() {
        if (!this.props.policy_inception_date) return 'N/A';
        const currentDate = moment(new Date()).format(DEFAULT_DATE_FORMAT);
        const daysTillException = moment(this.props.policy_inception_date, DEFAULT_DATE_FORMAT);
        const duration = moment.duration(daysTillException.diff(currentDate));
        return Math.round(duration.asDays());
    }

    getDateFormatted() {
        const d = this.props.policy_inception_date.split('-');
        return d[1] + '/' + d[2] + '/' + d[0];
    }

    handleSendToScrub = (e: React.UIEvent<HTMLElement>) => {
        this.props.onClickTile(e, this.props);
    };

    handlePin = (e: React.UIEvent<HTMLElement>) => {
        e.stopPropagation();
        this.setState({ isPinned: !this.state.isPinned });
        this.props.onPinSubmission(this.props.id, this.state.isPinned);
    };

    handleDbClick = (e: React.UIEvent<HTMLElement>) => {
        e.stopPropagation();
        this.props.onDblClickTile(e, this.props);
    };

    render(): React.ReactNode {
        return (
            <div style={{ ...this.props.style, width: '94%' }} onClick={this.handleDbClick}>
                <div className="swim-tile">
                    <div className="swim-status blue"></div>

                    <div className="swim-values">
                        <label>SID :</label>
                        <figure>{this.props.id}</figure>

                        <label>Name :</label>
                        <figure>{this.props.insured_name}</figure>

                        <label>Eff. Date :</label>
                        <figure>{this.getDateFormatted()}</figure>

                        <label>Inception :</label>
                        <figure>{this.getDaysTillInception()}</figure>

                        <label>Broker :</label>
                        <figure>{this.props.account_broker_name}</figure>
                    </div>

                    <div className="swim-tags">
                        <span className="prioritize">
                            <i onClick={this.handlePin} style={this.state.isPinned ? { color: 'red' } : {}} className="material-icons">
                                {this.state.isPinned ? 'favorite' : 'favorite_border'}
                            </i>
                        </span>

                        <If condition={this.props.status === SUBMISSION_STATUS.ENTERING_DATA && !this.props.is_rush}>
                            <span className="prioritize">
                                <i onClick={this.handleSendToScrub} title="Send for scrub" className="material-icons">
                                    send
                                </i>
                            </span>
                        </If>

                        <If condition={this.props.is_rush}>
                            <span className="active">RUSH</span>
                        </If>

                        <span className="user" title={this.props.underwriter_full_name}>
                            <i>{this.getUserAbbreviation()}</i>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
