import React from 'react';
import _ from 'lodash';
import CheckboxInput from '@archinsurance-viki/property-jslib/src/components/inputs/v2/CheckboxInput';

type propTypes = {
    onSelect: (key: string, value: any, added: boolean) => void;
    boxChoices: Record<string, any>[];
    object: Record<string, any>;
    name: string;
};

type choicePropType = {
    name: string;
    value: any;
    label: string;
    checked?: boolean;
    className?: string;
    optionsLabel?: string;
    onChange: (key: string, value: any, added: boolean) => void;
};

export default class SelectBox extends React.Component<propTypes> {
    renderOptions(): React.ReactNode {
        const { name, object } = this.props;
        console.assert(_.isArray(object[name]), 'SelectBox requires object type array!!');

        return this.props.boxChoices.map((choice: choicePropType, index: number) => {
            if (choice.optionsLabel) {
                return (
                    <div key={index}>
                        <h4 className={choice.className}>{choice.optionsLabel}</h4>
                    </div>
                );
            }
            const checked = object[name].includes(choice.value);
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.props.onSelect(this.props.name, choice.value, e.target.checked);
            return (
                <label key={index} className="tw-flex tw-items-center tw-gap-2 tw-mb-2" htmlFor={choice.value}>
                    <CheckboxInput className="tw-h-[15px] tw-w-[15px]" checked={checked} onChange={handleChange} />
                    {choice.label}
                </label>
            );
        });
    }

    render(): React.ReactNode {
        return (
            <>
                <div>{this.renderOptions()}</div>
            </>
        );
    }
}
