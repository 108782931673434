import * as React from 'react';

import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';
import { TableConfigType, RowSelectedFnType, PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import BuildingCoverageBottomPanelContainer from './BuildingCoverageBottomPanelContainer';
import { AppContextType } from '../../ts-types/AppTypes';
import { submissionIsEditable, transactionIsPremiumBearing } from '../submissionlog/Helpers';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

type propTypes = {
    appContext: AppContextType;
    tableConfig: TableConfigType;
    tableData: PagedTableDataType;
    onRowSelected: RowSelectedFnType;
    selectedRowId?: number;
};

export default class BuildingCoverageApp extends React.Component<propTypes> {
    render() {
        let panelRowActions: any = {};
        let extraSections = [];

        const {
            appContext: { currentTransaction, currentSubmission, currentSubmissionId, currentBuilding },
            tableConfig,
        } = this.props;

        let isPremiumBearing = transactionIsPremiumBearing(currentTransaction);
        let isSubmissionEditable = submissionIsEditable(currentSubmission);
        let isReadonly = !isSubmissionEditable || !isPremiumBearing;
        let editable = !isReadonly;

        return (
            <PagedTableContainer
                {...this.props}
                hasSearchPanel={true}
                editingDisabled={!editable}
                panelActions={{ panelRowActions, extraSections }}
                tableConfig={tableConfig}
                getTaskSubtitles={null}
                bottomPanel={{
                    ContentElement: BuildingCoverageBottomPanelContainer,
                    label: 'Details',
                    currentSubmission,
                    currentSubmissionId,
                    currentBuilding,
                    readonly: !editable,
                }}
                tasksPanel={<AccountTasksPanel />}
            />
        );
    }
}
