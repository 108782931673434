import { useSubmissionIsEditable } from '../../../hooks/submissions';
import _ from 'lodash';
import cx from 'classnames';
import { usePolicyTermsContext } from '../utils/coverage-utils';
import { useAppContext } from '../../../hooks/context';
import { BuildingCoverageType } from '../../../ts-types/ApiTypes';

export const BulkEditBuildingCoverageOptions = () => {
    const isEditable = useSubmissionIsEditable();
    const { currentQuote } = useAppContext();

    const { selectedBuildingCoverageIds, handleSelectedBuildingCoverageChange, setSelectedBuildingCoverageIds, buildingCoverageId } = usePolicyTermsContext();

    const buildingCoveragesIds = currentQuote.policy_coverage.building_coverages.map(bc => bc.id);

    const isAllBuildingCoverageActive = _.isEqual([...buildingCoveragesIds].sort(), [...selectedBuildingCoverageIds].sort());

    const renderBuildingOption = (buildingData: BuildingCoverageType) => {
        return (
            <>
                <a
                    className={cx('select-cell material-icons tw-select-none', {
                        active: selectedBuildingCoverageIds.includes(buildingData.id),
                        'hover:tw-text-grey-checkbox': !selectedBuildingCoverageIds.includes(buildingData.id) && !isEditable,
                    })}
                    title="Select"
                    onClick={() => {
                        if (buildingData.id === buildingCoverageId || !isEditable) {
                            return;
                        }
                        handleSelectedBuildingCoverageChange(buildingData.id);
                    }}
                >
                    done
                </a>
                <div className="data-cell tw-min-w-[64px]">{buildingData.location_code}</div>
                <div className="data-cell tw-min-w-[64px]">{buildingData.building.id}</div>
                <div className="data-cell tw-min-w-[144px]">{buildingData.building.description}</div>
                <div className="data-cell tw-min-w-[176px]">{buildingData.building.street}</div>
                <div className="data-cell tw-min-w-[144px]">{buildingData.building.city}</div>
                <div className="data-cell tw-min-w-[48px]">{buildingData.building.state}</div>
                <div className="data-cell tw-min-w-[112px]">{buildingData.building.county}</div>
                <div className="data-cell tw-min-w-[64px]">{buildingData.building.zipcode}</div>
            </>
        );
    };

    const handleAllClick = () => {
        setSelectedBuildingCoverageIds(isAllBuildingCoverageActive ? [buildingCoverageId] : buildingCoveragesIds);
    };

    return (
        <div className="info-block subpaneltop resize-on" style={{ overflow: 'hidden' }}>
            <div className="w-100" style={{ position: 'absolute', top: 0, overflow: 'hidden' }}>
                <div className="grid-layout settings panelgeneral w-100">
                    <div className="flex fd-r table-header">
                        <div className="data-cell">Select Buildings</div>
                        <div className="data-cell" style={{ borderLeft: 0 }}>
                            <div className="attached-item search-bar grow w-100">
                                <div
                                    className="icon icon-search"
                                    style={{
                                        border: '1px solid #eaeaea',
                                        borderRight: 0,
                                        height: '25px',
                                        flexGrow: 0,
                                        width: '25px',
                                        paddingLeft: '5px',
                                    }}
                                />
                                <input
                                    className="search-input"
                                    style={{
                                        border: '1px solid #eaeaea',
                                        flexGrow: 1,
                                        marginRight: '4px',
                                        height: '25px',
                                        padding: '0 5px',
                                        fontFamily: 'sans-serif',
                                        fontWeight: 'normal',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100" style={{ position: 'absolute', top: '33px', overflowY: 'auto', height: 'calc(100% - 33px)' }}>
                <div className="grid-layout settings panelgeneral w-100">
                    <div className="flex fd-r">
                        <a
                            className={cx('select-cell material-icons tw-select-none', {
                                active: isAllBuildingCoverageActive,
                                'hover:tw-text-grey-checkbox': !isEditable,
                            })}
                            title="Select All"
                            onClick={() => {
                                if (isEditable) {
                                    handleAllClick();
                                }
                            }}
                        >
                            done
                        </a>
                        <div className="data-cell tw-font-semibold tw-min-w-[64px]">Loc/Bld</div>
                        <div className="data-cell tw-font-semibold tw-min-w-[64px]">Bldg ID</div>
                        <div className="data-cell tw-font-semibold tw-min-w-[144px]">Building Description</div>
                        <div className="data-cell tw-font-semibold tw-min-w-[176px]">Street Address</div>
                        <div className="data-cell tw-font-semibold tw-min-w-[144px]">City</div>
                        <div className="data-cell tw-font-semibold tw-min-w-[48px]">State</div>
                        <div className="data-cell tw-font-semibold tw-min-w-[112px]">County</div>
                        <div className="data-cell tw-font-semibold tw-min-w-[64px]">Zip</div>
                    </div>

                    {currentQuote.policy_coverage.building_coverages.map((data: BuildingCoverageType) => (
                        <div key={data.id} className="flex fd-r">
                            {renderBuildingOption(data)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
