import React from 'react';
import cx from 'classnames';

export const getTivRate = (premium: string | number, tiv: string | number) => (+premium / +tiv) * 100;
export const getAmountSubjectRate = (premium: string | number, amountSubject: string | number) => (+premium / +amountSubject) * 100;

type ButtonProps = {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    error?: boolean;
    onClick?: () => void;
};
export const Button = ({ children, className, disabled, error, onClick }: ButtonProps) => (
    <button type="button" onClick={onClick} className={`${className || ''} ${error ? 'red' : 'blue'} rounded-sm`} disabled={disabled}>
        {children}
    </button>
);

type TwButtonProps = {
    children: React.ReactNode;
    className?: string;
    disabled?: boolean;
    onClick: () => void;
};

export const TwButton = ({ children, className, disabled, onClick }: TwButtonProps) => (
    <button
        className={cx(
            'no-viki-style',
            'tw-py-2 tw-px-4',
            'tw-flex tw-items-center tw-justify-center',
            'tw-text-center tw-text-4xs tw-font-raleway tw-font-bold tw-tracking-[2px] tw-uppercase',
            'tw-shadow-md',
            'tw-cursor-pointer',
            disabled ? 'tw-text-transparent-10 tw-bg-grey-light' : 'tw-text-white tw-bg-blue-primary',
            className
        )}
        disabled={disabled}
        onClick={onClick}
    >
        <div className="tw-text-center">{children}</div>
    </button>
);

type ErrorDetailObject = {
    error_detail_list?: {
        detailObjs?: Record<string, { field?: string; message: string }>;
    };
};
export const findFieldErrors = (error: ErrorDetailObject, searchField: string) => {
    return Object.values(error?.error_detail_list?.detailObjs || [])
        .filter(obj => obj.field === searchField)
        .map(obj => obj.message)
        .join('\n');
};

export const parseErrorResponse = (error: any, field: string) => {
    const fieldSpecificError = findFieldErrors(error, field) || (error[field] as string);
    const errorArray: string | null = Array.isArray(error) ? error.join('\n') : null;
    return fieldSpecificError || errorArray || 'Error while trying to reprice.';
};
