import * as types from '../constants/ActionTypes';
import * as jslibTypes from '@archinsurance-viki/property-jslib/src/constants/ActionTypes';
import u from 'updeep';
import { eq } from '@archinsurance-viki/property-jslib/src/utils/updeep-helpers';

let initialState = {
    accounts: {},
    submissionIdToAccount: {},
};

export const NO_ACCOUNT = 'NO_ACCOUNT';

export const SUBMISSION_SHORTCUT = {
    CURRENT: 'current', // most recent bound version
    ORIGINAL: 'original', // original bound version (same as account id)
    REVIEW: 'review', // the current in review endorsement if there is one
    INITIAL: 'initial', // original, unless account modified endorsement was applied
};

// TODO: Replace with pure RTK Query calls
function accountsReducer(state: any = initialState, action: any) {
    switch (action.type) {
        case types.RECEIVE_ACCOUNT: {
            let { account, submissionIdToAccount } = action;
            return u(
                {
                    accounts: {
                        [account.id]: account,
                    },
                    submissionIdToAccount,
                },
                state
            );
        }

        case types.RECEIVE_NO_ACCOUNT: {
            let { id } = action;
            return u(
                {
                    accounts: { [id]: { id: NO_ACCOUNT } },
                },
                state
            );
        }

        case types.DISCARD_ENDORSEMENT: {
            return u.updateIn(`accounts.${action.accountId}.account_transaction_ids`, u.reject(eq(action.transactionId)), state);
        }

        case jslibTypes.DELETE_TABLE_ROW: {
            let accounts = {};
            accounts = Object.assign(state.accounts, accounts);
            const accountId = Object.keys(accounts).find(accountId => accounts[accountId].account_transaction_ids?.includes(action.rowId));
            if (accountId) {
                return u.updateIn(`accounts.${accountId}.account_transaction_ids`, u.reject(eq(action.rowId)), state);
            } else {
                return state;
            }
        }

        default:
            return state;
    }
}

export default accountsReducer;
