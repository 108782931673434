import { FormDateInput, isValidDate } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormDateInput';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { isString } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormValues, PolicyTermsFormField, selectSortedAlphabeticalChoices } from '../utils/form';
import { useAppContext } from '../../../hooks/context';
import { TRANSACTION_TYPES } from '../../../constants/TransactionConstants';

// old class based https://gist.github.com/josephmisiti/f0613f8cf1c0dcce9f26bef61b495c6c

const isValidDateString = (data: unknown) => {
    if (!isString(data)) {
        return false;
    }
    return data === '' || isValidDate(new Date(data));
};

export const PolicyLevelOverview = () => {
    const { currentTransaction } = useAppContext();
    const COVERAGE_FORM_TYPES = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'COVERAGE_FORM_TYPES'));
    const CAUSE_OF_LOSS_POLICY_FORMS = useAppSelector(state => selectSortedAlphabeticalChoices(state, 'CAUSE_OF_LOSS_POLICY_FORMS'));

    // TODO: Figure out cleaner way to handle type narrowing of Array.includes()
    const isEffectiveDateDisabled =
        currentTransaction &&
        !([TRANSACTION_TYPES.NEW_BUSINESS, TRANSACTION_TYPES.RENEWAL_BUSINESS] as string[]).includes(currentTransaction?.transaction_type);

    return (
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormInput<PolicyTermsFormValues> name="quote_description" className="tw-w-full tw-max-w-none tw-text-left" />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormDateInput<PolicyTermsFormValues>
                    name="effective_date"
                    disabled={isEffectiveDateDisabled}
                    rules={{ validate: (data: unknown) => isValidDateString(data) || 'Invalid date' }}
                />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormDateInput<PolicyTermsFormValues>
                    name="expiration_date"
                    rules={{ validate: (data: unknown) => isValidDateString(data) || 'Invalid date' }}
                />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="coverage_form" selections={COVERAGE_FORM_TYPES} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="form" selections={CAUSE_OF_LOSS_POLICY_FORMS} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormInput<PolicyTermsFormValues> name="agent_commission_rate" maskOptions={MASKS['PERCENTAGE_POSITIVE']} percentAfter />
            </PolicyTermsFormField>
        </div>
    );
};
