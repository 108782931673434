import React, { useContext } from 'react';
import classNames from 'classnames';

import { PANELS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import SubmissionInfo from './SubmissionInfo';
import NavIcon from '@archinsurance-viki/property-jslib/src/components/widgets/NavIcon';
import VikiLogo from '@archinsurance-viki/property-jslib/src/components/widgets/VikiLogo';
import DebugInfo from '@archinsurance-viki/property-jslib/src/components/widgets/DebugInfo';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { updateUi } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';

type propTypes = {
    noCurrentSubmission?: boolean;
    transactionPulldown?: string;
    topPanelHeader?: React.ReactNode | string;
};

export default function TopPanel(props: propTypes) {
    const { noCurrentSubmission, transactionPulldown, topPanelHeader } = props;
    const dispatch = useAppDispatch();
    const sharedContext = useContext(AppContext);
    const ENV = useAppSelector(state => state.global.ENV);
    const leftPanelOpen = useAppSelector(state => state.uiState.openPanels[PANELS.LEFT]);
    const debugOpen = useAppSelector(state => state.uiState.TopBar.debugOpen);

    const handleToggleLeftPanel = () => {
        sharedContext.onTogglePanel(PANELS.LEFT);
    };

    const toggleDebugBar = () => {
        dispatch(updateUi({ data: { debugOpen: !debugOpen }, dataPath: 'TopBar' }));
    };

    const showDebugPanel = ENV.ENV !== 'production';
    const isRAE = ENV.SITE.id === 5;
    const appName = isRAE ? 'RAE' : 'VIKI';
    const features = ENV?.TENANT_SETTINGS?.features;
    const subTitle = features?.ventus_site_rebrand ? 'By Ventus Risk' : '';
    return (
        <>
            <div
                key="header-main"
                className={classNames('header-main', {
                    'env-not-production': showDebugPanel,
                    'tw-h-[65px]': typeof topPanelHeader === 'string',
                })}
            >
                <div className="header-left" key="header-logo">
                    <NavIcon open={leftPanelOpen} onClick={handleToggleLeftPanel} />
                    <VikiLogo title={appName} subtitle={subTitle} newLogo={features?.ventus_new_logo} />
                </div>
                <div className="header-right">
                    <TopPanelHeader topPanelHeader={topPanelHeader} noCurrentSubmission={noCurrentSubmission} transactionPulldown={transactionPulldown} />
                </div>
            </div>
            <DebugInfo key="debug-info" ENV={ENV} open={debugOpen} toggleDebugBar={toggleDebugBar} />,
        </>
    );
}

function TopPanelHeader({ topPanelHeader, noCurrentSubmission, transactionPulldown }: propTypes) {
    if (topPanelHeader) {
        if (typeof topPanelHeader === 'string') {
            return <div className="status-area main-label">{topPanelHeader}</div>;
        }
        return <>{topPanelHeader}</>;
    }
    return <SubmissionInfo noCurrentSubmission={noCurrentSubmission} transactionPulldown={transactionPulldown} />;
}
