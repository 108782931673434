export const TRANSACTION_TYPES = {
    NEW_BUSINESS: 'NB',
    ENDORSEMENT: 'EN',
    FLAT_CANCELLATION: 'CFL',
    SHORT_RATE_CANCELLATION: 'CSR',
    NON_FLAT_RATE_CANCELLATION: 'CPR',
    RENEWAL_BUSINESS: 'RB',
    REINSTATEMENT: 'RET',
    REVERSION: 'REV',
    NEW_BUSINESS_REWRITE: 'NBR',
    RENEWAL_BUSINESS_REWRITE: 'RBR',
    NEW_BINDER_REVISION: 'NBV',
    RENEWAL_BINDER_REVISION: 'RBV',
    CORRECTION: 'COR',
} as const;

export const REWRITE_AND_REVISION_TRANSACTION_TYPES = [
    TRANSACTION_TYPES.NEW_BINDER_REVISION,
    TRANSACTION_TYPES.RENEWAL_BINDER_REVISION,
    TRANSACTION_TYPES.NEW_BUSINESS_REWRITE,
    TRANSACTION_TYPES.RENEWAL_BUSINESS_REWRITE,
];

export const NEEDS_ENDORSEMENT_PACKAGE_TRANSACTION_TYPES = [
    TRANSACTION_TYPES.ENDORSEMENT,
    TRANSACTION_TYPES.FLAT_CANCELLATION,
    TRANSACTION_TYPES.SHORT_RATE_CANCELLATION,
    TRANSACTION_TYPES.NON_FLAT_RATE_CANCELLATION,
    TRANSACTION_TYPES.REINSTATEMENT,
];

export const STUB_SUBMISSION_TRANSACTION_TYPES = [TRANSACTION_TYPES.REVERSION, TRANSACTION_TYPES.REINSTATEMENT];

export const CANCELLATION_TYPES = [
    TRANSACTION_TYPES.FLAT_CANCELLATION,
    TRANSACTION_TYPES.SHORT_RATE_CANCELLATION,
    TRANSACTION_TYPES.NON_FLAT_RATE_CANCELLATION,
];

export const RENEWAL_TRANSACTION_TYPES = [
    //what else should go in here?
    TRANSACTION_TYPES.RENEWAL_BUSINESS,
];

export const TRANSACTION_NAMES = {
    NB: 'NEW_BUSINESS',
    EN: 'ENDORSEMENT',
    CFL: 'FLAT_CANCELLATION',
    CSR: 'SHORT_RATE_CANCELLATION',
    CPR: 'NON_FLAT_RATE_CANCELLATION',
    RB: 'RENEWAL_BUSINESS',
    RET: 'REINSTATEMENT',
    REV: 'REVERSION',
    NBR: 'NEW_BUSINESS_REWRITE',
    RBR: 'RENEWAL_BUSINESS_REWRITE',
    NBV: 'NEW_BINDER_REVISION',
    RBV: 'RENEWAL_BINDER_REVISION',
    COR: 'CORRECTION',
};

export const BASIC_DETAILS = {
    header: 'Basic Details',
    key: 'basic-details',
    rows: ['s', 'sid', 'seqn', 'tn', 'tt', 'ed', 'cb', 'review_by', 'bound_by', 'booked_by', 'ofac'],
    readonly: true,
};

export const DATES = {
    header: 'Dates',
    key: 'dates',
    rows: ['c', 'r', 'pd', 'efd', 'dk', 'ac', 'po', 'cpuo'],
    readonly: true,
};

export const CAT_PRICING_DETAILS = {
    header: 'Cat Pricing Details',
    key: 'cat-details',
    rows: ['aclc', 'amcnp', 'amcp', 'atcp', 'atel', 'rclc', 'rmcnp', 'rmcp', 'rtcp', 'rtel'],
    readonly: true,
};

export const PRICING_DETAILS = {
    header: 'Pricing Details',
    key: 'pricing-details',
    rows: ['ept', 'ifee', 'mf', 'ebepo', 'atbpt', 'rtbpt', 'cpbt', 'ccp', 'cap', 'ct', 'ceb', 'cp', 'ar', 'ebar'],
};

export const PRINT_ENDORSEMENT_DESCRIPTION = {
    header: 'Print Endorsement Description',
    key: 'print-endorsement-description',
    field: 'print_endorsement_description',
    type: 'TEXT',
    modal_data: {
        title: 'Enter endorsement description for Policy Change Form',
        modalData: {},
    },
};

export const CARRIER_PARTICIPATION = {
    header: 'Carrier Participtaion',
    key: 'carrier-participation',
    field: 'carrier_participation',
    type: 'TABLE',
};
