import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { PAGED_TABLE_VIEW_SETTINGS, SUMMARY_CONFIGS } from './PagedTableViewSettings';
import { COMBINED_TABLE_GLOSSARY } from './CombinedTableGlossary';
import SWIMLANE_CONFIGS from './SwimlaneConfigs';

const BUILDING_GRID: TableConfigType = {
    tableReducerPath: 'buildings',
    tableName: 'buildingdatasqlview',
    apiEndpoint: 'building_data',
    rowDataName: 'Building',
    defaultTab: 'data-entry',
    noCsvDownload: true,
    fetchFields: {
        geocoded_address: true,
        gut_reno_approved_default: true,
        gut_reno_haircut_default: true,
        pretty_address: true,
        exposure: true,
        _validations: true,
        geo: true,
        ppc_default: true,
        sinkhole_rating_default: true,
    },
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.buildingdatasqlview,
    tableGlossary: COMBINED_TABLE_GLOSSARY.buildingdatasqlview,
    summaryConfigs: SUMMARY_CONFIGS,
    disableStaleData: true,
    noLocalStorage: true,
};
const BUILDING_PREMIUMS: TableConfigType = {
    tableReducerPath: 'buildingPremiums',
    tableName: 'buildingpremiumsqlview',
    apiEndpoint: 'building_premium',
    rowDataName: 'Building Premiums',
    noActiveRow: true,
    noCsvDownload: true,
    totalsRow: { location_number: false, defaultOn: true },
    fetchFields: {},
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.buildingpremiumsqlview,
    tableGlossary: COMBINED_TABLE_GLOSSARY.buildingpremiumsqlview,
    summaryConfigs: SUMMARY_CONFIGS,
    noLocalStorage: true,
};
const BUILDING_COVERAGE_GRID: TableConfigType = {
    tableReducerPath: 'buildingCoverages',
    tableName: 'buildingcoveragegridsqlview',
    apiEndpoint: 'building_coverage_grid',
    rowDataName: 'Building Coverages',
    noActiveRow: true,
    noCsvDownload: true,
    totalsRow: {},
    fetchFields: {},
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.buildingcoveragegridsqlview,
    tableGlossary: COMBINED_TABLE_GLOSSARY.buildingcoveragegridsqlview,
    summaryConfigs: SUMMARY_CONFIGS,
    noLocalStorage: true,
};
const BUILDING_QUOTE_CONDITIONS: TableConfigType = {
    tableReducerPath: 'buildingQuoteConditions',
    tableName: 'buildingquoteconditionssqlview',
    apiEndpoint: 'building_quote_conditions',
    rowDataName: 'Quote Conditions',
    alwaysShowColumnSetter: true,
    fetchFields: {
        quote_id: true,
        manually_applied_quote_conditions: true,
    },
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.building_quote_conditions,
    tableGlossary: COMBINED_TABLE_GLOSSARY.building_quote_conditions,
    noLocalStorage: true,
};
const TRANSACTION_BUILDING_PREMIUMS: TableConfigType = {
    tableReducerPath: 'transactionBuildingPremiums',
    tableName: 'accounttransactionbuildingdatasqlview',
    apiEndpoint: 'account_transaction_building_data',
    rowDataName: 'Transaction Building Premiums',
    noActiveRow: true,
    noCsvDownload: true,
    totalsRow: { latest_location_number: false, defaultOn: true },
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.accounttransactionbuildingdatasqlview,
    tableGlossary: COMBINED_TABLE_GLOSSARY.accounttransactionbuildingdatasqlview,
    summaryConfigs: SUMMARY_CONFIGS,
    noLocalStorage: true,
};
const BLANKET_LIMITS: TableConfigType = {
    tableReducerPath: 'blanketLimits',
    tableName: 'blanket_limits',
    apiEndpoint: 'building_premium',
    tableGlossaryName: 'buildingpremiumsqlview',
    rowDataName: 'Building',
    fetchFields: {
        geocoded_address: true,
        pretty_address: true,
        exposure: true,
        _validations: true,
        geo: true,
    },
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.blanket_limits,
    tableGlossary: COMBINED_TABLE_GLOSSARY.buildingpremiumsqlview,
    summaryConfigs: SUMMARY_CONFIGS,
    noLocalStorage: true,
};
const SUBMISSION_LOG: TableConfigType = {
    tableReducerPath: 'submissionLog',
    tableName: 'accounttransactiondatasqlview',
    apiEndpoint: 'account_transaction_data',
    rowDataName: 'Submission',
    onAddGoToTab: 'clearance',
    defaultTab: 'recents',
    onDuplicateGoToTab: 'underwriting',
    staticApiParams: {
        is_latest: true,
    },
    useMultiplePanelFilters: true,
    fetchFields: {
        status: true,
        account_id: true,
        account_view_id: true,
        is_editable: true,
    },
    ignoreFieldsForExport: [
        'producer_id',
        'licensed_broker_id',
        'brokerage_office_id',
        'account_broker_id',
        'declined_reason',
        'ntu_reason',
        'underwriter_id',
        'status',
        'primary_quote_status',
        'id',
        'account_view_id',
        'is_editable',
    ],
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.accounttransactiondatasqlview,
    tableGlossary: COMBINED_TABLE_GLOSSARY.accounttransactiondatasqlview,
    summaryConfigs: SUMMARY_CONFIGS,
    disableStaleData: true,
    swimlanesOpts: SWIMLANE_CONFIGS.SUBMISSION_LOG,
    noAutoSelect: true,
    noLocalStorage: true,
};
const TASKS: TableConfigType = {
    tableReducerPath: 'backgroundTasks',
    tableName: 'backgroundtask',
    apiEndpoint: 'tasks',
    rowDataName: 'Task',
    fetchFields: {},
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.backgroundtask,
    tableGlossary: COMBINED_TABLE_GLOSSARY.backgroundtask,
    summaryConfigs: SUMMARY_CONFIGS,
    noLocalStorage: true,
};

const REGIONAL_CAT_SUMMARY: TableConfigType = {
    tableReducerPath: 'regionalCATSummary',
    tableName: 'airlossbyregionandperil',
    apiEndpoint: 'regional_cat_summary',
    rowDataName: 'CATSummary',
    noActiveRow: true,
    noCsvDownload: true,
    fetchFields: {},
    viewSettingsConfig: PAGED_TABLE_VIEW_SETTINGS.regional_cat_summary,
    tableGlossary: COMBINED_TABLE_GLOSSARY.regional_cat_summary,
    noLocalStorage: true,
};

export const PAGED_TABLES = {
    BUILDING_GRID,
    BUILDING_PREMIUMS,
    BUILDING_COVERAGE_GRID,
    BUILDING_QUOTE_CONDITIONS,
    TRANSACTION_BUILDING_PREMIUMS,
    BLANKET_LIMITS,
    SUBMISSION_LOG,
    TASKS,
    REGIONAL_CAT_SUMMARY,
};
