import _ from 'lodash';
import * as React from 'react';

import { formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { VIRA_MODAL_TYPES, BPP_COVERAGES_ROWS, TIME_ELEMENT_ROWS } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeader } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

type headerPropTypes = {
    modalData: Record<string, any>;
};

export function PPEHeader(props: headerPropTypes) {
    let { title } = props.modalData;
    title = `${title} PROPERTY ENHANCEMENT ENDORSEMENT LOSS COSTS`;
    return <DefaultHeader title={title} />;
}

type bodyPropTypes = {
    description: string;
    modalData: Record<string, any>;
};

class PPEBody extends React.Component<bodyPropTypes> {
    renderRow = (c: Record<string, any>, idx: number) => {
        let { coverages } = this.props.modalData.peeInterimData;
        let coverage = _.find(coverages, cc => {
            return cc.field === c.prefix;
        });
        let prfx = '$';
        let psfx = undefined;
        if (c.field === 'percentage') {
            prfx = undefined;
            psfx = '%';
        } else if (c.field === 'text') {
            prfx = undefined;
        }
        return (
            <tr key={idx}>
                <td>{c.col.toUpperCase()}</td>
                <td>{c.description}</td>
                <td className="num">{coverage && formatNumber(coverage.included_limit, prfx, psfx, 0)}</td>
                <td className="num">{coverage && formatNumber(coverage.adjusted_limit, prfx, psfx, 0)}</td>
                <td className="num">{coverage && formatNumber(coverage.rate, undefined, undefined, 3)}</td>
                <td className="num">{coverage && formatNumber(coverage.addl_loss_cost, prfx, psfx, 0)}</td>
            </tr>
        );
    };

    renderRows = (rowConfig: Record<string, any>[]) => {
        return rowConfig.map((c, idx) => {
            return this.renderRow(c, idx);
        });
    };

    buildTable = (title: string, rowConfig: Record<string, any>[]) => {
        return [
            <h3 key="title">{title}</h3>,
            <table key="table" className="summary three-col">
                <thead>
                    <tr>
                        <th style={{ width: '35px', minWidth: 'unset' }} />

                        <th>Description</th>

                        <th className="num">Included Limit</th>

                        <th className="num">Adjusted Limit</th>

                        <th className="num">Rate</th>

                        <th className="num">Additional Loss Cost</th>
                    </tr>
                </thead>
                <tbody>{this.renderRows(rowConfig)}</tbody>
            </table>,
        ];
    };

    render() {
        let { peeInterimData } = this.props.modalData;
        return (
            <div className="standard-modal-content viki-ppe-modal">
                {this.buildTable('BPP Coverage Limits', BPP_COVERAGES_ROWS)}
                {this.buildTable('Time Element Limits', TIME_ELEMENT_ROWS)}
                <h3>Totals</h3>
                <table className="summary three-col">
                    <tbody>
                        <tr>
                            <td style={{ width: '35px' }} />
                            <td>Loss Cost for Included Limits</td>
                            <td />
                            <td />
                            <td />
                            <td className="num">{formatNumber(peeInterimData.pee_included_limits_final, '$', undefined, 0)}</td>
                        </tr>
                        <tr>
                            <td style={{ width: '35px' }} />
                            <td>Loss Cost for Adjusted Limits</td>
                            <td />
                            <td />
                            <td />
                            <td className="num">{formatNumber(peeInterimData.pee_adjusted_limits_final, '$', undefined, 0)}</td>
                        </tr>
                        <tr>
                            <td style={{ width: '35px' }} />
                            <td>TOTAL LOSS COST</td>
                            <td />
                            <td />
                            <td />
                            <td className="num">{formatNumber(peeInterimData.pee_limits_final, '$', undefined, 0)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

type footerPropTypes = {
    onClose: (x?: boolean) => any;
};

class PPEFooter extends React.Component<footerPropTypes> {
    render() {
        return (
            <div className="button-row">
                <button
                    className="blue"
                    onClick={() => {
                        this.props.onClose(true);
                    }}
                >
                    Ok
                </button>
            </div>
        );
    }
}

let modalTypeObj = {
    Body: PPEBody,
    Header: PPEHeader,
    Footer: PPEFooter,
    className: 'ppe-modal',
};
registerType(VIRA_MODAL_TYPES.PPE, modalTypeObj);
export default modalTypeObj;
