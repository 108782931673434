import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import AdditionalNamedInsuredsApp from '../apps/AdditionalNamedInsuredsInsuredsModalApp';
import { addOrSaveAdditionalNamedInsured, deleteAdditionalNamedInsured } from '../../../../actions/InsuredActions';

type ownPropTypes = {
    modalData: Record<string, any>;
    onClose: (x?: boolean) => any;
};

type onRequestFinishedType = (success: boolean, errorData?: Record<string, any>) => void;

type propTypes = {
    onSaveNamedInsured: (stateAni: any, onRequestFinished: onRequestFinishedType) => any;
    onDeleteNamedInsured: (stateAniId: number, onRequestFinished: onRequestFinishedType) => any;
} & ownPropTypes;

const mapStateToProps = (_state: Record<string, any>, _ownProps: ownPropTypes) => {
    return {};
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    return {
        onSaveNamedInsured: (ani, callback) => {
            dispatch(addOrSaveAdditionalNamedInsured(ani, ownProps.modalData.submissionId, callback));
        },
        onDeleteNamedInsured: (aniId, callback) => {
            dispatch(deleteAdditionalNamedInsured(aniId, ownProps.modalData.submissionId, callback));
        },
    };
};

class AdditionalNamedInsuredsContainer extends React.Component<propTypes> {
    render() {
        return <AdditionalNamedInsuredsApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(AdditionalNamedInsuredsContainer);
export default connected;
