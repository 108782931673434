import * as types from '../constants/ActionTypes';
import u from 'updeep';
import { LOADING } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { eq } from '@archinsurance-viki/property-jslib/src/utils/updeep-helpers';
import { AnyAction } from '@reduxjs/toolkit';

let initialState = {
    map: {},
};

type SubmissionDocumentsState = {
    map: Record<string, number[] | typeof LOADING>;
};

// TODO: Replace with pure RTK Query calls
export default function (state: SubmissionDocumentsState = initialState, action: AnyAction): SubmissionDocumentsState {
    switch (action.type) {
        case types.ADD_SUBMISSION_DOCUMENT: {
            let { id, documentId } = action;
            let addDocument = documents => {
                return [].concat(documents || [], documentId);
            };
            return u(
                {
                    map: {
                        [id]: addDocument,
                    },
                },
                state
            ) as any;
        }

        case types.DELETE_SUBMISSION_DOCUMENT: {
            let { id, documentId } = action;
            return u(
                {
                    map: {
                        [id]: u.reject(eq(documentId)),
                    },
                },
                state
            ) as any;
        }

        case types.RECEIVE_SUBMISSION_DOCUMENT_IDS: {
            let { data, id } = action;
            return u(
                {
                    map: {
                        [id]: u.constant(data),
                    },
                },
                state
            ) as any;
        }

        case types.UPDATE_SUBMISSION_DOCUMENTS: {
            let { id, data } = action;
            return u(
                {
                    map: {
                        [id]: data,
                    },
                },
                state
            ) as any;
        }

        case types.LOADING_SUBMISSION_DOCUMENTS: {
            let { id } = action;
            return u(
                {
                    map: {
                        [id]: LOADING,
                    },
                },
                state
            ) as any;
        }

        default:
            return state;
    }
}
