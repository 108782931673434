//import _ from 'lodash';
import u from 'updeep';
import * as types from '../constants/ActionTypes';

const initialState = {
    submissionOutcome: {
        _errors: {},
    },
};

// TODO: Replace with pure RTK Query calls
function submissionReducer(state: any = initialState, action: any) {
    switch (action.type) {
        case types.SET_SUBMISSION_OUTCOME:
            return u(
                {
                    submissionOutcome: {
                        [action.field]: action.value,
                        _errors: { [action.field]: action.error },
                    },
                },
                state
            );

        case types.SET_SUBMISSION_OUTCOME_ERROR:
            return u({ submissionOutcome: { _errors: action.errors } }, state);

        case types.CLEAR_SUBMISSION_OUTCOME:
            return u({ submissionOutcome: () => ({}) }, state);

        default:
            return state;
    }
}

export default submissionReducer;
