import * as React from 'react';
import { connect } from 'react-redux';

import { PagedTableDataType, RowSelectedFnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { SubmissionDataType, BuildingDataType } from '../../../../ts-types/DataTypes';
import BuildingsBottomPanelApp from '../apps/BuildingsBottomPanelApp';
import { fetchAdditionalStructures } from '../../../../actions/BuildingActions';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { PAGED_TABLES } from '../../../../constants/PagedTableConfigs';
import { persistRowData_server } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { Types } from '../../../../ts-types/viki-types';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import { ADD_ADDITIONAL_STRUCTURES_COLUMNS } from '../../../../constants/BuildingConstants';
import { AppDispatch } from '../../../../store';

type ownPropTypes = {
    tableData: PagedTableDataType;
    ENV: Types.Env;
    CONSTANTS: Types.Constants;
    readonly: boolean;
    currentSubmission: SubmissionDataType;
    currentSubmissionId: number;
    currentBuilding: BuildingDataType;
    currentQuote: Record<string, any>;
    selectedRowIndex: number;
    onSaveFn: (...params: any) => any;
    onRowSelected: RowSelectedFnType;
};

type propTypes = {
    fetchAdditionalStructures: (building_id: number, submission_id: number) => void;
    onAddOrEditAdditionalStructure: (structure: Record<string, any>, data: Record<string, any>) => void;
} & ownPropTypes;

const mapStateToProps = (_state: Record<string, any>, _ownProps: ownPropTypes) => {
    return {};
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: ownPropTypes) => {
    let { currentSubmissionId } = ownProps;
    return {
        onPersistTableRows: persistData => {
            const apiParams = {
                submission_id: currentSubmissionId,
            };
            const apiParamsKey = getApiParamsKey(apiParams);
            dispatch(persistRowData_server({ ...PAGED_TABLES.BUILDING_GRID, apiParams, apiParamsKey }, persistData));
        },
        fetchAdditionalStructures: (buildingId: number, submissionId: number) => {
            const apiParams = {
                submission_id: currentSubmissionId,
            };
            const apiParamsKey = getApiParamsKey(apiParams);
            return dispatch(fetchAdditionalStructures({ ...PAGED_TABLES.BUILDING_GRID, apiParams, apiParamsKey }, buildingId, submissionId));
        },
        onAddOrEditAdditionalStructure: (structure: Record<string, any>, data: Record<string, any>) => {
            return dispatch(
                openCenteredModal(
                    {
                        modalData: {
                            additionalStructure: structure,
                            glossaryData: data.glossary,
                            rows: ADD_ADDITIONAL_STRUCTURES_COLUMNS,
                            submissionId: ownProps.currentSubmissionId,
                            buildingId: ownProps.currentBuilding.id,
                            readOnly: data.readonly,
                        },
                    },
                    VIRA_MODAL_TYPES.ADDITIONAL_STRUCTURES
                )
            );
        },
    };
};

class BuildingsBottomPanelContainer extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);

        let cB = props.currentBuilding;
        if (cB && cB.id && !cB.additional_structures) {
            props.fetchAdditionalStructures(cB.id, props.currentSubmissionId);
        }
    }

    componentDidUpdate(prevProps: propTypes) {
        let cB = prevProps.currentBuilding;
        let nB = this.props.currentBuilding;
        if (nB && nB.id && (!cB || cB.id !== nB.id) && !nB.additional_structures) {
            prevProps.fetchAdditionalStructures(nB.id, this.props.currentSubmissionId);
        }
    }

    render() {
        return <BuildingsBottomPanelApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BuildingsBottomPanelContainer) as any;
export default connected;
