import { useGetBuildingPremiumQuery } from '../../services/apiSlice';
import useQuoteId from '../../hooks/quotes';
import { BuildingPremium } from '../../ts-types/ApiTypes';

const BuildingRow = (props: BuildingPremium) => {
    return (
        <>
            <label></label>
            <div>{props.description}</div>
            <div>xxx</div>
            <div>xxx</div>
            <div>xxx</div>
            <div>xxx</div>
            <div>xxx</div>
            <div>xxx</div>
        </>
    );
};

const BuildingsPanel = (props: any) => {
    const quoteId = useQuoteId();
    const { data, isLoading } = useGetBuildingPremiumQuery({ quoteId: +quoteId }, { skip: !quoteId });

    return (
        <>
            {/*<div className="header">Building-Level Premiums</div>*/}

            <div className="grid-layout gl-8 no-gap padding-light">
                <label className="sub-header border-bottom">Description</label>
                <div className="sub-header border-bottom">Address</div>
                <div className="sub-header border-bottom">Construction</div>
                <div className="sub-header border-bottom">Occupancy</div>
                <div className="sub-header border-bottom">TIV</div>
                <div className="sub-header border-bottom">AOP</div>
                <div className="sub-header border-bottom">CAT</div>
                <div className="sub-header border-bottom">Total</div>

                <If condition={isLoading}>
                    <label>Loading ...</label>
                </If>
                <If condition={!isLoading}>
                    {data.results.map((b: BuildingPremium, idx: number) => {
                        return <BuildingRow key={idx} {...b} />;
                    })}
                </If>
            </div>
        </>
    );
};

export default BuildingsPanel;
