import classNames from 'classnames';

import PolicyFormsApp from './PolicyFormsApp';
import ActionPanel from '@archinsurance-viki/property-jslib/src/components/panels/ActionPanel';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../../components/submissionlog/Helpers';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

const OVERVIEW_ACTIONS_PANEL = 'OVERVIEW_ACTIONS';

export default function PolicyFormsPageContainer() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const uiState = useAppSelector(state => state.uiState);

    return (
        <>
            <div className="main-content-center">
                <AccountTasksPanel />
                <div className="tw-pt-0 ventus-content flex-table even-odd quote-pipeline">
                    <PolicyFormsApp />
                </div>
            </div>
            <div
                className={classNames('main-right-panel main-panel', {
                    closed: !uiState.openPanels[OVERVIEW_ACTIONS_PANEL],
                })}
            >
                <ActionPanel
                    uiState={uiState}
                    panelActions={calculateRowActions({ appContext, appContextFns }, ACTION_PANEL_LOCATIONS.OVERVIEW)}
                    panelName={OVERVIEW_ACTIONS_PANEL}
                    openPanels={uiState.openPanels}
                    tableConfig={PAGED_TABLES.SUBMISSION_LOG}
                />
            </div>
        </>
    );
}
