import * as React from 'react';

import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import DefaultModal, { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    modalData: Record<string, any>;
};

class CuoApprovalBody extends React.Component<bodyPropTypes> {
    render() {
        let { approval_descriptions, message } = this.props.modalData;

        if (!approval_descriptions.length) {
            return <div className="standard-modal-content">{message}</div>;
        }

        const listItems = approval_descriptions.map((approval_description, idx) => <li key={idx}>{approval_description}</li>);

        return (
            <div className="standard-modal-content">
                You have approved the following requests:
                <ul>{listItems}</ul>
            </div>
        );
    }
}

let modalTypeObj = {
    Body: CuoApprovalBody,
    Header: DefaultHeaderStatic('CUO Approval'),
    Footer: DefaultModal.Footer,
};
registerType(VIRA_MODAL_TYPES.CUO_APPROVAL, modalTypeObj);
export default modalTypeObj;
