import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';

import { prepareChoices } from '@archinsurance-viki/property-jslib/src/utils/choices-helpers';
import {
    ACCOUNT_PURPOSES,
    TRANSACTION_PULLDOWN_STATES,
    TRANSACTION_STATUS,
    TRANSACTION_STATUS_MAP,
} from '@archinsurance-viki/property-jslib/src/constants/Constants';
import SelectComponent from '@archinsurance-viki/property-jslib/src/components/select/SelectComponent';
import { formatDateString, trucateStringIfNeeded } from '@archinsurance-viki/property-jslib/src/utils/converters';
import { NO_ACCOUNT, SUBMISSION_SHORTCUT } from '../../reducers/accounts';
import { isInReviewReinstatementOrReversion } from '../../utils/overview-helpers';
import { TRANSACTION_TYPES } from '../../constants/TransactionConstants';
import { getCommunicationStatus } from '@archinsurance-viki/property-jslib/src/utils/arch-helpers';
import { CLEARANCE_URL } from '../../utils/setup-endpoints';
import { getSelectedIdFromQuerystring } from '../../utils/state-helpers';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';
import { getArchLinkSubmissionUrl, getArchPWSubmissionUrl, getArchSubmissionId } from '../../utils/navurl-helpers';
import { selectFeatureFlags } from '../../selectors/environment';

type propTypes = {
    noCurrentSubmission?: boolean;
    transactionPulldown?: string;
};

const MAX_PULLDOWN_DESCRIPTION_LENGTH = 20;

const overviewURL = (submissionid?: string, policyNumber?: string) => {
    if (policyNumber !== null) {
        return `/submissions/${submissionid}/${submissionid}/overview`;
    }
    return `/submissions/${submissionid}/current/overview`;
};

export default function SubmissionInfo(props: propTypes) {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const match = useRouteMatch<{ submissionId?: string }>();
    const ENV = useAppSelector(state => state.global.ENV);
    const [urlSubmissionId, setUrlSubmissionId] = useState<number>(null);
    const [submissionFromUrl, setSubmissionFromUrl] = useState<number | string>(null);
    const me = useAppSelector(state => state.global.me);
    const featureFlags = useAppSelector(state => selectFeatureFlags(state));

    /* TODO: implement auto-refresh for client actions and pop up refresh for other clients
    const invalidateSubmissionCache = () => dispatch(api.util.invalidateTags([{ type: 'Submission', id: currentSubmissionId }]));

    usePusherEvent(`submission-${currentSubmissionId}`, 'is-dirty', (messagePayload, { socketId }) => {
        const submitterSocketId = (messagePayload as Record<string, unknown>)?.submitter_pusher_socket_id;
        if (submitterSocketId && submitterSocketId === socketId) {
            invalidateSubmissionCache();
        } else if (submitterSocketId) {
            setShowSubmissionRefresh(true);
        }
    });
    */

    useEffect(() => {
        let { currentAccount, currentAccountId, currentSubmission } = appContext;

        let urlSubmissionId = null;
        if (match && !props.noCurrentSubmission) {
            urlSubmissionId = match.params.submissionId || getSelectedIdFromQuerystring();

            let submissionIdToSet = null;
            if (isFinite(urlSubmissionId)) {
                // is number
                urlSubmissionId = Number(urlSubmissionId);
                if (currentAccountId && currentAccount.id !== NO_ACCOUNT) {
                    if (urlSubmissionId === currentAccount.latest_submission_id) {
                        // or most_recent_committed_submission_id?
                        submissionIdToSet = SUBMISSION_SHORTCUT.CURRENT;
                    } else if (urlSubmissionId === currentAccount.latest_initial_submission_id) {
                        submissionIdToSet = SUBMISSION_SHORTCUT.INITIAL;
                    } else if (urlSubmissionId === currentAccount.original_submission_id) {
                        submissionIdToSet = SUBMISSION_SHORTCUT.ORIGINAL;
                    }
                }
            } else if (!urlSubmissionId) {
                if (currentAccountId) {
                    submissionIdToSet = SUBMISSION_SHORTCUT.CURRENT;
                } else if (currentAccount.id === NO_ACCOUNT && currentSubmission && currentSubmission.id) {
                    submissionIdToSet = SUBMISSION_SHORTCUT.CURRENT;
                    currentAccountId = currentSubmission.id;
                }
            } else if (!Object.values(SUBMISSION_SHORTCUT).includes(urlSubmissionId)) {
                submissionIdToSet = SUBMISSION_SHORTCUT.CURRENT;
            }

            if (submissionIdToSet && submissionIdToSet !== submissionFromUrl) {
                console.info([submissionIdToSet, urlSubmissionId]);
                setSubmissionFromUrl(submissionIdToSet);
                appContextFns.onSetCurrentSubmission(currentAccountId, submissionIdToSet, true);
            }

            if (currentAccount.id === NO_ACCOUNT && currentSubmission && currentSubmission.account_id && currentSubmission.account_id !== currentAccountId) {
                console.log('Went to url with a submission id as account id', currentSubmission.account_id, currentAccountId);
                appContextFns.onSetCurrentSubmission(currentSubmission.account_id, currentSubmission.id, true);
            }
        }
        setUrlSubmissionId(urlSubmissionId);
    }, [appContext, match, props.noCurrentSubmission, appContextFns, submissionFromUrl]);

    const _getTransactionPulldownChoices = (): [string, string][] => {
        const { transactions, currentAccount } = appContext;
        const { transactionPulldown } = props;
        const c: [string, string][] = [];

        if (currentAccount.id === NO_ACCOUNT) {
            console.error('Can not get transaction pulldowns if no account');
            return c;
        }

        if (transactionPulldown === TRANSACTION_PULLDOWN_STATES.ACCOUNT_CORRECTIONS) {
            let originalSubmissionId = currentAccount.original_submission_id;
            let initialPolicyIsCorrected = false;
            let openCorrections = [];
            for (let transaction of transactions) {
                if (transaction.submission?.account_purpose === ACCOUNT_PURPOSES.FIRST_DAY_ENDORSEMENT) {
                    if (transaction.status === TRANSACTION_STATUS.BOUND) {
                        originalSubmissionId = transaction.submission_id;
                        initialPolicyIsCorrected = true;
                    } else {
                        openCorrections.push(transaction);
                    }
                }
            }
            c.push([SUBMISSION_SHORTCUT.INITIAL, `Original Submission (${originalSubmissionId})${initialPolicyIsCorrected ? ' [Corrected]' : ''}`]);
            for (let oct of openCorrections) {
                c.push([
                    oct.submission_id,
                    `${TRANSACTION_STATUS_MAP[oct.status].value} Account Correction (${oct.submission_id}): ${trucateStringIfNeeded(
                        oct.endorsement_description,
                        MAX_PULLDOWN_DESCRIPTION_LENGTH
                    )}`,
                ]);
            }
        } else {
            // policy as bound
            c.push([SUBMISSION_SHORTCUT.CURRENT, `Policy As Bound (${currentAccount.latest_submission_id})`]);

            // policy in review
            let inReviewTrans = transactions.find(t => t.status === TRANSACTION_STATUS.IN_REVIEW);

            if (inReviewTrans) {
                // VIKI-7198: the correct submission to render for unbound in-review txns is the last_interval_submission
                let inReviewSID = isInReviewReinstatementOrReversion(inReviewTrans) ? inReviewTrans.last_interval_submission_id : inReviewTrans.submission_id;

                c.push([
                    `${inReviewSID}`,
                    `Policy In Review (${inReviewSID}): ${trucateStringIfNeeded(
                        inReviewTrans.endorsement_description,
                        MAX_PULLDOWN_DESCRIPTION_LENGTH
                    )} - Effective ${formatDateString(inReviewTrans.effective_date)}`,
                ]);
            }

            for (let transaction of transactions) {
                if (transaction.status === TRANSACTION_STATUS.OPEN) {
                    c.push([
                        `${transaction.submission_id}`,
                        `Open Endorsement (${transaction.submission_id}): ${trucateStringIfNeeded(
                            transaction.endorsement_description,
                            MAX_PULLDOWN_DESCRIPTION_LENGTH
                        )} - Effective ${formatDateString(transaction.effective_date)}`,
                    ]);
                }
            }
        }

        return c;
    };

    const _getQuotesPullDownChoices = (quoteIds: number[], quotesMap: Record<string, any>): [string, string][] => {
        const c: [string, string][] = [];
        for (let quoteId of quoteIds) {
            const quote = quotesMap[quoteId];
            if (quote) {
                c.push([quote.id, `Quote (${quote.id}): ${trucateStringIfNeeded(quote.description, MAX_PULLDOWN_DESCRIPTION_LENGTH)}`]);
            }
        }
        return c;
    };

    const renderPolicyLink = (policyId?: string, policyNumber?: string, type?: string): React.ReactNode => {
        if (!policyId) {
            return null;
        }

        let url = overviewURL(policyId, policyNumber);

        return (
            <React.Fragment>
                <div className="info-figure policy-figure">
                    <span className="info-label">{type} :</span>
                    <span className="info-data">
                        <a href={url}>{policyId}</a>&nbsp;-&nbsp;
                        <a href={url}>{policyNumber || 'NOT BOUND'}</a>
                    </span>
                </div>
            </React.Fragment>
        );
    };

    const onSelectTransaction = (submissionId: number | 'current'): void => {
        if (!appContextFns) {
            console.warn('No app context Fns.');
            return;
        }
        appContextFns.onNavigate({ submissionId });
    };

    const onSelectQuote = (quoteId: number): void => {
        if (!appContextFns) {
            console.warn('No app context Fns.');
            return;
        }
        appContextFns.onSetCurrentQuote(quoteId);
    };

    const createPicker = (c: [string, string][], label: string, selectedId: number, onSelected: (...params: any[]) => any): Record<string, any> => {
        if (c.length === 0) {
            return null;
        }
        const { choices, selectedIndex } = prepareChoices({ choices: c, noSort: true }, undefined, selectedId, false);
        return {
            content: <SelectComponent onSelected={onSelected} choices={choices as [string, string][]} selectedIndex={selectedIndex} />,
            label,
        };
    };

    const renderTransactionPicker = (): Record<string, any> | null => {
        const { transactions, currentAccount } = appContext;
        const { transactionPulldown } = props;

        if (transactionPulldown !== TRANSACTION_PULLDOWN_STATES.HIDDEN && transactions && transactions.length > 1 && currentAccount) {
            const c = _getTransactionPulldownChoices();
            return createPicker(c, 'ENDORSEMENT:', urlSubmissionId || -1, onSelectTransaction);
        }
        return null;
    };

    const renderCoverageOptionPicker = (): Record<string, any> | null => {
        const { urlQuoteId, currentSubmission, quotesMap } = appContext;
        if (urlQuoteId && currentSubmission && currentSubmission.quotes && currentSubmission.quotes.length) {
            const c = _getQuotesPullDownChoices(currentSubmission.quotes, quotesMap);
            return createPicker(c, 'COVERAGE OPTION:', urlQuoteId, onSelectQuote);
        }
        return null;
    };

    const { currentSubmission, currentSubmissionId, originalSubmissionId, currentAccountId, currentAccount, currentTransaction } = appContext;

    if (!currentSubmission || !currentSubmission.id) {
        return (
            <div className="submission-info">
                <div className="status-area main-label">Submissions</div>
            </div>
        );
    }

    const {
        policy_number,
        insured_name,
        renewal_of_id,
        renewed_by_id,
        renewal_of_policy_policy_number,
        renewed_by_policy_number,
        rewrite_of_id,
        rewritten_by_id,
        rewrite_of_policy_number,
        rewritten_by_policy_number,
        renewal_of_arch_sid,
        renewal_of_arch_policy_number,
        policy_inception_date,
        policy_expiration_date,
        target_effective_date,
    } = isInReviewReinstatementOrReversion(currentTransaction) ? currentTransaction.last_interval_submission_obj : currentSubmission;

    const id = currentAccountId || currentSubmission.account_id || currentSubmissionId;

    const picker = renderTransactionPicker() || renderCoverageOptionPicker();

    const isOriginalBusiness =
        currentTransaction &&
        (currentTransaction.transaction_type === TRANSACTION_TYPES.NEW_BUSINESS || currentTransaction.transaction_type === TRANSACTION_TYPES.RENEWAL_BUSINESS);
    let isBound: boolean, statusValue: React.ReactNode;

    if (!isOriginalBusiness && currentTransaction) {
        const PolicyTransactionTypeDescriptions = {
            [TRANSACTION_TYPES.NEW_BUSINESS]: 'New Business',
            [TRANSACTION_TYPES.ENDORSEMENT]: 'Endorsement',
            [TRANSACTION_TYPES.FLAT_CANCELLATION]: 'Flat Cancellation',
            [TRANSACTION_TYPES.RENEWAL_BUSINESS]: 'Renewal Business',
            RET: 'Reinstatement',
            REV: 'Reversion',
            [TRANSACTION_TYPES.SHORT_RATE_CANCELLATION]: 'Short Rate Cancellation',
            [TRANSACTION_TYPES.NON_FLAT_RATE_CANCELLATION]: 'Non-Flat Rate Cancellation',
            [TRANSACTION_TYPES.NEW_BUSINESS_REWRITE]: 'New Business Rewrite',
            [TRANSACTION_TYPES.RENEWAL_BUSINESS_REWRITE]: 'Renewal Business Rewrite',
            [TRANSACTION_TYPES.NEW_BINDER_REVISION]: 'New Binder Revision',
            [TRANSACTION_TYPES.RENEWAL_BINDER_REVISION]: 'Renewal Binder Revision',
        };

        isBound = currentTransaction.status === TRANSACTION_STATUS.BOUND;
        let transactionTypeDescription = PolicyTransactionTypeDescriptions[currentTransaction.transaction_type] || currentTransaction.transaction_type;
        statusValue = [
            <span className="endorsement-label" key="type">
                {transactionTypeDescription}/
            </span>,
            <span key="status">
                {currentTransaction.accounting_date || currentTransaction.provisional_accounting_date
                    ? 'BOOKED'
                    : TRANSACTION_STATUS_MAP[currentTransaction.status].value}
            </span>,
        ];
    } else {
        isBound = currentSubmission.is_bound;
        statusValue = currentSubmission.status_display;
        if (statusValue === 'Bound') {
            statusValue += '/Booked';
        } else if (isBound && currentSubmissionId === originalSubmissionId) {
            if (currentTransaction && currentTransaction.provisional_accounting_date) {
                statusValue += '/Booked';
            } else {
                if (currentSubmission.is_editable) {
                    statusValue += '/Unlocked';
                } else {
                    statusValue += '/Unbooked';
                }
            }
        }
    }

    const statusClasses = classNames('status-badge', { bound: isBound });
    const {
        clearance_submission_id,
        archlink_communication_statuses,
        arch_policywriter_mc_id,
        arch_policywriter_ct_id,
        arch_policywriter_version_no,
        arch_submission_id,
    } = currentSubmission;
    const isCancelled = !!currentAccount.cancellation_date;
    let pendingBorDisplay = '';
    if (currentSubmission.pending_bor_status && currentSubmission?.bor_incumbent_submission_id?.length > 0) {
        const isPendingBOR = currentSubmission.pending_bor_status === 'P';
        if (
            (currentSubmission.bor_incumbent_submission_id[0] === 'V' &&
                String(currentSubmission.id) === currentSubmission.bor_incumbent_submission_id.substring(1)) ||
            currentSubmission.bor_incumbent_submission_id === String(currentSubmission.clearance_submission_id)
        ) {
            pendingBorDisplay = ' Incumbent';
        }

        if (isPendingBOR) {
            pendingBorDisplay += ' - Pending BOR';
        } else if (currentSubmission.pending_bor_status === 'L') {
            pendingBorDisplay += ' - BOR Received';
        } else {
            pendingBorDisplay = '';
        }
    }

    return (
        <div className="submission-info">
            <div className="submission-details">
                {!!statusValue && (
                    <div className={statusClasses}>
                        {statusValue}
                        {pendingBorDisplay}
                    </div>
                )}
                {isCancelled && <div className="status-badge red">CANCELLED {currentAccount.cancellation_date}</div>}
                <div className="submission-id-details">
                    <div className="info-figure">
                        <span className="info-label">ID :</span>
                        <span className="info-data">{id}</span>
                    </div>
                    {policy_number && (
                        <div className="info-figure">
                            <span className="info-label">POLICY :</span>
                            <span className="info-data">{policy_number}</span>
                        </div>
                    )}
                    {clearance_submission_id && (
                        <div className="info-figure">
                            <span className="info-label">CLEARANCE ID:</span>
                            <span className="info-data">
                                <a
                                    target="_blank"
                                    href={`${CLEARANCE_URL}/dashboard/${clearance_submission_id}?search=${clearance_submission_id}&tab=all&inbox=-1`}
                                >
                                    {clearance_submission_id}
                                </a>
                            </span>
                        </div>
                    )}
                    {ENV?.SITE?.id === 5 && archlink_communication_statuses && archlink_communication_statuses.length !== 0 && (
                        <div className="info-figure">
                            <span className="info-label">ARCH STATUS:</span>
                            <span className="info-data">{getCommunicationStatus(archlink_communication_statuses)}</span>
                        </div>
                    )}
                    {picker && (
                        <div className="info-figure viki-select">
                            <span className="info-label">{picker && picker.label}</span>
                            <span className="info-data">{picker && picker.content}</span>
                        </div>
                    )}
                    {renderPolicyLink(renewal_of_id, renewal_of_policy_policy_number, 'Prev')}
                    {renderPolicyLink(renewed_by_id, renewed_by_policy_number, 'Renewal')}
                    {renderPolicyLink(rewrite_of_id, rewrite_of_policy_number, 'Rewrite Of')}
                    {renderPolicyLink(rewritten_by_id, rewritten_by_policy_number, 'Rewritten By')}
                    {ENV?.TENANT_SETTINGS?.features?.archlink && renewal_of_arch_sid && (
                        <div className="info-figure policy-figure">
                            <span className="info-label">PREV :</span>
                            <span className="info-data">
                                <a href={getArchLinkSubmissionUrl(ENV.ENV, currentSubmission.renewal_of_arch_sid)} target={'_blank'}>
                                    {renewal_of_arch_sid}&nbsp;-&nbsp;{renewal_of_arch_policy_number}
                                </a>
                            </span>
                        </div>
                    )}
                    {isOriginalBusiness && !isBound && target_effective_date && (!policy_inception_date || !policy_expiration_date) && (
                        <div className="info-figure">
                            <span className="info-label">TGT EFF :</span>
                            <span className="info-data">{formatDateString(target_effective_date)}</span>
                        </div>
                    )}
                    {policy_inception_date && policy_expiration_date && (
                        <div className="info-figure">
                            <span className="info-label">EFFECTIVE :</span>
                            <span className="info-data">
                                {formatDateString(policy_inception_date)}&nbsp;-&nbsp;
                                {formatDateString(policy_expiration_date)}
                            </span>
                        </div>
                    )}
                    <div className="info-figure">
                        <span className="info-label">UW:</span>
                        <span className="info-data">
                            {featureFlags.enable_uw_list_ff && !currentSubmission.underwriter_full_name
                                ? currentSubmission.arch_underwriter_full_name
                                : currentSubmission.underwriter_full_name}
                        </span>
                    </div>
                    {ENV?.TENANT_SETTINGS?.features?.pw_submission && arch_policywriter_mc_id && arch_submission_id && (
                        <div className="info-figure policy-figure">
                            <span className="info-label">PW Submission :</span>
                            <a
                                href={getArchPWSubmissionUrl(
                                    ENV.ENV,
                                    arch_policywriter_mc_id,
                                    me.username,
                                    arch_policywriter_ct_id,
                                    arch_policywriter_version_no
                                )}
                                target={'_blank'}
                            >
                                {getArchSubmissionId(arch_submission_id)}
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="tw-flex">
                <div className="main-label ellipsize tw-flex-1">
                    {insured_name}
                    {currentSubmission.is_insured_flag && (
                        <>
                            <i className="material-icons red-flag" title={currentSubmission.insured_flag_reason}>
                                flag
                            </i>
                            <span className="info-data red">Insured Flagged</span>
                        </>
                    )}
                </div>
                {/* TODO
                showSubmissionRefresh && (
                    <div className="tw-flex tw-items-start tw-text-[13px]">
                        <p>The submission has been updated.</p>
                        <BasicButton
                            className="tw-p-1"
                            onClick={() => {
                                invalidateSubmissionCache();
                                setShowSubmissionRefresh(false);
                            }}
                        >
                            Refresh Data
                        </BasicButton>
                    </div>
                )*/}
            </div>
        </div>
    );
}
