import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ComboBoxInput, TextInput, MaskedInput, NumericInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { useUpdateTargetMultipleRuleMutation, useDeleteTargetMultipleRuleMutation } from '../../services/apiSlice';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';

type TargetMultipleProps = {
    id: number;
    description: string;
    county?: string;
    multiplier: number;
    state?: string;
    zipcode?: number;
    zone: number;
    county_code?: string;
    filterType?: string;
    is_active: boolean;
    disableChange: boolean;
    onDelete: (id?: number) => void;
    onAddOrUpdate: (data: Record<string, any>) => void;
};

const FILTER_TYPES = [
    ['zipcode', 'Zip Code'],
    ['state', 'State'],
    ['county_code', 'County'],
    ['zone', 'Region'],
];

const TargetMultipleRow = (props: TargetMultipleProps) => {
    const [edited, setEdited] = useState(false);
    const [state, setState] = useState({
        is_active: true,
        filter_type: null,
        description: null,
        state: null,
        zipcode: null,
        county: null,
        multiplier: null,
        zone: null,
    });
    const [updateTargetRule /*{ error, isLoading: isOverriding }*/] = useUpdateTargetMultipleRuleMutation();
    const [deleteTargetMultiple] = useDeleteTargetMultipleRuleMutation();
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const dispatch = useAppDispatch();
    const { id, county_code, zipcode, state: _state, multiplier, county, onDelete, description, onAddOrUpdate, filterType, is_active, zone } = props;

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            description,
            county,
            multiplier,
            zipcode,
            county_code,
            is_active,
            id,
            filter_type: filterType,
            state: _state,
            zone,
        }));
    }, [id, is_active, filterType, zipcode, description, county_code, _state, county, multiplier, zone]);

    const popAlertModal = (title: string, onOk: () => void, confirmLabelTitle) => {
        dispatch(
            openMessageModal(
                {
                    title,
                    disableModalResizable: true,
                    modalData: {
                        confirmLabelTitle,
                        dismissButtonTitle: 'Cancel',
                        onOk: () => {
                            onOk();
                        },
                    },
                },
                CENTERED_MODAL_TYPES.CONFIRM
            )
        );
    };

    const canSave = !!state.description && !!state.multiplier && edited;
    const handleChange = (name, value) => {
        if (props.disableChange) {
            return null;
        }
        let overrides = {};
        switch (name) {
            case 'county_code':
                overrides['zipcode'] = null;
                overrides['state'] = null;
                break;
            case 'state':
                overrides['county_code'] = null;
                overrides['zipcode'] = null;
                overrides['county'] = null;
                break;
            case 'zipcode':
                overrides['county_code'] = null;
                overrides['state'] = null;
                overrides['county'] = null;
                break;
            case 'zone':
                overrides['county_code'] = null;
                overrides['state'] = null;
                overrides['county'] = null;
                overrides['zipcode'] = null;
                break;
        }
        setEdited(true);
        setState(prevState => ({
            ...prevState,
            ...overrides,
            [name]: value,
        }));
    };

    return (
        <>
            <a
                onClick={() => {
                    popAlertModal(
                        `Are you sure you want to ${state.is_active ? 'deactivate' : 'activate'}`,
                        () => {
                            updateTargetRule({ is_active: !state.is_active, ...state }).then(() => {
                                handleChange('is_active', !state.is_active);
                            });
                        },
                        state.is_active ? 'Deactivate' : 'Activate'
                    );
                }}
                className={classNames('select-cell material-icons', { active: state.is_active })}
                title={state.is_active ? 'Active' : 'Inactive'}
            >
                <span className="material-icons">done</span>
            </a>

            <TextInput name="description" noWrapper={true} label={null} object={state} onChange={handleChange} />

            <ComboBoxInput
                className="grid-layout sub-grid"
                name="filter_type"
                defaultValue={filterType}
                noWrapper={true}
                label={null}
                object={state}
                onChange={handleChange}
                selectProps={{ choices: FILTER_TYPES }}
            />

            <If condition={!state.filter_type}>
                <div>Select filter type</div>
            </If>

            <If condition={state.filter_type === 'zone'}>
                <ComboBoxInput
                    className="grid-layout sub-grid"
                    name="zone"
                    noWrapper={true}
                    label={null}
                    object={state}
                    onChange={handleChange}
                    selectProps={{ choices: CONSTANTS.PRICING_ZONES.map(s => [s[0], s[1]]) }}
                />
            </If>

            <If condition={state.filter_type === 'state'}>
                <ComboBoxInput
                    className="grid-layout sub-grid"
                    name="state"
                    noWrapper={true}
                    label={null}
                    object={state}
                    onChange={handleChange}
                    selectProps={{ choices: CONSTANTS.states.map(s => [s.value, s.display]) }}
                />
            </If>

            <If condition={state.filter_type === 'zipcode'}>
                <MaskedInput noWrapper={true} label={null} format={{ mask: 'ZIPCODE' }} name="zipcode" onChange={handleChange} object={state} />
            </If>

            <If condition={state.filter_type === 'county_code'}>
                <ComboBoxInput
                    className="grid-layout sub-grid"
                    name="county_code"
                    noWrapper={true}
                    label={null}
                    object={state}
                    onChange={handleChange}
                    selectProps={{ choices: CONSTANTS.COUNTIES.map(s => [s[0], s[1]]) }}
                />
            </If>

            <NumericInput format={{ subtype: 'number', decimals: 2 }} name="multiplier" noWrapper={true} label={null} object={state} onChange={handleChange} />

            <div className={classNames('flex padding-none no-border', { disabled: props.disableChange })}>
                <a
                    className={classNames('action padding-light', { 'green-txt': canSave, 'grey-txt disabled': !canSave })}
                    title="Save"
                    onClick={() => {
                        canSave && onAddOrUpdate(state);
                        setEdited(false);
                    }}
                >
                    <span className="material-icons">save</span>
                </a>
                <a
                    className={classNames('action red-txt padding-light', { disabled: props.disableChange })}
                    title="Delete"
                    onClick={() => {
                        popAlertModal(
                            `Are you sure you want to delete ${description || ' this unsaved target multiple'}?`,
                            () => {
                                deleteTargetMultiple({ id }).then(() => {
                                    onDelete(id);
                                });
                            },
                            'Delete'
                        );
                    }}
                >
                    <span className="material-icons">delete</span>
                </a>
            </div>
        </>
    );
};

export default TargetMultipleRow;
