import API from '@archinsurance-viki/property-jslib/src/utils/API';
import { AppDispatch } from '../store';

export const sendSubmissionToScrub = (submissionId: number) => {
    return (dispatch: AppDispatch) => {
        return API.doRequest(
            API.endpoints.submission.sendToDataEntry,
            {
                id: submissionId,
                showProgress: true,
                params: {
                    is_rush: true,
                },
            },
            dispatch
        );
    };
};
