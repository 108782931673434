import cx from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useGetPeeDataQuery } from '../../../services/apiSlice';
import { useQuotePolicyCoverage } from '../../../hooks/quotes';
import { ReadOnlyInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/ReadOnlyInput';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { PolicyTermsFieldName, PolicyTermsFormValues, peeOverrides, PolicyTermsFormField } from '../utils/form';
import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { PEECoverageType, PeeOverridesType } from '../../../ts-types/ApiTypes';

type PropertyEnhancementEndorsementSubField = {
    label: string;
    maximumKey: string;
    limitKey: PolicyTermsFieldName;
    isCurrencyField?: boolean;
    isPercentField?: boolean;
};

type PropertyEnhancementEndorsementField = {
    label: string;
    maximumKey?: string;
    limitKey?: PolicyTermsFieldName;
    isCurrencyField?: boolean;
    isPercentField?: boolean;
    subFields?: PropertyEnhancementEndorsementSubField[];
};

const BPP_PROPERTY_ENHANCEMENT_ENDORSEMENT_FIELDS: PropertyEnhancementEndorsementField[] = [
    { label: 'Accounts Receivable', maximumKey: 'accounts_receivable', limitKey: 'pee_override_accounts_receivable', isCurrencyField: true },
    { label: 'Asbestos Removal', maximumKey: 'asbestos_removal', limitKey: 'pee_override_asbestos_removal', isCurrencyField: true },
    { label: 'Builders Risk', maximumKey: 'builders_risks', limitKey: 'pee_override_builders_risks', isCurrencyField: true },
    {
        label: 'Debris Removal',
        subFields: [
            { label: '% of Loss', maximumKey: 'debris_removal_percent_loss', limitKey: 'pee_override_debris_removal_percent_loss', isPercentField: true },
            { label: 'Maximum', maximumKey: 'debris_removal_maximum', limitKey: 'pee_override_debris_removal_maximum', isCurrencyField: true },
            {
                label: 'Additional Limit',
                maximumKey: 'debris_removal_additional_limit',
                limitKey: 'pee_override_debris_removal_additional_limit',
                isCurrencyField: true,
            },
        ],
    },
    { label: 'Electronic Data and Media', maximumKey: 'electronic_data_and_media', limitKey: 'pee_override_electronic_data_and_media', isCurrencyField: true },
    { label: 'Emergency Removal', maximumKey: 'emergency_removal_expense', limitKey: 'pee_override_emergency_removal_expense', isCurrencyField: true },
    { label: 'Errors or Omissions', maximumKey: 'errors_or_omissions', limitKey: 'pee_override_errors_or_omissions', isCurrencyField: true },
    { label: 'Fine Arts', maximumKey: 'fine_arts', limitKey: 'pee_override_fine_arts', isCurrencyField: true },
    { label: 'Fire Department Charges', maximumKey: 'fire_brigade_charges', limitKey: 'pee_override_fire_brigade_charges', isCurrencyField: true },
    { label: 'Fraud and Deceit', maximumKey: 'fraud_and_deceit', limitKey: 'pee_override_fraud_and_deceit', isCurrencyField: true },
    {
        label: 'Fungus/Bacteria',
        subFields: [
            { label: 'Per Occurrence', maximumKey: 'fungus_per_occurrence', limitKey: 'pee_override_fungus_per_occurrence', isCurrencyField: true },
            { label: 'Aggregate', maximumKey: 'fungus_aggregate', limitKey: 'pee_override_fungus_aggregate', isCurrencyField: true },
        ],
    },
    { label: 'Green Upgrades', maximumKey: 'green_upgrades', limitKey: 'pee_override_green_upgrades', isCurrencyField: true },
    {
        label: 'Leased/Rented Equipment',
        subFields: [
            {
                label: 'Per Item Limit',
                maximumKey: 'leased_equipment_per_item_limit',
                limitKey: 'pee_override_leased_equipment_per_item_limit',
                isCurrencyField: true,
            },
            {
                label: 'Aggregate Limit',
                maximumKey: 'leased_equipment_aggregate_limit',
                limitKey: 'pee_override_leased_equipment_aggregate_limit',
                isCurrencyField: true,
            },
        ],
    },
    { label: 'Leasehold Interest', maximumKey: 'leasehold_interest', limitKey: 'pee_override_leasehold_interest', isCurrencyField: true },
    {
        label: 'Pollution - Annual Aggregate',
        maximumKey: 'limited_pollution_coverage',
        limitKey: 'pee_override_limited_pollution_coverage',
        isCurrencyField: true,
    },
    { label: 'Lock Replacement', maximumKey: 'lock_replacement', limitKey: 'pee_override_lock_replacement', isCurrencyField: true },
    { label: 'Unnamed Locations', maximumKey: 'misc_unnamed_locations', limitKey: 'pee_override_misc_unnamed_locations', isCurrencyField: true },
    {
        label: 'Newly Acquired Property',
        subFields: [
            { label: 'Days', maximumKey: 'newly_acquired_property_days', limitKey: 'pee_override_newly_acquired_property_days' },
            {
                label: 'Maximum Limit',
                maximumKey: 'newly_acquired_property_maximum',
                limitKey: 'pee_override_newly_acquired_property_maximum',
                isCurrencyField: true,
            },
        ],
    },
    {
        label: 'Outdoor Property',
        subFields: [
            { label: 'Per Item Limit', maximumKey: 'plants_per_item_limit', limitKey: 'pee_override_plants_per_item_limit', isCurrencyField: true },
            { label: 'Aggregate Limit', maximumKey: 'plants_aggregate_limit', limitKey: 'pee_override_plants_aggregate_limit', isCurrencyField: true },
        ],
    },
    {
        label: 'Personal Property of Others',
        maximumKey: 'personal_property_of_others',
        limitKey: 'pee_override_personal_property_of_others',
        isCurrencyField: true,
    },
    { label: 'Preservation of Property (Days)', maximumKey: 'preservation_of_property_days', limitKey: 'pee_override_preservation_of_property_days' },
    { label: 'Claim Prep Fees - Annual Aggregate', maximumKey: 'professional_fees', limitKey: 'pee_override_professional_fees', isCurrencyField: true },
    {
        label: 'Property Under Construction',
        maximumKey: 'property_in_course_of_construction',
        limitKey: 'pee_override_property_in_course_of_construction',
        isCurrencyField: true,
    },
    {
        label: 'Fire Extinguishing Equipment',
        maximumKey: 'recharging_fire_equipment',
        limitKey: 'pee_override_recharging_fire_equipment',
        isCurrencyField: true,
    },
    { label: 'Land Improvements', maximumKey: 'reclaiming_land_improvements', limitKey: 'pee_override_reclaiming_land_improvements', isCurrencyField: true },
    { label: 'Reward Reimbursement', maximumKey: 'reward_reimbursement', limitKey: 'pee_override_reward_reimbursement', isCurrencyField: true },
    {
        label: 'Service Interruption',
        subFields: [
            {
                label: 'Direct Damage Limit',
                maximumKey: 'service_interruption_direct_damage',
                limitKey: 'pee_override_service_interruption_direct_damage',
                isCurrencyField: true,
            },
            {
                label: 'Combined DD and TE Limit',
                maximumKey: 'service_interruption_combined',
                limitKey: 'pee_override_service_interruption_combined',
                isCurrencyField: true,
            },
        ],
    },
    { label: 'Sewer/Drain/Sump Overflow', maximumKey: 'sewer', limitKey: 'pee_override_sewer', isCurrencyField: true },
    { label: 'Transit', maximumKey: 'transit', limitKey: 'pee_override_transit', isCurrencyField: true },
    { label: 'Valuable Papers & Records', maximumKey: 'valuable_papers', limitKey: 'pee_override_valuable_papers', isCurrencyField: true },
    { label: 'Wind-Driven Precipitation', maximumKey: 'wind_driven_precipitation', limitKey: 'pee_override_wind_driven_precipitation', isCurrencyField: true },
];

const TIME_ELEMENT_PROPERTY_ENHANCEMENT_ENDORSEMENT_FIELDS: PropertyEnhancementEndorsementField[] = [
    { label: 'Builders Risks Soft Costs', maximumKey: 'builders_risks_soft_costs', limitKey: 'pee_override_builders_risks_soft_costs', isCurrencyField: true },
    {
        label: 'Civil Authority',
        subFields: [
            { label: 'Distance (miles)', maximumKey: 'interruption_by_authority_distance', limitKey: 'pee_override_interruption_by_authority_distance' },
            { label: 'Weeks', maximumKey: 'interruption_by_authority_weeks', limitKey: 'pee_override_interruption_by_authority_weeks' },
            {
                label: 'Maximum',
                maximumKey: 'interruption_by_authority_maximum',
                limitKey: 'pee_override_interruption_by_authority_maximum',
                isCurrencyField: true,
            },
        ],
    },
    { label: 'Contingent Time Element', maximumKey: 'contingent_time_element', limitKey: 'pee_override_contingent_time_element', isCurrencyField: true },
    { label: 'BI Indemnity Period', maximumKey: 'extended_period_of_bi_indemnity', limitKey: 'pee_override_extended_period_of_bi_indemnity' },
    { label: 'Extra/Expediting Expense', maximumKey: 'extra_expense', limitKey: 'pee_override_extra_expense', isCurrencyField: true },
    {
        label: 'Ingress/Egress',
        subFields: [
            { label: 'Distance (miles)', maximumKey: 'ingress_distance', limitKey: 'pee_override_ingress_distance' },
            { label: 'Weeks', maximumKey: 'ingress_weeks', limitKey: 'pee_override_ingress_weeks' },
            { label: 'Maximum', maximumKey: 'ingress_maximum', limitKey: 'pee_override_ingress_maximum', isCurrencyField: true },
        ],
    },
    { label: 'Ordinary Payroll (days)', maximumKey: 'ordinary_payroll', limitKey: 'pee_override_ordinary_payroll' },
    { label: 'Royalties', maximumKey: 'royalties', limitKey: 'pee_override_royalties', isCurrencyField: true },
    { label: 'Service Interruption', maximumKey: 'service_interruption_bi', limitKey: 'pee_override_service_interruption_bi', isCurrencyField: true },
];

const renderField = (field: PropertyEnhancementEndorsementField | PropertyEnhancementEndorsementSubField, fieldData: PEECoverageType) => (
    <>
        <div className="input-label">{field.label}</div>
        <ReadOnlyInput
            value={fieldData?.maximum}
            {...(field?.isCurrencyField ? { dollarBefore: true, maskOptions: MASKS['CURRENCY_INTEGER'] } : {})}
            {...(field?.isPercentField ? { percentAfter: true, maskOptions: MASKS['PERCENTAGE_POSITIVE'] } : {})}
        />
        <ReadOnlyInput
            value={fieldData?.std_limit}
            {...(field?.isCurrencyField ? { dollarBefore: true, maskOptions: MASKS['CURRENCY_INTEGER'] } : {})}
            {...(field?.isPercentField ? { percentAfter: true, maskOptions: MASKS['PERCENTAGE_POSITIVE'] } : {})}
        />
        <PolicyTermsFormField hideLabel>
            <FormInput<PolicyTermsFormValues>
                name={field.limitKey}
                {...(field?.isCurrencyField ? { dollarBefore: true, maskOptions: MASKS['CURRENCY_INTEGER'] } : {})}
                {...(field?.isPercentField ? { percentAfter: true, maskOptions: MASKS['PERCENTAGE_POSITIVE'] } : {})}
            />
        </PolicyTermsFormField>
    </>
);

const gridColsClassName = () => 'tw-grid-cols-[190px_100px_100px_100px]';

const PeeField = ({ field, data }: { field: PropertyEnhancementEndorsementField; data: Record<string, PEECoverageType> }) => {
    if (!field.subFields) {
        return <div className={cx('grid-sub-content', gridColsClassName())}>{renderField(field, data[field.maximumKey])}</div>;
    }
    return (
        <div className={cx('grid-sub-content grid-sub-row', gridColsClassName())}>
            <div className="input-label tw-col-span-full">{field.label}</div>
            {field.subFields.map(subfield => (
                <React.Fragment key={subfield.label}>{renderField(subfield, data[subfield.maximumKey])}</React.Fragment>
            ))}
        </div>
    );
};

const PeeTable = ({ children, label }: { children: React.ReactNode; label: string }) => {
    return (
        <div className="grid-sub-wrapper align-columns pee">
            <div className={cx('grid-sub-content clmn-headers', gridColsClassName())}>
                <div>{label}</div>
                <div>Maximum</div>
                <div>Standard</div>
                <div>Limit</div>
            </div>
            {children}
        </div>
    );
};

export const PropertyEnhancementEndorsement = () => {
    const {
        formState: { dirtyFields, isDirty },
        reset,
        setValue,
    } = useFormContext<PolicyTermsFormValues>();
    const { policyCoverage } = useQuotePolicyCoverage();
    const { bppCoverages, timeElements } = useGetPeeDataQuery(
        { id: policyCoverage?.id },
        {
            skip: !policyCoverage?.id || !policyCoverage.prop_enhancement_endorsement,
            selectFromResult: result => {
                const bppCoverages: Record<string, PEECoverageType> = {};
                result?.data?.bpp_coverages?.forEach(rowData => {
                    bppCoverages[rowData.prefix] = rowData;
                });
                const timeElements: Record<string, PEECoverageType> = {};
                result?.data?.time_elements?.forEach(rowData => {
                    timeElements[rowData.prefix] = rowData;
                });
                return { ...result, bppCoverages, timeElements };
            },
        }
    );
    const evacuationExpenseEndorsement = useWatch<PolicyTermsFormValues, 'evacuation_expense_endorsement'>({ name: 'evacuation_expense_endorsement' });
    const _peeOverrideValues = useWatch<PolicyTermsFormValues>({ name: Object.keys(peeOverrides) as (keyof PeeOverridesType)[] });
    const includeStdLimit = useWatch<PolicyTermsFormValues, 'is_pee_standard_limits_included'>({ name: 'is_pee_standard_limits_included' });
    const [prevIncludeStdLimit, setPrevIncludeStdLimit] = useState(includeStdLimit);

    useEffect(() => {
        if (includeStdLimit !== prevIncludeStdLimit) {
            Object.keys(peeOverrides).forEach((key: keyof PeeOverridesType) => {
                if (policyCoverage[key] !== null || key in dirtyFields) {
                    return;
                }
                if (includeStdLimit) {
                    const searchKey = key.replace('pee_override_', '');
                    // TODO: Fix
                    setValue(key, bppCoverages?.[searchKey]?.std_limit ?? timeElements?.[searchKey]?.std_limit ?? '', { shouldDirty: false });
                } else {
                    setValue(key, '0', { shouldDirty: false });
                }
            });
            // need to wipe out isDirty form state and this is the only way how
            if (Object.keys(dirtyFields).length === 0 && isDirty) {
                setValue('is_pee_standard_limits_included', includeStdLimit, { shouldDirty: true });
            }
            setPrevIncludeStdLimit(includeStdLimit);
        }
    }, [bppCoverages, dirtyFields, includeStdLimit, isDirty, policyCoverage, prevIncludeStdLimit, reset, setValue, timeElements]);

    return (
        <div className="[&_.grid-sub-content>div]:tw-m-0 [&_input]:tw-text-right">
            <div className="tw-pl-[8px] tw-py-[4px] tw-grid tw-grid-cols-[190px_1fr]">
                <PolicyTermsFormField>
                    <FormButtonGroup<PolicyTermsFormValues>
                        name="is_pee_standard_limits_included"
                        items={[
                            { display: 'Include', value: true },
                            { display: 'Exclude', value: false },
                        ]}
                    />
                </PolicyTermsFormField>
            </div>
            <PeeTable label="Building and BPP Coverages">
                {BPP_PROPERTY_ENHANCEMENT_ENDORSEMENT_FIELDS.map(field => (
                    <PeeField key={field.label} field={field} data={bppCoverages} />
                ))}
            </PeeTable>
            <hr />
            <PeeTable label="Time Element Coverages">
                {TIME_ELEMENT_PROPERTY_ENHANCEMENT_ENDORSEMENT_FIELDS.map(field => (
                    <PeeField key={field.label} field={field} data={timeElements} />
                ))}
            </PeeTable>
            {/* TODO */}
            {false && (
                <>
                    <hr />
                    <div className="info-rows">
                        <div className="input-label flex">Spoilage</div>
                        <div></div>

                        <div className="input-label flex">Limit</div>
                        <div>
                            <div className="input-div flex text-input masked-text-input numeric-input">
                                <input type="text" className="input-element" value="" />
                            </div>
                        </div>
                        <div className="input-label flex">Deductible</div>
                        <div className="input-div flex viki-combo-box">
                            <div className="combo-box">
                                <div className="combo-box-arrow"></div>
                                <input type="text" autoComplete="off" className="combobox-input-element input-element" value="" />
                            </div>
                        </div>
                        <div className="input-label flex">Description of Property</div>
                        <div>
                            <div className="input-div flex text-input masked-text-input numeric-input">
                                <input type="text" className="input-element" value="" />
                            </div>
                        </div>

                        <div className="input-label flex">
                            <div className="txt-overflow">Claim Valuation</div>
                        </div>
                        <div className="input-div flex viki-combo-box">
                            <div className="combo-box">
                                <div className="combo-box-arrow"></div>
                                <input type="text" autoComplete="off" className="combobox-input-element input-element" value="" />
                            </div>
                        </div>

                        <div className="input-label flex">
                            <div className="txt-overflow">Occupancy</div>
                        </div>
                        <div className="input-div flex viki-combo-box">
                            <div className="combo-box">
                                <div className="combo-box-arrow"></div>
                                <input type="text" autoComplete="off" className="combobox-input-element input-element" value="" />
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="info-rows [&:not(:empty)]:tw-border-t [&:not(:empty)]:tw-mt-2 [&:not(:empty)]:tw-pt-2 tw-border-grey-border">
                <PolicyTermsFormField>
                    <FormButtonGroup<PolicyTermsFormValues>
                        name="evacuation_expense_endorsement"
                        items={[
                            { display: 'Include', value: true },
                            { display: 'Exclude', value: false },
                        ]}
                    />
                </PolicyTermsFormField>
                {evacuationExpenseEndorsement && (
                    <>
                        <PolicyTermsFormField>
                            <FormInput<PolicyTermsFormValues>
                                name="evacuation_expense_endorsement_per_occurrence_limit"
                                dollarBefore
                                maskOptions={MASKS['CURRENCY_INTEGER']}
                            />
                        </PolicyTermsFormField>

                        <PolicyTermsFormField>
                            <FormInput<PolicyTermsFormValues>
                                name="evacuation_expense_endorsement_aggregate_limit"
                                dollarBefore
                                maskOptions={MASKS['CURRENCY_INTEGER']}
                            />
                        </PolicyTermsFormField>
                    </>
                )}
            </div>
        </div>
    );
};
