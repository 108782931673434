import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../hooks/redux';
import { OverrideSwitch } from '@archinsurance-viki/property-jslib/src/components/inputs/v2';
import Grid, { Label, OverrideButton, Value } from './PricingGrid';
import { useSetFinalPremiumOverridesMutation } from '../../services/apiSlice';
import { useSubmissionIsEditable } from '../../hooks/submissions';
import { useQuoteId, useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import { useChangeEffect, useDebounceCallback } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { PRICING_DEBOUNCE_TIME } from '../../constants/PricingConstants';
import { ChargedPremiumDifferenceBadge } from './features/ChargedPremiumDifferenceBadge';
import { SetOverridesArgs } from '../../ts-types/ApiTypes';

const AopPremiumPanel = ({ showModal }: { showModal: () => void }) => {
    const quoteId = useQuoteId();
    const isEditable = useSubmissionIsEditable();
    const { finalPremium } = useQuoteFinalPremium();
    const { policyCoverage } = useQuotePolicyCoverage();

    const { register, handleSubmit, resetField } = useForm({
        defaultValues: {
            equipment_breakdown: policyCoverage.equipment_breakdown,
            terror: policyCoverage.terror,
        },
    });

    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const enableWindDeductibleBuyDown = featureFlags?.enable_wind_deductible_buy_down || false;
    const [setOverrides] = useSetFinalPremiumOverridesMutation({ fixedCacheKey: `${quoteId}` });
    const debouncedSetOverrides = useDebounceCallback(
        (data: Pick<SetOverridesArgs, 'equipment_breakdown' | 'terror'>) => setOverrides({ id: finalPremium.id, quoteId, data }),
        PRICING_DEBOUNCE_TIME
    );

    const disabled = !isEditable;
    useChangeEffect(policyCoverage, (nextPC, prevPC) => {
        if (!prevPC) {
            return;
        }
        if (prevPC.terror !== nextPC.terror) {
            resetField('terror', { defaultValue: nextPC.terror });
        }
        if (prevPC.equipment_breakdown !== nextPC.equipment_breakdown) {
            resetField('equipment_breakdown', { defaultValue: nextPC.equipment_breakdown });
        }
    });

    if (!finalPremium) {
        return null;
    }
    const {
        base_premium_final,
        equipment_breakdown_premium_adj_for_participation_final,
        property_enhancement_premium_adj_for_participation_final,
        ground_up_base_premium_final,
        is_valid,
        layer_base_premium_final,
        layered_pricing_factor_final,
        limit_of_insurance_credit,
        loss_history_credit,
        occurrence_participation,
        spread_of_risk_credit,
        terror_premium_adj_for_participation_final,
        total_aop_premium_final,
        total_building_premium_final,
        terror_premium,
        terror_premium_override,
        equipment_breakdown_external_premium_override,
        equipment_breakdown_premium_adj_for_participation_model,
        charged_premium_before_tf,
    } = finalPremium;

    const terrorPremiumDisplayValue = charged_premium_before_tf
        ? terror_premium_adj_for_participation_final
        : terror_premium_override
        ? terror_premium_override
        : terror_premium;

    const totalAopPremiumFinal = is_valid ? (+total_aop_premium_final + +terrorPremiumDisplayValue).toFixed(0) : null;
    // total_aop_premium_final arbitrarily excludes terror, so have to add it back in
    // base_premium_final and the adj_for_participation values are not always whole numbers

    const onSubmit = async (data: { equipment_breakdown: boolean; terror: boolean }) => {
        debouncedSetOverrides(data);
    };

    return (
        <>
            <div className="info-block grid-2-high w-260">
                <div className="header">AOP Premium</div>

                <Grid columns={2} className="gap-0125 padding-light">
                    <Label>Base Premium - All Buildings</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={total_building_premium_final} />

                    <Label>Limit of Insurance Credit</Label>
                    <Value format={{ type: 'number', decimals: 1, postfix: '%' }} value={limit_of_insurance_credit} />

                    <Label>Spread of Risk Credit</Label>
                    <Value format={{ type: 'number', decimals: 1, postfix: '%' }} value={spread_of_risk_credit} />

                    <Label>Loss History Credit</Label>
                    <Value format={{ type: 'number', decimals: 1, postfix: '%' }} value={loss_history_credit} />

                    <Label>Ground Up Base Premium</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={ground_up_base_premium_final} />

                    <div className="grid-2-wide grid-spacer"></div>

                    <Label>Layer Loss Factor</Label>
                    <Value format={{ type: 'number', decimals: 1, postfix: '%' }} value={layered_pricing_factor_final} />

                    <Label>Layer Base Premium</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={layer_base_premium_final} />

                    <Label>Quota Share Participation</Label>
                    <Value format={{ type: 'number', decimals: 1, postfix: '%' }} value={occurrence_participation} />

                    <Label>Base Premium</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={base_premium_final} />

                    <div className="grid-2-wide grid-spacer"></div>

                    <Label>PEE</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={property_enhancement_premium_adj_for_participation_final} />

                    <div className="flex gap-x-1 ai_c tw-p-0 tw-pl-1">
                        EB
                        <OverrideSwitch disabled={disabled} onSubmit={handleSubmit(onSubmit)} inputProps={{ ...register('equipment_breakdown') }} />
                        <ChargedPremiumDifferenceBadge premiumField="equipment_breakdown" />
                    </div>
                    <OverrideButton onClick={() => showModal()}>
                        <Value
                            format={{ type: 'number', delimiter: ',' }}
                            value={
                                charged_premium_before_tf
                                    ? equipment_breakdown_premium_adj_for_participation_final
                                    : equipment_breakdown_external_premium_override
                                    ? equipment_breakdown_external_premium_override
                                    : equipment_breakdown_premium_adj_for_participation_model
                            }
                        />
                    </OverrideButton>

                    <div className="flex gap-x-1 ai_c tw-p-0 tw-pl-1">
                        Terror
                        <OverrideSwitch disabled={disabled} onSubmit={handleSubmit(onSubmit)} inputProps={{ ...register('terror') }} />
                        <ChargedPremiumDifferenceBadge premiumField="terror" />
                    </div>
                    <OverrideButton onClick={() => showModal()}>
                        <Value format={{ type: 'number', delimiter: ',' }} value={terrorPremiumDisplayValue} />
                    </OverrideButton>

                    <div className="grid-2-wide grid-spacer"></div>
                    {enableWindDeductibleBuyDown ? (
                        <Label>
                            <b>Target AOP Premium</b>
                        </Label>
                    ) : (
                        <Label>
                            <b>Total AOP Premium</b>
                        </Label>
                    )}
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={totalAopPremiumFinal} />
                </Grid>
            </div>
        </>
    );
};

export default AopPremiumPanel;
