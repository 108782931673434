import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { PolicyTermsFormValues, PolicyTermsFormField } from '../utils/form';

export const SinkholeCoverages = () => (
    <>
        {false && (
            <div className="info-rows">
                {/* TODO */}
                <>
                    <div className="input-label flex">
                        <div className="txt-overflow">Deductible</div>
                    </div>
                    <div className="data-entry-btn-wrapper dollar-percent-labels">
                        <button className="data-entry active">$</button>
                        <button className="data-entry">%</button>
                        <div className="input-div flex text-input masked-text-input numeric-input">
                            <input type="text" className="input-element" value="25,000" />
                        </div>
                    </div>
                </>
            </div>
        )}
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormInput<PolicyTermsFormValues> name="min_sinkhole_deductible" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
            </PolicyTermsFormField>
        </div>
    </>
);
