import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultFooter, DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { Types } from '../../../../ts-types/viki-types';

type ModalSectionProps = {
    children: ReactNode;
    onReset?: () => void;
    showReset?: boolean;
    title: string;
};

const ModalSection = ({ children, onReset, showReset, title }: ModalSectionProps) => (
    <div>
        <div className="tw-mb-0.5 tw-flex tw-gap-2">
            <span className="tw-text-sm">{title}</span>
            {showReset && (
                <span className="tw-cursor-pointer tw-text-sm tw-text-blue-primary" onClick={onReset}>
                    Reset
                </span>
            )}
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>
    </div>
);

type AddDescriptionProps = {
    onSubmit: (formValues: FormValues) => void;
} & footerProps;

type FormValues = {
    descriptionBody: string;
};

const AddDescription = ({ onSubmit, modalData, ...modalProps }: AddDescriptionProps) => {
    const { formState, handleSubmit, register, resetField, watch } = useForm<FormValues>({
        defaultValues: {
            descriptionBody: '',
        },
    });

    const { dirtyFields } = formState;
    const descriptionBody = watch('descriptionBody');

    return (
        <form className="tw-flex tw-flex-col tw-gap-4">
            <ModalSection title="Description" showReset={!!dirtyFields.descriptionBody} onReset={() => resetField('descriptionBody')}>
                <textarea rows={5} className="tw-p-1 tw-w-full tw-border tw-border-grey-border" {...register('descriptionBody')} />
            </ModalSection>
            <DefaultFooter
                className="tw-p-0"
                disableConfirm={!descriptionBody || descriptionBody.length === 0}
                modalData={{ onOk: handleSubmit(onSubmit), ...modalData }}
                {...modalProps}
            />
        </form>
    );
};

type footerProps = {
    onClose: (close: boolean) => void;
    isDestructive?: boolean;
    modalData?: Record<string, unknown>;
};

type ModalProps = {
    ENV: Types.Env;
    modalState: Record<string, unknown>;
} & footerProps;

export const AddDescriptionWrapper = ({
    modalData,
    onSubmit,
    onClose,
    ...modalProps
}: ModalProps & { onSubmit: ({ descriptionBody }: FormValues) => void }) => {
    return (
        <div className="tw-flex tw-flex-col tw-p-4 tw-w-[512px]">
            <AddDescription onSubmit={onSubmit} onClose={onClose} modalData={modalData} {...modalProps} />
        </div>
    );
};

const modalTypeObj = {
    Body: AddDescriptionWrapper,
    Header: DefaultHeaderStatic('Quote Description'),
};
registerType(VIRA_MODAL_TYPES.ADD_QUOTE_DESCRIPTION, modalTypeObj);
export default modalTypeObj;
