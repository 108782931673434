import { useQueryParams, NumberParam, StringParam, encodeDelimitedArray, decodeDelimitedArray } from 'use-query-params';
import AnalyticsApp from './AnalyticsApp';
import { getAirPml, getPmlTableData, calculatePmlData } from '../../actions/AnalyticsActions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { DefaultAccountLayout } from '../common/DefaultLayout';

type propTypes = {
    hasLeftPanel?: boolean;
};

const CommaArrayParam = {
    encode: (array: string[]) => encodeDelimitedArray(array, ','),
    decode: (arrayStr: string | string[]) => decodeDelimitedArray(arrayStr, ','),
};

export default function AnalyticsContainer(props: propTypes) {
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const dispatch = useAppDispatch();

    const onGetAirPml = (options: Record<string, any>) => dispatch(getAirPml(options));
    const onGetPmlTableData = (options: Record<string, any>) => dispatch(getPmlTableData(options));
    const onCalculatePmlData = (options: Record<string, any>) => dispatch(calculatePmlData(options));

    const [params, setParams] = useQueryParams({
        carrier_id: NumberParam,
        year: NumberParam,
        in_force: StringParam,
        return_periods: CommaArrayParam,
        pmls: CommaArrayParam,
        cat_view_types: CommaArrayParam,
        includes_quoteds: CommaArrayParam,
        metrics: CommaArrayParam,
    });

    return (
        <DefaultAccountLayout topPanelHeader="PML Analysis" hasActionPanel={false}>
            <AnalyticsApp
                CONSTANTS={CONSTANTS}
                featureFlags={featureFlags}
                onGetAirPml={onGetAirPml}
                onGetPmlTableData={onGetPmlTableData}
                onCalculatePmlData={onCalculatePmlData}
                params={params}
                onChangeUrlQueryParams={setParams}
            />
        </DefaultAccountLayout>
    );
}
