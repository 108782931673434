import { createListenerMiddleware } from '@reduxjs/toolkit';
import { commonApi } from '@archinsurance-viki/property-jslib/src/services/commonApi';
import { isNumber } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { updateSubmissionData } from '../actions/SubmissionActions';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { submissionApi } from '../services/endpoints/submission';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    matcher: submissionApi.endpoints.validateSubmission.matchFulfilled,
    effect: ({ meta, payload }, listenerApi) => {
        if (!payload) {
            return;
        }

        const { quoteId, submissionId } = meta.arg.originalArgs;

        listenerApi.dispatch(updateSubmissionData(submissionId, { _validations: { ...payload, _currentQuoteId: quoteId } }));

        // sync up actions post-validation so buttons become clickable
        listenerApi.dispatch(submissionApi.endpoints.submissionData.initiate({ id: submissionId, includes: ['_actions.*'] }, { subscribe: false }));
    },
});

listenerMiddleware.startListening({
    matcher: commonApi.endpoints.updateTable.matchFulfilled,
    effect: ({ meta, payload }, listenerApi) => {
        if (meta.arg.originalArgs.tableConfig !== PAGED_TABLES.SUBMISSION_LOG) {
            return;
        }
        const rows = Array.isArray(payload) ? payload : [payload];
        rows.forEach(row => {
            if (isNumber(row?.primary_quote_id)) {
                listenerApi.dispatch(submissionApi.util.invalidateTags([{ type: 'QuoteValidation', id: row.primary_quote_id }]));
            }
        });
    },
});

export default listenerMiddleware;
