import * as React from 'react';

import DocumentsViewerFullScreen from '@archinsurance-viki/property-jslib/src/components/documents/DocumentsViewerFullScreen';

import { OnOpenModalType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

import DocumentUpload from './DocumentUpload';
import { API } from '@archinsurance-viki/property-jslib';

import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { TableConfigType, LiteTableDataType, OnPersistTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import SubmissionInfo from '../navigation/SubmissionInfo';
import { AppContextFnsType, AppContextType } from '../../ts-types/AppTypes';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../submissionlog/Helpers';
import { documentMapper } from '../../utils/ui-helpers';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { getTaskSubtitles } from '../../utils/task-helpers';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';
import { Types } from '../../ts-types/viki-types';
import { RAE_SITE_ID } from '../../constants/SiteConstants';
import { LITE_TABLE_VIEW_SETTINGS } from '../../constants/LiteTableViewSettings';

type propTypes = {
    onDownloadAllSubmissionDocs: () => void;
    onPersistDocumentData: OnPersistTableDataType;
    onExportSubmission: () => Promise<any>;
    onSendToClaimsVendor: (documentRowData: Record<string, any>) => Promise<any>;
    onDeleteDocument: (documentId: number) => Promise<any>;
    onParseDocument: (documentId: number, index?: number) => any;
    onParseSov: (id?) => any;
    onExportMergedDataEntrySpreadsheet: (documentId: number, index?: number) => any;
    onOpenCenteredModal: OnOpenModalType;
    onUpdateSubmissionData: (submissionId: number, data: Record<string, any>) => any;
    onDownloadDocument: (document: Record<string, any>, callback: () => any) => any;
    onLoadExcel: (document: Record<string, any>) => any;
    tableConfig: TableConfigType;
    tableData: LiteTableDataType;
    appContext: AppContextType;
    appContextFns: AppContextFnsType;
    onDocumentPreviewFail: (documentId) => any;
    ENV: Types.Env;
};

type stateTypes = {
    uploadMessage?: string;
    uploadErrors: string[];
    popupContent: React.ReactNode;
    popupRef?: Element;
    documentPreviewRetriedId: number[];
};

export default class DocumentsApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;

    documents: Record<string, any>[];
    docViewer: any;

    constructor(props: propTypes) {
        super(props);

        this.state = {
            uploadMessage: null,
            uploadErrors: [],
            popupContent: null,
            popupRef: null,
            documentPreviewRetriedId: [],
        };
    }

    onStatusUpdate = (update: Record<string, any>) => {
        this.setState({
            uploadMessage: update.message,
            uploadErrors: update.errors,
        });

        if (update.done) {
            setTimeout(() => {
                this.setState({ uploadMessage: null });
            }, 2000);
        }
    };

    onDownloadDocument = (documentId: number) => {
        const doc = this.getDocument(documentId);

        if (doc.av_status !== 'C') {
            const avErrorMessage =
                doc.av_status === 'V'
                    ? 'The system has identified a potential virus with this file, please contact IT for support'
                    : 'This file cannot be downloaded while still pending antivurs scan.';

            return this.props.onOpenCenteredModal({ title: avErrorMessage }, CENTERED_MODAL_TYPES.CONFIRM);
        }

        return this.props.onDownloadDocument(doc, () => {
            this.props.onOpenCenteredModal(
                {
                    title: 'The download URL for this file is missing!',
                },
                CENTERED_MODAL_TYPES.CONFIRM
            );
        });
    };

    onOpenCarousel = (id: number) => {
        if (this.docViewer) {
            this.docViewer.openViewer(id);
        }
    };

    getDocument = (id: number): Record<string, any> => {
        return this.props.tableData.rowData[id];
    };

    onError = (index: number, document: Record<string, any>, errorState: string) => {
        if (errorState === 'THUMBNAIL_LOAD_FAILURE') {
            if (!this.state.documentPreviewRetriedId.includes(document.id)) {
                this.props.onDocumentPreviewFail(document.id);
                this.setState({ documentPreviewRetriedId: [...this.state.documentPreviewRetriedId, document.id] });
            }
        }
        this.props.onUpdateSubmissionData(this.props.appContext.originalSubmissionId, {
            documents: { [index]: { preview: { state: errorState } } },
        });
    };

    onExtraDocumentRowAction = (id: number, index: number, rowData: Record<string, any>) => {
        if (rowData.document_type === 'SOV_BOT_DATA_ENTRY_XLSX') return this.props.onExportMergedDataEntrySpreadsheet(id, index);
        if (rowData.document_type === 'DATA_ENTRY_XLSX') return this.props.onParseDocument(id, index);
        if (rowData.document_type === 'SOV') return this.props.onParseSov(id);
    };

    render() {
        let {
            appContext: { documentIdList, originalSubmission, originalSubmissionId },
            tableConfig,
            tableData,
            ENV,
        } = this.props;

        if (!originalSubmission) {
            return null;
        }

        const isRaeAndBound = ENV.SITE.id === RAE_SITE_ID && originalSubmission.status === 'BOUND';
        if (isRaeAndBound) {
            tableConfig.viewSettingsConfig = LITE_TABLE_VIEW_SETTINGS.raeBoundDocuments;
        }

        const disabledClaimsAction = {
            disabled: !this.props.appContext.me.has_operations_permissions,
            disabledReasons: 'This action is restricted to operations users.',
        };
        if (!this.props.appContext.currentSubmission?.is_issued) {
            disabledClaimsAction.disabled = true;
            disabledClaimsAction.disabledReasons = 'Submission does not have policy issuance document.';
        }

        let submissionId = originalSubmissionId;
        const docsSection = {
            title: 'Documents Actions',
            actions: [
                {
                    label: 'Export Data Entry Spreadsheet',
                    action: this.props.onExportSubmission,
                    id: 'export',
                },
                {
                    label: 'Download All Documents',
                    action: this.props.onDownloadAllSubmissionDocs,
                    id: 'download',
                },
                {
                    label: 'Send Policy Docs to Sedgwick',
                    action: this.props.onSendToClaimsVendor,
                    actionFnData: tableData.rowData,
                    id: 'sedgwick',
                    ...disabledClaimsAction,
                },
            ],
        };

        let submissionInfo = <SubmissionInfo />;
        let documentsList = documentMapper(documentIdList, tableData.rowData);

        return (
            <DocumentUpload
                className="submission-file-editor flex grow height-100"
                uploadParams={{ submission_id: submissionId }}
                uploadAlias="document"
                onStatusUpdate={this.onStatusUpdate}
                uploadEndpoint={API.endpoints.submission.uploadFiles}
                onDocumentUploaded={() => {
                    this.props.appContextFns.onLoadDocumentsForSubmission(submissionId, true);
                }}
            >
                <DocumentsViewerFullScreen
                    ref={ref => {
                        this.docViewer = ref;
                    }}
                    documentsList={documentsList}
                    focusElementOnClose={`${tableConfig.tableName}VikiTable`}
                    leftItems={submissionInfo}
                    onDownloadDocument={this.onDownloadDocument}
                    onLoadExcel={this.props.onLoadExcel}
                />
                <LiteTableContainer
                    hasSearchPanel={true}
                    tableConfig={tableConfig}
                    allowsDragging={false}
                    hasActionPanel={true}
                    onSpacePressed={this.onOpenCarousel}
                    panelActions={calculateRowActions(this.props, ACTION_PANEL_LOCATIONS.DOCUMENTS, docsSection)}
                    onPersistTableData={this.props.onPersistDocumentData}
                    tableRows={documentIdList}
                    actionHelperFns={{
                        onOpenCarousel: this.onOpenCarousel,
                        onDownloadDocument: this.onDownloadDocument,
                        onError: this.onError,
                        onLoadExcel: this.props.onLoadExcel,
                        onDeleteDocument: this.props.onDeleteDocument,
                        onExtraDocumentRowAction: this.onExtraDocumentRowAction,
                        onParseDocument: this.props.onParseDocument,
                        onParseSov: this.props.onParseSov,
                        onExportMergedDataEntrySpreadsheet: this.props.onExportMergedDataEntrySpreadsheet,
                    }}
                    getTaskSubtitles={getTaskSubtitles}
                    tasksPanel={<AccountTasksPanel />}
                >
                    <div className="instructions upload">
                        <div className="txt">{this.state.uploadMessage || 'Drag and drop files on this table to add them to the submission'}</div>
                        <span className="icon-file-text2" />
                        <span className="icon-folder-open" />
                        <span className="icon-file-picture" />
                        <span className="icon-smile2" />
                    </div>
                </LiteTableContainer>
            </DocumentUpload>
        );
    }
}
