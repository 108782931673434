import { isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { getCurrentAppContext } from '../utils/state-helpers';
import { AppContextFnsType, AppContextType } from '../ts-types/AppTypes';
import { useAppSelector } from './redux';
import { createContextAndHelpers } from '@archinsurance-viki/property-jslib/src/hooks/context';

// TODO: this is quite inefficient because the entire app context is memoized as one, we need to break this up
export const useAppContext = (): AppContextType => {
    const params = useParams();
    return useAppSelector(state => getCurrentAppContext(state, params), isEqual);
};

export const [appContextFnsContext, AppContextFnsProvider, useAppContextFns] = createContextAndHelpers<AppContextFnsType>();
