import * as React from 'react';

import EndorsementPricing from '../containers/EndorsementPricing.container';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';
import { TransactionDataType } from '../../../../ts-types/DataTypes';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    modalData: {
        onSaveEndorsementPricing: (
            transaction: TransactionDataType,
            pricing_type: any,
            pricing_override: any,
            equipment_breakdown_external_premium_delta_override: any,
            inspection_fee: any,
            modeling_fee: any
        ) => void;
        transaction: TransactionDataType;
    };
    onClose: (x?: boolean) => any;
};

class EndorsementPricingBody extends React.Component<bodyPropTypes> {
    render(): React.ReactNode {
        return <EndorsementPricing {...this.props.modalData} onClose={this.props.onClose} />;
    }
}

let modalTypeObj = {
    Header: DefaultHeaderStatic('Endorsement Pricing Options'),
    Body: EndorsementPricingBody,
    className: 'endorsement-pricing-modal',
};
registerType(VIRA_MODAL_TYPES.ENDORSEMENT_PRICING, modalTypeObj);
export default modalTypeObj;
