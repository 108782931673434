import * as React from 'react';

import { PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';

import BuildingCoverageApp from './BuildingCoverageApp';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppSelector } from '../../hooks/redux';

export default function BuildingCoverageContainer() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const apiParams = { submission_id: appContext.currentSubmissionId };
    const apiParamsKey = getApiParamsKey(apiParams);
    const tableData = useAppSelector(state => state.buildingCoverages[apiParamsKey] || { rows: [], rowData: {} });

    const onRowSelected = (buildingId: number, replaceInUrl: boolean) => {
        appContextFns.onNavigate({ buildingId }, replaceInUrl);
    };

    const { currentBuildingId, currentSubmissionId } = appContext;

    if (!currentSubmissionId) {
        return null;
    }

    return (
        <BuildingCoverageApp
            appContext={appContext}
            tableConfig={appContext.buildingConverageGridConfig}
            tableData={tableData as PagedTableDataType}
            onRowSelected={onRowSelected}
            selectedRowId={currentBuildingId}
        />
    );
}
