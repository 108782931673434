import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormValues, PolicyTermsFormField, insertOptionChoices } from '../utils/form';

const OPTIONAL_CHOICE = '- Choose an option (optional) -';

export const AopCoverages = () => {
    const AOP_DEDUCTIBLE_OPTION = useAppSelector(state => state.global.CONSTANTS.AOP_DEDUCTIBLE_OPTION);
    const WATER_DAMAGE_DEDUCTIBLE_OPTION = useAppSelector(state => insertOptionChoices(state, 'WATER_DAMAGE_DEDUCTIBLE_OPTION', OPTIONAL_CHOICE));

    return (
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="aop_deductible" selections={AOP_DEDUCTIBLE_OPTION} />
            </PolicyTermsFormField>

            <PolicyTermsFormField>
                <FormSelect<PolicyTermsFormValues> name="water_damage_deductible" selections={WATER_DAMAGE_DEDUCTIBLE_OPTION} />
            </PolicyTermsFormField>
        </div>
    );
};
