import * as React from 'react';

import moment from 'moment';

import { DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import DefaultModal, { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/viki-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalState: Record<string, any>;
    modalData: Record<string, any>;
};

class RenewSubmissionBody extends React.Component<bodyPropTypes> {
    render() {
        let { new_sid, changed_fields } = this.props.modalData;
        let { insured__effective_date, insured__expiration_date } = changed_fields;
        let insuredEffective = moment(insured__effective_date[1]).format(DISPLAY_DATE_FORMAT);
        let insuredExpire = moment(insured__expiration_date[1]).format(DISPLAY_DATE_FORMAT);
        // let editUrl = `/submissions/${new_sid}/documents`;

        return (
            <div style={{ padding: '30px 0', textAlign: 'center' }}>
                <div style={{ paddingBottom: '25px' }}>
                    <b>Submission {new_sid}</b> with Effective <b>{insuredEffective}</b> to Expiration on <b>{insuredExpire}</b>
                </div>
            </div>
        );
    }
}

let modalTypeObj = {
    Body: RenewSubmissionBody,
    Header: DefaultHeaderStatic('YOUR RENEWAL HAS BEEN CREATED'),
    Footer: DefaultModal.Footer,
    className: 'renew-modal',
};
registerType(VIRA_MODAL_TYPES.RENEWAL, modalTypeObj);
export default modalTypeObj;
