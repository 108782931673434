import { TagDescription } from '@reduxjs/toolkit/query';
import {
    BuildingGroupTermsGlossaryResult,
    BuildingGroupTermsResult,
    BuildingTermsGlossaryResult,
    BuildingTermsResult,
    PolicyTermsGlossaryResult,
    PolicyTermsResult,
} from '../../ts-types/ApiTypes';
import api from '../apiSlice';

const coverageTermsApi = api.injectEndpoints({
    endpoints: builder => ({
        policyTermsData: builder.query<PolicyTermsResult, { policyCoverageId: number }>({
            query: ({ policyCoverageId }) => ({ url: `v1/policy_terms_data/${policyCoverageId}/`, method: 'GET' }),
            providesTags: (_result, _err, { policyCoverageId }) => [{ type: 'PolicyCoverage', id: policyCoverageId }],
        }),

        policyTermsGlossary: builder.query<PolicyTermsGlossaryResult, Record<string, never>>({
            query: () => ({ url: 'v1/policy_terms_data/glossary/', method: 'GET' }),
        }),
        policyTermsDetailGlossary: builder.query<PolicyTermsGlossaryResult, { id: number }>({
            query: ({ id }) => ({ url: `v1/policy_terms_data/${id}/detail_glossary/`, method: 'GET' }),
        }),
        poilcyTermsBulkUpdate: builder.mutation<
            { status: string; modified_policy_coverages: number[] },
            { quoteIds: number[]; data: Partial<PolicyTermsResult> }
        >({
            query: ({ quoteIds, data }) => ({
                url: 'v2/bulk_update/policy_data',
                method: 'PUT',
                data: { coverage_options: quoteIds, policy_updates: data },
                hideDialogForErrors: true,
            }),
            invalidatesTags: (result, _err, { quoteIds }) => {
                if (!result) {
                    return [];
                }
                let tags: TagDescription<'Quote' | 'QuoteValidation' | 'PolicyCoverage' | 'PolicyCoverageValidation'>[] = [];
                quoteIds.forEach(id => {
                    tags.push({ type: 'Quote', id });
                    tags.push({ type: 'QuoteValidation', id });
                });
                result.modified_policy_coverages.forEach(id => {
                    tags.push({ type: 'PolicyCoverage', id });
                    tags.push({ type: 'PolicyCoverageValidation', id });
                });
                return tags;
            },
        }),
        buildingTermsData: builder.query<BuildingTermsResult, { buildingCoverageId: number }>({
            query: ({ buildingCoverageId }) => ({ url: `v1/policy_terms_building_data/${buildingCoverageId}/`, method: 'GET' }),
            providesTags: (_result, _err, { buildingCoverageId }) => [{ type: 'BuildingCoverage', id: buildingCoverageId }],
        }),
        buildingTermsGlossary: builder.query<BuildingTermsGlossaryResult, Record<string, never>>({
            query: () => ({ url: 'v1/policy_terms_building_data/glossary/', method: 'GET' }),
        }),
        buildingTermsDetailGlossary: builder.query<BuildingTermsGlossaryResult, { buildingCoverageId: number }>({
            query: ({ buildingCoverageId }) => ({ url: `v1/policy_terms_building_data/${buildingCoverageId}/detail_glossary/`, method: 'GET' }),
        }),
        buildingTermsBulkUpdate: builder.mutation<
            { status: string; modified_policy_coverages: number[]; modified_building_coverages: number[] },
            { buildingCoverageIds: number[]; quoteIds: number[]; data: Partial<BuildingTermsResult> }
        >({
            query: ({ buildingCoverageIds, quoteIds, data }) => ({
                url: 'v2/bulk_update/building_data',
                method: 'PUT',
                data: { coverage_options: quoteIds, building_updates: data, building_coverages: buildingCoverageIds },
                hideDialogForErrors: true,
            }),
            invalidatesTags: (result, _err, { quoteIds }) => {
                if (!result) {
                    return [];
                }
                let tags: TagDescription<'Quote' | 'QuoteValidation' | 'PolicyCoverage' | 'PolicyCoverageValidation' | 'BuildingCoverage'>[] = [];
                quoteIds.forEach(id => {
                    tags.push({ type: 'Quote', id });
                    tags.push({ type: 'QuoteValidation', id });
                });
                result.modified_policy_coverages.forEach(id => {
                    tags.push({ type: 'PolicyCoverage', id });
                    tags.push({ type: 'PolicyCoverageValidation', id });
                });
                result.modified_building_coverages.forEach(id => {
                    tags.push({ type: 'BuildingCoverage', id });
                    // TODO: BuildingCoverageValidation
                });
                return tags;
            },
        }),
        buildingGroupTermsData: builder.query<BuildingGroupTermsResult, { buildingGroupCoverageId: number }>({
            query: ({ buildingGroupCoverageId }) => ({ url: `v1/building_group_terms_data/${buildingGroupCoverageId}/`, method: 'GET' }),
            providesTags: (_result, _err, { buildingGroupCoverageId }) => [{ type: 'BuildingGroupCoverage', id: buildingGroupCoverageId }],
        }),
        buildingGroupTermsGlossary: builder.query<BuildingGroupTermsGlossaryResult, Record<string, never>>({
            query: () => ({ url: 'v1/building_group_terms_data/glossary/', method: 'GET' }),
        }),
        buildingGroupTermsDetailGlossary: builder.query<BuildingGroupTermsGlossaryResult, { buildingGroupCoverageId: number }>({
            query: ({ buildingGroupCoverageId }) => ({ url: `v1/building_group_terms_data/${buildingGroupCoverageId}/detail_glossary/`, method: 'GET' }),
        }),
        /*TODO: buildingGroupTermsBulkUpdate: builder.mutation<
            { status: string; modified_policy_coverages: number[]; modified_building_coverages: number[] },
            { buildingGroupCoverageIds: number[]; quoteIds: number[]; data: Partial<BuildingTermsResult> }
        >({
            query: ({ buildingCoverageIds, quoteIds, data }) => ({
                url: 'v2/bulk_update/building_data',
                method: 'PUT',
                data: { coverage_options: quoteIds, building_updates: data, building_coverages: buildingCoverageIds },
                hideDialogForErrors: true,
            }),
            invalidatesTags: (result, _err, { quoteIds }) => {
                if (!result) {
                    return [];
                }
                let tags: TagDescription<'Quote' | 'QuoteValidation' | 'PolicyCoverage' | 'PolicyCoverageValidation' | 'BuildingCoverage'>[] = [];
                quoteIds.forEach(id => {
                    tags.push({ type: 'Quote', id });
                    tags.push({ type: 'QuoteValidation', id });
                });
                result.modified_policy_coverages.forEach(id => {
                    tags.push({ type: 'PolicyCoverage', id });
                    tags.push({ type: 'PolicyCoverageValidation', id });
                });
                result.modified_building_coverages.forEach(id => {
                    tags.push({ type: 'BuildingGroupCoverage', id });
                    // TODO: BuildingCoverageValidation
                });
                return tags;
            },
        }),*/
    }),
});

export const {
    useBuildingTermsDataQuery,
    useBuildingTermsGlossaryQuery,
    useBuildingTermsDetailGlossaryQuery,
    useBuildingTermsBulkUpdateMutation,
    usePoilcyTermsBulkUpdateMutation,
    usePolicyTermsGlossaryQuery,
    usePolicyTermsDetailGlossaryQuery,
    usePolicyTermsDataQuery,
    useBuildingGroupTermsDataQuery,
    useBuildingGroupTermsDetailGlossaryQuery,
    useBuildingGroupTermsGlossaryQuery,
} = coverageTermsApi;
