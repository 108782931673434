import * as React from 'react';

import CheckInputClearable from '@archinsurance-viki/property-jslib/src/components/inputs/CheckInputClearable';
import TextInput from '@archinsurance-viki/property-jslib/src/components/inputs/TextInput';
import { INPUT_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';

type propTypes = {
    inputType: string;
    inputData: Record<string, any>;
    onClick: (currentQuoteId: any, object: Record<string, any>) => void;
    currentQuote: Record<string, any>;
    object: Record<string, any>;
    disabled: boolean;
    checkboxIndex?: number;
};

export default class BindingRequirementsRow extends React.Component<propTypes> {
    handleChange = (field: string, value: any) => {
        let { onClick, currentQuote, object } = this.props;
        onClick(currentQuote.id, { ...object, [field]: value });
    };

    render(): React.ReactNode {
        let { object, inputType, inputData, disabled } = this.props;
        let { label, active, id } = this.props.inputData;
        if (!object) {
            return null;
        }

        return (
            <div className="flex-table-row row">
                <Choose>
                    <When condition={inputType === INPUT_TYPES.CHECKBOX}>
                        <CheckInputClearable
                            className="width-20"
                            name={id}
                            object={{ ...object, [id]: !!object?.[id] ?? active }}
                            onChange={this.handleChange}
                            disabled={inputData.disabled || disabled}
                        />
                        <div className="width-remaining">{label}</div>
                    </When>
                    <When condition={inputType === INPUT_TYPES.TEXTBOX}>
                        <CheckInputClearable className="width-20" name={`checked`} object={{ checked: active }} disabled={true} />
                        <div className="width-remaining">
                            <TextInput
                                className="width-remaining"
                                style={{ textAlign: 'left' }}
                                onChange={this.handleChange}
                                name={id}
                                object={{ ...object, [id]: label }}
                                disabled={inputData.disabled || disabled}
                            />
                        </div>
                    </When>
                </Choose>
            </div>
        );
    }
}
