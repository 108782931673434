import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { PAGED_TABLE_VIEW_SETTINGS } from '../../constants/PagedTableViewSettings';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { reloadTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { getBackgroundTask_server } from '@archinsurance-viki/property-jslib/src/actions/TaskActions';
import BackgroundTaskApp from '../../components/tasks/BackgroundTasksApp';
import { RootState } from '../../store';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import { useAppContext } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { SelectedTaskPanel } from '../../features/AccountTasksPanel';

let FETCHED_TASKS = {};

const mapStateToProps = (state: RootState) => {
    let { backgroundTasks } = state;
    let currentRowId = backgroundTasks.selectedRowId;
    return {
        tableData: backgroundTasks,
        selectedBackgroundTaskId: +currentRowId,
        selectedBackgroundTask: currentRowId !== null ? backgroundTasks.rowData[currentRowId] : null,
        ENV: state.global.ENV,
    };
};

const mapDispatchToProps = (dispatch: DispatchType) => {
    return {
        onReloadTable: () => {
            dispatch(reloadTable(PAGED_TABLES.TASKS));
        },
        onLoadTaskIfNeeded: (taskId?: number, forceLoad?: boolean) => {
            if (taskId && (forceLoad === true || !FETCHED_TASKS[taskId])) {
                FETCHED_TASKS[taskId] = true;
                dispatch(getBackgroundTask_server(taskId, { ...PAGED_TABLES.TASKS }));
            }
        },
    };
};

export default function AccountTasksContainer() {
    const appContext = useAppContext();
    const stateProps = useAppSelector(mapStateToProps);
    const dispatch = useAppDispatch();
    const dispatchProps = mapDispatchToProps(dispatch);
    const history = useHistory();

    const currentSubmissionId = appContext.currentSubmissionId;
    if (!currentSubmissionId) {
        return null;
    }

    const apiParams = {
        submission_id__gte: currentSubmissionId,
        submission_id__lt: currentSubmissionId + 1,
    };
    const apiParamsKey = getApiParamsKey(apiParams);
    const viewSettingsConfig = PAGED_TABLE_VIEW_SETTINGS.backgroundtask_sub_submission;
    const tableConfig = { ...PAGED_TABLES.TASKS, apiParams, apiParamsKey, viewSettingsConfig };
    const currentRowId = stateProps.selectedBackgroundTaskId as number;
    return (
        <BackgroundTaskApp
            tableConfig={tableConfig}
            history={history}
            {...stateProps}
            {...dispatchProps}
            tasksPanel={<SelectedTaskPanel taskId={currentRowId} />}
        />
    );
}
