import api, { selectMutations } from '../apiSlice';
import { PolicyFormCheckbox, PolicyFormsResult } from '../../ts-types/ApiTypes';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { isCancel } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';

type PolicyFormUpdate = Pick<PolicyFormCheckbox, 'applied' | 'field' | 'manually_applied' | '_actions'>;

const policyFormApi = api.injectEndpoints({
    endpoints: builder => ({
        getPolicyForms: builder.query<PolicyFormsResult, { quoteId: number }>({
            query: ({ quoteId }) => ({
                url: 'v2/policy_form_data',
                method: 'GET',
                params: { quote_id: quoteId },
            }),
            providesTags: ['PolicyForms'],
        }),
        updatePolicyForm: builder.mutation<PolicyFormUpdate, { policyCoverageId: number; quoteId: number; data: any }>({
            query: ({ policyCoverageId, data, quoteId }) => ({
                url: `v1/policy_coverage/${policyCoverageId}/update_policy_form_data/`,
                method: 'PUT',
                data,
                cancelRequest: {
                    abortCacheKey: `policy-form-${quoteId}`,
                },
            }),
            invalidatesTags: ['PolicyForms'],
            async onQueryStarted({ quoteId, data: updateData }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    policyFormApi.util.updateQueryData('getPolicyForms', { quoteId }, draft => {
                        const { field } = updateData;
                        const merged = { ...draft.policy_forms.checkboxes_by_field_id[field], ...updateData };
                        Object.assign(draft.policy_forms.checkboxes_by_field_id[field], merged);
                        Object.assign(draft.policy_forms, { ...draft.policy_forms, has_manual_overrides: true });
                    })
                );
                try {
                    await queryFulfilled;
                } catch (e) {
                    if (isCancel(e?.error)) {
                        return;
                    }
                    patchResult.undo();
                }
            },
        }),
        updateEndorsementForm: builder.mutation<PolicyFormUpdate, { transactionId: number; quoteId: number; data: any }>({
            query: ({ transactionId, data, quoteId }) => ({
                url: `v2/account_transaction/${transactionId}/update_endorsement_forms/`,
                method: 'PUT',
                data,
                cancelRequest: {
                    abortCacheKey: `policy-form-${quoteId}`,
                },
            }),
            async onQueryStarted({ quoteId, data: updateData }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    policyFormApi.util.updateQueryData('getPolicyForms', { quoteId }, draft => {
                        const { field, applied } = updateData;
                        const merged = { ...draft.policy_forms.checkboxes_by_field_id[field], endorsement_package: applied };
                        Object.assign(draft.policy_forms.checkboxes_by_field_id[field], merged);
                    })
                );
                try {
                    await queryFulfilled;
                } catch (e) {
                    if (isCancel(e?.error)) {
                        return;
                    }
                    patchResult.undo();
                }
            },
            invalidatesTags: ['PolicyForms'],
        }),
        clearPolicyFormsOverrides: builder.mutation<unknown, { quoteId: number; policyCoverageId: number }>({
            query: ({ policyCoverageId }) => ({ url: `v1/policy_coverage/${policyCoverageId}/clear_overrides/`, method: 'PUT' }),
            invalidatesTags: ['PolicyForms'],
            onQueryStarted({ quoteId }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    policyFormApi.util.updateQueryData('getPolicyForms', { quoteId }, draft => {
                        Object.assign(draft.policy_forms, { ...draft.policy_forms, has_manual_overrides: false });
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
        }),
        deleteBindingRequirement: builder.mutation<unknown, unknown>({
            query: ({ id }) => ({ url: `v1/reports/binding_requirement/${id}/`, method: 'DELETE' }),
            invalidatesTags: () => ['BindingRequirements'],
        }),
    }),
});

export const selectPolicyFormMutationsByEndpoint = createSelector(
    // input selectors
    [selectMutations, (_state: RootState, endpoint: keyof typeof policyFormApi.endpoints) => endpoint],

    // output selector
    (mutations, endpoint) => {
        const resultingObject = {};
        Object.entries(mutations)
            .filter(([_key, mutation]) => mutation?.endpointName === endpoint)
            .forEach(([key, mutation]) => (resultingObject[key] = mutation));
        return resultingObject;
    }
);

export const {
    useGetPolicyFormsQuery,
    useUpdatePolicyFormMutation,
    useUpdateEndorsementFormMutation,
    useClearPolicyFormsOverridesMutation,
    useDeleteBindingRequirementMutation,
} = policyFormApi;
