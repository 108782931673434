import * as React from 'react';

import CheckInput from '@archinsurance-viki/property-jslib/src/components/inputs/CheckInput';

export default class PolicyFormCheckInput extends CheckInput {
    getValueRaw(props: any) {
        return props.object.applied;
    }

    getExtraAfterElement(): any {
        let { name, onChange, _actions, suggested, disabled, object } = this.props;

        if (!_actions || disabled) {
            return null;
        }

        if (_actions.clear_override.visible) {
            let tooltip = undefined;
            if (object.applied !== suggested) {
                tooltip = `Policy settings indicate we should ${suggested ? 'INCLUDE' : 'EXCLUDE'} this form but it is currently ${
                    suggested ? 'excluded' : 'included'
                }. Click to reset to policy default.`;
            }

            return (
                <button
                    title={tooltip}
                    className="tw-px-2 tw-py-1 tw-text-[0.55rem] tw-whitespace-nowrap button x-small blue"
                    disabled={!_actions.clear_override.enabled}
                    onClick={e => {
                        e.preventDefault();
                        onChange && onChange(name, null);
                    }}
                >
                    Clear Override
                </button>
            );
        } else if (_actions.follow_suggested.visible) {
            let tooltip = `Policy settings indicate we should ${suggested ? 'INCLUDE' : 'EXCLUDE'} this form but it is currently ${
                suggested ? 'excluded' : 'included'
            }. Click to accept change.`;

            return (
                <button
                    title={tooltip}
                    className="button x-small blue"
                    disabled={!_actions.follow_suggested.enabled}
                    onClick={e => {
                        e.preventDefault();
                        onChange && onChange(name, null);
                    }}
                >
                    Follow Suggested
                </button>
            );
        } else {
            return null;
        }
    }
}
