import * as React from 'react';
import { connect } from 'react-redux';

import SubmissionSwimTileApp from './SubmissionSwimTileApp';
import { sendSubmissionToScrub } from '../../actions/SwimlanesActions';
// import { updateSubmissionSuccess } from '../../actions/SubmissionLogActions';

type ownPropTypes = Record<string, any>;

type propTypes = {
    onSendSubmissionToScrub: (submissionId: number, isRush: boolean) => Promise<Record<string, any>[]>;
} & ownPropTypes;

const mapStateToProps = (state: Record<string, any>, ownProps: ownPropTypes) => {
    return {};
};

const mapDispatchToProps = (dispatch: any, ownProps: ownPropTypes) => {
    return {
        onSendSubmissionToScrub: (submissionId: number) => {
            return dispatch(sendSubmissionToScrub(submissionId));
            // return dispatch(sendSubmissionToScrub(submissionId, isRush)).then(() => {
            //     return updateSubmissionSuccess(dispatch, submissionId, 'Successfully Sent to Scrub');
            // });
        },
    };
};

class SwimTileContainer extends React.Component<propTypes> {
    render() {
        return <SubmissionSwimTileApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(SwimTileContainer) as any;
export default connected;
