import { combineReducers } from '@reduxjs/toolkit';

import carrierReducer from './carriers';
import accountsReducer from './accounts';
import quoteCheckboxes from './quoteCheckboxes';
import submissionDocumentsMapReducer from './submissionDocumntsMap';
import pagedTableReducer from '@archinsurance-viki/property-jslib/src/reducers/paged_table';
import liteTableReducer from '@archinsurance-viki/property-jslib/src/reducers/lite_table';
import closeSubmissionReducer from './closeSubmission';
import userReducer from '@archinsurance-viki/property-jslib/src/reducers/user';
import keyedReducer from '@archinsurance-viki/property-jslib/src/reducers/keyed_reducer';
import jslibReducers from '@archinsurance-viki/property-jslib/src/reducers';
import { getKeyedReducerKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import pricingReducer from './pricing';
import tasksReducer from './tasks';

import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { LITE_TABLES } from '../constants/LiteTableConfigs';

import { QUOTE_CHECKBOX_ACTIONS } from '../constants/ActionTypes';

import api from '../services/apiSlice';
import { Types } from '../ts-types/viki-types';
import { UserType } from '../ts-types/AppTypes';

const rootReducer = combineReducers({
    ...jslibReducers<Types.FeatureFlags, Types.Constants, UserType>(),
    accountsState: accountsReducer,
    backgroundTasks: pagedTableReducer(PAGED_TABLES.TASKS.tableName),
    regionalCATSummary: pagedTableReducer(PAGED_TABLES.REGIONAL_CAT_SUMMARY.tableName),
    buildingPremiums: pagedTableReducer(PAGED_TABLES.BUILDING_PREMIUMS.tableName),
    blanketLimits: pagedTableReducer(PAGED_TABLES.BLANKET_LIMITS.tableName),
    blanketLimitGroups: liteTableReducer(LITE_TABLES.BLANKET_LIMIT_GROUPS.tableName),
    transactionBuildingPremiums: pagedTableReducer(PAGED_TABLES.TRANSACTION_BUILDING_PREMIUMS.tableName),
    carriers: carrierReducer,
    submissionLog: pagedTableReducer(PAGED_TABLES.SUBMISSION_LOG.tableName),
    buildings: keyedReducer(
        () => pagedTableReducer(PAGED_TABLES.BUILDING_GRID.tableName),
        action => action.tableConfig && action.tableConfig.tableName === PAGED_TABLES.BUILDING_GRID.tableName,
        getKeyedReducerKey
    ),
    buildingCoverages: keyedReducer(
        () => pagedTableReducer(PAGED_TABLES.BUILDING_COVERAGE_GRID.tableName),
        action => action.tableConfig && action.tableConfig.tableName === PAGED_TABLES.BUILDING_COVERAGE_GRID.tableName,
        getKeyedReducerKey
    ),
    buildingLocations: liteTableReducer(LITE_TABLES.BUILDING_LOCATIONS.tableName),
    documents: liteTableReducer(LITE_TABLES.DOCUMENTS.tableName),
    submissionDocumentsMap: submissionDocumentsMapReducer,
    quotes: liteTableReducer(LITE_TABLES.QUOTES.tableName),
    tivGuidelines: liteTableReducer(LITE_TABLES.TIVGUIDELINES.tableName),
    buildingQuoteConditions: keyedReducer(
        () => pagedTableReducer(PAGED_TABLES.BUILDING_QUOTE_CONDITIONS.tableName),
        action => action.tableConfig && action.tableConfig.tableName === PAGED_TABLES.BUILDING_QUOTE_CONDITIONS.tableName,
        getKeyedReducerKey
    ),
    transactions: liteTableReducer(LITE_TABLES.TRANSACTIONS.tableName),
    quoteCheckboxes: keyedReducer(
        () => quoteCheckboxes,
        action => action.idKey && !!QUOTE_CHECKBOX_ACTIONS[action.type],
        action => action.idKey
    ),
    closeSubmissionData: closeSubmissionReducer,
    userState: userReducer,
    [api.reducerPath]: api.reducer,
    pricing: pricingReducer,
    tasks: tasksReducer,
});

export type RTKRootState = ReturnType<typeof rootReducer>;

export default rootReducer;
