/* eslint-disable react/no-danger -- We render markdown in this file, which requires dangerouslySetInnerHtml */
import * as React from 'react';
import { Location } from 'history';

import { getLoadingIndicator } from '@archinsurance-viki/property-jslib/src/utils/ui-helpers';
import { marked } from 'marked';
marked.setOptions({ gfm: true });

import { GlossaryItemType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { getFirstElementInScroll } from '@archinsurance-viki/property-jslib/src/utils/ui-helpers';

export type GlossaryDataTableType = {
    db_table: string;
    description: string;
    fields: GlossaryItemType[];
    model_name: string;
    verbose_name: string;
};

export type GlossaryDataSectionType = {
    description: string;
    section_name: string;
    tables: GlossaryDataTableType[];
    title: string;
    type: string;
};

export type GlossaryDataType = GlossaryDataSectionType[];

type propTypes = {
    glossaryData: GlossaryDataType;
    location: Location;
    updateNav: (update: Record<string, any>) => void;
};

const TABLE_OF_CONTENTS_ID = 'table-of-contents';

export default class BIGlossary extends React.Component<propTypes> {
    state = {
        visibleSectionId: TABLE_OF_CONTENTS_ID,
        visibleTableId: null,
    };
    tablesDiv: React.RefObject<HTMLDivElement> = React.createRef();
    mainScrollDiv: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        if (this.props.glossaryData) {
            setTimeout(this.initializeItems);
        }
    }

    componentDidUpdate(prevProps: Readonly<propTypes>): void {
        if (!prevProps.glossaryData && this.props.glossaryData) {
            this.initializeItems();
        }
    }

    initializeItems = () => {
        const locationId = this.getLocationId();
        if (locationId) {
            const el = window.document.getElementById(locationId);
            if (el) {
                el.scrollIntoView();
            }
        }

        this.updateSubNavItems();
    };

    getLocationId() {
        const { location } = this.props;
        if (location && location.hash && location.hash.length > 1) {
            return /^#(.*)/.exec(location.hash)[1] || null;
        }
        return null;
    }

    updateSubNavItems = () => {
        const locationId = this.getLocationId();
        const { visibleSectionId, visibleTableId } = this.state;
        const subNavItems = this.props.glossaryData.map(section => {
            let sectionNav = {
                name: section.title,
                url: '#' + section.section_name,
                subNavItems: [],
                isActive: (visibleSectionId === null && locationId === section.section_name) || visibleSectionId === section.section_name,
                isAnchor: true,
                isExpanded: true,
            };
            for (let table of section.tables) {
                let tableId = table.db_table;
                let isActive = (visibleTableId === null && tableId === locationId) || visibleTableId === tableId;
                if (isActive) {
                    sectionNav.isActive = true;
                }
                sectionNav.subNavItems.push({
                    name: tableId,
                    url: '#' + tableId,
                    isActive,
                    isAnchor: true,
                });
            }
            return sectionNav;
        });
        const tocSubnav = {
            name: 'Table of Contents',
            isAnchor: true,
            url: '#' + TABLE_OF_CONTENTS_ID,
            isActive: visibleSectionId === TABLE_OF_CONTENTS_ID,
        };
        this.props.updateNav({
            subNavItems: [tocSubnav, ...subNavItems],
        });
    };

    onMainScroll = (e: React.UIEvent<HTMLElement>) => {
        const target = e.currentTarget;
        if (!this.mainScrollDiv.current || !this.tablesDiv.current) {
            return;
        }

        let visibleSectionId = null;
        let visibleTableId = null;

        let block = getFirstElementInScroll(target, this.mainScrollDiv.current);
        if (block.getAttribute('id') === TABLE_OF_CONTENTS_ID) {
            visibleSectionId = TABLE_OF_CONTENTS_ID;
        } else {
            let section = getFirstElementInScroll(target, this.tablesDiv.current);
            if (section) {
                visibleSectionId = section.getAttribute('id');

                let table = getFirstElementInScroll(target, section);
                if (table) {
                    visibleTableId = table.getAttribute('id');
                }
            }
        }

        if (visibleSectionId !== this.state.visibleSectionId || visibleTableId !== this.state.visibleTableId) {
            this.setState({
                visibleTableId,
                visibleSectionId,
            });
            setTimeout(this.updateSubNavItems);
        }
    };

    renderTocTableDefs = (table: GlossaryDataTableType) => {
        return (
            <React.Fragment key={table.db_table}>
                <dt className="toc-table">
                    <a href={'#' + table.db_table}>
                        <span className="db-label">{table.db_table}</span> ({table.verbose_name})
                    </a>
                </dt>
                <dd dangerouslySetInnerHTML={{ __html: marked(table.description) }} />
            </React.Fragment>
        );
    };

    renderTocSection = (section: GlossaryDataSectionType) => {
        return (
            <section className="toc-section" key={section.section_name}>
                <h2>
                    <a href={'#' + section.section_name}>{section.title}</a>
                </h2>
                <If condition={section.description && section.description.length}>
                    <p className="description" dangerouslySetInnerHTML={{ __html: marked(section.description) }} />
                </If>
                <dl className="toc-section-tables">{section.tables.map(this.renderTocTableDefs)}</dl>
            </section>
        );
    };

    renderTableRow = (rowData: GlossaryItemType) => {
        return (
            <tr key={rowData.field_name}>
                <td>{rowData.field_name}</td>
                <td>{rowData.type}</td>
                <td dangerouslySetInnerHTML={{ __html: marked(rowData.help_text || '') }} />
                <td>{rowData.fk_table_db_table}</td>
                <td>{rowData.nullable}</td>
                <td>{rowData.primary_key ? 'YES' : ''}</td>
            </tr>
        );
    };

    renderTableBlock = (table: GlossaryDataTableType) => {
        return (
            <div className="table-section" key={table.db_table} id={table.db_table}>
                <h2>
                    <span className="db-label">{table.db_table}</span> ({table.verbose_name})
                    <a className="header-link" href={'#' + table.db_table} title="Permalink to this table">
                        {' ¶'}
                    </a>
                </h2>
                <If condition={table.description && table.description.length}>
                    <p className="description" dangerouslySetInnerHTML={{ __html: marked(table.description) }} />
                </If>
                <table className="glossary-table">
                    <tbody>
                        <tr key="header">
                            <th>Db Field Name</th>
                            <th>SQL Type</th>
                            <th>Description</th>
                            <th>Related Model</th>
                            <th>Nullable</th>
                            <th>Primary Key</th>
                        </tr>
                        {table.fields.map(this.renderTableRow)}
                    </tbody>
                </table>
                <div className="divider-line dl-margin-default" />
            </div>
        );
    };

    renderSection = (section: GlossaryDataSectionType) => {
        return (
            <div className="table-section" key={section.section_name} id={section.section_name}>
                {section.tables.map(this.renderTableBlock)}
            </div>
        );
    };

    renderContent(glossaryData) {
        return (
            <div className="content-scroll definitions-table" onScroll={this.onMainScroll} ref={this.mainScrollDiv}>
                <div id={TABLE_OF_CONTENTS_ID} className="glossary-toc table-of-contents">
                    {glossaryData.map(this.renderTocSection)}
                </div>
                <div className="divider-line dl-margin-default" />
                <div className="glossary-items item-definitions" ref={this.tablesDiv}>
                    {glossaryData.map(this.renderSection)}
                </div>
            </div>
        );
    }

    renderLoading() {
        return getLoadingIndicator();
    }

    render() {
        const { glossaryData } = this.props;

        const content = glossaryData && glossaryData.length ? this.renderContent(glossaryData) : this.renderLoading();

        return <div className="main-content-center">{content}</div>;
    }
}
