import { useState } from 'react';
import { SearchInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/SearchInput';
import { DefaultAccountLayout } from '../components/common/DefaultLayout';
import { useSearchAccountsQuery } from '../services/endpoints/account';
import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import { Link, Redirect } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { useGetConstantsQuery } from '@archinsurance-viki/property-jslib/src/services/endpoints/environment';
import { AccountSearchResultType } from '../ts-types/DataTypes';
import { useAppSelector } from '../hooks/redux';

const ResultField = ({ label, children }: { label: string; children: React.ReactNode }) => {
    if (children === null || children === undefined) {
        return null;
    }
    return (
        <div className="tw-flex tw-gap-1 tw-items-baseline">
            <label className="tw-select-none tw-text-xs tw-font-semibold tw-text-grey tw-uppercase tw-tracking-wider">{`${label}:`}</label>
            <div className="tw-text-sm">{children}</div>
        </div>
    );
};

const ResultLink = ({ url, title }: { url: string; title: string }) => (
    <Link to={url}>
        <BasicButton className="tw-px-1.5 tw-py-1">{title}</BasicButton>
    </Link>
);

const AccountResult = ({ title, attributes, sublinks }: AccountSearchResultType) => (
    <div className="tw-flex tw-flex-col tw-gap-1 tw-border-2 tw-border-grey-medium-light tw-p-2">
        <h3>{title}</h3>
        <div className="tw-flex tw-flex-wrap tw-gap-x-4 tw-flex-1">
            {attributes?.map(({ title: attributeTitle, value, url }) => (
                <ResultField label={attributeTitle}>{url ? <Link to={url}>{value}</Link> : value}</ResultField>
            ))}
        </div>
        <div className="tw-border-b tw-border-grey-border" />
        <div className="tw-flex tw-gap-3 tw-mt-1">
            {sublinks?.map(({ title: sublinkTitle, url }) => (
                <ResultLink url={url} title={sublinkTitle} />
            ))}
        </div>
    </div>
);

const ERROR_MESSAGE = 'There was an error while trying to search.';
const NO_RESULTS = 'Results not found with this search criteria.';

export const VikiSearch = () => {
    const [query, setQuery] = useQueryParams({
        search: StringParam,
    });
    const [searchText, setSearchText] = useState(query.search ?? '');
    const { currentData, isError, isSuccess, refetch } = useSearchAccountsQuery(
        { search: query.search },
        { skip: !query.search, refetchOnMountOrArgChange: 5 }
    );
    const { data: constantsData } = useGetConstantsQuery({});
    const siteAcronym = useAppSelector(state => state.global.ENV.SITE_ACRONYM);

    const vikiSearchEnabled = (constantsData?.FEATURE_FLAGS as Record<string, boolean>)?.viki_search ?? false;
    if (constantsData && !vikiSearchEnabled) {
        return <Redirect to="/" />;
    }

    const searchResults =
        currentData?.map(({ title, attributes, sublinks }) => <AccountResult key={title} title={title} attributes={attributes} sublinks={sublinks} />) ?? null;

    return (
        <DefaultAccountLayout topPanelHeader={`${siteAcronym} Search`} hasActionPanel={false} hideBackgroundTask>
            <div className="tw-flex tw-flex-col tw-gap-6 tw-py-2">
                <div className="tw-flex tw-flex-col tw-gap-2 tw-max-w-[900px]">
                    <SearchInput
                        className="tw-min-w-[465px]"
                        searchValue={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        onSearch={() => {
                            setQuery({ search: searchText ? searchText : undefined });
                            refetch();
                        }}
                        placeholder="Enter a search term to find accounts and submissions"
                        onClear={() => {
                            setSearchText('');
                            setQuery({ search: undefined });
                        }}
                        searchOnEnter
                    />
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2 tw-max-w-[900px] tw-h-[calc(100vh-150px)] tw-pr-2 tw-overflow-auto">
                    {isError && ERROR_MESSAGE}
                    {isSuccess && searchResults?.length === 0 ? NO_RESULTS : searchResults}
                </div>
            </div>
        </DefaultAccountLayout>
    );
};
