import { useEffect, useCallback } from 'react';

import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';

import BlanketLimitsApp from './BlanketLimitsApp';
import { AppContextType } from '../../ts-types/AppTypes';
import { RootState } from '../../store';
import API from '@archinsurance-viki/property-jslib/src/utils/API';
import { getBlanketLimits_server } from '../../actions/QuoteActions';
import { persistRowData_server, toggleAllRowsChecked } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { merge } from 'lodash';

const mapStateToProps = (state: RootState, appContext: AppContextType) => {
    const { blanketLimits, blanketLimitGroups } = state;
    const { currentQuoteId } = appContext;

    const apiParams = {
        coverage_option_id: currentQuoteId,
    };
    const blanketLimitGroupChoices = blanketLimitGroups.rows.map(id => {
        const groupData = blanketLimitGroups.rowData[id];
        return {
            display: groupData.description,
            value: id,
        };
    });

    let tableConfig: TableConfigType = { ...PAGED_TABLES.BLANKET_LIMITS, apiParams };
    tableConfig = merge(tableConfig, { tableGlossary: { bcli: { choices: blanketLimitGroupChoices } } });

    return {
        tableConfig,
        tableData: blanketLimits,
        blanketLimitGroups,
        extraCellRenderData: {
            blanketLimitGroupChoices, // put here for re-render purposes
        },
        oldWebsite: state.global.oldWebsite,
    };
};

export default function BlanketLimitsContainer() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const stateProps = useAppSelector(state => mapStateToProps(state, appContext));
    const dispatch = useAppDispatch();
    const onGetBlanketLimitGroups = useCallback(
        (quoteId: number) => {
            return dispatch(getBlanketLimits_server(quoteId));
        },
        [dispatch]
    );

    useEffect(() => {
        if (appContext.currentQuoteId) {
            onGetBlanketLimitGroups(appContext.currentQuoteId);
        }
    }, [appContext.currentQuoteId, onGetBlanketLimitGroups]);

    const createBlanketLimitGroup = async (description: string) => {
        const { tableData, tableConfig } = stateProps;
        const { currentQuoteId, currentSubmissionId } = appContext;

        const { data } = await API.doRequest(
            API.endpoints.blanketCoverage.create,
            {
                id: currentQuoteId,
                params: {
                    description,
                    submission_id: currentSubmissionId,
                    quote_id: currentQuoteId,
                },
            },
            dispatch
        );

        const checkedRows = Object.keys(tableData.checkedRows).map(id => Number(id));

        const request = {
            data: { blanket_coverage_id: data.id },
            pks: checkedRows,
        };

        dispatch(persistRowData_server(tableConfig, { request })).then(() => {
            appContextFns.onValidateSubmission(currentSubmissionId, currentQuoteId);
            onGetBlanketLimitGroups(currentQuoteId);
        });
        dispatch(toggleAllRowsChecked(tableConfig, []));
    };

    const onPersistedData = ({ request }) => {
        if (request.data.blanket_coverage_id !== undefined && appContext.currentQuoteId) {
            // updated group, refetch groups
            appContextFns.onValidateSubmission(appContext.currentSubmissionId, appContext.currentQuoteId);
            onGetBlanketLimitGroups(appContext.currentQuoteId);
        }
    };

    return (
        <BlanketLimitsApp
            currentTransaction={appContext.currentTransaction}
            currentSubmission={appContext.currentSubmission}
            currentQuoteId={appContext.currentQuoteId}
            oldWebsite={stateProps.oldWebsite}
            tableData={stateProps.tableData}
            tableConfig={stateProps.tableConfig}
            extraCellRenderData={stateProps.extraCellRenderData}
            appContext={appContext}
            blanketLimitGroups={stateProps.blanketLimitGroups}
            onPersistedData={onPersistedData}
            createBlanketLimitGroup={createBlanketLimitGroup}
        />
    );
}
