import React, { useEffect } from 'react';

import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import { LITE_TABLES } from '../../constants/LiteTableConfigs';
import { getTivGuidelines_server } from '../../actions/QuoteActions';
import TivGuidelinesApp from './TivGuidelinesApp';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

export default function TivGuidelinesPage() {
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const dispatch = useAppDispatch();
    const tableData = useAppSelector(state => state.tivGuidelines);

    useEffect(() => {
        if (appContext.currentQuoteId) {
            dispatch(getTivGuidelines_server(appContext.currentQuoteId));
        }
    }, [appContext.currentQuoteId, dispatch]);

    // return only rows relevant to current quote
    const filterTableData = (): LiteTableDataType => {
        const rowData = tableData.rowData;
        return { ...tableData, rows: tableData.rows.filter(row_id => rowData[row_id].quote_id === appContext.currentQuoteId) };
    };

    return <TivGuidelinesApp tableData={filterTableData()} tableConfig={LITE_TABLES.TIVGUIDELINES} appContextFns={appContextFns} appContext={appContext} />;
}
