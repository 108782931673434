import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import { BodyCell, HeaderCell, HeaderRow, Table, TableRow } from '@archinsurance-viki/property-jslib/src/components/table/Table';
import { ThinBottomPanelWrapper } from '@archinsurance-viki/property-jslib/src/containers/panels/ThinBottomPanelWrapper';
import { usePrevUpdate } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { useState } from 'react';
import useQuoteId from '../hooks/quotes';
import { useAppSelector } from '../hooks/redux';
import { useSubmissionId } from '../hooks/submissions';
import { useValidateSubmissionQuery } from '../services/endpoints/submission';
import { getValidationsFromData, renderValidationsTable, VALIDATING } from '../utils/render-helpers/quotes/quote-validations-helper';

export const BottomValidationsPanel = ({ openOnValidationErrors = false }: { openOnValidationErrors?: boolean }) => {
    const submissionId = useSubmissionId();
    const quoteId = useQuoteId();
    const oldWebsite = useAppSelector(state => state.global.oldWebsite);
    const { data, isFetching } = useValidateSubmissionQuery({ submissionId, quoteId }, { skip: !submissionId || !quoteId });

    const { errors, warnings } = data ? getValidationsFromData(data, oldWebsite) : { errors: [], warnings: [] };
    const [isValidationsOpen, setIsValidationsOpen] = useState(false);
    const hasValidationErrors = errors.length > 0;
    usePrevUpdate(hasValidationErrors, prevHasValidationErrors => {
        if (!openOnValidationErrors) {
            return;
        }
        if (hasValidationErrors && !prevHasValidationErrors) {
            setIsValidationsOpen(true);
        } else if (!hasValidationErrors && prevHasValidationErrors) {
            setIsValidationsOpen(false);
        }
    });

    const activeTab = isValidationsOpen ? 0 : null;

    const onTabClick = () => {
        setIsValidationsOpen(prev => !prev);
    };

    const tabTitle = (
        <span className="tw-flex tw-items-baseline tw-gap-2 tw-px-1">
            <span className="tw-flex tw-gap-1 tw-text-xs">
                {`Error${errors.length !== 1 ? 's' : ''}`}
                <div className="tw-text-red">{errors.length}</div>
            </span>
            <span className="tw-flex tw-gap-1 tw-text-xs">
                {`Warning${errors.length !== 1 ? 's' : ''}`}
                <div className="tw-text-orange">{warnings.length}</div>
            </span>
        </span>
    );
    return (
        <ThinBottomPanelWrapper
            tabs={[isFetching ? 'Validations' : tabTitle]}
            activeTabIndex={activeTab}
            onTabSelect={onTabClick}
            render={() => {
                return (
                    <div className="tw-flex tw-flex-col tw-gap-2 tw-pb-8 tw-overflow-auto tw-w-full">
                        {isFetching ? (
                            <div className="tw-h-[400px]">{VALIDATING.content}</div>
                        ) : (
                            <div className={'grid-layout gl-2 errors-wrapper panel-area'}>
                                <div className="errors-warnings-content">
                                    <h1>
                                        <span>{errors.length}</span> Errors
                                    </h1>
                                    {renderValidationsTable(errors, null, 'ERROR', 'ERROR')}
                                </div>
                                <div className="errors-warnings-content warnings">
                                    <h1>
                                        <span>{warnings.length}</span> Warnings
                                    </h1>
                                    {renderValidationsTable(warnings, null, 'WARNING', 'WARNING')}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }}
        />
    );
};

export const ValidationsTable = ({ validations }: { validations: { message: string; onClick?: () => void; buttonTitle?: string }[] }) => (
    <div className="tw-flex-1 tw-overflow-auto">
        <Table className="tw-grid-cols-[1fr_110px] tw-w-[800px]">
            <HeaderRow>
                <HeaderCell>Message</HeaderCell>
                <HeaderCell />
            </HeaderRow>
            {validations.map(({ message, buttonTitle, onClick }, i) => (
                <TableRow key={`${message}${i}`}>
                    <BodyCell>
                        <div className="tw-flex-1 tw-whitespace-normal">{message}</div>
                    </BodyCell>
                    <BodyCell>
                        <BasicButton className={onClick ? 'tw-p-1' : 'tw-invisible'} onClick={() => onClick()}>
                            {buttonTitle ? buttonTitle : 'Link'}
                        </BasicButton>
                    </BodyCell>
                </TableRow>
            ))}
        </Table>
    </div>
);
