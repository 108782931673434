import { useRef } from 'react';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';

import { ActionPanelType, ApiParamsType, ExtraDataType, TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { AppContextType } from '../../ts-types/AppTypes';
import { RootState } from '../../store';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';
import { VIKITableWrapperImperativeHandle } from '@archinsurance-viki/property-jslib/src/containers/VIKITableWrapper';
import { UiStateType, updateUi } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';
import { Paths, TypeFromPath } from '@archinsurance-viki/property-jslib/src/ts-types/util';
import { getQueryStringParam } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

const mapStateToProps = (state: RootState, appContext: AppContextType, useTransactionVersion: boolean) => {
    let tableConfig: TableConfigType = useTransactionVersion ? PAGED_TABLES.TRANSACTION_BUILDING_PREMIUMS : PAGED_TABLES.BUILDING_PREMIUMS;

    const { uiState } = state;
    const { currentSubmission, currentQuoteId, currentTransactionId } = appContext;

    const extraCellRenderData: ExtraDataType = {};
    extraCellRenderData.pricing = uiState.pricing;
    if (currentSubmission && currentSubmission.ventus_commission_rate) {
        extraCellRenderData.ventus_commission_rate = Number(currentSubmission.ventus_commission_rate);
    }

    const apiParams: ApiParamsType = {};
    if (useTransactionVersion) {
        apiParams.account_transaction_id = currentTransactionId;
    } else {
        apiParams.coverage_option_id = currentQuoteId;
    }

    tableConfig = { ...tableConfig, apiParams };

    return {
        extraCellRenderData,
        tableConfig,
    };
};

type PricingFields = UiStateType['pricing'];

const BuildingPremiumPage = ({ isTransactionRoute }: { isTransactionRoute?: boolean }) => {
    const tableWrapperRef = useRef<VIKITableWrapperImperativeHandle>();
    const dispatch = useAppDispatch();
    const pricing = useAppSelector(state => state.uiState.pricing);
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const { tableConfig, extraCellRenderData } = useAppSelector(state => mapStateToProps(state, appContext, isTransactionRoute));
    const { currentQuoteId, currentTransactionId } = appContext;

    const onRowSelected = buildingId => {
        appContextFns.onNavigate({ buildingId });
    };

    const handleToggle = <Path extends Paths<PricingFields>>(toggleProp: Path) => {
        return (value: TypeFromPath<PricingFields, Path>) => {
            dispatch(updateUi({ data: { pricing: { [toggleProp]: value } } }));
        };
    };

    const handleChargedTechnicalToggle = (tab: string) => {
        tableWrapperRef.current.onSelectTab(tab);
    };

    if (!currentQuoteId && !currentTransactionId) {
        return null;
    }

    let extraSections: ActionPanelType['extraSections'] = [];

    const selectedTab = getQueryStringParam('tab');
    const isCharged = selectedTab === 'charged';
    const actions = [];
    const { viewSettingsConfig } = tableConfig;
    if (viewSettingsConfig.type === 'tabs' && viewSettingsConfig.tabsList.length) {
        // transaction version doesn't have technical/charded switches
        actions.push({
            action: handleChargedTechnicalToggle,
            id: 'charged-technical',
            actionFnData: isCharged ? 'technical' : 'charged',
            toggle: [
                { label: 'Charged Premium', active: isCharged, value: 'charged' },
                { label: 'Technical Premium', active: !isCharged, value: 'technical' },
            ],
        });
    }
    actions.push({
        action: handleToggle('gross'),
        id: 'gross-net',
        actionFnData: !pricing.gross,
        toggle: [
            { label: 'Gross', active: pricing.gross, value: true },
            { label: 'Net', active: !pricing.gross, value: false },
        ],
    });
    actions.push({
        action: handleToggle('dollar'),
        id: 'dollar-rate',
        actionFnData: !pricing.dollar,
        toggle: [
            { label: 'Dollar', active: pricing.dollar, value: true },
            { label: 'Rate', active: !pricing.dollar, value: false },
        ],
    });

    extraSections.push({
        title: 'Grid Control',
        actions: actions,
    });

    return (
        <PagedTableContainer
            tableConfig={tableConfig}
            extraCellRenderData={extraCellRenderData}
            onRowSelected={onRowSelected}
            selectedRowId={appContext.currentBuildingId ?? null}
            tableWrapperRef={tableWrapperRef}
            hasSearchPanel={true}
            editingDisabled={false}
            panelActions={{ extraSections }}
            tasksPanel={<AccountTasksPanel />}
        />
    );
};

export default BuildingPremiumPage;
