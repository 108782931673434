import cx from 'classnames';
import _ from 'lodash';
import useQuoteId from '../../../hooks/quotes';
import { useSubmissionId, useSubmissionIsEditable } from '../../../hooks/submissions';
import { useSubmissionDataQuery } from '../../../services/endpoints/submission';
import { formatQuoteDescription, usePolicyTermsContext } from '../utils/coverage-utils';

export const BulkEditCoverageOptions = () => {
    const { selectedQuoteIds, handleSelectedQuoteChange, setSelectedQuoteIds } = usePolicyTermsContext();
    const currentQuoteId = useQuoteId();
    const currentSubmissionId = useSubmissionId();
    const isEditable = useSubmissionIsEditable();
    const { data: submissionData } = useSubmissionDataQuery({ id: currentSubmissionId }, { skip: !currentSubmissionId });
    const submissionQuoteIds: number[] = submissionData?.quotes?.map(({ id }) => id) ?? [];
    const isAllCoverageActive = _.isEqual([...submissionQuoteIds].sort(), [...selectedQuoteIds].sort());

    const handleClick = (id: number) => handleSelectedQuoteChange(id);

    const handleAllClick = () => {
        setSelectedQuoteIds(isAllCoverageActive ? [currentQuoteId] : submissionQuoteIds);
    };

    const renderCoverageOptions = () => {
        return (
            submissionData?.quotes?.map(quote => (
                <div key={quote.id} className="flex fd-r">
                    <a
                        className={cx('select-cell material-icons tw-select-none', {
                            active: selectedQuoteIds.includes(quote.id),
                            'hover:tw-text-grey-checkbox': !selectedQuoteIds.includes(quote.id) && !isEditable,
                        })}
                        title="Select"
                        onClick={() => {
                            if (quote.id === currentQuoteId || !isEditable) {
                                return;
                            }
                            handleClick(quote.id);
                        }}
                    >
                        done
                    </a>
                    <div className="data-cell">
                        <span>{formatQuoteDescription(quote)}</span>
                    </div>
                </div>
            )) ?? []
        );
    };

    return (
        <div className="flex overflow-y-auto h-100 flex-1">
            <div className="grid-layout settings panelgeneral h-100 w-100">
                <div className="flex fd-r table-header">
                    <a
                        className={cx('select-cell material-icons tw-select-none', {
                            active: isAllCoverageActive,
                            'hover:tw-text-grey-checkbox': !isEditable,
                        })}
                        title="Select All"
                        onClick={() => {
                            if (isEditable) {
                                handleAllClick();
                            }
                        }}
                    >
                        done
                    </a>
                    <div className="data-cell">Bulk Edit Coverage Options</div>
                </div>
                {renderCoverageOptions()}
            </div>
        </div>
    );
};
