import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';
import api, { useQuotePolicyCoverageQuery, useQuoteFinalPremiumQuery } from '../services/apiSlice';
import { useAppSelector } from './redux';
import { setQuoteDirty } from '../reducers/pricing';
import { FinalPremiumType, PolicyCoverageType } from '../ts-types/ApiTypes';
import { FinalCarrierData } from '../ts-types/DataTypes';
import { getCurrentQuoteId } from '../utils/state-helpers';

export const useQuoteId = (): number | null => {
    const params = useParams();
    return useAppSelector(state => getCurrentQuoteId(state, params));
};

export default useQuoteId;

export const useQuoteFinalPremium = () => {
    const quoteId = useQuoteId();
    const currentQuote = useAppSelector(state => state.quotes.rowData[quoteId] ?? null);

    const { fallbackFinalPremium, fallbackFinalCarrierData } = useMemo(
        () => ({
            fallbackFinalPremium: currentQuote?.final_premium as FinalPremiumType,
            fallbackFinalCarrierData: (currentQuote?.final_carrier_data as any)?.filter(carrier => carrier.pool_name === 'VENTUS') as FinalCarrierData[],
        }),
        [currentQuote?.final_carrier_data, currentQuote?.final_premium]
    );
    const { finalPremium, finalCarrierData, ...result } = useQuoteFinalPremiumQuery(
        { quoteId },
        {
            skip: !quoteId,
            selectFromResult: result => ({
                ...result,
                finalCarrierData: result?.data?.final_carrier_data ? { ...result.data.final_carrier_data } : {},
                finalPremium: result?.data?.final_premium,
            }),
        }
    );
    return {
        finalPremium: finalPremium ?? fallbackFinalPremium ?? null,
        finalCarrierData: finalCarrierData ?? fallbackFinalCarrierData ?? null,
        ...result,
    };
};

export const useQuotePolicyCoverage = () => {
    const quoteId = useQuoteId();
    const currentQuote = useAppSelector(state => state.quotes.rowData?.[quoteId] ?? null);
    const { policyCoverage, ...result } = useQuotePolicyCoverageQuery(
        { quoteId },
        {
            skip: !quoteId,
            selectFromResult: result => ({
                ...result,
                policyCoverage: result?.data?.policy_coverage,
            }),
        }
    );
    return {
        policyCoverage: policyCoverage ?? (currentQuote?.policy_coverage as PolicyCoverageType) ?? null,
        ...result,
    };
};

export const usePricingIsDirty = (quoteId: number): [boolean, () => void] => {
    const dispatch = useDispatch();
    const pricingIsDirty = useAppSelector(state => !!state.pricing.dirty[quoteId]);
    const invalidateQuoteCache = useCallback(
        (invalidateQuote?: boolean) => {
            const tag: any = invalidateQuote ? { type: 'Quote', id: quoteId } : { type: 'FinalPremium', id: quoteId };
            dispatch(api.util.invalidateTags([tag, { type: 'QuoteValidation', id: quoteId }]));
        },
        [dispatch, quoteId]
    );
    // this event callback will be triggered on every submission.reprice_quote()
    // TODO: use redis to cache parameters for reprice to determine if results are different
    usePusherEvent(`quote-${quoteId}`, 'dirty', (message: any, { socketId }) => {
        if (socketId && message?.submitter_pusher_socket_id === socketId) {
            invalidateQuoteCache();
        } else {
            dispatch(setQuoteDirty(quoteId));
        }
    });
    const refreshPricing = () => {
        invalidateQuoteCache(true);
    };
    return [pricingIsDirty, refreshPricing];
};
