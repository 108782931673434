import _ from 'lodash';

export const sortSubmissionsForSwimlanes = (params: Record<string, any>, datafromApi: Record<string, any>[], state: Record<string, any>) => {
    const stateData = datafromApi;
    const status = params['status'];
    const is_rush = params['is_rush'];
    const has_active_quotes = params['has_active_quotes'];
    const filteredData = state.filteredData;
    const data = (filteredData.length > 0 ? filteredData : stateData).filter((d: any) => {
        let filterRet = d.status === status;
        if (!d.policy_inception_date) {
            return false;
        }
        if (!_.isNull(is_rush)) {
            filterRet = filterRet && d.is_rush === is_rush;
        }
        if (!_.isNull(has_active_quotes)) {
            filterRet = filterRet && d.has_active_quotes === has_active_quotes;
        }
        return filterRet;
    });
    return data.sort((b: any, c: any) => {
        if (b.pinned && !c.pinned) return -1;
        else if (!b.pinned && c.pinned) return 1;
        else if (b.id > c.id) return 1;
        else if (b.id < c.id) return -1;
        else return 0;
    });
};
