import * as React from 'react';

import UpdateCancellationEffectiveDate from '../containers/UpdateCancellationEffectiveDate.container';
import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { AppContextFnsType, AppContextType } from '../../../../ts-types/AppTypes';
import { ActionHelperFnsType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

type bodyPropTypes = {
    onClose: (x?: boolean) => any;
    modalProps: Record<string, any>;
    appContextFns: AppContextFnsType;
    appContext: AppContextType;
    actionHelperFns: ActionHelperFnsType;
};

class UpdateCancellationEffectiveDateBody extends React.Component<bodyPropTypes> {
    constructor(props: bodyPropTypes) {
        super(props);
    }

    render() {
        const { modalProps, ...rest } = this.props;
        return <UpdateCancellationEffectiveDate {...rest} {...modalProps} />;
    }
}

let modalTypeObj = {
    Body: UpdateCancellationEffectiveDateBody,
    Header: DefaultHeaderStatic('Update Cancellation Effective Date'),
    className: 'update-cancellation-effective-date-modal',
};

registerType(VIRA_MODAL_TYPES.UPDATE_CANCELLATION_EFFECTIVE_DATE, modalTypeObj);
export default modalTypeObj;
