import * as React from 'react';

import { VIRA_MODAL_TYPES } from '../../../../constants/Modal';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import AdditionalNamedInsuredsContainer from '../containers/AdditionalNamedInsuredsContainer';
import { DefaultHeader } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

type headerPropTypes = {
    modalData: Record<string, any>;
};

export function AdditionalNamedInsuredsModalHeader(props: headerPropTypes) {
    let { modalData } = props;
    let title = modalData.additionalNamedInsured && modalData.additionalNamedInsured.id ? 'Edit Additional Named Insured' : 'Add Additional Named Insured';
    return <DefaultHeader title={title} />;
}

type bodyPropTypes = {
    modalData: Record<string, any>;
    onClose: (x?: boolean) => any;
};

class AdditionalNamedInsuredsModalBody extends React.Component<bodyPropTypes> {
    constructor(props: bodyPropTypes) {
        super(props);
    }

    render() {
        return <AdditionalNamedInsuredsContainer {...this.props} />;
    }
}

let modalTypeObj = {
    Body: AdditionalNamedInsuredsModalBody,
    Header: AdditionalNamedInsuredsModalHeader,
    className: 'additional-structures-modal',
};
registerType(VIRA_MODAL_TYPES.ADDITIONAL_NAMED_INSURED, modalTypeObj);
export default modalTypeObj;
