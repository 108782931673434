import * as React from 'react';

import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';

import { TableConfigType, LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { AppContextFnsType, AppContextType } from '../../ts-types/AppTypes';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../submissionlog/Helpers';
import { getTaskSubtitles } from '../../utils/task-helpers';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

type propTypes = {
    tableData: LiteTableDataType;
    tableConfig: TableConfigType;
    appContextFns: AppContextFnsType;
    appContext: AppContextType;
};

export default class TivGuidelinesApp extends React.Component<propTypes> {
    render() {
        let { currentSubmission, currentQuoteId } = this.props.appContext;

        if (!currentSubmission || !currentQuoteId) {
            return null;
        }

        return (
            <LiteTableContainer
                tableData={this.props.tableData}
                tableConfig={this.props.tableConfig}
                allowsDragging={false}
                hasActionPanel={false}
                editingDisabled={true}
                panelActions={calculateRowActions(this.props, ACTION_PANEL_LOCATIONS.TIV_GUIDELINES)}
                getTaskSubtitles={getTaskSubtitles}
                tasksPanel={<AccountTasksPanel />}
            />
        );
    }
}
