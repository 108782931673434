import _ from 'lodash';
import {
    BuildingPremiumResultType,
    BuildingPremiumQueryOptions,
    CarriersQueryOptions,
    CarriersResult,
    QuoteResult,
    RepriceResult,
    PEEQueryResult,
    PEEQueryOptions,
    MetricsResult,
    MetricsQueryOptions,
    HazardHubDataResult,
    HazardHubDataRequest,
    ComparableSubmissionsOptions,
    ComparableSubmissionsResult,
    CatModelsResult,
    RunCatModelsQueryOptions,
    SubmitCatModelsQueryOptions,
    PolicyCoverageType,
    FinalPremiumType,
    MarginPMLResultType,
    MarginalPMLQueryOptions,
    SetOverridesArgs,
    RegionalCATSummaryChoiceResultType,
} from '../ts-types/ApiTypes';
import { isUpdateVentusCarriersOverrides } from '../ts-types/typeguard-utils';
import { AllTagTypes, commonApi } from '@archinsurance-viki/property-jslib/src/services/commonApi';
import { buildIncludesExcludesParams, updateCarrierOverridesObject, wasInvalidPricing, wasNotAbleToPrice } from './utils';
import { isCancel } from '@archinsurance-viki/property-jslib/src/ts-types/typeguard-utils';
import { RootState } from '../store';
import { Draft, createSelector } from '@reduxjs/toolkit';
import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { FinalCarrierData } from '../ts-types/DataTypes';
import { UPDATE_ROW_DATA } from '../constants/ActionTypes';
import { updateDataByPath } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { clearChangedPremium, getChangedPremium, selectFinalPremium, setChangedPremium } from '../reducers/pricing';

type QuotePolicyCoverageResponse = QuoteResult & { policy_coverage: PolicyCoverageType };

const apiWithTags = commonApi.enhanceEndpoints({
    addTagTypes: [
        'BulkCatModelJob',
        'TargetMultiple',
        'CarrierData',
        'FinalPremium',
        'PolicyCoverage',
        'PolicyCoverageValidation',
        'PolicyForms',
        'Quote',
        'QuoteValidation',
        'Submission',
        'SubmissionOverview',
        'Account',
        'QuoteConditions',
        'BindingRequirements',
        'CatAnalysis',
        'BuildingCoverage',
        'BuildingGroupCoverage',
    ],
});

export type VikiApiTags = AllTagTypes<typeof apiWithTags>;

export const createTagsFromResult = (result: unknown, successTags: TagDescription<VikiApiTags>[], failureTags: TagDescription<VikiApiTags>[] = []) =>
    result ? successTags : failureTags;

const api = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        // TODO: For better organization, we can split the carrier update off from setFinalPremiumOverrides and move it to endpoints/carrier.ts
        carrierPools: builder.query<CarriersResult<any>, CarriersQueryOptions>({
            query: ({ quoteId }) => ({
                url: `v1/quotes/${quoteId}/carrier_pools/`,
                method: 'get',
            }),
            providesTags: result =>
                result?.quote_id
                    ? [
                          { type: 'Quote', id: result.quote_id },
                          { type: 'CarrierData', id: result.quote_id },
                      ]
                    : [],
        }),
        toggleLayeredCarrier: builder.mutation<unknown, { quoteId: number; isLayered: boolean }>({
            query: ({ quoteId, isLayered }) => ({
                url: `v1/quotes/${quoteId}/toggle_layered_carrier_participation/`,
                method: 'PUT',
                data: { layered_carrier_participation: isLayered },
            }),

            async onQueryStarted({ quoteId, isLayered }, { dispatch, queryFulfilled }) {
                const pcPatchResult = dispatch(
                    api.util.updateQueryData('quoteFinalPremium', { quoteId }, draft => {
                        draft.is_carrier_layered = isLayered;
                    })
                );
                try {
                    await queryFulfilled;
                } catch (e) {
                    pcPatchResult.undo();
                    throw e;
                }
            },
            invalidatesTags: (result, _err, { quoteId }) =>
                result
                    ? [
                          { type: 'Quote', id: quoteId },
                          { type: 'CarrierData', id: quoteId },
                      ]
                    : [],
        }),
        getMarginalPmls: builder.query<MarginPMLResultType, MarginalPMLQueryOptions>({
            query: ({ quoteId }) => {
                return {
                    url: `v2/marginal_pmls?quote_id=${quoteId}`,
                    method: 'get',
                };
            },
        }),
        getBuildingPremium: builder.query<BuildingPremiumResultType, BuildingPremiumQueryOptions>({
            query: ({ quoteId }) => {
                return {
                    url: `v1/building_premium/?coverage_option_id=${quoteId}`,
                    method: 'get',
                };
            },
        }),
        setFinalPremiumOverrides: builder.mutation<
            { final_premium: FinalPremiumType; [prop: string]: unknown },
            { id: number; quoteId: number; usedChargedPremiumButton?: boolean; data: Partial<SetOverridesArgs> }
        >({
            query: ({ id, quoteId, data: { gross_amount_subject_account_rate, gross_tiv_account_rate, ...restData } }) => ({
                url: `v1/final_premium/${id}/set_overrides/`,
                method: 'PUT',
                data: restData,
                cancelRequest: {
                    abortCacheKey: `quote-${quoteId}`,
                },
            }),
            async onQueryStarted({ quoteId, data: updateData, usedChargedPremiumButton }, { dispatch, queryFulfilled }) {
                const { carrier_participations, ...pcUpdateData } = updateData;

                const store = (await import('../store')).default;
                const prevFinalPremium = selectFinalPremium(store.getState(), quoteId);
                const savedChangedPremium = store.getState().pricing.changedPremium;

                dispatch(clearChangedPremium(['equipment_breakdown', 'terror']));

                const pcPatchResult = dispatch(
                    api.util.updateQueryData('quotePolicyCoverage', { quoteId }, (draft: Draft<QuotePolicyCoverageResponse>) => {
                        if (!pcUpdateData) {
                            return;
                        }

                        // quote fields
                        (['split_point', 'layer_1_premium', 'layer_2_premium'] as const).forEach(key => {
                            if (key in pcUpdateData) {
                                // TODO: there has to be a better way to type this. problem is that draft[key] doens't like
                                // being provided string | number it wants one or the other
                                (draft[key] as string | number) = pcUpdateData[key];
                            }
                        });

                        draft.policy_coverage = {
                            ...draft.policy_coverage,
                            ...pcUpdateData,
                        };
                    })
                );
                const carrierPatchResult = dispatch(
                    api.util.updateQueryData('carrierPools', { quoteId }, draft => {
                        if (!carrier_participations) {
                            return;
                        } else if (!isUpdateVentusCarriersOverrides(carrier_participations)) {
                            // TOOD: is it possible to assign and undo patch result in this case?
                            // low prio because it shouldn't happen/should be caught by dev during testing
                            console.error('Provided carrier_participations in invalid format');
                            return;
                        }
                        const newCarrierPools = [...draft.carrier_pools];
                        carrier_participations.forEach(({ pool_id, carrier_id, override_participation_pct }) => {
                            const poolIndex = newCarrierPools.findIndex(p => p.pool_id === pool_id);
                            const carrierIndex = newCarrierPools[poolIndex].carriers.findIndex(c => c.carrier_id === carrier_id);
                            draft.carrier_pools[poolIndex].carriers[carrierIndex].override_participation_pct =
                                (+override_participation_pct)?.toFixed(1) ?? '0.0';
                        });
                        draft.carrier_pools = updateCarrierOverridesObject(draft.carrier_pools, carrier_participations);
                    })
                );
                try {
                    const { data } = await queryFulfilled;
                    // Charged Premium Difference button
                    // After successful override, calculate whether EB or Terror have a new premium value
                    // If so, dispatch it to the pricing reducer to trigger showing the button
                    if (data?.final_premium && !usedChargedPremiumButton) {
                        getChangedPremium(dispatch, data.final_premium, prevFinalPremium);
                    }

                    // TODO: Remove after VENT-12328
                    // pessimistic update for account rates
                    // we need to account for case where account_rates were null due to invalid charged_premium
                    // after removing eb/terror or after increasing the charged_premium, the account rates will no longer be null
                    dispatch(
                        api.util.updateQueryData('quotePolicyCoverage', { quoteId }, draft => {
                            draft.policy_coverage = {
                                ...draft.policy_coverage,
                                gross_amount_subject_account_rate: data?.final_premium?.gross_amount_subject_account_rate ?? null,
                                gross_tiv_account_rate: data?.final_premium?.gross_tiv_account_rate ?? null,
                            };
                        })
                    );

                    dispatch({
                        type: UPDATE_ROW_DATA,
                        tableConfig: LITE_TABLES.QUOTES,
                        data,
                    });
                } catch (e) {
                    if (wasInvalidPricing(e?.error?.data)) {
                        // TODO: Remove after VENT-12328
                        // Policy Coverage inputs were saved and FinalPremium was updated but invalid
                        // account rates get nulled out when charged_premium_before_tf isn't valid
                        // this can happen if Equipment Breakdown or Terror premiums are larger than charged_premium
                        dispatch(
                            api.util.updateQueryData('quotePolicyCoverage', { quoteId }, draft => {
                                draft.policy_coverage = {
                                    ...draft.policy_coverage,
                                    gross_amount_subject_account_rate: null,
                                    gross_tiv_account_rate: null,
                                };
                            })
                        );
                    } else if (!isCancel(e?.error) && !wasNotAbleToPrice(e?.error?.data)) {
                        // if we've hit this point, our PolicyCoverage and Carrier changes were not saved
                        // revert the optimistic update
                        pcPatchResult.undo();
                        carrierPatchResult.undo();
                        dispatch(setChangedPremium(savedChangedPremium));
                    }

                    throw e;
                }
            },
            invalidatesTags: (result, error: unknown) => {
                if (error && (error as any)?.data?.error_detail?.coverage_option_id) {
                    const quoteId = (error as any)?.data?.error_detail?.coverage_option_id;
                    return [
                        { type: 'CarrierData', id: quoteId },
                        { type: 'FinalPremium', id: quoteId },
                        { type: 'QuoteValidation', id: quoteId },
                    ];
                } else if (result) {
                    return [
                        { type: 'CarrierData', id: result.final_premium.quote_id },
                        { type: 'FinalPremium', id: result.final_premium.quote_id },
                        { type: 'QuoteValidation', id: result.final_premium.quote_id },
                    ];
                }
            },
        }),
        reprice: builder.mutation<RepriceResult, { id: number }>({
            query: ({ id }) => ({ url: `v1/quotes/${id}/reprice/`, method: 'PUT' }),
            async onQueryStarted({ id }, { queryFulfilled, dispatch }) {
                const { data } = await queryFulfilled;
                dispatch(updateDataByPath(LITE_TABLES.QUOTES, `rowData.${id}`, data));
                // VENT-12328
                if (data?.reprice?.is_valid) {
                    dispatch(
                        api.util.updateQueryData('quotePolicyCoverage', { quoteId: id }, draft => {
                            draft.policy_coverage = {
                                ...draft.policy_coverage,
                                gross_amount_subject_account_rate: data.reprice.gross_amount_subject_account_rate,
                                gross_tiv_account_rate: data.reprice.gross_tiv_account_rate,
                            };
                        })
                    );
                }
            },
            invalidatesTags: (result, _, args) =>
                result?.reprice?.is_valid && args?.id
                    ? [
                          { type: 'FinalPremium', id: args.id },
                          { type: 'CarrierData', id: args.id },
                          { type: 'QuoteValidation', id: args.id },
                      ]
                    : [],
        }),
        fetchHazardHubData: builder.mutation<HazardHubDataResult, HazardHubDataRequest>({
            query: request => ({ url: 'v1/fetch_hazardhub_data', method: 'POST', data: request }),
        }),
        comparableSubmissions: builder.query<ComparableSubmissionsResult, ComparableSubmissionsOptions>({
            query: ({ submissionId, ...params }) => ({
                method: 'GET',
                url: `v1/comparable_submissions/${submissionId}`,
                params,
            }),
        }),
        getMetrics: builder.query<MetricsResult, MetricsQueryOptions>({
            query: ({ year }) => {
                return {
                    url: `v2/air_pml_analytics?carrier=&as_of_type=E&year=${year}&return_periods=200&ys=AEP&cat_view_types=P`,
                    method: 'get',
                };
            },
        }),
        getPeeData: builder.query<PEEQueryResult, PEEQueryOptions>({
            query: ({ id }) => ({ url: `v2/pee/${id}/`, method: 'GET' }),
            transformResponse: (result: PEEQueryResult, meta) => {
                return {
                    policy_coverage_id: result.policy_coverage_id,
                    bpp_coverages: result.bpp_coverages,
                    time_elements: result.time_elements,
                    quote_id: result.quote_id,
                    metaResponseData: meta?.response.data,
                };
            },
            providesTags: result => (result ? [{ type: 'Quote', id: result.quote_id }] : []),
        }),
        setTargetMultipleRule: builder.mutation<any, any>({
            query: request => ({ url: `v2/target_multiple_rule`, method: 'POST', data: request, hideDialogForErrors: true }),
            transformResponse: (result: any, meta) => {
                return { ...result, metaResponseData: meta?.response.data };
            },
            invalidatesTags: ['TargetMultiple'],
        }),
        getTargetMultipleAuditHistory: builder.query<any, any>({
            query: () => ({ url: `v2/target_multiple_rule_log`, method: 'GET' }),
            // transformResponse: (result: any, meta) => {
            //     return { ...result, metaResponseData: meta?.response.data };
            // },
            providesTags: ['TargetMultiple'],
        }),
        getTargetMultipleRules: builder.query<any, any>({
            query: () => ({ url: `v2/target_multiple_rule`, method: 'GET' }),
            providesTags: ['TargetMultiple'],
        }),
        getGlobalStatus: builder.query<any, any>({
            query: () => ({ url: `v1/global/status`, method: 'GET' }),
        }),
        updateTargetMultipleRule: builder.mutation<any, any>({
            query: request => ({ url: `v2/target_multiple_rule`, method: 'PUT', data: request }),
            transformResponse: (result: any, meta) => {
                return { ...result, metaResponseData: meta?.response.data };
            },
            invalidatesTags: ['TargetMultiple'],
        }),
        deleteTargetMultipleRule: builder.mutation<any, any>({
            query: request => ({ url: `v2/target_multiple_rule`, method: 'DELETE', data: request }),
            transformResponse: (result: any, meta) => {
                return { ...result, metaResponseData: meta?.response.data };
            },
            invalidatesTags: ['TargetMultiple'],
        }),
        fakeTransactionCatModels: builder.mutation<unknown, RunCatModelsQueryOptions>({
            query: ({ transactionId }) => ({
                url: `v2/account_transaction/${transactionId}/fake_cat_models/`,
                method: 'PUT',
            }),
        }),
        fakeSubmitCatModels: builder.mutation<unknown, SubmitCatModelsQueryOptions>({
            query: ({ submissionId, quoteIds }) => ({
                url: `v1/submission/${submissionId}/fake_submit/`,
                method: 'POST',
                data: { quote_ids: quoteIds },
            }),
            invalidatesTags: result => {
                const ids = (result as CatModelsResult)?.quote_ids ?? [];
                const tags = [];
                ids.forEach((id: string | number) => {
                    tags.push({ type: 'FinalPremium', id });
                    tags.push({ type: 'QuoteValidation', id });
                });
                tags.push({ type: 'BackgroundTask', id: 'ACCOUNT_TASKS' });
                return tags;
            },
        }),
        runTransactionCatModels: builder.mutation<unknown, RunCatModelsQueryOptions>({
            query: ({ transactionId }) => ({
                url: `v2/account_transaction/${transactionId}/run_cat_models/`,
                method: 'PUT',
                showProgress: true,
            }),
        }),
        submitCatModels: builder.mutation<unknown, SubmitCatModelsQueryOptions>({
            query: ({ submissionId, quoteIds }) => ({
                url: `v1/submission/${submissionId}/submit/`,
                method: 'POST',
                data: { quote_ids: quoteIds },
                showProgress: true,
            }),
            invalidatesTags: [{ type: 'BackgroundTask', id: 'ACCOUNT_TASKS' }],
        }),
        quoteFinalPremium: builder.query<QuoteResult & { final_premium: FinalPremiumType; final_carrier_data: FinalCarrierData[] }, { quoteId: number }>({
            query: ({ quoteId }) => {
                return {
                    url: `v2/quote/${quoteId}/`,
                    method: 'get',
                    params: buildIncludesExcludesParams({ includes: ['final_premium', 'final_carrier_data'] }),
                };
            },
            async onQueryStarted(_args, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch({
                    type: UPDATE_ROW_DATA,
                    tableConfig: LITE_TABLES.QUOTES,
                    data,
                });
            },
            transformResponse: (result: { quote: QuoteResult & { final_premium: FinalPremiumType; final_carrier_data: FinalCarrierData[] } }, meta) => {
                return { ...result.quote, metaResponseData: meta?.response.data };
            },
            providesTags: (result, _err, arg) => {
                if (!result) {
                    return [];
                }
                return [
                    { type: 'Quote', id: arg.quoteId },
                    { type: 'FinalPremium', id: arg.quoteId },
                ];
            },
        }),
        quotePolicyCoverage: builder.query<QuotePolicyCoverageResponse, { quoteId: number }>({
            query: ({ quoteId }) => {
                return { url: `v2/quote/${quoteId}/`, method: 'get', params: buildIncludesExcludesParams({ includes: ['policy_coverage'] }) };
            },
            transformResponse: (result: { quote: QuoteResult & { policy_coverage: PolicyCoverageType } }, meta) => {
                // TOOD: Why did i do this?
                if (!result?.quote || !result?.quote?.policy_coverage) {
                    return {
                        id: null,
                        created: null,
                        date_sent: null,
                        description: null,
                        final_premium_id: null,
                        is_carrier_layered: null,
                        split_point: null,
                        layer_1_premium: null,
                        layer_2_premium: null,
                        last_cat_model_request_sent_on: null,
                        modified: null,
                        policy_coverage_id: null,
                        policy_coverage: null,
                        metaResponseData: meta?.response.data,
                    };
                }
                return {
                    ...result.quote,
                    metaResponseData: meta?.response.data,
                };
            },
            providesTags: (result, _err, arg) => {
                if (!result || !('policy_coverage_id' in result)) {
                    return [];
                }
                return [
                    { type: 'Quote', id: arg.quoteId },
                    { type: 'PolicyCoverage', id: result.policy_coverage_id },
                ];
            },
        }),
        getRegionalCATSummaryChoices: builder.query<RegionalCATSummaryChoiceResultType, { submissionId: number }>({
            query: ({ submissionId }) => {
                return {
                    url: `v1/regional_cat_summary_choices?submission_id=${submissionId}`,
                    method: 'GET',
                    showProgress: true,
                };
            },
        }),
    }),
});

export const selectMutations = (state: RootState) => state.api.mutations;
export const selectMutationByCacheKey = (state: RootState, key: string) => state.api.mutations?.[key];
export const selectMutationsByEndpoint = createSelector(
    // input selectors
    [selectMutations, (_state: RootState, endpoint: keyof typeof api.endpoints) => endpoint],

    // output selector
    (mutations, endpoint) => {
        const resultingObject = {};
        Object.entries(mutations)
            .filter(([_key, mutation]) => mutation?.endpointName === endpoint)
            .forEach(([key, mutation]) => (resultingObject[key] = mutation));
        return resultingObject;
    }
);

export const selectPendingRequests = (requests: (RootState['api']['queries'][string] | RootState['api']['mutations'][string])[]) => {
    return requests.filter(request => request.status === 'pending');
};

export default api;

export const {
    useGetMarginalPmlsQuery,
    useCarrierPoolsQuery,
    useGetBuildingPremiumQuery,
    useSetFinalPremiumOverridesMutation,
    useGetMetricsQuery,
    useGetPeeDataQuery,
    useRepriceMutation,
    usePrefetch,
    useFakeTransactionCatModelsMutation,
    useFakeSubmitCatModelsMutation,
    useFetchHazardHubDataMutation,
    useSetTargetMultipleRuleMutation,
    useUpdateTargetMultipleRuleMutation,
    useGetTargetMultipleRulesQuery,
    useDeleteTargetMultipleRuleMutation,
    useRunTransactionCatModelsMutation,
    useQuoteFinalPremiumQuery,
    useQuotePolicyCoverageQuery,
    useSubmitCatModelsMutation,
    useGetTargetMultipleAuditHistoryQuery,
    useGetGlobalStatusQuery,
    useToggleLayeredCarrierMutation,
    useGetRegionalCATSummaryChoicesQuery,
} = api;

export const { useLazyComparableSubmissionsQuery } = api;
