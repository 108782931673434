import { PolicyTermsFormField } from '../utils/form';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';

export const AopCoverages = () => {
    return (
        <div className="info-rows">
            <PolicyTermsFormField>
                <FormInput name="aop_deductible" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
            </PolicyTermsFormField>
        </div>
    );
};
