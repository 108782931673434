import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { PolicyTermsFieldName, PolicyTermsFormValues, PolicyTermsFormField } from '../utils/form';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { MASKS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';
import { useAppSelector } from '../../../hooks/redux';
import Icon from '@archinsurance-viki/property-jslib/src/components/Icon';
import { usePolicyTermsDetailGlossaryQuery } from '../../../services/endpoints/coverage-terms';
import { useAppContext } from '../../../hooks/context';

type FloodCoverage = {
    short_name: string;
    label: string;
    included_name: PolicyTermsFieldName;
    limit_name: PolicyTermsFieldName;
    deductible_name: PolicyTermsFieldName;
    bi_waiting_period_name: PolicyTermsFieldName;
};

const FLOOD_COVERAGE_FIELDS: FloodCoverage[] = [
    {
        short_name: 'zone1',
        label: 'Zone 1 - V',
        included_name: 'is_flood_1_included',
        limit_name: 'flood_1_limit',
        deductible_name: 'flood_1_deductible',
        bi_waiting_period_name: 'flood_1_bi_waiting_period',
    },
    {
        short_name: 'zone2',
        label: 'Zone 2 - A, D',
        included_name: 'is_flood_2_included',
        limit_name: 'flood_2_limit',
        deductible_name: 'flood_2_deductible',
        bi_waiting_period_name: 'flood_2_bi_waiting_period',
    },
    {
        short_name: 'zone3',
        label: 'Zone 3 - B, BL, X500, X500L, X (Shaded)',
        included_name: 'is_flood_3_included',
        limit_name: 'flood_3_limit',
        deductible_name: 'flood_3_deductible',
        bi_waiting_period_name: 'flood_3_bi_waiting_period',
    },
    {
        short_name: 'zone4',
        label: 'Zone 4 - C, X, X (Unshaded)',
        included_name: 'is_flood_4_included',
        limit_name: 'flood_4_limit',
        deductible_name: 'flood_4_deductible',
        bi_waiting_period_name: 'flood_4_bi_waiting_period',
    },
];

export const FloodCoverages = () => {
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const { currentQuote } = useAppContext();
    const { data: policyTermsGlossary } = usePolicyTermsDetailGlossaryQuery({ id: currentQuote.policy_coverage_id });

    if (
        [
            policyTermsGlossary.is_flood_1_included,
            policyTermsGlossary.is_flood_2_included,
            policyTermsGlossary.is_flood_3_included,
            policyTermsGlossary.is_flood_4_included,
        ].every(glossaryItem => glossaryItem?.active === false)
    ) {
        return null;
    }

    return (
        <div className="grid-sub-wrapper align-columns">
            <div className="grid-sub-content grid-sub-5 clmn-headers">
                <div>Zone</div>
                <div style={{ width: '72px' }}></div>
                <div>Aggregate and Occurrence</div>
                <div>Deductible Per Building</div>
                <div>BI Waiting Period Per Building</div>
            </div>
            {FLOOD_COVERAGE_FIELDS.map(fieldProps => (
                <div key={fieldProps.label} className="grid-sub-content grid-sub-5">
                    <div className="input-label flex">
                        <Tooltip
                            wrapChild={true}
                            content={
                                <div>
                                    {CONSTANTS?.FLOOD_TOOLTIPS[fieldProps.short_name.toLocaleUpperCase()].split('\n').map((str: string) => (
                                        <p key={str}>{str}</p>
                                    ))}
                                </div>
                            }
                            tooltipTitle="Zone Info"
                        >
                            <>
                                <Icon className="blue-txt icononly" icon="info"></Icon>
                            </>
                        </Tooltip>
                        {fieldProps.label}
                    </div>

                    <PolicyTermsFormField hideLabel>
                        <FormButtonGroup<PolicyTermsFormValues>
                            name={fieldProps.included_name}
                            items={[
                                { display: 'Yes', value: true },
                                { display: 'No', value: false },
                            ]}
                        />
                    </PolicyTermsFormField>

                    <PolicyTermsFormField hideLabel>
                        <FormInput<PolicyTermsFormValues> name={fieldProps.limit_name} dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                    </PolicyTermsFormField>

                    <PolicyTermsFormField hideLabel>
                        <FormInput<PolicyTermsFormValues> name={fieldProps.deductible_name} dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                    </PolicyTermsFormField>

                    <PolicyTermsFormField hideLabel>
                        <FormInput<PolicyTermsFormValues> name={fieldProps.bi_waiting_period_name} dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                    </PolicyTermsFormField>
                </div>
            ))}
            <hr />
            <div className="grid-sub-content grid-sub-5 clmn-headers">
                <div></div>
                <div style={{ width: '72px' }}></div>
                <div>Per Occurrence Limit</div>
                <div>Policy Term Aggregate Limit</div>
                <div>Per Occurrence Deductible</div>
            </div>
            <div className="grid-sub-content grid-sub-5">
                <div className="input-label flex tw-col-span-2">Overall</div>
                {false && (
                    <div className="input-control tertiary-input-control tw-invisible">
                        <label className="input-row">
                            <div className="input-div flex tertiary-input">
                                <div className="data-entry-btn-wrapper">
                                    <button className="button data-entry">Yes</button>
                                    <button className="button data-entry active">No</button>
                                </div>
                            </div>
                        </label>
                    </div>
                )}
                <PolicyTermsFormField hideLabel>
                    <FormInput<PolicyTermsFormValues> name="flood_policy_per_occurrence_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>

                <PolicyTermsFormField hideLabel>
                    <FormInput<PolicyTermsFormValues> name="flood_policy_term_aggregate_limit" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>

                <PolicyTermsFormField hideLabel>
                    <FormInput<PolicyTermsFormValues> name="flood_policy_per_occurrence_deductible" dollarBefore maskOptions={MASKS['CURRENCY_INTEGER']} />
                </PolicyTermsFormField>
            </div>
        </div>
    );
};
