import React from 'react';

type propTypes = Record<string, any>;

type stateTypes = Record<string, any>;

export default class BuildingCoverageBottomPanelApp extends React.Component<propTypes, stateTypes> {
    render() {
        return <div></div>;
    }
}
