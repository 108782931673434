import cx from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { useWatch, useForm, FormProvider } from 'react-hook-form';
import _ from 'lodash';
import { Prompt } from 'react-router';
import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { ThinBottomPanelWrapper } from '@archinsurance-viki/property-jslib/src/containers/panels/ThinBottomPanelWrapper';
import { BuildingLevelHeader } from './BuildingLevelHeader';
import { WindDeductibles } from './WindDeductibles';
import { EarthquakeCoverages } from './EarthquakeCoverages';
import { AopCoverages } from './AopCoverages';
import { EquipmentBreakdown } from './EquipmentBreakdown';
import { BuildingLevelSidebar } from './BuildingLevelSidebar';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormField, getFormDefaultsFromGlossary, BuildingTermsFormValues, BuildingTermsFieldName } from '../utils/form';
import { useValidateQuery } from '../../../services/endpoints/policy-coverage';
import { useAppContext } from '../../../hooks/context';
import { useChangeEffect, usePrevUpdate } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { useBeforeUnload } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { getLocationId } from '../../../utils/navurl-helpers';
import { Location } from 'history';
import { ValidationsTable } from '../../../features/ValidationsPanel';
import { BuildingLevelOverview } from './BuildingLevelOverview';
import { BuildingAndCoverageLimits } from './BuildingAndCoverageLimits';
import { usePolicyTermsContext } from '../utils/coverage-utils';
import { FloodCoverages } from './FloodCoverages';

const BuildingTermsSection = ({
    children,
    groupKey,
    fieldName,
    disabled = false,
    tooltipMessage = undefined,
}: {
    children?: React.ReactNode;
    groupKey: string;
    fieldName?: BuildingTermsFieldName;
    disabled?: boolean;
    tooltipMessage?: string;
}) => {
    const contentRef = useRef<HTMLDivElement>();
    const isIncluded = useWatch<BuildingTermsFormValues>({ name: fieldName });
    const isCollapsed = !children || !isIncluded;

    const BUILDING_TERMS_GROUPS = useAppSelector(state => state.global.CONSTANTS.BUILDING_TERMS_GROUPS);

    const { buildingTermsGlossary } = usePolicyTermsContext();

    // hide sections if group is inactive
    const glossaryKey = `group_${groupKey.toLocaleLowerCase()}`;
    if (buildingTermsGlossary?.[glossaryKey]?.active === false) {
        return null;
    }

    const infoRowsElement = contentRef.current?.querySelector('.info-rows');
    const isEmptySection = !contentRef.current?.hasChildNodes() || (infoRowsElement && !infoRowsElement.hasChildNodes());

    return (
        <div id={groupKey} className="info-block row-editable tw-p-0">
            <div className={cx('tw-transition tw-delay-75 header', { 'tw-border-transparent': isCollapsed || isEmptySection })}>
                <div className="header-text">{BUILDING_TERMS_GROUPS[groupKey]}</div>
                {fieldName && (
                    <PolicyTermsFormField hideLabel showError={false} tooltipMessage={tooltipMessage}>
                        <FormButtonGroup<BuildingTermsFormValues>
                            className="tw-justify-end tw-bg-inherit"
                            name={fieldName}
                            items={[
                                { display: 'Include', value: true },
                                { display: 'Exclude', value: false },
                            ]}
                            disabled={disabled}
                        />
                    </PolicyTermsFormField>
                )}
            </div>
            <div
                ref={contentRef}
                className={cx('tw-transition-[all] tw-duration-300', { 'tw-overflow-hidden tw-max-h-0': isCollapsed, 'tw-max-h-[2000px]': !isCollapsed })}
            >
                {children}
            </div>
        </div>
    );
};

export const BuildingLevelTerms = () => {
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const { currentQuote } = useAppContext();

    const [isValidationsOpen, setIsValidationsOpen] = useState(false);

    const { buildingTermsData, buildingTermsGlossary } = usePolicyTermsContext();

    const formDefaultValues = getFormDefaultsFromGlossary(buildingTermsData, buildingTermsGlossary);
    const formMethods = useForm<BuildingTermsFormValues>({
        defaultValues: formDefaultValues,
        reValidateMode: 'onSubmit',
    });

    const isPolicyEquipmentBreakdownIncluded = useWatch<BuildingTermsFormValues, 'is_policy_equipment_breakdown_included'>({
        name: 'is_policy_equipment_breakdown_included',
        control: formMethods.control,
    });
    const equipmentBreakdownToolTipMesssage =
        CONSTANTS?.EQUIPMENT_BREAKDOWN_INCLUDE_EXCLUDE_TOOLTIPS[isPolicyEquipmentBreakdownIncluded ? 'INCLUDE' : 'EXCLUDE'];

    const isPolicyEarthquakeIncluded = useWatch<BuildingTermsFormValues, 'is_policy_earthquake_included'>({
        name: 'is_policy_earthquake_included',
        control: formMethods.control,
    });
    const earthquakeToolTipMessage = CONSTANTS?.EARTHQUAKE_INCLUDE_EXCLUDE_TOOLTIPS[isPolicyEarthquakeIncluded ? 'INCLUDE' : 'EXCLUDE'];

    const isPolicyAOPIncluded = useWatch<BuildingTermsFormValues, 'is_policy_aop_included'>({
        name: 'is_policy_aop_included',
        control: formMethods.control,
    });
    const aopToolTipMessage = CONSTANTS?.AOP_INCLUDE_EXCLUDE_TOOLTIPS[isPolicyAOPIncluded ? 'INCLUDE' : 'EXCLUDE'];

    useEffect(() => {
        const locationId = getLocationId();
        if (locationId) {
            const el = window.document.getElementById(locationId);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, []);

    const { data: validationData } = useValidateQuery({ id: currentQuote.policy_coverage_id });

    const allValidationErrors = [
        ...Object.values(validationData?.non_field_errors ?? {}).map(message => ({ message })),
        ...Object.entries(validationData?.field_errors ?? {}).map(([field, errors]) => ({
            message: `${buildingTermsGlossary?.[field]?.label ?? 'Error'}: ${errors.join('\n')}`,
            buttonTitle: 'Jump to Error',
            onClick: () => formMethods.setFocus(field as BuildingTermsFieldName),
        })),
    ];
    const hasValidationErrors = allValidationErrors.length > 0;
    usePrevUpdate(hasValidationErrors, prevHasValidationErrors => {
        if (hasValidationErrors && !prevHasValidationErrors) {
            setIsValidationsOpen(true);
        } else if (!hasValidationErrors && prevHasValidationErrors) {
            setIsValidationsOpen(false);
        }
    });

    useBeforeUnload(!_.isEmpty(formMethods.formState.dirtyFields));

    // Reset override default values when changed via input form or via external event
    // Ensure that we only reset fields that have changed to avoid wiping out user input
    // Note: we have extra isDirty checks in here because the final premium can change our default value for inspection fees
    useChangeEffect(formDefaultValues, (nextDefaults, prevDefaults) => {
        Object.keys(nextDefaults).forEach((key: keyof typeof formDefaultValues) => {
            if (!prevDefaults || prevDefaults[key] !== nextDefaults[key]) {
                formMethods.resetField(key, { defaultValue: nextDefaults[key] });
            }
        });
    });

    return (
        <FormProvider {...formMethods}>
            <div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-hidden">
                <BuildingLevelHeader />
                <Prompt
                    message={(location: Location, _action: string) => {
                        return !location.pathname.includes('coverage_options') && !_.isEmpty(formMethods.formState.dirtyFields)
                            ? `You are leaving this page with active changes, do you want to discard them?`
                            : true;
                    }}
                />
                <div className="ventus-content fd-r content-hidden tw-flex-1 tw-h-full">
                    <div className="w-55 flex column overflow-y tw-pr-2 tw-h-full [&_.info-rows:empty]:tw-m-0 [&_.info-rows:empty]:tw-border-0">
                        <BuildingTermsSection groupKey="OVERVIEW">
                            <BuildingLevelOverview />
                        </BuildingTermsSection>

                        <BuildingTermsSection groupKey="COVERAGES_AND_LIMITS">
                            <BuildingAndCoverageLimits />
                        </BuildingTermsSection>

                        <BuildingTermsSection groupKey="WIND" fieldName="wind_or_hail_excluded">
                            <WindDeductibles />
                        </BuildingTermsSection>

                        <BuildingTermsSection groupKey="FLOOD" fieldName="is_flood_included">
                            <FloodCoverages />
                        </BuildingTermsSection>

                        <BuildingTermsSection
                            groupKey="EARTHQUAKE"
                            fieldName="quake_exclusion"
                            disabled={!isPolicyEarthquakeIncluded}
                            tooltipMessage={earthquakeToolTipMessage}
                        >
                            <EarthquakeCoverages />
                        </BuildingTermsSection>

                        <BuildingTermsSection groupKey="AOP" fieldName="aop" disabled={!isPolicyAOPIncluded} tooltipMessage={aopToolTipMessage}>
                            <AopCoverages />
                        </BuildingTermsSection>

                        <BuildingTermsSection groupKey="TERROR" fieldName="terror" />

                        <BuildingTermsSection
                            groupKey="EQUIPMENT_BREAKDOWN"
                            fieldName="equipment_breakdown"
                            disabled={!isPolicyEquipmentBreakdownIncluded}
                            tooltipMessage={equipmentBreakdownToolTipMesssage}
                        >
                            <EquipmentBreakdown />
                        </BuildingTermsSection>
                    </div>
                    <BuildingLevelSidebar />
                </div>
                <ThinBottomPanelWrapper
                    tabs={[`${allValidationErrors.length} Error${allValidationErrors.length !== 1 ? 's' : ''}`]}
                    activeTabIndex={isValidationsOpen ? 0 : null}
                    onTabSelect={() => setIsValidationsOpen(prev => !prev)}
                    render={() => (
                        <div className="tw-flex tw-flex-col tw-gap-2 tw-h-[300px] tw-pb-8">
                            <h2>Validation Errors</h2>
                            <ValidationsTable validations={allValidationErrors} />
                        </div>
                    )}
                />
            </div>
        </FormProvider>
    );
};
