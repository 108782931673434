import api from '../apiSlice';
import { AccountDataType, AccountSearchResultType, SubmissionDataType } from '../../ts-types/DataTypes';
import { NO_ACCOUNT } from '../../reducers/accounts';
import { processAccountData, receiveNoAccount } from '../../actions/SubmissionActions';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';

const ACCOUNT_DATA_INCLUDES = [
    'account_transaction_ids.*',
    'account_transaction_ids.account_transaction_results_obj.*',
    'account_transaction_ids.last_interval_submission_obj',
    'account_transaction_ids.transaction_underwriter.*',
    'account_transaction_ids.review_by.*',
    'account_transaction_ids.bound_by.*',
    'account_transaction_ids.booked_by.*',
];

export const accountApi = api.injectEndpoints({
    endpoints: builder => ({
        accountData: builder.query<{ account: Partial<AccountDataType>; account_transactions: SubmissionDataType[] }, { id: number; showProgress?: boolean }>({
            query: ({ id, showProgress = false }) => ({
                url: `v2/account/${id}/`,
                method: 'get',
                params: new URLSearchParams(ACCOUNT_DATA_INCLUDES.map(includeVal => ['include[]', includeVal])),
                hideDialogForErrors: true,
                showProgress,
            }),
            // ignore showProgress for cache key
            serializeQueryArgs: ({ queryArgs: { id }, ...rest }) => defaultSerializeQueryArgs({ queryArgs: { id }, ...rest }),
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data.account.id !== NO_ACCOUNT) {
                        processAccountData(dispatch, data);
                    }
                } catch (e) {
                    // special handling because we return 404 for Data Entry/UW submissions
                    if (e?.error?.status === 404) {
                        dispatch(
                            accountApi.util.upsertQueryData(
                                'accountData',
                                { id },
                                {
                                    account: { id: NO_ACCOUNT },
                                    account_transactions: [],
                                }
                            )
                        );
                        dispatch(receiveNoAccount(id));
                    }
                }
            },
        }),
        accountTransactionData: builder.query<SubmissionDataType, { submissionId: number }>({
            query: ({ submissionId }) => ({
                url: `v1/account_transaction_data/${submissionId}/`,
                method: 'get',
            }),
        }),
        searchAccounts: builder.query<AccountSearchResultType[], { search: string }>({
            query: ({ search }) => ({ url: `v1/search`, method: 'get', params: { q: search } }),
        }),
    }),
});

export const { useAccountDataQuery, useLazyAccountDataQuery, useAccountTransactionDataQuery, useSearchAccountsQuery } = accountApi;
