import * as React from 'react';

import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { getValidationsPanel } from '../../utils/render-helpers/quotes/quote-validations-helper';
import { AppContextFnsType, AppContextType } from '../../ts-types/AppTypes';
import { ACTION_PANEL_LOCATIONS, calculateRowActions } from '../submissionlog/Helpers';
import TransactionsBottomPanelContainer from '../common/panels/containers/TransactionsBottomPanelContainer';
import { getTaskSubtitles } from '../../utils/task-helpers';
import { AccountTasksPanel } from '../../features/AccountTasksPanel';

type propTypes = {
    appContext: AppContextType;
    tableConfig: TableConfigType;
    transactionIds: number[];
    appContextFns: AppContextFnsType;
    onGetCarriers: (currentQuoteId: number) => void;
    onGetCarriersByTransaction: (curretnTransactionId: number) => void;
    carriers: Record<string, any>;
};

export default class TransactionsApp extends React.Component<propTypes> {
    componentDidUpdate(prevProps: propTypes): void {
        const { currentTransactionId } = this.props.appContext;
        const { urlTransactionId } = prevProps.appContext;
        const prevTransactionId = prevProps.appContext.currentTransactionId;
        if (!urlTransactionId && prevTransactionId) {
            this.onRowSelected(prevTransactionId);
        }

        // if urlTransacstionId isn't a known txn id then select first available txn id
        const newTransactionIds = this.props.transactionIds;
        if (urlTransactionId && newTransactionIds.length > 0 && newTransactionIds.indexOf(this.props.appContext.urlTransactionId) === -1) {
            this.onRowSelected(newTransactionIds[0]);
            return;
        }
        const currentCarrierPools = this.props.carriers.carrier_pools[this.props.appContext.currentQuoteId];
        const currentTransactionStatus = this.props.appContext.currentTransaction ? this.props.appContext.currentTransaction.status : null;
        const prevTransactionStatus = prevProps.appContext.currentTransaction ? prevProps.appContext.currentTransaction.status : null;
        if (
            this.props.appContext.currentQuoteId &&
            (!currentCarrierPools || (currentTransactionStatus && currentTransactionStatus !== prevTransactionStatus))
        ) {
            this.props.onGetCarriers(this.props.appContext.currentQuoteId);
        }
        if (!this.props.appContext.currentQuoteId && currentTransactionStatus !== prevTransactionStatus && currentTransactionId) {
            this.props.onGetCarriersByTransaction(currentTransactionId);
        }
    }

    onRowSelected = (rowId?: number) => {
        this.props.appContextFns.onNavigate({ accountId: this.props.appContext.currentAccountId, transactionId: rowId }, true);
    };

    render(): React.ReactNode {
        const { appContext, transactionIds, carriers } = this.props;
        const { currentTransactionId, currentSubmission, oldWebsite, urlTransactionId } = appContext;
        const { currentQuoteId } = appContext;
        let bottomPanels = [getValidationsPanel(currentSubmission, oldWebsite)];
        let carrierPools;
        if (currentQuoteId) {
            carrierPools = carriers.carrier_pools[currentQuoteId] || [];
        } else {
            carrierPools = carriers.carrier_pools_by_transaction[currentTransactionId] || [];
        }

        let bottomPanel = {
            ContentElement: TransactionsBottomPanelContainer,
            appContext,
            carrierPools,
            label: 'Details',
        };

        return (
            <LiteTableContainer
                tableConfig={this.props.tableConfig}
                allowsDragging={false}
                hasActionPanel={true}
                onPersistTableData={this.props.appContextFns.onPersistTransactionData}
                selectedRowId={urlTransactionId}
                onRowSelected={this.onRowSelected}
                panelActions={calculateRowActions(this.props, ACTION_PANEL_LOCATIONS.TRANSACTIONS)}
                bottomPanel={bottomPanel}
                bottomPanels={bottomPanels}
                tableRows={transactionIds}
                getTaskSubtitles={getTaskSubtitles}
                tasksPanel={<AccountTasksPanel />}
            />
        );
    }
}
