import React from 'react';
import { TextInput } from '@archinsurance-viki/property-jslib/src/components/inputs';

type propTypes = {
    onClose: (x?: boolean) => any;
    onAddCustomQuoteCondition: (quoteId: number, data: Record<string, any>, callback: (success: boolean, errorData?: any) => any) => any;
    quoteId: number;
    customQuoteConditions: string[];
};

export default class AddQuoteConditionApp extends React.Component<propTypes, { conditionData: { condition: string; _errors?: Record<string, unknown> } }> {
    constructor(props: propTypes) {
        super(props);

        this.state = {
            conditionData: {
                condition: null,
            },
        };
    }

    handleChange = (fieldName: string, value: string) => {
        this.setState(prevState => ({ ...prevState, conditionData: { condition: value } }));
    };

    onRequestFinished = (success: boolean, errorData?: { errors: Record<string, unknown> }) => {
        if (success) {
            this.setState(prevState => ({ ...prevState, conditionData: { ...prevState.conditionData, condition: null } }));
            this.props.onClose(true);
        } else if (errorData) {
            this.setState(prevState => ({
                ...prevState,
                conditionData: {
                    ...prevState.conditionData,
                    _errors: { ...errorData.errors },
                },
            }));
        } else {
            console.warn('TODO: handle non-field error');
        }
    };

    handleAddQuoteCondition = () => {
        let { quoteId } = this.props;
        this.props.onAddCustomQuoteCondition(quoteId, this.state.conditionData, this.onRequestFinished);
    };

    handleCancel = () => {
        this.props.onClose(true);
    };

    render() {
        return (
            <div className="standard-modal-content flex column">
                <div className="js-notes-class">
                    <TextInput label="Quote Condition" object={this.state.conditionData} name="condition" onChange={this.handleChange} />
                </div>
                <div className="button-row">
                    <button className="green" onClick={this.handleAddQuoteCondition}>
                        Save
                    </button>
                    <div className="spacer wide" />
                    <button
                        className="red"
                        onClick={() => {
                            this.handleCancel();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}
