import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import AdditionalStructuresModalApp from '../apps/AdditionalStructuresModalApp';
import { addOrSaveStructure, deleteStructure } from '../../../../actions/BuildingActions';
import { AppContextType } from '../../../../ts-types/AppTypes';

type ownPropTypes = {
    modalData: Record<string, any>;
    onClose: (x?: boolean) => any;
    appContext: AppContextType;
};

type propTypes = {
    onSaveStructure: (structure: any, callback: (sucess: boolean, errorData?: any) => any) => void;
    onDeleteStructure: (structure: any, callback: (sucess: boolean, errorData?: any) => any) => void;
} & ownPropTypes;

const mapStateToProps = (_state: Record<string, any>, _ownProps: ownPropTypes) => {
    return {};
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    let { currentAccountId } = ownProps.appContext;

    return {
        onSaveStructure: (structure, callback) => {
            dispatch(
                addOrSaveStructure(ownProps.appContext.buildingDataConfig, structure, ownProps.modalData.buildingId, ownProps.modalData.submissionId, callback)
            );
        },
        onDeleteStructure: (structureId, callback) => {
            dispatch(deleteStructure(ownProps.appContext.buildingDataConfig, currentAccountId, structureId, ownProps.modalData.buildingId, callback));
        },
    };
};

class AdditionalStructuresModalContainer extends React.Component<propTypes> {
    render() {
        return <AdditionalStructuresModalApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(AdditionalStructuresModalContainer);
export default connected;
